// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `    .error-msg {
        color: red;
      }
       .has-error label{
        color:red!important;
        }
        .has-error input{
        border:1px solid red !important;
        } 
        .has-error select{
        border:1px solid red !important;
        } 
h5 > span{
	color: #414141;
}

.only-voucher-container {
  margin-bottom: 2px; 
}

.no-voucher-selected {
  margin-bottom: 2px;
  margin-top: -62px;
}

.smFnt{
  font-size: 10px;
  display: block;
  margin-top: -5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/contractaggreement/components/contract-locations/locations.component.css"],"names":[],"mappings":"IAAI;QACI,UAAU;MACZ;OACC;QACC,mBAAmB;QACnB;QACA;QACA,+BAA+B;QAC/B;QACA;QACA,+BAA+B;QAC/B;AACR;CACC,cAAc;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB","sourcesContent":["    .error-msg {\r\n        color: red;\r\n      }\r\n       .has-error label{\r\n        color:red!important;\r\n        }\r\n        .has-error input{\r\n        border:1px solid red !important;\r\n        } \r\n        .has-error select{\r\n        border:1px solid red !important;\r\n        } \r\nh5 > span{\r\n\tcolor: #414141;\r\n}\r\n\r\n.only-voucher-container {\r\n  margin-bottom: 2px; \r\n}\r\n\r\n.no-voucher-selected {\r\n  margin-bottom: 2px;\r\n  margin-top: -62px;\r\n}\r\n\r\n.smFnt{\r\n  font-size: 10px;\r\n  display: block;\r\n  margin-top: -5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
