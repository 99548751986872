import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoresListService } from '../store/services/stores-list.service';
import { AddBusinessService } from '../store/services/AddBusiness.Service';
import { OpportunitiesService } from '../store/services/opportunities.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReusableModule } from '../common/components/reusable.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AppCommonSession } from '../common/app-common-session';
import { TableModule } from 'primeng/table';

import { ScheduleEventComponent } from './components/schedule-event/schedule-event.component';

import { FollowupComponent } from './components/follow-up/followup.component';
import { OpportunityComponent } from './components/opportunity/opportunity.component';
import { ContactlogComponent } from './components/contactlog/contactlog.component';
import { Contactlogpipe } from './pipes/contactlogpipe';
import { ContactlogService } from './services/contactlog.service';
import { Opportunities } from '../../models/opportunities';
import { ScheduleeventService } from './services/scheduleevent.service';
import { KeysLogPipe } from './pipes/keyslog';
import { NgxSpinnerModule } from 'ngx-spinner';
import {KeyFilterModule} from 'primeng/keyfilter';
import {TabViewModule} from 'primeng/tabview';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {InputMaskModule} from 'primeng/inputmask';
import {FieldsetModule} from 'primeng/fieldset';
import {DialogModule} from 'primeng/dialog';
import {ConfirmationService} from 'primeng/api';
import {CalendarModule} from 'primeng/calendar';
import {AccordionModule} from 'primeng/accordion';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ReusableModule,
    InputMaskModule,
    AccordionModule,
    KeyFilterModule,
    FieldsetModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    DialogModule,
    TabViewModule,
    // DataListModule,
    // GrowlModule,
    MessagesModule,
    MessageModule,
    CalendarModule,
    TableModule,NgxSpinnerModule

  ],
  declarations: [ScheduleEventComponent, FollowupComponent, OpportunityComponent, ContactlogComponent, Contactlogpipe,KeysLogPipe],
  providers: [StoresListService,OpportunitiesService, AddBusinessService, ScheduleeventService, ConfirmationService, AppCommonSession, Opportunities, ContactlogService]
})
export class StoreModule { }
