import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../../../common/application-settings';
import { saveAs as importedSaveAs } from "file-saver";
import { HighLevelCompliance } from '../../../../models/HighLevelCompliance';
import * as appSettings from '../../globals';
import { HighlevelcomplianceService } from '../../services/highlevelcompliance.service';
import { ErrorMessages } from "../../../../config-files/error-messages";

@Component({
  selector: 'app-highlevelcompliance',
  templateUrl: './highlevelcompliance.component.html',
  styleUrls: ['./highlevelcompliance.component.css']
})
export class HighlevelcomplianceComponent implements OnInit {
  highLevelComplianceArray: any;
  ddlYear: any;
  years: any;
  quarters: any;
  ddlQuarter: any;
  ddlFormat: any;
  loading:boolean=true;
  totalRecords:number=0;  
  canExport:any;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  private outreachEffort = appSettings.outreachEffortIP;

  constructor(private highlevel_compliance_service:HighlevelcomplianceService) { }

  ngOnInit() {
    this.ddlFormat = "0";    
      this.years = ApplicationSettings.getOutreachReportYears();
      this.quarters = ApplicationSettings.getReportYearQuarters();
      this.ddlYear = (ApplicationSettings.getOutreachStartDate().getFullYear());
      this.ddlQuarter = ApplicationSettings.getCurrentQuarter().toString();
    this.generateReport();
  }
  generateReport(): void {
    this.loading = true;
    let hlcr: HighLevelCompliance;
    var is_current_season = ((this.ddlYear) !== (ApplicationSettings.getOutreachStartDate().getFullYear()) ? 0 : 1);
    let quarter_start_date:any = new Date(new Date(((is_current_season)?"06/01/":"09/01/") + (this.ddlYear -1)));
      hlcr = new HighLevelCompliance(this.outreachEffort.toString(),quarter_start_date,this.ddlQuarter, "", "");
      this.highlevel_compliance_service.getHighLevelReportSO(hlcr).subscribe((resultArray: any) => {
        this.totalRecords = resultArray.length;
        this.highLevelComplianceArray = resultArray;
        setTimeout(() => {
          this.loading = false;
          this.canExport = true;
        }, 1000)
      },error =>{
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
        this.highLevelComplianceArray = [];
        this.totalRecords = 0;
      });
      this.ddlFormat="0";
  }
  generatereportformat(): void {
    this.loading = true;
    let hlcr: HighLevelCompliance;
    var is_current_season = ((this.ddlYear) !== (ApplicationSettings.getOutreachStartDate().getFullYear()) ? 0 : 1);
    let quarter_start_date:any = new Date(new Date(((is_current_season)?"06/01/":"09/01/") + (this.ddlYear -1)));
    hlcr = new HighLevelCompliance(this.outreachEffort.toString(),quarter_start_date,this.ddlQuarter, "", "");
      this.highlevel_compliance_service.exportReportData(hlcr).subscribe((res: any) => {
        importedSaveAs(res, "");
        this.loading=false;
        this.canExport=true;
      },error =>{
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
      });
      this.ddlFormat="0";
  }
  public getQuarterName(quarter: number) {
    var quarter_name = "";
   /* switch (quarter) {
      case 1:
        quarter_name = "Q1";
        break;
      case 2:
        quarter_name = "Q2";
        break;
      case 3:
        quarter_name = "Q3";
        break;
      case 4:
        quarter_name = "Q4";
        break;
      default:
        break;
    }*/
    return quarter_name;
  }
  canExportOnchange(){
    this.canExport=false;
  }
  validateExportType(){
    if(this.ddlFormat=="0" || !this.canExport)
    return true;
    else
     return false;
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
