import { ComponentCanDeactivate } from '../can-deactivate/component-can-deactivate';
import { NgForm } from "@angular/forms";
import { Directive } from "@angular/core";

@Directive()
export abstract class FormCanDeactivate extends ComponentCanDeactivate {

  abstract get form(): NgForm;
  abstract getModelDiff(): any;
  canDeactivate(): boolean {

    //return !this.form.touched && (this.form.submitted || !this.form.dirty)
    var diff = this.getModelDiff();
    return !(Object.keys(diff).length > 0);
  }
}