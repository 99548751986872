import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[numberhyphonparenthesis]'
})
export class NumberhyphonparenthesisDirective {

   // Allow decimal numbers and negative values
   private regex: RegExp = new RegExp(/^[0-9-+() .+]*$/g);
//Note Whatever character you want to add in above regular expression just add be ]*$ it wille accept it.
   // Backspace, tab, end, home
   private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete'];

   constructor(private el: ElementRef) {
   }
   @HostListener('keydown', ['$event'])
   onKeyDown(event: KeyboardEvent) {
       // Allow Backspace, tab, end, and home keys
       if (this.specialKeys.indexOf(event.key) !== -1) {
           return;
       }
       let current: string = this.el.nativeElement.value;
       let next: string = current.concat(event.key);
       if (next && !String(next).match(this.regex)) {
           event.preventDefault();
       }
   }

}
