import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timeToDate'
})
export class TimeToDatePipe implements PipeTransform {

  transform(value: any, timestamp: any): any {    
    let input_date: Date;
    if (value !== '') {
      input_date = new Date(value);
      let hours = +timestamp.split(' ')[0].split(':')[0];      
      let ampm = timestamp.split(' ')[1];
      // if(hours==12 && ampm==='AM'){ //handle 12 AM
      //   hours= 0;
      // }
      if(ampm.toLowerCase() === 'pm'){
        if(hours !== 12){
        hours = hours+12;
        }
      }
      input_date.setHours(hours, +timestamp.split(' ')[0].split(':')[1]);
      return input_date;
    }
    else
      return input_date;
  }




  dateFormat(value) {
     var datePipe = new DatePipe("en-US");
      value = datePipe.transform(value, 'dd/MM/yyyy');
      return value;
  }
}

