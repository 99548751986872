export const MaxImmunizers: any[] =
[  {
       "key": "1",
       "value": "1"
     },
     {
      "key": "2",
      "value": "2"
     },
     {
      "key": "3",
      "value": "3"
     },
     {
      "key": "4",
      "value": "4"
     },
     {
      "key": "5",
      "value": "5"
     },
     {
      "key": "6",
      "value": "6"
     },
     {
      "key": "7",
      "value": "7"
     },
     {
      "key": "8",
      "value": "8"
     },
     {
      "key": "9",
      "value": "9"
     },
     {
      "key": "10",
      "value": "10"
     }

];
export const t1:string='*t44iz*'; // do not delete