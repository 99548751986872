// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-lst{
    padding: 4px 4px !important;
    line-height: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/reports/components/covid-weekly-vaccine-purchase-report/covid-weekly-vaccine-purchase-report.component.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,yBAAyB;AAC7B","sourcesContent":[".btn-lst{\r\n    padding: 4px 4px !important;\r\n    line-height: 0 !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
