
import {of as observableOf,  Observable, from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { WorkflowService } from '../workflow/workflow.service';
import { STEPS } from '../workflow/workflow.model';
import { ContractData } from '../../../models/contract';
import { Clinic } from '../../../models/contract';
import { environment } from '../../../../environments/environment';
import { IPTandCData } from '../../../JSON/IPTandC';
import { Util } from '../../../utility/util';
import {  CommunityOutreach, CommunityOutreachServiceModel, EquityEventsServiceModel } from '../../../models/community-outreach-v1';
import { HttpClient } from '@angular/common/http';
import { catchError, retry ,tap } from 'rxjs/operators';
import { COVIDTandCData } from '../../../JSON/COVIDTandC';
import { UniversalTandCData } from '../../../JSON/UniversalTandC';
import { UniversalTandCData2022 } from '../../../JSON/UniversalTandC-2022';
import { UniversalTandCData2023 } from '../../../JSON/UniversalTandC-2023'; 
import { UniversalTandCData2024 } from '../../../JSON/UniversalTandC-2024';
import * as internal from 'stream';
@Injectable()
export class OutreachProgramService {

    private formData: ContractData = new ContractData();
    private contractData: ContractData = new ContractData();
    private isImmunizationsFormValid: boolean = false;
    private isLocationsFormValid: boolean = false;
    private isWitnessFormValid: boolean = false;
    private isDataValidationsRequired:boolean = false;
    private outReachType: string;
    immunizations: any[] = [];
    immunization_data: any;
    InvoiceInformation: any[] = [];
    everGreenData: any;
    cancel_check: boolean = false;
    private coData: CommunityOutreach = new CommunityOutreach();
    // private coData: CommunityOutreach = new CommunityOutreach();
    private coFormData: CommunityOutreach = new CommunityOutreach();
    isValidPortalEmail
    constructor(private workflowService: WorkflowService, private _http: HttpClient) {

    }

    getImmunizationsList(agreement_pk, clinic_type) {
        let url = environment.API_URL + environment.RESOURCES.GET_IMMUNIZATION_LIST + "/" + agreement_pk + "/" + clinic_type;
        return this._http.get(url, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
    }
    getOutReachType(): string {
        return this.outReachType;
    }
    setOutReachType(outReachType: string) {
        this.outReachType = outReachType;
    }

    getSelectedImmunizations(): any {
        if (this.formData !== undefined)
            return this.formData.clinicImmunizationList;
        else
            return [];
    }

    getCOSelectedImmunizations(): any {
        if (this.coFormData !== undefined)
            return this.coFormData.clinicImmunizationList;
        else
            return [];
    }

    setSelectedImmunizations(data: any) {
        this.isImmunizationsFormValid = true;
        this.formData.clinicImmunizationList = data;
        this.workflowService.validateStep(STEPS.immunization);
    }


    setCOSelectedImmunizations(data: any) {
        this.isImmunizationsFormValid = true;
        this.coFormData.clinicImmunizationList = data;
        this.workflowService.validateStep(STEPS.immunization);
    }

    getCancelSelectedImmunizations(): any {
        if (this.formData !== undefined)
            return this.formData.clinicImmunizationList;
        else
            return [];
    }

    getCOCancelSelectedImmunizations(): any {
        if (this.coFormData !== undefined)
            return this.coFormData.clinicImmunizationList;
        else
            return [];
    }

    setCancelSelectedImmunizations(data: any) {
        this.isImmunizationsFormValid = true;
        this.formData.clinicImmunizationList = data;
        this.workflowService.validateStep(STEPS.immunization);
    }

    setCOCancelSelectedImmunizations(data: any) {
        this.isImmunizationsFormValid = true;
        this.coFormData.clinicImmunizationList = data;
        this.workflowService.validateStep(STEPS.immunization);
    }
    getClinicLocationsForNewContract(): any {
        // if (Object.keys(contractData).length > 0) {
        //     this.formData = contractData;
        // }
        return this.formData;
    }
    getWalgreensWitnessData() {
        return this.formData.walgreenCoInfo[0];
    }
    setWalgreensWitnessData(data: any) {
        this.isWitnessFormValid = true;
        this.formData.walgreenCoInfo = data;
        this.workflowService.validateStep(STEPS.witness);
    }
    getClinicLocation(i): any {
        return this.formData.clinicList[i];
    }
    getCOClinicLocation(i): any {
        return this.coFormData.clinicList[i];
    }

    clearClinicLocations() {
        this.formData.clinicList = [];
    }

    clearCOClinicLocations() {
        this.coFormData.clinicList = [];
    }

    setClinicLocations(data: any): boolean {
        this.isLocationsFormValid = true;
        this.formData.clinicList = data.locations;
        this.workflowService.validateStep(STEPS.location);
        return true;
    }

    setCOClinicLocations(data: any): boolean {
        this.isLocationsFormValid = true;
        this.coFormData.clinicList = data.locations;
        this.workflowService.validateStep(STEPS.location);
        return true;
    }

    setEquityEventsClinicLocations(data: any): boolean {
        
        this.isLocationsFormValid = true;
        this.coFormData.clinicList = data.locations;
        this.workflowService.validateStep(STEPS.location);
        return true;
    }

    setCancelClinicLocations(data: any): boolean {
        this.isLocationsFormValid = true;
        this.formData.clinicList = data.locations;

        this.workflowService.validateStep(STEPS.location);
        return true;
    }

    getCancelClinicLocation(i): any {
        return this.formData.clinicList[i];
    }

    getContractAgreement() {
        // Return the entire Form Data
        return observableOf(this.formData);
    }

    resetFormData() {
        // Reset the workflow
        this.workflowService.resetSteps();
        // Return the form data after all this.* members had been reset
        this.formData.clear();
        this.isImmunizationsFormValid = this.isLocationsFormValid = this.isWitnessFormValid = false;
        return this.formData;
    }

    isFormValid() {
        // Return true if all forms had been validated successfully; otherwise, return false
        return this.isImmunizationsFormValid &&
            this.isLocationsFormValid &&
            this.isWitnessFormValid;
    }

    setImmunizationsData(data: any) {
        this.immunization_data = data;
    }

    setEverGreenData(data: any) {
        this.everGreenData = data;

    }

    SetIscanceled(is_cancel) {
        this.cancel_check = is_cancel;
    }

    getIsCancel(): boolean {
        return this.cancel_check;
    }

    getEverGreenData() {
        if (this.everGreenData !== undefined) {
            return this.everGreenData;
        }
        else {
            return '';
        }
    }

    getImmunizationsData() {
        if (this.immunization_data !== undefined) {
            return this.immunization_data;
        }
        else {
            return [];
        }
    }
    clearSelectedImmunizations() {
        if (this.immunization_data !== undefined) {
            this.immunization_data = [];
        }
        this.immunization_data = [];

    }
    
    setInvoiceAndVoucherData(data: any) {
        this.InvoiceInformation = data;
    }

    getInvoiceAndVoucherData() {
        if (this.InvoiceInformation !== undefined) {
            return this.InvoiceInformation;
        }
        else {
            return [];
        }
    }
    clearSelectedInvoiceAndVouchersData() {
        if (this.InvoiceInformation !== undefined) {
            this.InvoiceInformation = [];
        }
        this.InvoiceInformation = [];

    }
    
    saveClinicLocations(values: any): boolean {
        this.coFormData.clinicList = values;
        return true;
    }
    saveCommunityOutreachData(): boolean {
        //todo construct the json from immunization and contract form;
        this.coData.clinicImmunizationList = this.immunization_data
        this.coData.clinicList = this.coFormData.clinicList;
        return true;
    }

    saveEquityEventsData(): boolean {
        
        //todo construct the json from immunization and contract form;
        this.coData.clinicImmunizationList = this.immunization_data
        this.coData.clinicList = this.coFormData.clinicList;
        return true;
    }

    getCommunityOutreachData() {
        return this.coData;
    }

    getEquityEventsData() {
        return this.coData;
    }

    saveInterimCommunityOutreachData(step: number): boolean {
        
        if (step == 1) {
            this.contractData.clinicImmunizationList = this.immunization_data;
            return true;
        }
        else if (step == 2) {
            this.contractData.clinicImmunizationList = this.immunization_data;
            this.contractData.clinicList = this.formData.clinicList;
            return true;
        }

        return false;
    }
     
    languageChange(year?:number,isCovidContract:boolean = false,loadUniversalTAndC:boolean=true,loadoldUTandC?:boolean) {
        if(loadUniversalTAndC && year==undefined){
            // return (UniversalTandCData2023);
            return (UniversalTandCData2024);
        }
       else if(loadUniversalTAndC && loadoldUTandC && year==0){
            return (UniversalTandCData);
        }
        else if(loadUniversalTAndC && year==2022)
        {
            return (UniversalTandCData2022);
        }
        else if( year==2022)
        {
            return (UniversalTandCData2022);
        }
        else if(loadUniversalTAndC && year==2023)
        {
            return (UniversalTandCData2023);
        }
        else if(year==2023)
        {
            return (UniversalTandCData2023);
        }
        else if(loadUniversalTAndC && year==2024)
            {
                return (UniversalTandCData2024);
            }
            else if(year==2024)
            {
                return (UniversalTandCData2024);
            }
        else{
                if(isCovidContract){
            return (COVIDTandCData);
        }
        else{
        return (IPTandCData);
        }
    }
    }

    getContractAgreementData(clinic_agreement_pk,isAmendAgreement?: boolean) {
        if (this.immunization_data == null || this.immunization_data.length <= 0) {
            let url = environment.API_URL + environment.RESOURCES.GET_CONTRACT_AGREEMENT + "/" + clinic_agreement_pk;
            if (isAmendAgreement) {
                url = url +"/"+null+ "/" + isAmendAgreement;
            }
             return this._http.get<ContractData>(url, Util.getRequestHeadersV1())
             .pipe(
                retry(4),
                tap(rsp => {
                   this.formData = null;
                   this.formData = rsp;
                }),
                catchError(Util.handleErrorV2)
            );
             //.map((response: Response) => this.formData = response.json());
        } else {
            return observableOf(this.formData);
        }

    }

    fetchContractAgreementData$() {
        return of(this.formData);
    }

    fetchContractAgreementData() {
        return this.formData;
    }

    fetchCOAgreementData() {
        return this.coFormData;
    }

    saveAgreementData(store_id, clinic_type, clinic_agreement, is_override?: boolean) {
        let url = environment.API_URL + environment.RESOURCES.UPDATE_CONTRACT_AGREEMENT + "/" + store_id + "/" + clinic_type;
        if (is_override) {
            url = url + "/" + is_override;
        }
        sessionStorage["NewLogEntry"] = 0;
        return this._http.post(url, clinic_agreement, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
    }

    saveCODataToDB(store_id, clinic_type, coData, is_override?: boolean,is_reAgreementCommOutreach?:any) {
        let communityOutreachServiceModel: CommunityOutreachServiceModel = new CommunityOutreachServiceModel()
        communityOutreachServiceModel.logOutreachStatus = coData.logOutreachStatus;
        communityOutreachServiceModel.commOutreachProgram.approvedOrRejectedBy = coData.approvedOrRejectedBy;
        communityOutreachServiceModel.commOutreachProgram.clinicAgreementPk = coData.clinicAgreementPk;
        communityOutreachServiceModel.commOutreachProgram.clinicImmunizationList = coData.clinicImmunizationList;
        communityOutreachServiceModel.commOutreachProgram.clinicList = coData.clinicList;
        communityOutreachServiceModel.commOutreachProgram.contactLogPk = coData.contactLogPk;
        communityOutreachServiceModel.commOutreachProgram.errorS = coData.errorS;
        communityOutreachServiceModel.commOutreachProgram.isApproved = coData.isApproved;
        communityOutreachServiceModel.commOutreachProgram.isEmailSent = coData.isEmailSent;

        let isAmmendValue =    sessionStorage["IsCommunityOutreachAmendAgreement"];
        let urlForAPI =null;
        if(isAmmendValue ==="true")
        {
            urlForAPI=environment.RESOURCES.AMEND_CO_AGREEMENT;
        }
        else
        {
            urlForAPI=environment.RESOURCES.UPDATE_CO_AGREEMENT;
        }
    

        
        let url =  environment.API_URL + urlForAPI + "/" + store_id + "/" + clinic_type;
        if (is_override) {
            url = url + "/" + is_override;
        }
        sessionStorage["NewLogEntry"] = 0;
        return this._http.post(url, communityOutreachServiceModel, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
       
    }

   // Save to equity events

   saveEquityEventDataToDB(store_id, clinic_type, coData, is_override?: boolean,is_reAgreementCommOutreach?:any) {
    let communityOutreachServiceModel: EquityEventsServiceModel = new EquityEventsServiceModel()
    communityOutreachServiceModel.logOutreachStatus = coData.logOutreachStatus;
    communityOutreachServiceModel.commOutreachProgram.approvedOrRejectedBy = coData.approvedOrRejectedBy;
    communityOutreachServiceModel.commOutreachProgram.clinicAgreementPk = coData.clinicAgreementPk;
    communityOutreachServiceModel.commOutreachProgram.clinicImmunizationList = coData.clinicImmunizationList;
    communityOutreachServiceModel.commOutreachProgram.clinicList = coData.clinicList;
    communityOutreachServiceModel.commOutreachProgram.contactLogPk = coData.contactLogPk;
    communityOutreachServiceModel.commOutreachProgram.errorS = coData.errorS;
    communityOutreachServiceModel.commOutreachProgram.isApproved = coData.isApproved;
    communityOutreachServiceModel.commOutreachProgram.isEmailSent = coData.isEmailSent;
    var data = JSON.stringify(communityOutreachServiceModel);
    let isAmmendValue =    sessionStorage["IsEquityAmendAgreement"];
    let urlForAPI =null;
    if(isAmmendValue ==="true")
    {
        urlForAPI=environment.RESOURCES.AMEND_EquityEvents_AGREEMENT;
    }
    else
    {
        urlForAPI=environment.RESOURCES.UPDATE_EquityEvent_AGREEMENT;
    }



    let url =  environment.API_URL + urlForAPI + "/" + store_id + "/" + clinic_type
               // : environment.API_URL + environment.RESOURCES.UPDATE_EquityEvent_AGREEMENT + "/" + store_id + "/" + clinic_type;
    if (is_override) {
        url = url + "/" + is_override;
    }
    sessionStorage["NewLogEntry"] = 0;
    return this._http.post(url, communityOutreachServiceModel, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}

    saveInterimAgreementData(store_id, clinic_type, is_override?: boolean) {
        //this.makeValidBooleans();
        this.formData.contractPostedByUser = 'Walgreens User';
        this.formData.isEmailSent = null;
        this.formData.contactWagUser = null;
        this.formData.isApproved = null;
        this.makeValidBooleans();
        let url = environment.API_URL + environment.RESOURCES.UPDATE_CONTRACT_AGREEMENT + "/" + store_id + "/" + clinic_type;
        if (is_override) {
            url = url + "/" + is_override;
        }
        sessionStorage["NewLogEntry"] = 0;
        return this._http.post(url, this.formData, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
    }

    makeValidBooleans() {
        this.formData.clinicImmunizationList.forEach(im => {
            if (im.sendInvoiceTo <= 0) {
                im.isTaxExempt = null;
                im.isCopay = null;
                im.isVoucherNeeded = null;
            } else {
                im.isTaxExempt >= 1 ? im.isTaxExempt = 1 : im.isTaxExempt = 0;
                im.isCopay >= 1 ? im.isCopay = 1 : im.isCopay = 0;
                im.isVoucherNeeded >= 1 ? im.isVoucherNeeded = 1 : im.isVoucherNeeded = 0;
                im.copayValue = +im.copayValue;
            }
        });
        this.formData.clinicList.forEach(cl => {
            cl.previousContact = 0;
            cl.previousLocation = 0;
            cl.isNoClinic >= 1 ? cl.isNoClinic = 1 : cl.isNoClinic = 0;
            cl.isReassign >= 1 ? cl.isReassign = 1 : cl.isReassign = 0;
            cl.isCurrent >= 1 ? cl.isCurrent = 1 : cl.isCurrent = 0;            
            try{                
                if(typeof(cl.clinicDate)=='string'){
                let dateString = cl.clinicDate.substring(0, cl.clinicDate.indexOf('T'));
                if(dateString.length>0){
                let dateSplitTokens = dateString.split('-');
                if(dateSplitTokens.length>0){
                var dt = new Date(Number(dateSplitTokens[0]), Number(dateSplitTokens[1]) - 1, Number(dateSplitTokens[2]), 18, 30, 30);
                var clDate = new Date(dt);
                cl.clinicDate = clDate.getMonth() + 1 +"/" +clDate.getDate() +"/" + clDate.getFullYear();      
                }
                }
              }
              else{
                var clDate = new Date(cl.clinicDate);
                cl.clinicDate = clDate.getMonth() + 1 +"/" +clDate.getDate() +"/" + clDate.getFullYear();    
                if(cl.isNoClinic === 1){
                    cl.clinicDate = null;
                }
              }
            }
              catch(e){
                console.log(e);
              }
        });
    }

    getStoreBusinessDetails(businessId: any) {
        if(businessId !== undefined){
        let url = environment.API_URL + environment.RESOURCES.GET_OPPORTUNITY_DETAILS + "/" + businessId;
        return this._http.get(url, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
        }
    }

    getpreviousseasoncliniclocations(clinic_agreement_pk: any, business_pk: any) {
        let url = environment.API_URL + environment.RESOURCES.GET_PREVIOUSSEASON_CLINIC_LOCATIONS + "/" + clinic_agreement_pk + "/" + business_pk;
        return this._http.get(url, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
    }

    checkIfAnyImmunizationsHasCorpInvoiceAndVoucherNeeded(location: Clinic): boolean {
        let added_immunizations = this.getSelectedImmunizations();
        try {
            let hasCorpInvoiceNdVchrNd: Boolean = false;
            var fluType = 'flu';
            added_immunizations.forEach(rec => {
                if (Number(rec.paymentTypeId) == 6 && (rec.sendInvoiceTo === '1' || Number(rec.sendInvoiceTo) === 1) &&
                    (rec.isVoucherNeeded === '1' || Number(rec.isVoucherNeeded) === 1)) {
                    hasCorpInvoiceNdVchrNd = true;
                }
            });
            if (hasCorpInvoiceNdVchrNd == true)
                return true;
        } catch (e) {
            if (e === true)
                return true;
        }
        return false;
    }

    checkIfFluImmForCorpInvoiceSelected() {
        let added_immunizations = this.getSelectedImmunizations();
        try {
            added_immunizations.forEach(rec => {
                if (rec.paymentTypeId == 6 && (rec.sendInvoiceTo === '1' || Number(rec.sendInvoiceTo) === 1) &&
                    (rec.isVoucherNeeded === '1' || Number(rec.isVoucherNeeded) === 1)
                    && rec.immunizationName.toLowerCase().search('flu') !== -1) {
                    throw true;
                }
            });
        } catch (e) {
            if (e === true)
                return true;
        }
        return false;
    }

    checkIfNonFluImmForCorpInvoiceSelected() {
        let added_immunizations = this.getSelectedImmunizations();
        try {
            added_immunizations.forEach(rec => {
                if (rec.paymentTypeId == 6 && (rec.sendInvoiceTo === '1' || Number(rec.sendInvoiceTo) === 1) &&
                    (rec.isVoucherNeeded === '1' || Number(rec.isVoucherNeeded) === 1)
                    && rec.immunizationName.toLowerCase().search('flu') == -1) {
                    throw true;
                }
                // else if(rec.paymentTypeId==6 && rec.sendInvoiceTo===true && rec.immunizationName.toLowerCase().search('flu') == -1){
                //     this.hasNonFluImmSelected = true;
                // }
            });
        } catch (e) {
            if (e === true)
                return true;
        }
        return false;
    }
    checkIfCovidImmForCorpInvoiceSelected() {
        let added_immunizations = this.getSelectedImmunizations();
        try {
            added_immunizations.forEach(rec => {
                if (rec.paymentTypeId == 6 
                    && rec.immunizationName.toLowerCase().search('flu') == -1 && rec.immunizationName.toLowerCase().search('covid') !== -1) {
                    throw true;
                    //&& (rec.sendInvoiceTo === '1' || Number(rec.sendInvoiceTo) === 1) &&
                    //(rec.isVoucherNeeded === '1' || Number(rec.isVoucherNeeded) === 1)
                }
                // else if(rec.paymentTypeId==6 && rec.sendInvoiceTo===true && rec.immunizationName.toLowerCase().search('flu') == -1){
                //     this.hasNonFluImmSelected = true;
                // }
            });
        } catch (e) {
            if (e === true)
                return true;
        }
        return false;
    }
    checkIfNonFluImmIsSelected( ):boolean {
        let added_immunizations = this.getSelectedImmunizations();
        try {
            added_immunizations.forEach(rec => {
                if ( rec.immunizationName.toLowerCase().search('flu') == -1) {
                    throw true;
                }});
        } catch (e) {
            if (e === true)
                return true;
        }
        return false;
    }

    checkIfFluImmIsSelected(clinicType:number=1 ):boolean {
        let added_immunizations = this.getSelectedImmunizations();
        try {
            if(clinicType==6 ){
                added_immunizations = [];
                added_immunizations = this.getImmunizationsData();   
            }
            added_immunizations.forEach(rec => {
                if ( rec.immunizationName.toLowerCase().search('flu') > -1) {
                    throw true;
                }});
            
        } catch (e) {
            if (e === true)
                return true;
        }
        return false;
    }

    insertCharityProgram(store_id, clinic_type, contract_agreement_model, is_override?: boolean) {
        let url = environment.API_URL + environment.RESOURCES.INSERT_CHARITY_PROGRAM + "/" + store_id + "/" + clinic_type;
        if (is_override) {
            url = url + "/" + is_override;
        }
        return this._http.post(url, contract_agreement_model, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
    }

    getValidationsRequiredStatus(){
        return this.isDataValidationsRequired;
    }

    setValidationsRequiredStatus(status:boolean){
        this.isDataValidationsRequired = status;;
    }

    validatePortalEmail(eMail:string, store_id:number=0){  
        var url = environment.API_URL + environment.RESOURCES.VALIDATE_PORTAL_EMAIL+"/"+eMail + "/" + store_id ;
        return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
      }                 
      }

