import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Contactlogpipe } from '../../pipes/contactlogpipe';
import { ContactlogService } from '../../services/contactlog.service';
import { AppCommonSession } from '../../../common/app-common-session';
import { SessionDetails } from '../../../../utility/session';
import { FilterPipe } from '../../pipes/filter-pipe';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { ProgramType } from '../../../../utility/enum';
import { PcacontractAgreementService } from '../../../patientcareadvocacy/Services/pcacontract-agreement.service';
import { Util } from '../../../../utility/util';
import { LulaAso } from '../../../../models/pcaTandC';
import { LulaAsoRelease2 } from '../../../../models/pcaTandC-release2';
import { universalContractReleaseDate } from '../../../../JSON/UniversalTandC';
import { getContractComponentName } from '../../../../utility/loadContracts';
@Component({
  selector: 'app-contactlog',
  templateUrl: './contactlog.component.html',
  styleUrls: ['./contactlog.component.css'],
  providers: [Contactlogpipe, FilterPipe]
})
export class ContactlogComponent implements OnInit {

  showHints: boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : true;
  pageName: string = "viewcontactlogHistory";

  contactlogList: any[] = [];
  date: string;
  businessPk1: string;
  data: any[] = [];
  employees: any[] = [];
  contactLogData: any;
  contactLogHistory: any;
  selectedBusinessName: string;
  value: any;
  GetAssignedBusinessesData: string[] = [];
  businessData: any;
  businessName: string;
  selected: any = 0;
  loghistory: string = "";
  logTitle: string = "";
  noRecords: string = "";
  businesspk: any;
  id: any;
  private sub: any;
  viewlog: boolean = false;
  view_log_history: boolean = false;
  message: string;
  assinedData: any;
  contact_data: any;
  business_name: any;
  user_info: any;
  contact_log_history: any;
  business_type: any;
  displayLog: boolean = false;
  contact_log_pk: number = 0;
  outreach_effort_code: boolean = false;
  oldPcaReleaseDate: any;
  PcaReleaseDate2022_1: any;

  constructor(private _contactlog: ContactlogService, private router: Router, private _sortByPipe: FilterPipe, 
    private _locationNumberPipe: Contactlogpipe, private route: ActivatedRoute, private commonsession: AppCommonSession, 
    private message_service: MessageServiceService, private _contractService: PcacontractAgreementService) {
    
    this.contact_log_history = SessionDetails.GetopportunitiesData();
    if (this.contact_log_history.businessPk > 0) {
      this.view_log_history = true;
      this.business_type = SessionDetails.GetBusinessType();
      this.GetContactLogs(this.contact_log_history.businessPk);
    }
  }
  public GetContactLogs(businessPk) {
    this.outreach_effort_code = false;
    this.user_info = this.commonsession.login_info;
    var outreach_effort_code = this.contact_log_history.isPCA?"PCA":"IP";
    this.loghistory = '';
    this.logTitle = '';
    this.noRecords = '';
    this._contactlog.GetContactLogs(businessPk,SessionDetails.GetStoreId(), outreach_effort_code).subscribe((res) => {
      this.contact_data = res.dataList;
      this.selectedBusinessName = this.contact_data.length>0? this.contact_data[0].businessName:'';
      var contact_log_data;
      var contact_log_history;
      contact_log_data = this._locationNumberPipe.transform(this.contact_data.filter(item => item.isCurrentSeason == true), 'contactDate');
      contact_log_history = this._locationNumberPipe.transform(this.contact_data.filter(item => item.isCurrentSeason == false), 'contactDate');
      this.contactLogData = this._sortByPipe.sortBy(contact_log_data, "contactDate");
      this.contactLogHistory = this._sortByPipe.sortBy(contact_log_history, "contactDate");
      if (this.contactLogHistory.length > 0) {
        this.loghistory = "Past Outreach Logs";
      }
      if (this.contactLogData.length > 0) {
        this.logTitle = "Current Outreach Logs";
      }
      if(this.contactLogData.length === 0 && this.contactLogHistory.length === 0){
        this.noRecords="No records found";
      }
      console.log(this.contactLogHistory);
    });
  }
  
  ngOnInit() {
    SessionDetails.EventDetails("");
    SessionDetails.SetPageName("");
    SessionDetails.SetAgreementPK(0);
    SessionDetails.fromLocalclinics(false);
    this.outreach_effort_code = false;
    this.oldPcaReleaseDate = new LulaAso().tnCCutOffDate;
    this.PcaReleaseDate2022_1 = new LulaAsoRelease2().tnCCutOffDate;
  }
  GoTo(contact_log, outreach_status, clinic_type) {    
  this._contractService.getPCAContractAgreement(contact_log.clinicAgreementPk, null).subscribe((data:any) => {      

    let contractdateApproved = null;
    let routePageName =null;
    let showOldUniversalContract = false;
    
    if(data && (data?.dateApproved || data?.clientInfo?.reviewedDate)){
      contractdateApproved = new Date(data.dateApproved);
      if(data.clientInfo.reviewedDate && data.clientInfo.reviewedDate != '' )
      {
        contractdateApproved = new Date(data.clientInfo.reviewedDate);
      }
      // let uniContractReleaseDate:Date = new Date (universalContractReleaseDate);
      // if(contractdateApproved < uniContractReleaseDate){
      //   showOldUniversalContract = true;
      // }      
      
      routePageName = getContractComponentName(contractdateApproved,'contactlog-viewcontract')
    }

    if (outreach_status == 'Contract Initiated (Contracted Clinic)' && contact_log.isCurrentSeason && contact_log.isScheduledClinics && !contact_log.isDeleted) {
      SessionDetails.fromLocalclinics(true);
      sessionStorage["clinic_agreement_pk"] = contact_log.clinicAgreementPk;
      // if(showOldUniversalContract)
      // {
      //   this.router.navigateByUrl("/viewcontract");
      // }
      // else
      // {
      //   this.router.navigateByUrl("/viewcontract-2022-1");        
      // }
      this.router.navigateByUrl(routePageName);  
    }
   else if (outreach_status == 'Contract Initiated (Contracted Clinic)' && contact_log.isCurrentSeason && contact_log.isDeleted) {
      SessionDetails.fromUserPage(false);
      sessionStorage["clinic_agreement_pk"] = contact_log.clinicAgreementPk;
      // if(showOldUniversalContract)
      // {
      //   this.router.navigateByUrl("/viewcontract");
      // }
      // else
      // {
      //   this.router.navigateByUrl("/viewcontract-2022-1");        
      // }
      this.router.navigateByUrl(routePageName);  
    }
  else  if (outreach_status == 'Contract Initiated (Contracted Clinic)' && contact_log.isCurrentSeason && !contact_log.isDeleted) {
      SessionDetails.SetAgreementPK(contact_log.clinicAgreementPk);
      this.router.navigateByUrl("/communityoutreach/contract");
    }
   else if (outreach_status == 'Contract Initiated (Contracted Clinic)' && !contact_log.isCurrentSeason) {
      SessionDetails.fromUserPage(false);
      SessionDetails.fromLocalclinics(true);
      sessionStorage["clinic_agreement_pk"] = contact_log.clinicAgreementPk;
      // if(showOldUniversalContract)
      // {
      //   this.router.navigateByUrl("/viewcontract");
      // }
      // else
      // {
      //   this.router.navigateByUrl("/viewcontract-2022-1");        
      // }
         routePageName = getContractComponentName(contractdateApproved,'contactlog-viewcontract')
      this.router.navigateByUrl(routePageName);  
    }
    else if (outreach_status == 'Event Scheduled') {
      SessionDetails.EventDetails(contact_log);
      this.router.navigateByUrl("/communityoutreach/scheduleevent");
    }
    else if (outreach_status == 'Follow Up') {
      SessionDetails.SetFollowUp(true);
      SessionDetails.SetPageName("followUp");
      SessionDetails.opportunitiesData(contact_log);
      SessionDetails.SetProgramType(contact_log.outreachEffortCode == "IP" ? ProgramType.IP : contact_log.outreachEffortCode == "PCA"? ProgramType.PCA : ProgramType.SR);
      this.router.navigateByUrl("/communityoutreach/followUp");
    }
    else if (outreach_status == 'Community Outreach') {
      this.router.navigateByUrl("/communityoutreach/communityoutreach");
    }
    else if (outreach_status.toUpperCase().indexOf('LULA INITIATED')>-1 &&        
    contact_log.isCurrentSeason && contact_log.isScheduledClinics) {
      SessionDetails.fromUserPage(false);
      sessionStorage["clinic_agreement_pk"] = contact_log.clinicAgreementPk;
      if(outreach_status.toUpperCase().indexOf('AIDS SERVICE ORGANIZATION LULA INITIATED')>-1 ){
        sessionStorage["clinic_type"] = "8";
      }
      else if(outreach_status.toUpperCase().indexOf('GOVERNMENT LULA INITIATED')>-1 ){
        sessionStorage["clinic_type"] = "10";
      }
      else if(outreach_status.toUpperCase().indexOf('ORGAN PROCUREMENT ORGANIZATION LULA INITIATED')>-1 ){
        sessionStorage["clinic_type"] = "11";
      }
      else if(outreach_status.toUpperCase().indexOf('CANCER SCREENING LULA INITIATED')>-1 ){
        sessionStorage["clinic_type"] = "12";
      }

      if(new Date(Util.getFormatedDate(data.dateApproved)) >= new Date(this.PcaReleaseDate2022_1) || Util.getFormatedDate(data.dateApproved) == null)
      {
      this.router.navigate(['/rhdContractAgreement-2023-1']);
      }
      else
      {
        this.router.navigate(['/rhdContractAgreement']);
      }      
    }
    else if (outreach_status.toUpperCase().indexOf('AIDS SERVICE ORGANIZATION LULA INITIATED')>-1 && contact_log.isCurrentSeason && !contact_log.isDeleted) {
      SessionDetails.fromUserPage(false);
      SessionDetails.SetAgreementPK(contact_log.clinicAgreementPk);
      this.router.navigateByUrl("/communityoutreach/asolula");
    }
    else if (outreach_status.toUpperCase().indexOf('GOVERNMENT LULA INITIATED')>-1 && contact_log.isCurrentSeason && !contact_log.isDeleted) {
      SessionDetails.fromUserPage(false);
      SessionDetails.SetAgreementPK(contact_log.clinicAgreementPk);
      this.router.navigateByUrl("/communityoutreach/govlula");
    }
    else if (outreach_status.toUpperCase().indexOf('ORGAN PROCUREMENT ORGANIZATION LULA INITIATED')>-1 && contact_log.isCurrentSeason && !contact_log.isDeleted) {
      SessionDetails.fromUserPage(false);
      SessionDetails.SetAgreementPK(contact_log.clinicAgreementPk);
      this.router.navigateByUrl("/communityoutreach/opolula");
    }
    else if (outreach_status.toUpperCase().indexOf('CANCER SCREENING LULA INITIATED')>-1 && contact_log.isCurrentSeason && !contact_log.isDeleted) {
      SessionDetails.fromUserPage(false);
      SessionDetails.SetAgreementPK(contact_log.clinicAgreementPk);
      this.router.navigateByUrl("/communityoutreach/csslula");
    }
    else if(!contact_log.isCurrentSeason && !contact_log.isDeleted){
      sessionStorage["clinic_agreement_pk"] = contact_log.clinicAgreementPk;
      if(outreach_status.toUpperCase().indexOf('AIDS SERVICE ORGANIZATION LULA INITIATED')>-1 ){
        sessionStorage["clinic_type"] = "8";
      }
      else if(outreach_status.toUpperCase().indexOf('GOVERNMENT LULA INITIATED')>-1 ){
        sessionStorage["clinic_type"] = "10";
      }
      else if(outreach_status.toUpperCase().indexOf('ORGAN PROCUREMENT ORGANIZATION LULA INITIATED')>-1 ){
        sessionStorage["clinic_type"] = "11";
      }
      else if(outreach_status.toUpperCase().indexOf('CANCER SCREENING LULA INITIATED')>-1 ){
        sessionStorage["clinic_type"] = "12";
      }
      sessionStorage["fromPcaDetails"] = false;
      if(new Date(Util.getFormatedDate(data.dateApproved)) >= new Date(this.PcaReleaseDate2022_1) || Util.getFormatedDate(data.dateApproved) == null)
      {
      this.router.navigate(['/rhdContractAgreement-2023-1']);
      }
      else
      {
        this.router.navigate(['/rhdContractAgreement']);
      }      
    }

});
  }
  removeLogEntry(contact_log_pk) {
    
    this.displayLog = true;
    this.contact_log_pk = contact_log_pk;
  }
  cancelConfirmation() {
    this.displayLog = false;
  }
  deleteLogEntry() {
    
    if (this.contact_log_pk > 0) {
      this._contactlog.DeleteContactLog(this.contact_log_pk).subscribe((data: any) => {
        if (data == "success") {
          this.GetContactLogs(this.contact_log_history.businessPk);
          this.displayLog = false;
          this.contact_log_pk = 0;
          this.message_service.sendProfileChange("profilechange");
        }
      });
    }
  }



  // public onChange(val: any) {
  //   if (val.businessPk != undefined) {
  //     this.selectedBusinessName = val.businessName;
  //     this.GetContactLogs(val.businessPk);
  //   }
  //   else {
  //     //this.contactLogData = '[{}]';
  //     //this.contactLogHistory= '[{}]';
  //   }
  // }
}

