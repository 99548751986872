export class CancelSchedulledClinics{
    actionValue:number;
    userPk:number;
    cancelledList: CancelAppointments[];
    
}
export class CancelAppointments{
    clinicPk:number;
    outreachPK :number;
    clinicName:string;
}