import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { ScheduledclinicsreportComponent } from './components/scheduleclinicreport/scheduledclinicsreport.component';
import { ServicefessreportComponent } from './components/servicefeesreport/servicefeesreport.component';
import { ActivityreportComponent } from './components/activityreport/activityreport.component';
import { ScheduledAppointmentsreportComponent } from './components/scheduledappointmentsreport/scheduledappointmentsreport.component';
import { GroupidassignmentreportComponent } from './components/groupidassignmentreport/groupidassignmentreport.component';
import { ImmunizationfinancereportComponent } from './components/immunizationfinancereport/immunizationfinancereport.component';
import { RevisedclinicdatesComponent } from './components/revisedclinicdates/revisedclinicdates.component';
import { ScheduledeventsreportComponent } from './components/scheduledeventsreport/scheduledeventsreport.component';
import { VaccinePurchasingReportComponent } from './components/vaccinepurchasingreport/vaccinepurchasingreport.component';
import { WeeklygroupidassignmentsComponent } from './components/weeklygroupidassignmentsreport/weeklygroupidassignments.component';
import { WeeklyvaccinepurhaseComponent } from './components/weeklyvaccinepuchasingreport/weeklyvaccinepurchase.component';
import { ActivityReportService } from './services/activityreport.services';
import { HighlevelstatusreportComponent } from './components/highlevelstatusreport/highlevelstatusreport.component';
import {PaginatorModule} from 'primeng/paginator';
import { GroupIdAssignmentReportService } from './services/groupidassignmentreport.services';
import { VaccinePurchasingReportService } from './services/vaccinepurchasing.services';
import { Scheduledeventsreportservice } from './services/scheduledeventsreport.services';
import { ScheduledClinicsReportService } from './services/scheduledclinicsreport.services';
import { WeeklyvaccinepurchasingService } from './services/weeklyvaccinepurchase.service';
import { WeeklygroupidassignmentsService } from './services/weeklygroupidassignments.service';
import { RevisedClinicDatesAndVolumesService } from './services/revisedclinicdatesandvolumes.service';
import { ScheduledAppointmentsService } from './services/scheduledAppointments.service';
import { ImmunizationFinanceService } from './services/immunizationfinance.service';
import { WorkflowMonitorReportComponent } from './components/workflowmonitorreport/workflowmonitorreport.component';
import { WorkflowmonitorService } from './services/workflowmonitor.service';
import { HighlevelstatusService } from './services/highlevelstatus.service';
import { HcsActionsItemsIpComponent } from './components/hcs-actions-items-ip/hcs-actions-items-ip.component';
import { ActionitemsandmetricsService } from './services/actionitemsandmetrics.service';
import { StorecompliancereportComponent } from './components/storecompliancereport/storecompliancereport.component';
import { StorecompliancereportService } from './services/storecompliancereport.service';
import { UseraccessService } from './services/useraccess.service';
import { UseraccesslogComponent } from './components/useraccesslog/useraccesslog.component';
import { PotentiallocalbusinesslistService } from './services/potentiallocalbusinesslist.service';
import { PotentiallocalbusinesslistComponent } from './components/potentiallocalbusinesslist/potentiallocalbusinesslist.component';
import { PotentionallocalbusinessandcontactstatusComponent } from './components/potentionallocalbusinessandcontactstatus/potentionallocalbusinessandcontactstatus.component';
import { PotentialLocalBusinessAndContactStatusService } from './services/potential-local-business.service';
import { MinimumShotExceptionsComponent } from './components/minimumshotexceptions/minimumshotexceptions.component';
import { MinimumshotexceptionsService } from './services/minimumshotexceptions.service';
import { HighlevelcomplianceComponent } from './components/highlevelcompliance/highlevelcompliance.component';
import { HighlevelcomplianceService } from './services/highlevelcompliance.service';
import { InitiatedcontractsreportComponent } from './components/initiatedcontractsreport/initiatedcontractsreport.component';
import { InitiatedcontractsService } from './services/initiatedcontracts.service';
import { ScheduledclinicstatusComponent } from './components/scheduledclinicstatus/scheduledclinicstatus.component';
import { ScheduledClinicstatusService } from './services/scheduledclinicstatus.service';
import { DistrictCompliancereportComponent } from './components/districtcompliancereport/districtcompliancereport.component';
import { DistrictcomplianceService } from './services/districtcompliance.service';
import { RestrictedstateclinicsComponent } from './components/restrictedstateclinics/restrictedstateclinics.component';
import { SchedulerAppttrackingComponent } from './components/scheduler-appttracking/scheduler-appttracking.component';
import { NavigationCanDeactivateGuard } from '../../guards/navigationDeactivate/navigation-can-deactivate';
import { RestrictedstateclinicsService } from './services/restrictedstateclinics.service';
import { TableModule } from 'primeng/table';
import { PcaActionItemsComponent } from './components/pcaactionitems/pcaactionitems.component';
import { TestingeventsreportComponent } from './components/testingeventsreport/testingeventsreport.component';
import { TestingeventsService } from './services/testingevents.service';
import { DataStatsReportingComponent } from './components/data-stats-reporting/data-stats-reporting.component';
import { ScheduledApptsRepForReminderEmailComponent } from './components/scheduledApptsRepForReminderEmail/scheduledApptsRepForReminderEmail.component';
import { DialogModule } from "primeng/dialog";
import { CommonservicesService } from './services/commonservices.service';
import { ContactUsReportComponent } from './components/contact-us-report/contact-us-report.component';
import { CovidclinicsreportComponent } from './components/covidclinicsreport/covidclinicsreport.component';
import { CovidclinicsService } from './services/covidclinics.service';
import { CovidContactUsReportComponent } from './components/covid-contact-us-report/covid-contact-us-report.component';
import { CovidOppStatusreportComponent } from './components/covid-opp-statusreport/covid-opp-statusreport.component';
import { ScheduledCovidClinicsReportComponent } from './components/scheduled-covid-clinics-report/scheduled-covid-clinics-report.component';
import { CovidVaccinePurchasingReportComponent } from './components/covid-vaccine-purchasing-report/covid-vaccine-purchasing-report.component';
import { CovidGroupidassignmentreportComponent } from './components/covid-groupidassignmentreport/covid-groupidassignmentreport.component';
import { CovidHighlevelstatusreportrtComponent } from './components/covid-highlevelstatusreportrt/covid-highlevelstatusreportrt.component';
import { CovidimmunizationfinanceReportComponent } from './components/covidimmunizationfinancereport/covidimmunizationfinancereport.component';
import { CovidMinimumshotexceptionsReportComponent } from './components/covid-minimumshotexceptions-report/covid-minimumshotexceptions-report.component';
import { CovidRevisedClinicdatesComponent } from './components/covid-revised-clinicdates/covid-revised-clinicdates.component';
import { CovidWeeklyVaccinePurchaseReportComponent } from './components/covid-weekly-vaccine-purchase-report/covid-weekly-vaccine-purchase-report.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { LtcfContactUsReportComponent } from './components/ltcf-contact-us-report/ltcf-contact-us-report.component';
import { FluHcsActionsItemsIpComponent } from './components/flu-hcs-actions-items-ip/flu-hcs-actions-items-ip.component';
import { FluOpportunitiesContactStatusComponent } from './components/flu-opportunities-contact-status/flu-opportunities-contact-status.component';
//import { RestrictedstateclinicsService } from './services/restrictedstateclinics.service';

@NgModule({
  imports: [
    CommonModule,FormsModule,
    ReactiveFormsModule,CalendarModule,CheckboxModule,PaginatorModule,TableModule,DialogModule,NgxSpinnerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [ScheduledclinicsreportComponent, ActivityreportComponent, ScheduledAppointmentsreportComponent, 
    GroupidassignmentreportComponent, ImmunizationfinancereportComponent, RevisedclinicdatesComponent, ScheduledeventsreportComponent, 
    VaccinePurchasingReportComponent, WeeklygroupidassignmentsComponent, WeeklyvaccinepurhaseComponent,WorkflowMonitorReportComponent,
    HighlevelstatusreportComponent, HcsActionsItemsIpComponent,StorecompliancereportComponent,UseraccesslogComponent,
    PotentiallocalbusinesslistComponent,PotentionallocalbusinessandcontactstatusComponent, MinimumShotExceptionsComponent, 
    HighlevelcomplianceComponent,InitiatedcontractsreportComponent,ScheduledclinicstatusComponent,DistrictCompliancereportComponent,
    RestrictedstateclinicsComponent,SchedulerAppttrackingComponent,PcaActionItemsComponent, TestingeventsreportComponent, 
    DataStatsReportingComponent,FluOpportunitiesContactStatusComponent, ScheduledApptsRepForReminderEmailComponent, ContactUsReportComponent, CovidclinicsreportComponent, CovidOppStatusreportComponent, CovidContactUsReportComponent, ScheduledCovidClinicsReportComponent, CovidVaccinePurchasingReportComponent, CovidGroupidassignmentreportComponent, CovidHighlevelstatusreportrtComponent, CovidimmunizationfinanceReportComponent, CovidMinimumshotexceptionsReportComponent, CovidRevisedClinicdatesComponent, CovidWeeklyVaccinePurchaseReportComponent, LtcfContactUsReportComponent, FluHcsActionsItemsIpComponent,ServicefessreportComponent],
    
  providers: [ActivityReportService,GroupIdAssignmentReportService,VaccinePurchasingReportService,Scheduledeventsreportservice, 
    ScheduledClinicsReportService,WeeklyvaccinepurchasingService,WeeklygroupidassignmentsService,RevisedClinicDatesAndVolumesService,
    ScheduledAppointmentsService,ImmunizationFinanceService,WorkflowmonitorService,HighlevelstatusService,ActionitemsandmetricsService,
    StorecompliancereportService,UseraccessService,PotentialLocalBusinessAndContactStatusService,MinimumshotexceptionsService,
    HighlevelcomplianceService,RestrictedstateclinicsService,InitiatedcontractsService,ScheduledClinicstatusService,DistrictcomplianceService,TestingeventsService,
    PotentiallocalbusinesslistService,NavigationCanDeactivateGuard,CommonservicesService,CovidclinicsService]
})
export class ReportsModule { }
