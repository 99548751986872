export const PCADetailsFields: any[] =[{
    "groupName": "Testing Organization Information",
    "aliasNames": {
        "businessContactEmail": "Contact Email",
        "firstName": "Contact First Name",
        "lastName": "Contact Last Name",
        "phone": "Contact Phone"
    }
},{
    "groupName": "Services",
    "aliasNames": {
        "naClinicAddlComments": "Additional Comments/Instructions",
        "estimatedQuantity": "Est Attendance",
        "totalImmAdministered":"Actual Attendance",
        "preliminaryPositives":"Preliminary Positives/Registered Organ Donors"
        //"preliminaryPositives":"Preliminary Positives_Registered Organ Donors"
    }
},
{
    "groupName": "Testing Location & Event",
    "aliasNames": {
        "naContactFirstName": "Contact First Name",
        "naContactLastName": "Contact Last Name",
        "naClinicContactPhone": "Contact Phone#",
        "naContactEmail": "Contact Email",
        "naClinicAddress1": "Address",
        "naClinicAddress2": "Address 2",
        "naClinicCity": "City",
        "naClinicState": "State",
        "naClinicZip": "Zip",
        "clinicDate": "Event Date",
        "naClinicStartTime": "Start Time",
        "naClinicEndTime": "End Time",
        "confirmedClientName": "Confirmed By"
    }
}, {
    "groupName": "Feedback/Notes",
    "aliasNames": {
        "feedback": "Feedback/ Notes"
    }
}
]