import { Injectable } from '@angular/core';
import { ActivityReport } from '../../../models/ActivityReport';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ActivityReportService {
  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL;
  constructor(private http: HttpClient) { }

  public getActivityReportData(ar_params: ActivityReport) {
    let url = this._url + "/api/JsActivity_get";
    return this.http.post(url, ar_params, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  public exportReportData(ar_params: ActivityReport) {
    let headers: Headers = new Headers();
    let userData= SessionDetails.GetUserInfo()
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let url = this._url + "/api/JsActivity_downloadReport";
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    return this.http.post(url, ar_params, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
    .pipe(catchError(Util.handleErrorV2));
  }
}