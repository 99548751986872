    export class UserProfile {
        firstName: string;
        lastName: string;
        email: string;
        address: string;
        address2: string;
        city: string;
        state: string;
        zip: string;
        phone: string;
        fax: string;
        company: string;
        created: Date;
        createdBy: string;
        isDeleted: boolean;
        updatedOn: Date;
        updatedBy: string;
        areaCode: string;
        suiteNumber: string;
        username: string;
        userRole: number;
        password: string;
        assignUserRole?:number;
        isGenericEmail?:boolean;
        isEdit?:boolean;
    }

    export class UserProfileModel {
        pk: number;
        profile_model: UserProfile[];
        constructor() {
            this.profile_model = new Array<UserProfile>();
        }
    }

