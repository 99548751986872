
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AddBusiness } from './../../../models/AddBusiness';
import { Util } from '../../../utility/util';
import { environment } from '../../../../environments/environment';
import { AddOutreachOpportunity } from '../../../models/OutreachOpportunity';
import { DuplicateBusiness } from '../../../models/duplicatebusiness';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
  export class AddBusinessService {
    formdata : AddBusiness;
    contractData:any;
    edit_json:any;
    edit_business:any;
    saveJson:any;
    _baseUrl: string = 'http://localhost:49940/';
    number: number = 0;
   constructor(private http: HttpClient) { 
    this.formdata = new AddBusiness();
  }
 
  addOpportunity(opportunity:AddOutreachOpportunity,createdBy:any,isCheckDNC:any,isOverrideDupCheck:any): Observable<any>
  {
    let url = environment.API_URL + environment.RESOURCES.ADD_OPPORTUNTY+"/"+createdBy+"/"+isCheckDNC+"/"+isOverrideDupCheck;
    return this.http.post(url, opportunity,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
    //return this.http.post(url, opportunity).map((res) => Util.extractData(res)).catch((err) => Util.handleError(err, this.router));
  }

  updateOpportunity(opportunity:AddOutreachOpportunity,createdBy:any,isCheckDNC:any,isOverrideDupCheck:any): Observable<any>
  {
    let url = environment.API_URL + environment.RESOURCES.UPDATE_OPPORTUNITY+"/"+createdBy+"/"+isCheckDNC+"/"+isOverrideDupCheck;
    return this.http.post(url, opportunity,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
    //return this.http.post(url, opportunity).map((res) => Util.extractData(res)).catch((err) => Util.handleError(err, this.router));
  }
  // saveStoreBusiness(model:AddBusiness){
  //   //this.edit_json = this.contractData;
  //    this.formdata = model;
  //    return this.http.post(this._baseUrl + 'api/Values/AddStoreBusiness/',model)
  //    .map(
  //      (response: Response) => 
     
  //    response.json()).catch(this.handleError);
  // }
  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    //console.warn(errMsg);
    return observableThrowError(errMsg);
  }
  
   updateStoreBusiness(model:AddBusiness){
     // TODO:: add logice to update DB via Web API & add contract log as per the reqs
     this.formdata = model;
     return this.http.post(this._baseUrl + 'api/Values/UpdateStoreBusiness/',model,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
     
  }
 AddToStore(model:any){
    // TODO:: add logic to add call web api to update radio button update.
    return this.http.post(this._baseUrl + 'api/Values/AddToSelectedStore/',model,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
     
  }
 
  getContractData()
  {
    return this.contractData;
  }
   getStoreBusinessInfo():any[]
   { 
    const BusinessInfo : any[] = [{"id":"1","name":"Business Information"},{"id":"2","name":"Outreach Campaigns"}];
     return (BusinessInfo);
  }
  getStoreBusinessDetails(businessId:any) { 
    let url = environment.API_URL+environment.RESOURCES.GET_OPPORTUNITY_DETAILS + "/" +businessId;
    return this.http.get(url,Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }
  getPCAStoreBusinessDetails(businessId:any) { 
    let url = environment.API_URL+environment.RESOURCES.GET_PCA_OpportunityDetails + "/" +businessId;
    return this.http.get(url,Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }

  // getDuplicateBusiness()
  // { 
  //   return this.http.get('assets/showcase/data/duplicatePhone.json')
  //   .map((response: Response) => this.edit_json = response.json());
  // }
  checkDuplicateBusiness(duplcateAddress:DuplicateBusiness)
  { 
    let url = environment.API_URL+environment.RESOURCES.DUPLICATE_BUSINESS;
    return this.http.post(url, duplcateAddress,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  // getFindDuplicateBusiness(phone_search: string)
  // { 
  //   this.number = 0;
  //   var duplicate : boolean = false;
  //   let matching_imz1 = this.edit_json.filter(item => item.phone == phone_search);
  //   var add_business = new AddBusiness();
  //   if(matching_imz1!="")
  //     {
  //       for (let i = 0; i < matching_imz1.length; i++) {
  //          //this.number = i;
  //          this.number++;
  //     }
  //     if(this.number>1)
  //     {
  //       return this.http.get('assets/showcase/data/duplicatePhone.json')
  //       .map((response: Response) => response.json());
  //     }
  //     else
  //     {
  //       return this.http.get('assets/showcase/data/new59.json')
  //       .map((response: Response) => response.json());
  //     }
      
  //   }
  //   else
  //   {
  //     return this.http.get('assets/showcase/data/new59.json')
  //     .map((response: Response) => response.json());
  //   }
    
  // }


  duPlicatePhoneSearch(phone_search:string):{duplicate:boolean, addBusiness:AddBusiness[]}
   { 
      var duplicate : boolean = false;
      let matching_imz1 = this.contractData.filter(item => item.phone == phone_search);
      var add_business = new AddBusiness();
      if(matching_imz1!="")
      {
      add_business.Phone = add_business!= null ? matching_imz1[0].phone : null;
      add_business.SeniorOutReachStore = matching_imz1[0].SeniorOutReachStore;
      add_business.ImmunizationProgramStore = matching_imz1[0].ImmunizationProgramStore;
          
      if(add_business.Phone!=null)
      {
        duplicate = true;
      }
      else
      {
        duplicate = false;
      }
    }
      return {duplicate:duplicate, addBusiness:matching_imz1};
  }
  duPlicateAddressSearch(address_search:string)
   { 
      var duplicate : boolean = false;
      let matching_imz1 = this.transform(this.contractData.address,address_search); //this.contractData.filter(item => item.address == address_search);
      var add_business = new AddBusiness();
      if(matching_imz1!="")
      {
      add_business.Address1 = add_business!=null ? matching_imz1[0].address : null;
    
      if(add_business.Address1!=null)
      {
        duplicate = true;
      }
      else
      {
        duplicate = false;
      }
    }
      return duplicate;
      
      
  }
  IsSeniorOutReachStoreValid(BusinessId:number, SeniorOutReachStore:string):boolean
  {
    let matchStoreModel = this.contractData.filter(item=> item.SeniorOutReachStore==SeniorOutReachStore); // TODO:: we are not getting senior outreach store value from DB
    if(matchStoreModel.length>0) return true;
    else return false;
  }
  IsImmunizationProgStoreValid(BusinessId:number, ImmunizationProgramStore:string):boolean
  {
    let matchStoreModel = this.contractData.filter(item=> item.ImmunizationProgramStore==ImmunizationProgramStore) // // TODO:: we are not getting senior outreach store value from DB
    if(matchStoreModel!="") return true;
    else return false;
  }
  
  transform(value: any, input: string) {
    if (input) {
        input = input.toLowerCase();
        return this.contractData.filter(function (el: any) {
          
            return el.addressMatchKey.toLowerCase().indexOf(input) > -1; 
        })
    }
    return value;
}
addPCAOpportunity(opportunity:AddOutreachOpportunity,createdBy:any,isOverrideDupCheck:any): Observable<any>
  {
    let url = environment.API_URL + environment.RESOURCES.ADD_PCA_OPPORTUNTY+"/"+createdBy+"/"+isOverrideDupCheck;
    return this.http.post(url, opportunity,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
    //return this.http.post(url, opportunity).map((res) => Util.extractData(res)).catch((err) => Util.handleError(err, this.router));
  }
  
  updatePCAOpportunity(opportunity:AddOutreachOpportunity,createdBy:any,isOverrideDupCheck:any): Observable<any>
  {
    let url = environment.API_URL + environment.RESOURCES.UPDATE_PCA_OPPRTUNITY+"/"+createdBy+"/"+isOverrideDupCheck;
    return this.http.post(url, opportunity,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
    //return this.http.post(url, opportunity).map((res) => Util.extractData(res)).catch((err) => Util.handleError(err, this.router));
  }
  deactivateOpportunity(business_pk) {
    var url = environment.API_URL + "/api/DeactivateOpportunity/"+ business_pk;
    return this.http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
}
