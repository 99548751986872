export class ScheduledClinics {
    userId: number;
    outreachEffort: number;
    outreachStatus: any;
    clinicType:any;
    includeDates: boolean;
    fromDate: string;
    toDate: string;
    ipSeason: number;
    isPreviousSeason:boolean;
    pageIndex:number;
    regionNumber:number;
    outputType: string;
    reportName: string;
    covidOnly:boolean;
    state:string;
    pageSize?:number;
    opportunityDesignationType:string;
    constructor(user_id: number, outreach_effort: number, outreach_status: any,clinic_type:any, 
        include_dates: boolean, from_date: string, to_date: string, ip_season: number,
         is_previous_season:boolean,page_index:number,covid_only:boolean,regionNumber:number, state:string,opportunityDesignation:string,
         report_name?: string,
         output_type?: string,
         page_Size?:number) {
        this.userId = user_id;
        this.outreachEffort = outreach_effort;
        this.outreachStatus = outreach_status;
        this.clinicType = clinic_type;
        this.includeDates = include_dates;
        this.fromDate = from_date;
        this.toDate = to_date;
        this.ipSeason = ip_season;
        this.isPreviousSeason=is_previous_season;
        this.pageIndex=page_index;
        this.outputType = output_type;
        this.reportName = report_name;
        this.covidOnly = covid_only;
        this.regionNumber = regionNumber;
        this.state=state;
        this.opportunityDesignationType=opportunityDesignation;
        this.pageSize = page_Size;
    }
}