export class Schedulevent {
    contactLogPk: number;
    dateOfEvent: any;
    eventStartTime: string;
    eventEndTime: string;
    eventTypeId: number;
    eventDescription: string;
    walgreensRoleId: number;
    walgreensRoleDescription: string;
    numberOfAttendees: number;
    refreshments: boolean;
    fundingId: number;
    provideEducationalMaterial: boolean;
    description: string;
    totalCost: number;
    contactDate: Date;
}
