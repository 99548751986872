    export class OpportunitiesList {
        businessPk: number;
        outreachBusinessPk: number;
        storeId: number;
        districtNumber: number;
        areaNumber: number;
        businessName: string;
        address: string;
        address2: string;
        city: string;
        state: string;
        zip: string;
        actualLocationEmploymentSize: number;
        storeAddress: string;
        isStoreUpdated: boolean;
        contactPhone: string;
        lastContactDate: Date;
        outreachStatusId: number;
        outreachStatus: string;
        businessRank: boolean;
        canReassign: boolean;
        isChecked: boolean;
    }

    export class LocalLeads {
        userPk: number;
        assignedTo: number;
        pageIndex: number;
        filterBy: string;
        filterById: string;
        searchByValue: string;
        sortByField: string;
        totalCount: number;
        startIndex: number;
        finalIndex: number;
        noOfRecordsInPage:number;
        sortOrder:boolean;
        opportunitiesList: OpportunitiesList[];
    }

