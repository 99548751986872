import { Component, OnInit, Input } from '@angular/core';

import { FormGroup, FormArray } from '@angular/forms';
import { states } from '../../../../JSON/States';
import { ConfirmationService, Message } from 'primeng/api';
import { AppCommonSession } from '../../../common/app-common-session';
import { SessionDetails } from '../../../../utility/session';
import { environment } from '../../../../../environments/environment';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { Util } from '../../../../utility/util';
declare var google: any;
@Component({
  selector: 'app-charity-locations',
  templateUrl: './charity-locations.component.html',
  styleUrls: ['./charity-locations.component.css']
})
export class CharityLocationsComponent implements OnInit {
  @Input('group')
  public locationForm: FormGroup;
  @Input('locFormArray')
  public locationFormArray: FormArray;
  @Input('locationNumber')
  clinicNumber: Number = 0;
  locationData: any = {};
  immunizations: any[];
  states: any[];
  defaultDate: Date;
  maxDateValue: Date;
  minDateValue: Date;
  display: boolean = false;
  msgs: Message[] = [];
  dialogSummary: string;
  dialogMsg: string;
  user_info: any;
  clinicdatavalue: any;
  cancel_save: string;
  savePopUp: boolean = false;
  defaultStartTime: Date;

  constructor(
    private confirmationService: ConfirmationService, 
    private commonsession: AppCommonSession,private _utility: Util) {
    this.user_info = this.commonsession.login_info;
  }

  ngOnInit() {
    this.states = states.sort((a,b)=>a.id.localeCompare(b.id));
    var userProfile = SessionDetails.GetUserProfile();
    let today = new Date();
    if ( this._utility.isStoreIsfromRestrictedState()) {
      if (userProfile.userRole.toLowerCase() == "store manager" || userProfile.userRole.toLowerCase() == "pharmacy manager") { 
          this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);//+14
          this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);//+14
      }
       else {
      this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
      this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
      }      

        this.maxDateValue = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate() - 1, 12, 55, 55);
    }
    else {
      if (userProfile.userRole.toLowerCase() == "store manager" || userProfile.userRole.toLowerCase() == "pharmacy manager"
      ) {
        this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);//+14
        this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);//+14
  
      } else {      
      this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
      this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
    }
      this.maxDateValue = new Date(today.getFullYear() + 5, today.getMonth(), today.getDate() - 1, 8, 0, 0);
    }
    this.defaultStartTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
  }

  isStoreIsfromRestrictedState() {
    var resrict_states = ["MO"]; // "DC" -- Removing DC state as per task# 1363
    var current_date = new Date();
    if (resrict_states.indexOf(SessionDetails.GetState()) > -1) {
      if (current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO)) {
        return true;
      }
      if (current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  isFieldValid(field: string) {

    return !this.locationForm.get(field).valid && this.locationForm.get(field).touched;

  }
  onCharityHHSVoucherChange() {

  }

  onClinicDateSelected(selectedDate: Date,i) {
    
    this.locationForm.controls["clinicDate"].setValue(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(),18, 0, 0));
    let address1: string=  this.locationFormArray.controls[i].get('address1').value;
    let address2: string =   this.locationFormArray.controls[i].get('address2').value;
    let city: string =   this.locationFormArray.controls[i].get('city').value;
    let state: string =   this.locationFormArray.controls[i].get('state').value;
    let zipCode: string =   this.locationFormArray.controls[i].get('zipCode').value;
    let latitude: string =   this.locationFormArray.controls[i].get('latitude').value;
    let longitude: string =   this.locationFormArray.controls[i].get('longitude').value;
    let address = address1 + ", "+ address2 + ", " + city + ", " + state + " " + zipCode; 
    if(address1.length>0 && city.length > 0 && state.length>0 && zipCode.length > 0 &&(!latitude ||
      !longitude )){              
    this.getLatitudeLongitude(this.locationFormArray,address,i);
    }
  }
  onSelectedTime(selectedTime,i) {
    
    let address1: string=  this.locationFormArray.controls[i].get('address1').value;
    let address2: string =   this.locationFormArray.controls[i].get('address2').value;
    let city: string =   this.locationFormArray.controls[i].get('city').value;
    let state: string =   this.locationFormArray.controls[i].get('state').value;
    let zipCode: string =   this.locationFormArray.controls[i].get('zipCode').value;
    let address = address1 + ", "+ address2 + ", " + city + ", " + state + " " + zipCode; 
    let latitude: string =   this.locationFormArray.controls[i].get('latitude').value;
    let longitude: string =   this.locationFormArray.controls[i].get('longitude').value;

    if(address1.length>0 && city.length > 0 && state.length>0 && zipCode.length > 0 &&(!latitude ||
      !longitude )){
    this.getLatitudeLongitude(this.locationFormArray,address,i);
    }
  }
  getGoogleLatLngCoordinates(i: any)
  {
       let address1: string=  this.locationFormArray.controls[i].get('address1').value;
       let address2: string =   this.locationFormArray.controls[i].get('address2').value;
       let city: string =   this.locationFormArray.controls[i].get('city').value;
       let state: string =   this.locationFormArray.controls[i].get('state').value;
       let zipCode: string =   this.locationFormArray.controls[i].get('zipCode').value;
       let address = address1 + ", "+ address2 + ", " + city + ", " + state + " " + zipCode; 
       let latitude: string =   this.locationFormArray.controls[i].get('latitude').value;
       let longitude: string =   this.locationFormArray.controls[i].get('longitude').value;
   
       if(address1.length>0 && city.length > 0 && state.length>0 && zipCode.length > 0 &&(!latitude ||
       !longitude )){                
       this.getLatitudeLongitude(this.locationFormArray,address,i)
       }
  }

   //geocoder to get the latitude and longitude 
  getLatitudeLongitude(locationFormArray, fulladdress,index) {   
   
    //let controlclinicList = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': fulladdress }, function (results, status)
     {
      if (status == google.maps.GeocoderStatus.OK) 
      {
        locationFormArray.controls[index].get('latitude').setValue(results[0].geometry.location.lat());
        locationFormArray.controls[index].get('longitude').setValue(results[0].geometry.location.lng());   
      }
    });   
  }
  copyPreviousLocation(formIndex: number, checked: boolean) {
    if (!checked) {
      this.locationFormArray.controls[formIndex].get('address1').setValue('');
      this.locationFormArray.controls[formIndex].get('address2').setValue('');
      this.locationFormArray.controls[formIndex].get('city').setValue('');
      this.locationFormArray.controls[formIndex].get('state').setValue('');
      this.locationFormArray.controls[formIndex].get('zipCode').setValue('');
    }
    else {
      this.locationFormArray.controls[formIndex].get('address1').setValue(this.locationFormArray.controls[formIndex-1].get('address1').value);
      this.locationFormArray.controls[formIndex].get('address2').setValue(this.locationFormArray.controls[formIndex-1].get('address2').value);
      this.locationFormArray.controls[formIndex].get('city').setValue(this.locationFormArray.controls[formIndex-1].get('city').value);
      this.locationFormArray.controls[formIndex].get('state').setValue(this.locationFormArray.controls[formIndex-1].get('state').value);
      this.locationFormArray.controls[formIndex].get('zipCode').setValue(this.locationFormArray.controls[formIndex-1].get('zipCode').value);
    }
  }

  copyPreviousContactInfo(formIndex: number, checked: boolean) {
    if (!checked) {
      this.locationFormArray.controls[formIndex].get('contactFirstName').setValue('');
      this.locationFormArray.controls[formIndex].get('contactLastName').setValue('');
      this.locationFormArray.controls[formIndex].get('contactEmail').setValue('');
      this.locationFormArray.controls[formIndex].get('contactPhone').setValue('');
    }
    else {
      this.locationFormArray.controls[formIndex].get('contactFirstName').setValue(this.locationFormArray.controls[formIndex-1].get('contactFirstName').value);
      this.locationFormArray.controls[formIndex].get('contactLastName').setValue(this.locationFormArray.controls[formIndex-1].get('contactLastName').value);
      this.locationFormArray.controls[formIndex].get('contactEmail').setValue(this.locationFormArray.controls[formIndex-1].get('contactEmail').value);
      this.locationFormArray.controls[formIndex].get('contactPhone').setValue(this.locationFormArray.controls[formIndex-1].get('contactPhone').value);
    }
  }

 
  displayFieldCss(field: string) {
    this.locationForm.updateValueAndValidity();
    if (this.locationForm.controls['isNoClinic'].value == true) {
      if (field === 'localvouchersDist' || field === 'contactFirstName' || field === 'contactLastName' ||
        field === 'contactPhone' || field === 'localEstmdShots' || field === 'contactEmail') {
        return {
          'has-error': this.isFieldValid(field),
          'has-feedback': this.isFieldValid(field)
        };
      }
      else
        return;
    }

    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }


  displayNgMsg(msgSeverity: string, msgSummary: string, msgDetail: string) {
    this.msgs = [];
    //this.msgs.push({severity:msgSeverity,summary:msgSummary,detail:msgDetail});
    //this.messageService.add({severity:msgSeverity,summary:msgSummary,detail:msgDetail});  
  }

  confirm() {    
    this.confirmationService.confirm({
      message: ErrorMessages['clinicDateReminderBefore2WeeksEN'],
      header: ErrorMessages['ErrorMessages'],
      accept: () => { },
      reject: () => {
        return;
      }
    });
  }

  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
  }
  AllowAdminsToEditDataForRestrictedState(){
    return this._utility.AllowAdminsToEditDataForRestrictedState();
  }

  onlyNumberKey(event) {
    Util.onlyNumbers(event);
  }
  
}
