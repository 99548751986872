import { Component, Input} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { stateCodenName } from '../../../contractaggreement/components/contact-us/stateCodenName';

@Component({
  selector: 'app-ltcf-contact-us-locations',
  templateUrl: './ltcf-contact-us-locations.component.html',
  styleUrls: ['./ltcf-contact-us-locations.component.css']
})
export class LtcfContactUsLocationsComponent {
  statecodenname: any;
  @Input('group')
  public locationForm: FormGroup;
  @Input('locFormArray')
  public locationFormArray: FormArray;
  fNlNameRegEx: RegExp = /^(?!\d+$)(?:[a-zA-Z \.\,\'\-]*)?$/;
  cityRegEx: RegExp = /^(?!\d+$)(?:[a-zA-Z \-]*)?$/;
  address1N2: RegExp = /^[ A-Za-z0-9#'.,-/]*$/;
 
  constructor(
  ) {

  }

  ngOnInit() {
    this.statecodenname = stateCodenName;
  }

  isFieldValid(field: string) {
    return !this.locationForm.get(field).valid && this.locationForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  onlyAlphabets(e, ctrl) {
    if (ctrl == "fNlName") {
      return this.fNlNameRegEx.test(e.key);
    } else if (ctrl == "city") {
      return this.cityRegEx.test(e.key);
    }
    else if (ctrl == "add1N2") {
      return this.address1N2.test(e.key);
    }
  }


}
