import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MovePatientsBetweenPatientGroupsModel } from '../../../../models/MovePatientsBetweenPatientGroupsModel';
import { SearchCuraPatientPatientGroupsModel } from '../../../../models/SearchCuraPatientPatientGroupsModel';
import { HeaderserviceService } from '../../../../modules/common/services/headerservice.service';
import { PatientgroupService } from '../../services/patientgroup.service';


@Component({
  selector: 'app-patient-reassignment',
  templateUrl: './patient-reassignment.component.html',
  styleUrls: ['./patient-reassignment.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class PatientReassignmentComponent implements OnInit {
  cols: any[];
  errorShow: boolean = false;
  successShow: boolean = false;
  SelectedSource: any;
  SelectedTarget: any;
  sourceResult: any = [];
  errorMessageShow: string;
  reassignShow: boolean = false;
  recordsExist: boolean = true;
  sourceSearch = '';
  targetSearch = '';
  sourceSearchString: SearchCuraPatientPatientGroupsModel = new SearchCuraPatientPatientGroupsModel();
  targetResult: any = [];
  sourceResultExists: boolean = false;
  targetResultExists: boolean = false;
  targetSearchString: SearchCuraPatientPatientGroupsModel = new SearchCuraPatientPatientGroupsModel();
  sourceAndTargetFilled = false;
  responseFromCP: any;
  typeMisMatch: any = false;

  constructor(private service: PatientgroupService, private spinner: NgxSpinnerService) {
    this.cols = [
      { field: 'name', header: 'Patient Group' },
      { field: 'address', header: 'Location' },
      { field: 'type', header: 'Type' },
      { field: 'patientCount', header: 'Patients' }
    ];
  }

  ngOnInit() {
    //  this.getSourcePatientGroups();
    //  this.getTargetPatientGroups();
  }

  getSourcePatientGroups() {
    //  this.sourceSearch = 'al';
    this.sourceSearchString.searchString = this.sourceSearch;
    this.spinner.show();
    this.service.getSourcePatientGroups(this.sourceSearchString).subscribe((data) => {
      this.spinner.hide();
      let jsonRes = JSON.parse(data.result);
      this.sourceResult = jsonRes.result;
      this.sourceResult.forEach(element => {
        element.disabled = false;
      });

      this.sourceResult = this.sourceResult.filter((item) => item.patientCount != 0);

      if (this.sourceResult.length > 0) {
        this.sourceResultExists = true;
        this.recordsExist = true;
      }
      else {
        this.recordsExist = false;
      }
    }, err => {
      this.spinner.hide();
    });
  }

  getTargetPatientGroups() {
    // this.targetSearch = 'qual';
    this.targetSearchString.searchString = this.targetSearch;
    this.spinner.show();
    this.service.getTargetPatientGroups(this.targetSearchString).subscribe((data) => {
      this.spinner.hide();
      let jsonRes = JSON.parse(data.result);
      this.targetResult = jsonRes.result;
      if (this.targetResult.length > 0) {
        this.recordsExist = true;
        this.targetResultExists = true;
      }
      else {
        this.recordsExist = false;
      }
    }, err => {
      this.spinner.hide();
    });
  }

  getHeaderWidth(colName: string) {
    switch (colName) {
      case "Patient Group":
        return 'w200';
      case "Location":
        return 'w250';
      case "Type":
        return 'w50';
      case "Patients":
        return 'w80';
      default:
        return '';
    }
  }

  onChangeSource() {
    
    if (this.SelectedSource.length == 0) {
      this.sourceResult.forEach(element => {
        element.disabled = false;
      });
    }
    if (this.SelectedSource.length > 0) {
      this.sourceResult.forEach(element => {
        if (element.id != this.SelectedSource[0].id) {
          element.disabled = true;
        }
      });
    }
    if (this.SelectedSource.length > 0 && this.SelectedTarget.length > 0) {
      this.sourceAndTargetFilled = true;
    }
    else {
      this.sourceAndTargetFilled = false;
    }
  }

  onChangeTarget() {
    
    if (this.SelectedTarget.length == 0) {
      this.targetResult.forEach(element => {
        element.disabled = false;
      });
    }
    if (this.SelectedTarget.length > 0) {
      this.targetResult.forEach(element => {
        if (element.id != this.SelectedTarget[0].id) {
          element.disabled = true;
        }
      });
    }

    if (this.SelectedSource.length > 0 && this.SelectedTarget.length > 0) {
      this.sourceAndTargetFilled = true;
    }
    else {
      this.sourceAndTargetFilled = false;
    }
  }

  reassignPatients() {
    
    this.reassignShow = true;
  }

  confirmReassignPatients() {
    // 
    this.spinner.show();
    this.reassignShow = false;
    let postObj = new MovePatientsBetweenPatientGroupsModel;
    postObj.fromPG = this.SelectedSource[0].id;
    postObj.toPG = this.SelectedTarget[0].id;
    if(this.SelectedSource[0].type != this.SelectedTarget[0].type)
    {
      this.typeMisMatch = true;
      this.spinner.hide();
      return;
    }

    this.service.reassignPatients(postObj).subscribe((data) => {
      
      this.spinner.hide();
      this.responseFromCP = data.result;
      this.successShow = true;
    }, error => {
      this.spinner.hide();
      this.errorShow = true;
      this.errorMessageShow = error.errorS.errorMessage;
    
    });

  }
  
  successOkClick() {
    this.successShow = false;
    this.sourceResult = [];
    this.targetResult = [];
    this.SelectedSource = [];
    this.SelectedTarget = [];
    this.sourceAndTargetFilled = false;
    this.getSourcePatientGroups();
    this.getTargetPatientGroups();
  }

  typeMisMatchOkClick(){
    this.typeMisMatch = false;
  }


}


