import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContractapproveService } from '../../services/contractapprove.service';
import { ClientInfo } from '../../../../models/client-info';
import { String, StringBuilder } from 'typescript-string-operations';
import { ContractData, Client, WalgreenCo, LegalNoticeAddress, Clinic, LanguageVariables } from '../../../../models/contract';
import { SessionDetails } from '../../../../utility/session';
import { Util } from '../../../../utility/util';
import { DatePipe, Location } from '@angular/common';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ThankyouData } from '../../../../models/thankyouData';
import { universalContractReleaseDate } from '../../../../JSON/UniversalTandC';

@Component({
  selector: 'app-viewcontract-agreement-2022',
  templateUrl: './viewcontract-agreement.component-2022.html',
  styleUrls: ['./viewcontract-agreement.component-2022.css'],
  providers: [DatePipe]
})
export class ViewcontractAgreementComponent2022 implements OnInit {
  InvoiceInformation: any = [];
  displayInvoiceAndVoucher: boolean = false;
  getData: any;
  jsonData: string;
  currentUser = [];
  userRecords = [];
  aggrementData: any;
  clinicAgreement: ContractData = new ContractData();
  immunizationInformation = [];
  immunizationInformation1: string;
  clinicInformation = [];
  clientInformation: Client = new Client();
  legalNoticeAddressInfo: LegalNoticeAddress = new LegalNoticeAddress();
  walGreensInfo: WalgreenCo = new WalgreenCo();
  items: any;
  paymentCheck: boolean;
  clientName: string;
  currentLanguage: string = 'english';
  @Input() client: string;
  @Input() childMessage: string;
  spanishLanguage: string;
  headerText: string;
  attachment: string;
  message: string;
  imz: string;
  payment: string;
  rates: string;
  englishLanguage: string;
  enheader: string;
  enattachment: string;
  language: string;
  dialogSummary: string;
  dialogMsg: string;
  display: boolean = false;
  success_message: boolean = false;
  showBtnEn: boolean = false;
  showBtnSp: boolean = true;
  enlanguageSelection: boolean;
  splanguageSelection: boolean;
  json: string;
  legal_notice: string;
  today: any;
  attentionTo: string;
  client_information: any[];
  legal_information: any[];
  from_email: boolean = false;
  client_info: ClientInfo = new ClientInfo();
  table1: string = '';
  attn: string;
  isCovidVaccineAvailable: boolean = false;
  /////////////////////////////////////////// LANGUAGE Variables ///////////////////////
  languageVariables = new LanguageVariables();

  multipleEmailAddrss: string = '';
  editAgreeMnt: string = '';
  saveAgreeMnt: string = '';
  cancelTxt: string = '';
  noteTxt: string = '';
  fluExpiryDateTxt: string = '';
  routineExpiryDateTxt: string = '';
  estShots: string = '';
  sendInvoiceTo: string = '';
  isTaxExempt: string = '';
  isCopay: string = '';
  voucherNeeded: string = '';
  voucherExpiry: string = '';
  copayValue: string = '';
  approvedOrRejectedBy: string = "";
  sendAgreeMnt: string = "";
  from_clinic_details: boolean = false;
  approveContract: FormGroup;
  isSelected: boolean = false;
  covidOpportunityType: number;
  privateClinicFees: string;
  isCovidVoucher: boolean = false;
  showPreUniversalContract: boolean = false;
  siteMinimum: number = 25;
  ImzVcnName: any;
  PaymtBillMethod: any;
  VoucherRates: any;
  OffSiteClinicRates: any;
  businessName: any;
  isShow3dot3: boolean = true;
  isShowsingleStarTAndC2: boolean = true;

  constructor(
    private _apiservice: ContractapproveService, 
    private router: Router,
    private utility: Util, 
    private _fb: FormBuilder, 
    private clientInfo: ClientInfo,
    private _datePipe: DatePipe, 
    private _location: Location, 
    private spinner: NgxSpinnerService
  ) {
    this.today = new Date();
    this.client_info = this.clientInfo;
  }

  private selectedLink: string;

  setradio(e: string): void {
    this.selectedLink = e;
  }
  // isSelected(name: string): boolean {
  //   if (!this.selectedLink) {
  //     return false;
  //   }
  //   return (this.selectedLink === name);
  // }
  ngOnInit() {
    this.isSelected = false;
    this.approveContract = this._fb.group({
      email_signature: this._fb.control('', null),
      user_notes: this._fb.control('', null),
      radioBtn: this._fb.control('', null),
      email: this._fb.control('', null)
    });
    this.from_clinic_details = SessionDetails.GetfromLocalclinics();
    this.from_email = SessionDetails.GetfromUserPage();
    let cpk = sessionStorage["clinic_agreement_pk"];
    if (cpk == null || cpk == undefined) {
      this.showDialog(ErrorMessages['clientError'], ErrorMessages['nullClinicAgreementPk']);
      this.spinner.hide();
      return;
    }
    this._apiservice.getContractAgreementApproveData(cpk).subscribe((data: any) => {
      if(data && data.dateApproved && data.dateApproved < '2022-06-29')
      {
        this.isShow3dot3 = false;
      }
      if(data && data.dateApproved && data.dateApproved.toString() > '2022-08-19')
      {
        this.isShowsingleStarTAndC2 = false;
      }
      this.spinner.hide();
      this.clinicAgreement = data;
      this.businessName = data.opportunityInfo.businessName;
      this.approvedOrRejectedBy = data.approvedOrRejectedBy;
      if (data.isApproved) {
        this.approveContract.controls['radioBtn'].setValue("1");
      }
      else if (!data.isApproved && data.isApproved != null) {
        this.approveContract.controls['radioBtn'].setValue("2");
        this.isSelected = true;
      }

      this.approveContract.controls['user_notes'].setValue(data.notes);
      this.approveContract.controls['email_signature'].setValue(data.signature);
      this.userRecords = this.clinicAgreement.clinicImmunizationList;
      this.GetClinicInformation();
      this.GetImmunizationformation();
      this.GetClientAndLegalInformation();
      this.GetWalgreensCOformation();
      this.changeLanguage();
      this.displayInvoiceInfo();
      this.covidOpportunityType = data.opportunityInfo.covidOpportunityType;
      this.privateClinicFees = data.opportunityInfo.privateClinicFees ? data.opportunityInfo.privateClinicFees : "8.5";
      let covidVaccine = this.userRecords.filter(p => p.immunizationName.includes('COVID19 Vaccine (')).length > 0;
      //let CovidVoucher = this.userRecords.filter(item=>item.immunizationName.includes('Vaccine Voucher')).length > 0;
      if (covidVaccine) {
        this.isCovidVoucher = true;
      }
      // else{
      //   this.isCovidVoucher = true;
      // }
      this.siteMinimum = this.covidOpportunityType == 1 ? 15 : 25;
    },
      err => {
        this.spinner.hide();
        // switch (err) {
        //   case 500:
        //     this.showDialog(ErrorMessages['clientError'], err.json().Message);
        //     break;
        //   case 400:
        //     this.showDialog(ErrorMessages['clientError'], err.json().Message);
        //     break;
        //   default:
        this.showDialog(ErrorMessages['clientError'], err.json().Message);
        //     break;
        // }
      });
    this.changeLanguage();
  }

  getPaymentTypes(imz) {    
    let paymentTypes = '';
    let immzs = this.clinicAgreement.clinicImmunizationList.filter(p => p.immunizationPk == imz.immunizationPk);
    immzs.forEach((element, index, array) => {
      paymentTypes += `<p class="payment-mb10"><span>`
      paymentTypes += element.paymentTypeName;
      if (index !== (array.length - 1)) {
        paymentTypes += ',';
      }
      paymentTypes += `</span></p>`
    });
    return paymentTypes;
  }

  getImmzPrices(imz) {    
    let price = '';
    let immzs = this.clinicAgreement.clinicImmunizationList.filter(p => p.immunizationPk == imz.immunizationPk);
    immzs.forEach((element, index, array) => {
      if (element.paymentTypeId != '13' && element.paymentTypeId != '11') {
        price += '$';
        let priceEx = element.price;
        price += (Math.round(priceEx * 100) / 100).toFixed(2);
        price += ' per dose'
        if (index + 1 != array.length) {
          price += ', ';
        }
      }
    });
    // let isCashPaidByEmpExists = this.clinicAgreement.clinicImmunizationList.filter(p => p.immunizationPk == imz.immunizationPk && +p.paymentTypeId == 7).length > 0;
    // if (isCashPaidByEmpExists) {
    //   price = '$';
    //   let priceEx = this.clinicAgreement.clinicImmunizationList.filter(p => p.immunizationPk == imz.immunizationPk && +p.paymentTypeId == 7)[0].price;
    //   price += (Math.round(priceEx * 100) / 100).toFixed(2);
    //   price += ' per dose'
    // }
    return price;
  }

  getImmzPricesForVoucher(imz) {
    let price;
    let isRecordExists = this.clinicAgreement.clinicImmunizationList.filter(p => p.immunizationPk == imz.immunizationPk && +p.paymentTypeId == 13).length > 0;
    if (isRecordExists) {
      price = '$';
      let priceEx = this.clinicAgreement.clinicImmunizationList.filter(p => p.immunizationPk == imz.immunizationPk && +p.paymentTypeId == 13)[0].price;
      price += (Math.round(priceEx * 100) / 100).toFixed(2);
      price += ' per dose'
    }
    return price;
  }

  displayInvoiceInfo() {
    if (this.userRecords != null) {
      let corp = this.userRecords.filter(c => Number(c.paymentTypeId) == 13);
      let routineExpDateLst = corp.filter(c => c.immunizationName.toLowerCase().search('flu') === -1)
      let flueExpDateLst = corp.filter(c => c.immunizationName.toLowerCase().search('flu') !== -1)
      let covidExpDateLst = corp.filter(c => c.immunizationName.toLowerCase().search('covid') !== -1);
      if (corp !== null && corp.length > 0) {
        this.displayInvoiceAndVoucher = true;
        this.InvoiceInformation.push({
          "paymentTypeId": corp[0].paymentTypeId,
          "sendInvoiceTo": corp[0].sendInvoiceTo,
          "name": corp[0].name,
          "phone": corp[0].phone,
          "fax": corp[0].fax,
          "email": corp[0].email,
          "address1": corp[0].address1,
          "address2": corp[0].address2,
          "city": corp[0].city,
          "isTaxExempt": corp[0].isTaxExempt,
          "state": corp[0].state,
          "zip": corp[0].zip,
          "isCopay": corp[0].isCopay,
          "copayValue": corp[0].copayValue,
          "isVoucherNeeded": corp[0].isVoucherNeeded,
          "fluvoucherExpirationDate": flueExpDateLst.length > 0 ? flueExpDateLst[0].voucherExpirationDate : null,
          "routinevoucherExpirationDate": routineExpDateLst.length > 0 ? routineExpDateLst[0].voucherExpirationDate : null,
          "covidvoucherExpirationDate": covidExpDateLst.length > 0 ? covidExpDateLst[0].voucherExpirationDate : null
        });
      }
    }

  }
  GetClientLegalInfo() {
    this.client_info = this._apiservice.getSelectedClientLegalInfo();
  }
  languageChangeToSpanish() {
    this.showBtnEn = true;
    this.showBtnSp = false;
    this.enlanguageSelection = false;
    this.splanguageSelection = true;
    let contractdateApproved = null;
    if ((this.clinicAgreement && this.clinicAgreement.dateApproved)) {
      contractdateApproved = new Date(this.clinicAgreement.dateApproved);
      let uniContractReleaseDate: Date = new Date(universalContractReleaseDate);
      if (contractdateApproved < uniContractReleaseDate) {
        this.showPreUniversalContract = true;
      }
    }
    else if ((this.clinicAgreement && this.clinicAgreement.dateCreated)) {
      contractdateApproved = new Date(this.clinicAgreement.dateCreated);
      let uniContractReleaseDate: Date = new Date(universalContractReleaseDate);
      if (contractdateApproved < uniContractReleaseDate) {
        this.showPreUniversalContract = true;
      }
    }
    let data: any = this._apiservice.languageChange(2022,this.isCovidVaccineAvailable, !this.showPreUniversalContract, this.showPreUniversalContract);
    if (data) {
      this.spanishLanguage = data.spanish;
      this.headerText = data.header;
      this.attachment = data.attachmentheader;
      this.imz = data.imzsp;
      this.payment = data.paymentsp;
      this.rates = data.ratessp;

      this.ImzVcnName = data.ImzVcnNameSp;
      this.PaymtBillMethod = data.PaymtBillMethodSp;
      this.VoucherRates = data.VoucherRatesSp;
      this.OffSiteClinicRates = data.OffSiteClinicRatesSp;

      this.languageVariables.thirdPartyRights = data.thirdPartyRightsSP;
      this.languageVariables.thirdPartyRightsText = data.thirdPartyRightsTextSP;

      this.languageVariables.businessName = data.clientsp;
      this.languageVariables.name = data.namesp;
      this.languageVariables.eSignature = data.eSignatureSP;
      this.languageVariables.date = data.dateSP;
      this.languageVariables.district = data.districtSP;
      this.languageVariables.dose = data.doseSP;

      this.languageVariables.title = data.titlesp;
      this.attentionTo = data.attentionsp;
      this.languageVariables.address1 = data.address1sp;
      this.languageVariables.address2 = data.address2sp;
      this.languageVariables.city = data.citysp;
      this.languageVariables.state = data.statesp;
      this.languageVariables.zip = data.zipcodesp;
      //this.legal_notice = data.legalsp;
      //this.client_info = data.clientinfosp;
      this.languageVariables.approveAgrmnt = data.approveAgrmntSP;
      this.languageVariables.approveText = data.approveTextSP;
      this.languageVariables.electrSign = data.electrSignSP;
      this.languageVariables.notes = data.notesSP;
      this.languageVariables.pageTitle = data.pageTitleSP;

      this.languageVariables.inWitnessWhereOff = data.inWitnessWhereOffSP;
      this.languageVariables.inWitnessWhereOff2ndLine = data.inWitnessWhereOff2ndLineSP;
      this.languageVariables.sendLegalNoticeToClient = data.sendLegalNoticeToClientSP;
      this.languageVariables.sendLegalNoticeToWalgreens = data.sendLegalNoticeToWalgreensSP;
      this.languageVariables.wallGreensCommOffsiteTitle1 = data.wallGreensCommOffsiteTitle1SP;
      this.languageVariables.wallGreensCommOffsiteTitle2 = data.wallGreensCommOffsiteTitle2SP;
      this.languageVariables.wallGreensResponsibilities = data.wallGreensResponsibilitiesSP;
      this.languageVariables.wallGreensResponsibilitiesPara1Heading = data.wallGreensResponsibilitiesPara1HeadingSP;
      this.languageVariables.wallGreensResponsibilitiesCvrdServices = data.wallGreensResponsibilitiesCvrdServicesSP;
      this.languageVariables.wallGreensResponsibilitiesCvrdServices = data.wallGreensResponsibilitiesCvrdServicesSP;
      this.languageVariables.profJudgementHeading = data.profJudgementHeadingSP;
      this.languageVariables.profJudgementText = data.profJudgementTextSP;
      this.languageVariables.provHealthCrProfHdng = data.provHealthCrProfHdngSP;
      this.languageVariables.provHealthCrProfText = data.provHealthCrProfTextSP;
      this.languageVariables.clientsResponsibilites = data.clientsResponsibilitesSP;
      this.languageVariables.vouchersHeading = data.vouchersHeadingSP;
      this.languageVariables.vouchersText = data.vouchersTextSP;
      this.languageVariables.offSiteLocation = data.offSiteLocationSP;
      this.languageVariables.eligibleParticipants = data.eligibleParticipantsSP;
      this.languageVariables.important = data.importantSP;
      if (this.checkIfNonFluImmIsSelected()) {
        this.languageVariables.offSiteLocationText1 = String.Format(data.offSiteLocationText1SP, data.contractYearText1SP);
      } else {
        this.languageVariables.offSiteLocationText1 = String.Format(data.offSiteLocationText1SP, data.influenzaSeasonText1SP);
      }
      this.languageVariables.eligibleParticipantsText1 = data.eligibleParticipantsTextSP;
      this.languageVariables.siteMin = data.siteMinSP;
      this.languageVariables.siteAvg = data.siteAvgSP;
      if (this.checkIfNonFluImmIsSelected()) {
        this.languageVariables.siteMinText = String.Format(data.siteMinTextSP, data.contractYearText2SP, data.contractYearText2SP);
      } else {
        this.languageVariables.siteMinText = String.Format(data.siteMinTextfluSP, data.influenzaSeasonText2SP, data.influenzaSeasonText2SP);
      }
      this.languageVariables.siteAvgText = data.siteAvgTextSP;
      this.languageVariables.pmtAndBIlling = data.pmtAndBIllingSP;
      this.languageVariables.paymentHd = data.paymentSP;
      this.languageVariables.paymentText = data.paymentTextSP;
      this.languageVariables.latePayment = data.latePaymentSP;
      this.languageVariables.privateClinicFees = data.privateClinicFeesSP;
      this.languageVariables.privateClinicFeesText = data.privateClinicFeesTextSP;
      this.languageVariables.latePaymentText = data.latePaymentTextSP;
      this.languageVariables.costIncrease = data.costIncreaseSP; 
      this.languageVariables.costIncreasetext = data.costIncreasetextSP; 
      this.languageVariables.termAndTermCaps = data.termAndTermCapsSP;
      this.languageVariables.termAndTerm = data.termAndTermSP;
      this.languageVariables.termAndTermText = data.termAndTermTextSP;
      this.languageVariables.effectAndTerminiation = data.effectAndTerminiationSP;
      this.languageVariables.effectAndTerminiationText = data.effectAndTerminiationTextSP;
      this.languageVariables.waiver = data.waiverSP;
      this.languageVariables.waiverText = data.waiverTextSP;
      this.languageVariables.insurAndIndemnify = data.insurAndIndemnifySP;
      this.languageVariables.insurance = data.insuranceSP;
      this.languageVariables.insuranceText = data.insuranceTextSP;
      this.languageVariables.indemnification = data.indemnificationSP;
      let indemnificationTAndCChangedDate = new Date(data.indemnificationTAndCChangedDate);
      let universalContractReleasedate = data.universalContractReleaseDate ? new Date(data.universalContractReleaseDate) : null;
      let dateApproved = null;
      if (this.clinicAgreement && this.clinicAgreement.dateApproved) {
        dateApproved = new Date(this.clinicAgreement.dateApproved);
      }
      if (this.isCovidVaccineAvailable == true && (dateApproved === null || dateApproved >= indemnificationTAndCChangedDate)) {
        this.languageVariables.indemnificationText = data.indemnificationTextSP;
      }
      else {
        this.languageVariables.indemnificationText = data.indemnificationTextSP;
      }
      this.languageVariables.generalTerms = data.generalTermsSP;
      this.languageVariables.confidentiality = data.confidentialitySP;
      this.languageVariables.confidentialityP1 = data.confidentialityP1SP;
      this.languageVariables.businessConfidentiality = data.businessConfidentialitySP;
      this.languageVariables.businessConfidentialityText = data.businessConfidentialityTextSP;
      this.languageVariables.phi = data.phiSP;
      this.languageVariables.phiAb = data.phiAbSP;
      this.languageVariables.confidentialityP2 = data.confidentialityP2SP;
      this.languageVariables.confidentialityUniversalText = data.confidentialityTextSP;

      this.languageVariables.Advertising = data.AdvertisingSP;
      this.languageVariables.AdvertisingText = data.AdvertisingTextSP;
      this.languageVariables.frcMaj = data.frcMajSP;
      this.languageVariables.frcMajText = data.frcMajTextSP;
      this.languageVariables.compliance = data.complianceSP;
      this.languageVariables.complianceText = data.complianceTextSP;
      this.languageVariables.assignment = data.assignmentSP;
      this.languageVariables.assignmentText = data.assignmentTextSP;
      this.languageVariables.notices = data.noticesSP;
      this.languageVariables.noticesText = data.noticesTextSP;
      this.languageVariables.entireAgr = data.entireAgrSP;
      this.languageVariables.entireAgrText = data.entireAgrTextSP;
      this.languageVariables.counterPart = data.counterPartsHeadingSP;
      this.languageVariables.counterPartsText = data.counterPartsTextSP;

      this.languageVariables.rights = data.rightsSP;
      this.languageVariables.approve = data.approveSP;
      this.languageVariables.reject = data.rejectSP;
      this.languageVariables.submit = data.submitSP;
      this.languageVariables.cancel = data.cancelSP;
      this.languageVariables.printAgreementTxt = data.printAgreementSP;
      this.languageVariables.clinicFee = data.clinicFee;
      this.languageVariables.clinicLocation = data.clinicLocationSP;
      this.languageVariables.location = data.locationSP;
      this.languageVariables.time = data.timeSP;
      this.languageVariables.clinicDate = data.clinicDateSP;
      this.languageVariables.clinicTime = data.clinicTimeSP;
      this.languageVariables.contact = data.contactSP;
      this.languageVariables.phone = data.phoneSP;
      this.languageVariables.email = data.emailSP;
      this.languageVariables.emailAgrmntTo = data.emailAgreementToSP;

      this.multipleEmailAddrss = data.multipleEmailAddressSP;
      this.languageVariables.sendAgreeMnt = data.sendAgreementSP;
      this.editAgreeMnt = data.editAgreementSP;
      this.saveAgreeMnt = data.saveAgreementSP;
      this.cancelTxt = data.cancelSP;
      this.noteTxt = data.noteTextSP;
      this.table1 = data.table1SP;
      this.attn = this.attentionTo;
      this.fluExpiryDateTxt = data.fluExpiryDateSP;
      this.routineExpiryDateTxt = data.routineExpiryDateSP;
      this.estShots = data.estShotsSP;
      this.sendInvoiceTo = data.sendInvoiceToSP;
      this.isTaxExempt = data.isTaxExemptSP;
      this.isCopay = data.isCopaySP;
      this.voucherNeeded = data.voucherNeededSP;
      this.voucherExpiry = data.voucherExpirySP;
      this.copayValue = data.copayValueSP;
    }
  }
  languageChangeToEnglish() {
    
    this.showBtnSp = true;
    this.showBtnEn = false;
    this.splanguageSelection = false;
    this.enlanguageSelection = true;
    let contractdateApproved = null;
    if (this.clinicAgreement && this.clinicAgreement.dateApproved) {
      contractdateApproved = new Date(this.clinicAgreement.dateApproved);
      let uniContractReleaseDate: Date = new Date(universalContractReleaseDate);
      if (contractdateApproved < uniContractReleaseDate) {
        this.showPreUniversalContract = true;
      }
    }
    else if ((this.clinicAgreement && this.clinicAgreement.dateCreated)) {
      contractdateApproved = new Date(this.clinicAgreement.dateCreated);
      let uniContractReleaseDate: Date = new Date(universalContractReleaseDate);
      if (contractdateApproved < uniContractReleaseDate) {
        this.showPreUniversalContract = true;
      }
    }
    let data: any = this._apiservice.languageChange(2022,this.isCovidVaccineAvailable, !this.showPreUniversalContract, this.showPreUniversalContract);
    if (data) {
      let uniContractReleaseDate: Date = new Date(data.universalContractReleaseDate);
      this.englishLanguage = data.english;
      //this.enheader = data.englishheader;
      this.enattachment = data.englishattachment;
      this.imz = data.imzen;
      this.payment = data.payen;
      this.rates = data.ratesen;

      this.ImzVcnName = data.ImzVcnNameSp;
      this.PaymtBillMethod = data.PaymtBillMethodSp;
      this.VoucherRates = data.VoucherRatesSp;
      this.OffSiteClinicRates = data.OffSiteClinicRatesSp;

      this.languageVariables.thirdPartyRights = data.thirdPartyRightsEN;
      this.languageVariables.thirdPartyRightsText = data.thirdPartyRightsTextEN;

      this.languageVariables.businessName = data.clienten;
      this.languageVariables.name = data.nameen;
      this.languageVariables.eSignature = data.eSignatureEN;
      this.languageVariables.date = data.dateEN;
      this.languageVariables.district = data.districtEN;

      this.languageVariables.title = data.titleen;
      this.attentionTo = data.attentionen;
      this.languageVariables.address1 = data.address1en;
      this.languageVariables.address2 = data.address2en;
      this.languageVariables.city = data.cityen;
      this.languageVariables.state = data.stateen;
      this.languageVariables.zip = data.zipcodeen;
      //this.legal_notice = data.legalsp;
      //this.client_info = data.clientinfosp;
      this.languageVariables.approveAgrmnt = data.approveAgrmntEN;
      this.languageVariables.approveText = data.approveTextEN;
      this.languageVariables.electrSign = data.electrSignEN;
      this.languageVariables.notes = data.notesEN;
      this.languageVariables.pageTitle = data.pageTitleEN;
      this.languageVariables.email = data.emailEN;
      this.languageVariables.dose = data.doseEN;

      this.languageVariables.inWitnessWhereOff = data.inWitnessWhereOffEN;
      this.languageVariables.inWitnessWhereOff2ndLine = data.inWitnessWhereOff2ndLineEN;
      this.languageVariables.sendLegalNoticeToClient = data.sendLegalNoticeToClientEN;
      this.languageVariables.sendLegalNoticeToWalgreens = data.sendLegalNoticeToWalgreensEN;
      this.languageVariables.wallGreensCommOffsiteTitle1 = data.wallGreensCommOffsiteTitle1EN
      this.languageVariables.wallGreensCommOffsiteTitle2 = data.wallGreensCommOffsiteTitle2EN;
      this.languageVariables.wallGreensResponsibilities = data.wallGreensResponsibilitiesEN;
      this.languageVariables.wallGreensResponsibilitiesPara1Heading = data.wallGreensResponsibilitiesPara1HeadingEN;
      this.languageVariables.wallGreensResponsibilitiesCvrdServices = data.wallGreensResponsibilitiesCvrdServicesEN;
      this.languageVariables.wallGreensResponsibilitiesCvrdServices = data.wallGreensResponsibilitiesCvrdServicesEN;
      this.languageVariables.profJudgementHeading = data.profJudgementHeadingEN;
      this.languageVariables.profJudgementText = data.profJudgementTextEN;
      this.languageVariables.provHealthCrProfHdng = data.provHealthCrProfHdngEN;
      this.languageVariables.provHealthCrProfText = data.provHealthCrProfTextEN;
      this.languageVariables.clientsResponsibilites = data.clientsResponsibilitesEN;
      this.languageVariables.vouchersHeading = data.vouchersHeadingEN;
      this.languageVariables.vouchersText = data.vouchersTextEN;
      this.languageVariables.offSiteLocation = data.offSiteLocationEN;
      this.languageVariables.eligibleParticipants = data.eligibleParticipantsEN;
      this.languageVariables.important = data.importantEN;
      if (this.checkIfNonFluImmIsSelected()) {
        this.languageVariables.offSiteLocationText1 = String.Format(data.offSiteLocationText1EN, data.contractYearText1EN);
      } else {
        this.languageVariables.offSiteLocationText1 = String.Format(data.offSiteLocationText1EN, data.influenzaSeasonText1EN);
      }
      this.languageVariables.eligibleParticipantsText1 = data.eligibleParticipantsTextEN;
      this.languageVariables.siteMin = data.siteMinEN;
      this.languageVariables.siteAvg = data.siteAvgEN;
      if (this.checkIfNonFluImmIsSelected()) {
        this.languageVariables.siteMinText = String.Format(data.siteMinTextEN, data.contractYearText2EN, data.contractYearText2EN);
      } else {
        this.languageVariables.siteMinText = String.Format(data.siteMinTextfluEN, data.influenzaSeasonText2EN, data.influenzaSeasonText2EN);
      }
      this.languageVariables.siteAvgText = data.siteAvgTextEN;
      this.languageVariables.pmtAndBIlling = data.pmtAndBIllingEN;
      this.languageVariables.paymentHd = data.paymentEN;
      this.languageVariables.singleStarTAndC = data.singleStarTAndC;
      this.languageVariables.singleStarTAndC2 = data.singleStarTAndC2;
      this.languageVariables.article2Text = data.article2Text;
      if (this.checkIfCovidImmForCorpInvoiceSelected()) {
        this.enheader = this.showPreUniversalContract ? data.directBillEnglishheader : data.englishheader;
        this.languageVariables.paymentText = !this.showPreUniversalContract ? data.paymentTextEN : data.paymentTextDirectBillEN;
      }
      else {
        this.enheader = data.englishheader;
        this.languageVariables.paymentText = data.paymentTextEN;
      }
      this.languageVariables.privateClinicFees = data.privateClinicFeesEN;
      this.languageVariables.privateClinicFeesText = data.privateClinicFeesTextEN;
      this.languageVariables.latePayment = data.latePaymentEN;
      this.languageVariables.latePaymentText = data.latePaymentTextEN;
      this.languageVariables.costIncrease = data.costIncreaseEN; 
      this.languageVariables.costIncreasetext = data.costIncreasetextEN; 
      this.languageVariables.termAndTermCaps = data.termAndTermCapsEN;
      this.languageVariables.termAndTerm = data.termAndTermEN;
      this.languageVariables.termAndTermText = data.termAndTermTextEN;
      this.languageVariables.effectAndTerminiation = data.effectAndTerminiationEN;
      this.languageVariables.effectAndTerminiationText = data.effectAndTerminiationTextEN;
      this.languageVariables.waiver = data.waiverEN;
      this.languageVariables.waiverText = data.waiverTextEN;
      this.languageVariables.insurAndIndemnify = data.insurAndIndemnifyEN;
      this.languageVariables.insurance = data.insuranceEN;
      this.languageVariables.insuranceText = data.insuranceTextEN;
      this.languageVariables.indemnification = data.indemnificationEN;
      let indemnificationTAndCChangedDate = new Date(data.indemnificationTAndCChangedDate);
      let dateApproved = null;
      if (this.clinicAgreement && this.clinicAgreement.dateApproved) {
        dateApproved = new Date(this.clinicAgreement.dateApproved);
      }
      if (this.isCovidVaccineAvailable == true && (dateApproved === null || dateApproved >= indemnificationTAndCChangedDate)) {
        this.languageVariables.indemnificationText = data.indemnificationTextEN;
      }
      else {
        this.languageVariables.indemnificationText = data.indemnificationTextEN;
      }
      this.languageVariables.generalTerms = data.generalTermsEN;
      this.languageVariables.confidentiality = data.confidentialityEN;
      this.languageVariables.confidentialityP1 = data.confidentialityP1EN;
      this.languageVariables.businessConfidentiality = data.businessConfidentialityEN;
      this.languageVariables.businessConfidentialityText = data.businessConfidentialityTextEN;
      this.languageVariables.phi = data.phiEN;
      this.languageVariables.phiAb = data.phiAbEN;
      this.languageVariables.confidentialityP2 = data.confidentialityP2EN;
      this.languageVariables.confidentialityUniversalText = data.confidentialityTextEN;
      this.languageVariables.Advertising = data.AdvertisingEN;
      this.languageVariables.AdvertisingText = data.AdvertisingTextEN;
      this.languageVariables.frcMaj = data.frcMajEN;
      this.languageVariables.frcMajText = data.frcMajTextEN;
      this.languageVariables.compliance = data.complianceEN;
      this.languageVariables.complianceText = data.complianceTextEN;
      this.languageVariables.assignment = data.assignmentEN;
      this.languageVariables.assignmentText = data.assignmentTextEN;
      this.languageVariables.notices = data.noticesEN;
      this.languageVariables.noticesText = data.noticesTextEN;
      this.languageVariables.entireAgr = data.entireAgrEN;
      this.languageVariables.entireAgrText = data.entireAgrTextEN;
      this.languageVariables.counterPart = data.counterPartsHeadingEN;
      this.languageVariables.counterPartsText = data.counterPartsTextEN;

      this.languageVariables.rights = data.rightsEN;
      this.languageVariables.approve = data.approveEN;
      this.languageVariables.reject = data.rejectEN;
      this.languageVariables.submit = data.submitEN;
      this.languageVariables.cancel = data.cancelEN;
      this.languageVariables.printAgreementTxt = data.printAgreementEN;

      this.languageVariables.clinicLocation = data.clinicLocationEN;
      this.languageVariables.location = data.locationEN;
      this.languageVariables.time = data.timeEN;
      this.languageVariables.clinicDate = data.clinicDateEN;
      this.languageVariables.clinicTime = data.clinicTimeEN;
      this.languageVariables.contact = data.contactEN;
      this.languageVariables.phone = data.phoneEN;
      this.languageVariables.emailAgrmntTo = data.emailAgreementToEN;
      this.languageVariables.clinicFee = data.clinicFee;
      this.multipleEmailAddrss = data.multipleEmailAddressEN;
      this.languageVariables.sendAgreeMnt = data.sendAgreementEN;
      this.editAgreeMnt = data.editAgreementEN;
      this.saveAgreeMnt = data.saveAgreementEN;
      this.cancelTxt = data.cancelEN;
      this.noteTxt = data.noteTextEN;
      this.table1 = data.table1EN;
      this.attn = data.attnEN;
      this.fluExpiryDateTxt = data.fluExpiryDateEN;
      this.routineExpiryDateTxt = data.routineExpiryDateEN;
      this.estShots = data.estShotsEN;
      this.sendInvoiceTo = data.sendInvoiceToEN;
      this.isTaxExempt = data.isTaxExemptEN;
      this.isCopay = data.isCopayEN;
      this.voucherNeeded = data.voucherNeededEN;
      this.voucherExpiry = data.voucherExpiryEN;
      this.copayValue = data.copayValueEN;

    }

    this.language = "TRANSLATE TO ENGLISH";
  }
  public GetClinicInformation() {
    this.clinicInformation = this.clinicAgreement.clinicList;
    this.clinicInformation.forEach(cl => {
      if (cl.clinicDate) {
        let dateString = cl.clinicDate.substring(0, cl.clinicDate.indexOf('T'));
        let dateSplitTokens = dateString.split('-');
        var dt = new Date(dateSplitTokens[0], dateSplitTokens[1] - 1, dateSplitTokens[2], 18, 30, 30);
        cl.clinicDate = dt;
      }
    });
  }

  public GetImmunizationformation() {
    for (let i = 0; i < this.clinicAgreement.clinicList.length; i++) {
      this.immunizationInformation = this.clinicAgreement.clinicList[i].clinicImzQtyList;
    }
    this.isCovidVaccineAvailable = this.clinicAgreement.clinicList[0].clinicImzQtyList.filter(p => p.immunizationName.search(/COVID19/gi) >= 0).length > 0;
  }

  public GetClientAndLegalInformation() {
    
    if (this.clinicAgreement.clientInfo === null || this.clinicAgreement.clientInfo === undefined) {
      this.clientInformation = new Client();
    }
    if (this.clinicAgreement.clientInfo !== null && this.clinicAgreement.clientInfo !== undefined) {
      this.clientInformation = this.clinicAgreement.clientInfo;

      if (this.clinicAgreement.clientInfo.reviewedDate === null || this.clinicAgreement.clientInfo.reviewedDate === undefined) {
        let today = new Date();
        this.clientInformation = new Client();
        this.clientInformation.reviewedDate = this._datePipe.transform(today, "MM/dd/yyyy");
      }
    }
    if (this.clinicAgreement.legalNoticeAddressInfo === null || this.clinicAgreement.legalNoticeAddressInfo === undefined) {
      this.legalNoticeAddressInfo = new LegalNoticeAddress();
    }
    if (this.clinicAgreement.legalNoticeAddressInfo !== null && this.clinicAgreement.legalNoticeAddressInfo !== undefined) {
      this.legalNoticeAddressInfo = this.clinicAgreement.legalNoticeAddressInfo;
    }

  }

  public GetWalgreensCOformation() {
    this.walGreensInfo = this.clinicAgreement.walgreenCoInfo != null ? this.clinicAgreement.walgreenCoInfo : new WalgreenCo();

  }

  thankYou() {
    this.router.navigate(['/thankyou']);
  }

  printAgreement() {
    this.utility.print("approveContract", "Walgreens Community Off-site Agreement");
  }
  changeLanguage() {
    if (this.currentLanguage.toLowerCase() === 'spanish') {
      this.languageChangeToSpanish();
    } else {
      this.languageChangeToEnglish();
    }
  }
  cancelView() {
    if (this.from_email) {
      this.router.navigate(['/communityoutreach/storehome']);
    }
    else {
      this._location.back();
    }
  }

  checkIfAnyImmunizationsHasCorpInvoiceAndVoucherNeeded(location: Clinic): boolean {
    return this._apiservice.checkIfAnyImmunizationsHasCorpInvoiceAndVoucherNeeded(location) && Number(location.isNoClinic) >= 1;
  }
  checkIfFluImmForCorpInvoiceSelected(): boolean {
    return this._apiservice.checkIfFluImmForCorpInvoiceSelected();
  }
  checkIfNonFluImmForCorpInvoiceSelected(): boolean {
    return this._apiservice.checkIfNonFluImmForCorpInvoiceSelected();
  }
  getVoucherExpiryDate(clinicLoc: Clinic): string {
    let vchrDate: Date;
    this.userRecords.forEach(imz => {
      if (Number(imz.isVoucherNeeded) >= 1 && Number(imz.paymentTypeId) == 6 &&
        (imz.sendInvoiceTo === '1' || Number(imz.sendInvoiceTo) === 1) && imz.immunizationName.toLowerCase().search('flu') !== -1)
        vchrDate = new Date(imz.voucherExpirationDate);
      return vchrDate;
    });
    return this._datePipe.transform(vchrDate, 'MM/dd/yyyy');
  }
  getRoutineExpiryDate(clinicLoc: Clinic): string {
    let vchrDate: Date;
    this.userRecords.forEach(imz => {
      if (Number(imz.isVoucherNeeded) >= 1 && Number(imz.paymentTypeId) == 6 &&
        (imz.sendInvoiceTo === '1' || Number(imz.sendInvoiceTo) === 1) && imz.immunizationName.toLowerCase().search('flu') == -1)
        vchrDate = new Date(imz.voucherExpirationDate);
      return vchrDate;
    });
    return this._datePipe.transform(vchrDate, 'MM/dd/yyyy');
  }
  okClicked() {
    this.display = false;
  }
  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = "Contract Alert";
    this.display = true;
  }

  sendEmail() {
    this.approveContract.controls['email'].setValidators([Validators.required]);
    this.approveContract.controls['email'].updateValueAndValidity();
    if (this.approveContract.valid) {

      if (!this.utility.validateEmail(this.approveContract.value.email)) {
        let sumMsg = ErrorMessages['errMsg'];
        let sMsg = ErrorMessages['emailList'];
        this.showDialogForMail(sumMsg, sMsg, false);
        return;
      }
      this.spinner.show();

      this.clinicAgreement.businessUserEmails = this.approveContract.value.email;
      this.clinicAgreement.contractPostedByUser = 'Walgreens User'
      this.makeValidBooleans();
      this._apiservice.saveAgreementData(SessionDetails.GetStoreId(), 1, this.clinicAgreement).subscribe((data: any) => {
        this.spinner.hide();
        switch (data.errorS.errorCode) {
          case '0':
          case 0:
            this.showDialogForMail(ErrorMessages['contract'], ErrorMessages['contractEmailSent'], true);
            break;
          case -4:
          case '-4':
            this.showDialogForMail(ErrorMessages['contractAlert'], data.errorS.errorMessage, false);
            break;
          default:
            this.showDialogForMail(ErrorMessages['contract'], data.errorS.errorMessage, false);
            break;
        }

      },
        err => {
          this.spinner.hide();
          switch (err) {
            case 500:
              this.showDialogForMail(ErrorMessages['serverError'], err.json().Message, false);
              break;
            case 400:
              this.showDialogForMail(ErrorMessages['clientError'], err.json().Message, false);
              break;
            default:
              this.showDialogForMail(ErrorMessages['serverError'], err.json().Message, false);
              break;
          }

        });
    }
    else {
      this.utility.validateAllFormFields(this.approveContract);
    }

  }

  makeValidBooleans() {
    this.clinicAgreement.clinicImmunizationList.forEach(im => {
      if (im.sendInvoiceTo <= 0) {
        im.isTaxExempt = null;
        im.isCopay = null;
        im.isVoucherNeeded = null;
      } else {
        im.isTaxExempt >= 1 ? im.isTaxExempt = 1 : im.isTaxExempt = 0;
        im.isCopay >= 1 ? im.isCopay = 1 : im.isCopay = 0;
        im.isVoucherNeeded >= 1 ? im.isVoucherNeeded = 1 : im.isVoucherNeeded = 0;
        im.copayValue = +im.copayValue;
        if (im.voucherExpirationDate !== null && im.voucherExpirationDate !== undefined && im.voucherExpirationDate !== '') {
          im.voucherExpirationDate = this._datePipe.transform(im.voucherExpirationDate, "MM/dd/yyyy");
        }
      }
    });
    this.clinicAgreement.clinicList.forEach(cl => {
      cl.isNoClinic >= 1 ? cl.isNoClinic = 1 : cl.isNoClinic = 0;
      cl.isReassign >= 1 ? cl.isReassign = 1 : cl.isReassign = 0;
      cl.isCurrent >= 1 ? cl.isCurrent = 1 : cl.isCurrent = 0;

      if (cl.clinicDate === null || cl.clinicDate === undefined || cl.clinicDate === '') {
        cl.clinicDate = null;
      } else {
        cl.clinicDate = this._datePipe.transform(cl.clinicDate, "MM/dd/yyyy");
      }
    });
  }

  showDialogForMail(msgSummary: string, msgDetail: string, success_message: boolean) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
    this.success_message = success_message;
  }

  isFieldValid(field: string) {
    return !this.approveContract.get(field).valid && this.approveContract.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  showCancelBtn() {
    return this._apiservice.getShowViewCancelBtn();
  }
  checkIfNonFluImmIsSelected(): boolean {

    let added_immunizations = this.clinicAgreement.clinicImmunizationList
    try {
      added_immunizations.forEach(rec => {
        if (rec.immunizationName.toLowerCase().search('flu') === -1) {
          throw true;
        }
      });
    } catch (e) {
      if (e === true)
        return true;
    }
    return false;
  }
  checkIfCovidImmForCorpInvoiceSelected() {

    let added_immunizations = this.clinicAgreement.clinicImmunizationList;
    try {
      added_immunizations.forEach(rec => {
        if (rec.paymentTypeId == '6'
          && rec.immunizationName.toLowerCase().search('flu') == -1 && rec.immunizationName.toLowerCase().search('covid') !== -1) {
          throw true;
          // && (rec.sendInvoiceTo === 1 || Number(rec.sendInvoiceTo) === 1) &&
          // (rec.isVoucherNeeded === 1 || Number(rec.isVoucherNeeded) === 1)
        }
        // else if(rec.paymentTypeId==6 && rec.sendInvoiceTo===true && rec.immunizationName.toLowerCase().search('flu') == -1){
        //     this.hasNonFluImmSelected = true;
        // }
      });
    } catch (e) {
      if (e === true)
        return true;
    }
    return false;
  }
  thankYouPage() {
    let responseClinicAgreement: any = this.clinicAgreement;
    let corporateInvoice: any = false;
    let submitInsurance: any = false;
    let voucherNeeded: any = false;

    for (var i = 0; i < responseClinicAgreement.clinicImmunizationList.length; i++) {
      if (responseClinicAgreement.clinicImmunizationList[i].paymentTypeId == 6) {
        corporateInvoice = true;
      }
      if (responseClinicAgreement.clinicImmunizationList[i].paymentTypeId == 8) {
        submitInsurance = true;
      }
      if (responseClinicAgreement.clinicImmunizationList[i].paymentTypeId == 9) {
        submitInsurance = true;
      }
    }
    var thankuData = new ThankyouData();
    thankuData.hasPrefilledForms = responseClinicAgreement.contractThankYou.hasPrefilledForms;
    thankuData.storeState = responseClinicAgreement.contractThankYou.storeState;
    thankuData.pdfVarFormEn = responseClinicAgreement.contractThankYou.pdfVarFormEn;
    thankuData.pdfVarFormEsp = responseClinicAgreement.contractThankYou.pdfVarFormEsp;
    thankuData.voucherNeeded = voucherNeeded;//Need to check
    thankuData.isRestrictedState = false;//need to check
    thankuData.corporateInvoice = corporateInvoice;
    thankuData.submitInsurance = submitInsurance;
    thankuData.isCovidClinic = this.isCovidVaccineAvailable;
    thankuData.printContents = document.getElementById('approveContract').innerHTML;
    thankuData.isCovidOpportunity = this.isCovidVaccineAvailable;
    thankuData.covidOpportunityType = this.covidOpportunityType;
    SessionDetails.setThankUDetails(thankuData);
    this.router.navigate(['/thankyou']);
  }
  checkToPrintAsterisk(immAbvName: string, immName: string): boolean {
    let bRet: boolean = false;
    if (immAbvName && immAbvName.search(/Two Dose/gi) >= 0) {
      bRet = true;
    }
    return bRet;
  }
  checkForDoubleAsteriskRates(): boolean {
    return this.clinicAgreement.clinicImmunizationList.filter(p => p.immunizationName.search(/Dose/gi) >= 0).length > 0;
  }

  getCovidExpiryDate(): string {
    let vchrDate: Date;
    this.userRecords.forEach(imz => {
      if (Number(imz.isVoucherNeeded) >= 1 && Number(imz.paymentTypeId) == 6 &&
        (imz.sendInvoiceTo === '1' || Number(imz.sendInvoiceTo) === 1) && imz.immunizationName.toLowerCase().search('flu') == -1 && imz.immunizationName.toLowerCase().search('covid') !== -1)
        vchrDate = new Date(imz.voucherExpirationDate);
      return vchrDate;
    });
    return this._datePipe.transform(vchrDate, 'MM/dd/yyyy');
  }
}
