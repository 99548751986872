export class SrEventScheduledDetails {
    dateOfEvent: any;
    eventStartTime: string;
    eventEndTime: string;
    eventTypeId: number;
    eventDescription: string;
    walgreensRoleId: number;
    walgreensRoleDescription: string;
    numberOfAttendes: number;
    refreshments: string;
    fundingId: string;
    provideEducationalMaterial: boolean;
    description: string;
    totalCost: string;
    eventContactLogDate: Date;
}
export class LogOutreachStatus {
    businessPk: number;
    firstName: string;
    lastName: string;
    jobTitle: string;
    outreachProgram: string;
    outreachBusinessPk: number;
    contactDate: Date;
    outreachStatusId: number;
    outreachStatusTitle: string;
    Disposition:string;
    feedback: string;
    createdBy: number;
    clinicAgreementPk: number;
    contactLogPk : number;
    industry: string;
    other: string;
}

export class FollowUpDetails {
    emailTo: string;
    followupDate: any;
    isSendEmailReminder: boolean;
    createdBy: number;
}

export class ErrorS {
    errorCode: number;
    errorMessage: string;
}

export class FollowUp {
    logOutreachStatus: LogOutreachStatus;
    followUpDetails: FollowUpDetails;
    srEventScheduledDetails: SrEventScheduledDetails;
    errorS: ErrorS;
    constructor() {
        this.logOutreachStatus = new LogOutreachStatus();
        this.followUpDetails = new FollowUpDetails();
        this.srEventScheduledDetails=new SrEventScheduledDetails();
        
    }
}