import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GroupIdAssignmentReportService } from '../../services/groupidassignmentreport.services';
import * as appSettings from '../../globals';
import { ApplicationSettings } from '../../../common/application-settings';
import { saveAs as importedSaveAs } from "file-saver";
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-covid-groupidassignmentreport',
  templateUrl: './covid-groupidassignmentreport.component.html',
  styleUrls: ['./covid-groupidassignmentreport.component.css']
})
export class CovidGroupidassignmentreportComponent implements OnInit {
  GroupidassignmentreportForm: FormGroup;
  GroupidassignmentreportArray: any;
  ddlSeason: number;
  private isCurrentSeason = 1;
  ddlFormat: any;
  filterByDate: boolean = false;
  fromDate: Date;
  toDate: Date;
  minDate: Date;
  maxDate: Date;
  outreachStartDate = ApplicationSettings.outreachStartDateCovid;
  //groupIdLaunchDate = "05/01/2018";
  private from_date: string;
  private to_date: string;

  totalRecords: number;
  loading: boolean;
  private currentSeason = appSettings.covidSeason;
  seasons: any = [];
  canExport: boolean = true;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";

  showGrid: boolean = false;
  covidOnly: boolean = true;
  constructor(
    private fb: FormBuilder,
    private groupidassignmentreport: GroupIdAssignmentReportService,
   /* private logger: NGXLogger,*/ private spinner: NgxSpinnerService) {
  }
  ngOnInit() {
    for (let report_year: number = (new Date(this.outreachStartDate)).getFullYear(); report_year >=parseInt(appSettings.covidReportStartYear) ; report_year--){
      this.seasons.push({
        "yearName": report_year.toString() + "-" + (report_year + 1).toString(),
        "yearCode": report_year.toString()
      });
    }
    this.ddlSeason = (new Date(this.outreachStartDate)).getFullYear();

    this.ddlFormat = "0";
    this.filterByDate = false;
    this.setFormControls();
    this.setDates();
    this.fromDate = this.minDate;
    this.toDate = this.maxDate;


    //this.generateReport();
  }
  setFormControls() {
    this.GroupidassignmentreportForm = this.fb.group({
      'chkFilterByDate': this.filterByDate,
      'fDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'tDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
      'ddlSeason': [this.ddlSeason],
      'ddlFormat': ["0"],
      'covidOnly': [true]
    });
  }

  generateReport(): void {
    
    if (!this.showGrid) {
      this.spinner.show();
    }
    else {
      this.loading = true;
    }
    let data = this.GroupidassignmentreportForm.value;
    let d1 = data.fDate;
    let d2 = data.tDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    // this.logger.log(this.from_date);
    //this.logger.log(this.to_date);

    if (this.ddlSeason != this.currentSeason) {
      this.isCurrentSeason = 0;
    }
    this.groupidassignmentreport.getGroupidassignmentreportData(
      this.from_date,
      this.to_date, this.filterByDate, this.isCurrentSeason, this.ddlSeason, this.covidOnly).subscribe((resultArray: any) => {
        this.totalRecords = resultArray.length;
        // this.logger.log("totalRecords:" + this.totalRecords);
        this.GroupidassignmentreportArray = resultArray;
        // this.logger.log("this.GroupidassignmentreportArray:" + this.GroupidassignmentreportArray.length);
        setTimeout(() => {
          this.loading = false;
          this.spinner.hide();
          this.showGrid = true;
          this.canExport = true;
        }, 1000)
      }, error => {
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
        this.spinner.hide();
        this.GroupidassignmentreportArray = [];
        this.totalRecords = 0;
      });
    this.canExport = true;
  }
  generateReportFormat(): void {
    
    this.loading = true;
    this.groupidassignmentreport.exportReportData(this.from_date,
      this.to_date, this.filterByDate, this.isCurrentSeason, this.ddlSeason, "GroupIdAssignment", "xlsxv", this.covidOnly).subscribe((res: any) => {
        importedSaveAs(res.body, "GroupIdAssignment.xlsx");
        this.loading = false;
      }, error => {
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
      });
  }
  setDates() {
    if (this != undefined) {
      this.ddlSeason = this.GroupidassignmentreportForm.controls['ddlSeason'].value;
    }
    let tempMinDate: Date;
    let tempMaxDate: Date;
    tempMinDate = new Date(ApplicationSettings.getCovidStartDate(this.ddlSeason));
    tempMaxDate = new Date(ApplicationSettings.getCovidEndDate(this.ddlSeason));
    this.minDate = new Date(tempMinDate);
    this.maxDate = new Date(tempMaxDate)
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    this.canExport = false;

    this.isCurrentSeason = (this.ddlSeason != this.currentSeason) ? 0 : 1;
    if (this.GroupidassignmentreportForm != undefined) {
      this.GroupidassignmentreportForm.controls['fDate'].setValue(this.fromDate);
      this.GroupidassignmentreportForm.controls['tDate'].setValue(this.toDate);
    }
  }
  setCalendarDates() {
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    this.canExport = false;
  }
  reset(): void {
    //this.loading = true;
    this.showGrid = false;
    this.ddlSeason = this.currentSeason;
    this.ddlFormat = 0;
    this.setDates();
    this, this.covidOnly = true;
    //this.generateReport();
  }
  validateExportType() {
    this.ddlFormat = this.GroupidassignmentreportForm.controls['ddlFormat'].value;
    if (this.ddlFormat == "0" || !this.canExport/*==false*/)
      return true;
    else
      return false;
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
