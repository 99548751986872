import { Component, OnInit } from '@angular/core';
import { saveAs as importedSaveAs } from "file-saver";
import {  FormGroup,  FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RestrictedClinicsAndOpportunitiesReport } from '../../../../models/RestrictedClinicsAndOpportunitiesReport';
import { RestrictedstateclinicsService } from '../../services/restrictedstateclinics.service';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from "ngx-spinner";
import { throws } from 'assert';

@Component({
  selector: 'app-restrictedstateclinics',
  templateUrl: './restrictedstateclinics.component.html',
  styleUrls: ['./restrictedstateclinics.component.css']
})
export class RestrictedstateclinicsComponent implements OnInit {
  restrictedClinicsAndOpportunitiesArray:any;
  restrictedClinicsAndOpportunitiesForm:FormGroup;
  ddlFormat:any;
  ddlState:any;
  fromDate:any;
  toDate:any;
  from_date:any;
  to_date:any;
  maxDate:Date;
  minDate:Date;
  state:any;
  datasource:any;
  reportType: string = "";
  loading:boolean=false;
  canExport:boolean=true;
  totalRecords:number=0;
  reportName:any;
  filterByDate:boolean=false;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  showGrid:boolean=false;
  constructor(private fb: FormBuilder,private router: Router, private restricted_state_service:RestrictedstateclinicsService, private spinner: NgxSpinnerService) {       
      if (this.router.url.indexOf('clinics') > -1) {        
        this.reportType = "clinics";
      } else {        
        this.reportType = "opportunities";
      }
    }

  ngOnInit() {
    this.setDates();
    this.setFormControls();
    //this.generateReport();
  }

  setFormControls() {
    this.restrictedClinicsAndOpportunitiesForm = this.fb.group({
       'fDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
       'tDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
       'ddlState':["All"],
       'chkFilterByDate': [false],
       'ddlFormat': ["0"]
     });
     }
   
  setDates() {
    let tempMinDate: Date;
    let tempMaxDate: Date;
    if(this.reportType == "clinics")
    {
      tempMinDate = new Date("05/01/2023");
    }
    else
    {
      tempMinDate = new Date("07/03/2019");
    } 
    if(this.reportType == "clinics")
    {
      tempMaxDate = new Date("04/30/2024");
    }
    else
    {
      tempMaxDate = new Date("04/30/2024");
    }    
    
    this.minDate = new Date(tempMinDate);
    this.maxDate = new Date(tempMaxDate)
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    this.canExport=false;
  }
 ddlStateDropdown(data){
   this.ddlState=data.target.value;
   //this.restrictedClinicsAndOpportunitiesForm.controls['ddlState'].setValue(data.target.value);
    }
  generateReport(){
    if(!this.showGrid){
      this.spinner.show();
    }else{
      this.loading = true;
    }
    let data = this.restrictedClinicsAndOpportunitiesForm.value;
    let d1 = data.fDate;
    let d2 = data.tDate;
    this.from_date = (d1.getMonth() + 1) + "/" + (d1.getDate()) + "/" + (d1.getFullYear());
    this.to_date = (d2.getMonth() + 1) + "/" + (d2.getDate()) + "/" + (d2.getFullYear());
    console.log(data);
    let restricted_clinics_oppurtunities = new RestrictedClinicsAndOpportunitiesReport(this.from_date, this.to_date, ((data.chkFilterByDate)?'1':'0'), data.ddlState,this.reportType,"","");
    this.restricted_state_service.getRestrictedClinicsOpportunitiesReportData(restricted_clinics_oppurtunities).subscribe((resultArray: any) => {
      this.datasource = resultArray;
      this.totalRecords = this.datasource.length;
      this.restrictedClinicsAndOpportunitiesArray = this.datasource;//.slice(0, 100);
      setTimeout(() => {
        this.spinner.hide();
        this.loading = false;
        this.canExport=true;
        this.showGrid = true;
      }, 1000)
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
      this.restrictedClinicsAndOpportunitiesArray = [];
      this.totalRecords = 0;
    });
    this.canExport=true;
  }
  generateReportFormat(){
    this.loading=true;
    if(this.reportType=="clinics"){
      this.reportName="RestrictedStateClinicsReport";
    }
    else{
      this.reportName="RestrictedStateOpportunitiesReport";
    }
    let data = this.restrictedClinicsAndOpportunitiesForm.value;

    let restricted_clinics_oppurtunities = new RestrictedClinicsAndOpportunitiesReport(this.from_date, this.to_date, ((data.chkFilterByDate)?'1':'0'), data.ddlState, this.reportType, this.reportName,"xlsxv");
    this.restricted_state_service.exportReportData(restricted_clinics_oppurtunities).subscribe((res:any) => {
      importedSaveAs(res.body, this.reportName+".xlsx");
      this.loading=false;
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
  canExportOnchange(){
    this.canExport=false;
  }
  onFilterByDateChanged(){
    this.canExport=false;
  }
  validateExportType(){
    if(this.ddlFormat=="0" || !this.canExport){
      return true;
    }
    else{
      return false;
    } 
  }
  reset() {
    this.showGrid = false;
    this.ddlState = "All";
    this.setDates();
    this.setFormControls();
    this.ddlFormat = 0;
    this.filterByDate = false;
  }
    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}
