import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { SessionDetails } from '../../../../utility/session';
import { ActionitemsandmetricsService } from '../../services/actionitemsandmetrics.service';
import { saveAs as importedSaveAs } from "file-saver";
import { Compiler } from '@angular/core';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { ErrorMessages } from "../../../../config-files/error-messages";

@Component({
  selector: 'app-hcs-actions-items-ip',
  templateUrl: './hcs-actions-items-ip.component.html',
  styleUrls: ['./hcs-actions-items-ip.component.css']
})
export class HcsActionsItemsIpComponent implements OnInit {
  @ViewChildren('column') columns: QueryList<any>; 
  actionItemsAndMetricArray: any;
  hcsActionItemsOrMetricsList: any;
  ddlReports: number;
  ddlFormat: string;
  loading: boolean = true;
  userProfile: any;
  totalRecords: number = 0;
  reportType: string = "";
  reportId: number;
  cols: any = [];
  canExport:any;  
  recordsCountText ="";
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  is_flu:boolean = false;

  constructor(private actionitems_metrics_service: ActionitemsandmetricsService,
    private message_service:MessageServiceService, private _compiler: Compiler) {
    this._compiler.clearCache();
    this.userProfile = SessionDetails.GetUserProfile();
    if (sessionStorage.actionItemOrMetricId) {
      let item_details = sessionStorage.actionItemOrMetricId.split(':');
      if (item_details.length > 0) {
        this.reportType = item_details[0];
        this.reportId = item_details[1];
      }
    }
    if (this.reportType == "actionitem") {
      if (SessionDetails.getHCSActionItemsList().length > 0) {
        this.hcsActionItemsOrMetricsList = SessionDetails.getHCSActionItemsList();
      }
    }
    else {
      if (SessionDetails.getHCSActionItemsList().length > 0) {
        this.hcsActionItemsOrMetricsList = SessionDetails.getHCSMetricsList();
        for (let i = 0; i < 5; i++) {
          let metric = this.hcsActionItemsOrMetricsList[i];
          var name = metric.metricName.split('Review ').join('Review COVID ');
          this.hcsActionItemsOrMetricsList.push({ covidCount: 0, metricCount: 0, metricDesc: '', metricId: metric.metricId+5, metricName: name })
        }
      }
    }
  }

  ngOnInit() {
    
    this.ddlFormat = "0";
    this.ddlReports = this.reportId
    this.generateReport();
  }

  reportChanged() {
    this.actionItemsAndMetricArray =[];
    this.recordsCountText="";
    this.reportId = this.ddlReports;
    this.message_service.setReportTitle(this.getTitleForDashboardReports());
    this.generateReport();
  }

  generateReport(): void {
    this.loading = true;
    if (this.reportType == "actionitem") {
      this.actionitems_metrics_service.getActionItemsReportData(this.userProfile.userPk, this.ddlReports.toString(), this.is_flu).subscribe((resultArray: any) => {
        this.totalRecords = resultArray.length;
        if(this.totalRecords > 0){
          this.recordsCountText = "There are " + this.totalRecords + " records.";
        }else{
          this.recordsCountText = "No records found."
        }
        this.actionItemsAndMetricArray = resultArray;
        this.columns.forEach(col => {
          col.hidden = (this.actionItemsAndMetricArray[0][col.field]);
        });
        setTimeout(() => {
          this.loading = false; 
        }, 1000)
      }  ,error =>{
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
        this.actionItemsAndMetricArray = [];
        this.totalRecords = 0;
      });
    } else if (this.reportType == "metric") {
      this.actionitems_metrics_service.getMetricsReportData(this.userProfile.userPk, this.ddlReports.toString(), false).subscribe((resultArray: any) => {
        this.totalRecords = resultArray.length;
        if(this.totalRecords > 0){
          this.recordsCountText = "There are " + this.totalRecords + " records.";
        }else{
          this.recordsCountText = "No records found."
        }
        this.actionItemsAndMetricArray = resultArray;
        this.columns.forEach(col => {
          col.hidden = (this.actionItemsAndMetricArray[0][col.field]);
        });
        setTimeout(() => {
          this.loading = false; 
        }, 1000)
      },error =>{
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
        this.actionItemsAndMetricArray = [];
        this.totalRecords = 0;
      });
    }
  }
  getTitleForDashboardReports() {
    var title = "";
    let rpt_id:string=this.reportId.toString();
    let report_type:string=this.reportType;
    
    switch (rpt_id) {
      case "1":
      title=((report_type=="actionitem")?"Corporate Clinics to be Reviewed/Assigned":"Review Clinics Completed to Date");
      break;
      case "2":
      title="Review Clinics Pending Signed Contract";
        break;
      case "3":
      title=((report_type=="actionitem")?"Local Leads to be Reviewed/Assigned":"Review Contracts Drafted but Not Sent");
      break;
      case "4":
      title=((report_type=="actionitem")?"Corporate Clinics to be Confirmed":"Review Clinics with Signed Contract to be Completed");
        break;
      case "5":
      title=((report_type=="actionitem")?"Corporate Clinics to be Completed":"Review Contracts with Requested Changes");
        break;
      case "6":
        title = ((report_type == "actionitem") ? "COVID Clinics to be Reviewed/Assigned" : "Review COVID Clinics Completed to Date");
        break;
      case "7":
        title = ((report_type == "actionitem") ? "COVID Clinics to be Confirmed" : "Review COVID Clinics Pending Signed Contract");
        break;
      case "8":
        title = ((report_type == "actionitem") ? "COVID Clinics to be Completed" : "Review COVID Contracts Drafted but Not Sent");
        break;
        case "9":
        title = ((report_type == "actionitem") ? "" : "Review COVID Clinics with Signed Contract to be Completed");
        break;
        case "10":
        title = ((report_type == "actionitem") ? "" : "Review COVID Contracts with Requested Changes");
        break;
      default:
        break;
    }
    return title.toUpperCase();
  }

  generatereportformat(): void {
    
    this.loading = true;
    let report_name: string = "";
    if (this.reportType == "actionitem") {
      switch (this.ddlReports.toString()) {
        case "1":
        case "6":
          report_name = "CorporateClinicsToBeReviewedAssigned"
          break;
        case "3":
          report_name = "LocalLeadsToBeReviewedAssigned"
          break;
        case "4":
        case "7":
          report_name = "CorporateClinicsToBeConfirmed"
          break;
        case "5":
        case "8":
          report_name = "CorporateClinicsToBeCompleted"
          break;
        default:
          break;
      }
      this.actionitems_metrics_service.exportActionItemsReportData(this.userProfile.userPk, this.ddlReports.toString(),false, report_name, this.ddlFormat).subscribe((res:any) => {
        let filename = ['6','7','8'].indexOf(this.ddlReports.toString()) > -1 ? (report_name).replace('Corporate', 'COVID') : report_name;
        importedSaveAs(res.body, filename+".xlsx");
        this.loading = false;
      },error =>{
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
      });
    } else if (this.reportType == "metric") {
      switch (this.ddlReports.toString()) {
        case "1":
          report_name = "ReviewClinicsExecutedToDate"
          break;
        case "2":
          report_name = "ReviewClinicsPendingSignedContract"
          break;
        case "3":
          report_name = "ReviewContractsDraftedButNotSent"
          break;
        case "4":
          report_name = "ReviewClinicsWithSignedContractToBeExecuted"
          break;
        case "5":
          report_name = "ReviewContractsWithRequestedChanges"
          break;
          case "6":
            report_name = "ReviewClinicsExecutedToDate"
            break;
          case "7":
            report_name = "ReviewClinicsPendingSignedContract"
            break;
          case "8":
            report_name = "ReviewContractsDraftedButNotSent"
            break;
          case "9":
            report_name = "ReviewClinicsWithSignedContractToBeExecuted"
            break;
          case "10":
            report_name = "ReviewContractsWithRequestedChanges"
            break;
        default:
          break;
      }
      this.actionitems_metrics_service.exportMetricsReportData(this.userProfile.userPk, this.ddlReports.toString(),false, report_name, this.ddlFormat).subscribe((res:any) => {
        importedSaveAs(res.body, report_name+".xlsx");
        this.loading = false;
      },error =>{
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
      });
    }
  }
  validateExportType() {
    if (this.ddlFormat == "0"||this.canExport==false)
      return true;
    else
      return false;
  }
    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}
