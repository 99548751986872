
import {throwError as observableThrowError} from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from '../../../../../node_modules/rxjs';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { PotentialCOVIDBusiness, PotentialLocalBusiness } from '../../../models/PotentialLocalBusiness';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PotentialLocalBusinessAndContactStatusService {
  private _baseURL:string = environment.API_URL;
  private _url: string = this._baseURL;
  constructor(private http:HttpClient) { }
  public getPotentialLocalBusinessData(potential_local_business:PotentialLocalBusiness): Observable<any> {
    let url : any = this._url + "/api/JsPotentialLocalBusinessContactStatus_get";
    return this.http.post(url, potential_local_business, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  private handleError(error: Response) {
    return observableThrowError("Error in Service:" + error);
  }
  getOutreachStatus(): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_OUTREACH_STATUS_LABELS;
    return this.http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  public exportReportData(ar_params: PotentialLocalBusiness) {  
    let userData= SessionDetails.GetUserInfo()
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let url = this._url + "/api/JsPotentinalLocalBusiness_downloadReport ";
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    return this.http.post(url, ar_params, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
    .pipe(catchError(Util.handleErrorV2));
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });
  }
  public exportCOVIDReportData(ar_params: PotentialCOVIDBusiness) {
    let userData = SessionDetails.GetUserInfo()
    //let token = window.btoa(userData.userName + ':' + userData.password);
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let url = this._url + "/api/JsPotentinalCOVIDBusiness_downloadReport";
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    return this.http.post(url, ar_params, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'}).pipe(catchError(Util.handleErrorV2));
  }
  public getPotentialCOVIDBusinessData(potential_local_business:PotentialLocalBusiness): Observable<any> {
    let url : any = this._url + "/api/JsPotentialCOVIDBusinessContactStatus_get";
    return this.http.post(url, potential_local_business, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
}