import { PipeTransform, Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'cstDate' })
export class CSTDateConvertPipe implements PipeTransform {
  _datePipeObj: any;
  constructor(_datePipe: DatePipe) {
    this._datePipeObj = _datePipe;
  };
  transform(value) {
    let curr_date = new Date();
    curr_date.setHours(0,0,0,0)
    let day_light_saving_from_curr_year = this.nthWeekdayOfMonth(1,1,new Date(curr_date.getFullYear(),10));
    day_light_saving_from_curr_year.setHours(0,0,0,0);
    let day_light_saving_to_next_year = this.nthWeekdayOfMonth(1,2,new Date(curr_date.getFullYear() + 1,2));
    day_light_saving_to_next_year.setHours(0,0,0,0);
    let cstOffset = 5;
    if(curr_date >= day_light_saving_from_curr_year && curr_date < day_light_saving_to_next_year){
     cstOffset = 6;
    }
    cstOffset = cstOffset * 60 * 60 * 1000;
    let cstTimeZoneVal = new Date(value);
    let todayMillis = cstTimeZoneVal.getTime();
    let curretCST = todayMillis - cstOffset;
    let cstDateObj = new Date(curretCST)
    let formatted_cdt = this._datePipeObj.transform(cstDateObj, "MM/dd/yyyy, h:mm a");
    return formatted_cdt + " (CST)";
  }

  nthWeekdayOfMonth(weekday, n, date) {
    var count = 0,
        idate = new Date(date.getFullYear(), date.getMonth(), 1);
    while (true) {
      if (idate.getDay() === weekday) {
        if (++count == n) {
          break;
        }
      }
      idate.setDate(idate.getDate() + 1);
    }
    return idate;
  }
}