import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import {PaginatorModule} from 'primeng/paginator';
import {DialogModule} from 'primeng/dialog';
import {TabViewModule} from 'primeng/tabview';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardServiceService } from './Services/dashboard-service.service';
import { ReusableModule } from '../common/components/reusable.module';
import {ChartsComponent} from './charts/charts.component'
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../../shared/shared.module';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ActionComponent } from './leadsIntake/action/action.component';
import { MessageModule } from 'primeng/message';
import { LeadAwaitingResponseService } from './Services/leadsAwaitingResponse';
import { ChartService } from './Services/chartService';
import { ChartModule } from 'primeng/chart';
import { OppourtunitiesRequiringActionComponent } from './charts/oppourtunities-requiring-action/oppourtunities-requiring-action.component';
import { PriorityOppourtunitiesRequiringActionComponent } from './charts/priority-oppourtunities-requiring-action/priority-oppourtunities-requiring-action.component';
@NgModule({
  imports: [
    CommonModule,
    ConfirmDialogModule,
    DialogModule,
    TableModule,
    TabViewModule,
    // DataListModule,
    FormsModule,
    PaginatorModule,
    ReactiveFormsModule,
    NgbModule,
    ReusableModule,
    NgxSpinnerModule,
    SharedModule,
    DynamicDialogModule,
    MessageModule,
    ChartModule
  ],
  declarations: [
    DashboardComponent,
    ChartsComponent,
    ActionComponent,
    OppourtunitiesRequiringActionComponent,
    PriorityOppourtunitiesRequiringActionComponent,
  ],
  providers:[DashboardServiceService, LeadAwaitingResponseService, ChartService]
})
export class HomeModule { }
