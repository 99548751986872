import { Component, OnInit,ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { STEPS } from '../../../contractaggreement/workflow/workflow.model';
import { PcaContractsService } from '../../services/pca-contracts.service';
import { PcaImmunizationsComponent } from '../pca-immunizations/pca-immunizations.component';
import { PcaPharmacyInfoComponent } from '../pca-pharmacy-info/pca-pharmacy-info.component';
import { PcaLocationListComponent } from '../pca-location-list/pca-location-list.component';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { SessionDetails } from '../../../../utility/session';
import { Util } from '../../../../utility/util';
import { OutreachProgramService } from '../../../contractaggreement/services/outreach-program.service';
import { PcaContract } from '../../../../models/pcaclinic';
import { DatePipe } from '@angular/common';
import { ContractData } from '../../../../models/contract';
import { TimeToDatePipe } from '../../../contractaggreement/pipes/time-to-date.pipe';
import { LulaAso } from '../../../../models/pcaTandC';
import { LulaAsoRelease2 } from '../../../../models/pcaTandC-release2';


@Component({
  selector: 'app-asolula',
  templateUrl: './asolula.component.html',
  styleUrls: ['./asolula.component.css'],
  providers: [DatePipe, TimeToDatePipe]
}) 
export class AsolulaComponent implements OnInit {
  @ViewChild(PcaImmunizationsComponent) imzcmp: PcaImmunizationsComponent;
  @ViewChild(PcaLocationListComponent) loccmp: PcaLocationListComponent;
  @ViewChild(PcaPharmacyInfoComponent) witcmp: PcaPharmacyInfoComponent;

  showHints:boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff"))=='yes')?false:true;
  pageName:string = "asolula";

  step: number = 1;
  step_desc: string;
  dialogSummary: string;
  dialogMsg: string;
  display: boolean = false;
  contractType: string;
  tandC:LulaAso = null;
  contractData:any;
  OutReachProgramType: number = 8;
  isPharmacyFormValid:boolean = false;
  isLocationsFormValid:boolean = false;
  RHDRVPEmails=[];
  index_value:number;
  hideSaveBtn:boolean=false;
  locationName:string = "Testing Event";
  startTimeEndTimesStep_desc:string ;
  showStartTimeEndTimeValid :boolean = false;
  isTimeValid:Number = 0;
  tandcRelease1: boolean;
  tandcRelease2: boolean;
  clinic_agreement_pk: any;
  oldPcaReleaseDate: string;
  PcaReleaseDate2022_1: string;
  constructor( private router: Router, private spinner: NgxSpinnerService
    , private pcaContractService:PcaContractsService, private confirmationService:ConfirmationService,
    private _localContractService: OutreachProgramService, private utility:Util, private _datePipe: DatePipe) {

   }

  ngOnInit() {
    this.spinner.show();
    this.clinic_agreement_pk = SessionDetails.GetAgreementPK(); 
    this.getContractAgreementData(this.clinic_agreement_pk)
    this.pcaContractService.wipeData();
    this.tandC = new LulaAso();
    // this.pcaContractService.setLulaTandC(this.tandC);
    this.spinner.hide();
    this.getRHDRVPEmails();
    this.oldPcaReleaseDate = new LulaAso().tnCCutOffDate;
    this.PcaReleaseDate2022_1 = new LulaAsoRelease2().tnCCutOffDate;
  }
  getRHDRVPEmails()
  {
    let storeId = SessionDetails.GetStoreId();
    if(storeId === null || storeId === undefined ){
      this.showDialog(ErrorMessages['clientError'],ErrorMessages['nullStoreId']);
      return;
    }
    this.pcaContractService.validateRHDRVPEmails(storeId).subscribe((data:any)=>
      {        
        this.RHDRVPEmails = data;
      });
  }

  
  getContractAgreementData(clinic_agreement_pk) {    
    this.pcaContractService.getPCAContractAgreement(clinic_agreement_pk).subscribe((data:any) => {
      
    //refer dates in the tandc file
     if(data.dateApproved <= this.PcaReleaseDate2022_1)
     {
      this.tandcRelease1 = true;
      let profile_data = SessionDetails.getStoreProfileData();
      if(profile_data&& profile_data.length>0){ 
          this.tandC = new LulaAso(); 
      this.pcaContractService.setLulaTandC(this.tandC);
      }
      
     }
     else if(data.dateApproved > this.PcaReleaseDate2022_1 || data.dateApproved == null)
     {
      
      this.tandcRelease2 = true;
      let profile_data = SessionDetails.getStoreProfileData();
      if(profile_data&& profile_data.length>0){ 
          this.tandC = new LulaAsoRelease2(); 
      
      this.pcaContractService.setLulaTandC(this.tandC);
      }
      
     }
    },
    err => {
     switch (err) {
       case 500:
         this.showDialog(ErrorMessages['serverError'], err.json().Message);
         return false;
         case 400:
         this.showDialog(ErrorMessages['clientError'], err.json().Message);
         return false;   
       default:
         this.showDialog(ErrorMessages['serverError'], err.json().Message);
         return false;
     }
    }
    );
   }

   
  GoToStep(step: number) {
    this.spinner.show(); 
    if(this.tandcRelease1) 
      this.tandC = new LulaAso();
    else 
      this.tandC = new LulaAsoRelease2();       
    
    this.pcaContractService.setLulaTandC(this.tandC);
    let step_desc = this.getStepDesc(step);
    this.step_desc = this.getStepDesc(this.step);
    this.contractData = this.pcaContractService.fetchPcaContractAgreementData();
 
    if (this.loccmp) {
      if (this.loccmp.contractForm.value.contactPhone.indexOf("X") > -1) {
        this.loccmp.contractForm.controls.contactPhone.setValue(null);
        this.loccmp.contractForm.controls.contactPhone.updateValueAndValidity();
        let errMsg = ErrorMessages['MandatoryFields'];
        let sumMsg = 'Error';
        this.spinner.hide(); 
        this.showDialog(sumMsg, errMsg);
        return false;
      }
    }

    this.hideSaveBtn = this.contractData.isEmailSent == true && ( this.contractData.isApproved != false) && ( this.contractData.isRhdApproved != false);
    if (step < this.step) {
      this.cacheInEnteredData(this.step_desc);
      this.step = step;
      this.step_desc = step_desc;
      this.spinner.hide(); 
      return;
    }
    if (this.OnSave(this.step_desc, step, this.step)) {
      if (this.step_desc != step_desc) {
        this.step = step ;
      }
    }
    this.spinner.hide(); 
  }
  getStepDesc(step: number) {
    if (step === 1)
      return STEPS.immunization;
    if (step === 2)
      return STEPS.location;
    if (step === 3)
      return STEPS.witness;
    if (step === 4)
    {

      return STEPS.review;
    }
  }
  cacheInEnteredData(step_desc: any, currentStep?: number, prevStep?: number) {    
    let return_value: boolean = false;
    if (this.step_desc === STEPS.immunization) {
      return_value = this.imzcmp.simpleSave();
    }
    else if (this.step_desc === STEPS.location) {
      if (this.loccmp != undefined) {
        this.loccmp.simpleSave();
        
        if(!this.loccmp.isFormValid() ){
          this.isLocationsFormValid = false;
        } else {
          this.isLocationsFormValid = true;
        }
        
      }
    }
    else if (this.step_desc === STEPS.witness) {
      if (this.witcmp != undefined) {
        this.witcmp.simpleSave();
        if(!this.witcmp.isFormValid()){
          this.isPharmacyFormValid = false;
        } else {
          this.isPharmacyFormValid = true;
        }
      }
    }
  }
  OnSave(step_desc: any, currentStep: number, prevStep: number) {
    this.index_value = 1;
    let return_value = false;
    if (this.step_desc === STEPS.immunization) {
      return_value = this.imzcmp.save();
    }
    else if (this.step_desc === STEPS.location) {
      if(this.loccmp.isClinicDateReminderBefore2WksReq()){
        let sumMsg = ErrorMessages['impRmndrFor2Wks'];
        let errMsg = ErrorMessages['eventDateReminderBefore2WeeksEN'];
        var userProfile = SessionDetails.GetUserProfile();
        var lowerCaseName = userProfile.userRole.toLowerCase();
        if (lowerCaseName == "regionalvp" ||
        lowerCaseName == "regionaldirector" || lowerCaseName == "admin" ||
        lowerCaseName == "regional vice president" ||
        lowerCaseName == "regional healthcare director" || 1==1
        ) {
          this.step = 2;
          this.dialogSummary = sumMsg;
          this.confirm(sumMsg, errMsg, step_desc);
          return false;
        }
        else {
          errMsg = ErrorMessages['eventDateReminderBefore2WeeksEN'];
          this.showDialog(sumMsg, errMsg);
          this.step = 2;
          return false;
        }
      }
      this.isTimeValid = this.loccmp.isStartTimeEndTimeValid();
      if(this.isTimeValid){
       this.startTimeEndTimesStep_desc = step_desc;
       this.showStartTimeEndTimeValid = true;
       this.dialogSummary = "Alert";
       if(this.isTimeValid==1)
       this.dialogMsg = ErrorMessages['clinicLengthMoreThan8Hrs'];
       else if(this.isTimeValid == 2){
         this.dialogSummary = "Error";
         this.dialogMsg = ErrorMessages['clinicLengthAlert'];
       }
       else {
         this.dialogMsg = "Please contact support.";
       }
       return false;
     } 
      return_value = this.loccmp.save();
    }
    else if (this.step_desc === STEPS.witness) {
      return_value = this.witcmp.save();
      let a = this.witcmp.getRHDEmail();
      if(a.match(/@/g)!='@')
      {
        let sMsg = ErrorMessages['ValidateRHDEmail'];
        let sumMsg = "Invalid Email";
        if(a.length<=0){
          sMsg=ErrorMessages['MandatoryFields'];
        }
        this.showDialog(sumMsg, sMsg);
        return false;
      }
       this.witcmp.getRHDEmail().split(',').forEach(element => {
        var index = this.RHDRVPEmails.findIndex(q=>q.toLowerCase()==element.toLowerCase());       
        if(index==-1)
        {
          this.index_value = index;
           let sMsg = ErrorMessages['ValidateRHDEmail'];
           let sumMsg = "Invalid Email";
           this.showDialog(sumMsg, sMsg);
        }
      });
      if(this.index_value==-1)
      return false;
    }

    if(this.pcaContractService.getPcaContractData().isEmailSent == 1 ) {
      this.isLocationsFormValid = true;
      this.isPharmacyFormValid=true;
    } 
    if (currentStep > prevStep + 1) {
      //user has navigated one step further due to previous iteration.
      for (let count = prevStep ; count <= currentStep; count++) {
        switch (count) {
          case 1:
          if(!this.imzcmp.checkIfAnyImmunizationsAdded()){
            this.step = 1;
            return false;
          }
          break;
          case 2:
          if(this.imzcmp != null && this.imzcmp != undefined  ){

            if ( !this.isLocationsFormValid ||  this.pcaContractService.getValidationsRequiredStatus() )
              {//this.imzcmp.validateLocForm ||
              this.step = 2;
              this.imzcmp.validateLocForm = false;
              return false;
            }
          } else if(this.pcaContractService.getValidationsRequiredStatus()) {
            this.step = 2;
            this.imzcmp.validateLocForm = false;
            return false;
          }
            break;
            case 3:
            if(!this.isPharmacyFormValid ){
              this.step = 3;
              return false;
            }
          default:
            break;
        }
      }
    }
    return return_value;
  }
  setLula(state:string){
    
    // this.tandC = new LulaAso();
    // this.pcaContractService.setLulaTandC(this.tandC);
  }
  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
  }
  confirm(hdr: string, msg: string, step_desc: any) {
    this.confirmationService.confirm({
      message: msg,
      header: hdr,
      accept: () => {
        this.isTimeValid = this.loccmp.isStartTimeEndTimeValid();
        if(this.isTimeValid){
          this.showStartTimeEndTimeValid = true;
          this.startTimeEndTimesStep_desc = step_desc;
          this.dialogSummary = "Alert";
          if(this.isTimeValid==1)
          this.dialogMsg = ErrorMessages['clinicLengthMoreThan8Hrs'];
          else if(this.isTimeValid == 2){
            this.dialogSummary = "Error";
            this.dialogMsg = ErrorMessages['clinicLengthAlert'];
          }
          else {
            this.dialogMsg = "Please contact support";
          }
          return false;
        }
        if (this.loccmp.save()) {
            this.step = this.step + 1;
        }
        else {
          this.step = 2;
          return false;
        }
        return true;
      },
      reject: () => {
        this.step = 2;
        return false;
      }
    });
    
  }
  okClicked() {
    this.display = false;
  }
  saveBtnClicked() {
    this.spinner.show();
    let return_value = false;
    if (this.step_desc === STEPS.immunization || this.step <= 1) {
      if (this.imzcmp != undefined) {
      return_value = this.imzcmp.simpleSave();
      }
    }
    if (this.step_desc === STEPS.location || this.step == 2) {
      if (this.loccmp != undefined) {
        
        return_value = this.loccmp.simpleSave();
      }
    }
    if (this.step_desc === STEPS.witness || this.step >= 3)
      if (this.witcmp != undefined) {

        return_value = this.witcmp.simpleSave();
      }
      if( !return_value ) {
        this.spinner.hide();  
         return false; }
        
         let contractData = new PcaContract();
         contractData = this.pcaContractService.getPcaContractData();

         let orthoDoxContract = new ContractData();
         this.utility.transformPcaAgreementToOrthoDoxContractJson(contractData,"", this._datePipe,orthoDoxContract);
         orthoDoxContract.isEmailSent = 0;
         orthoDoxContract.contactWagUser = null;
         orthoDoxContract.isApproved = null;
    this._localContractService.saveAgreementData(SessionDetails.GetStoreId(), 8,orthoDoxContract).subscribe((data: any) => {
      this.spinner.hide();
      switch (data.errorS.errorCode) {
        case '0':
        case 0:
          this.showDialog(ErrorMessages['asoLula'], ErrorMessages['asoLulaSaved']);
          break; 
          case -4:
          case '-4':
          this.showDialog(ErrorMessages['resolve'], data.errorS.errorMessage);
          break;
          default:
          this.showDialog(ErrorMessages['contract'], data.errorS.errorMessage);
          break;           
      }
     
    },
      err => {
        this.spinner.hide();
        switch (err) {
          case 500:
            this.showDialog(ErrorMessages['serverError'], err.json().Message);
            break;
            case 400:
            this.showDialog(ErrorMessages['clientError'], err.json().Message);
            break;
          default:
            this.showDialog(ErrorMessages['serverError'], err.json().Message);
            break;
        }
      });
    return return_value;
  }
  cancelContract() {
    // let return_value = false;
    // if (this.step_desc === STEPS.immunization || this.step <= 1) {
    //   if(this.imzcmp != undefined){
    //   return_value = this.imzcmp.cancelImmunization();
    //   }
    //   if (return_value) {
    //     this.savePopUp = true;
    //     this.showDialogCancel(ErrorMessages['unSavedData'], ErrorMessages['contract_alert']);
    //   }
    //   else {
    //     this._location.back();
    //   }
    // }
    // if (this.step_desc === STEPS.location || this.step == 2) {
    //   this.cancel_check = this._localContractService.getIsCancel();
    //    return_value = this.loccmp.cancelLocationData();
    //   if (return_value || this.cancel_check ) {
    //     this.savePopUp = true;
    //     this.showDialogCancel(ErrorMessages['unSavedData'], ErrorMessages['contract_alert']);
    //   }
    //   else {
    //     this._location.back();
    //   }
    // }
    // if (this.step_desc === STEPS.witness || this.step >= 3)
    //   if (this.witcmp != undefined) {
    //     this.cancel_check = this._localContractService.getIsCancel();
    //     return_value = this.witcmp.cancelWitness();
    //     if (return_value || this.cancel_check ) {
    //       this.savePopUp = true;
    //       this.showDialogCancel(ErrorMessages['unSavedData'], ErrorMessages['contract_alert']);
    //     }
    //     else {
    //       this._location.back();
    //     }
    //   }
    this.router.navigateByUrl('/communityoutreach/storehome');
  }
    showDialogCancel(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
  }
  EditTimes(){
    this.showStartTimeEndTimeValid=false;
  }
  ContinueToNextStep(){
    this.showStartTimeEndTimeValid=false;
    if (this.loccmp.save()) {
      this.step = this.step + 1;
  }
  else {
    this.step = 2;
    return false;
  }
  return true;
  }
}
