import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { StoreComplianceSR,StoreComplianceIP } from '../../../models/storeCompliance';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class StorecompliancereportService {

  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL;
  constructor(private http: HttpClient) { }


  public getStoreBusinessFeedbackPer(store_compliance: StoreComplianceIP) {
    let url = this._url + "/api/JsStoreComplianceImz_get";
    return this.http.post(url, store_compliance, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  public getLocationComplianceSO(store_compliance: StoreComplianceSR) {
    let url = this._url + "/api/JsStoreComplianceSO_get";
    return this.http.post(url, store_compliance, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  public exportReportDataImz(store_compliance: StoreComplianceIP) {
    let url = this._url + "/api/JsStoreComplianceImz_downloadReport";
    return this.exportReportData(url,store_compliance);
    /*let headers: Headers = new Headers();
    let userData = SessionDetails.GetUserInfo()
    let token = window.btoa(userData.userName + ':' + userData.password);
    headers.append("Content-Type", "application/json");
    headers.append('Authorization', 'Basic ' + token)
    let url = this._url + "/api/JsStoreComplianceImz_downloadReport";
    let options = new RequestOptions({ responseType: ResponseContentType.Blob, headers: headers });
    return this.http.post(url, store_compliance, options).map(res => {
      var contentDisposition = res.headers.get('Content-Disposition');
      var result = contentDisposition.split(';')[1].trim().split('=')[1];
      this.filename = result;
      return res.blob()
    });*/
  }
  public exportReportDataSO(store_compliance: StoreComplianceSR) {

    let url = this._url + "/api/JsStoreComplianceSO_downloadReport";
    return this.exportReportData(url,store_compliance);
    /*let headers: Headers = new Headers();
    let userData = SessionDetails.GetUserInfo()
    let token = window.btoa(userData.userName + ':' + userData.password);
    headers.append("Content-Type", "application/json");
    headers.append('Authorization', 'Basic ' + token)
    let options = new RequestOptions({ responseType: ResponseContentType.Blob, headers: headers });
    return this.http.post(url, store_compliance, options).map(res => {
      var contentDisposition = res.headers.get('Content-Disposition');
      var result = contentDisposition.split(';')[1].trim().split('=')[1];
      this.filename = result;
      return res.blob()
    });*/
  }

  exportReportData(url:string,store_compliance:any){
    let userData = SessionDetails.GetUserInfo()
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    //let url = this._url + "/api/JsStoreComplianceSO_downloadReport";
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    return this.http.post(url, store_compliance, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'}).pipe(catchError(Util.handleErrorV2));
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });
  }
}
