
export class CorporateTrackerSchedulerClinics {
    isClient:number;
    clientId:number=-1;
    apptType:number=0;
    reportName: string = "";
    outputType: string = "";
    constructor(is_client: number, client_id: number, appt_type:number, report_name: string, output_type: string) {
        this.isClient = is_client;
        this.clientId = client_id;
        this.apptType = appt_type;
        this.reportName = report_name;
        this.outputType = output_type;
    }
}