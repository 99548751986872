export const COVIDVisits : any[] = [
    {
        "id":"1",
        "name":"Visit 1"
    }
    ,
    {
        "id":"2",
        "name":"Visit 2"
    }    ,
    {
        "id":"3",
        "name":"Visit 3"
    }    ,
    {
        "id":"4",
        "name":"Visit 4"
    }    ,
    {
        "id":"5",
        "name":"Visit 5"
    }    ,
    {
        "id":"6",
        "name":"Visit 6"
    }    ,
    {
        "id":"7",
        "name":"Visit 7"
    }    ,
    {
        "id":"8",
        "name":"Visit 8"
    }    ,
    {
        "id":"9",
        "name":"Visit 9"
    }    ,
    {
        "id":"10",
        "name":"Visit 10"
    }    ,
    {
        "id":"0",
        "name":"Not Applicable"
    }
]