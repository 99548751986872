import { Component, OnInit } from '@angular/core';
import { DatastatsreportingService } from '../../services/datastatsreporting.service';
import { saveAs as importedSaveAs } from "file-saver";
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorMessages } from "../../../../config-files/error-messages";

@Component({
  selector: 'app-data-stats-reporting',
  templateUrl: './data-stats-reporting.component.html',
  styleUrls: ['./data-stats-reporting.component.css'],
  providers: [DatastatsreportingService]
})
export class DataStatsReportingComponent implements OnInit {
  ddlReport: string = "";
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  constructor(private _data_stats_reporting_service: DatastatsreportingService, 
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.ddlReport = "0";
  }

  generatereportformat(): void {
    this.spinner.show();
    this._data_stats_reporting_service.exportData(this.ddlReport).subscribe((res:any) => {
      importedSaveAs(res.body, this.ddlReport+".xlsx");
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.showDialog("Error", ErrorMessages["error"], true);
    });
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
