export const IPTandCData: any =
{
    "spanish":"El presente <strong><em>CONTRATO DE SERVICIO DE VACUNAS </em></strong> (“<b>el Contrato</b>”) por y entre la parte indicada a continuación (“<b>el Cliente</b>”) y Walgreen Co., en su nombre y el de sus subsidiarias y afiliadas (“<b>Walgreens</b>”) se celebra en la fecha que lo firmó por última vez un representante autorizado tanto del Cliente como de Walgreens (la “<b>Fecha de Entrada en Vigor</b>”). Se puede hacer referencia a Walgreens y el Cliente individualmente como una “<b>Parte</b>” o colectivamente como las “<b>Partes.</b>”",
    "header":"A título oneroso, cuyo recibo y suficiencia se reconocen por la presente, el Cliente y Walgreens, por medio de sus firmas más abajo, por la presente acuerdan que Walgreens provea las vacunas indicadas en el Anexo A,anexado e incorporado al presente contrato, hecho que consiste en el despacho y la administración de dichas vacunas (“<b>las Vacunas</b>”) a los participantes (“<b>los Participantes</b>”) en el sitio(s) acordado(s) mutuamente fuera de las tiendas Walgreens, denominadas sitios fuera de la tienda, y/o en las tiendas Walgreens participantes por medio de la emisión de un vale (“<b>los Servicios Cubiertos</b>”).",
    "attachmentheader":"<p align=center><b>ANEXO A</b></p><p>Por cada servicio cubierto, ya sea por medio de un vale, en un sitio fuera de la tienda o ambos, el Cliente o Participante, según sea pertinente, realizará un reembolso a Walgreens a las tasas establecidas en la Tabla 1, a continuación.  El Cliente reconoce que las tasas de reembolso establecidas en el presente documento son información confidencial y de propiedad exclusiva de Walgreens. El Cliente acuerda no revelar dichas tasas a ningún tercero aparte de lo mínimamente necesario conforme a los términos del presente Contrato. Las tasas incluidas a continuación incluyen el costo de la vacuna,los cargos por despacho, el costo por administración y cualquier impuesto pertinente gravado en conexión con los servicios cubiertos.</p>",
    "imzsp":"Vacuna",
    "paymentsp":"Método De Pago",
    "ratessp":"el Precio",
    "imzen":"Immunization",
    "payen":"Payment",
    "ratesen":"Rate",
   "english":"<p class=black-font>This<b> <i>IMMUNIZATION SERVICE AGREEMENT</i></b><b>  &nbsp;(\"Agreement\")</b> by and between the party indicated below <b>(\"Client\")</b>, and Walgreen Co., on behalf of itself and its subsidiaries and affiliates <b>(\"Walgreens\")</b> is made and entered into on the date last signed by an authorized representative of both the Client and Walgreens (the <b>\"Effective Date\"</b>). Walgreens and Client may be individually referred to as a <b>\"Party\"</b> or collectively as the <b>\"Parties.\"</b><br/></p>",
    "englishheader":"<p class=black-font>For good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, Client and Walgreens, by their signatures below, hereby agree Walgreens will provide the immunizations indicated in Attachment A, attached hereto and incorporated herein, consisting of dispensing and administering of such immunizations (<b>\"Immunizations</b>\") to participants (<b>\"Participants</b>\") at mutually agreed upon location(s) outside of Walgreens’ store locations, referred to as off-site locations,   and/or at Walgreens’ participating store locations through issuance of a voucher (<b>\"Covered Services</b>\").</p>",
    "englishattachment":"<h3 class='text-center mb-5' style='font-size:18px !important;color:#212529 !important;'>ATTACHMENT A</h3><p class=\"black-font\">For each Covered Service whether through a voucher, at an off-site location or both, Client or Participant, as applicable, will reimburse Walgreens at the rates set forth in Table1, below. Client acknowledges that the reimbursement rates set forth herein are Walgreens’ confidential and proprietary information and Client agrees not to disclose the rates to any third-party other than as minimally necessary under the terms of this Agreement. The rates listed below are inclusive of the cost of vaccine, dispensing fee, administration fee and any applicable taxes imposed in connection with Covered Services.</p>",
   "clientsp":"CLIENTE:",
   "namesp":"NOMBRE:",
   "titlesp":"TÍTULO:",
   "clienten":"Business Name:",
   "nameen":"Name:",
   "eSignatureSP": "eSig:",
   "eSignatureEN": "eSig:",
   "titleen":"Title:",
   "attentionsp": "Atención",
   "address1sp": "Dirección 1",
   "address2sp": "Dirección 2",
   "citysp": "Ciudad",
   "statesp": "Estado",
   "zipcodesp": "Código Postal:",
    "attentionen": "Attention",
    "address1en":"Address1",
    "address2en":"Address2",
    "cityen":"City",
    "stateen":"State",
    "zipcodeen":"Zip",
    "legalsp":"Envíele Avisos Legales al Cliente A",
    "clientinfosp":"Vigencia",
     "legalen":"Send Legal Notice To Client At",
    "clientinfoen":"Client Information",
    "inWitnessWhereOffEN":"IN WITNESS WHEREOF,",
    "inWitnessWhereOffSP": "IN WITNESS WHEREOF,",
    "inWitnessWhereOff2ndLineEN":"Client and Walgreens have executed this Agreement.",
     "inWitnessWhereOff2ndLineSP":"Client and Walgreens have executed this Agreement.",
     "sendLegalNoticeToClientEN":"Send Legal Notice To Client At:",
     "sendLegalNoticeToClientSP":"Envíele Avisos Legales al Cliente A:",
     "sendLegalNoticeToWalgreensEN":"Send Legal Notice To Walgreens At:",
     "sendLegalNoticeToWalgreensSP":"Envíele Avisos Legales al Walgreens A:",
     "wallGreensCommOffsiteTitle1EN":"WALGREENS COMMUNITY OFF-SITE CLINIC AGREEMENT",
     "wallGreensCommOffsiteTitle1SP":"WALGREENS CONTRATO DE CLÍNICA AMBULATORIA COMUNITARIA",
     "wallGreensCommOffsiteTitle2EN":"TERMS AND CONDITIONS",
     "wallGreensCommOffsiteTitle2SP":"TERMS AND CONDITIONS",
     "wallGreensResponsibilitiesEN":" WALGREENS’ RESPONSIBILITIES",// deliberately extra space
     "wallGreensResponsibilitiesSP":"RESPONSABILIDADES DE WALGREENS",
     "wallGreensResponsibilitiesPara1HeadingEN":"Covered Services",
     "wallGreensResponsibilitiesPara1HeadingSP":"Covered Services",
     "wallGreensResponsibilitiesCvrdServicesEN":" Subject to the limitations or restrictions imposed by federal and state contracts, laws, and regulations, and the availability of the appropriate Immunization, Walgreens will provide the Covered Services to Participants. With respect to such Covered Services, the Parties will comply with the procedures set forth herein. When required by state law, Walgreens will require Participants to provide a valid prescription from their physician or allow the health care professional to contact their physician to obtain a valid prescription; however, for influenza Immunizations, Walgreens will be responsible for obtaining standing orders from physicians. Participants will be required to complete a Walgreens’ vaccine administration record and consent form before receiving an Immunization.",
    "wallGreensResponsibilitiesCvrdServicesSP":"Subject to the limitations or restrictions imposed by federal and state contracts, laws, and regulations, and the availability of the appropriate Immunization, Walgreens will provide the Covered Services to Participants. With respect to such Covered Services, the Parties will comply with the procedures set forth herein. When required by state law, Walgreens will require Participants to provide a valid prescription from their physician or allow the health care professional to contact their physician to obtain a valid prescription; however, for influenza Immunizations, Walgreens will be responsible for obtaining standing orders from physicians. Participants will be required to complete a Walgreens’ vaccine administration record and consent form before receiving an Immunization.",
    "profJudgementHeadingEN":"Professional Judgment",
    "profJudgementHeadingSP":"Professional Judgment",
    "profJudgementTextEN":" Walgreens may withhold Covered Services to a Participant for good cause, including but not necessarily limited to, Client’s or Participant’s (where applicable) failure to pay for Covered Services rendered; requests by Participant for services inconsistent with the legal and regulatory requirements; or where, in the professional judgment of the health care professional, the services should not be rendered.",
    "profJudgementTextSP":" Walgreens may withhold Covered Services to a Participant for good cause, including but not necessarily limited to, Client’s or Participant’s (where applicable) failure to pay for Covered Services rendered; requests by Participant for services inconsistent with the legal and regulatory requirements; or where, in the professional judgment of the health care professional, the services should not be rendered.",
    "provHealthCrProfHdngEN":"Provision of Healthcare Professional",
    "provHealthCrProfHdngSP":"Provision of Healthcare Professional",
    "provHealthCrProfTextEN":" If the Parties agree in writing that Walgreens will provide Covered Services at off-site locations, Walgreens will provide Client with the appropriate number of qualified health care professionals and technicians to provide Covered Services at such off-site locations. Any requests for additional personnel will be subject to mutual agreement by the Parties and may require additional agreed-upon fees to be paid by Client to Walgreens in accordance with this Agreement.",
    "provHealthCrProfTextSP":" If the Parties agree in writing that Walgreens will provide Covered Services at off-site locations, Walgreens will provide Client with the appropriate number of qualified health care professionals and technicians to provide Covered Services at such off-site locations. Any requests for additional personnel will be subject to mutual agreement by the Parties and may require additional agreed-upon fees to be paid by Client to Walgreens in accordance with this Agreement.",
    "clientsResponsibilitesEN":"CLIENT’S RESPONSIBILITIES",
    "clientsResponsibilitesSP":"CLIENT’S RESPONSIBILITIES",
    "vouchersHeadingEN":"Vouchers",
    "vouchersHeadingSP":"Vouchers",
    "vouchersTextEN":" If the Parties agree in writing that Walgreens will provide Covered Services upon receipt of a voucher, Client will provide Participants with a voucher (in a format agreeable to both Parties), which Participants may redeem at a participating Walgreens store location. Once the voucher is approved by both Parties it may not be modified. Client may not rescind, retract, reduce or deny payment owed to Walgreens for claims where Covered Services have been provided to its Participants, even if Client no longer considers the individual redeeming the voucher to be a Participant.",
    "vouchersTextSP":" If the Parties agree in writing that Walgreens will provide Covered Services upon receipt of a voucher, Client will provide Participants with a voucher (in a format agreeable to both Parties), which Participants may redeem at a participating Walgreens store location. Once the voucher is approved by both Parties it may not be modified. Client may not rescind, retract, reduce or deny payment owed to Walgreens for claims where Covered Services have been provided to its Participants, even if Client no longer considers the individual redeeming the voucher to be a Participant.",
    "offSiteLocationEN":"Off-Site Locations.",
    "offSiteLocationSP":"Off-Site Locations.",
    "importantEN":"IMPORTANT",
    "importantSP":"IMPORTANT",
    "offSiteLocationText1EN":" If the Parties agree in writing, that Walgreens will provide Covered Services at off-site locations, Client will provide Participants with notice of the dates, times and locations for such off-site locations and provide a private, clean room location, tables and chairs for Walgreens' personnel and Participants.  Additionally, Client guarantees that an average minimum of 25 Immunizations will be administered to Participants at each of Client's off-site locations {0}",
    "offSiteLocationText1SP":" If the Parties agree in writing, that Walgreens will provide Covered Services at off-site locations, Client will provide Participants with notice of the dates, times and locations for such off-site locations and provide a private, clean room location, tables and chairs for Walgreens' personnel and Participants.  Additionally, Client guarantees that an average minimum of 25 Immunizations will be administered to Participants at each of Client's off-site locations {0}",
    "siteMinEN":"Site Minimum",
    "siteMinSP":"Site Minimum",
    "siteAvgEN":"Site Average",
    "siteAvgSP":"Site Average",
    "siteMinTextEN":"(\"<b>Site Minimum</b>\"). If Walgreens determines that the Site Minimum is not achieved for the {0} (determined by taking the total number of Immunizations administered at all off-site locations divided by the number of off-site locations in such {1} ",
    "siteMinTextfluEN":"(\"<b>Site Minimum</b>\"). If Walgreens determines that the Site Minimum is not achieved for the {0} (determined by taking the total number of Immunizations administered at all off-site locations divided by the number of off-site locations in such {1} ",
    "siteMinTextSP":"(\"<b>Site Minimum</b>\"). If Walgreens determines that the Site Minimum is not achieved for the {0} (determined by taking the total number of Immunizations administered at all off-site locations divided by the number of off-site locations in such {1} ",
    "siteMinTextfluSP":"(\"<b>Site Minimum</b>\"). If Walgreens determines that the Site Minimum is not achieved for the {0} (determined by taking the total number of Immunizations administered at all off-site locations divided by the number of off-site locations in such {1} ",
    "siteAvgTextEN":"(\"<b>Site Average</b>\")), at Walgreens’ discretion, it will invoice Client for the difference between the Site Minimum and Site Average multiplied by the number of off-site events. The sum of which will be multiplied by the lowest reimbursement rate set forth in table in Attachment A and Client shall pay such amount within 30 days of being invoiced by Walgreens.",
    "siteAvgTextSP":"(\"<b>Site Average</b>\")), at Walgreens’ discretion, it will invoice Client for the difference between the Site Minimum and Site Average multiplied by the number of off-site events. The sum of which will be multiplied by the lowest reimbursement rate set forth in table in Attachment A and Client shall pay such amount within 30 days of being invoiced by Walgreens.",
    "pmtAndBIllingEN":"PAYMENT AND BILLING",
    "pmtAndBIllingSP":"PAYMENT AND BILLING",
    "paymentEN":"Payment.",
    "paymentSP":"Payment.",
    "paymentTextEN":"3.1 <u>Payment</u>. For Covered Services where: (i) Participant provides evidence of coverage under third-party insurance or a government funded program (e.g., Medicare) prior to the provision of Covered Services;(ii) and Walgreens is contracted such third-party insurance or government funded program, Walgreens will submit the claim for that Participant and any copayment, coinsurance, deductible owed by the Participant will be collected at the time of service or billed at a later date. If such evidence is not provided at the time of service, Walgreens will either, as agreed to by the Parties, collect from the Participant or invoice the Client monthly at the lesser of the prices stated herein or the Usual and Customary Charge. If a claim for reimbursement is later denied, the Parties agree that Walgreens can seek reimbursement from the Participant. As used in this Agreement, \"Usual and Customary Charge\" shall refer to the amount charged to a cash customer for an Immunization by the administering pharmacy at the time of administration, exclusive of: (i) sales tax; (ii) discounts claimed, and (iii) discounts provided for prescription drug savings card or other similar discounts. Client will reimburse Walgreens within 30 days from receipt of the monthly invoice and must be sent to the remittance address stated on the invoice. The invoice will contain the following data elements, and no further information will be provided: Group ID, store number, prescription number, patient name, recipient number, physician name, cost, service fee, copayment amount, sales tax, total charge, date of service, and drug name/NDC. At the time of payment, Client will provide Walgreens with a written explanation of the specific claims for which payment is made.",
    "paymentTextSP":" For Covered Services where: (i) Participant provides evidence of coverage under third-party insurance or a government funded program (e.g., Medicare) prior to the provision of Covered Services;(ii) and Walgreens is contracted such third-party insurance or government funded program, Walgreens will submit the claim for that Participant and any copayment, coinsurance, deductible owed by the Participant will be collected at the time of service or billed at a later date. If such evidence is not provided at the time of service, Walgreens will either, as agreed to by the Parties, collect from the Participant or invoice the Client monthly at the lesser of the prices stated herein or the Usual and Customary Charge. If a claim for reimbursement is later denied, the Parties agree that Walgreens can seek reimbursement from the Participant. As used in this Agreement, \"Usual and Customary Charge\" shall refer to the amount charged to a cash customer for an Immunization by the administering pharmacy at the time of administration, exclusive of: (i) sales tax; (ii) discounts claimed, and (iii) discounts provided for prescription drug savings card or other similar discounts. Client will reimburse Walgreens within 30 days from receipt of the monthly invoice and must be sent to the remittance address stated on the invoice. The invoice will contain the following data elements, and no further information will be provided: Group ID, store number, prescription number, patient name, recipient number, physician name, cost, service fee, copayment amount, sales tax, total charge, date of service, and drug name/NDC. At the time of payment, Client will provide Walgreens with a written explanation of the specific claims for which payment is made.",
    "latePaymentEN":"Late Payment",
    "latePaymentSP":"Late Payment",
    "latePaymentTextEN":" All sums owed by Client to Walgreens will bear interest of 1.5% per month from the date payment is due until paid; however, in no event will such interest rate be greater than the rate permitted by law. Client shall be solely responsible for any and all costs incurred by Walgreens in seeking collection of any delinquent amounts owed by Client. Walgreens may invoice Client for interest and costs due under this Section on a monthly basis and payment will be due within 30 days from receipt.",
    "latePaymentTextSP":" All sums owed by Client to Walgreens will bear interest of 1.5% per month from the date payment is due until paid; however, in no event will such interest rate be greater than the rate permitted by law. Client shall be solely responsible for any and all costs incurred by Walgreens in seeking collection of any delinquent amounts owed by Client. Walgreens may invoice Client for interest and costs due under this Section on a monthly basis and payment will be due within 30 days from receipt.",
    "termAndTermCapsEN":"TERM AND TERMINATION",
    "termAndTermCapsSP":"DURACIÓN Y TERMINACIÓN",
    "termAndTermEN":"Term and Termination",
    "termAndTermSP":"Duración y terminación",
    "termAndTermTextEN":" This Agreement will become effective on the Effective Date and shall continue in full force and effect for an initial term of one year. Upon expiration of the initial term, this Agreement will automatically renew for successive one-year terms. Either Party may terminate this Agreement at any time without cause by giving at least thirty (30) days’ prior written notice to the other Party.",
    "termAndTermTextSP":" This Agreement will become effective on the Effective Date and shall continue in full force and effect for an initial term of one year. Upon expiration of the initial term, this Agreement will automatically renew for successive one-year terms. Either Party may terminate this Agreement at any time without cause by giving at least thirty (30) days’ prior written notice to the other Party.",
    "effectAndTerminiationEN":"Effect of Termination",
    "effectAndTerminiationSP":"Effect of Termination",
    "effectAndTerminiationTextEN":" Termination will have no effect upon the rights or obligations of the Parties arising out of any transactions occurring prior to the effective date of such termination.",
    "effectAndTerminiationTextSP":" Termination will have no effect upon the rights or obligations of the Parties arising out of any transactions occurring prior to the effective date of such termination.",
    "waiverEN":"Waiver",
    "waiverSP":"Waiver",
    "waiverTextEN":" No waiver by either Party with respect to any breach or default of any right or remedy and no course of dealing may be deemed to constitute a continuous waiver of any other breach or default or of any other right or remedy unless such waiver is expressed in writing by the Party to be bound.",
    "waiverTextSP":" No waiver by either Party with respect to any breach or default of any right or remedy and no course of dealing may be deemed to constitute a continuous waiver of any other breach or default or of any other right or remedy unless such waiver is expressed in writing by the Party to be bound.",
    "insurAndIndemnifyEN":"INSURANCE AND INDEMNIFICATION ",
    "insurAndIndemnifySP":"INSURANCE AND INDEMNIFICATION ",
    "insuranceEN":"Insurance",
    "insuranceSP":"Insurance",
    "insuranceTextEN":" Each Party will self-insure or maintain at its sole expense, and in amounts consistent with industry standards, such insurance as may be necessary to insure each respective Party, its employees, and agents against any claim or claims for damages arising out of or in connection with its duties and obligations under this Agreement. Walgreens will automatically name Client as Additional Insured under its applicable insurance policy(ies). Evidence of such insurance can be downloaded from Walgreens’ website. Client will provide a memorandum or certificate of insurance coverage to Walgreens upon request.",
    "insuranceTextSP":" Each Party will self-insure or maintain at its sole expense, and in amounts consistent with industry standards, such insurance as may be necessary to insure each respective Party, its employees, and agents against any claim or claims for damages arising out of or in connection with its duties and obligations under this Agreement. Walgreens will automatically name Client as Additional Insured under its applicable insurance policy(ies). Evidence of such insurance can be downloaded from Walgreens’ website. Client will provide a memorandum or certificate of insurance coverage to Walgreens upon request.",
    "indemnificationEN":"Indemnification",
    "indemnificationSP":"Indemnification",
    "indemnificationTextEN":" To the extent permitted by law, each Party will indemnify, defend, and hold harmless the other Party, including its employees and agents, from and against any and all third-party claims or liabilities arising from the negligence or wrongful act of the indemnifying Party, its employees, or agents in carrying out its duties and obligations under the terms of this Agreement. This Section will survive the termination of this Agreement.",
    "indemnificationTextSP":" To the extent permitted by law, each Party will indemnify, defend, and hold harmless the other Party, including its employees and agents, from and against any and all third-party claims or liabilities arising from the negligence or wrongful act of the indemnifying Party, its employees, or agents in carrying out its duties and obligations under the terms of this Agreement. This Section will survive the termination of this Agreement.",
    "generalTermsEN":" GENERAL TERMS ",// deliberately extra space
    "generalTermsSP":"CONDICIONES GENERALES",
    "confidentialityEN":"Confidentiality of PHI",
    "confidentialitySP":"Confidencialidad de PHI",
    "confidentialityP1EN":" Both Parties warrant that they will maintain and protect the confidentiality of all individually identifiable health information specifically relating to Participants ",
    "confidentialityP1SP":" Both Parties warrant that they will maintain and protect the confidentiality of all individually identifiable health information specifically relating to Participants ",
    "phiEN":"(<b>\"Protected Health Information\"</b> or",
    "phiSP":"(\"Información Médica Protegida\" or",
    "phiAbEN":"\"PHI\"",
    "phiAbSP":"\"PHI\"",
    "confidentialityP2EN":" in accordance with the Health Insurance Portability and Accountability Act of 1996 and all applicable federal and state laws and regulations. However, nothing herein will limit either Party’s use of any aggregated Participant information that does not contain PHI. This Section will survive the termination of this Agreement.",
    "confidentialityP2SP":" in accordance with the Health Insurance Portability and Accountability Act of 1996 and all applicable federal and state laws and regulations. However, nothing herein will limit either Party’s use of any aggregated Participant information that does not contain PHI. This Section will survive the termination of this Agreement.",
    "AdvertisingEN":"Advertising",
    "AdvertisingSP":"Advertising",
    "AdvertisingTextEN":" Neither Party may advertise or use any trademarks, service marks, or symbols of the other Party without first receiving the written consent of the Party owning the mark and/or symbol with the following exceptions: Client may use the name and the addresses of Walgreens' locations in materials to inform Participants that Walgreens provides Covered Services. Any other reference to Walgreens in any Client materials must be pre-approved, in writing, by Walgreens.",
    "AdvertisingTextSP":" Neither Party may advertise or use any trademarks, service marks, or symbols of the other Party without first receiving the written consent of the Party owning the mark and/or symbol with the following exceptions: Client may use the name and the addresses of Walgreens' locations in materials to inform Participants that Walgreens provides Covered Services. Any other reference to Walgreens in any Client materials must be pre-approved, in writing, by Walgreens.",
    "frcMajEN":"Force Majeure",
    "frcMajSP":"Fuerza mayor",
    "frcMajTextEN":" The performance by either Party hereunder will be excused to the extent of circumstances beyond such Party’s reasonable control, such as flood, tornado, earthquake, or other natural disaster, epidemic, war, material destruction of facilities, fire, acts of terrorism, acts of God, etc. In such event, the Parties will use their best efforts to resume performance as soon as reasonably possible under the circumstances.",
    "frcMajTextSP":" The performance by either Party hereunder will be excused to the extent of circumstances beyond such Party’s reasonable control, such as flood, tornado, earthquake, or other natural disaster, epidemic, war, material destruction of facilities, fire, acts of terrorism, acts of God, etc. In such event, the Parties will use their best efforts to resume performance as soon as reasonably possible under the circumstances.",
    "complianceEN":"Compliance",
    "complianceSP":"Compliance",
    "complianceTextEN":" The Parties will comply with all applicable laws, rules, and regulations for each jurisdiction in which Covered Services are provided under this Agreement. Each Party will cooperate with reasonable requests by the other Party for information that is needed for its compliance with applicable laws, rules, and/or regulations.",
    "complianceTextSP":" The Parties will comply with all applicable laws, rules, and regulations for each jurisdiction in which Covered Services are provided under this Agreement. Each Party will cooperate with reasonable requests by the other Party for information that is needed for its compliance with applicable laws, rules, and/or regulations.",
    "assignmentEN":"Assignment",
    "assignmentSP":"Assignment",
    "assignmentTextEN":" Neither Party may assign this Agreement to a third-party without the prior written consent of the other Party, except that either Party will have the right to assign this Agreement to any direct or indirect parent, subsidiary or affiliated company or to a successor company without such consent. Any permitted assignee will assume all obligations of its assignor under this Agreement. No assignment will relieve any Party of responsibility for the performance of any obligations which have already occurred. This Agreement will inure to the benefit of and be binding upon each Party, its respective successors and permitted assignees.",
    "assignmentTextSP":" Neither Party may assign this Agreement to a third-party without the prior written consent of the other Party, except that either Party will have the right to assign this Agreement to any direct or indirect parent, subsidiary or affiliated company or to a successor company without such consent. Any permitted assignee will assume all obligations of its assignor under this Agreement. No assignment will relieve any Party of responsibility for the performance of any obligations which have already occurred. This Agreement will inure to the benefit of and be binding upon each Party, its respective successors and permitted assignees.",
    "noticesEN":"Notices",
    "noticesSP":"Notices",
    "noticesTextEN":" All notices provided for herein must be in writing, sent by U.S. certified mail, return receipt requested, postage prepaid, or by overnight delivery service providing proof of receipt to the address set forth following the signature blocks. Notices will be deemed delivered upon receipt or upon refusal to accept delivery.",
    "noticesTextSP":" All notices provided for herein must be in writing, sent by U.S. certified mail, return receipt requested, postage prepaid, or by overnight delivery service providing proof of receipt to the address set forth following the signature blocks. Notices will be deemed delivered upon receipt or upon refusal to accept delivery.",
    "entireAgrEN":"Entire Agreement",
    "entireAgrSP":"Entire Agreement",
    "entireAgrTextEN":" This Agreement, which includes any and all attachments, exhibits, riders, and other documents referenced herein, constitutes the entire and full agreement between the Parties relating to the subject matter herein and supersedes any previous contract, for which the signatories are authorized to sign for, and no changes, amendments, or alterations will be effective unless reduced to a writing signed by a representative of each Party. Any prior agreements, documents, understandings, or representations relating to the subject matter of this Agreement not expressly set forth herein or referred to or incorporated herein by reference are of no force or effect.",
    "entireAgrTextSP":" This Agreement, which includes any and all attachments, exhibits, riders, and other documents referenced herein, constitutes the entire and full agreement between the Parties relating to the subject matter herein and supersedes any previous contract, for which the signatories are authorized to sign for, and no changes, amendments, or alterations will be effective unless reduced to a writing signed by a representative of each Party. Any prior agreements, documents, understandings, or representations relating to the subject matter of this Agreement not expressly set forth herein or referred to or incorporated herein by reference are of no force or effect.",
    "counterPartsHeadingEN":"Counterparts",
    "counterPartsHeadingSP":"Counterparts",
    "counterPartsTextEN":" This Agreement may be executed in two or more counterparts, each of which together shall be deemed an original, but all of which together shall constitute one and the same instrument, provided that each Party has received the other Party’s executed instruments.  In the event that any signature is delivered by facsimile transmission or by e-mail delivery of a \".pdf\" format data file (or similar format however designated), such signature shall create a valid and binding obligation of the Party executing (or on whose behalf such signature is executed) with the same force and effect as if such facsimile or \".pdf\" signature page were an original thereof.",
    "counterPartsTextSP":" This Agreement may be executed in two or more counterparts, each of which together shall be deemed an original, but all of which together shall constitute one and the same instrument, provided that each Party has received the other Party’s executed instruments.  In the event that any signature is delivered by facsimile transmission or by e-mail delivery of a \".pdf\" format data file (or similar format however designated), such signature shall create a valid and binding obligation of the Party executing (or on whose behalf such signature is executed) with the same force and effect as if such facsimile or \".pdf\" signature page were an original thereof.",
    "rightsEN":"All rights reserved.",
    "rightsSP":"Todos los derechos reservados.",
    "approveAgrmntEN":"Approve Agreement",
    "approveAgrmntSP":"Aprobar el acuerdo",
    "approveTextEN":"Carefully review the Community Off-Site Agreement. If you agree to the conditions of the contract, please check 'Approve' below and type your name into the Electronic Signature field. If there are any discrepancies in the Agreement, reject the Agreement and provide corrections in the notes field.",
    "approveTextSP":"Examine cuidadosamente el Acuerdo para Clínica Externa en la Comunidad. Si está de acuerdo con las condiciones del contrato, favor de marcar 'Aprobado' adelante y entre su nombre en el campo Firma Electrónica. Si hubiese alguna discrepancia, rechace el Acuerdo y provea las correcciones en el campo Apuntes.",
    "electrSignEN":"Electronic Signature",
    "electrSignSP":"Firma Electrónica",
    "notesEN":"Notes:",
    "notesSP":"Notas:",
    "pageTitleEN":"",
    "pageTitleSP":"CONTRATO DE CLÍNICA AMBULATORIA COMUNITARIA",
    "dateEN":"Date:",
    "dateSP":"FECHA",
    "districtEN":"District#:",
    "districtSP":"DISTRITO#:",
    "approveEN":"Approve",
    "approveSP":"Aprobar",
    "rejectEN":"Request Changes",
    "rejectSP":"Solicitar cambios",
    "submitEN":"Submit",
    "submitSP":"Enviar",
    "cancelEN":"Cancel",
    "cancelSP":"Cancelar",
    "printAgreementEN":"Print Agreement",
    "printAgreementSP":"Acuerdo de impresión",
    "clinicLocationEN":"Clinic:",
    "clinicLocationSP":"Ubicación de la clínica:",
    "clinicDateEN":"Clinic Date:",
    "clinicDateSP":"Fecha de la clínica:",
    "clinicTimeEN":"Clinic Time:",
    "clinicTimeSP":"Tiempo de la clínica:",
    "contactEN":"Contact:",
    "contactSP":"Contacto:",
    "phoneEN":"Phone:",
    "phoneSP":"Teléfono:",
    "estShotsEN":"Est. Shots",
    "estShotsSP":"Est. Shots",
    "locationEN":"Location:",
    "locationSP":"Ubicación:",
    "timeEN":"Time:",
    "timeSP":"Hora:",
    "emailEN":"Email:",
    "emailSP":"Email:",
    "emailAgreementToEN":"Email Agreement to:",
    "emailAgreementToSP":"Acuerdo de correo electrónico a:",
    "multipleEmailAddressEN":'Multiple email addresses may be entered by placing a comma between addresses.',
    "multipleEmailAddressSP":'Se pueden ingresar varias direcciones de correo electrónico colocando una coma entre las direcciones.',
    "sendAgreementEN":"SEND AGREEMENT",
    "sendAgreementSP":'ENVIAR ACUERDO',
    "editAgreementEN":'EDIT AGREEMENT',
    "editAgreementSP":'EDITAR ACUERDO',
    "saveAgreementEN":"SAVE AGREEMENT",
    "saveAgreementSP":"GUARDAR ACUERDO",
    "noteTextEN":"* Please note, once a contract has been signed by the client, it cannot be edited.",
    "noteTextSP":"* Tenga en cuenta que una vez que el cliente ha firmado un contrato, no se puede editar.",
    "printAgreementTxtCapsEN":"PRINT AGREEMENT",
    "printAgreementTxtCapsSP":"ACUERDO IMPRESO",
    "table1EN":'Table 1',
    "table1SP":'Tabla 1',
    "attnEN":"Attn",
    "fluExpiryDateEN":"Flu Exp. Date:",
    "fluExpiryDateSP":"Fecha de vencimiento de la gripe:",
    "routineExpiryDateEN":"Routine Exp. Date:",
    "routineExpiryDateSP":"Fecha de vencimiento de rutina:",
    "sendInvoiceToEN":'Send Invoice to:',
    "sendInvoiceToSP":'Enviar factura a:',
    'isTaxExemptEN':'Is Employer Tax Exempt?',
    'isTaxExemptSP':'¿El empleador está exento de impuestos?',
    'isCopayEN':'Will patient pay the portion of the cost. Is there a copay?',
    'isCopaySP':'Pagará el paciente la parte del costo. ¿Hay un copago?',
    'voucherNeededEN':'Voucher Needed:',
    'voucherNeededSP':'Vale necesario:',
    'voucherExpiryEN':'Expiration Date:',
    'voucherExpirySP':'Fecha de caducidad:',
    'copayValueEN':'Copay Value:',
    'copayValueSP':'Valor de copago:',
    'influenzaSeasonText1EN':'per influenza season (August – April)',
    'influenzaSeasonText1SP':'per influenza season (August – April)',
    'contractYearText1EN':'per contract year',
    'contractYearText1SP':'per contract year',
    'influenzaSeasonText2EN':'influenza season',
    'influenzaSeasonText2SP':'influenza season',
    'contractYearText2EN':'contract year',
    'contractYearText2SP':'contract year',
    'contactName':'Contact Name:',
    'contactPhone':'Contact Phone:',
    'contactEmail':'Contact Email:',
    'contactNameSP':'Contact Name:',
    'contactPhoneSP':'Contact Phone:',
    'contactEmailSP':'Contact Email:',
    'doseEN':'COVID Clinic:',
    'doseSP':'COVID Clinic:',
    'confidentialityTextEN':'',
    'confidentialityTextSP':'',
    'participants': 'Participants:'

  }