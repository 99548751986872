import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig} from 'primeng/dynamicdialog';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent {

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,  private spinner: NgxSpinnerService){

  }

  handleSubmission(){
    this.spinner.show();
    this.ref.close({action: "Submission"});
  }

  hadndleReassign(){
    this.spinner.show();
    this.ref.close({action: "Reassign"});
  }

  handleRespond(){
    this.spinner.show();
    this.ref.close({action: "Respond"});
  }


}
