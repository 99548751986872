import { Component, OnInit } from '@angular/core';
import {  FormGroup,  FormBuilder, Validators } from '@angular/forms';
import { GroupIdAssignmentReportService } from '../../services/groupidassignmentreport.services';
import * as appSettings from '../../globals';
//import { NGXLogger } from 'ngx-logger';
import { ApplicationSettings } from '../../../common/application-settings';
import { saveAs as importedSaveAs } from "file-saver";
import { ErrorMessages } from "../../../../config-files/error-messages";
//import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-groupidassignmentreport',
  templateUrl: './groupidassignmentreport.component.html',
  styleUrls: ['./groupidassignmentreport.component.css']
})
export class GroupidassignmentreportComponent implements OnInit {
  GroupidassignmentreportForm: FormGroup;
  GroupidassignmentreportArray: any;
  ddlSeason: number;
  past2021:boolean = false;
  private isCurrentSeason = 1;
  ddlFormat:any;
  filterByDate: boolean=false;
  fromDate: Date;
 toDate: Date;
  minDate:Date;
  maxDate:Date;
  isCurrentYearSeason:number;
  outreachStartDate=ApplicationSettings.outreachStartDateIP;
  groupIdLaunchDate="05/01/2018";
  private from_date: string;
  private to_date: string;
totalRecords: number;
  loading: boolean;
private currentSeason = appSettings.ipSeason;
  seasons:any=[];
canExport:boolean=true;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  showGrid:boolean = false;
  covidOnly: boolean = false;
  constructor(
    private fb: FormBuilder,
    private groupidassignmentreport: GroupIdAssignmentReportService,
    /*private logger: NGXLogger,*/ /*private spinner: NgxSpinnerService*/private spinner: NgxSpinnerService) {
  }
  ngOnInit() {
    for (let report_year:number = (new Date()).getFullYear() ; report_year >= (new Date(this.groupIdLaunchDate)).getFullYear(); report_year--)
    {
         this.seasons.push({
          "yearName": report_year.toString()+"-"+(report_year+1).toString(),
          "yearCode": report_year.toString()
        });
    }
    
    this.ddlSeason = (new Date(this.outreachStartDate)).getFullYear();
    
    this.ddlFormat="0";
    this.filterByDate = false;
    //this.fromDate = this.minDate;
    //this.toDate = this.maxDate;
    this.setFormControls();
    this.setDates();
    }
    setFormControls() {
      this.GroupidassignmentreportForm = this.fb.group({
        'chkFilterByDate': this.filterByDate,
        'fDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
        'tDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
        'ddlSeason': [this.ddlSeason],
        'ddlFormat':[this.ddlFormat],
        'covidOnly': [false]
      });
     }
    
    //this.generateReport();
generateReport(): void {
  
    if(!this.showGrid){
      this.spinner.show();
 }
    else{
      this.loading = true;
    }
    this.fromDate =  this.GroupidassignmentreportForm.controls['fDate'].value;
    //this.toDate   =  this.GroupidassignmentreportForm.controls['tDate'].value;
    //this.ddlSeason   =  this.GroupidassignmentreportForm.controls['ddlSeason'].value;
    //this.ddlFormat   =  this.GroupidassignmentreportForm.controls['ddlFormat'].value;
    //this.filterByDate=  this.GroupidassignmentreportForm.controls['chkFilterByDate'].value;
    let data=this.GroupidassignmentreportForm.value;
    let d1 = data.fDate;
    let d2 = data.tDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    //this.logger.log(this.from_date);
    //this.logger.log(this.to_date);
     
    if (this.ddlSeason != this.currentSeason) {
      this.isCurrentSeason = 0;
      this.isCurrentYearSeason =0;
    } 
    else
    {
      this.isCurrentSeason = 1;
      this.isCurrentYearSeason =1;
    }  
    this.groupidassignmentreport.getGroupidassignmentreportData(
      this.from_date,
      
      this.to_date,this.filterByDate,this.isCurrentSeason,this.ddlSeason, this.covidOnly).subscribe((resultArray: any) => {
        
        this.totalRecords = resultArray.length;
        //this.logger.log("totalRecords:" + this.totalRecords);

        this.GroupidassignmentreportArray = resultArray;

        //this.logger.log("this.GroupidassignmentreportArray:" + this.GroupidassignmentreportArray.length);
        setTimeout(() => {
          this.loading = false;
          this.spinner.hide();
          this.showGrid = true;
          this.canExport=true;
        }, 1000)
      },error =>{
        
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
        this.spinner.hide();
        this.GroupidassignmentreportArray = [];
        this.totalRecords = 0;
      });
      this.canExport=true;
  }
  generateReportFormat(): void {
    
    this.loading=true;
    if (this.ddlSeason != this.currentSeason) {
      this.isCurrentSeason = 0;
      this.isCurrentYearSeason =0;
    } 
    else
    {
      this.isCurrentSeason = 1;
      this.isCurrentYearSeason =1;
    }  
    this.groupidassignmentreport.exportReportData(this.from_date,
      this.to_date,this.filterByDate,this.isCurrentSeason,this.ddlSeason,"GroupIdAssignment","xlsxv", this.covidOnly).subscribe((res:any) => {
      importedSaveAs(res.body, "GroupIdAssignment.xlsx");
      this.loading=false;
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
  setDates(){
    
    if(this != undefined){
      this.ddlSeason=this.GroupidassignmentreportForm.controls['ddlSeason'].value;
    } 
    let tempMinDate:Date;
    let tempMaxDate:Date;
    tempMinDate=new Date(ApplicationSettings.getIMmunizationStartDate(this.ddlSeason));
    tempMaxDate=new Date(ApplicationSettings.getImmunizationEndDate(this.ddlSeason));
    this.minDate=new Date(tempMinDate);
    this.maxDate=new Date(tempMaxDate)
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    this.canExport=false;
    this.isCurrentSeason = (this.ddlSeason != this.currentSeason)?0:1;
    this.past2021 = this.ddlSeason > 2021
    if(this.GroupidassignmentreportForm != undefined){
      this.GroupidassignmentreportForm.controls['fDate'].setValue(this.fromDate);
      this.GroupidassignmentreportForm.controls['tDate'].setValue(this.toDate);
      }
  }
  setDates1(){
    let tempMinDate:Date;
    let tempMaxDate:Date;
    tempMinDate=new Date(ApplicationSettings.getIMmunizationStartDate(this.ddlSeason));
    tempMaxDate=new Date(ApplicationSettings.getImmunizationEndDate(this.ddlSeason));
    this.minDate=new Date(tempMinDate);
    this.maxDate=new Date(tempMaxDate)
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    this.canExport=false;    
    this.isCurrentSeason = (this.ddlSeason != this.currentSeason)?0:1;
  }
  setCalendarDates(){
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    this.canExport=false;
  }
  reset(): void {
    //this.loading = true;
    this.showGrid = false;
    this.ddlSeason = this.currentSeason;
    this.ddlFormat=0;
    this.setDates();
    this.setFormControls();
    this,this.covidOnly = false;
    //this.generateReport();
  }
  validateExportType(){
    this.ddlFormat   =  this.GroupidassignmentreportForm.controls['ddlFormat'].value;
    if(this.ddlFormat=="0" || !this.canExport)
    return true;
    else
     return false;
  }
   //Shows the message dialog.
   showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
