// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#corporateSchedulerTabs .ui-tabview .ui-tabview-nav li.ui-tabview-selected a, .ui-tabview .ui-tabview-nav li.ui-state-disabled a, .ui-tabview .ui-tabview-nav li.ui-state-processing a {

}

.primeNgCtrl{
    height: 40px !important;
}

.table td, .table th
{ border-top: 0px solid #fff !important;
font-size:12px !important;
}
.underline{
    text-decoration: underline;
} 
.ui-table .ui-sortable-column .ui-sortable-column-icon{
    color:#fff !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/admin/components/corporate-scheduler/corporate-scheduler.component.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;EACE,qCAAqC;AACvC,yBAAyB;AACzB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,qBAAqB;AACzB","sourcesContent":["#corporateSchedulerTabs .ui-tabview .ui-tabview-nav li.ui-tabview-selected a, .ui-tabview .ui-tabview-nav li.ui-state-disabled a, .ui-tabview .ui-tabview-nav li.ui-state-processing a {\r\n\r\n}\r\n\r\n.primeNgCtrl{\r\n    height: 40px !important;\r\n}\r\n\r\n.table td, .table th\r\n{ border-top: 0px solid #fff !important;\r\nfont-size:12px !important;\r\n}\r\n.underline{\r\n    text-decoration: underline;\r\n} \r\n.ui-table .ui-sortable-column .ui-sortable-column-icon{\r\n    color:#fff !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
