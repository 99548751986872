import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { PotentialClientInterestService } from '../../services/potential-client-interest.service';

@Component({
  selector: 'app-store-responds-yes-or-no',
  templateUrl: './store-responds-yes-or-no.component.html',
  styleUrls: ['./store-responds-yes-or-no.component.css']
})
export class StoreRespondsYesOrNoComponent implements OnInit {
  dialogMsg: string;
  dialogSummary: string;
  display: boolean;
  success_message: boolean;
  leadPk: any;
  storeResForLead: any;
  storeResForLeadStoreId: any;
  alreadyExists: boolean = false;
  leadNotFound: boolean;

  constructor(private spinner: NgxSpinnerService, private _apiservice: PotentialClientInterestService) { }

  ngOnInit(): void {
    this.spinner.hide();
    this.sendEmail();
  }


  sendEmail() {
    this.leadPk = sessionStorage["leadPk"];
    this.storeResForLead = sessionStorage["storeResForLead"];
    this.storeResForLeadStoreId = sessionStorage["storeResForLeadStoreId"];

    this.spinner.show();
    if (this.leadPk) {
      if (this.storeResForLead == 'Yes') {
        this._apiservice.sendEmailToClientWhenStoreRespondsYes(this.leadPk, this.storeResForLeadStoreId).subscribe((data: any) => {
          
          sessionStorage.clear();
          this.spinner.hide();
          if (data == 'exists') {
            this.alreadyExists = true;
          }
          if (data == 'lead not found') {
            this.leadNotFound = true;
          }
          
        },
          err => {
            
            sessionStorage.clear();
            this.spinner.hide();
            switch (err) {
              case 500:
                this.showDialogForMail(ErrorMessages['serverError'], err.message, false);
                break;
              case 400:
                this.showDialogForMail(ErrorMessages['clientError'], err.message, false);
                break;
              default:
                this.showDialogForMail(ErrorMessages['serverError'], err.message, false);
                break;
            }

          });
      }
      else if (this.storeResForLead == 'No') {
        this._apiservice.sendEmailToClientWhenStoreRespondsNo(this.leadPk, this.storeResForLeadStoreId).subscribe((data: any) => {
          
          sessionStorage.clear();
          this.spinner.hide();
          if (data == 'exists') {
            this.alreadyExists = true;
          }
          if (data == 'lead not found') {
            this.leadNotFound = true;
          }
        },
          err => {
            
            sessionStorage.clear();
            this.spinner.hide();            
            switch (err) {
              case 500:
                this.showDialogForMail(ErrorMessages['serverError'], err.message, false);
                break;
              case 400:
                this.showDialogForMail(ErrorMessages['clientError'], err.message, false);
                break;
              default:
                this.showDialogForMail(ErrorMessages['serverError'], err.message, false);
                break;
            }

          });
      }
    }
  }

  showDialogForMail(msgSummary: string, msgDetail: string, success_message: boolean) {
    
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
    this.success_message = success_message;
  }

  okClicked() {
    this.display = false;
    window.location.href = 'https://wagsoutreach.com';

  }


}
