// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cFont {
    font-size: 19px;
}

.ng-invalid:not(form) {
    border: #ec0d0d 2px solid !important;
    transition: all 0.2s ease-in;
    }

  `, "",{"version":3,"sources":["webpack://./src/app/modules/contractaggreement/components/contractapprove/contractapprove.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,oCAAoC;IAIpC,4BAA4B;IAC5B","sourcesContent":[".cFont {\r\n    font-size: 19px;\r\n}\r\n\r\n.ng-invalid:not(form) {\r\n    border: #ec0d0d 2px solid !important;\r\n    -webkit-transition: all 0.2s ease-in;\r\n    -moz-transition: all 0.2s ease-in;\r\n    -o-transition: all 0.2s ease-in;\r\n    transition: all 0.2s ease-in;\r\n    }\r\n\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
