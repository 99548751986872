// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `element.style {
    height: 135px;
  }
 
  .table td, .table th {
    border-top: 0px solid #e9ecef;
}

.table>tbody>tr>td, 
.table>tbody>tr>th, 
.table>tfoot>tr>td, 
.table>tfoot>tr>th, 
.table>thead>tr>td, 
.table>thead>tr>th {
	vertical-align: top !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/contractaggreement/components/viewcontract-agreement-2022/viewcontract-agreement.component-2022.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,6BAA6B;AACjC;;AAEA;;;;;;CAMC,8BAA8B;AAC/B","sourcesContent":["element.style {\r\n    height: 135px;\r\n  }\r\n \r\n  .table td, .table th {\r\n    border-top: 0px solid #e9ecef;\r\n}\r\n\r\n.table>tbody>tr>td, \r\n.table>tbody>tr>th, \r\n.table>tfoot>tr>td, \r\n.table>tfoot>tr>th, \r\n.table>thead>tr>td, \r\n.table>thead>tr>th {\r\n\tvertical-align: top !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
