// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1365px) /* I want the datatable to be stacked at at least iPad Pro portrait mode, this depends on your data */ 
{
    /* Data in responsive mode */
    .ui-datatable-reflow .ui-datatable-data > tr > td {
        width: 100% !important;
        text-align: left;
        border: 0;
        box-sizing: border-box;
        float: left;
        clear: left;
    }


    .ui-datatable-reflow .ui-datatable-data tr.ui-widget-content {
        border-left: 0;
        border-right: 0;
    }

    .ui-datatable-reflow .ui-datatable-data.ui-widget-content {
        border: 0;
    }

    /*Hide the headers in responsive mode*/
    .ui-datatable-reflow thead th {
        display: none !important;
    }

    /*Display the headers inline with the data in responsive mode */
    .ui-datatable-reflow .ui-datatable-data td .ui-column-title {
        padding: .4em;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4em;
        font-weight: bold;
    } 
}
/* a:focus { border:1px solid #003366 !important; } */
input:focus { border:1px solid #003366 !important; }
button:focus { border: 1px solid #003366 !important; }
.clLinks:focus,.btn.btn-vBig:focus
{
    border:  1px #003366 solid  !important; 
}`, "",{"version":3,"sources":["webpack://./src/app/modules/admin/components/scheduler-landing/scheduler-landing.component.css"],"names":[],"mappings":"AAAA;;IAEI,4BAA4B;IAC5B;QACI,sBAAsB;QACtB,gBAAgB;QAChB,SAAS;QACT,sBAAsB;QACtB,WAAW;QACX,WAAW;IACf;;;IAGA;QACI,cAAc;QACd,eAAe;IACnB;;IAEA;QACI,SAAS;IACb;;IAEA,sCAAsC;IACtC;QACI,wBAAwB;IAC5B;;IAEA,+DAA+D;IAC/D;QACI,aAAa;QACb,cAAc;QACd,qBAAqB;QACrB,6BAA6B;QAC7B,iBAAiB;IACrB;AACJ;AACA,qDAAqD;AACrD,cAAc,mCAAmC,EAAE;AACnD,eAAe,oCAAoC,EAAE;AACrD;;IAEI,sCAAsC;AAC1C","sourcesContent":["@media (max-width: 1365px) /* I want the datatable to be stacked at at least iPad Pro portrait mode, this depends on your data */ \r\n{\r\n    /* Data in responsive mode */\r\n    .ui-datatable-reflow .ui-datatable-data > tr > td {\r\n        width: 100% !important;\r\n        text-align: left;\r\n        border: 0;\r\n        box-sizing: border-box;\r\n        float: left;\r\n        clear: left;\r\n    }\r\n\r\n\r\n    .ui-datatable-reflow .ui-datatable-data tr.ui-widget-content {\r\n        border-left: 0;\r\n        border-right: 0;\r\n    }\r\n\r\n    .ui-datatable-reflow .ui-datatable-data.ui-widget-content {\r\n        border: 0;\r\n    }\r\n\r\n    /*Hide the headers in responsive mode*/\r\n    .ui-datatable-reflow thead th {\r\n        display: none !important;\r\n    }\r\n\r\n    /*Display the headers inline with the data in responsive mode */\r\n    .ui-datatable-reflow .ui-datatable-data td .ui-column-title {\r\n        padding: .4em;\r\n        min-width: 30%;\r\n        display: inline-block;\r\n        margin: -.4em 1em -.4em -.4em;\r\n        font-weight: bold;\r\n    } \r\n}\r\n/* a:focus { border:1px solid #003366 !important; } */\r\ninput:focus { border:1px solid #003366 !important; }\r\nbutton:focus { border: 1px solid #003366 !important; }\r\n.clLinks:focus,.btn.btn-vBig:focus\r\n{\r\n    border:  1px #003366 solid  !important; \r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
