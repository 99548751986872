export const adminFooterMenuItems: any[] = [{
    title: "Dashboards",
    url: "/communityoutreach/home"  
  },  
  {
    title: "Store",
    url: "/communityoutreach/storehome",  
  },
  {
    title: "IP Resources",    
    url: "/communityoutreach/imzresources"   
  },
  {
    title: "SR Resources",    
    url: "/communityoutreach/soresources"   
  },
  {
    title: "PCA Resources",    
    url: "/communityoutreach/pcaresources"   
  },
  {
    title: "Reports",    
    url: "/communityoutreach/scheduledclinicsreport"   
  }
  ]

  export const storeManagerFooterMenuItems: any[] = [
  {
    title: "Store",
    url: "/communityoutreach/storehome",  
  },
  {
    title: "IP Resources",    
    url: "/communityoutreach/imzresources"   
  },
  {
    title: "SR Resources",    
    url: "/communityoutreach/soresources"   
  },
  {
    title: "PCA Resources",    
    url: "/communityoutreach/pcaresources"   
  },
  {
    title: "Reports",    
    url: "/communityoutreach/scheduledclinicsreport"   
  }
  ]

  export const districtManagerFooterMenuItems: any[] = [
    {
      title: "Dashboards",
      url: "/communityoutreach/home"  
    },   
  {
    title: "Store",
    url: "/communityoutreach/storehome",  
  },
  {
    title: "IP Resources",    
    url: "/communityoutreach/imzresources"   
  },
  {
    title: "SR Resources",    
    url: "/communityoutreach/soresources"   
  },
  {
    title: "PCA Resources",    
    url: "/communityoutreach/pcaresources"   
  },
  {
    title: "Reports",    
    url: "/communityoutreach/scheduledclinicsreport"     
  }
  ]

  export const healthcareSupervisorFooterMenuItems: any[] = [ 
    {
      title: "Dashboards",
      url: "/communityoutreach/home"  
    },  
  {
    title: "Store",
    url: "/communityoutreach/storehome",  
  },
  {
    title: "IP Resources",    
    url: "/communityoutreach/imzresources"   
  },
  {
    title: "SR Resources",    
    url: "/communityoutreach/soresources"   
  },
  {
    title: "PCA Resources",    
    url: "/communityoutreach/pcaresources"   
  },
  {
    title: "Reports",    
    url: "/communityoutreach/scheduledclinicsreport"    
  }
  ]

  export const regionalVicePresidentFooterMenuItems: any[] = [ 
    {
      title: "Dashboards",
      url: "/communityoutreach/home"  
    },  
  {
    title: "Store",
    url: "/communityoutreach/storehome",  
  },
  {
    title: "IP Resources",    
    url: "/communityoutreach/imzresources"   
  },
  {
    title: "SR Resources",    
    url: "/communityoutreach/soresources"   
  },
  {
    title: "PCA Resources",    
    url: "/communityoutreach/pcaresources"   
  },
  {
    title: "Reports",    
    url: "/communityoutreach/scheduledclinicsreport"    
  }
  ]
  export const directorRxRetailFooterMenuItems: any[] = [
    {
      title: "Dashboards",
      url: "/communityoutreach/home"  
    },  
  {
    title: "Store",
    url: "/communityoutreach/storehome",  
  },
  {
    title: "IP Resources",    
    url: "/communityoutreach/imzresources"   
  },
  {
    title: "SR Resources",    
    url: "/communityoutreach/soresources"   
  },
  {
    title: "PCA Resources",    
    url: "/communityoutreach/pcaresources"   
  },
  {
    title: "Reports",    
    url: "/communityoutreach/scheduledclinicsreport"   
  }
  ]