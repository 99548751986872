import { Component, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { Subscription } from 'rxjs';
import { HintMessage } from '../../../../JSON/hintMessages';
import { SessionDetails } from '../../../../utility/session';
import { SafehtmlPipe } from '../../services/safehtml.pipe';

@Component({
    selector: 'hintscomponent',
    templateUrl: './popuphints.component.html',
    styleUrls: ['../footer/footer.component.css']
})
export class HintsPopupComponent implements OnChanges {
    //title = 'I\'m a nested component';      
    private userProfile = SessionDetails.GetUserProfile();
    dispChild: boolean = false;
    hintMessages: string[];
    @Input() isDashboard: boolean = false;
    @Input() dispHintDialog: boolean = true;
    @Input() stepNumber: number;
    @Output() turnOffHintsChange = new EventEmitter<boolean>();
    @Input() title: string = "";
    currentHintNumber: number = 0;
    backButton: boolean = true;
    nextButton: boolean = true;
    closeButton: boolean = false;
    hintHdng: string = "";
    hintMsg: string = "";
    turnOnOffHints: boolean = false;
    private hintsType:string="";

    constructor(
        private message_service: MessageServiceService, 
        safehtmlPipe: SafehtmlPipe
    ) {
        //this.hintMessages = HintMessage[this.title]["admin"];
        this.message_service.getShowTabbedHints().subscribe(message => {
            this.currentHintNumber = 0;
            if ((this.hintMessages && this.hintMessages.length > 1) && !this.stepNumber && this.currentHintNumber === 0) {
                this.backButton = false;
                this.nextButton = true;
                this.closeButton = false;
            }          
            this.loadCurrentHints();            
            this.dispHintDialog = message;  
        });
        this.message_service.getHintsType().subscribe(message =>{
            if(this.hintsType!=message){                
                this.hintsType = message;
                this.hintMessages = [];
                this.loadHintsOnTypeChange();
            }else{
                this.hintsType = message;
            }
        });
        this.message_service.getTurnOnOffLocalHintsFromFooter().subscribe(message => {
            if (message.text) {
                localStorage.removeItem("hintsOff");
                sessionStorage.removeItem("hintsOff");
                this.turnOnOffHints = false;
                this.currentHintNumber = 0;
                if ((this.hintMessages && this.hintMessages.length > 1) && !this.stepNumber && this.currentHintNumber == 0) {
                    this.backButton = false;
                    this.nextButton = true;
                    this.closeButton = false;
                }
                this.loadCurrentHints();
            } else {
                sessionStorage.setItem("hintsOff", "yes");
                this.currentHintNumber = 0;
            }            
            this.dispHintDialog = message.text;
        });

        this.message_service.getShowHintsOnButtonClick().subscribe(message =>{
            if(message){
                localStorage.removeItem("hintsOff");
                sessionStorage.removeItem("hintsOff");
                this.message_service.setTurnOnOffLocalHintsFromFooter(true);
                if(!this.userProfile){
                    this.userProfile = SessionDetails.GetUserProfile();
                }
                var role = this.userProfile.userRole;
                if(window.location.href.indexOf('storehome')>-1){
                    this.title = 'storehome';
                }
                let hints_key = this.hintsType + this.title;
                for (let hints in HintMessage[hints_key]) {
                    if (hints.toLowerCase().indexOf(role.toLowerCase()) > -1) {
                        this.hintMessages = HintMessage[hints_key][hints];
                        break;
                    }
                }
                this.turnOnOffHints = false;
                this.currentHintNumber = 0;
                if (this.currentHintNumber == 0) {
                    this.backButton = false;
                    this.nextButton = true;
                    this.closeButton = false;
                }
                this.loadCurrentHints();
                this.dispHintDialog = message;
            }
        });
    }

    turnOffHints(event: any) {
        let ele = event.target || event.targetElement || event.currentTarget;
        this.dispHintDialog = false;
        this.turnOffHintsChange.emit(!ele.checked);
        //this.message_service.setTurnOnOffLocalHintsFromComponent(!event.path[0].checked);
        this.message_service.setTurnOnOffLocalHintsFromComponent(!ele.checked);
        localStorage.setItem("hintsOff", "yes");
    }

    public showMsg() {
        this.dispHintDialog = true;
        //alert('This is child component');
    }
    
    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes["title"]) {
            this.title = changes["title"].currentValue;
            this.loadHintsOnTypeChange();
            if (this.hintMessages && this.hintMessages.length > 0) {
                if (this.currentHintNumber == 0) {
                    this.backButton = false;
                }
            }
            if (this.hintMessages && this.hintMessages.length === 1) {
                this.backButton = false;
                this.nextButton = false;
            }
            if (!changes["title"]) {
                this.loadCurrentHints();
            }
            if(changes["dispHintDialog"]){
                this.loadCurrentHints();
                this.dispHintDialog = changes["dispHintDialog"].currentValue;
            }
        }
        if (changes["stepNumber"]) {
            this.backButton = false;
            this.nextButton = false;
            this.closeButton = false;
            this.stepNumber = changes["stepNumber"].currentValue;
            if (!changes["title"]) {
                this.loadCurrentHints();
                this.dispHintDialog = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : true;
            }
        }
    }
    loadHintsOnTypeChange(){
        if(!this.userProfile){
            this.userProfile = SessionDetails.GetUserProfile();
        }

        if(!this.userProfile){
            this.userProfile = JSON.parse(SessionDetails.getD(SessionDetails.absJ,sessionStorage["cI"]));
            this.userProfile.userRole = this.userProfile.role;
        }
        var role = this.userProfile.userRole.toLowerCase();  
        if(window.location.href.indexOf('storehome')>-1 || window.location.href.indexOf('pcahome')>-1){
            this.title = 'storehome';
        }   
        let hints_key = this.hintsType + this.title;
        for (let hints in HintMessage[hints_key]) {
            if (hints.toLowerCase().indexOf(role.toLowerCase()) > -1) {
                this.hintMessages = HintMessage[hints_key][hints];
                break;
            }
        }
        if (!this.hintMessages || this.hintMessages.length == 0) {
            for (let hints in HintMessage[hints_key]) {
                if (hints.toLowerCase().indexOf("admin") > -1) {
                    this.hintMessages = HintMessage[hints_key][hints];
                    break;
                }
            }
        }
    }
    loadCurrentHints() {
        if (this.stepNumber) {
            this.hintHdng = this.hintMessages[this.stepNumber - 1]["hintHdng"];
            this.hintMsg = this.hintMessages[this.stepNumber - 1]["hintMsg"];
        }
        else {
            this.hintHdng = this.hintMessages[this.currentHintNumber]["hintHdng"];
            this.hintMsg = this.hintMessages[this.currentHintNumber]["hintMsg"];
        }
    }
    loadStepWiseHints() {
        this.hintHdng = this.hintMessages[this.stepNumber]["hintHdng"];
        this.hintMsg = this.hintMessages[this.stepNumber]["hintMsg"];
    }
    next(step: number) {
        this.backButton = true;
        this.closeButton = false;
        this.currentHintNumber = this.currentHintNumber + step;
        if (this.hintMessages.length - 1 == this.currentHintNumber) {
            this.nextButton = false;
            this.closeButton = true;
        }
        this.loadCurrentHints()
        //this.hintHdng = this.hintMessages[this.currentHintNumber]["hintHdng"];
        //this.hintMsg = this.hintMessages[this.currentHintNumber]["hintMsg"];
    }

    back(step: number) {
        this.currentHintNumber = this.currentHintNumber - step;
        this.nextButton = true;
        this.closeButton = false;
        if (this.currentHintNumber == 0) {
            this.backButton = false;
            //this.closeButton=true;
        }
        this.loadCurrentHints();
        //this.hintHdng = this.hintMessages[this.currentHintNumber]["hintHdng"];
        //this.hintMsg = this.hintMessages[this.currentHintNumber]["hintMsg"];
    }
    close() {
        this.dispHintDialog = false;
    }
}