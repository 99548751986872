import { Component, OnInit } from '@angular/core';
import { SessionDetails } from '../../../../utility/session';

@Component({
  selector: 'app-viss',
  templateUrl: './viss.component.html',
  styleUrls: ['./viss.component.css']
})
export class VissComponent implements OnInit {
  userProfile = SessionDetails.GetUserProfile();
  displayDownload:boolean=false;
  selectedIndex:number;
  footerText1="For your protection, only your name and email will be stored to identify your appointment. Email stored for appointments will not be used for any other purpose than communicating with the customer about the scheduled immunization clinic and will be flushed from our system after the clinic has been completed.";
  selectedResourcesFiles:any=[];
   walgreensResources : any = 
   {
    "resources": {
      "routine": [
        {
          "resourceName": "Pneumovax 23",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/ppv.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Prevnar 13",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/pcv.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Prevnar 20",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/pcv.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },{
          "resourceName": "Tdap (Boostrix, Adacel)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/tdap.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },        
        {
          "resourceName": "Meningococcal (MCV4 - Menveo, Menactra)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/mening.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Heplisav-B",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/hep-b.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Hepatitis B ",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/hep-b.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Hepatitis A",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/hep-a.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        
        
        {
          "resourceName": "Hepatitis A and B (Twinrix)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Hep-A_and_B_Twinrix.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "HPV (Gardasil 9)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/hpv.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Meningitis B (Trumenba)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/mening-serogroup.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Meningitis B (Bexsero)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/mening-serogroup.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "MMR (Measles Mumps Rubella)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/mmr.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Polio (Ipol Inactive)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/ipv.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Td (Tetanus and Diphtheria)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/td.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Shingrix",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/shingles.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        }        
      ],
      "flu": [
        {
          "resourceName": "Flu",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/flu.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        }
       
      ],
      "nonroutine": [
        {
          "resourceName": "Typhoid",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/typhoid.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Japanese Encephalitis(lxlaro)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/je-ixiaro.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Rabies (Imovax, Rabavert)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/rabies.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        }
      ],
      "COVID_19":[
        {
          "resourceName": "COVID-19",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://www.cdc.gov/vaccines/hcp/vis/vis-statements/COVID-19.pdf"
            }
          ]
        }        
      ]
    }
  }
  constructor() { }

  ngOnInit() {
    this.displayDownload = false;
  }
  showDownloadDialog(index,resource_category)
  {
    
    this.selectedResourcesFiles=this.walgreensResources.resources[resource_category][index].resourceFiles;
    this.displayDownload=true;
  }

  dispSelectedResource(selected_type){
    
  }
}
