import { Injectable } from "@angular/core";
export class Immunization {
    public clinicPk:number = 0;
    public immunizationPk: string = '';
    public immunizationName: string = '';
    public immunizationAbvNameEN : string = '';
    public paymentTypeName: string = '';
    public paymentTypeId: string = '';
    public estimatedQuantity: number;
    public clear() {
        this.clinicPk = 0;
        this.immunizationPk = '';
        this.immunizationName = '';
        this.paymentTypeName = '';
        this.paymentTypeId = '';
        this.estimatedQuantity = 0;
        this.immunizationAbvNameEN = '';
    }
}

export class Clinic {
    public clinicImzQtyList: Immunization[] = [];
    public location: string = '';
    public contactFirstName: string = '';
    public contactName:string = '';
    public contactLastName: string = '';
    public contactEmail: string = '';
    public contactPhone: string = '';
    public clinicDate: string = '';
    public startTime: string = '';
    public endTime: string = '';
    public address1: string = '';
    public address2: string = '';
    public city: string = '';
    public state: string = '';
    public zipCode: string = '';
    public isReassign: number = 0;
    public isNoClinic: number ;
    public previousContact: number = 0;
    public previousLocation: number = 0;
    public isCurrent: number = 1;
    public fluExpiryDate: string = '';
    public routineExpiryDate: string = '';
    public latitude: string = '';
    public longitude: string = '';
    public estShots:string='';
    public localvouchersDist:string='';
    public coOutreachTypeId:string;
    public coOutreachTypeDesc:string;
    public jobTitle:string;
    public visitType:string = "";
    public estimatedParticipants: number;

    // public dose1: number = 0;
    // public dose2: number = 0;
    public isCovidClinic:number = 0;
    public vouchersDistributed: number;
    public isNoClinicFee: boolean = false;
    public clinicFee: number;
    constructor(){
        this.isReassign = 0;
        this.isNoClinic = 0;
        
        this.clinicDate = null;
        this.estShots ="";
        this.fluExpiryDate = null;
        this.isCurrent = 1;
        this.isNoClinic = 0;
        this.isReassign = 0;
        this.latitude = null;
        this.longitude = null;
        this.routineExpiryDate=null;
        this.vouchersDistributed = 0;
    }
}

export class Immunization2 {    
    public immunizationPk: string;
    public immunizationName: string;
    public immunizationAbvNameEN : string;
    public immunizationSpanishName: string; 
    public price: number;
    public paymentTypeId: string;
    public paymentTypeName: string;
    public estimatedQuantity: number;
    public paymentTypeSpanishName: string
    public sendInvoiceTo: number;
    public name: string;
    public address1: string;
    public city: string;
    public state: string;
    public zip: string;
    public phone: string;
    public email: string;
    public email2: string;
    public isTaxExempt: number;
    public isCopay: number;
    public copayValue: number;
    public isVoucherNeeded: number;
    public voucherExpirationDate: string
    public showprice: number;
    public isClinicalReqd:Boolean;
    public clinicalReqdApprovedBy:number;
    public clinicalReqApprovedDate:string;
    public clear() {
        this.immunizationPk = '';
        this.immunizationName = '';
        this.paymentTypeName = '';
        this.paymentTypeId = '';
        this.estimatedQuantity = 0;
        this.isCopay = 0;
        this.isVoucherNeeded = 0;
        this.sendInvoiceTo = 0;
        this.showprice = 1;
        this.voucherExpirationDate = null;
        this.paymentTypeId = null;
        this.paymentTypeName = null;
        this.price = null;
    }
}

export class Immunizations {
    public Immunization: Immunization2[] = [];
}

export class Client {
    public client: string = '';
    public name: string = '';
    public title: string = '';
    public reviewedDate: string = '';
}

export class WalgreenCo {
    public name: string = '';
    public eSignature: string = '';
    public title: string = '';
    public preparedDate: string = '';
    public districtNumber: string = '';
    public rhdName:string="";
    public rhdEmail:string = "";
    public contactPhone:string = "";
    public contactEmail:string = "";
}

@Injectable()
export class LegalNoticeAddress {
    public attentionTo: string
    public address1: string
    public address2: string
    public city: string
    public state: string
    public zipCode: string
    constructor(){
    this.attentionTo= '';
    this.address1= '';
    this.address2= '';
    this.city= '';
    this.state= '';
    this.zipCode= '';
    }
}

export class contractThankYou{
    public storeState:string = '';
    public hasPrefilledForms:number = 0;
    public pdfVarFormEn:string = '';
    public pdfVarFormEsp:string = '';
}

export class ContractData {
    clear() {
        this.clinicImmunizationList.forEach(imz => imz.clear());
    }
    clinicAgreementPk:number;
    parentClinicAgreementId:number;
    contactLogPk:number;
    isApproved: number;
    isLocationReqAvailable:number;
    isHealthRoomAvailable:number;
    approvedOrRejectedBy: string;
    signature: string;
    rhdSignature: string;
    isEmailSent: number = 0;
    businessUserEmails:string;
    notes: string;
    dateCreated: Date;
    contractPostedByUser:string;
    isRHDApproved:number;
    licenseAgreementNum:string;
    isEmailSentToRHD:string;
    contactWagUser:string;
    hasServiceAgreementAccepted:boolean;
    modifiedBy:string;
    public dateApproved:Date;
    public dateRHDApproved:Date;
    public clinicList: Array<Clinic> = new Array<Clinic>();
    public clinicImmunizationList:Immunization2[]=[];
    public clientInfo: Client;
    public walgreenCoInfo: WalgreenCo;
    public legalNoticeAddressInfo: LegalNoticeAddress;
    public  contracTthankYou:contractThankYou;
    constructor() {
        this.contractPostedByUser = 'Walgreens User';
        this.contactWagUser = '';
        this.isEmailSent = null;     
        this.isEmailSentToRHD = null;
        this.isRHDApproved = null;
        this.isApproved   = null;
        this.licenseAgreementNum = null;
        this.isLocationReqAvailable = null;
        this.isHealthRoomAvailable = null;
        this.clientInfo = new Client();
        this.walgreenCoInfo = new WalgreenCo();
        this.legalNoticeAddressInfo = new LegalNoticeAddress();
        this.contracTthankYou = new contractThankYou();
    }
}
export class LanguageVariables {
    // language variables

    public thirdPartyRightsText:string ='';    
    public thirdPartyRights:string ='';

    public inWitnessWhereOff:string ='';

    public inWitnessWhereOff2ndLine:string = '';
  
    public wallGreensCommOffsiteTitle1:string = '';

    public wallGreensCommOffsiteTitle2:string = '';

    public wallGreensResponsibilities:string = '';

    public wallGreensResponsibilitiesPara1Heading:string = '';

    public sendLegalNoticeToClient:string ='';

    public sendLegalNoticeToWalgreens:string ='';

    public wallGreensResponsibilitiesCvrdServices:string ='';
 
    public profJudgementHeading:string='';

    public profJudgementText:string='';

    public provHealthCrProfHdng:string='';

    public provHealthCrProfText:string='';
 
    public clientsResponsibilites:string='';

    public vouchersHeading:string='';

    public vouchersText:string='';

    public offSiteLocation:string='';

    public eligibleParticipants:string='';

    public important:string='';

    public offSiteLocationText1:string='';

    public eligibleParticipantsText1:string='';

    public siteMin:string='';

    public siteAvg:string='';

    public siteMinText:string='';

    public siteAvgText:string='';

    public pmtAndBIlling:string='';

    public paymentHd:string='';

    public paymentText:string='';

    public latePayment:string='';

    public latePaymentText:string='';
    
    public costIncreasetext:string='';  
    
    public costIncrease:string='';  

    public termAndTermCaps:string='';

    public termAndTerm:string='';
 
    public termAndTermText:string='';

    public effectAndTerminiation:string='';

    public effectAndTerminiationText:string='';

    public waiver:string='';

    public waiverText:string='';

    public insurAndIndemnify:string='';

    public insurance:string='';

    public insuranceText:string='';

    public indemnification:string='';

    public indemnificationText:string='';

    public generalTerms:string ='';

    public confidentiality:string='';

    public confidentialityP1:string='';

    public phi:string = '';

    public phiAb:string='';

    public confidentialityP2:string='';
    public confidentialityUniversalText:string='';
    public singleStarTAndC:string='';
    public singleStarTAndC2:string=''
    public article2Text:string ='';

    public Advertising:string ='';

    public AdvertisingText:string ='';
 
    public frcMaj:string='';

    public frcMajText:string='';

    public compliance:string='';

    public complianceText:string='';

    public assignment:string='';

    public assignmentText:string='';

    public notices:string='';

    public noticesText:string='';
 
    public entireAgr:string='';

    public entireAgrText:string='';

    public counterPart: string = '';
    public counterPartsText: string = '';

    public rights:string = '';

    public businessName:string ='';
    public name:string = "";
    public eSignature: string = ""; 
    public title:string = '';
    public attentionTo: string;
    public date:string = '';
    public district:string = '';
    public attentionToTxt:string = '';
    public address1:string = '';
    public address2:string = '';
    public city:string = '';
    public state:string = '';
    public zip:string = '';
    public approveAgrmnt:string = '';
    public approveText:string = '';
    public electrSign:string = '';
    public notes:string = '';
    public pageTitle:string = '';
    public approve:string = '';
    public reject:string = '';
    public submit:string = '';
    public printAgreementTxt:string = '';
    public clinicLocation:string= '';
    public clinicDate:string = '';
    public clinicTime:string = '';
    public contact:string = '';
    public phone:string = '';
    public estShots:string =''
    public location:string = '';
    public time:string = ''; 
    public email:string = '';
    public emailAgrmntTo= '';
    public multipleEmailAddrss = '';
    public sendAgreeMnt = '';
    public editAgreeMnt = '';
    public saveAgreeMnt = '';
    public cancelTxt = '';
    public noteTxt = '';
    public table1 = '';
    public cancel:string = '';
    public contactName:string = "";
    public contactPhone:string = "";
    public contactEmail:string = "";
    public dose:string = "";
    public privateClinicFees:string = "";
    public privateClinicFeesText:string = "";
    public businessConfidentiality: string = "";
    public businessConfidentialityText: string = "";
    public clinicFee: string = ""; 
    public participants = "";
}
