import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ResourceCountDetails} from '../../../models/resourceCountDetails';
import { SessionDetails } from '../../../utility/session';

@Injectable()
export class ResourcecountService {

  constructor(private http: HttpClient) { }

  resourceItemCount(data: ResourceCountDetails) {
    let url = environment.API_URL + environment.RESOURCES.RESOURCE_COUNT;
    return this.http.post(url,data, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));;
  }

  getResourceCount(requestData: ResourceCountDetails){
    let userData= SessionDetails.GetUserInfo()
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    let url = `${environment.API_URL}/${environment.RESOURCES.GET_RESOURCE_COUNT}/${requestData.userId}/${requestData.reportName}`;
       return this.http.get(url, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
       .pipe(catchError(Util.handleErrorV2));
}

}