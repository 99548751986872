import { Component,  OnInit } from '@angular/core';
import * as appSettings from '../../globals';
import {  FormGroup,  FormBuilder, Validators } from '@angular/forms';
import { saveAs as importedSaveAs } from "file-saver";
import { WorkflowmonitorService } from '../../services/workflowmonitor.service';
import { WorkflowMonitor } from '../../../../models/WorkflowMonitor';
import { SessionDetails } from '../../../../utility/session';
import { ErrorMessages } from "../../../../config-files/error-messages";

@Component({
  selector: 'app-workflowmonitorreport',
  templateUrl: './workflowmonitorreport.component.html',
  styleUrls: ['./workflowmonitorreport.component.css']
})
export class WorkflowMonitorReportComponent implements OnInit {
  workFlowReportForm:FormGroup;
  workFlowReportArray: any;
  userProfile: any;
  private outreachEffort = appSettings.outreachEffortIP;
  totalRecords: number;
  loading: boolean;
  ddlFormat: any;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  constructor(private fb: FormBuilder,
    private workflowreport: WorkflowmonitorService,
  ) {
    this.userProfile = SessionDetails.GetUserProfile();     
  }

  ngOnInit() {
    this.ddlFormat = "";
    this.loading = true;
    this.setFormControls();
    this.generateReport();
  }

  setFormControls() {
    
    this.workFlowReportForm = this.fb.group({
      
       'ddlFormat': [this.ddlFormat],
      
     });
     //this.generateReport();
   }

  generateReport(): void {    
    
    let data= this.workFlowReportForm.value;
    let workflow_monitor = new WorkflowMonitor(this.userProfile.userPk, this.userProfile.userRole, this.outreachEffort, "", "");
    this.workflowreport.getWorkflowMonitorReportData(workflow_monitor).subscribe((resultArray: any) => {     
      this.totalRecords = resultArray.length;
      //console.log("totalRecords:" + this.totalRecords);
      this.workFlowReportArray = resultArray;
      //console.log("this.scheduledClinicReportArray:" + this.workFlowReportArray.length);
      setTimeout(() => {
        this.loading = false;
      }, 1000)
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.workFlowReportArray = [];
      this.totalRecords = 0;
    });
  }
  generateReportFormat(): void {
    
    this.loading = true;
    let data= this.workFlowReportForm.value;
    let workflow_monitor = new WorkflowMonitor(this.userProfile.userPk, this.userProfile.userRole, this.outreachEffort, "WorkflowMonitor", "xlsxv");
    this.workflowreport.exportReportData(workflow_monitor).subscribe((res:any) => {
      importedSaveAs(res.body, res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1]);
      this.loading = false;
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
  validateExportType(){
    if(this.ddlFormat=="")
    return true;
    else
     return false;
  }
    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}
