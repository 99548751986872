import { Injectable, OnInit } from '@angular/core';
import { OpportunitiesService } from '../store/services/opportunities.service';

@Injectable()
export class AppCommonSession implements OnInit  {
  public login_info:any;
  ngOnInit()
  {
    
  }
constructor(private _service:OpportunitiesService)
{
    // this._service.setLoginUserInfo('Admin').subscribe((opportunities) => {
    //     this.login_info = opportunities;
    //   },
    //     error => {
    //       console.error("Error in fetching opportunities", error);
    //     })
  
}
    // setLoginUserInfo(user_role:any)
    // {
    //     return this.http.get("http://localhost:49940/GetUserInformation?user_role="+user_role+"").map((response: Response) => this.login_info = response.json());
    // }

}
