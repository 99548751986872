export class OpportunityInfo {
    businessPk: number;
    outreachBusinessPk: number;
    clinicAgreementPk: number;
    contractedStoreId: number;
    storeId: number;
    storeState: string;
    regionNumber: number;
    clinicType: number;
    businessName: string;
    firstName?: any;
    lastName?: any;
    businessAddress: string;
    businessAddress2: string;
    businessCity: string;
    businessState: string;
    businessZip: string;
    jobTitle?: any;
    businessContactEmail?: any;
    phone: string;
    maxClinicLocationId: number;
    naClinicAddlComments?: any;
    licenseAgreementNum:number;
}

export class ClinicImzQtyList {
    clinicPk: number;
    immunizationPk: number;
    immunizationName: string;
    paymentTypeId: number;
    paymentTypeName: string;
    estimatedQuantity: number;
    totalImmAdministered?: number;
    preliminaryPositives?:number;
    price: any;
    showPrice: any;
}

export class ClinicList {
    clinicPk: number;
    clinicStoreId: number;
    clinicNumber: number;
    naClinicLocation: string;
    naContactFirstName: string;
    naContactLastName: string;
    naContactEmail: string;
    naClinicAddress1: string;
    naClinicAddress2: string;
    naClinicCity: string;
    naClinicState: string;
    naClinicZip: string;
    naClinicContactPhone: string;
    clinicDate: any;//
    clinicScheduledOn: Date;
    naClinicStartTime: string;
    naClinicEndTime: string;
    confirmedClientName?: any;
    clinicLatitude?: any;
    clinicLongitude?: any;
    isCompleted: any;
    isConfirmed: any;
    isCancelled: any;
    coOutreachTypeId?: any;
    coOutreachTypeDesc?: any;
    clinicRoom: any;
    isScheduled: any;
    naClinicContactPhoneExt: any;
    clinicImzQtyList: ClinicImzQtyList[];
}

export class ClinicImmunizationList {
    pk: number;
    clinicPk: number;
    immunizationPk: number;
    immunizationName: string;
    price: number;
    paymentTypeId: number;
    paymentTypeName: string;
    sendInvoiceTo: number;
    name: string;
    address1?: any;
    address2?: any;
    city?: any;
    state?: any;
    zip?: any;
    phone?: any;
    email?: any;
}

export class ClinicsUpdateHistoryLogList {
    pk: number;
    updatedOn: any;
    updateAction: string;
    updatedField?: any;
    updateSubCategory?: any;
    updatedValue?: any;
    updatedBy: string;
}

export class ProofOfDocs{
    pk:number;
    clinicAgreementPk:number
    fileName:string;
    fileDisplayName:string;
}

export class ErrorS {
    errorCode: number;
    errorMessage?: any;
}

export class PCATestingEventDetails {
    userPk: number;
    clinicContactStatus?: any;
    feedback?:string;
    opportunityInfo: OpportunityInfo;
    clinicList: ClinicList[];
    clinicImmunizationList: ClinicImmunizationList[];
    clinicsUpdateHistoryLogList: ClinicsUpdateHistoryLogList[];
    proofOfDocs: ProofOfDocs[];
    errorS: ErrorS;
    constructor() {
        this.opportunityInfo = new OpportunityInfo();
        this.clinicList = new Array(new ClinicList());
        this.clinicImmunizationList = new Array(new ClinicImmunizationList());
        this.clinicsUpdateHistoryLogList = new Array(new ClinicsUpdateHistoryLogList());
    }
}


