import { Component, OnInit } from '@angular/core';
import { SessionDetails } from '../../../../utility/session';

@Component({
  selector: 'app-senioroutreach',
  templateUrl: './senioroutreach.component.html',
  styleUrls: ['./senioroutreach.component.css']
})
export class SenioroutreachComponent implements OnInit {
  userProfile = SessionDetails.GetUserProfile();
  displayDownload:boolean=false;
  selectedIndex:number;
  selectedResourcesFiles:any=[];
   walgreensResources : any = 
   {
    "resources": {
      "SeniorPresentations": [
        {
          "resourceName": "The ABCD's of Medicare",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/ABCDsOfMedicare(FullVersion).pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/ABCDsOfMedicare(FullVersion).pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "The ABCD's of Medicare Short Version",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/ABCDsOfMedicare(ShortVersion).pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/ABCDsOfMedicare(ShortVersion).pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },{
          "resourceName": "Blood Pressure & Cholesterol Management",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/BloodPressureAndCholesterol.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/BloodPressureAndCholesterol_Notes.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/BloodPressureAndCholesterol.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },        
        {
          "resourceName": "Blood Pressure & Cholesterol - Spanish",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Blood_Pressure_and_Cholesterol-Spanish.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Blood_Pressure_and_Cholesterol-Spanish-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Blood_Pressure_and_Cholesterol-Spanish.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Cough, Cold, and Flu in Older Adults",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/CoughAndColdOlderAdults.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/CoughAndColdOlderAdults_Notes.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/CoughAndColdOlderAdults.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Cost Saving Tips for Your Medications",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/CostSavingsMedications.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/CostSavingsMedications_Notes.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/CostSavingsMedications.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Dementia & Alzheimer's Disease",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Dementia_and_Alzheimers.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Dementia_and_Alzheimers-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Dementia_and_Alzheimers.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        
        
        {
          "resourceName": "Depression in Older Adults",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Depression_in_Older_Adults.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Depression_in_Older_Adults-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Depression_in_Older_Adults.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Diabetes Management Basics",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Diabetes_Management_Basics.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Diabetes_Management_Basics-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Diabetes_Management_Basics.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Diabetes Management Basics - Spanish",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Diabetes-Spanish.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Diabetes-Spanish-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Diabetes-Spanish.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Drug Interactions",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Drug_Interactions.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Drug_Interactions-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Drug_Interactions.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Eating for Healthy Aging",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Eating_for_Healthy_Aging.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Eating_for_Healthy_Aging-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Eating_for_Healthy_Aging.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Exercise & Physical Activity",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Exercise_and_Physical_Activity.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Exercise_and_Physical_Activity-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Exercise_and_Physical_Activity.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Fall Prevention",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Fall_Prevention.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Fall_Prevention-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Fall_Prevention.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "General Senior Issues",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/General_Senior_Issues.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/General_Senior_Issues-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/General_Senior_Issues.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Hypertension",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Hypertension.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Hypertension-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Hypertension.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Immunizations",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Immunizations.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Immunizations-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Immunizations.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Medication Adherence",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Medication_Adherence.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Medication_Adherence-NOTES.pdf"
           },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Medication_Adherence.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Medication Safety",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Medication_Safety.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Medication_Safety-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Medication_Safety.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Nutrition & Exercise",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Nutrition_and_Exercise.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Nutrition_and_Exercise-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Nutrition_and_Exercise.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Osteporosis Prevention & Management",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Osteporosis_Presentation.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Osteporosis_Presentation-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Osteporosis_Presentation.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Parkinson's Disease",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Parkinsons_Disease.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Parkinsons_Disease-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Parkinsons_Disease.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Poison Prevention",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Poison_Prevention.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Poison_Prevention-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Poison_Prevention.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Quit Smoking Now",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Quit_Smoking_Education.pdf" 
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Quit_Smoking_Education-NOTES.pdf" 
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Quit_Smoking_Education.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Safe Medication Disposal",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Safe_Medication_Disposal.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Safe_Medication_Disposal-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Safe_Medication_Disposal.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Skin Care for Seniors",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Skin_Care_for_Seniors.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Skin_Care_for_Seniors-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Skin_Care_for_Seniors.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Stress Management in Older Adults",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Stress_Management_in_Older_Adults.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Stress_Management_in_Older_Adults-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Stress_Management_in_Older_Adults.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },       
        {
          "resourceName": "Tips to Avoid Being Scammed",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Scams%20on%20Customers.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Scams%20on%20Customers.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "The Walgreens App - An Overview",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Walgreens_App-An_Overview-2019.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Walgreens_App-An_Overview-2019-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Walgreens_App-An_Overview-2019.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Vitamins, Herbals & Supplements",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Vitamins_Herbals_and_Supplements.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Vitamins_Herbals_and_Supplements-NOTES.pdf"
            },
            {
              "resourceType": "PPTX",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pptx/Vitamins_Herbals_and_Supplements.pptx"
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        }
      ],
      "CommunityOutreachMaterials": [
        {
          "resourceName": "Senior Outreach - Talking points",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/senior_outreach_job_aid.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Senior Outreach Letter",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/senior_outreach_letter.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "AARP State Office Contact List",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/AARP-State-Office-Contact-List-April-2014.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/AARP-State-Office-Contact-List-April-2014.xlsx"
            }
          ]
        }
      ],
      "MedBMaterials": [
        {
          "resourceName": "Med-B Patient FAQ",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Medicare_Part-B_Patient_FAQ.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Med-B Diabetes Testing Supplies Presentation",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Medicare_Part_B_Diabetes_Testing_Supplies_Presentation.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Med-B Flyer",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Medicare_Part-B_Flyer.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
        {
          "resourceName": "Med-B Flyer(Spanish)",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Medicare_Part-B_Flyer(SP).pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        }
      ]
    }
  }
  constructor() { }

  ngOnInit() {
    this.displayDownload = false;
  }
showDownloadDialog(index,resource_category)
  {
    
    
    this.selectedResourcesFiles=this.walgreensResources.resources[resource_category][index].resourceFiles;
    this.displayDownload=true;
  }

  dispSelectedResource(selected_type){
    
    
  }
}
