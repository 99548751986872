import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import {  WalgreenCo, Client } from '../../../models/contract';
import { PcaContract, PcaImmunization, PcaEvent, PcaEventSchedule, OpportunityInfo } from '../../../models/pcaclinic';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PcaContractsService {
  private formData: PcaContract = new PcaContract();
  private contractData: PcaContract = new PcaContract();
  private lulaTandC:any;
  private isDataValidationsRequired:boolean = false;

  constructor(private _http: HttpClient) {}
  getImmunizationsList(agreement_pk, clinic_type) {
    let url = environment.API_URL + environment.RESOURCES.GET_IMMUNIZATION_LIST + "/" + agreement_pk + "/" + clinic_type;
    return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}

getImmunizationsData() {
  if (this.formData.immunization !== undefined) {
     let immunizationArray = [];
     if(this.formData.immunization != null){
     immunizationArray.push(this.formData.immunization);
     }
      return immunizationArray;
  }
  else {
      return [];
  }
}
wipeData(){
this.contractData.wipeData(); 
this.formData.wipeData();
}
setImmunizationsData(data){
  if(this.formData == null ){
    this.formData = new PcaContract();
  }
  if(!this.formData.immunization  || !this.formData.immunization.length) {
    this.formData.immunization = new Array<PcaImmunization>();
  }
  if(this.formData.pcaClinic == null ) {
    this.formData.pcaClinic = new PcaEvent();
  }

  // if(data[0] != undefined && data[0].immunizationPk != undefined && data[0].immunizationPk != null) {
    // this.formData.immunization.immunizationPk = data[0].immunizationPk;
    // this.formData.immunization.immunizationName = data[0].immunizationName;
    // this.formData.pcaClinic.immunizationPk = data[0].immunizationPk;
    // this.formData.pcaClinic.immunizationName = data[0].immunizationName;
    // }
    if(data.length <=0){
      this.formData.immunization = null;    
    } else {
      if(!this.formData.immunization.length){
        data.map((immunization) => {
          this.formData.immunization.push({immunizationPk: immunization.immunizationPk, immunizationName: immunization.immunizationName});
          // this.formData.pcaClinic.push({immunizationPk: immunization.immunizationPk, immunizationName: immunization.immunizationName});
        })
      } else {
        let immunizationiPkArray = this.formData.immunization.map((immunization) => immunization.immunizationPk);
        data.map((obj)=> {
          if(!immunizationiPkArray.includes(obj.immunizationPk)){
            this.formData.immunization.push({immunizationPk: obj.immunizationPk, immunizationName: obj.immunizationName});
            this.formData.pcaClinic.immunization.push({immunizationPk: obj.immunizationPk, immunizationName: obj.immunizationName});
          }
        })
      }
    }

}
fetchPcaContractAgreementData() {
  if(this.formData !== undefined){
    return this.formData;
  }
  return null;
}

setSelectedImmunization(imm:any){
  let immunizationiPkArray = this.formData.pcaClinic.immunization.map((immunization) => immunization.immunizationPk);
  if(this.formData.pcaClinic.immunization.length || !immunizationiPkArray.includes(imm.immunizationPk))
    this.formData.pcaClinic.immunization.push({
      immunizationPk:imm.immunizationPk,
      immunizationName: imm.immunizatioName
    })
}

getPcaContractData(){
if(this.formData !== undefined){
  return this.formData;
}
return null;
}
setPcaContractData(pcaCtData:any){
  this.formData = pcaCtData;
}
setWalgreensWitnessData(wallgreenCoData:any){
  if( this.formData.walgreenCoInfo == null ){
    this.formData.walgreenCoInfo = new WalgreenCo();
  }
  this.formData.walgreenCoInfo = wallgreenCoData;
}
setTestingLocation(testingLocation){
  this.formData.isLocationReqAvailable = testingLocation.isLocationReqAvailable;
  this.formData.isHealthRoomAvailable = testingLocation.isHealthRoomAvailable;
  this.formData.pcaClinic = testingLocation;
  this.formData.pcaEvenScheduleList = testingLocation.locations;
}
getStoreRhdDetails(storeId){
  let url = environment.API_URL + environment.RESOURCES.GET_RHD_DETAILS + "/" + storeId;
  return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}
getTotalCancelEvents(storeId,clinicAgreementPk){
  let url = environment.API_URL + environment.RESOURCES.GET_CANCEREVENTS_COUNT + "/" + storeId+"/"+clinicAgreementPk;
  return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}
getTpOpportunityDetails(agreementPk:number, outReachPk:number){
  let url = environment.API_URL + environment.RESOURCES.GET_TPOPPORTUNITY_DETAILS + "/" + agreementPk + "/" + outReachPk;
  return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}
getTpOpportunityDetailsUsingBusinessPk(businessPk:number){
  let url = environment.API_URL + environment.RESOURCES.GET_PCA_OpportunityDetails + "/" + businessPk;
  return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}
setValidationsRequiredStatus(isvalidationsRequired:boolean){
  this.isDataValidationsRequired = isvalidationsRequired;
}
getValidationsRequiredStatus(){
  return this.isDataValidationsRequired;
}
cacheDBContractData(dbData){  
    this.formData.clinicAgreementPk = this.contractData.clinicAgreementPk = dbData.clinicAgreementPk;
  this.formData.contactLogPk = this.contractData.contactLogPk = dbData.contactLogPk;
  this.formData.isLocationReqAvailable = this.contractData.isLocationReqAvailable= dbData.isLocationReqAvailable;
  this.formData.isHealthRoomAvailable = this.contractData.isHealthRoomAvailable= dbData.isHealthRoomAvailable;
  if(dbData.clinicImmunizationList != null && dbData.clinicImmunizationList != undefined && 
    dbData.clinicImmunizationList.length > 0){
  this.contractData.immunization = new Array<PcaImmunization>();
  this.formData.immunization = new Array<PcaImmunization>();
  this.formData.immunization = dbData.clinicImmunizationList;
  this.contractData.immunization = dbData.clinicImmunizationList;
  
  if(dbData.clinicList != null && dbData.clinicList != undefined && 
    dbData.clinicList.length > 0){
this.contractData.pcaClinic = new PcaEvent();
this.formData.pcaClinic = new PcaEvent();
this.formData.pcaClinic = this.contractData.pcaClinic = dbData.clinicList[0];
this.formData.pcaEvenScheduleList = this.contractData.pcaEvenScheduleList = [];
dbData.clinicList.forEach(element => {
  let pcaEventSchedule = new PcaEventSchedule();
  pcaEventSchedule.eventDate = element.clinicDate;
  pcaEventSchedule.startTime = element.startTime;
  pcaEventSchedule.endTime = element.endTime;
  pcaEventSchedule.estimatedQuantity = element.clinicImzQtyList[0].estimatedQuantity;
  pcaEventSchedule.location = element.location;
  this.contractData.pcaEvenScheduleList.push(pcaEventSchedule);

});
this.formData.pcaEvenScheduleList = this.contractData.pcaEvenScheduleList;
this.contractData.walgreenCoInfo = new WalgreenCo();
this.contractData.walgreenCoInfo = dbData.walgreenCoInfo;
this.contractData.testingPartner = new OpportunityInfo();
this.formData.testingPartner = this.contractData.testingPartner = dbData.testingPartnerInfo;
this.formData.store = this.contractData.store = dbData.storeInfo;
this.contractData.licenseAgreementNum = dbData.licenseAgreementNum;
this.contractData.businessUserEmails = dbData.businessUserEmails;
this.contractData.isEmailSent = dbData.isEmailSent;
this.contractData.isApproved = dbData.isApproved;
this.contractData.isRhdApproved = dbData.isRHDApproved;
this.formData.isCurrent = this.contractData.isCurrent = dbData.isCurrent;
this.contractData.clinicAgreementPk = dbData.clinicAgreementPk;
this.contractData.approvedOrRejectedBy = dbData.approvedOrRejectedBy;
this.contractData.contactLogPk = dbData.contactLogPk;
this.contractData.lastUpdatedDate = dbData.lastUpdatedDate;
this.contractData.dateCreated =dbData.dateCreated;
this.contractData.dateApproved =dbData.dateApproved;
this.contractData.dateRHDApproved =dbData.dateRHDApproved;
this.formData.notes = this.contractData.notes = dbData.notes;
this.formData.signature = this.contractData.signature = dbData.signature;
this.formData.rhdSignature = this.contractData.rhdSignature = dbData.rhdSignature;
if(dbData.clientInfo){
  this.formData.clientInfo = new Client();
  this.contractData.clientInfo = new Client();
  this.contractData.clientInfo=this.formData.clientInfo = dbData.clientInfo;
}
}
 return this.contractData;
}
    
}
getPCAContractAgreement(agreement_pk)
{
  var url = environment.API_URL + environment.RESOURCES.GET_PCA_CONTRACTAGREEMENT + "/"+ agreement_pk;
  return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}
setLulaTandC(tAndC:any){
  this.lulaTandC = tAndC;
}
getLulaTandC(){
  return this.lulaTandC;
}
validateRHDRVPEmails(store_id){  
  var url = environment.API_URL + environment.RESOURCES.VALIDATE_RHD_RVP+"/"+store_id ;
  return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}
}
