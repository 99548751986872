import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ApproveagreementComponent } from './components/approveagreement/approveagreement.component';
import { BusinesscontactpageComponent } from './components/businesscontactpage/businesscontactpage.component';
import { ContractapproveService } from './services/contractapprove.service';
import { FieldErrorDisplayComponent } from '../common/components/field-error-display/field-error-display.component';
import { ReusableModule } from '../common/components/reusable.module';
//import { Utility } from '../common/utility';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { ClientInfo } from '../../models/client-info';
import { Globalvariables } from '../../models/globalvariables';
import { ContractapproveComponent } from './components/contractapprove/contractapprove.component';
import { NgPipesModule } from 'ngx-pipes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OutreachProgramService } from './services/outreach-program.service';
import { WorkflowService } from './workflow/workflow.service';
import { ContractComponent } from './components/local-contracts/contract.component';
import { ImmunizationsComponent } from './components/immunizations/immunizations.component';
import { LocationsComponent } from './components/contract-locations/locations.component';
import { WitnessComponent } from './components/contract-pharmacy-info/witness.component';
import { AgreementComponent } from './components/contract-agreement/agreement.component';
import { LocationsListComponent } from './components/contract-locations-list/locations-list.component';
import { CharityLocationsComponent } from './components/charity-locations/charity-locations.component';
import { CharitylocationsListComponent } from './components/charity-locations-list/charity-locations-list.component';
import { CommunityoutreachComponent } from './components/communityoutreach/communityoutreach.component';
import { Util } from '../../utility/util';
import { ViewcontractAgreementComponent } from './components/viewcontract-agreement/viewcontract-agreement.component';
import { LegalNoticeAddress } from '../../models/contract';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaFormsModule } from 'ng-recaptcha';
import { LeadOpportunitiesService } from './services/lead-opportunities.service';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MessagesModule } from 'primeng/messages'
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { UnsubscribeService } from './services/unsubscribe.service';
import { CustomAgreementGroupByPipe, CustomGroupByPipe, CustomLocationGroupByPipe } from './pipes/CustomGroupBy.pipe';
import { CustomUniquePipe } from './pipes/unique.pipe';
import { AgreementComponent2022 } from './components/contract-agreement-2022/agreement.component-2022';
import { ApproveagreementComponent2022 } from './components/approveagreement-2022/approveagreement.component-2022';
import { ViewcontractAgreementComponent2022 } from './components/viewcontract-agreement-2022/viewcontract-agreement.component-2022';
import { AgreementComponent2023 } from './components/contract-agreement-2023/agreement.component-2023';
import { ApproveagreementComponent2023 } from './components/approveagreement-2023/approveagreement.component-2023';
import { ViewcontractAgreementComponent2023 } from './components/viewcontract-agreement-2023/viewcontract-agreement.component-2023';
import { EquityeventsComponent } from './components/equityevents/equityevents.component';
import { ViewcontractAgreement2024Component } from './components/viewcontract-agreement-2024/viewcontract-agreement-2024.component';
import { AgreementComponent2024 } from './components/contract-agreement-2024/agreement.component-2024';
import { Approveagreement2024Component } from './components/approveagreement-2024/approveagreement-2024.component';
import { ContactUsLocationComponent } from './components/contact-us-locations/contact-us-location.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    ReusableModule,
    KeyFilterModule,
    ConfirmDialogModule,
    DialogModule,
    CalendarModule,
    BrowserAnimationsModule,
    //  GrowlModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    KeyFilterModule,
    ReusableModule,
    NgPipesModule,
    InputMaskModule,
    RecaptchaModule, 
    NgxSpinnerModule
  ],
  declarations: [
    ContractComponent, 
    ImmunizationsComponent, 
    LocationsComponent, 
    WitnessComponent,
    AgreementComponent, 
    AgreementComponent2022, 
    LocationsListComponent,
    CharityLocationsComponent, 
    CharitylocationsListComponent, 
    CommunityoutreachComponent, 
    ContractapproveComponent,
    BusinesscontactpageComponent, 
    ApproveagreementComponent, 
    ApproveagreementComponent2022, 
    ThankyouComponent, 
    ViewcontractAgreementComponent, 
    ViewcontractAgreementComponent2022, 
    ContactUsComponent,
    MaintenanceComponent, 
    CustomGroupByPipe, 
    CustomLocationGroupByPipe, 
    CustomAgreementGroupByPipe, 
    CustomUniquePipe, 
    AgreementComponent2023, 
    ApproveagreementComponent2023, 
    ViewcontractAgreementComponent2023, 
    EquityeventsComponent, 
    ViewcontractAgreement2024Component, 
    AgreementComponent2024, 
    Approveagreement2024Component,
    ContactUsLocationComponent
  ],
  providers: [
    OutreachProgramService,
    WorkflowService,
    MessageService,
    ConfirmationService,
    ContractapproveService,
    ClientInfo,
    LegalNoticeAddress,
    Globalvariables,
    Util,
    LeadOpportunitiesService,
    UnsubscribeService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LeKNuIUAAAAAPOrmf3IVQt3BmLsABxIQW3JwWXm',
      } as RecaptchaSettings,
    }
  ],
})
export class ContractagreementModule { }
