
    export class PrimaryAddress {
        storeId: number;
        businessPk: number;
        businessName: string;
        outreachPk: number;
        phone: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        zipCode: string;
    }

    export class DuplicateAddressList {
        storeId: number;
        businessPk: number;
        businessName: string;
        outreachPk: number;
        phone: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        zipCode: string;
    }

    export class DuplicateBusiness {
        totalS: number;
        totalRecs: number;
        primaryAddress: PrimaryAddress;
        duplicateAddressList: DuplicateAddressList[];
        constructor()
        {
            this.primaryAddress=new PrimaryAddress();
        }
    }



