// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a:focus { border:1px solid #003366 !important; }
input:focus { border:1px solid #003366 !important; }
button:focus { border: 1px solid #003366 !important; }
select.form-control:focus {
border: 1px solid #003366 !important;
}
label:focus:before, label:active:before, label:focus i, label:active i {
    border: 1px solid #003366 !important;
}
/* radio:focus:before,  radio:focus i {
    border: 1px solid #003366 !important;
} */

/* p-calendar .ui-inputtext:focus{
    border: 1px solid #003366 !important;
  }
   p-inputmask .form-group:focus .ui-inputtext:focus .ui-inputtext:active  .ui-corner-all:focus .ui-widget:focus{
    border: 1px solid #006622 !important;
  }  */
`, "",{"version":3,"sources":["webpack://./src/app/modules/contractaggreement/components/contact-us/contact-us.component.css"],"names":[],"mappings":"AAAA,UAAU,mCAAmC,EAAE;AAC/C,cAAc,mCAAmC,EAAE;AACnD,eAAe,oCAAoC,EAAE;AACrD;AACA,oCAAoC;AACpC;AACA;IACI,oCAAoC;AACxC;AACA;;GAEG;;AAEH;;;;;MAKM","sourcesContent":["a:focus { border:1px solid #003366 !important; }\r\ninput:focus { border:1px solid #003366 !important; }\r\nbutton:focus { border: 1px solid #003366 !important; }\r\nselect.form-control:focus {\r\nborder: 1px solid #003366 !important;\r\n}\r\nlabel:focus:before, label:active:before, label:focus i, label:active i {\r\n    border: 1px solid #003366 !important;\r\n}\r\n/* radio:focus:before,  radio:focus i {\r\n    border: 1px solid #003366 !important;\r\n} */\r\n\r\n/* p-calendar .ui-inputtext:focus{\r\n    border: 1px solid #003366 !important;\r\n  }\r\n   p-inputmask .form-group:focus .ui-inputtext:focus .ui-inputtext:active  .ui-corner-all:focus .ui-widget:focus{\r\n    border: 1px solid #006622 !important;\r\n  }  */\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
