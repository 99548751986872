import { Component, OnInit, ViewChild } from '@angular/core';
import {  FormArray, FormGroup, NgForm } from '@angular/forms';
import { OutreachProgramService } from '../../services/outreach-program.service';
import { ImmunizationsComponent } from '../../components/immunizations/immunizations.component';
import { LocationsListComponent } from '../../components/contract-locations-list/locations-list.component';
import { WitnessComponent } from '../../components/contract-pharmacy-info/witness.component';
import { WorkflowGuard } from '../../workflow/workflow-guard.service';
import { STEPS } from '../../workflow/workflow.model';
import { OutReachProgramType } from '../../../../models/OutReachPrograms';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { SessionDetails } from '../../../../utility/session';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { String } from 'typescript-string-operations';
import { Location, DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { AlertService } from '../../../../alert.service';
import { WorkflowService } from '../../workflow/workflow.service';
import { TimeToDatePipe } from '../../pipes/time-to-date.pipe';
import { universalContractReleaseDate } from '../../../../../app/JSON/UniversalTandC';
import { getContractComponentName } from '../../../../utility/loadContracts';
import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';
import { ClinicDetailsUtilities } from '../../../../../app/utility/clinicDetailsUtilities';
@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css'],
  providers: [WorkflowGuard, TimeToDatePipe, DatePipe]
})
export class ContractComponent implements OnInit {
  @ViewChild(ImmunizationsComponent) imzcmp: ImmunizationsComponent;
  @ViewChild(LocationsListComponent) loccmp: LocationsListComponent;
  @ViewChild(WitnessComponent) witcmp: WitnessComponent;
  
  addedImmunizations: any[] = [];
  InvoiceInformation:any[]=[];
  validateInvoceInfo: boolean = false;
  invoiceInfoPopUp: boolean = false;
  cancel_check:boolean = false;
  contractDataDBSnapShot:any;
  showHints:boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff"))=='yes')?false:true;
  pageName:string = ((sessionStorage.getItem("AmendAgreement"))=='true')?'amendcontract':'contract';

  step: number = 1;
  step_desc: string;
  contractData: any;
  form: NgForm
  errorse: any;
  errorstatus: any;
  OutReachProgramType: string;
  dialogSummary: string;
  dialogMsg: string;
  display: boolean = false;
  //displayInventoryWarning: boolean = false;
  //displayInventoryWarningOkClicked:boolean = true;
  immunizerWarningAlert:boolean = false;
  immunizerWarningOkClicked:boolean = false;
  cancel_save: string;
  savePopUp: boolean = false; 
  isLocationsFormValid:boolean = false; 
  isPharmacyFormValid:boolean = false;
  isPharmacyFormValidResp;
  startTimeEndTimesStep_desc:string ;
  showStartTimeEndTimeValid :boolean = false;
  isTimeValid:Number = 0;
  OverrideOrOkTitle:string = "Override";
  showCancelBtn:boolean = false;
  inNoclinicPopUp: boolean = false;  
  showPreUniversalContract: boolean = false;
  componentToRenderName : string ='';
  covidVaccineSelected = false;
  covidVaccineAdminConfirmation = false;
  covidVaccineWarningOkClicked = false;
  isAdmin = false;
  estimatedParticipantsError = false;
  showEstimatedParticipantsWarning = false;
  estimatedParticipantsAdminConfirmation = false;
  showParticipantsShotsMismatchWarning = false;
  immunizationShotsParticipantsMismatchError = false;
  immunizationShotsParticipantsMismatchAdminConfirmation = false;

  constructor(
    private _localContractService: OutreachProgramService,
    private _workflowGuard: WorkflowGuard,
    private confirmationService: ConfirmationService, 
    private _location: Location, private router: Router, 
    private spinner: NgxSpinnerService,
    private _alertService: AlertService,
    private workflowService:WorkflowService,
    private _timeToDatePipe: TimeToDatePipe, 
    private _datePipe: DatePipe ,
  ) {
    this.OutReachProgramType = OutReachProgramType.contracts;
  }

  ngOnInit() {   
    this.spinner.show();
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this._localContractService.clearSelectedImmunizations();
    this._localContractService.clearClinicLocations();
    this._localContractService.getContractAgreement().subscribe(data => this.contractData = data);
    this.OutReachProgramType = OutReachProgramType.contracts;
   //this.displayInventoryWarning = false;
    //this.displayInventoryWarningOkClicked = false;
    this.immunizerWarningOkClicked = false;
    this.covidVaccineAdminConfirmation = false;
    this.covidVaccineWarningOkClicked = false;
    this.estimatedParticipantsError = false;
    this.showEstimatedParticipantsWarning = false;
    this.estimatedParticipantsAdminConfirmation = false;
    this.showParticipantsShotsMismatchWarning = false;
    this.immunizationShotsParticipantsMismatchError = false;
    this.immunizationShotsParticipantsMismatchAdminConfirmation = false;
    this.isAdmin = false;
    this.getContractAgreementData();
    
    // this._alertService.info(ErrorMessages['localContractsVerbiage'],false);
  }
  ContinueInvoiceInfo()
  {    
    this.invoiceInfoPopUp=false;     
    this.validateInvoceInfo=true;
    this.GoToStep(2);
  }
  getContractAgreementData() {
    let agreementPk = SessionDetails.GetAgreementPK();
    if(agreementPk == null || agreementPk == "null" || agreementPk == undefined ){
      this.showDialog(ErrorMessages['clientError'],ErrorMessages['nullClinicAgreementPk']);
      this.spinner.hide();
      return;
    }    
    this._localContractService.getContractAgreementData(agreementPk).subscribe((data:any) => {
      this.contractDataDBSnapShot = data;     
    
      let contractdateApproved = null;
      this.componentToRenderName= getContractComponentName(this.contractDataDBSnapShot.dateApproved,'app-agreement');
          // if((this.contractDataDBSnapShot && this.contractDataDBSnapShot.dateApproved))
          // {

          //       contractdateApproved = new Date(this.contractDataDBSnapShot.dateApproved);
          //       let uniContractReleaseDate:Date = new Date (universalContractReleaseDate);
          //       if(contractdateApproved < uniContractReleaseDate){
          //         this.showPreUniversalContract = true;
          //       }
          // }
          // else
          // {
          //   this.showPreUniversalContract = false;
          // }

    SessionDetails.opportunitiesData(data.opportunityInfo);

      if(data.parentClinicAgreementId && data.parentClinicAgreementId>0){
        sessionStorage["AmendAgreement"]="true";
      }
    
      this.spinner.hide();
      if(this.contractDataDBSnapShot.isEmailSent != null &&
         ( this.contractDataDBSnapShot.isEmailSent == true || this.contractDataDBSnapShot.isEmailSent == 1 ) && 
         ( this.contractDataDBSnapShot.isApproved == null || this.contractDataDBSnapShot.isApproved == 0 || this.contractDataDBSnapShot.isApproved == false)){
          this.workflowService.validateStep(STEPS.immunization); 
          this.workflowService.validateStep(STEPS.location); 
          this.workflowService.validateStep(STEPS.witness); 
          this.step = 4;
           this.GoToStep(4);
         }
      
    },    
    err => {
      this.spinner.hide();
      switch (err) {
        case 500:
          this.showDialog(ErrorMessages['serverError'], err.json().Message);
          return false;
          case 400:
          this.showDialog(ErrorMessages['clientError'], err.json().Message);
          return false;   
        default:
          this.showDialog(ErrorMessages['serverError'], err.json().Message);
          return false;
      }
    });
  }

  GoToStep(step: number) {    
    // if(!this.enableContractEditStatus()){
    //   return;
    // }
    if(sessionStorage.getItem('Old_immunizationForm') !== null){
      sessionStorage.removeItem('Old_immunizationForm');
    }
    
    this.spinner.show(); 
    if(this.step_desc=='location' && this.loccmp&&this.loccmp.locations_data1){    
      if(this.loccmp.locations_data1.value.contactPhone&&this.loccmp.locations_data1.value.contactPhone.indexOf("X") > -1){
        this.loccmp.locations_data1.controls.contactPhone.setValue(null);
        this.loccmp.locations_data1.controls.contactPhone.updateValueAndValidity();
        return false;
      }
    }

    let agreementPk = SessionDetails.GetAgreementPK();
    if (agreementPk == null || agreementPk == "null" || agreementPk == undefined) {
      return;
    } 
    let step_desc = this.getStepDesc(step);
    this.step_desc = this.getStepDesc(this.step);
    if (step < this.step) {
      this.cacheInEnteredData(this.step_desc);
      this.step = step;
      this.step_desc = step_desc;
      //this.displayInventoryWarningOkClicked = false;
      this.immunizerWarningOkClicked = false;
      this.covidVaccineAdminConfirmation = false;
      this.covidVaccineWarningOkClicked = false;
      this.estimatedParticipantsError = false;
      this.showEstimatedParticipantsWarning = false;
      this.estimatedParticipantsAdminConfirmation = false;
      this.showParticipantsShotsMismatchWarning = false;
      this.immunizationShotsParticipantsMismatchError = false;
      this.immunizationShotsParticipantsMismatchAdminConfirmation = false;
      this.isAdmin = false;
      this.spinner.hide();
      return;
    }
    if (this.OnSave(step_desc, step, this.step)) {
      if (this.step_desc != step_desc) {
        this.validateInvoceInfo=false;
        this.step_desc = this._workflowGuard.verifyWorkFlow(step_desc);
        this.step = Object.keys(STEPS).findIndex(inx => inx == this.step_desc) + 1;
      }
    }
    this.spinner.hide();
  }

  getStepDesc(step: number) {
    if (step === 1)
      return STEPS.immunization;
    if (step === 2)
      return STEPS.location;
    if (step === 3)
      return STEPS.witness;
    if (step === 4)
      return STEPS.review;
  }

  cacheInEnteredData(step_desc: any, currentStep?: number, prevStep?: number) {
    let return_value: boolean = false;
    if (this.step_desc === STEPS.immunization) {
      return_value = this.imzcmp.simpleSave();
    }
    else if (this.step_desc === STEPS.location) {      
      if (this.loccmp != undefined) {
        this.loccmp.simpleSave();
        
        if(!this.loccmp.isFormValid() && !this.loccmp.isDateTimeConflictPresent && this.loccmp.userApprovedGt250 &&
         this.loccmp.userApprovedLt25 && this._localContractService.getValidationsRequiredStatus()){
          this.isLocationsFormValid = false;
        } else {
          this.isLocationsFormValid = true;
        }
        
      }
    }
    else if (this.step_desc === STEPS.witness) {
      if (this.witcmp != undefined) {
        this.witcmp.simpleSave();
        if(!this.witcmp.isFormValid()){
          this.isPharmacyFormValid = false;
        } else {
          this.isPharmacyFormValid = true;
        }
      }
    }
  }

   OnSave(step_desc: any, currentStep: number, prevStep: number) {
    let return_value = false;
    let userProfile = SessionDetails.GetUserProfile();
    this.isAdmin = userProfile.userRole.toLowerCase() == "admin";
    if (this.step_desc === STEPS.immunization) {
      return_value = this.imzcmp.save();
      this.addedImmunizations = this._localContractService.getImmunizationsData();
      this.InvoiceInformation = this._localContractService.getInvoiceAndVoucherData();
      // if(this.addedImmunizations.length>0&& this.InvoiceInformation.length>0 ){
      //   let corp=this.addedImmunizations.filter(c=>c.paymentTypeId=="6" || c.paymentTypeId=="13");
      //   if(corp.length===0 && !this.validateInvoceInfo && this.imzcmp.isFormValid())
      //   {
      //     this.step = 1;
      //     this.invoiceInfoPopUp=true;
      //     this.validateInvoceInfo=true;
      //     return false;
      //   }
      // }
      
      // if(this.imzcmp.isFormValid() && !this.displayInventoryWarningOkClicked&&this._localContractService.checkIfFluImmIsSelected()){
      //   this.showdisplayInventoryWarningDialog("INVENTORY ALERT","Please ensure you have sufficient vaccine prior to committing to this opportunity.");
      //   return false;
      // }
      
    }
    if (this.step_desc === STEPS.location) {

      // assigning hidden payment methods estimated quantity
      let locations = this.loccmp.contractForm.value.locations;

      locations.forEach((element, index) => {
        let frmar: FormArray = this.loccmp.contractForm.get('locations') as FormArray;
        let frmGrp = frmar.controls[index] as FormGroup;
        let clinicImzQtyListArr: any = frmar.controls[index].get('clinicImzQtyList') as FormArray;
        const voucherPaymentOnly = ClinicDetailsUtilities.CheckOnlyVoucherPaymentIsSelected(frmar.controls[index].get('clinicImzQtyList').value);
        const Noclinic = Number(frmar.controls[index].get('isNoClinic').value);
        if(voucherPaymentOnly && Noclinic)
          this.handleVoucherPaymentMethod();
        
        const estimatedParticipants = frmar.controls[index].get('estimatedParticipants').value;
        if(estimatedParticipants != null && estimatedParticipants < 50 &&  !this.estimatedParticipantsAdminConfirmation && !voucherPaymentOnly && Noclinic == 0) {
          this.estimatedParticipantsError = true;
          frmar.controls[index].get('estimatedParticipants').setErrors({'incorrect': true});
          frmar.controls[index].get('estimatedParticipants').markAsDirty();
          frmar.controls[index].get('estimatedParticipants').markAsTouched();
          frmar.controls[index].updateValueAndValidity();
        } else {
          frmar.controls[index].get('estimatedParticipants').setErrors(null);
          frmar.controls[index].get('estimatedParticipants').markAsDirty();
          frmar.controls[index].get('estimatedParticipants').markAsTouched();
          frmar.controls[index].updateValueAndValidity();
        }
        const uniqueImmPks = [...new Set(element.clinicImzQtyList.map(item => item.immunizationPk))];
        uniqueImmPks.forEach(item => {
          let immzs = element.clinicImzQtyList.filter(p => p.immunizationPk == item);
          immzs.forEach((element, index) => {
            if (index > 0) {
              element.estimatedQuantity = 0;
            }
          });

          let formArr = clinicImzQtyListArr.controls.filter(p => p.controls.immunizationPk.value == item);
          formArr.forEach((element, index) => {
            if (index > 0) {
              element.patchValue({
                estimatedQuantity: 0
              });
            }
          });
        });
      });
      this.handleParticipantsShotsMismatch();
      if(this.immunizationShotsParticipantsMismatchError) return false;
      if(this.estimatedParticipantsError){
        this.handleParticipantsChange(locations);
        return false;
      }

      if (this.loccmp != undefined) {
        if(!this.covidVaccineWarningOkClicked){
            const locations = this.loccmp.contractForm.get('locations') as FormArray;
            for (const location of locations.controls) {
              const clinicImzQtyList = location.get('clinicImzQtyList') as FormArray;
              let clinicDate = location.get('clinicDate').value;
              clinicDate = moment(clinicDate);
              let startDate  = moment(environment.covidVaccineNotAviableDates.startDate, "DD/MM/YYYY");
              let endDate = moment(environment.covidVaccineNotAviableDates.endDate, "DD/MM/YYYY");
              for (const clinicImzQty of clinicImzQtyList.controls) {
                if(clinicImzQty.get('immunizationName').value.toLocaleLowerCase()?.includes('covid') && (Number(clinicImzQty.get('estimatedQuantity').value) > 0) && (clinicDate.isBetween(startDate, endDate) || clinicDate.isSame(startDate) || clinicDate.isSame(endDate))){
                  this.covidVaccineSelected = true;
                  this.dialogMsg = ErrorMessages["sesionalCovid19Info"];
                  this.dialogSummary = "Alert";
                  clinicImzQty.get('estimatedQuantity').setErrors({'incorrect': true});
                  clinicImzQty.get('estimatedQuantity').markAsDirty();
                  clinicImzQty.get('estimatedQuantity').markAsTouched();
                  clinicImzQty.updateValueAndValidity();
                } else {
                  clinicImzQty.get('estimatedQuantity').setErrors(null);
                  clinicImzQty.get('estimatedQuantity').markAsDirty();
                  clinicImzQty.get('estimatedQuantity').markAsTouched();
                  clinicImzQty.updateValueAndValidity();
                }
              } 
            }
            if(this.covidVaccineSelected) {
              locations.updateValueAndValidity();
              return false;
            } else {
              locations.updateValueAndValidity();
            }
        }
        if (!this.loccmp.isFormValid()) {
          const locations = this.loccmp.contractForm.get('locations') as FormArray;
          for (const location of locations.controls) {
            const clinicImzQtyList = location.get('clinicImzQtyList') as FormArray;
            for (const clinicImzQty of clinicImzQtyList.controls) {
              if(!clinicImzQty.value.estimatedQuantity?.toString()?.length){
                clinicImzQty.get('estimatedQuantity').setErrors({'incorrect': true});
                clinicImzQty.get('estimatedQuantity').markAsDirty();
                clinicImzQty.get('estimatedQuantity').markAsTouched();
                clinicImzQty.updateValueAndValidity();
              }
            }
          }
          locations.updateValueAndValidity();
          return false;
        } 
      }
      if(!this.immunizerWarningOkClicked){
        let estimatedImmunizers = 2;
        let errorList:string [] = [];
        
        this.getEstimatedImmunizers(errorList);
        if(errorList.length>0){
        this.showdImmunizerWarningDialog("Warning", ErrorMessages['immunizerWarningMainPara']+errorList.join().replace(",",""));
        return false;
        }
      }
      let locString: String[] = [];
      if (this.loccmp.isClinicDateReminderBefore2WksReq(locString)) {
        let sumMsg = ErrorMessages['impRmndrFor2Wks'];
        let errMsg = ErrorMessages['new_clinicDateReminderBefore2WeeksEN'];
        var lowerCaseName = userProfile.userRole.toLowerCase();
        // if (lowerCaseName == "director" ||
        // lowerCaseName == "supervisor" || lowerCaseName == "regionalvp" ||
        // lowerCaseName == "regionaldirector" || lowerCaseName == "admin" ||
        // lowerCaseName == "regional vice president" ||
        // lowerCaseName == "regional healthcare director" ||
        // lowerCaseName == "healthcare supervisor" 
        // || lowerCaseName == "director - rx and retail ops" || lowerCaseName == "district manager") {
          this.step = 2;
          this.dialogSummary = sumMsg;
          this.OverrideOrOkTitle = "OK";
          this.showCancelBtn = false;
          this.confirm(sumMsg, errMsg, step_desc);
          return false;
//        }
        // else {
        //   errMsg = ErrorMessages['clinicDateReminderBefore2WeeksEN'];
        //   this.showDialog(sumMsg, errMsg);
        //   this.step = 2;
        //   return false;
        // }
      }  
      this.isTimeValid = this.loccmp.isStartTimeEndTimeValid();
       if(this.isTimeValid){
        this.startTimeEndTimesStep_desc = step_desc;
        this.showStartTimeEndTimeValid = true;
        this.dialogSummary = "Alert";
        if(this.isTimeValid==1)
        this.dialogMsg = ErrorMessages['clinicLengthMoreThan8Hrs'];
        else if(this.isTimeValid == 2){
          this.dialogSummary = "Error";
          this.dialogMsg = ErrorMessages['clinicLengthAlert'];
        }
        else {
          this.dialogMsg = "Please Contact support";
        }
        return false;
      }    
      let isNoclinc: string = '';
        
        let isNoclinicPopUp = false;
        locations.forEach(element => {
          let covidVaccine = element.clinicImzQtyList.filter(p => p.immunizationName.includes('COVID19 Vaccine (')).length > 0;
          let isVoucherOnly = element.clinicImzQtyList.filter(item => item.immunizationName.includes('Vaccine Voucher')).length > 0;
          let vaccines = element.clinicImzQtyList.filter(p => p.immunizationName.includes('COVID19 Vaccine ('));
          let vaccineNonzero = false;
          if (covidVaccine) {
            vaccineNonzero = vaccines[0].estimatedQuantity == "0" ? false : true;
            if (!vaccineNonzero&&vaccines.length>1)
              vaccineNonzero = vaccines[1].estimatedQuantity == "0" ? false : true;
          }
          if (covidVaccine && isVoucherOnly && element.isNoClinic == 1 && vaccineNonzero) {
            isNoclinc += element.location + ', ';
            isNoclinicPopUp = true;
          }
        });
        if (isNoclinicPopUp) {
          this.inNoclinicPopUp = true;
          this.dialogMsg = String.Format("{0} has non-voucher products with estimated shots. Either uncheck the 'No Clinic – Voucher Distribution Only' setup option or set the Estimated Shots for vaccines to '0'.", isNoclinc.slice(0, -2));
          return false;
        }
      return_value = this.loccmp.save();
      if(return_value== true){
        this._alertService.clear();        
        if(this.loccmp.isNonNoClinicPresent()){
        this._alertService.info(ErrorMessages['clinicDateReminderAfter2WeeksEN'],false);
        }
      }
    }
    if (this.step_desc === STEPS.witness) {        
      if (this.witcmp != undefined) {
        
        if(!this.witcmp.isFormValid()){
          this.isPharmacyFormValid = false;
        } else {
          if(SessionDetails.GetUserProfile().email.toLowerCase() !=  this.witcmp.getPharmacyContactEmail().toLowerCase()){
          this.validatePortalEmail();
          return false;
          }
          else {
            return_value = this.witcmp.save();
            this.isPharmacyFormValid = true;
          }             
        }
      }
    }
    if (currentStep > prevStep + 1) {
      //user has navigated one step further due to previous iteration.
      for (let count = prevStep ; count <= currentStep; count++) {
        switch (count) {
          case 1:
          if(!this.imzcmp.checkIfAnyImmunizationsAdded() || !this.imzcmp.isFormValid()){
            this.step = 1;
            return false;
          }
          break;
          case 2:
          if(this.imzcmp != null && this.imzcmp != undefined ){
            if (this.imzcmp.validateLocForm || !this.isLocationsFormValid ||  this._localContractService.getValidationsRequiredStatus()) {
              this.step = 2;
              this.imzcmp.validateLocForm = false;
              return false;
            }
          } else if(this._localContractService.getValidationsRequiredStatus()) {
            this.step = 2;
            this.imzcmp.validateLocForm = false;
            return false;
          }
            break;
            case 3:
            if(!this.isPharmacyFormValid){
              this.step = 3;
              return false;
            }
          default:
            break;
        }
      }
    }
    return return_value;
  }
  EditTimes(){
    this.showStartTimeEndTimeValid=false;
  }
  ContinueToNextStep(){
    this.showStartTimeEndTimeValid=false;
    if (this.loccmp.save()) {
      if (this.step_desc != this.startTimeEndTimesStep_desc) {
        this.step_desc = this._workflowGuard.verifyWorkFlow(this.startTimeEndTimesStep_desc);
        this.step = Object.keys(STEPS).findIndex(inx => inx == this.step_desc) + 1;
      }
    }
    else {
      this.step = 2;
      return false;
    }
    return true;
  }
  validatePortalEmail(){ 
    this.spinner.show();   
      this._localContractService.validatePortalEmail(this.witcmp.getPharmacyContactEmail().toLowerCase()).subscribe(data=>{
        this.spinner.hide();
        if(data==true){
          this.witcmp.save();
          this.isPharmacyFormValid = true;
          this.step_desc = this._workflowGuard.verifyWorkFlow("review");
          this.step = Object.keys(STEPS).findIndex(inx => inx == this.step_desc) + 1;
          this.GoToStep(4);
        }
        else {
          this.showDialog("Error", ErrorMessages['validatePortalEmailError']);
        }
      },
      err => {
        this.spinner.hide();
        switch (err) {
          default:
            this.showDialog(ErrorMessages['serverError'], err.json().Message);
            break;
        }
      }
      );
  }

  okClicked() {
    this.display = false;
  }

  saveBtnClicked() {
    this.spinner.show();
    let return_value = false;
    if (this.step_desc === STEPS.immunization || this.step <= 1) {
      if (this.imzcmp != undefined && this.imzcmp.immunizationForm.valid) {
        return_value = this.imzcmp.simpleSave();
      }
      else{
        this.imzcmp.immunizationForm.markAllAsTouched();
      }
    }
    if (this.step_desc === STEPS.location || this.step == 2) {
      if(!this.loccmp.locations_data1.valid){
        this.spinner.hide();
        const locations = this.loccmp.contractForm.controls.locations as FormArray;
        for (const location of locations.controls) {
          location.setErrors({incorrect: true});
          location.markAllAsTouched();
        }
        return false;
      }

      if (this.loccmp != undefined) {
        return_value = this.loccmp.simpleSave();
      }
    }
    if (this.step_desc === STEPS.witness || this.step >= 3)
      if (this.witcmp != undefined) {

        return_value = this.witcmp.simpleSave();
      }
    if (!return_value) {
      this.spinner.hide();
      return false;
    }
    this.saveInteriumAgreementData(false);
    return return_value;
  }

  saveInteriumAgreementData(is_overlap?: boolean) {

    this._localContractService.saveInterimAgreementData(SessionDetails.GetStoreId(), 1, is_overlap).subscribe((data: any) => {
      this.spinner.hide();
      switch (data.errorS.errorCode) {
        case '0':
        case 0:
          this.showDialog(ErrorMessages['contract'], ErrorMessages['contractSaved']);
          this._localContractService.SetIscanceled(false);
          break;
        case -4:
        case '-4':
          this.showCancelBtn = true;
          this.OverrideOrOkTitle = "Override";
          this.confirmOverlap(ErrorMessages['eventDateTimeConflict'],
          String.Format("{0}<br/><br/>{1}",data.errorS.errorMessage,ErrorMessages['eventDateTimeConflictErrText']));
          this._localContractService.SetIscanceled(true);
          break;
        default:
          this.showDialog(ErrorMessages['contract'], data.errorS.errorMessage);
          this._localContractService.SetIscanceled(true);
          break;
      }

    },
      err => {
        this.spinner.hide();
        switch (err) {
          default:
            this.showDialog(ErrorMessages['serverError'], err.json().Message);
            break;
        }
      });
  }


  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
  }

  // showdisplayInventoryWarningDialog(msgSummary: string, msgDetail: string) {
  //   this.dialogMsg = msgDetail;
  //   this.dialogSummary = msgSummary;
  //   this.displayInventoryWarning = true;
  // }
  showdImmunizerWarningDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.immunizerWarningAlert = true;
  }
  // displayInventoryWarningOk(){
  //   this.displayInventoryWarning = false;
  //   this.displayInventoryWarningOkClicked = true;;
  //   this.GoToStep(2);
  // }
  showdImmunizerWarningDialogOk(){
    this.immunizerWarningAlert = false;
    this.immunizerWarningOkClicked = true;;
    this.GoToStep(3);
  }
  editdImmunizerWarning(){
    this.immunizerWarningAlert = false;
  }
getEstimatedImmunizers(errorList:string[]){
  let isCovidVaccineAvailable:boolean =   this.addedImmunizations.filter(p=>p.immunizationName.search(/COVID19/gi)>=0).length>0;                

  // fix for 794
  let shots_per_10_min = 4;
  // let slotTime = isCovidVaccineAvailable ? 15 : 10;
  // isCovidVaccineAvailable?shots_per_10_min=2:shots_per_10_min=4;
  let slotTime = 10;  
  let shots_per_hour = (60 / slotTime) * shots_per_10_min;  
  
  this.loccmp.contractForm.value.locations.forEach(loc => {
    try {
        if (loc.startTime != "")
            try{
            loc.startTime = this._datePipe.transform(loc.startTime, 'shortTime');
            }catch(e){
                console.log(e);        
            }
        if (loc.endTime != "") {
            try{
            loc.endTime = this._datePipe.transform(loc.endTime, 'shortTime');
        }catch(e){
            console.log(e);        
        }
        }
    } catch (e) {
        console.log(e); // here the exception is delibarately caught because when you click back, startTime & end
        // Time is not correct timeformat. Hence we are ignoring this by catching here.
    }
});
for (let count = 0; count < this.loccmp.contractForm.value.locations.length; count++) {  
  let locClinicDate:Date = new Date(this.loccmp.contractForm.value.locations[count].clinicDate);
          let locStartTime = this.loccmp.contractForm.value.locations[count].startTime;
          let locEndTime = this.loccmp.contractForm.value.locations[count].endTime;
          if (this.loccmp.contractForm.value.locations[count].isNoClinic >= 1 || 
              this.loccmp.contractForm.value.locations[count].isNoClinic === '1' || 
              this.loccmp.contractForm.value.locations[count].isReassign >= 1 || 
              this.loccmp.contractForm.value.locations[count].isReassign === '1') {
              continue;
          }
          let endDate: Date;
          if (locEndTime.toString().toLowerCase().search('am') !== -1 || locEndTime.toString().toLowerCase().search('pm') !== -1) {
              endDate = this._timeToDatePipe.transform(locClinicDate, locEndTime);
          }
          else {
              endDate = locEndTime;
          }
          let startDatetmToDt: Date;
          if (locStartTime.toString().toLowerCase().search('am') !== -1 || locStartTime.toString().toLowerCase().search('pm') !== -1) {
              startDatetmToDt = this._timeToDatePipe.transform(locClinicDate, locStartTime);
          }
          else {
              startDatetmToDt = locStartTime;
          }
          let locStart: Date = new Date(startDatetmToDt);
          let locEnd: Date = new Date(endDate);
          let clinicLength = (locEnd.valueOf()-locStart.valueOf())/(3600*1000);
          if(clinicLength> 0 ){   
           let clinicLoc= this.loccmp.contractForm.value.locations[count];  
           let totalImmunizations = clinicLoc.clinicImzQtyList.map(item=>item.estimatedQuantity).reduce((acc,current)=>Number(acc)+Number(current));
           // = clinicLoc.clinicImzQtyList.reduce((accum,current)=>accum+current.estimatedQuantity,0);
           let estimatedImmunizers = Math.ceil(totalImmunizations/(shots_per_hour*clinicLength))
           if(estimatedImmunizers>1){
           errorList.push(String.Format(ErrorMessages['immunizerWarning'],clinicLoc.location,estimatedImmunizers))
           }
           totalImmunizations =  0;
           estimatedImmunizers = 0;
          }
          
}
}

  confirm(hdr: string, msg: string, step_desc: any) {
    this.confirmationService.confirm({
      message: msg,
      header: hdr,
      accept: () => {   
        // TO fix the button switches to "Override"  issue in ip contract.
        //this.OverrideOrOkTitle = "Override";
        this.showCancelBtn = true;
        this.isTimeValid = this.loccmp.isStartTimeEndTimeValid();

        if(this.isTimeValid){
          this.startTimeEndTimesStep_desc = step_desc;          
          this.dialogSummary = "Alert";
          this.showStartTimeEndTimeValid = true;
          if(this.isTimeValid==1)
          this.dialogMsg = ErrorMessages['clinicLengthMoreThan8Hrs'];
          else if(this.isTimeValid == 2){
            this.dialogSummary = "Error";
            this.dialogMsg = ErrorMessages['clinicLengthAlert'];
          }
          else {
            this.dialogMsg = "Please Contact support";
          }
          return false;
        }
        if (this.loccmp.save()) {
          if (this.step_desc != step_desc) {
            this.step_desc = this._workflowGuard.verifyWorkFlow(step_desc);
            this.step = Object.keys(STEPS).findIndex(inx => inx == this.step_desc) + 1;
          }
          this._alertService.clear();
          if(this.loccmp.isNonNoClinicPresent()){            
            this._alertService.info(ErrorMessages['clinicDateReminderAfter2WeeksEN'],true);  
            }
        }
        else {
          this.step = 2;
          return false;
        }
        return true;
      },
      reject: () => {
        this.step = 2;
        return false;
      }
    });
  }

  confirmOverlap(hdr: string, msg: string) {
    this.confirmationService.confirm({
      message: msg,
      header: hdr,
      accept: () => {
        this.saveInteriumAgreementData(true);
        return true;
      },
      reject: () => {
        return false;
      }
    });
  }

  cancelContract() {    
    this.validateInvoceInfo=false;  
    let return_value = false;
    if (this.step_desc === STEPS.immunization || this.step <= 1) {
      if(this.imzcmp != undefined){
      return_value = this.imzcmp.cancelImmunization();
      }
      if (return_value) {
        this.savePopUp = true;
        this.showDialogCancel(ErrorMessages['unSavedData'], ErrorMessages['contract_alert']);
      }
      else {
        this._location.back();
      }
    }
    if (this.step_desc === STEPS.location || this.step === 2) {

      this.cancel_check = this._localContractService.getIsCancel();
       return_value = this.loccmp.cancelLocationData();
      if (return_value && this.cancel_check ) {
        this.savePopUp = true;
        this.showDialogCancel(ErrorMessages['unSavedData'], ErrorMessages['contract_alert']);
      }
      else {
        this._location.back();
      }
    }
    if (this.step_desc === STEPS.witness || this.step >= 3)
      if (this.witcmp != undefined) {
        this.cancel_check = this._localContractService.getIsCancel();
        return_value = this.witcmp.cancelWitness();
        if (return_value && this.cancel_check ) {
          this.savePopUp = true;
          this.showDialogCancel(ErrorMessages['unSavedData'], ErrorMessages['contract_alert']);
        }
        else {
          this._location.back();
        }
      }
    
    if(sessionStorage.getItem('Old_immunizationForm') !== null){
      sessionStorage.removeItem('Old_immunizationForm');
    }
  }

  doNotSave() {
    this.savePopUp = false;
    if (this.router)
    {
      if(sessionStorage["AmendAgreement"] && sessionStorage["AmendAgreement"]=="true")
        this.router.navigate(['./communityoutreach/LocalClinicProgramDetails']);
      else
        this.router.navigate(['./communityoutreach/storehome']);
    } 
  }

  Continue() {
    this.savePopUp = false;
  }

  showDialogCancel(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
  }

  enableContractEditStatus(){
    if(this.contractDataDBSnapShot != null && this.contractDataDBSnapShot != undefined){
    if(this.contractDataDBSnapShot.isEmailSent != null &&
      ( this.contractDataDBSnapShot.isEmailSent == true || this.contractDataDBSnapShot.isEmailSent == 1 ) && 
      this.contractDataDBSnapShot.isApproved == null){
        return false;
      }
    }
      return true;
  }
  
  showHideSaveBtnStatus() {
    
    if(this.contractDataDBSnapShot != null && this.contractDataDBSnapShot != undefined){
    if(this.contractDataDBSnapShot.isEmailSent != null &&
      (  this.contractDataDBSnapShot.isEmailSent == 1 ) && 
      ( this.contractDataDBSnapShot.isApproved === null || this.contractDataDBSnapShot.isApproved === 1 || this.contractDataDBSnapShot.isApproved === 0  )) {
        return false;
      }
    }
    if (sessionStorage["AmendAgreement"] != null)   {
      return false;
    }
      return true;
  }
  calculateNextStep(step:any){
    let step_desc = this.getStepDesc(step);
   this.step_desc = this._workflowGuard.verifyWorkFlow(step_desc);
   this.step = 3;
   
  }
  inNoclinicConfirm(){
    this.inNoclinicPopUp = false;
    //this.isNoclinicChecked = false;
  }

  handleCovidVaccineAlertOk(){
    this.covidVaccineSelected = false;
  }

  handleCovidVaccineAdminConfirmation(){
    const locations = this.loccmp.contractForm.get('locations') as FormArray;
    this.covidVaccineSelected = false;
    this.covidVaccineWarningOkClicked = true;
    this.immunizationShotsParticipantsMismatchAdminConfirmation = false;
    this.estimatedParticipantsAdminConfirmation = false;
    for (const location of locations.controls) {
      const clinicImzQtyList = location.get('clinicImzQtyList') as FormArray;
      for (const clinicImzQty of clinicImzQtyList.controls) {
        if(clinicImzQty.get('immunizationName').value.toLocaleLowerCase()?.includes('covid')){
          clinicImzQty.get('estimatedQuantity').setErrors(null);
          clinicImzQty.get('estimatedQuantity').markAsDirty();
          clinicImzQty.get('estimatedQuantity').markAsTouched();
          clinicImzQty.updateValueAndValidity();
        }
      }
    }
    locations.updateValueAndValidity();
    this.GoToStep(3);
  }

  handleEstimatedParticipantsAdminConfirmation(){
    const locations = this.loccmp.contractForm.value.locations;
    this.showEstimatedParticipantsWarning = false;
    this.estimatedParticipantsAdminConfirmation = true;
    this.estimatedParticipantsError = false; 
    this.covidVaccineWarningOkClicked = false;
    locations.forEach((element, index) => {   
      let frmar: FormArray = this.loccmp.contractForm.get('locations') as FormArray;
      frmar.controls[index].get('estimatedParticipants').setErrors(null);
      frmar.controls[index].get('estimatedParticipants').markAsDirty();
      frmar.controls[index].get('estimatedParticipants').markAsTouched();
      frmar.controls[index].updateValueAndValidity();
    });
    this.GoToStep(3);
  }

  handleParticipantsChange(loccmp) {
    let locationList = [];
    let clinicsList = "";
    loccmp.forEach((element, index) => {
      locationList.push(loccmp[index].location);
    })
    loccmp.forEach(clinic => {
      if (clinic.estimatedParticipants < 50 && clinic.estimatedParticipants != null && clinic.isNoClinic == 0) {
        clinicsList += clinic.location + ", ";
      }
    });
    const errorMessage = ErrorMessages["estimatedParticipantsWarning"].replace('LOCATION', `LOCATION ${clinicsList}`);
    this.showEstimatedParticipantsWarning = true;
    this.dialogSummary = "Please resolve the below issues to continue further";
    this.dialogMsg = errorMessage;
  }

  handleParticipantsShotsMismatch() {
    const locations = this.loccmp.contractForm.get('locations') as FormArray;
    for (const location of locations.controls) {
      let estimatedParticipants = Number(location.get('estimatedParticipants').value);
      const clinicImzQtyList = location.get('clinicImzQtyList') as FormArray;
      for (const clinicImzQty of clinicImzQtyList.controls) {
        const estimatedShots =Number(clinicImzQty.get('estimatedQuantity')?.value);
        if( estimatedShots > estimatedParticipants && !this.immunizationShotsParticipantsMismatchAdminConfirmation){
          this.showParticipantsShotsMismatchWarning = true;
          this.immunizationShotsParticipantsMismatchError = true;
          this.dialogMsg = ErrorMessages["estimatedShotsWarning"];
          this.dialogSummary = "Please resolve the below issues to continue further";
          clinicImzQty.get('estimatedQuantity').setErrors({'incorrect': true});
          clinicImzQty.get('estimatedQuantity').markAsDirty();
          clinicImzQty.get('estimatedQuantity').markAsTouched();
          clinicImzQty.updateValueAndValidity();
          return;
        } else {
          clinicImzQty.get('estimatedQuantity').setErrors(null);
          clinicImzQty.get('estimatedQuantity').markAsDirty();
          clinicImzQty.get('estimatedQuantity').markAsTouched();
          clinicImzQty.updateValueAndValidity();
        }
      }
    }
  }

  handleParticipantsShotsMismatchAdminConfirmation() {
    this.showParticipantsShotsMismatchWarning = false;
    this.immunizationShotsParticipantsMismatchError = false;
    this.immunizationShotsParticipantsMismatchAdminConfirmation = true;
    const locations = this.loccmp.contractForm.get('locations') as FormArray;
    for (const location of locations.controls) {
      const clinicImzQtyList = location.get('clinicImzQtyList') as FormArray;
      for (const clinicImzQty of clinicImzQtyList.controls) {
        clinicImzQty.get('estimatedQuantity').setErrors(null);
        clinicImzQty.get('estimatedQuantity').markAsDirty();
        clinicImzQty.get('estimatedQuantity').markAsTouched();
        clinicImzQty.updateValueAndValidity();
      }
    }
    locations.updateValueAndValidity();
    this.GoToStep(3);
    // this.dialogSummary = "Please resolve the below issues to continue further";
    // this.dialogMsg = ErrorMessages['estimatedShotsWarning'];
  }

  getAllEstimatedShots(locationForm){
    const totalEstimatedShots = locationForm.controls.clinicImzQtyList['controls'].reduce((accumulator, currentValue) => Number(accumulator?.controls?.estimatedQuantity?.value) + Number(currentValue?.controls?.estimatedQuantity?.value));
    return totalEstimatedShots;
  }

  handleVoucherPaymentMethod() {
    const locations = this.loccmp.contractForm.get('locations') as FormArray;
    for (const location of locations.controls) {
      location.patchValue({"estimatedParticipants": 0})
      const clinicImzQtyList = location.get('clinicImzQtyList') as FormArray;
      for (const clinicImzQty of clinicImzQtyList.controls) {
        clinicImzQty.patchValue({"estimatedQuantity": 0});
      }
    }
  }
}