import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { DOCUMENT } from '../../../../../../node_modules/@angular/common';
import { Router } from '../../../../../../node_modules/@angular/router';
import { PreviewService } from '../../services/preview.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SessionDetails } from '../../../../utility/session';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from "ngx-spinner";
import { UpdateSchedulerSiteDesign, SiteLandingPageText, SiteLogoAndStyles, ClientInfo, SiteRequiredRegisField, ClinicsList } from '../../../../models/UpdateSchedulerSiteDesign';
import { SiteRequiredFields } from '../../../../JSON/SiteRequiredFields';
import { MaxImmunizers } from '../../../../JSON/MaxImmunizers';
import { MinImmunizers } from '../../../../JSON/MinImmunizers';
import { environment } from '../../../../../environments/environment';
import { SchedulerUserInfo } from '../../../../models/schedulerUserInfo';
import { AuthenticationService } from '../../../common/services/authentication.service';
import { EncryptDecrypt } from '../../../../models/encryptDecrypt';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { String } from 'typescript-string-operations';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { Table } from 'primeng/table';

@Component({
	selector: 'app-corporate-scheduler',
	templateUrl: './corporate-scheduler.component.html',
	styleUrls: ['./corporate-scheduler.component.css'],
	providers: [PreviewService]
})



export class CorporateSchedulerComponent implements OnInit {

	showHints:boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : true;
	pageName: string = "corporateScheduler";
	corporateSchedulerForm: FormGroup;
	cols: any[];
	index: number = 0;
	searchValue: string = '';
	copyText: any;
	date3: any;
	dispEncryptedLink: boolean = false;
	appointmentCols: any[];
	BannerColor: string;
	BannerTextColor: string;
	ButtonBgColor: string;
	ButtonTextColor: string;
	landingPageHeaderText: string = '';
	landingPageBodyText: string = '';
	landingPageFooterText: string = '';

	landingPageHeaderText_value: string = '';
	landingPageBodyText_value: string = '';
	landingPageFooterText_value: string = '';

	domain: any;
	protocol: any;
	port: any;
	csTbl: any[];
	userProfile: any;
	apptSchedulerEncLink: string;
	copyApptTrackingEncLink: string;
	accessSchedulerEncryptedLink: any;
	accessSchedulerTrackerEncryptedLink: any;
	isApptSchedulerEncLink: boolean = false;
	isApptTrackingEncLink: boolean = false;
	corporate_client_list: any;
	tbl_show: boolean = false;
	defaultDate: Date = new Date();
	maxDateValue: Date = new Date();
	minDateValue: Date = new Date();
	selectedDataList: any[] = [];
	sign_up_date: Date;
	signUpDates = [];
	signUpMaxDates = [];
	client_id: any = "";
	scheduler_site_design: any;
	tab_index: any;
	selectedValues: boolean = true;
	encryptedSiteLink: boolean = false;
	encryptedClientApptTrackingLink: boolean = false;

	//@ViewChild('dtclinic') dataTableClinic: Paginator;
	@ViewChild('dtclinic') dataTableClinic: Table;
	scheduleTotalRecords: number;
	private scheduleSource: any = {};
	siteSetUp: boolean = false;
	requiredFields = [];
	minImmunizers: any = "1";
	maxImmunizers: any = "3";
	signUpDate: any;
	maxImmunizersList: any[] = [];
	minImmunizersList: any[] = [];
	message_text: string = "";
	signupEndDate: any[] = [];
	client_name: any;
	siteStatus: any = "1";
	walgreensLogo: any = 1;
	clientLogo: any = null;
	client_log_image: any = null;
	maxImmunizersData: any;
	minImmunizersData: any;
	selectedMaxValue = [];
	selectedMinValue = [];
	walgreensLogoChecked: any;
	siteSetUpSuccess: boolean = false;
	uploadedFileName: string = '';
	files: any;
	image_source: any;
	setValue: any = "1";
	uploadFileClinet: boolean = false;
	overRideOption: boolean = false;
	fileExt: string[] = ["JPG", "JPEG", "PNG"];
	temp_true: any[] = [];
	temp_false: any[] = [];
	pagination_value: boolean = false;
	schedulerSiteDesign: UpdateSchedulerSiteDesign;
	constructor(@Inject('Window') private win: Window, private spinner: NgxSpinnerService,
		private router: Router, private _fb: FormBuilder, @Inject(DOCUMENT) private document: any, private previewService: PreviewService, private authenticationService: AuthenticationService,private message_service:MessageServiceService) {
	}

	@ViewChild('appointmenttbl')
	private table: Table;

	ngOnInit() {
		this.image_source = environment.image_file_path;
		//this.walgreensLogo = 1;
		this.selectedDataList = [];
		let today = new Date();
		var temp_date = new Date((new Date(today.getFullYear(), today.getMonth(), today.getDate())).setDate(today.getDate() - 1));
		this.minDateValue = new Date(temp_date.getFullYear(), temp_date.getMonth(), temp_date.getDate(), 8, 0, 0);
		this.userProfile = SessionDetails.GetUserProfile();
		this.corporateSchedulerForm = this._fb.group({
			firstNameRequired: [0, null],
			firstNameVisible: [1, null],
			middleNameRequired: [0, null],
			middleNameVisible: [0, null],
			lastNameRequired: [0, null],
			lastNameVisible: [1, null],
			employeeIdRequired: [0, null],
			employeeIdVisible: [1, null],
			EmailRequired: [1, null],
			EmailVisible: [1, null]
			// phoneRequired: [1, null],
			// phoneVisible: [1, null],
			// genderRequired: [1, null],
			// genderVisible: [1, null],
			// DOBRequired: [1, null],
			// DOBVisible: [1, null],
			// address1Required: [1, null],
			// address1Visible: [1, null],
			// address2Required: [1, null],
			// address2Visible: [1, null],
			// cityRequired: [1, null],
			// cityVisible: [1, null],
			// stateRequired: [1, null],
			// stateVisible: [1, null],
			// zipRequired: [1, null],
			// zipVisible: [1, null],
		});
		// this.previewService.change.subscribe(BannerColor => {
		// 	this.BannerColor = BannerColor;
		//   });

		this.cols = [
			{ field: 'clientName', header: 'Clinic' },
			{ field: 'clinicAddress', header: 'Location' },
			{ field: 'clinicDate', header: 'Date' },
			{ field: 'clinicStartTime', header: 'Start Time' },
			{ field: 'clinicEndTime', header: 'End Time' },
			{ field: 'clinicStoreId', header: 'Store' },
			{ field: 'estimatedQuantity', header: 'Est Vol' },
			{ field: 'changedImmunizers', header: 'Min Immunizers' },
			{ field: 'changedMaxImmunizers', header: 'Max Immunizers' },
			{ field: 'signupEndDate', header: 'Sign-up End Date' }
		];

		this.appointmentCols = [
			{ field: 'clinic', header: 'Clinic' },
			{ field: 'location', header: 'Location' },
			{ field: 'apptdate', header: 'Appt Date' },
			{ field: 'appttime', header: 'Appt Time' },
			{ field: 'timeslot', header: 'Time Slot' },
			{ field: 'name', header: 'Name' },
			{ field: 'employeeid', header: 'Employee ID' },
			{ field: 'confirmationid', header: 'Confirmation ID' }
		];


		this.domain = this.document.location.hostname;
		this.protocol = this.document.location.protocol;
		this.port = this.document.location.port;
		//this.getCorporateSchedularClinics();
		this.getCorporateClientList();

	}
	Sort(value) {

		if (value == 'clinic' || value == 'location' || value == 'clinicDate')
			return value;
		else
			return '';
	}

	getCorporateSchedularClinics(client_pk, start_number) {
		this.pagination_value = false;
		this.maxImmunizersList = [];
		this.minImmunizersList = [];
		this.temp_true = [];
		this.temp_false = [];
		this.selectedMaxValue = [];
		this.selectedMinValue = []
		this.selectedDataList = [];
		this.scheduleSource = [];
		this.signUpDates = [];
		this.signUpMaxDates = [];
		let min_date_Value;
		this.spinner.show();
		this.previewService.getCorporateSchedulerClinics(client_pk).subscribe((data:any) => {
			this.scheduleSource = data;
			this.maxImmunizersData = MaxImmunizers;
			this.minImmunizersData = MinImmunizers;

			this.csTbl = data.clinicList;//.slice(start_number, (start_number + 10));
			var list = [];
			this.scheduleSource.clinicList.filter(function (item) {
				if (item.isScheduled && !item.isCanceled) {
					list.push(item);
				}
				item.clinicStartTime = item.clinicStartTime.replace(' AM', 'AM').replace(' PM', 'PM');
				item.clinicEndTime = item.clinicEndTime.replace(' AM', 'AM').replace(' PM', 'PM');
			}
			);
			this.selectedDataList = list;
			this.csTbl.forEach(item => {
				this.selectedMaxValue.push((item.maxImmunizers == -1 || item.maxImmunizers == null) ? 3 : item.maxImmunizers);
				this.selectedMinValue.push(item.immunizers);
			});

			this.scheduleTotalRecords = data.clinicList.length;
			if (this.csTbl.length > 0)
				this.tbl_show = true;
			if (this.dataTableClinic !== undefined) {
				this.dataTableClinic.first = 0;
				this.dataTableClinic.paginator;
			}
			for (let i = 0; i < this.csTbl.length; i++) {

				if (this.csTbl[i].signupEndDate === null) {
					min_date_Value = new Date(this.csTbl[i].clinicDate);
					this.signUpDates.push(new Date(min_date_Value - 1));
					this.signUpMaxDates.push(new Date(min_date_Value - 1));
				}
				else {
					min_date_Value = new Date(this.csTbl[i].signupEndDate);
					let max_date_value: any = new Date(this.csTbl[i].clinicDate);
					this.signUpDates.push(min_date_Value);
					this.signUpMaxDates.push(new Date(max_date_value - 1));
				}

			}
			this.spinner.hide();

		});

	}
	getCorporateClientList() {
		this.previewService.getCorporateClientList().subscribe((data:any) => {
			this.corporate_client_list = data.corporateClientList;
		});

	}


	resetToDefault() {
		// var data = SessionDetails.getSchedulerSiteSetUpData();
		// if (data != null && this.client_id != "") {
		// 	this.BannerColor = data.siteLogoAndStyles.bannerColor;
		// 	this.BannerTextColor = data.siteLogoAndStyles.bannerTextColor;
		// 	this.ButtonBgColor = data.siteLogoAndStyles.buttonColor;
		// 	this.ButtonTextColor = data.siteLogoAndStyles.buttonTextColor;
		// }
		// else {
		this.defautLogoValues();
		//}
	}

	displayFieldCss(hName: string) {
		if (hName == 'Location') {
			return 'wth250 underline';
		}
		if (hName == 'Date') {
			return 'underline';
		}
		if (hName == 'Sign-up End Date') {
			return 'wth120 mediawth120';
		}
		if (hName == 'Clinic') {
			return 'wth120 underline';
		}
		if (hName == 'Est Vol' || hName == 'Store') {
			return 'mW40';
		}
		if (hName == 'Sign-up End Date') {
			return 'mW80';
		}
		else {
			return '';
		}
	}


	clFld() {
		this.searchValue = '';
		this.table.reset();
	}
	handleChange(e) {
		if(e>=0) {
			this.pageNameChanger(e);
				setTimeout(()=>{  
					this.message_service.setShowTabbedHints(this.showHints);
				}, 200);
		}
		else{
		//this.selectedDataList = [];
		this.index = this.tab_index = e.index;
		// if (this.index == 1) {
		// 	this.getSchedulerSiteDesign();
		// }
		// if (this.index == 2) {
		// 	this.getSchedulerSiteDesign();
		// }
	
		this.pageNameChanger(this.index);
			setTimeout(()=>{  
				this.message_service.setShowTabbedHints(this.showHints);
			}, 200);
		}
	}
	ngAfterViewInit(){ 
		this.message_service.setShowTabbedHints(this.showHints);
	}
	openNext() {
		this.index = this.index + 1;
		this.handleChange(this.index);
	}
	openPrev() {
		this.index = this.index - 1;
		this.handleChange(this.index);
	}

	pageNameChanger(e){
		switch (e) {
			case 0:
				this.pageName = "corporateScheduler";
				break;
			case 1:
				this.pageName = "corporateSchedulerLogoNStyle";
				break;
			case 2:
				this.pageName = "corporateSchedulerLandingPage"
				break;
			case 3:
				this.pageName = "corporateSchedulerFormFields"
				break;
			default:
				this.pageName = "corporateScheduler"
				break;
		}
	}
	

	resetToDefaultText(text: string) {
		this.defaultTextValues(text);
	}
	toHTML(input): any {
		return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
	}
	openPreview() {
		var preview_info = {};
		preview_info["LogoFileName"] = this.client_log_image;
		preview_info["BannerTextColor"] = this.BannerTextColor;
		preview_info["BannerColor"] = this.BannerColor;
		preview_info["ButtonBgColor"] = this.ButtonBgColor;
		preview_info["ButtonTextColor"] = this.ButtonTextColor;
		preview_info["BodyText"] = this.landingPageBodyText.replace(/\n/g, "&lt;br/&gt");
		preview_info["FooterText"] = this.landingPageFooterText.replace(/\n/g, "&lt;br/&gt").replace(/</g, "&lt;").replace(/>/g, "&gt;");
		preview_info["HeaderText"] = this.landingPageHeaderText.replace(/\n/g, "&lt;br/&gt");
		sessionStorage["previewInfo"] = JSON.stringify(preview_info);

		let path = this.protocol + "//" + this.domain + ((this.port) ? ":" + this.port : "");
		window.open(path + '/corporateSchedulerPreview', '_blank', 'location=yes,height=660,width=800,scrollbars=yes,status=yes');
	}
	openLandingPage() {
		let path = this.protocol + "//" + this.domain + ((this.port) ? ":" + this.port : "");
		window.open(path + '/schedulerLanding', '_blank', 'location=yes,height=660,width=800,scrollbars=yes,status=yes');
	}

	showEncryptedLinkDlg(ptype: any) {
		if (ptype == 'Tracking') {
			this.dispEncryptedLink = true;
			this.isApptTrackingEncLink = true;
			this.isApptSchedulerEncLink = false;
		}
		if (ptype == 'Scheduler') {
			this.dispEncryptedLink = true;
			this.isApptSchedulerEncLink = true;
			this.isApptTrackingEncLink = false;
		}
	}
	copyEncryptedLink(linkText) {
		this.copyText = document.getElementById(linkText);
		this.copyText.select();
		document.execCommand("copy");
	}
	noRequireOptionChanged(event, checked: boolean) {
		this.corporateSchedulerForm.controls[event.target.attributes.formcontrolname.nodeValue.replace('Required', 'Visible')].setValue(1);
	}
	ClientChanged(client_pk) {
		if (client_pk !== "") {
			this.uploadedFileName = '';
			this.client_id = client_pk;
			this.client_name = this.corporate_client_list.filter(item => item.pk === Number(this.client_id))[0].clientName;
			this.signUpDates = [];
			this.signUpMaxDates = [];
			this.csTbl = [];
			// make required fields null
			if (this.schedulerSiteDesign) {
				this.schedulerSiteDesign.siteRequiredRegisFields = [];
			}
			this.getCorporateSchedularClinics(client_pk, 0);

			this.getSchedulerSiteDesign();
			if (this.index !== 0) {
				this.index = 0;
			}
		}
	}
	getSchedulerSiteDesign() {

		if (this.client_id !== undefined) {
			this.siteStatus = "1";
			this.defaultLandingValues();
			this.defautLogoValues();
			this.encryptedSiteLink = false;
			this.encryptedClientApptTrackingLink = false;
			//this.clientLogo = null;
			this.client_log_image = null;
			this.previewService.getSchedulerSiteDesign(this.client_id).subscribe((data:any) => {
				this.scheduler_site_design = data;
				this.accessSchedulerEncryptedLink = this.scheduler_site_design.clientInfo.encryptedSiteLink;
				this.accessSchedulerTrackerEncryptedLink = this.scheduler_site_design.clientInfo.encryptedClientApptTrackingLink;
				//SessionDetails.setSchedulerSiteSetUpData(data);
				this.siteStatus = (this.scheduler_site_design.clientInfo.siteStatus || this.scheduler_site_design.clientInfo.siteStatus === null) ? "1" : "0";
				if (this.scheduler_site_design.clientInfo.enryptedClientKey !== null) {
					this.encryptedSiteLink = true;

					this.apptSchedulerEncLink = this.win.location.protocol + "//" + this.win.location.hostname + "/ss" + "/" + this.scheduler_site_design.clientInfo.enryptedClientKey;//  this.scheduler_site_design.clientInfo.enryptedClientKey;
				}
				if (this.scheduler_site_design.clientInfo.encryptedClientApptTrackingLink !== null) {
					this.encryptedClientApptTrackingLink = true;
					this.copyApptTrackingEncLink = this.win.location.protocol + "//" + this.win.location.hostname + "/ss" + "/" + this.scheduler_site_design.clientInfo.encryptedClientApptTrackingLink;
				}
				if (this.scheduler_site_design.clientInfo.enryptedClientKey !== null) {
					this.BannerColor = data.siteLogoAndStyles.bannerColor;
					this.BannerTextColor = data.siteLogoAndStyles.bannerTextColor;
					this.ButtonBgColor = data.siteLogoAndStyles.buttonColor;
					this.ButtonTextColor = data.siteLogoAndStyles.buttonTextColor;
					//	this.walgreensLogo =  data.siteLogoAndStyles.mastheadLogo;
					this.clientLogo = data.siteLogoAndStyles.mastheadLogo;
					this.client_log_image = this.clientLogo === "Client Logo" ? data.siteLogoAndStyles.logoFile : null;
					//"Walgreens Logo" : "Client Logo"					
					if (this.clientLogo === "Client Logo") {
						this.image_source = environment.API_URL + "/ClientLogos/" + this.client_log_image;
						this.setValue = "2";
					}
					else if (this.clientLogo === "Walgreens Logo") {
						this.image_source = environment.image_file_path;
						this.setValue = "1";
					}
					this.landingPageBodyText = this.decodeHTML(data.siteLandingPageText.bodyText).replace(/<br\s*\/?>/mg, "\n").replace("&lt", "<").replace("&gt", ">");
					this.landingPageFooterText = this.decodeHTML(data.siteLandingPageText.footerText).replace(/<br\s*\/?>/mg, "\n").replace("&lt", "<").replace("&gt", ">");
					this.landingPageHeaderText = this.decodeHTML(data.siteLandingPageText.headerText).replace(/<br\s*\/?>/mg, "\n").replace("&lt", "<").replace("&gt", ">");
				}
				else {
					this.image_source = environment.image_file_path;
					this.setValue = "1";
				}
				if (data.siteRequiredRegisFieldList.length > 0) {
					this.corporateSchedulerForm = this._fb.group({
						firstNameRequired: [data.siteRequiredRegisFieldList[0].isFieldRequired, null],
						firstNameVisible: [data.siteRequiredRegisFieldList[0].isFieldVisible, null],
						middleNameRequired: [data.siteRequiredRegisFieldList[1].isFieldRequired, null],
						middleNameVisible: [data.siteRequiredRegisFieldList[1].isFieldVisible, null],
						lastNameRequired: [data.siteRequiredRegisFieldList[2].isFieldRequired, null],
						lastNameVisible: [data.siteRequiredRegisFieldList[2].isFieldVisible, null],
						employeeIdRequired: [data.siteRequiredRegisFieldList[3].isFieldRequired, null],
						employeeIdVisible: [data.siteRequiredRegisFieldList[3].isFieldVisible, null],
						EmailRequired: [data.siteRequiredRegisFieldList[4].isFieldRequired, null],
						EmailVisible: [data.siteRequiredRegisFieldList[4].isFieldVisible, null]
					});
					this.requiredFields.push(this.corporateSchedulerForm.value);
				}
				else {
					this.corporateSchedulerForm = this._fb.group({
						firstNameRequired: [0, null],
						firstNameVisible: [1, null],
						middleNameRequired: [0, null],
						middleNameVisible: [0, null],
						lastNameRequired: [0, null],
						lastNameVisible: [1, null],
						employeeIdRequired: [0, null],
						employeeIdVisible: [1, null],
						EmailRequired: [1, null],
						EmailVisible: [1, null]
					});
					this.requiredFields.push(this.corporateSchedulerForm.value);
				}
			});
		}

	}

	decodeHTML(html) {
		if (html !== null) {
			var txt = document.createElement('textarea');
			txt.innerHTML = html;
			return txt.value;
		}
		else {
			return "";
		}
	};

	defaultTextValues(text) {
		if (text === 'landingPageBodyText')
			this.landingPageBodyText = this.decodeHTML("There's one thing we know about flu season and that is it's unpredictable.&lt;br/&gt;&lt;br/&gt;That's why the CDC and other health organizations widely recommend that everyone 6 months of age and older get a flu shot every year as the best protection possible against the flu.&lt;br/&gt;&lt;br/&gt;Thank you for taking this important step in protecting both yourself as well as those around you from the adverse effect of the flu.&lt;br/&gt;&lt;br/&gt;Please use this page to create your appointment for your upcoming flu clinic.").replace(/<br\s*\/?>/mg, "\n").replace("&lt", "<").replace("&gt", ">");
		if (text === 'landingPageFooterText')
			this.landingPageFooterText = this.decodeHTML("All participants are <strong>recommended</strong> to wear a face covering, comfortable clothing and a short sleeve shirt on the day of the clinic.\n\nIn addition, it is required for us to provide you with the Vaccine Information Statement (VIS) prior to receiving your vaccination.  Please review the VIS by clicking <a href='visresources' target='_blank'>here</a>").replace(/<br\s*\/?>/mg, "\n").replace("&lt", "<").replace("&gt", ">");
		if (text === 'landingPageHeaderText')
			this.landingPageHeaderText = this.decodeHTML("Walgreens Immunization Clinic").replace(/<br\s*\/?>/mg, "\n").replace("&lt", "<").replace("&gt", ">");
	}
	defaultLandingValues() {

		this.landingPageBodyText = this.decodeHTML("There's one thing we know about flu season and that is it's unpredictable.&lt;br/&gt;&lt;br/&gt;That's why the CDC and other health organizations widely recommend that everyone 6 months of age and older get a flu shot every year as the best protection possible against the flu.&lt;br/&gt;&lt;br/&gt;Thank you for taking this important step in protecting both yourself as well as those around you from the adverse effect of the flu.&lt;br/&gt;&lt;br/&gt;Please use this page to create your appointment for your upcoming flu clinic.").replace(/<br\s*\/?>/mg, "\n").replace("&lt", "<").replace("&gt", ">");
		this.landingPageFooterText = this.decodeHTML("Please wear comfortable clothing and a short sleeve shirt on the day of the clinic.\n\nIn addition, it is required for us to provide you with the Vaccine Information Statement (VIS) prior to receiving your vaccination.  Please review the VIS by clicking <a href='visresources' target='_blank'>here</a>").replace(/<br\s*\/?>/mg, "\n").replace("&lt", "<").replace("&gt", ">");
		this.landingPageHeaderText = this.decodeHTML("Walgreens Immunization Clinic").replace(/<br\s*\/?>/mg, "\n").replace("&lt", "<").replace("&gt", ">");
	}

	defautLogoValues() {
		this.BannerColor = '#02a0c0';
		this.BannerTextColor = '#FFFFFF';
		this.ButtonBgColor = '#02a0c0';
		this.ButtonTextColor = '#FFFFFF';
		//this.walgreensLogo = "walgreens_1901_logo.png";
		//this.clientLogo = null;
	}
	// loadDataLazy(event) {
	// 	this.selectedMaxValue = [];
	// 	this.selectedMinValue = [];
	// 	//this.selectedDataList = [];
	// 	this.signUpDates = [];
	// 	this.signUpMaxDates = [];
	// 	let min_date_Value;
	// 	var curr_page_rec_count = event.first + Number(event.rows);
	// 	var page_to_get = curr_page_rec_count / 50;
	// 	page_to_get = Math.ceil(page_to_get);
	// 	var start_number = event.first;
	// 	var source_obj: any = {};
	// 	source_obj = this.scheduleSource;
	// 	this.csTbl = this.scheduleSource.clinicList.slice(start_number, (start_number + Number(event.rows)));
	// 	var list = [];
	// 	this.csTbl.filter(function (item) {
	// 		if (item.isScheduled == true) {
	// 			list.push(item);
	// 		}
	// 	}
	// 	);
	// 	for (let i = 0; i < this.csTbl.length; i++) {

	// 		if (this.csTbl[i].signupEndDate == null) {
	// 			min_date_Value = new Date(this.csTbl[i].clinicDate);
	// 			this.signUpDates.push(new Date(min_date_Value - 1));
	// 			this.signUpMaxDates.push(new Date(min_date_Value - 1));
	// 		}
	// 		else {
	// 			min_date_Value = new Date(this.csTbl[i].signupEndDate);
	// 			let max_date_value: any = new Date(this.csTbl[i].clinicDate);
	// 			this.signUpDates.push(min_date_Value);
	// 			this.signUpMaxDates.push(new Date(max_date_value - 1));
	// 		}

	// 	}

	// 	var temp_var = [];
	// 	var temp_true = [];
	// 	var temp_list = this.selectedDataList;
	// 	// var list_data = this.selectedDataList;
	// 	// list.forEach(function (item) {
	// 	// 	var index = list_data.findIndex(it => it.clinicPk === item.clinicPk);
	// 	// 	if (index == -1) {
	// 	// 		list_data.push(item);
	// 	// 	}
	// 	// }
	// 	// );
	// 	// this.selectedDataList = list_data;

	// 	this.csTbl.forEach(item => {
	// 		this.selectedMaxValue.push((item.maxImmunizers == -1 || item.maxImmunizers == null) ? 3 : item.maxImmunizers);
	// 		this.selectedMinValue.push(item.immunizers);
	// 	});


	// 	//}
	// }
	okClicked() {
		this.siteSetUp = false;
	}

	Save() {
		var validation: boolean = true;
		if (this.client_id !== "") {
			//"Walgreens Logo" : "Client Logo"
			if (this.setValue == "2" && (this.client_log_image == null)) {
				this.uploadFileClinet = true;
				this.message_text = "Logo & Styles Error: Please upload a client logo file or select the default Walgreens logo.";
				return;
			}
			this.spinner.show();
			var scheduler_site_design = new UpdateSchedulerSiteDesign();
			var siteLandingPageText = new SiteLandingPageText();
			var siteLogoAndStyles = new SiteLogoAndStyles();

			//Assigning 
			siteLandingPageText.bodyText = this.landingPageBodyText.replace(/\n/g, "&lt;br/&gt");
			siteLandingPageText.footerText = this.landingPageFooterText.replace(/\n/g, "&lt;br/&gt").replace(/</g, "&lt;").replace(/>/g, "&gt;");
			siteLandingPageText.headerText = this.landingPageHeaderText.replace(/\n/g, "&lt;br/&gt");
			scheduler_site_design.siteLandingPageText = siteLandingPageText;

			siteLogoAndStyles.bannerColor = this.BannerColor;
			siteLogoAndStyles.bannerTextColor = this.BannerTextColor;
			siteLogoAndStyles.buttonColor = this.ButtonBgColor;
			siteLogoAndStyles.buttonTextColor = this.ButtonTextColor;
			siteLogoAndStyles.logoFile = this.setValue == 1 ? "walgreens_email_logo.png" : this.client_log_image;
			siteLogoAndStyles.mastheadLogo = this.setValue == 1 ? "Walgreens Logo" : "Client Logo";
			siteLogoAndStyles.alternateText = "walgreens";

			scheduler_site_design.siteLogoAndStyles = siteLogoAndStyles;

			// clientInfo.clientId = this.client_id;
			// clientInfo.encryptedClientApptTrackingLink = null;
			// clientInfo.encryptedSiteLink = null;
			// clientInfo.encryptedClientKey = null;
			// clientInfo.clientName = this.client_name;
			// clientInfo.siteStatus = this.client_id;
			scheduler_site_design.clientInfo = this.scheduler_site_design.clientInfo;
			scheduler_site_design.clientInfo.siteStatus = Number(this.siteStatus);

			var siteRequiredFields1 = new Array<SiteRequiredRegisField>();
			//Required Fields
			if (this.scheduler_site_design.siteRequiredRegisFieldList.length <= 0) {
				scheduler_site_design.siteRequiredRegisFields = SiteRequiredFields;
				this.scheduler_site_design.siteRequiredRegisFieldList = SiteRequiredFields;
			}
			if (this.scheduler_site_design.siteRequiredRegisFieldList.length > 0) {
				this.scheduler_site_design.siteRequiredRegisFieldList.forEach(item => {
					var siteRequiredFields = new SiteRequiredRegisField();
					siteRequiredFields.displayName = item.displayName;
					siteRequiredFields.fieldName = item.fieldName;
					if (siteRequiredFields.fieldName !== null)
						siteRequiredFields.isFieldRequired = this.corporateSchedulerForm.controls[item.fieldName.concat("Required")].value ? 1 : 0;// this.scheduler_site_design.siteRequiredRegisFieldList[i].isFieldVisible;
					else
						siteRequiredFields.isFieldRequired = 0;
					if (siteRequiredFields.displayName !== null)
						siteRequiredFields.isFieldVisible = this.corporateSchedulerForm.controls[item.fieldName.concat("Visible")].value ? 1 : 0;
					else
						siteRequiredFields.isFieldVisible = 0;
					siteRequiredFields1.push(siteRequiredFields);
				});
				scheduler_site_design.siteRequiredRegisFields = siteRequiredFields1;
			}

			//ClinicsList
			//var clinicList = new Array<ClinicsList>();

			var temp_var = [];
			var temp_true = [];
			var all_data = [];
			var temp_list = this.selectedDataList;
			// this.scheduleSource.clinicList.filter(function (item) {
			// 	var temp = temp_list.filter(function (inner_item) {
			// 		if (inner_item.clinicPk == item.clinicPk) {
			// 			var index1 = all_data.findIndex(it => it.clinicPk === item.clinicPk);
			// 			if (index1 == -1) {
			// 				all_data.push(item);
			// 			}
			// 		}
			// 	});
			// 	if (temp.length == 0 && item.isScheduled) {
			// 		var index1 = all_data.findIndex(it => it.clinicPk === item.clinicPk);
			// 		if (index1 == -1) {
			// 			all_data.push(item);
			// 		}

			// 	}
			// });
			//temp_list = all_data;
			this.scheduleSource.clinicList.filter(function (item) {
				var temp = temp_list.filter(function (inner_item) {
					if (inner_item.clinicPk == item.clinicPk) {
						// if (temp == undefined && item.isScheduled == false) {
						// 	//item.isScheduled = false;
						// 	//temp_true.push(item);
						// }
						inner_item.isScheduled = true;
						return true;
					}

				});
				if (temp.length == 0 && item.isScheduled) {
					item.isScheduled = false;
					temp_var.push(item);
				}
			});
			temp_var.forEach(item => {
				this.selectedDataList.push(item);
			});
			var clinic_list = [];
			if (this.selectedDataList.length > 0) {
				scheduler_site_design.clinicsList = this.selectedDataList.filter(item => item.isStoreConfirmed);
				scheduler_site_design.clinicsList.forEach(element => {
					let min_date_Value;
					min_date_Value = new Date(element.clinicDate);
					this.maxImmunizers = this.maxImmunizersList.filter(item => item.clinicPk == element.clinicPk);
					this.minImmunizers = this.minImmunizersList.filter(item => item.clinicPk == element.clinicPk);
					this.signUpDate = this.signupEndDate.filter(item => item.clinicPk == element.clinicPk);
					element.maxImmunizers = Number(this.maxImmunizers.length > 0 ? this.maxImmunizers[0].max_imm : (element.maxImmunizers == -1 || element.maxImmunizers == null) ? 3 : element.maxImmunizers);
					element.immunizers = Number(this.minImmunizers.length > 0 ? this.minImmunizers[0].min_imm : element.immunizers);
					if (element.immunizers > element.maxImmunizers) {
						this.spinner.hide();
						this.message_text = "Min Immunizers should not exceed Max Immunizers";
						this.uploadFileClinet = true;
						validation = false;
						return;

					}
					element.signupEndDate = this.signUpDate.length > 0 ? this.signUpDate[0].signupEndDate : new Date(min_date_Value - 1);
					element.signupEndDate = this.signUpDate.length > 0 ? this.signUpDate[0].signupEndDate : element.signupEndDate.getMonth() + 1 + "/" + element.signupEndDate.getDate() + "/" + element.signupEndDate.getFullYear();

					if (this.maxImmunizers.length > 0 ||
						this.minImmunizers.length > 0 || this.signUpDate.length > 0) {
						let index = clinic_list.findIndex(it => it.clinicPk === element.clinicPk);
						if (index == -1) {
							clinic_list.push(element);
						}
					}

					if (this.temp_true.length > 0) {
						let index = this.temp_true.findIndex(it => it.clinicPk === element.clinicPk);
						if (index > -1) {
							let index1 = clinic_list.findIndex(it => it.clinicPk === element.clinicPk);
							if (index1 == -1) {
								clinic_list.push(element);
							}
						}

					}

					if (this.temp_false.length > 0) {
						let index = this.temp_false.findIndex(it => it.clinicPk === element.clinicPk);
						if (index > -1) {
							let index1 = clinic_list.findIndex(it => it.clinicPk === element.clinicPk);
							if (index1 == -1) {
								clinic_list.push(element);
							}
						}

					}
					this.maxImmunizers = [];
					this.minImmunizers = [];
					this.signUpDate = [];
				});
				scheduler_site_design.clinicsList = clinic_list;
				if (validation) {
					this.previewService.updateSchedulerClientSetUp(scheduler_site_design, 0).subscribe((data:any) => {
						this.spinner.hide();
						if (data.errorS !== null) {
							if (data.errorS.errorCode == -1) {
								this.message_text = String.Format(ErrorMessages['blockApptKeyMessage'], data.errorS.errorMessage);
								this.overRideOption = true;
								this.schedulerSiteDesign = scheduler_site_design;
								return;
							}
						}
						this.siteSetUpSuccess = true;
						this.getCorporateSchedularClinics(this.client_id, 0);
						this.getSchedulerSiteDesign();

					},
						err => {
							/*switch (err) {
								case 500:
									alert('ErrorCode 500 received from Service');
									break;
								case 400:
									alert('Unhandled exception error code received from Service');
									break;
								default:
									alert('Unhandled exception error code received from Service');
									break;
							}*/
							this.showErrorMessage(err);
							this.spinner.hide();
						});

				}
			}
			else {
				this.spinner.hide();
				this.message_text = "Please select at least one clinic";
				this.siteSetUp = true;
			}

		}
		else {
			this.spinner.hide();
			this.message_text = "Please select a corporate client";
			this.siteSetUp = true;
		}

	}
	maxImmunizersChanged(clinic_pk, max_imm) {
		var index = this.maxImmunizersList.findIndex(it => it.clinicPk === clinic_pk);
		if (index > -1) {
			this.maxImmunizersList.splice(index, 1);
		}
		this.maxImmunizersList.push({ "clinicPk": clinic_pk, "max_imm": max_imm });
		//this.selectedDataList.push(this.maxImmunizersList);
	}
	minImmunizersChanged(clinic_pk, min_imm) {
		var index = this.minImmunizersList.findIndex(it => it.clinicPk === clinic_pk);
		if (index > -1) {
			this.minImmunizersList.splice(index, 1);
		}
		this.minImmunizersList.push({ "clinicPk": clinic_pk, "min_imm": min_imm });
		//this.selectedDataList.push(this.minImmunizersList);
	}
	selectSignUpDate(clinic_pk, value) {
		var index = this.signupEndDate.findIndex(it => it.clinicPk === clinic_pk);
		if (index > -1) {
			this.signupEndDate.splice(index, 1);
		}
		this.signupEndDate.push({ "clinicPk": clinic_pk, "signupEndDate": value.getMonth() + 1 + "/" + value.getDate() + "/" + value.getFullYear() });
	}
	disabledControl(value) {
		let date = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0));
		let clinic_date: Date = new Date(value.clinicDate);
		if (value.isStoreConfirmed) {

			if (clinic_date <= date || value.isCanceled) {
				return true;
			}
			else {
				return false;
			}
		}
		else if (date <= clinic_date) {
			return true;

		}
		else {
			return true;
		}

	}
	ok() {
		this.siteSetUpSuccess = false;
	}
	uploadFile(event) {
		if (this.setValue == "2") {
			this.files = event.target.files;
			if (this.files.length > 0) {
				if (this.isValidFiles(this.files)) {
					this.client_log_image = this.uploadedFileName = event.target.files[0].name;
					let formData: FormData = new FormData();
					if (this.files.length > 0) {
						for (var j = 0; j < this.files.length; j++) {
							this.files[j].name.replace(".png", ".PNG").replace(".jpeg", ".JPEG").replace(".jpg", ".JPG");
							formData.append("file", this.files[j]);
						}
					}
					this.previewService.imageUpload(formData).subscribe(data => {
						this.image_source = environment.API_URL + "/ClientLogos/" + this.uploadedFileName;
					},
						err => {
							/*switch (err) {
								case 500:
									alert('ErrorCode 500 received from Service');
									break;
								case 400:
									alert('Unhandled exception error code received from Service');
									break;
								default:
									alert('Unhandled exception error code received from Service');
									break;
							}*/
							this.showErrorMessage(err);
							this.client_log_image = null;
							this.uploadedFileName = '';
							this.image_source = '';
						});
				}
				else {
					this.message_text = "Please upload PNG, JPG or JPEG formats.";
					this.uploadFileClinet = true;
				}
			}
		}
		else {
			this.message_text = "Please select the client logo option to upload the image";
			this.uploadFileClinet = true;
		}
		//event = null;
	}
	setradio(value: any) {
		this.setValue = value;
		if (value === "1") {
			this.image_source = environment.image_file_path;
		}
		else {
			this.image_source = "";
			if (this.clientLogo === 'Client Logo' && this.client_log_image !== null) {
				this.image_source = environment.API_URL + "/ClientLogos/" + this.client_log_image;
			}
		}
	}
	uploadOk() {
		this.uploadFileClinet = false;
	}
	private isValidFiles(files) {
		return this.isValidFileExtension(files);
	}

	private isValidFileExtension(files) {
		for (var i = 0; i < files.length; i++) {
			// Get file extension
			var ext = files[i].name.toLocaleUpperCase().split('.').pop() || files[i].name;

			// Check the extension exists
			var exists = this.fileExt.filter(element => element == ext);
			if (exists.length <= 0) {
				return false;
			}
			//return false;
		}
		return true;
	}

	accessScheduler() {
		if (!this.accessSchedulerEncryptedLink) {
			return;
		}
		/*var encrypt_data = new EncryptDecrypt();
		encrypt_data.encryptedS = this.accessSchedulerEncryptedLink;
		encrypt_data.decryptedS = "";
		this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
			let decrypted_data = JSON.parse(data._body).decryptedS;
			if (decrypted_data !== null || decrypted_data !== undefined) {
				let splited_Data = decrypted_data.split("|");
				var scheduleuserInfo = new SchedulerUserInfo();
				scheduleuserInfo.userName = splited_Data[0];
				scheduleuserInfo.userPk = splited_Data[1];
				scheduleuserInfo.password = splited_Data[2];
				scheduleuserInfo.designPk = splited_Data[3];
				scheduleuserInfo.appointmentPK = ((!splited_Data[4] || typeof (splited_Data[4]) === 'string') ? -1 : splited_Data[4]);
				scheduleuserInfo.redirectPage = ''// splited_Data[5];
				scheduleuserInfo.clinicPK = splited_Data[6] ? splited_Data[6] : -1;
				SessionDetails.setSchedulerUserInfo(scheduleuserInfo);
				let path = this.protocol + "//" + this.domain + ((this.port) ? ":" + this.port : "");
				window.open(path + '/schedulerLanding');
				//this.router.navigate(['/schedulerLanding']);
			}
		});*/
		this.navigateToSchedulerSites("schedulerLanding");
	}

	accessTracker() {
		if (!this.accessSchedulerTrackerEncryptedLink) {
			return;
		}
		
		this.navigateToSchedulerSites("clientSchedulerApptTracker");
	}
	navigateToSchedulerSites(page_name:string){
		var encrypt_data = new EncryptDecrypt();
		encrypt_data.encryptedS = this.accessSchedulerTrackerEncryptedLink;
		encrypt_data.decryptedS = "";
		this.authenticationService.postEncryptionDecryption(encrypt_data).subscribe((data: any) => {
			let decrypted_data = JSON.parse(data._body).decryptedS;
			if (decrypted_data !== null || decrypted_data !== undefined) {
				decrypted_data= SessionDetails.getD(SessionDetails.absJ,decrypted_data);
				let splited_Data = decrypted_data.split("|");
				var scheduleuserInfo = new SchedulerUserInfo();
				scheduleuserInfo.userName = splited_Data[0];
				scheduleuserInfo.userPk = splited_Data[1];
				scheduleuserInfo.password = splited_Data[2];
				scheduleuserInfo.designPk = splited_Data[3];
				scheduleuserInfo.appointmentPK = ((!splited_Data[4] || typeof (splited_Data[4]) === 'string') ? -1 : splited_Data[4]);
				scheduleuserInfo.redirectPage = ''// splited_Data[5];
				scheduleuserInfo.clinicPK = splited_Data[6] ? splited_Data[6] : -1;
				SessionDetails.setSchedulerUserInfo(scheduleuserInfo);
				let path = this.protocol + "//" + this.domain + ((this.port) ? ":" + this.port : "");
				window.open(path + '/' + page_name);
			}
		});
	}
	Override() {
		this.spinner.show();
		this.previewService.updateSchedulerClientSetUp(this.schedulerSiteDesign, 1).subscribe(data => {
			this.siteSetUpSuccess = true;

			//this.selectedDataList = [];
			this.maxImmunizersList = [];
			this.minImmunizersList = [];
			//temp_true = [];
			//temp_var = [];
			this.spinner.hide();
			this.overRideOption = false;

			this.getCorporateSchedularClinics(this.client_id, 0);
			this.getSchedulerSiteDesign();
		},
			err => {
				/*switch (err) {
					case 500:
						alert('ErrorCode 500 received from Service');
						break;
					case 400:
						alert('Unhandled exception error code received from Service');
						break;
					default:
						alert('Unhandled exception error code received from Service');
						break;
				}*/
				this.showErrorMessage(err);
				this.spinner.hide();
			});
	}
	showErrorMessage(err:any){
		switch (err) {
			case 500:
				alert('ErrorCode 500 received from Service');
				break;
			case 400:
				alert('Unhandled exception error code received from Service');
				break;
			default:
				alert('Unhandled exception error code received from Service');
				break;
		}
	}
	Cancel() {

		this.getCorporateSchedularClinics(this.client_id, 0);
		this.overRideOption = false;
	}
	onRowSelect(event, clinic_pk) {
		var index = this.temp_true.findIndex(it => it.clinicPk === clinic_pk);
		if (index > -1) {
			this.temp_true.splice(index, 1);
		}
		this.temp_true.push(event);
	}
	onRowUnselect(event, clinic_pk) {
		var index = this.temp_false.findIndex(it => it.clinicPk === clinic_pk);
		if (index > -1) {
			this.temp_false.splice(index, 1);
		}
		this.temp_false.push(event);
	}
	customSort(event: SortEvent) {

		if (event.field == 'clientName' || event.field == 'clinicAddress' || event.field == 'clinicDate') {
			var reA = /[^a-zA-Z]/g;
			var reN = /[^0-9]/g;
			event.data.sort((data1, data2) => {

				let value1 = data1[event.field];
				let value2 = data2[event.field];
				let result = null;

				if (value1 === null && value2 !== null)
					result = -1;
				else if (value1 !== null && value2 === null)
					result = 1;
				else if (value1 === null && value2 === null)
					result = 0;
				else if (typeof value1 === 'string' && typeof value2 === 'string') {
					if (event.field === 'clientName' || event.field === 'clinicAddress') {
						var aA = value1.replace(reA, "");
						var bA = value2.replace(reA, "");
						if (aA === bA) {
							var aN = parseInt(value1.replace(reN, ""), 10);
							var bN = parseInt(value2.replace(reN, ""), 10);
							result = aN === bN ? 0 : aN > bN ? 1 : -1;
						} else {
							result = aA > bA ? 1 : -1;
						}
					}
					else
						result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
				}
				else
					result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

				return (event.order * result);
				//return result;
			});
			this.signUpDates = [];
			this.signUpMaxDates = [];
			this.selectedMinValue = [];
			this.selectedMaxValue = [];
			for (let i = 0; i < event.data.length; i++) {

				if (event.data[i].signupEndDate == null) {
					let min_date_Value: any = new Date(event.data[i].clinicDate);
					this.signUpDates.push(new Date(min_date_Value - 1));
					this.signUpMaxDates.push(new Date(min_date_Value - 1));
				}
				else {
					let min_date_Value = new Date(event.data[i].signupEndDate);
					let max_date_value: any = new Date(event.data[i].clinicDate);
					this.signUpDates.push(min_date_Value);
					this.signUpMaxDates.push(new Date(max_date_value - 1));
				}

			}

			event.data.forEach(item => {
				this.selectedMaxValue.push((item.maxImmunizers == -1 || item.maxImmunizers == null) ? 3 : item.maxImmunizers);
				this.selectedMinValue.push(item.immunizers);
			});
		}
	}
}