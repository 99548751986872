import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationSettings } from '../../../common/application-settings';
import {  FormGroup,  FormBuilder, Validators } from '@angular/forms';
import { PotentialLocalBusinessAndContactStatusService } from '../../services/potential-local-business.service';
import { SessionDetails } from '../../../../utility/session';
import { FilterPipe } from '../../../store/pipes/filter-pipe';
import { saveAs as importedSaveAs } from "file-saver";
import { PotentialLocalBusiness } from '../../../../models/PotentialLocalBusiness';
import { Router } from '../../../../../../node_modules/@angular/router';
import { Paginator } from '../../../../../../node_modules/primeng/paginator';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-potentionallocalbusinessandcontactstatus',
  templateUrl: './potentionallocalbusinessandcontactstatus.component.html',
  styleUrls: ['./potentionallocalbusinessandcontactstatus.component.css'],
  providers: [FilterPipe]
})  
export class PotentionallocalbusinessandcontactstatusComponent implements OnInit {
  loading: any;
  storeId: any;
  ddlFormat: any;
canExport: any;
  outreachstatus: any;
  contacted: any;
  status: any;
  fromDate: any;
  toDate: any;
  outreachEffort: any;
  minDate: any;
  maxDate: any;
  from_date: any;
  to_date: any;
  isDisable: boolean = false;
  outreachStartDate:any;
  outreachEndDate:any;
  potentialLocalBusinessContactStatusReportForm: FormGroup;
  potentialLocalBusinessContactStatusReportArray: any = [];
  potentialLocalBusinessContactStatusReportSource: any;
  totalRecords: number = 0;
  allStatuses: any = [];
  canShowNoBusinessesText: boolean = false;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  isDisableDates:boolean=false;
  @ViewChild('plbcsPager') plbcsPager: Paginator;
  constructor(private fb: FormBuilder,private router: Router, private potential_service: PotentialLocalBusinessAndContactStatusService, private message_service: MessageServiceService) {
    if (this.router.url.indexOf('immunization') > -1) {
      this.outreachEffort = "3";
      this.outreachStartDate  = ApplicationSettings.getIMmunizationStartDate(new Date().getFullYear());
      this.outreachEndDate = ApplicationSettings.getImmunizationEndDate(new Date().getFullYear());
    } else {
      this.outreachEffort = "1";
      this.outreachStartDate  = ApplicationSettings.getSRStartDate(new Date().getFullYear());
      this.outreachEndDate = ApplicationSettings.getSREndDate(new Date().getFullYear());
    }
    
    this.message_service.getStoreID().subscribe(x => {
      this.storeId = x.text;
      this.loading = true;
      this.generateReport();
    });
  }

  ngOnInit() {
   
    this.storeId = SessionDetails.GetStoreId();
    this.outreachstatus = "-1";
    this.contacted = "3";
    this.isDisableDates=true;
    this.ddlFormat = "0";
    this.setFormControls();
      this.potentialLocalBusinessContactStatusReportForm.patchValue({
        fromDate:new Date(this.outreachStartDate),
        toDate:new Date(this.outreachEndDate)
      })
    
    this.setDates();
    this.potential_service.getOutreachStatus().subscribe((resultArray: any) => {
      let outreach_program = ((this.outreachEffort == "1") ? "SR" : "IP");
      this.allStatuses = resultArray;
      var temp = resultArray.filter(x => x.outreachProgram == outreach_program && x.isActive /*== true*/ && [ 9, 10].indexOf(x.outreachStatusId) == -1);
      this.status = temp;
      this.generateReport();
    });
  }
  setFormControls() {
    
    this.potentialLocalBusinessContactStatusReportForm = this.fb.group({
      'fromDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'toDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'ddlcontacted':["3"],
      'ddloutreachstatus':["-1"],
       'ddlFormat': [this.ddlFormat],
      
     });
     //this.generateReport();
   }
  setDates() {
    
    this.minDate = this.fromDate = new Date(this.outreachStartDate);
    this.maxDate = this.toDate = new Date(this.outreachEndDate);
    // if(this.outreachEffort == 1){
    //     this.minDate = this.fromDate = new Date("09/01/" + this.fromDate.getFullYear());
    //     this.maxDate = this.toDate = new Date("08/31/" + this.toDate.getFullYear());
    //   }
    //this.toDate.setMonth(this.toDate.getMonth() + 1);
  }
  setCalendarDates() {
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
  }
  reset() {
    this.contacted = "3";
    this.ddlFormat = "0";
    this.outreachstatus = -1;
    this.setFormControls();
    this.setDates();
   this.generateReport();
  }
  generateReport() {
    //
    let data= this.potentialLocalBusinessContactStatusReportForm.value;
    let d1 = data.fromDate;
    let d2 = data.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    
    let potential_local_business = new PotentialLocalBusiness(this.storeId, this.outreachEffort,data.ddlcontacted, this.from_date, this.to_date, data.ddloutreachstatus, "PotentialLocalBusiness", "xlsxv");
    this.potential_service.getPotentialLocalBusinessData(potential_local_business).subscribe((resultArray: any) => {
      if (resultArray.length == 0) {
        this.canShowNoBusinessesText = true;
      } else {
        this.canShowNoBusinessesText = false;
      }
      this.potentialLocalBusinessContactStatusReportSource = resultArray;
      this.totalRecords = this.potentialLocalBusinessContactStatusReportSource.length;
      var temp = this.potentialLocalBusinessContactStatusReportSource.slice(0, 50);
      this.potentialLocalBusinessContactStatusReportArray = temp;
      setTimeout(() => {
        this.loading = false;
        this.canExport = true;
      }, 1000)
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.potentialLocalBusinessContactStatusReportSource = [];
      this.totalRecords = 0;
    });
    this.ddlFormat = "0";
  }
  updateOutreachStatus($event) {
    let data=this.potentialLocalBusinessContactStatusReportForm.value;
    let outreach_program = ((this.outreachEffort == "1") ? "SR" : "IP");
    if (data.ddlcontacted == "0") {
      let temp = this.allStatuses.filter(x => x.outreachProgram == outreach_program && x.isActive /* == true*/ && [0,2].indexOf(x.outreachStatusId) > -1);
      this.status = temp;
      this.isDisable = false;
      this.outreachstatus = 0;
      this.potentialLocalBusinessContactStatusReportForm.controls['ddloutreachstatus'].setValue('-1');
    }
    else if(data.ddlcontacted==1){
      let temp = this.allStatuses.filter(x => x.outreachProgram == outreach_program && x.isActive /* == true*/ && [0,2,9, 10].indexOf(x.outreachStatusId) == -1);
      this.status = temp;
      this.isDisable = false;
      this.potentialLocalBusinessContactStatusReportForm.controls['ddloutreachstatus'].setValue('-1');
      this.outreachstatus = 0;
    }
    else {
      let temp = this.allStatuses.filter(x => x.outreachProgram == outreach_program && x.isActive /* == true*/ && [ 9, 10].indexOf(x.outreachStatusId) == -1);
      this.status = temp;
      this.isDisable = false;
      this.potentialLocalBusinessContactStatusReportForm.controls['ddloutreachstatus'].setValue('-1');
      this.outreachstatus = -1;
    }

    if(data.ddlcontacted==3||data.ddlcontacted==0){
      this.isDisableDates=true;
    }
    else{
      this.isDisableDates=false;
    }
    this.canExportOnchange($event);
  }
  generatereportformat(): void {
    
    let data= this.potentialLocalBusinessContactStatusReportForm.value;
    this.loading = true;
    let d1 = data.fromDate;
    let d2 = data.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    let report_name: string = ((this.outreachEffort == "1") ? "SR_" : "IP_") + "LocalOpportunityAndContactStatus";
    let potential_local_business = new PotentialLocalBusiness(this.storeId, this.outreachEffort, data.ddlcontacted, this.from_date, this.to_date, data.ddloutreachstatus,"PotentialLocalBusiness", "xlsxv");
    this.potential_service.exportReportData(potential_local_business).subscribe((res:any) => {
      importedSaveAs(res.body, report_name+".xlsx");
      this.loading = false;
      this.canExport = true;
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
    this.ddlFormat = "0";
  }

  paginate(event) {
    this.potentialLocalBusinessContactStatusReportArray = [];
    this.loading = true;
    var curr_page_rec_count = event.first + event.rows;
    var page_to_get = curr_page_rec_count / 50;
    page_to_get = Math.ceil(page_to_get);
    var start_number = ((event.first < 50) ? (event.first - (50 * (page_to_get - 1))) : event.first);
    if (this.potentialLocalBusinessContactStatusReportSource) {
      var temp = this.potentialLocalBusinessContactStatusReportSource.slice(start_number, (start_number + 50));
      this.potentialLocalBusinessContactStatusReportArray = temp;
      this.totalRecords = this.potentialLocalBusinessContactStatusReportSource.length;
    }
    this.loading = false;
  }
  canExportOnchange($event) {
    this.canExport = false;
  }
  validateExportType() {
    if (this.ddlFormat == "0" || !this.canExport) {
      return true;
    }
    else {
      return false;
    }
  }
    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}
