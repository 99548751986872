// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button{
    background-color: #2a7db5
}`, "",{"version":3,"sources":["webpack://./src/app/modules/contractaggreement/components/communityoutreach/communityoutreach.component.css"],"names":[],"mappings":"AAAA;IACI;AACJ","sourcesContent":["button{\r\n    background-color: #2a7db5\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
