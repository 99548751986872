import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as appSettings from '../../globals';
//import { NGXLogger } from 'ngx-logger';
import { ApplicationSettings } from './../../../common/application-settings';
import { ImmunizationFinanceService } from '../../services/immunizationfinance.service';
import { saveAs as importedSaveAs } from "file-saver";
import { ImmunizationFinance } from '../../../../models/ImmunizationFinance';
import { ErrorMessages } from "../../../../config-files/error-messages";
//import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-covidimmunizationfinancereport',
  templateUrl: './covidimmunizationfinancereport.component.html', 
  styleUrls: ['./covidimmunizationfinancereport.component.css']
})

export class CovidimmunizationfinanceReportComponent implements OnInit {
  immunizationFinanceReportForm: FormGroup;
  immunizationFinanceReportArray: any;
  outreachStartDate = ApplicationSettings.outreachStartDateCovid;
  private datasource: any;
  ddlSeason: number;
  private isCurrentSeason = 1;
  fromDate: Date;
  ddlFormat: any;
  toDate: Date;
  maxDate: Date;
  minDate: Date;
  private from_date: string;
  private to_date: string;
  totalRecords: number;
  loading: boolean;

  checked: boolean = false;
  private currentSeason = appSettings.covidSeason;
  filterByDate: boolean = false;
  seasons: any = [];
  canExport: boolean = true;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";

  showGrid: boolean = false;
  covidOnly: boolean = true;

  constructor(
    private fb: FormBuilder,
    //private logger: NGXLogger, 
    private imzFinanceService: ImmunizationFinanceService, /*private spinner: NgxSpinnerService*/
    private spinner: NgxSpinnerService) {
  }
  ngOnInit() {
    
    for (let report_year: number = (new Date(this.outreachStartDate)).getFullYear(); report_year >= parseInt(appSettings.covidReportStartYear); report_year--) {
      this.seasons.push({
        "yearName": report_year.toString() + "-" + (report_year + 1).toString(),
        "yearCode": report_year.toString()
      });
    }
    this.ddlFormat = "0";
    this.filterByDate = false;
    this.ddlSeason = (new Date(this.outreachStartDate)).getFullYear();
    this.setDates();
    this.setFormControls();
    //this.generateReport();
  }
  onFilterChange(eve: any) {
  }
  setFormControls() {
    this.immunizationFinanceReportForm = this.fb.group({
      'fDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'tDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
      'ddlSeason': [this.currentSeason],
      'chkFilterByDate': [false],
      'ddlFormat': ["0"],
      'covidOnly': [true]
    });
  }
  generateReport(): void {
    if (!this.showGrid) {
      this.spinner.show();
    } else {
      this.loading = true;
    }
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = (d1.getMonth() + 1) + "/" + (d1.getDate()) + "/" + (d1.getFullYear());
    this.to_date = (d2.getMonth() + 1) + "/" + (d2.getDate()) + "/" + (d2.getFullYear());

    if (this.ddlSeason != this.currentSeason) {
      this.isCurrentSeason = 0;
    } else {
      this.isCurrentSeason = 1;
    }
    let data = this.immunizationFinanceReportForm.value;
    let immz_finance = new ImmunizationFinance(data.fDate, data.tDate, this.isCurrentSeason, data.ddlSeason, data.chkFilterByDate, this.covidOnly);
    this.imzFinanceService.getImmunizationFinanceReportData(immz_finance).subscribe((resultArray: any) => {
      this.datasource = resultArray
      this.totalRecords = this.datasource.length;
      //this.logger.log("totalRecords:" + this.totalRecords);
      this.immunizationFinanceReportArray = this.datasource;
      setTimeout(() => {
        this.loading = false;
        this.spinner.hide();
        this.showGrid = true;
        this.canExport = true;
      }, 1000)
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
      this.immunizationFinanceReportArray = [];
      this.totalRecords = 0;
    });
  }

  generateReportFormat(): void {
    this.loading = true;
    let data = this.immunizationFinanceReportForm.value;
    let immz_finance = new ImmunizationFinance(data.fDate, data.tDate, this.isCurrentSeason, data.ddlSeason, data.chkFilterByDate, this.covidOnly, "ImmunizationFinance", "xlsxv");
    this.imzFinanceService.exportReportData(immz_finance).subscribe((res:any) => {
      importedSaveAs(res.body, "ImmunizationFinance.xlsx");
      this.loading = false;
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
  setDates() {
    if(this.immunizationFinanceReportForm != undefined){
      this.ddlSeason=this.immunizationFinanceReportForm.controls['ddlSeason'].value;
    } 
    let tempMinDate: Date;
    let tempMaxDate: Date;
    tempMinDate = new Date(ApplicationSettings.getCovidStartDate(this.ddlSeason));
    tempMaxDate = new Date(ApplicationSettings.getCovidEndDate(this.ddlSeason));
    this.minDate = new Date(tempMinDate);
    this.maxDate = new Date(tempMaxDate)
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    this.canExport = false;
    if(this.immunizationFinanceReportForm != undefined){
      this.immunizationFinanceReportForm.patchValue({
        fDate:this.fromDate,
        tDate:this.toDate })
      }
  }
  reset(): void {
    //this.loading = true;
    this.ddlSeason = this.currentSeason;
    this.filterByDate = false;
    this.setDates();
    this.isCurrentSeason = 1;
    this.ddlFormat = 0;
    this.showGrid = false;
    this.covidOnly = true;
    this.setFormControls();
    //this.generateReport();
  }
  canExportOnchange() {
    this.canExport = false;
  }
  validateExportType() {
    if (this.ddlFormat == "0" || !this.canExport)
      return true;
    else
      return false;
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
