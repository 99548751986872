export const userID = 41;
export const storeID = 13;
export const ipSeason = 2024;
export const covidSeason = 2021;
export const seasonStartDate: string = "05/01/2014";
export const seasonEndDate: string = "04/30/2018";
export const outreachEffortIP = 3;
export const outreachEffortSO =1;
export const userRole = "admin";
export const reportStartYear="2014";
export const covidReportStartYear="2020";
export const outreachStartDateSR: string = "06/01/2018";
export const outreachStartDateIP: string = "05/01/2018";
export const outreachProgramSelected: string = "SR";