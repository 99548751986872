import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { PotentialClientInterestService } from '../../services/potential-client-interest.service';

@Component({
  selector: 'app-previous-client-interest',
  templateUrl: './previous-client-interest.component.html',
  styleUrls: ['./previous-client-interest.component.css']
})
export class PreviousClientInterestComponent implements OnInit {
  potentialInterestBusinessPk: any;
  potentialInterestEmail: any;
  dialogSummary: string;
  dialogMsg: string;
  display: boolean = false;
  success_message: boolean;
  potentialInterestAlreadyExists: boolean = false;
  showErrorMessageHtml: boolean = false;

  constructor(private spinner: NgxSpinnerService, private _apiservice: PotentialClientInterestService) { }

  ngOnInit(): void {
    this.spinner.hide();
    this.sendEmail();
  }


  sendEmail() {
    this.potentialInterestBusinessPk = sessionStorage["potentialInterestBusinessPk"];
    this.potentialInterestEmail = sessionStorage["potentialInterestEmail"];
    this.spinner.show();
    if (this.potentialInterestBusinessPk && this.potentialInterestEmail) {
      this._apiservice.SendPotentialClientInterestEmail(this.potentialInterestBusinessPk, this.potentialInterestEmail).subscribe((data: any) => {
        
        this.spinner.hide();
        if(data == 'exists')
        {
          this.potentialInterestAlreadyExists = true;
        }
        sessionStorage.clear();        
        switch (data.errorS.errorCode) {
          case '0':
          case 0:
            this.showDialogForMail(ErrorMessages['contract'], ErrorMessages['contractEmailSent'], true);
            break;
          case -4:
          case '-4':
            this.showDialogForMail(ErrorMessages['contractAlert'], data.errorS.errorMessage, false);
            break;
          default:
            this.showDialogForMail(ErrorMessages['contract'], data.errorS.errorMessage, false);
            break;
        }

      },
        err => {
          this.spinner.hide();
          this.showErrorMessageHtml = true;
          sessionStorage.clear();          
          switch (err) {
            case 500:
              this.showDialogForMail(ErrorMessages['serverError'], err.json().Message, false);
              break;
            case 400:
              this.showDialogForMail(ErrorMessages['clientError'], err.json().Message, false);
              break;
            default:
              this.showDialogForMail(ErrorMessages['serverError'], err.json().Message, false);
              break;
          }

        });
    }
  }



  showDialogForMail(msgSummary: string, msgDetail: string, success_message: boolean) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
    this.success_message = success_message;
  }




}

