import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImmunizationFinance } from '../../../models/ImmunizationFinance';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ImmunizationFinanceService {
  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL;
  private cURL;
  constructor(private http: HttpClient) { }
  public getImmunizationFinanceReportData(immz_finance: ImmunizationFinance): Observable<any> {

    this.cURL = this._url + "/api/JsImmunizationFinance_get";

    console.log(this.cURL);
    return this.http.post(this.cURL, immz_finance, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  public exportReportData(immz_finance: ImmunizationFinance) {
    let user_data = SessionDetails.GetUserInfo()
    let headers: Headers = new Headers();
    headers.append("Content-Type", "application/json");
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,user_data.userName + ':'+ user_data.password);                    
    headers.append('Authorization', 'Basic ' + usernamePassword)
    let url = this._url + "/api/JsImmunizationFinance_downloadReport";
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    return this.http.post(url, immz_finance,  {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });
    .pipe(catchError(Util.handleErrorV2));
  }
}