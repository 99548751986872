import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-senior-outreach-email-template',
  templateUrl: './senior-outreach-email-template.component.html',
  styleUrls: ['./senior-outreach-email-template.component.css']
})
export class SeniorOutreachEmailTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
