
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'groupBy'
  })
export class Contactlogpipe implements PipeTransform {
    transform(input: any, prop: string): any {
      if (!isArray(input)) {
        return input;
      }
  
      const arr: { [key: string]: Array<any> } = {};
  
      for (const value of input) {
        const field: any = getProperty(value, prop);
  
        if (isUndefined(arr[field])) {
          arr[field] = [];
        }
  
        arr[field].push(value);
      }
  
      return Object.keys(arr).map(key => ({ key, 'value': arr[key] }));
    }
  }

//   @Pipe({name: 'groupBy'})
// export class Contactlogpipe implements PipeTransform {
//   transform(value: Array<any>, field: string): Array<any> {
//     const groupedObj = value.reduce((prev, cur)=> {
//       if(!prev[cur[field]]) {
//         prev[cur[field]] = [cur];
//       } else {
//         prev[cur[field]].push(cur);
//       }
//       return prev;
//     }, {});
//     return Object.keys(groupedObj).map(key => return {key, value: groupedObj[key]});
//   }
// }

  export function getProperty (value: { [key: string]: any}, key: string): any {
  
    if (isNil(value) || !isObject(value)) {
      return undefined;
    }
    
    const keys: string[] = key.split('.');
    let result: any = value[keys.shift()!];
    
    for (const key of keys) {
      if (isNil(result) || !    (result)) {
        return undefined;
      }
      
      result = result[key];
    }
    
    return result;
  }
  export function isUndefined (value: any): value is undefined {
  
    return typeof value === 'undefined';
  }
  export function isNil (value: any): value is (null|undefined) {
    return value === null || typeof (value) === 'undefined';
  }
  export function isObject (value: any): boolean {
  
    return typeof value === 'object';
  }

  export function isArray (value: any): boolean {
  
    return Array.isArray(value);
  }