import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReusableModule } from '../../modules/common/components/reusable.module';
import { NgPipesModule } from 'ngx-pipes';
import { CalendarModule } from 'primeng/calendar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalClinicDetailsComponent } from './components/local-clinic-details/local-clinic-details.component';
import { ClinicdetailsService } from './services/clinicdetails.service';
import { OutreachProgramService } from '../contractaggreement/services/outreach-program.service';
import { ClinicdetailsstatusComponent } from './components/clinicdetailsstatus/clinicdetailsstatus.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputMaskModule } from 'primeng/inputmask';
import { ConfirmationService } from 'primeng/api';
import { UniquePipeClinicDetails } from './pipes/clinicUnique.pipe';
import { EquityEventDetailsComponent } from './components/equity-event-details/equity-event-details.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    ReusableModule,
    CalendarModule,
    BrowserAnimationsModule,
    // GrowlModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    DialogModule,
    KeyFilterModule,
    ReusableModule,
    AccordionModule,
    AutoCompleteModule,
    InputMaskModule,
    QRCodeModule,
    NgbModule
  ], 
  declarations: [LocalClinicDetailsComponent, ClinicdetailsstatusComponent,UniquePipeClinicDetails, EquityEventDetailsComponent ],
  providers: [ClinicdetailsService, OutreachProgramService, ConfirmationService]
  })
export class ClinicDetailsModule { }
