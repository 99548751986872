
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SchedulerregistrationService {

  private _baseURL: string = environment.API_URL;  
  public filename: any;

  constructor(private http: HttpClient,private router:Router) { }

  getSchedulerClinicLocations(client_id:number,appt_pk:number) {
    let headers: Headers = new Headers();  
    let url:string = this._baseURL + "/api/getSchedulerClinicLocations/"+client_id+"/"+appt_pk;
    let userData = SessionDetails.GetUserInfo();
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    headers.append('Authorization', 'Basic ' + usernamePassword);
  
    let req_headers = new HttpHeaders()
    .set('Authorization','Basic '+ usernamePassword);
    
    const httpOptions = {
      headers: req_headers
    };

    return this.http.get(url, httpOptions)
    .pipe(catchError(Util.handleErrorV2));
  }

  resendAppointmentConfirmationEmail(email_id:string,client_id:number) {
    let headers: Headers = new Headers();  
    let url:string = this._baseURL + "/api/resendAppointmentConfirmationEmail/"+email_id+"/"+client_id;
    let userData = SessionDetails.GetUserInfo();
    //let token = btoa(userData.userName + ':' + userData.password);
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set('Authorization','Basic '+ usernamePassword);
    
    const httpOptions = {
      headers: req_headers
    };
    return this.http.get(url, httpOptions)
    .pipe(catchError(Util.handleErrorV2));
  }

  getSchedulerApptDetails(appt_pk:number) {
    let headers: Headers = new Headers();  
    let url:string = this._baseURL + "/api/SchedulerEeApptDetails_getEeApptDetails/"+appt_pk;
    let userData = SessionDetails.GetUserInfo();
    //let token = btoa(userData.userName + ':' + userData.password);
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set('Authorization','Basic '+ usernamePassword);
    
    const httpOptions = {
      headers: req_headers
    };
    return this.http.get(url, httpOptions)
    .pipe(catchError(Util.handleErrorV2));
  }

  postSchedulerApptDetails(appt_details:any) {
    let headers: Headers = new Headers();  
    let url:string = this._baseURL + "/api/SchedulerEeApptDetails_postEeApptDetails";
    let userData = SessionDetails.GetUserInfo();
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
  
    let req_headers = new HttpHeaders()
    .set('Authorization','Basic '+ usernamePassword);
    
    const httpOptions = {
      headers: req_headers
    };
    return this.http.post(url,appt_details, httpOptions).pipe(catchError(Util.handleErrorV2));
      //.map(response => response.json())
      //.catch(this.handleError);
  }

  cancelRegisteredAppt(cancelled_list:any,action_type:number) {
    let headers: Headers = new Headers();  
    let url:string = this._baseURL + "/api/SchedulerEeApptDetails_postEeCancelled/"+action_type;
    let userData = SessionDetails.GetUserInfo();
    //let token = btoa(userData.userName + ':' + userData.password);
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set('Authorization','Basic '+ usernamePassword);
    
    const httpOptions = {
      headers: req_headers
    };
    return this.http.post(url,cancelled_list, httpOptions).pipe(catchError(Util.handleErrorV2));;
      //.map(response => response.json())
      //.catch(this.handleError);
  }

  getSchedulerSiteDesign(client_id:number) {
    let headers: Headers = new Headers();  
    let url:string = this._baseURL + environment.RESOURCES.GET_SCHEDULER_SITE_DESIGN +"/"+client_id;
    let userData = SessionDetails.GetUserInfo();
    let token = window.btoa(userData.userName + ':' + userData.password);
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set('Authorization','Basic '+ usernamePassword);
    
    const httpOptions = {
      headers: req_headers
    };
    return this.http.get(url, httpOptions)
    .pipe(catchError(Util.handleErrorV2));;
  }

  public getVarFormPDF(appt_pk:number) {
    let headers: Headers = new Headers();
    let userData= SessionDetails.GetUserInfo()
    //let token =  btoa(userData.userName+':'+userData.password);
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set("Content-Type","application/json")
    .set('Authorization','Basic '+ usernamePassword);
    
    const httpOptions = {
      headers: req_headers
    };
    let url = this._baseURL + "/api/SchedulerEeApptDetails_getEeApptPdf/" + appt_pk;
    return this.http.get(url, httpOptions).pipe(catchError(Util.handleErrorV2));;
    //.map(res => {
      //var contentDisposition = res.headers.get('Content-Disposition');
      //var result = contentDisposition.split(';')[1].trim().split('=')[1];
      //this.filename = result;
//      return res.blob()
    //});
  }


  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    //console.warn(errMsg);
    return observableThrowError(errMsg);
  }

}
