import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash'; 

@Pipe({
  name: 'CustomUniquePipe',
  pure: false
})

export class CustomUniquePipe implements PipeTransform {
    transform(value: any): any{ 
        let index = 0;
        value.forEach(element => {
            element.addControl('indexVal', new FormControl('', null));            
            element.patchValue({
                indexVal: index 
              });
              index = index + 1;
        });      
        
        if(value!== undefined && value!== null){
           
            return _.uniqBy(value, 'value.immunizationPk');
        }
        return value;
    }
}

