   export class FollowUpDetails {
        outreachPk: number;
        contactLogPk: number;
        outreachEffortCode: string;
        followupDate: string;
        emailTo: string;
        emailReminder: boolean;
        updatedBy: number;
        updatedDate: Date;
    }

    export class FollowUpDetailsList {
        followUpDetailsList: FollowUpDetails[];
        constructor()
        {
            this.followUpDetailsList = [];
        }
        
    }


