
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GroupIdAssignmentReportService {
  private _baseURL: string  = environment.API_URL; 
  private _url: string = this._baseURL;
  private cURL;
  constructor(private http: HttpClient) { }
  public getGroupidassignmentreportData(from_date: string, to_date: string,filter_by_date:boolean,is_current_season:number,ip_season:number,covidOnly:boolean): Observable<any> {    
    this.cURL = this._url + "/api/JsGroupIdAssignments_get/" + from_date.replace(/\//g,"-") + "/" + to_date.replace(/\//g,"-") + "/"+ filter_by_date +"/"+ is_current_season + "/" + ip_season+ "/" + covidOnly;
    return this.http.get(this.cURL,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  private handleError(error: Response) {
    return observableThrowError("Error in Service:" + error);
  }
  public exportReportData(from_date: string, to_date: string,filter_by_date:boolean, is_current_season:number,ip_season:number, report_name: string, output_type: string, covidOnly: boolean) {
    let headers: Headers = new Headers();
    let userData= SessionDetails.GetUserInfo()
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    let url = this._url + "/api/JsGroupIdAssignments_downloadReport/" + from_date.replace(/\//g,"-") + "/" + to_date.replace(/\//g,"-") + "/"+ filter_by_date + "/" + is_current_season + "/" + ip_season + "/" + report_name + "/" + output_type + "/" + covidOnly ;
    return this.http.get(url, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
    .pipe(catchError(Util.handleErrorV2));
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });

  }
}