import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from '../admin/components/user-profile/user-profile.component';
import { EditUserComponent } from '../admin/components/user-profile/edit-user/edit-user.component';
import { AddUserComponent } from '../admin/components/user-profile/add-user/add-user.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserProfileService } from '../admin/services/user-profile.service';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReusableModule } from '../common/components/reusable.module';
import { StoreModule } from '../store/store.module';
import { CorporateSchedulerComponent } from './components/corporate-scheduler/corporate-scheduler.component';
import { TableModule } from 'primeng/table';
import { CorporateSchedulerPreviewComponent } from './components/corporate-scheduler-preview/corporate-scheduler-preview.component';
import { WalgreensSchedulerRegistrationComponent } from './components/walgreens-scheduler-registration/walgreens-scheduler-registration.component';
import { CorporateuploaderstatusComponent } from './components/corporateuploaderstatus/corporateuploaderstatus.component';
import { CorporateuploaderComponent } from './components/corporateuploader/corporateuploader.component';
import { CorporateuploaderService } from './services/corporateuploader.service';
import { WalgreensSchedulerConfirmationComponent } from './components/walgreens-scheduler-confirmation/walgreens-scheduler-confirmation.component';
import { SchedulerLandingComponent } from './components/scheduler-landing/scheduler-landing.component';
import { PreviewService } from './services/preview.service';
import { ClientSchedulerApptTrackerComponent } from './components/client-scheduler-appt-tracker/client-scheduler-appt-tracker.component';
import { SchedulerregistrationService } from './services/schedulerregistration.service';
import {CheckboxModule} from 'primeng/checkbox';
import { CorporateStoreApptTrackerComponent } from './components/corporate-store-appt-tracker/corporate-store-appt-tracker.component';
import { StoreprofileuploaderstatusComponent } from './components/storeUploader/storeprofileuploaderstatus/storeprofileuploaderstatus.component';
import { StoreprofileuploaderComponent } from './components/storeUploader/storeprofileuploader/storeprofileuploader.component';
import { StoreprofilereportandupdateComponent } from './components/storeUploader/storeprofilereportandupdate/storeprofilereportandupdate.component';
import { UploadstoreprofilesComponent } from './components/storeUploader/uploadstoreprofiles/uploadstoreprofiles.component';
import { StoreProfileUploadCompleteComponent } from './components/storeUploader/store-profile-upload-complete/store-profile-upload-complete.component';
import { UpdatestoreprofileComponent } from './components/storeUploader/updatestoreprofile/updatestoreprofile.component';
import { ImmunizationCheckComponent } from './components/immunization-check/immunization-check.component';
import { AddEditImmunizationCheckComponent } from './components/immunization-check/add-edit-immunization-check/add-edit-immunization-check.component';
import { DroppedStoreReassignmentsComponent } from './components/dropped-store-reassignments/dropped-store-reassignments.component';
import { GroupidsuploaderComponent } from './components/groupidsuploader/groupidsuploader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PickListModule } from 'primeng/picklist';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
import { TooltipModule } from 'primeng/tooltip';
import { ColorPickerModule } from 'primeng/colorpicker';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PatientReassignmentComponent } from './components/patient-reassignment/patient-reassignment.component';

@NgModule({
    imports: [
        CommonModule,
        DialogModule,
        NgbModule,
        ConfirmDialogModule,
        FormsModule,
        AutoCompleteModule,
        RouterModule,
        ReusableModule,
        StoreModule,
        AccordionModule,
        PaginatorModule,
        CalendarModule,
        DialogModule,
        CheckboxModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        TabViewModule,
        TableModule,
        ColorPickerModule,
        TooltipModule,
        ListboxModule,
        MultiSelectModule,
        PickListModule, 
        NgxSpinnerModule
    ],
    declarations: [
        UserProfileComponent, 
        CorporateuploaderComponent, 
        CorporateuploaderstatusComponent,
        EditUserComponent, 
        AddUserComponent, 
        CorporateSchedulerComponent, 
        CorporateSchedulerPreviewComponent,
        WalgreensSchedulerRegistrationComponent,
        WalgreensSchedulerConfirmationComponent, 
        SchedulerLandingComponent,
        ClientSchedulerApptTrackerComponent, 
        CorporateStoreApptTrackerComponent, 
        StoreprofileuploaderstatusComponent,
        StoreprofileuploaderComponent, 
        StoreprofilereportandupdateComponent, 
        UploadstoreprofilesComponent, 
        StoreProfileUploadCompleteComponent, 
        UpdatestoreprofileComponent,
        ImmunizationCheckComponent, 
        AddEditImmunizationCheckComponent, 
        DroppedStoreReassignmentsComponent, 
        GroupidsuploaderComponent, 
        PatientReassignmentComponent
    ],
    providers: [
        UserProfileService, 
        CorporateuploaderService, 
        PreviewService,
        SchedulerregistrationService, 
        { provide: 'Window', useValue: window }
    ]
})
export class AdminModule { }
