// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-msg {
    color: red;
  }
   .has-error label{
    color:red!important;
    }
    .has-error input{
    border:1px solid red !important;
    } 
    .has-error select{
    border:1px solid red !important;
    }      p-dataTable >>>  ui-dataTable-header{
        min-height: 60px;
    }`, "",{"version":3,"sources":["webpack://./src/app/modules/admin/components/corporateuploader/corporateuploader.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;EACZ;GACC;IACC,mBAAmB;IACnB;IACA;IACA,+BAA+B;IAC/B;IACA;IACA,+BAA+B;IAC/B,OAAO;QACH,gBAAgB;IACpB","sourcesContent":[".error-msg {\r\n    color: red;\r\n  }\r\n   .has-error label{\r\n    color:red!important;\r\n    }\r\n    .has-error input{\r\n    border:1px solid red !important;\r\n    } \r\n    .has-error select{\r\n    border:1px solid red !important;\r\n    }      p-dataTable >>>  ui-dataTable-header{\r\n        min-height: 60px;\r\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
