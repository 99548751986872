import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import * as _ from "lodash";
import { Util } from '../../../../utility/util';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { LeadOpportunitiesService } from '../../../contractaggreement/services/lead-opportunities.service';
import { recaptchaRequest } from '../../../../models/localLeadsSave';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { stateCodenName } from '../../../contractaggreement/components/contact-us/stateCodenName';
import { NgxSpinnerService } from "ngx-spinner";
import { SelectType } from '../../../../models/OutreachOpportunity';
import { LookupService } from '../../../../lookup.service';
import { LtcfContactUs } from '../../../../models/LtcfLocations';

@Component({
  selector: 'app-ltcf-contact-us',
  templateUrl: './ltcf-contact-us.component.html',
  styleUrls: ['./ltcf-contact-us.component.css']
})
export class LtcfContactUsComponent implements OnInit {

  routerSubscription: Subscription;
  leadGenerationForm: FormGroup;
  statecodenname: any;
  dialogMsg: string = '';
  dialogSummary: string = '';
  locListModalDlg: boolean = false;
  successDlg1: boolean = false;
  vacn: any = [];
  vacn2: any=[];
  paymentType: any = [];
  showOtherVaccinationInputField: boolean = false;
  showOtherVaccinationListInputField: boolean =false;
  parentCompanyOptions = [{ 'id': 'None', 'name': 'None' }, { 'id': 'Enlivant', 'name': 'Enlivant' }, { 'id': 'Ascension', 'name': 'Ascension' }, { 'id': 'Other', 'name': 'Other' }]

  selectedvcInfo: [string];

  facilityTypesCheckBoxes: any[] = [
    { "facilityTypeVal": 1, "facilityTypeName": "Skilled Nursing Facility (SNF)" },
    { "facilityTypeVal": 2, "facilityTypeName": "Assisted Living Facility (ALF)" },
    { "facilityTypeVal": 3, "facilityTypeName": "Residential Facility (RES)" },
    { "facilityTypeVal": 4, "facilityTypeName": "Intellectual and Developmental Disability (IDD)" },
    { "facilityTypeVal": 5, "facilityTypeName": "Housing and Urban Development (HUD)" },
    //{ "facilityTypeVal": 6, "facilityTypeName": "Continuing Care Retirement Community (CCRC)" },
    //{ "facilityTypeVal": 7, "facilityTypeName": "Independent Living (IND)" }
    { "facilityTypeVal": 8, "facilityTypeName": "State Veterans Home (VET)" }
  ]

   VaccineTypeCheckBox: any[] =[
    { "vaccineTypeVal": 2, "vaccineTypeName": "Flu" },
    { "vaccineTypeVal": 1, "vaccineTypeName": "COVID19" },
    { "vaccineTypeVal": 3, "vaccineTypeName": "Pneumonia" },
    { "vaccineTypeVal": 4, "vaccineTypeName": "Shingles" },
    { "vaccineTypeVal": 8, "vaccineTypeName": "RSV" },
    { "vaccineTypeVal": 6, "vaccineTypeName": "Other" }
   ];

  paymentTypeCheckBoxes: any[] = [
    //  { "paymentTypeValue": 2, "paymentTypeName": "Insurance", "paymentMessage": "Walgreens can bill patient’s insurance directly." },
    // { "paymentTypeValue": 1, "paymentTypeName": "Cash", "paymentMessage": "Payment for the vaccine can be collected from the patient receiving the immunization at the time of vaccination by cash or via check from the employer/location." },
    //{ "paymentTypeValue": 3, "paymentTypeName": "Direct Bill", "paymentMessage": "Employer/Location will pay for employee immunizations. They will receive an invoice directly from Walgreens for the number of immunizations provided. No payment will be collected at the time of the immunization." },
  ]
  showOtherPaymentOptionTxtField: boolean = false;
  redirect: boolean = false;
  minDateValue: Date;
  fNlNameRegEx: RegExp = /^(?!\d+$)(?:[a-zA-Z \.\,\'\-]*)?$/;
  cityRegEx: RegExp = /^(?!\d+$)(?:[a-zA-Z \-]*)?$/;
  address1N2: RegExp = /^[ A-Za-z0-9#'.,-/]*$/

  maxDateValue: Date;
  isRecaptchaVerified: boolean = true;
  selectedIndustryText: any;
  industries: SelectType[] = [];
  otherIndustryIndex: number;

  constructor(
    private formBuilder: FormBuilder, 
    private utility: Util, 
    private leadService: LeadOpportunitiesService, 
    private spinner: NgxSpinnerService, 
    public router: Router,
    private _lookupService: LookupService
  ) {
  }

  ngOnInit() {
    this.leadGenerationForm = this.formBuilder.group({
      // lgCntrlsetatSluMcoPBstrCl086: ['', Validators.required],
      lgCntrlynapmoc784: ['', Validators.required],
      // lgCntrlspmEfOoNlatot935: [0, [Validators.required, Validators.maxLength(5)]],
      lgCntrlofnIsnoitaniccav193: this.createvaccinationsInfo(this.facilityTypesCheckBoxes),
      lgCntrlynapmoc884: [''],
      lgCntrletaDcinilc137: [''],
      lgCntrlstnapicitraPfOrebmuNdetamitse432: ['', [Validators.required, Validators.maxLength(5)]],
      lgCntrlstnapicitraPfOrebmuNdetamitse433: ['', [Validators.required, Validators.maxLength(5)]],
      // lgCntrlsetatSluMcoLpme035: ['', Validators.required],
      // lgCntrlsetatSluMcoLpme036: ['', Validators.required],
      lgCntrlepyTtnemyap294: [null, null],//this.createPaymentType(this.paymentTypeCheckBoxes),
      lgCntrlepyTtnemyap111:   this.createVaccineType(this.VaccineTypeCheckBox),
      lgCntrlmgrPhcruoVsi530: ['', null],
      recaptchaReactive: ['default', Validators.required],
      vaccinationArray: [null],
      paymentTypeArray: [null, null],
      VaccineTypeCheckBoxArray: [null, null],
      isCovid: [true],
      covidOpportunityType: [1],
      lgCntrlynapmoc3284:[''],
      lgCntrlAlternateFN: ['', [Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]],
      lgCntrlAlternateLN: ['', [ Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]],      
      lgCntrlAlternateJobTitle: [''],
      lgCntrlAlternateEmail: ['', [Validators.email, Validators.pattern(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/)]],      
      lgCntrlDesiredClinicCompDate:[''],
      lgCntrlNeedClinic:['',Validators.required],
      hadClinicInPast: ['', Validators.required],
      lgCntrlOtherIndustry: [''],
      lgCntrlIndustry: ['', Validators.required],
      lgCntrlAltPhoneNumber:[''],
      lgCntrlAltPhoneNumberExt:[''],
      lgCntrlpharmacyPrvider:['',Validators.required],
      locations: this.formBuilder.array([]),
    });

  
    setTimeout(() => {
      this.resetONBackBtn();
    }, 100);

    this.addAnotherLocation(undefined);

    this.statecodenname = stateCodenName;
    {
      this.minDateValue = new Date();
      this.maxDateValue = new Date(this.minDateValue.getFullYear() + 1, this.minDateValue.getMonth(), this.minDateValue.getDate() - 1);
    }

    this._lookupService.getLookUpValues('Industry', 'LTCFOpportunity')
      .subscribe((response: SelectType[]) => {
        this.industries = response;
        this.otherIndustryIndex = this.industries?.find(industry => industry?.value?.toLocaleLowerCase() === 'other')?.id;
      });
  }

  createvaccinationsInfo(vaccinationsInfoInputs) {
    const arr = vaccinationsInfoInputs.map(vcVals => {
      return new FormControl(null);
    });
    return new FormArray(arr);
  }
  createPaymentType(paymentInp) {
    const arr = paymentInp.map(vcVals => {
      return new FormControl(null);
    });
    return new FormArray(arr);
  }
  createVaccineType(paymentInp) {
    const arr = paymentInp.map(vcVals => {
      return new FormControl(null);
    });
    return new FormArray(arr);
  }

  getSelectedPaymentTypeInfo(ev, val) {
    this.paymentField('');
    if (ev.target.checked) {
      this.paymentType.push(val);
    } else {
      let i = this.vacn.indexOf(val);
      this.paymentType.splice(i, 1);
    }
  }

  getSelectedIndustry(ev) {
    this.selectedIndustryText =  ev.target.options[ev.target.options.selectedIndex].text;
    const otherIndustryIndex = this.industries?.find(industry => industry?.value?.toLocaleLowerCase() === 'other')?.id;
    const industry = this.leadGenerationForm.controls['lgCntrlIndustry']?.value;
    
    if (industry == otherIndustryIndex) {
      this.leadGenerationForm.controls.lgCntrlOtherIndustry.setValidators(Validators.required);
      this.leadGenerationForm.controls.lgCntrlOtherIndustry.updateValueAndValidity();
      this.showOtherVaccinationInputField = true;
      document.getElementById('lgCntrlOtherIndustry').classList.remove('has-error');
      document.getElementById('lgCntrlOtherIndustry').classList.remove('has-feedback');
    }
    else {
      this.leadGenerationForm.controls.lgCntrlOtherIndustry.clearValidators();
      this.leadGenerationForm.controls.lgCntrlOtherIndustry.updateValueAndValidity();
      document.getElementById('lgCntrlOtherIndustry').classList.remove('has-error');
      document.getElementById('lgCntrlOtherIndustry').classList.remove('has-feedback');
    }

  }
  
  onParentOptionChanged(ev) {
    if (ev === 'Other') {
      this.showOtherPaymentOptionTxtField = true;
      this.leadGenerationForm.controls.lgCntrlynapmoc884.setValidators(Validators.required);
      this.leadGenerationForm.controls.lgCntrlynapmoc884.updateValueAndValidity();
    }
    else {
      this.showOtherPaymentOptionTxtField = false;
      this.leadGenerationForm.controls.lgCntrlynapmoc884.clearValidators();
      this.leadGenerationForm.controls.lgCntrlynapmoc884.updateValueAndValidity();

    }
  }
   
  getSelectedvaccinationsInfo(ev, val) {
    
    this.seperateFiedls('');
    if (ev.target.checked) {
      this.vacn.push(val);
      if (val == 6) {
        //this.showOtherVaccinenameTxtField = true;
        this.leadGenerationForm.controls.lgCntrlynapmoc884.setValidators(Validators.required);
        this.leadGenerationForm.controls.lgCntrlynapmoc884.updateValueAndValidity();

      }
    } else {
      let i = this.vacn.indexOf(val);
      this.vacn.splice(i, 1);
      this.leadGenerationForm.controls.lgCntrlynapmoc884.setValidators(null);
      this.leadGenerationForm.controls.lgCntrlynapmoc884.updateValueAndValidity();
      // this.showOtherVaccinenameTxtField = false;
    }
  }

  getSelectedvaccinationsList(ev, val) {
    
    this.seperateFiedls2('');
    if (ev.target.checked) {
      this.vacn2.push(val);
      if (val == 'Other') {
         this.showOtherVaccinationListInputField = true;
        this.leadGenerationForm.controls.lgCntrlynapmoc3284.setValidators(Validators.required);
        this.leadGenerationForm.controls.lgCntrlynapmoc3284.updateValueAndValidity();
      }
      else if(val == 'Other' && !ev.target.checked)
      {
        this.leadGenerationForm.controls.lgCntrlynapmoc3284.setValidators(null);
        this.leadGenerationForm.controls.lgCntrlynapmoc3284.updateValueAndValidity();
          this.showOtherVaccinationListInputField = false;
      }
    } else {
      let i = this.vacn2.indexOf(val);
      this.vacn2.splice(i, 1);
      this.leadGenerationForm.controls.lgCntrlynapmoc3284.setValidators(null);
      this.leadGenerationForm.controls.lgCntrlynapmoc3284.updateValueAndValidity();
      if (val == 'Other')
      {
        this.showOtherVaccinationListInputField = false;
      }
    }
  }

  getSelectedvaccinationsInfoName() {
    this.selectedvcInfo = _.filter(
      this.selectedvcInfo,
      function (vcVals) {
        if (vcVals !== false) {
          return vcVals;
        }
      }
    );
  }

  resolved(captchaResponse: string) {
    this.isRecaptchaVerified = true;
    if (captchaResponse) {
      let verify = new recaptchaRequest();
      verify.response = captchaResponse;
      this.leadService.siteVerify(verify).subscribe((data:any) => {
        let rsp = data;
        if (rsp.success == true) {
          this.isRecaptchaVerified = true;
          this.leadGenerationForm.controls.recaptchaReactive.setValue(rsp.success);
          this.leadGenerationForm.controls.recaptchaReactive.updateValueAndValidity();
        }
        else {
          this.showDialogV3("Error", "Recaptcha Verification Failed. Please retry.");
        }
      },
        err => {
          this.showDialogV3("Error", "Expectaion Failed");
        })
    }
  }

  onSubmit(formValues) {
    
    this.trimFormValues(formValues);
    if (this.leadGenerationForm.valid && !(this.leadGenerationForm.controls['lgCntrlIndustry'].value == '4' && this.leadGenerationForm.controls['lgCntrlOtherIndustry'].value == '' )) {
      this.leadGenerationForm.controls['vaccinationArray'].patchValue(this.vacn);
      this.leadGenerationForm.controls['paymentTypeArray'].patchValue(this.paymentType);

      this.leadGenerationForm.controls['VaccineTypeCheckBoxArray'].patchValue(this.vacn2);
      this.redirect = false;
      if (this.leadGenerationForm.controls['recaptchaReactive'].value == '' || !this.isRecaptchaVerified || this.leadGenerationForm.controls['recaptchaReactive'].value == null) {
        this.showDialogV3("Error", "Please verify that you are not a robot.");
        return false;
      }

      
      // const SumofEstimates = Number(this.leadGenerationForm.get('lgCntrlstnapicitraPfOrebmuNdetamitse432').value) + Number(this.leadGenerationForm.get('lgCntrlstnapicitraPfOrebmuNdetamitse433').value);
      // if (SumofEstimates < 15) {
      //   window.scroll(40,40);
      //   this.showDialogV3("Minimum Requirement", 'Thank you for your interest in Walgreens to support your LTCF COVID-19 Vaccine Booster Clinic. Unfortunately, due to overwhelming demand we are currently unable to service facilities requesting support for fewer than 15 participants. In-store COVID-19 Vaccine appointments for long-term care residents or eligible staff can be scheduled through the <a class="blueAnchor1" href="https://www.walgreens.com/findcare/vaccination/covid/19/landing?vaccineType=covid?ban=R122_covidboosters_header_scheduler"target="_blank">Walgreens online scheduler</a>. For online scheduler support, contact our Walgreens support team at 1-866-614-7029. For additional information on long-term care booster access, please visit the <a class="blueAnchor1" href="https://www.cdc.gov/vaccines/covid-19/long-term-care/pharmacy-partnerships/administrators-managers.html" target="_blank">CDC website</a>.')
      //   return false;
      // }

      // if(this.leadGenerationForm.controls['lgCntrlstnapicitraPfOrebmuNdetamitse432'].value<25 ){
      //   this.showDialogV3("Minimum Requirement", 'There is a minimum of 25 participates required to use this form. Please <a class="blueAnchor1" href="https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.walgreens.com%2Fstorelocator%2Ffind.jsp%3Ftab%3Dstore%2Blocator%26requestType%3Dlocator&data=02%7C01%7Ccsmith%40tribpub.com%7C0d84887a116b49829f7b08d84aa0ff14%7Cf7da0482aed242fa80233b240fb6598d%7C0%7C0%7C637341402632750223&sdata=STcZEKlnk7OfRG1mL7EN2PaexM1KlxsHumvIL%2Fow8Ag%3D&reserved=0" target="_blank">contact</a> your local Walgreens for alternative options.');
      //   window.scroll(40,40);
      //   return false;
      // }
            
      this.leadGenerationForm.value.lgCntrlIndustry = this.selectedIndustryText;     

      let sumOfLtcfEligibleResidentsandStaff = (+this.leadGenerationForm.controls['lgCntrlstnapicitraPfOrebmuNdetamitse432'].value) + (+this.leadGenerationForm.controls['lgCntrlstnapicitraPfOrebmuNdetamitse433'].value);
                                              
      if(sumOfLtcfEligibleResidentsandStaff <50 && 
      this.leadGenerationForm.controls['lgCntrlNeedClinic'].value != 'true'){
        this.showDialogV3("Minimum Requirement", 'There is a minimum of 50 participants required to request an on-site clinic. Please contact your local Walgreens for alternative options or select "Walgreens Store" when filling out the form.');
        window.scroll(40,40);
        return false;
      }
      this.spinner.show();
      this.leadService.saveLeadOpportunitesEnquiries(this.leadGenerationForm.value).subscribe((data:any) => {
        if (data == 1) {
          this.redirect = true;
          this.spinner.hide();
          this.showDialogV3("Thank you!", "Your information has been submitted. Our team will be in touch in the future, once we have additional information to provide.");
        }
        else if (data == -1) {
          this.spinner.hide();
          this.showDialogV3("Error", "Bad Geocode -- Invalid Request.");
        }
        else if (data == -2) {
          this.spinner.hide();
          this.showDialogV3("Error", "Bad Geocode -- Over Query Limit.");
        }
        else if (data == -3) {
          this.spinner.hide();
          this.showDialogV3("Error", "Bad Geocode -- Request Denied.");
        }
        else if (data == -4) {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages['geo_zero_results']);
        }
        else if (data == -5) {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages['geo_unknown_error']);
        }
        else if (data == -6) {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages['geo_timed_out']);
        }
        else {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages["error"]);
        }
      }, err => {
        this.spinner.hide();
        this.showDialogV3("Error", "Expectation failed.");
      });
    }
    else {
      this.spinner.hide();
      this.validateFormNSendAlert();
      this.validateNResetCaptcha();
      // if (this.leadGenerationForm.value.lgCntrlsetatSluMcoPBstrCl086 === "false") {
      //   document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-error');
      //   document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-feedback');
      // }
    }
  }

  isFieldValid(field: string) {    
      return !this.leadGenerationForm.get(field).valid && this.leadGenerationForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  seperateFiedls(e) {
    if (e == '') {
      if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193) {
        if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193.includes(true)) {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-feedback');
        }
      }
    } else {
      if (e.toElement.checked && e.toElement.checked == true && this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193) {
        if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193.includes(true) || e.toElement.checked == true) {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-feedback');
        }
      }
    }
  }

  seperateFiedls2(e) {
    if (e == '') {
      if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193) {
        if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193.includes(true)) {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-feedback');
        }
      }
    } else {
      if (e.toElement.checked && e.toElement.checked == true && this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193) {
        if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193.includes(true) || e.toElement.checked == true) {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-feedback');
        }
      }
    }
  }

  paymentField(e) {
    if (e == '') {
      if (this.leadGenerationForm.value.lgCntrlepyTtnemyap294) {
        if (this.leadGenerationForm.value.lgCntrlepyTtnemyap294.includes(true)) {
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-feedback');
        }
      }
    } else {
      if (e.toElement.checked && e.toElement.checked == true && this.leadGenerationForm.value.lgCntrlepyTtnemyap294) {
        if (this.leadGenerationForm.value.lgCntrlepyTtnemyap294.includes(true) || e.toElement.checked == true) {
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-feedback');
        }
      }
    }
  }

  showDialogV2(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.locListModalDlg = true;
  }
  showDialogV3(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.successDlg1 = true;
  }
  okClickedV2() {
    this.locListModalDlg = false;
  }
  goToWagsOnClose() {
    this.successDlg1 = false;
    if (this.redirect) {
      window.location.href = 'http://walgreens.com';
    }
  }
  public trimFormValues(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      if (key != "lgCntrlofnIsnoitaniccav193" && key != "lgCntrlepyTtnemyap294" 
      && key != "lgCntrlDesiredClinicCompDate" && key != "recaptchaReactive" 
      && key != "vaccinationArray" && key != "paymentTypeArray"  && key != "lgCntrlspmEfOoNlatot936"
      && key!= "locations" && key != "isCovid" && key !="covidOpportunityType"
      && key != "lgCntrlepyTtnemyap111" && key !="VaccineTypeCheckBoxArray") {
        formGroup.get(key)?.setValue(formGroup?.get(key).value?.toString()?.trim())
      }
    });
  }
 
  validateFormNSendAlert() {
    this.utility.validateAllFormFields(this.leadGenerationForm);
    this.seperateFiedls('');
    this.paymentField('');
    this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['requiredFields']);
    return false;
  }
  onlyAlphabets(e, ctrl) {
    if (ctrl == "fNlName") {
      return this.fNlNameRegEx.test(e.key);
    } else if (ctrl == "city") {
      return this.cityRegEx.test(e.key);
    }
    else if (ctrl == "add1N2") {
      return this.address1N2.test(e.key);
    }
  }
  validateNResetCaptcha() {
    this.leadGenerationForm.controls.recaptchaReactive.setValue('default');
    this.leadGenerationForm.controls.recaptchaReactive.updateValueAndValidity();
  }
  resetONBackBtn() {
    this.leadGenerationForm.controls.lgCntrlrebmuNhp802.setValue('');
    this.leadGenerationForm.controls.lgCntrlrebmuNhp802.updateValueAndValidity();
    this.leadGenerationForm.controls.lgCntrletats936.setValue('');
    this.leadGenerationForm.controls.lgCntrletats936.updateValueAndValidity();
  }
  NavigateToImmContactUs()
  {
    this.router.navigateByUrl("/Immunization-Contact-Us");
  }

  displayVaccineClass(vaccineName, index) {
    if(vaccineName == 'Other' && (index%2) > 0) {
      return "col-md-2 chkBox off offset-sm-1"
    } else if ((index%2) > 0) {
      return "col-md-4 chkBox off offset-sm-1"
    } else {
      return "col-md-4 chkBox"
    }
  }
 
  initLocation(location : LtcfContactUs) : FormGroup{
   if(location){
    return this.formBuilder.group({
      lgCntrl1sserddA594: new FormControl(location.lgCntrl1sserddA594, [Validators.required, Validators.maxLength(40), Validators.pattern(/^[ A-Za-z0-9#'.,-/]*$/)]),
      lgCntrlFacilityAddrTwo: new FormControl(location.lgCntrlFacilityAddrTwo ,[Validators.maxLength(60), Validators.pattern(/^[ A-Za-z0-9#'.,-/]*$/)]),
      lgCntrlytic105: new FormControl(location.lgCntrlytic105, [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \-$]*)?$/)]),
      lgCntrletats936: new FormControl(location.lgCntrletats936,[ Validators.required]),
      lgCntrlpiz466: new FormControl(location.lgCntrlpiz466,[ Validators.required]),
      lgCntrlemaNf255: new FormControl(location.lgCntrlemaNf255,[Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]),
      lgCntrlemaNl400: new FormControl(location.lgCntrlemaNl400,[Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]),
      lgCntrlJobTitle: new FormControl(location.lgCntrlJobTitle),
      lgCntrlliame450: new FormControl(location.lgCntrlliame450,[Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/)]),
      lgCntrlrebmuNhp802: new FormControl(location.lgCntrlrebmuNhp802,[Validators.required]),
      lgCntrlnoisnetxe491: new FormControl(location.lgCntrlnoisnetxe491),
    })
   }
   else{
    return this.formBuilder.group({
      lgCntrlemaNf255: new FormControl('', [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]),
      lgCntrlliame450: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/)]),
      lgCntrlemaNl400: new FormControl('', [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]), 
      lgCntrlJobTitle: new FormControl(''),
      lgCntrl1sserddA594: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.pattern(/^[ A-Za-z0-9#'.,-/]*$/)]), 
      lgCntrlFacilityAddrTwo: new FormControl('', [ Validators.maxLength(60), Validators.pattern(/^[ A-Za-z0-9#'.,-/]*$/)]), 
      lgCntrlytic105: new FormControl('', [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \-$]*)?$/)]),
      lgCntrletats936: new FormControl('', Validators.required), 
      lgCntrlpiz466: new FormControl('', Validators.required), 
      lgCntrlrebmuNhp802: new FormControl('', Validators.required), 
      lgCntrlnoisnetxe491: new FormControl(''), 
  });
   }
   

  }
  addAnotherLocation(location: LtcfContactUs){
    if(location){
      const control = <FormArray>this.leadGenerationForm.controls['locations'];
      const addrCtrl = this.initLocation(location);
      control.push(addrCtrl);
    }
    else{
      const control = <FormArray>this.leadGenerationForm.controls['locations'];
      const addrCtrl = this.initLocation(undefined);
      control.push(addrCtrl);
    }
  }

  canDeleteTheClinic(clinicIndex: number) {
    if (this.leadGenerationForm.controls.locations.value.length > 1 && clinicIndex != 0) {
        return true;
    }
  }

  removeLocation(i: number) {
    let control = <FormArray>this.leadGenerationForm.controls['locations'];
    control.removeAt(i);
}
}
