import { Component, OnInit } from '@angular/core';
import {  Router, NavigationEnd } from '@angular/router';
import { SessionDetails } from '../../../../utility/session';
import { Title } from '@angular/platform-browser';
import { Util } from '../../../../utility/util';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { String } from 'typescript-string-operations';
@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {
  storeState: string = '';
  hasPrefilledForms: boolean = false;
  pdfVarFormEn: string = '';
  pdfVarFormEsp: string = '';
  voucherNeeded: boolean = false;
  isRestrictedState: boolean = false;
  corporateInvoice: boolean = false;
  cashPaid: boolean = false;
  submitInsurance: boolean = false;
  isCovidClinic:boolean = false;
  medicalInsurance: boolean = false;
  pharmacyInsurance: boolean = false;
  isShowPDF: boolean = false;
  header_title:string='';
  messageData: any;
  Message1: string = '';
  Message2: string = '';
  Message3: string = '';
  thankyou:boolean=false;
  torgthankyou:boolean=false;
  rhdthankyou:boolean=false;
  torgthankyouReject:boolean = false;
  iconImage:boolean=false;
  contractThankYouInfo=null;

  approveRejectCancellation:boolean = false;
  approveRejectCancellationText:string = "";
  isCovid: boolean;
  txtStandard: string = 'Next Steps';
  constructor(router:Router,titleService:Title, private utility: Util) {
    router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        let title = this.getTitle(router.routerState, router.routerState.root).join();
       titleService.setTitle(title);
       if(title=="rhdthankyou" || title=="Walgreens Community Outreach Thank You" || title=="torgthankyouReject"){
         this.iconImage=true;
       }
       if(title=="Walgreens Community Outreach")
       this.thankyou = true;
         else if(title=="Walgreens Community Outreach Thank You")
       sessionStorage["Reject"]=='null'?this.torgthankyou = true:this.torgthankyouReject=true;
      }
    });
  }
  ngOnInit() {
    let thankuData = this.contractThankYouInfo = SessionDetails.GetThankUDetails();
    let approveRejectCancellation =  sessionStorage["CancellationToken"];
    this.hasPrefilledForms = thankuData.hasPrefilledForms;
    this.storeState = SessionDetails.GetState();
    if(this.storeState == null){
    this.storeState = thankuData.storeState;
    }
    if(this.storeState&& this.storeState.length<=0){
      this.storeState = SessionDetails.GetState();      
    }
    this.pdfVarFormEn = thankuData.covidOpportunityType == 1 ? "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/CP LTCF Patient Registration Guide.pdf" : thankuData.pdfVarFormEn;
    this.pdfVarFormEsp =  thankuData.covidOpportunityType == 1 ? "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/LTCF Admin Patient Insurance Consent and Assessment Guide.pdf" : thankuData.pdfVarFormEsp;
    this.voucherNeeded = thankuData.voucherNeeded;
    this.isRestrictedState = thankuData.isRestrictedState;
    this.corporateInvoice = thankuData.corporateInvoice;
    this.submitInsurance = thankuData.submitInsurance;
    this.isCovidClinic = thankuData.covidOpportunityType == 1 ? true : false;
    this.isCovid = thankuData.isCovidOpportunity;
    // if(thankuData.covidOpportunityType == 2){
    //   this.txtStandard += ' for COVID-19 Clinics';
    // }
    if (this.hasPrefilledForms) {
      this.isShowPDF = true;
    }
   // if (!this.corporateInvoice) {
      //this.Message1 = "Patient registration (including Insurance), consent, and Pre-Vaccination Assessment is required for each participant before receiving an immunization. This will be completed digitally using the Walgreens Digital Clinic Platform.  Starting this process as soon as possible will allow participants to be immunized more efficiently.";
      if(this.isCovidClinic){
        this.Message1 = "Patient registration (including Insurance), consent, and Pre-Vaccination Assessment is required for each participant before receiving an immunization. This will be completed digitally using the Walgreens Digital Clinic Platform.  Starting this process as soon as possible will allow participants to be immunized more efficiently.";
      }
      else {
      this.Message1 = "A completed <b>Vaccine Administration Record (VAR)</b> form will be <b>required</b> for each participant before receiving an Immunization. Making these available prior to the clinic will allow participants to be immunized more efficiently.";
      }
   // }
    if (this.corporateInvoice&&!this.isCovidClinic) {
      this.Message2 = "Walgreens corporate office will directly bill the invoice contact entered on the contract one month after the clinic has been conducted. This will be delivered electronically in a secure email format.";
    }
    if (this.submitInsurance&&!this.isCovidClinic) {
      this.Message3 = "Please ask all participants to provide a photocopy of their insurance card for processing purposes.";
    }
    
    if(approveRejectCancellation!=null && approveRejectCancellation != undefined){
      this.approveRejectCancellation = true;
      this.thankyou = false;
      sessionStorage.removeItem("CancellationToken");
      let wagContactInfo = SessionDetails.getWagsContactInfo();
      switch(Number(approveRejectCancellation)){       
        case 2:
          if(wagContactInfo != undefined && wagContactInfo != null){
          this.approveRejectCancellationText = String.Format(ErrorMessages['approveRejectCancellationText'],wagContactInfo.contactName, wagContactInfo.contactPhone, wagContactInfo.contactEmail);
          }
          else {
            this.approveRejectCancellationText = ErrorMessages['approveRejectCancellationTextNoContactInfo'];
          }
          break;
          default:
            if(wagContactInfo != undefined && wagContactInfo != null){
              this.approveRejectCancellationText = String.Format(ErrorMessages['approveRejectCancellationText'],wagContactInfo.contactName, wagContactInfo.contactPhone, wagContactInfo.contactEmail);
              }
              else {
                this.approveRejectCancellationText = ErrorMessages['approveRejectCancellationTextNoContactInfo'];
              }
            break;
      }
    }
  }

  getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if(state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  print(){    
    let title:string = "Walgreens Community Off-site Agreement";
    if(this.torgthankyou){
      title = "Limited Use License Agreement";
    }
    this.utility.print("approveContract",title ,SessionDetails.GetThankUDetails().printContents);
  }
}
