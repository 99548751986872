import { Component, OnInit,  Inject } from '@angular/core';
import { DOCUMENT } from '../../../../../../node_modules/@angular/common';
import { Router } from '../../../../../../node_modules/@angular/router';
import { SchedulerregistrationService } from '../../services/schedulerregistration.service';
import { SessionDetails } from '../../../../utility/session';
import { defaultSiteDesignInfo } from '../../../../JSON/schedulerDefaultValues';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-scheduler-landing',
  templateUrl: './scheduler-landing.component.html',
  styleUrls: ['./scheduler-landing.component.css']
})
export class SchedulerLandingComponent implements OnInit {
  domain: any;
  protocol: any;
  port: any;
  siteDesignInfo: any = {};
  clientLogo: string = "";
  clientName: string = "";
  clientStatus: boolean = true;
  bodyText: string = "";
  footerText1: string = "";
  showAppointmentButton: boolean = true;
  showBodyText: boolean = true;
  scheduledAppointmentInfo: any = {};
  showResendPopup:boolean = false;
  resendEmail:string ="";
  dialogMsg: string;
  dialogSummary:string ="";
  errorPopUp: boolean = false;
  constructor(private router: Router, @Inject(DOCUMENT) private document: any, private scheduler_regisration: SchedulerregistrationService) {
  }
  ngOnInit() {
    this.scheduledAppointmentInfo = SessionDetails.getSchedulerUserInfo();
    if (!this.scheduledAppointmentInfo) {
      this.scheduledAppointmentInfo = {
        "designPk": -1,
        "redirectTo": "schedulerRegistration",
        "apptPk": -1,
        "clinicPK": -1
      };
      //This condition has to be removed once the above data is available from servive.
      //if (sessionStorage["scheduler_site_set_up"]) {
      //this.scheduledAppointmentInfo.designPk = JSON.parse(sessionStorage["scheduler_site_set_up"]).clientInfo.clientId
      //}
    }
    this.scheduler_regisration.getSchedulerSiteDesign(this.scheduledAppointmentInfo.designPk).subscribe((result:any) => {
      if (Object.keys(result).length > 0) {
        if (!result.clientInfo.enryptedClientKey) {
          result.siteLogoAndStyles = defaultSiteDesignInfo.defaultDesignValues;
          result.siteLandingPageText = defaultSiteDesignInfo.defaultTextValues;
        }
        this.siteDesignInfo = result;

        //this.clientName = this.siteDesignInfo.clientInfo.businessName;
        this.clientLogo = ((this.siteDesignInfo.siteLogoAndStyles && this.siteDesignInfo.siteLogoAndStyles.logoFile &&
          this.siteDesignInfo.siteLogoAndStyles.logoFile !== "string") ? environment.RESOURCES.CORPORATE_SCHEDULER_UPLOADED_LOGOS_URL + this.siteDesignInfo.siteLogoAndStyles.logoFile : '');

        this.clientName = this.siteDesignInfo.siteLandingPageText.headerText;
        this.clientStatus = this.siteDesignInfo.clientInfo.siteStatus;
        this.bodyText = this.htmlDecode(this.siteDesignInfo.siteLandingPageText.bodyText);
        if (!this.siteDesignInfo.clientInfo.siteStatus || (this.siteDesignInfo.clientInfo.siteStatus && this.siteDesignInfo.hasActiveClinics === -1)) {
          this.showAppointmentButton = false;
          this.showBodyText = false;
          this.bodyText = "Sorry. The clinic you are looking for is not available for scheduling appointments.";
        } else if (this.siteDesignInfo.clientInfo.siteStatus && this.siteDesignInfo.hasActiveClinics === 0) {
          this.showAppointmentButton = false;
          this.showBodyText = false;
          this.bodyText = "Sorry, scheduling has been closed for this clinic.";
        } else if (this.scheduledAppointmentInfo.appointmentPK > -1 && this.siteDesignInfo.hasActiveClinics > 0) {
          this.router.navigate(["/schedulerRegistration"]);
        }
        this.footerText1 = this.htmlDecode(this.siteDesignInfo.siteLandingPageText.footerText);

        window.setTimeout(function () {
          if (document.getElementsByClassName("blueAnchor1").length > 0 && document.getElementsByClassName("blueAnchor1")[0].getElementsByTagName('a') && document.getElementsByClassName("blueAnchor1")[0].getElementsByTagName('a').length > 0) {
            var path_ref = document.getElementsByClassName("blueAnchor1")[0].getElementsByTagName('a')[0].href;
            document.getElementsByClassName("blueAnchor1")[0].getElementsByTagName('a')[0].setAttribute("style", "text-decoration: underline;");
            document.getElementsByClassName("blueAnchor1")[0].getElementsByTagName('a')[0].href = "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/" + path_ref.substring(path_ref.lastIndexOf('/') + 1);
            document.getElementsByClassName("blueAnchor1")[0].getElementsByTagName('a')[0].attributes["target"] = "_blank";
          }
        }, 2000);
      }
    });
  }

  htmlDecode(str) {
    var div = document.createElement("div");
    div.innerHTML = str;
    return div.textContent || div.innerText;
  }
  openRegistrationPage() {
    let path = this.protocol + "//" + this.domain + ((this.port) ? ":" + this.port : "");
    window.open(path + '/schedulerRegistration', '_blank', 'location=yes,height=660,width=800,scrollbars=yes,status=yes');
  }

  openPopup(event: any){
    this.showResendPopup = true;
    return false;
  }

  submitResendPopup(){
    let reg_exp = new RegExp('^[a-zA-Z0-9][a-zA-Z0-9.!@#$%&*+_-]+[a-zA-Z0-9]@[a-zA-Z0-9][a-zA-Z0-9._-]+\.[a-zA-Z]{2,3}$');
    if (reg_exp.test(this.resendEmail)) {
      this.scheduler_regisration.resendAppointmentConfirmationEmail(
          this.resendEmail,
          this.siteDesignInfo.clientInfo.clientId
        )
        .subscribe((result:any) => {
          if (result.errorS != null) {
            if ((result.errorS.errorCode = -1)) {
              this.dialogSummary = "Error";
              this.dialogMsg = result.errorS.errorMessage;
              this.errorPopUp = true;
            }
          } else {
            this.dialogSummary = "Resend My Appointment Confirmation Email";
            this.dialogMsg = "Email sent successfully.";
            this.resendEmail = "";
            this.showResendPopup = false;
            this.errorPopUp = true;
          }
        });
    } else {
      this.dialogSummary = "Resend My Appointment Confirmation Email";
      this.dialogMsg = "Please enter valid email address.";
      this.errorPopUp = true;
    }
  }

  closeErrorPopup(){
    this.errorPopUp = false;
  }

  closePop(){
    this.showResendPopup = false;
    this.resendEmail = "";
  }
}
