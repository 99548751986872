export const ErrorMessages: any = {
    "ClinicInformationHeading": "Clinic Information Phone Error",
    "eventInformationHeading": "Event Information Phone Error",
    "ClinicInformationMessage": "Invalid phone number. Please enter a valid phone number in the Clinic Information section.",
    "EventInformationMessage": "Invalid phone number. Please enter a valid phone number in the Event Information section.",
    "OpportunityInformationHeading": "Opportunity Information Phone Error",
    "TestingOrganizationInformationHeading": "Organization Information Phone Error",
    "OpportunityInformationMessage": "Invalid phone number. Please enter a valid phone number in the Opportunity Information section.",
    "TestingOrganizationInformationMessage": "Invalid phone number. Please enter a valid phone number in the Organization Information section.",
    "PharmacistPostClinicInformationHeading": "Pharmacist & Post Clinic Information Phone Error",
    "PharmacistPostClinicInfoHeading": "Pharmacist & Post Clinic Information Error",
    "PharmacistPostClinicInformationMessage": "Invalid phone number. Please enter a valid phone number in the Pharmacist & Post Clinic Information section.",
    "PharmacistPostClinicErrorMsg": "Invalid Pharmacist Name. Please enter a valid Pharmacist Name in the Pharmacist & Post Clinic Information section.",
    "VouchersDistributedMsg": "Vouchers Distributed Cannot be Zero",
    "blockoutImmunizationValidationMessage": "The selected flu product is not available from April 15 to July 15. Please select another immunization or a clinic date after July 15 for clinic location(s): ",
    "minimumShotsRequiredAlert": "If the 25 shot minimum requirement is not met to perform a clinic the client may be sent an invoice for the difference. Recommend the voucher (In-store use only) option for businesses that cannot meet this requirement.",
    "clinicDateReminderBefore2WeeksEN": "<span class='text-danger'>The selected clinic date is less than two weeks from the current date. Your vaccine will not arrive in time for this clinic. Work with your Healthcare Supervisor or District Manager to override the two week minimum scheduling issue and to obtain vaccine to support your clinic.</span>",
    "eventDateReminderBefore2WeeksEN": "<span class='text-danger'>The selected event date is less than two weeks from the current date. Work with your site administrator (<a class=\"text-danger\" href=\"mailto:patientcareandadvocacy@walgreens.com\">PatientCareAndAdvocacy@walgreens.com</a>) to override the two week minimum scheduling issue.</span>",
    "clinicDateReminderAfter2WeeksEN": "IMPORTANT REMINDER: If the contract is not signed at least two weeks prior to the clinic date, vaccine will not arrive in time for this clinic and you will need to work with local leadership to obtain vaccine from your area.",
    "coClinicDateReminderAfter2WeeksEN": "IMPORTANT REMINDER: If the community outreach clinic has not been submitted at least two weeks prior to the clinic date, vaccine will not arrive in time for this clinic and you will need to work with Community Outreach local leadership to obtain vaccine from your area.",
    "chClinicDateReminderAfter2WeeksEN": "IMPORTANT REMINDER: If the Charity (WAG Free Flu Voucher) Program clinic has not been submitted at least two weeks prior to the clinic date, vaccine will not arrive in time for this clinic and you will need to work with Community Outreach local leadership to obtain vaccine from your area.",
    "eventDateReminderAfter2WeeksEN": "IMPORTANT REMINDER: If the contract is not signed at least two weeks prior to the event date, vaccine will not arrive in time for this event and you will need to work with local leadership to obtain vaccine from your area.",
    "CorporateInvoiceError": "Please fill in all Invoice Form fields to proceed",
    "ContractCOImmunizationError": "Please select an Immunization Name and Payment Method",
    "eventDateTimeChanged": "Changes to date and time must be confirmed with the partner prior to being updated. If you have not confirmed these changes, please click Cancel and confirm with the partner first. If you Continue, an email will be sent to the partner with the revised date and time.",
    "errMsg": "Error",
    "sendAcessLink": "Send Access Link",
    "impRmndr": "SCHEDULING ISSUE",
    "impRmndrFor2Wks": "SCHEDULING WARNING",
    "MandatoryFields": "Mandatory fields are empty or have invalid entries. Please check the fields highlighted in red and enter or correct the information.",
    "noMatchingEmails": "Email & Confirm Email do not match. Please enter matching emails to continue.",
    "assignedStore": "Please provide a valid store assignment id.",
    "coSucccess": "The Community Outreach Program saved successfully.",
    "coSummary": 'Community Outreach Program',
    "chSucccess": "The Charity (WAG Free Flu Voucher) Program Updated successfully.",
    "chSummary": 'Charity (WAG Free Flu Voucher) Outreach Program',
    "inValidForm": 'Invalid Form',
    'unKnownError': "Unknown Error Occurred.",
    'MinNumberOfShots': "The number of estimated shots is below the 50 participant minimum in <b>{0}</b>. Each of the 50 participants must receive at least one shot.",
    'MinNumberOfShotsForLTCF': "If the 15 shot minimum requirement is not met to perform <b>{0}</b>, the client may be sent an invoice for the difference. Recommend the voucher (In-store use only) option for businesses that cannot meet this requirement.",
    'MinNumberOfShotsForDetails': "If the 50 shot minimum requirement is not met to perform <b>{0}</b>, the client may be sent an invoice for the difference. Recommend the voucher (In-store use only) option for businesses that cannot meet this requirement.",
    'MinNumberOfShotsForDetails_2_7_6': "If the 25 shot minimum is not met to perform a <b>{0}</b> clinic, recommend the voucher (In-store use only) option.",
    'MinNumberOfShotsForLTCFDetails': "If the 15 shot minimum requirement is not met to perform <b>{0}</b>, the client may be sent an invoice for the difference. Recommend the voucher (In-store use only) option for businesses that cannot meet this requirement.",
    'MinNumberOfShotsForLTCFDetails_2_7_6': "If the 15 shot minimum is not met to perform a <b>{0}</b> clinic, recommend the voucher (In-store use only) option.",
    'MinNumberOfShotsForDetailsHeader': "Minimum Requirement Warning",
    'VerifyEmail': 'The email addresses entered into the Email and Verify Email fields do not match. Please enter matching email addresses.',
    "clinicDateReminderAfter2WeeksENNonAdmin": "IMPORTANT REMINDER: If the contract is not signed at least two weeks prior to the clinic date, vaccine will not arrive in time for this clinic and you will need to work with local leadership to obtain vaccine from your area.",
    "voucherFluYes": "Please select at least one vaccine with <b>Voucher Needed</b> to conduct Voucher Distribution",
    "voucherRoutineYes": "You should select 'Yes' for at least 1 Routine Immunization. Please select 'Yes' for the Voucher option in the corporate invoice form",
    'contractImmunizationOption': "Please select an Immunization and Payment Option from the dropdown to proceed.",
    'NoOutReachProgram': "Select at least one Outreach Campaign to continue.",
    'Atleast1Immunization': "Select at least one Immunization and Payment Method to continue.",
    'DesignationMisMatchCovid': "This opportunity is missing a COVID designation. In order to proceed with contracting a COVID vaccine, update the Opportunity Profile with a COVID designation. If you want to save your current selections, click " + "Cancel" + " and then " + "Save" + " before updating the Opportunity.",
    'DesignationMisMatchFlu': "This opportunity is missing a FLU and ROUTINE designation. In order to proceed with contracting a FLU and ROUTINE vaccine, update the Opportunity Profile with a FLU and ROUTINE designation. If you want to save your current selections, click " + "Cancel" + " and then " + "Save" + " before updating the Opportunity.",
    'CanAddFluImms': "Ensure we offer flu vaccines, if applicable.",
    'CanAddRoutineImms': "Ensure we offer non-flu vaccines such as COVID, pneumonia, and shingles, if applicable.",
    'DesignationMisMatchCovidCommOR': "This opportunity is missing a COVID designation. In order to proceed with contracting a COVID vaccine, update the Opportunity Profile with a COVID designation.",
    'DesignationMisMatchFluCommOR': "This opportunity is missing a FLU and ROUTINE designation. In order to proceed with contracting a FLU and ROUTINE vaccine, update the Opportunity Profile with a FLU and ROUTINE designation.",
    'AtleastPcaImmunization': "Add a Service to continue to the next step.",
    'moreThan250Immunizations': 'You have entered an estimated quantity of <b>{0}</b> shots of <b>{1}</b> for <b>{2}</b>. Please confirm that this quantity is correct.',
    'moreThan250ImmunizationsForDetails': 'You have entered an estimated quantity of <b>{0}</b> shots of <b>{1}</b> for <b>{2}</b>. Please confirm that this quantity is correct.',
    'resolve': 'Please resolve the issues below to continue.',
    'eventDateTimeConflict': 'WARNING: Event Conflict',
    'min25ShotsHdr': 'Below Minimum Requirement for Estimated Shots',
    'emailList': 'Please enter a valid email list. Enter a single email (email@example.com) or multiple emails separated by commas (email1@example.com,email2@example.com,email3@example.com).',
    'emailMessgae': 'Please enter valid email. Enter a single email (email@example.com) or multiple emails separated by commas (email1@example.com,email2@example.com,email3@example.com).',
    'sendEmailMessgae': 'Please enter a valid email',
    'contractSaved': 'The Walgreens Community Off-Site Agreement has been Saved Successfully.',
    'asoLulaSaved': 'The Walgreens Limited Use License Agreement has been Saved Successfully.',
    'contractEmailSent': 'The Walgreens Community Off-Site Agreement email has been sent.',
    'asolulaEmailSent': 'The Walgreens Limited Use License Agreement email has been sent.',
    'asoLula': "Limited Use License Agreement",
    'contract': 'Community Off-Site Agreement',
    'contractAlert': 'Conflict Alert',
    'clincLocDateTimeSingleConflict': 'The Date and Time you have selected for <b>{0}</b> overlaps with another clinic already scheduled for your store on <b>{1}</b> from <b>{2}</b> to <b>{3}</b> for <b>{4}</b>. Please select a different time or date for this clinic in order to save or send this agreement',
    'eventLocDateTimeSingleConflict': 'The Date and Time you have selected for <b>{0}</b> overlaps with another event already scheduled for your store on <b>{1}</b> from <b>{2}</b> to <b>{3}</b> for <b>{4}</b>. Please select a different time or date for this event in order to save or send this agreement',
    'restrictedStateDateConflict': 'The Date you have selected for <b>{0}</b> falls in between April 1st and August 31st. Please select a different date greater than or equal September 1st for this clinic in order to save or send this agreement.',
    'multipleClinicDatesConflictLineItem': 'There are multiple scheduling conflicts in this agreement. Please select a different time or date for the following clinics in order to save or send this agreement.<br/><br/>',
    'multipleEventsDatesConflictLineItem': 'There are multiple scheduling conflicts in this agreement. Please select a different time or date for the following events in order to save or send this agreement.<br/><br/>',
    'clincLocDateTimeMultipleConflict': '<b>{0}</b> overlaps a clinic already scheduled for your store on <b>{1}</b> from <b>{2}</b> to <b>{3}</b> for <b>{4}</b>.',
    'eventLocDateTimeMultipleConflict': '<b>{0}</b> overlaps a event already scheduled for your store on <b>{1}</b> from <b>{2}</b> to <b>{3}</b> for <b>{4}</b>.',
    'approveContract': 'The highlighted input fields are empty or invalid. Please update and submit',
    'restrictedStates': 'All Additional District of {0} Offsite Clinics must be Approved. Please work with Region {1} Leadership.',
    'cancel_save': 'The Community Outreach setup has not been completed. If you leave the setup without submitting clinic Immunizations, Payment Methods and Locations the Community Outreach log status and event will not be saved.',
    'dotNotAllowedCharacter': 'Invalid characters are not allowed as a Copay Value (Example: 25.22).',
    'invalidZipCode': 'The entered zip code is invalid.',
    'invalidPhoneNo': 'The entered phone number is invalid.',
    'invalidFaxNo': 'The entered fax number is invalid.',
    'unSavedData': 'WARNING: Unsaved Data',
    "followp_alert": 'The Follow-Up Email Reminder setup has not been completed. If you leave the page without saving, the Follow-Up log status and email reminder will not be saved.',
    "charity_alert": 'The Charity (WAG Free Flu Voucher) setup has not been completed. If you leave the page without saving, the Charity (WAG Free Flu Voucher) log status and event will not be saved.',
    "contract_alert": 'The Community OffSite Agreement setup has not been completed. If you leave the page without saving, the Community Offsite Agreement log status and event will not be saved.',
    "co_alert": 'The Community Outreach Program setup has not been completed. If you leave the page without saving, the Community Outreach Program log status and event will not be saved.',
    "eq_alert": 'The Equity Event setup has not been completed. If you leave the page without saving, the Equity Event log status and event will not be saved.',
    'warning': "WARNING",
    'specialCharacters': 'Remove invalid characters from the Immunizations Estimated Quantity field.',
    "schedule_alert": 'The SR Event setup has not been completed. If you leave the page without saving, the Scheduled Event log status and event will not be saved.',
    'routineVoucherExpiryInValid': 'The Enhanced Vaccine Voucher expiration dates do not match. All Enhanced Vaccine Voucher expiration dates must be the same expiration date.',
    'fluVoucherExpiryInValid': 'The Flu Voucher expiration dates do not match. All Flu Voucher expiration dates must be the same expiration date.',
    'agreement': "Community Offsite Agreement",
    "comOutreach": 'Community Outreach Program',
    'coSaved': 'The Walgreens Community Outreach clinic has been scheduled.',
    "equityEvents": 'Equity Event Program',
    'eqSaved': 'The Walgreens Equity Event has been scheduled.',
    'serverError': 'Error',
    'clientError': 'Error',
    'invalidDates': 'For Restricted States, dates between May 1st and August 31st are invalid. Please select appropriate dates.',
    'clinicConfirm': 'This clinic has been successfully logged as confirmed.',
    'clinicConfirmWithSchdlr': 'This clinic has been confirmed successfully and an email has been sent to the clinic contact with links to the Appointment Scheduler and Appointment Tracking for this clinic.',
    'clinicConfirmWithCPSchdlr': 'This clinic has been confirmed successfully and the clinic details have been sent to CURA Patient.',
    'eventConfirm': 'This event has been successfully logged as Confirmed.',
    'clinicComplete': 'This clinic has been successfully logged as Completed.',
    'clinicCompleteWithSchdlr': 'This clinic has been completed successfully and an email has been sent to the clinic contact with links to the Appointment Scheduler and Appointment Tracking for this clinic.',
    'clinicCompleteWithCPSchdlr': 'This clinic has been completed successfully and the clinic details have been sent to CURA Patient.',
    'eventComplete': 'This event has been successfully logged as Completed.',
    'clinicCancel': 'This clinic has been Cancelled.',
    'clinicCancelForClinicType3And5': 'Clinic – {0} has scheduled appointments. The Cancellation will be reviewed by a Walgreens Administrator prior to cancelling the scheduled appointments and sending a notification to appointment holders.',
    'clinicCancelForClinicType3And5ForAdmin': 'Clinic - {0} has scheduled appointments. Cancelling the clinic will trigger an email to the clinic contact to approve the sending of cancellation emails to all affected appointment holders. Emails will not be sent to appointment holders until approved by the clinic contact.',
    'eventCancel': 'This event has been Cancelled.',
    'clinicDetails': 'Clinic Details',
    'eventDetails': 'Event Details',
    'clinicDetailsDelete': 'The clinic details deleted successfully.',
    'eventDetailsDelete': 'The event details deleted successfully.',
    'communityOutreachDelete': 'Removing the Community Outreach Program cannot be undone. If you "Continue" the community outreach locations associated with this business and all of their program details will be permanently deleted.',
    'charityProgramDelete': 'Removing the Charity (WAG Free Flu Voucher) cannot be undone. If you "Continue" the Charity (WAG Free Flu Voucher) locations associated with this business and all of their program details will be permanently deleted.',
    'localProgramDelete': 'Deleting the Community Off-Site Agreement cannot be undone. If you "Continue" the contract, contract approval, all clinic locations associated with this business and all of their program details will be permanently deleted.',
    'clinicSave': 'This clinic has been updated successfully.',
    'clinicSavedWithSchdlr': 'This clinic has been updated successfully and an email has been sent to the clinic contact with links to the Appointment Scheduler and Appointment Tracking for this clinic.',
    'clinicSavedWithCPSchdlr': 'This clinic has been updated successfully and the clinic details have been sent to CURA Patient.',
    'eventSave': 'This event has been updated successfully.',
    'invalidEmail': 'Please enter a valid email.',
    'invalidPhoneFormat': 'Please enter a valid phone format.',
    "DetailsMandatoryFields": "Please enter values for all required fields.",
    'reassignStoreChkHdr': 'Reset contact status',
    'reassignStoreChkMsg': 'Do you want to maintain the current status of this clinic or change it back to {0}?',
    'reassignStoreChkMsgForCO': 'Do you want to maintain the current status of this clinic or change it back to {0}?',
    'reAssignStoreInvalidActionHdr': 'Store change with contact status warning',
    'reAssignStoreInvalidActionMsg': 'You cannot reassign the clinic store location and change the status of the clinic at the same time.',
    'moreDosesInDetailsPageError': "<p class='text-danger'>Changing the number of flu doses for this clinic within 14 days of the scheduled date may result in the store having insufficient inventory. Consider another date or check with the DM to obtain sufficient inventory.</p>",
    'totalAdministeredIsNOK': 'Please correct the Total Administered value.',
    'eventTotalAdministeredIsNOK': 'Please correct the Actual Attendance value.',
    'clinicListEmpty': 'At least one Clinic Date should be added to send the Contract for Approval.',
    'coClinicListEmpty': 'At least one Clinic Date should be added to submit Community Outreach Program.',
    'clinicRegistration': 'Clinic Registration & Scheduling',
    'blockApptKeyMessage': '{0} has scheduled appointments. Reducing the immunizers or unscheduling the clinic(s) will cancel the appointments. Click "Continue" to reduce immunizers or unschedule the clinic(s) and send the rescheduled appointment email.',
    'dashBoardErrorMessage': 'The date and time you have selected for <b>{0}</b> overlaps <br/> another clinic already scheduled for your store on <b>{1}</b> <br/> from <b>{2}</b> to <b>{3}</b> for <b>{4}</b>. </br> Please select a different time or date for this clinic.',
    'dashBoardOverlapErrorMessage': 'The date and time you have selected for <b>{0}</b> overlaps another clinic already scheduled for store {1} on <b>{2}</b> from <b>{3}</b> to <b>{4}</b> for <b>{5}</b>.',
    'DashboardReassignmentWarning': 'Please be aware that the clinic you are reassigning has appointments scheduled. <br/> These appointments will be reassigned along with the clinic(s) <b>{0}</b>.',
    'CorpClinicDetailsRspErrorSummary': 'Reschedule Appointment confirmation',
    'CorpClinicDetailsChangedWarning': 'This Clinic has scheduled appointments. Changing the clinic details ({1}) will cancel the appointments and trigger an email to the patients to reschedule their appointments. Click "Continue" to change the details and send email.',
    'CorpClinicDetailsChangedErrMsg': 'This Clinic has scheduled appointments. Changing the clinic details ({1}) will cancel the appointments and trigger an email to the patients to reschedule their appointments.  If it is necessary to change the details please open a ticket with the Ask the Retail Hub via Pharmacy > Community Outreach Portal > Immunizations.',
    'CorpClinicDetailsLocationChangedWarning': 'Saving changes to the location of this clinic will immediately send a change of clinic location email to anyone with a scheduled appointment for this clinic. Are you sure you want to update this clinic?',
    'CorpClinicCancelClinicWarning': 'Cancelling this clinic will initiate a Cancelled Clinic email to all scheduled patients for this clinic. Click "Continue" to cancel the clinic and send the emails.',
    'CorpClinicCancelPastClinicWarning': 'The date for the selected clinic has already passed. If you continue, the clinic will move to a "Cancelled" status but no emails will be sent for the scheduled appointments.',
    'PCAAddWarning': 'The store you are assigning this organization to cannot be in a different state than the organization’s location.',
    'DuplicatePCAAdd': 'There is already an organization in the system with the same <b>{0}</b>.<br> If you would like to add the organization below to your store {1}, please select an organization and click Add.',
    'ElectronicSignatureErrorHdng': 'Contract Alert',
    'ElectronicSignatureErrorMsg': 'The electronic signature does not match the signer\'s name in the agreement. Please click on Step 1 and verify that the entered “Name” matches the name you are entering as the electronic signature',
    'ValidateRHDEmail': 'The email provided is not a valid Walgreens Regional user. Please correct the email and try again.',
    'DuplicatePCAAddress': 'There is an existing partner available with the same address. The matching Store IDs and Partners are: {0}. To eliminate duplicate partners from being created, this organization will not be added. <br/><br/>To add this partner to a store, use the “Search for an Existing Partner” function, add the partner and save. Please contact a site administrator if you need assistance.',
    'DuplicatePCAPhone': 'There is an existing partner available with the same phone number. The matching Store IDs and Partners are: {0}. To eliminate duplicate partners from being created, this organization will not be added.<br/><br/>To add this partner to a store, use the “Search for an Existing Partner” function, add the partner and save. Please contact a site administrator if you need assistance.',
    'DuplicateTestingOrg': 'There is already an organization assigned to this store with the same organization name: <b>{0}</b>.',
    'clinicNotConfirmedMsg': 'In order to Complete the clinic, you will need to Confirm it first. Please enter the name of the person at the business or organization who confirmed the event with you in the "Confirmed By" field and click the Confirm button.',
    'StopAddLocationAmend': 'The contract for this clinic has been amended and sent for approval. New locations may not be added until the amended contract is approved.',
    'DisbaleButtonsAmend': 'The contract for this clinic has been amended and sent for approval. Updates may not be made to the Clinic Details until the amended contract is approved. If the amendment needs to be revoked, please contact a site administrator.',
    'eventDateTimeConflictErrText': 'Click \'Cancel\' to change the conflicting date and time or \'Override\' if sufficient staff is available to perform multiple events.',
    'cancerScreeningEventsHeading': 'Error in Cancer Screening Events',
    'locationRequirementsHeading': 'Error in Location Requirements',
    'locationRequirements': 'Cancer Screening services cannot be provided unless the location requirements are met. Please confirm and check off the satisfied requirements. If the requirements cannot be met, than the store cannot hold an event.',
    'cancerEventWarning': 'This store will exceed the maximum of two Cancer Screening events allowed per year. Please remove events or Cancel the LULA setup if you have already scheduled two events for the year. If you have questions, please contact a site administrator (<a href="mailto:patientcareandadvocacy@walgreens.com" class="text-primary"><u>PatientCareAndAdvocacy@walgreens.com</u></a>).',
    'noStoreAssigned': 'There are no stores assigned to you. Please contact an administrator.',
    'nullClinicAgreementPk': 'Invalid Clinic Agreement Number. Please contact an administrator.',
    'nullStoreId': 'Invalid Store Id. Please contact an administrator.',
    'CoCharityForUnapprovedOpportunity': "The selected opportunity has not been approved and assigned to this store for the Immunization Program. Please ask your Healthcare Supervisor to assign this opportunity prior to initiating an Immunization Program Community Outreach or Charity event.",
    'error': "An error has occurred. Please contact an administrator.",
    'locationHealthRoomRequirements': 'A free testing & counseling event cannot be provided unless the location requirements are met.  If your store does not have a dedicated private health room or modular private health room and if your testing partner does not have a mobile testing unit, then please refer the testing partner to a store near you that meets the location requirements.',
    'eventRemoved': "The event you are looking for is no longer available. It may have been reassigned or removed.",
    'clinicDetailsErrHeader': "Alert",
    'confirmClinicCancellation': 'Are you sure you want to Cancel the Clinic? Store re-assignments and date/time changes should be made in the clinic details and saved. Contact a site administrator for Corporate Clinic store re-assignments.',
    'confirmCancelEvent': 'Are you sure you want to Cancel this Event?',
    'removeCancelledClinicStatus': 'Cancelled status has been removed successfully.',
    'errorVARForm': 'An error has occurred while generating the VAR form. This form will be available to download from the confirmation email sent to the provided email.',
    'errorSchedulerMsg': "An error has occurred.",
    'CIError': "A contract has already been initiated for this opportunity.",
    'scheduleClinicReportFilterError': "Please select at least one {0}.",
    'clinicalRequirementsError': 'The Clinical Requirements have not been checked. {0} cannot be administered unless the clinical requirements are agreed upon by the immunizing pharmacist.',
    'walgreensMandatoryEmail': 'Please enter a valid Contact Phone.',
    'validatePortalEmailError': 'The email you have entered does not exist in the portal. Please use an alias email (e.g., rxm.00000@store.walgreens.com), not a personal email containing a name.',
    'requiredFields': 'Please fill out all required fields highlighted in red with valid information.',
    'geo_zero_results': 'The address entered cannot be located. Please check the address and resubmit the form.',
    'geo_unknown_error': 'Sorry, we are experiencing a problem. Please try again.',
    'geo_timed_out': 'Sorry, the request has timed out. Please try again.',
    'approveRejectCancellationText': '<p>Email notifications have been sent to the appointment holders affected by your cancelled clinic. If you have other clinic dates and times scheduled, they will be provide the option to reschedule at one of the other clinics. <br/><br/>If you have any questions or would like to discuss our immunization voucher program, please contact your Walgreens representative.<br/><span><strong>Walgreens Representative: </strong>{0}<br/><strong>Phone: </strong>{1}<br/><strong>Email: </strong>{2}</span></p>',
    'approveRejectCancellationTextNoContactInfo': '<p>Email notifications have been sent to the appointment holders affected by your cancelled clinic. If you have other clinic dates and times scheduled, they will be provide the option to reschedule at one of the other clinics. <br/><br/>If you have any questions or would like to discuss our immunization voucher program, please contact your Walgreens representative.',
    'clinicLengthMoreThan8Hrs': 'The duration for the event, based on the start and end times, is more than 8 hours. Please verify that this is correct.',
    'clinicLengthAlert': 'The selected end time is the same or precedes the selected start time. Please correct the selected times for this event.',
    'slotsBlockedWarning': 'Updating the clinic date or times may affect appointment time slots that have been previously blocked out for breaks. Please check the Appointment Tracking Scheduled Breaks to ensure that the appropriate appointment slots have been blocked.',
    'turnOffApptSchdWarning': 'Disabling the appointment scheduler will remove this clinic location as an option in the appointment scheduler. However, any previously scheduled appointments will remain scheduled.',
    'immunizerWarningMainPara': 'Based on the number of estimated shots and the duration of the clinic, additional immunizers may be needed at the clinic locations below. If another immunizer cannot attend the clinic, consider lengthening the duration of the clinic.<br/><br/>',
    'noClinicApptsWarning': 'Updating the existing clinic to Voucher Only may result in cancellation of appointments that have been previously registered.',
    'immunizerWarning': '{0} may need up to {1} immunizers.<br/>',
    "new_clinicDateReminderBefore2WeeksEN": "<span class='text-danger'>The selected clinic date is less than two weeks from the current date. Your vaccine will not arrive in time for this clinic. Work with your Healthcare Supervisor or District Manager to obtain vaccine to support your clinic.</span>",
    'COVIDvisitTypeError': 'The visit you have selected for <b>{0}</b> does not match with the esitmated quantity you have entered. Please enter correct values.',
    'covidVisitGap': 'The selected clinic date for <b>{0} Visit {1} </b> and <b>{2} Visit {3} </b> is less than the {4} days required for a {5} COVID vaccine from the previous visit date.',
    'InvalidCOVIDContractHeader': 'Invalid Immunization',
    'InvalidCOVIDContractError': 'A combination of COVID and non-COVID immunizations may not be added to the same contract. Please add a new opportunity and override the duplicate warning to initiate a second contract with the client.',
    'InvalidCOVIDMNFContractError': 'A combination of different COVID manufactured immunizations may not be added to the same contract.',
    'DoseComb': 'COVID Community Outreach Clinics require both COVID-19 vaccine doses to be added to the clinic. Please add Dose {0}.',
    'RemoveLogStatus': 'Are you sure you want to remove the last logged status? The clinic status will be reverted to the prior status.',
    'RemoveCancelled': 'Are you sure you want to remove the Cancelled status? The clinic status will be reverted to the status prior to the cancellation.',
    'covidVoucherError': 'A COVID-19 Voucher has been added as an Immunization product. Please change Voucher Needed to "Yes" or remove the COVID-19 Voucher.',
    'covidVoucherError1': 'A COVID-19 Voucher must be added as an Immunization product. Please add a COVID-19 Voucher or change Voucher Needed to “No”.',
    'NoSupportForDose1OrDose2': '{0} is no longer Available. Please contact site administrator.',
    'localContractsVerbiage': "<span><b>Note</b>: If the client requests a combined clinic with Flu/Routine and COVID add a new duplicate opportunity and select the appropriate designation as needed. <a href=\"http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/offsite_clinics/Add_Opportunity.pdf\">Click here for Job Aid</a></span>",
    'vouchersDistributed': "Vouchers Distributed cannot be greater than the Estimated Shots per clinic. Please be sure to include the number of Vouchers Distributed in the number of Estimated Shots.",
    'lowerCountVouchersDistributed': "The value entered for Vouchers Distributed lowers the number of shots to be provided at the clinic below the minimum.",
    'immunizationWithoutVoucherPayment': "<b>{0}</b> has been set as 'No Clinic - Voucher Distribution Only'. To continue, do one of the following: Set the Estimated Shots for any Immunization without a 'Voucher: Corporate to Invoice Employer Directly' payment method to '0', go back to Step 1 and add the 'Voucher: Corporate to Invoice Employer Directly' payment method to the Immunization, or uncheck the 'No Clinic - Voucher Distribution Only' setup option.",
    'immunizationWithoutVoucherPaymentForEuityEvents': "<b>{0}</b> has been set as No Clinic - Voucher Distribution Only'. To continue, do one of the following: Set the Estimated Shots for any immunization without a Voucher payment method to '0' Go back to Step 1 and add a Voucher payment method to any immunization with a value greater than '0'  Uncheck the 'No Clinic - Voucher Distribution Only' setup option",
    "COVIDManufacturerReminder": "Select the COVID manufacturer assigned to the host store to streamline distribution and reduce waste.",
    "deleteOpportunityHeader": "Delete Opportunity",
    "deleteOpportunityConfirmation": "Are you sure you want to delete this opportunity? Once deleted it will no longer appear in the Community Outreach Portal for any Outreach Programs.",
    "cannotDeleteOpportunity": "This opportunity cannot be deleted because it is in the process of being contracted or has scheduled clinic(s) or event(s).",
    "deleteCommunityOutreachHeader": "Deleting A Community Outreach Clinic",
    "deleteCommunityOutreachConfirmation": 'Deleting a Community Outreach Clinic cannot be undone. If you "Continue", all clinics associated with this client will be permanently deleted.',
    "deleteOffSiteAgreementHeader": "Deleting An Off-Site Agreement",
    "deleteOffSiteAgreementConfirmation": 'Deleting the Community Off-Site Agreement cannot be undone. If you "Continue" the contract, contract approval, all clinic locations associated with this business, all of their program details, and any pending amended contracts will be permanently deleted.',
    "serviceAgreementAcceptence": 'The Service Fee Acknowledgement has not been checked. The Community Off-Site Agreement cannot be sent unless the service fee has been acknowledged.',
    "sesionalCovid19Info": "COVID 19 vaccines are now seasonal and will not be available for clinics scheduled between May 20th and August 15th. Please remove the COVID 19 vaccines or set the COVID 19 Estimated Shots to '0' for clinics between May 20th and August 15th to continue.",
    "estimatedParticipantsWarning": "If the 50 participant minimum requirement is not met to perform <b> LOCATION </b>  the client may be sent an invoice for the difference. Recommend the voucher (In-store use only) option for businesses that cannot meet this requirement.",
    "estimatedShotsWarning": "The Estimated Number of Shots for any single immunization cannot be greater than the Estimated Number of Participants. Please either increase the number of participants or decrease the number of shots to continue.",
    "estimatedShotsWarninglt50": "The number of estimated shots is below the 50 participant minimum in <b> LOCATION </b> . Each of the 50 participants must receive at least one shot."
}