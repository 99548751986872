import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-immunization-program-email-template',
  templateUrl: './immunization-program-email-template.component.html',
  styleUrls: ['./immunization-program-email-template.component.css']
})
export class ImmunizationProgramEmailTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
