
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationSettings } from '../../../common/application-settings';
import { PotentialLocalBusinessAndContactStatusService } from '../../services/potential-local-business.service';
import { SessionDetails } from '../../../../utility/session';
import { saveAs as importedSaveAs } from "file-saver";
import { PotentialCOVIDBusiness } from '../../../../models/PotentialLocalBusiness';
import { Router } from '../../../../../../node_modules/@angular/router';
import { Paginator } from '../../../../../../node_modules/primeng/paginator';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { ClinicdetailsService } from '../../../clinicdetails/services/clinicdetails.service';
import { ScheduledAppointmentsService } from '../../services/scheduledAppointments.service';
import { NgxSpinnerService } from "ngx-spinner";
import { states } from '../../../../JSON/States';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-covid-opp-statusreport',
  templateUrl: './covid-opp-statusreport.component.html',
  styleUrls: ['./covid-opp-statusreport.component.css']
})
export class CovidOppStatusreportComponent implements OnInit {

  loading: any;
  storeId: any;
  ddlFormat: any;
  canExport: any;
  outreachstatus: any;
  contacted: any;
  status: any;
  fromDate: any;
  toDate: any;
  outreachEffort: any;
  minDate: any;
  maxDate: any;
  from_date: any;
  to_date: any;
  isDisable: boolean = false;
  outreachStartDate: any;
  outreachEndDate: any;
  potentialLocalBusinessContactStatusReportArray: any = [];
  potentialLocalBusinessContactStatusReportSource: any;
  totalRecords: number = 0;
  allStatuses: any = [];
  canShowNoBusinessesText: boolean = false;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  regionList = [];
  areaList = [];
  districtList = [];
  //storeList = [];
  ddlArea: string = "0";
  ddlDistrict: string = "0";
  ddlRegion: string = "0";
  ddlStoreId: string = "0";
  @ViewChild('plbcsPager', { static: false }) plbcsPager: Paginator;
  userProfile: any;
  states: any[];
  ddlState: any = "0";
  showddlState: boolean = false;
  covidOppOutreachstatusForm: FormGroup;
  isDisableDates: boolean;
  constructor(private router: Router, private potential_service: PotentialLocalBusinessAndContactStatusService,
    private message_service: MessageServiceService,
    private coClinicdetailsService: ClinicdetailsService, private storeIdListService: ScheduledAppointmentsService
    , private spinner: NgxSpinnerService, private fb: FormBuilder) {
    //   if (this.router.url.indexOf('immunization') > -1) {
    this.outreachEffort = "3";
    this.outreachStartDate = ApplicationSettings.getCovidStartDate(new Date().getFullYear());
    this.outreachEndDate = ApplicationSettings.getCovidEndDate(new Date().getFullYear());
    // } else {
    //   this.outreachEffort = "1";
    //   this.outreachStartDate  = ApplicationSettings.getSRStartDate(new Date().getFullYear());
    //   this.outreachEndDate = ApplicationSettings.getSREndDate(new Date().getFullYear());
    // }
    this.userProfile = SessionDetails.GetUserProfile();
    if (this.userProfile.userRole == "Admin" || this.userProfile.userRole.toLowerCase() == "regional vice president" || this.userProfile.userRole.toLowerCase() == "regional healthcare director") {
      this.showddlState = true;
    }
    this.states = states.sort((a, b) => a.id.localeCompare(b.id));
    this.message_service.getStoreID().subscribe(x => {
      this.storeId = x.text;
      this.loading = true;
      this.generateReport();
    });
  }

  ngOnInit() {
    this.storeId = SessionDetails.GetStoreId();
    this.outreachstatus = "-1";
    this.contacted = "3";
    this.ddlFormat = "0";
    this.isDisableDates=true;
    this.potential_service.getOutreachStatus().subscribe((resultArray: any) => {
      let outreach_program = ((this.outreachEffort == "1") ? "SR" : "IP");
      this.allStatuses = resultArray;
      var temp = resultArray.filter(x => x.outreachProgram == outreach_program && x.isActive /*== true*/ && [0, 9, 10].indexOf(x.outreachStatusId) == -1);
      this.status = temp;
      this.setDates();
      let region = this.showddlState ? "Region" : "Area";
      this.coClinicdetailsService.getAreaDist(region,0).subscribe(data => {
        
        let dataList = data;
        for (var keyValue in dataList) {
          let valueData = dataList[keyValue];
          if (region == "Region")
            this.regionList.push({ key: keyValue, value: valueData });
          else
            this.areaList.push({ key: keyValue, value: valueData });
        }
      });
      this.setFormControls();
      
      //this.getStoreIdList(this.ddlRegion,this.ddlArea,this.ddlDistrict);
      //this.generateReport();
    });

    this.covidOppOutreachstatusForm.controls['areaNumber'].patchValue("0");

    // this.covidOppOutreachstatusForm.patchValue({
    //   areaNumber:0
    // })
  }
  setFormControls() {
    this.covidOppOutreachstatusForm = this.fb.group({
      'fromDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'toDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
      'regionNumber': ["0"],
      'areaNumber': ["0"],
      'districtNumber': ["0"],
      'state': ["0"],
      'contacted': ["3"],
      'status': ["-1"],
      'outputType': ["0"]
    });
  }
  // onDistrictSelect(value, selected_value) {    
  //   this.getStoreIdList(this.ddlRegion,this.ddlArea,this.ddlDistrict);
  //   //this.showAllLocationsOption = true;
  // }
  // getStoreIdList(regionNumber,areaNumber,districtNumber){
  //   this.storeIdListService.getStoreIdListByRegionAreaDistict(
  //     regionNumber,areaNumber,districtNumber).subscribe((data) => {
  //       this.storeList = data;
  //     });
  // }
  onSelect(value, selected_value) {
    
    switch (value) {
      case 'Area':
        this.areaList = [];
        this.districtList = [];
        this.ddlArea = '0';
        this.covidOppOutreachstatusForm.controls['areaNumber'].setValue('0');
        this.ddlDistrict = '0';
        this.coClinicdetailsService.getAreaDist(value,selected_value).subscribe(data => {
          let dataList = data;
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.areaList.push({ key: keyValue, value: valueData });
          }
          //this.getSchedulerClients(this.ddlRegion,this.ddlArea>0?this.ddlArea:0,this.ddlDistrict>0?this.ddlDistrict:0);
          //this.showAllLocationsOption = true;
          //this.getStoreIdList(this.ddlRegion,0,0);
        });
        break;
      case "District":
        this.districtList = [];
        this.ddlDistrict = '0';
        this.covidOppOutreachstatusForm.controls['districtNumber'].setValue('0');
        this.coClinicdetailsService.getAreaDist(value,selected_value).subscribe(data => {
          let dataList = data;
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.districtList.push({ key: keyValue, value: valueData });
          }
          //this.getSchedulerClients(this.ddlRegion,this.ddlArea,this.ddlDistrict>0?this.ddlDistrict:0);
          //this.showAllLocationsOption = true;
          //this.getStoreIdList(this.ddlRegion,this.ddlArea,0);
        });
        break;
      default:
        this.areaList = [];
        this.districtList = [];
        this.coClinicdetailsService.getAreaDist(value,0).subscribe(data => {
          let dataList = data;
          for (var keyValue in dataList) {
            let valueData = dataList[keyValue];
            this.regionList.push({ key: keyValue, value: valueData });
          }
        });
        break;
    }
  }
  setDates() {
    this.minDate = this.fromDate = new Date(this.outreachStartDate);
    this.maxDate = this.toDate = new Date(this.outreachEndDate);
    // if(this.outreachEffort == 1){
    //     this.minDate = this.fromDate = new Date("09/01/" + this.fromDate.getFullYear());
    //     this.maxDate = this.toDate = new Date("08/31/" + this.toDate.getFullYear());
    //   }
    //this.toDate.setMonth(this.toDate.getMonth() + 1);
  }
  setCalendarDates() {
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
  }
  reset() {
    this.contacted = "3";
    this.ddlFormat = "0";
    this.outreachstatus = -1;
    this.setDates();
    this.ddlFormat = "0";
    this.ddlRegion = "0";
    this.ddlArea = "0";
    this.ddlDistrict = "0";
    this.ddlStoreId = "0";
    this.setFormControls();
    //this.generateReport();
  }
  generateReport() {
      
    let data=this.covidOppOutreachstatusForm.value;
    let d1 = data.fromDate;
    let d2 =data.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
   
    let potential_local_business = new PotentialCOVIDBusiness(Number(this.ddlStoreId) > 0 ? Number(this.ddlStoreId) : 0, data.regionNumber,data.areaNumber, data.districtNumber, this.outreachEffort, data.contacted, this.from_date, this.to_date, data.status, "", "", data.state);
    this.spinner.show();
    this.potential_service.getPotentialCOVIDBusinessData(potential_local_business).subscribe((resultArray: any) => {
      if (resultArray.length == 0) {
        this.canShowNoBusinessesText = true;
      } else {
        this.canShowNoBusinessesText = false;
      }
this.potentialLocalBusinessContactStatusReportSource = resultArray;
      this.totalRecords = this.potentialLocalBusinessContactStatusReportSource.length;
      var temp = this.potentialLocalBusinessContactStatusReportSource.slice(0, 50);
      this.potentialLocalBusinessContactStatusReportArray = temp;
      setTimeout(() => {
        this.loading = false;
        this.canExport = true;
        this.spinner.hide();
      }, 1000)
    }, error => {
      this.spinner.hide();
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.potentialLocalBusinessContactStatusReportSource = [];
      this.totalRecords = 0;
    });
    this.ddlFormat = "0";
  }
  updateOutreachStatus() {
    
    let data=this.covidOppOutreachstatusForm.value;
    let outreach_program = ((this.outreachEffort == "1") ? "SR" : "IP");
    if (data.contacted == "0") {
      let temp = this.allStatuses.filter(x => x.outreachProgram == outreach_program && x.isActive /* == true*/ && [0,2].indexOf(x.outreachStatusId) > -1);
      this.status = temp;
      // this.isDisable = true;
      this.outreachstatus = 0;
      this.isDisableDates = true;
    }
    else if (data.contacted == "1") {
      let temp = this.allStatuses.filter(x => x.outreachProgram == outreach_program && x.isActive /* == true*/ && [0, 9, 10].indexOf(x.outreachStatusId) == -1 && x.outreachStatusId!=2);
      this.status = temp;
      // this.isDisable = true;
      this.outreachstatus = 0;
      this.isDisableDates = false;
    }
    else {
      let temp = this.allStatuses.filter(x => x.outreachProgram == outreach_program && x.isActive /* == true*/ && x.outreachStatusId!=9 && x.outreachStatusId!=10);// && [9, 10].indexOf(x.outreachStatusId) == -1 && x.outreachStatusId> -1);
      this.status = temp;
      // this.isDisable = false;
      this.outreachstatus = -1;
      this.isDisableDates = true;
    }
    this.canExportOnchange();
  }
  generatereportformat(): void {
    this.loading = true;
    let data=this.covidOppOutreachstatusForm.value;
    let d1 = data.fromDate;
    let d2 = data.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    let report_name: string = ((this.outreachEffort == "1") ? "SR_" : "IP_") + "COVIDOpportunityAndContactStatus";
    
    // let potential_local_business = new PotentialCOVIDBusiness(Number(this.ddlStoreId) > 0 ? Number(this.ddlStoreId) : 0, data.regionNumber,data.areaNumber, data.districtNumber, this.outreachEffort, data.contacted, this.from_date, this.to_date, data.status, "", "", data.state);
    let potential_local_business = new PotentialCOVIDBusiness(Number(this.ddlStoreId) > 0 ? Number(this.ddlStoreId) : 0, data.regionNumber,data.areaNumber, data.districtNumber,this.outreachEffort, data.contacted, this.from_date, this.to_date, data.status, report_name, data.outputType, data.state);
    this.spinner.show();
    this.potential_service.exportCOVIDReportData(potential_local_business).subscribe((res:any) => {
      importedSaveAs(res.body, report_name + ".xlsx");
      this.loading = false;
      this.canExport = true;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
    this.ddlFormat = "0";
  }

  paginate(event) {
    this.potentialLocalBusinessContactStatusReportArray = [];
    this.loading = true;
    var curr_page_rec_count = event.first + event.rows;
    var page_to_get = curr_page_rec_count / 50;
    page_to_get = Math.ceil(page_to_get);
    var start_number = ((event.first < 50) ? (event.first - (50 * (page_to_get - 1))) : event.first);
    if (this.potentialLocalBusinessContactStatusReportSource) {
      var temp = this.potentialLocalBusinessContactStatusReportSource.slice(start_number, (start_number + 50));
      this.potentialLocalBusinessContactStatusReportArray = temp;
      this.totalRecords = this.potentialLocalBusinessContactStatusReportSource.length;
    }
    this.loading = false;
  }
  canExportOnchange() {
    this.canExport = false;
  }
  validateExportType() {
    if (this.ddlFormat == "0" || !this.canExport) {
      return true;
    }
    else {
      return false;
    }
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }

}
