import { Injectable } from "@angular/core";
@Injectable()
export class StoreProfile {
    storePk: number;
    storeId: number;
    storeAddress: string;
    storeAddress2: string;
    storeCity: string;
    storeState: string;
    lastStatusProvided: Date;
}