import { Component, OnInit } from '@angular/core';
import { saveAs as importedSaveAs } from "file-saver";
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { InitiatedcontractsService } from '../../services/initiatedcontracts.service';
import { ErrorMessages } from "../../../../config-files/error-messages";

@Component({
  selector: 'app-initiatedcontractsreport',
  templateUrl: './initiatedcontractsreport.component.html',
  styleUrls: ['./initiatedcontractsreport.component.css']
})
export class InitiatedcontractsreportComponent implements OnInit {
  InitiatedContractsReportForm: FormGroup;
 ddlFormat: any;
  loading:boolean=true;
  InitiatedContractsReportArray: any;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  covidOnly: boolean = true;
  constructor( private fb: FormBuilder,private initiatedcontractsreport: InitiatedcontractsService) { }

  ngOnInit() {
    this.ddlFormat = "0";
    this.setFormControls();
    this.generateReport();
  }

  setFormControls() {
    this.InitiatedContractsReportForm = this.fb.group({
      'ddlFormat': [0],
      'covidOnly': [false]
    });
  }
  ddlFormatDropdown(data){
  //
  console.log(data.target.value);
  this.InitiatedContractsReportForm.controls['ddlFormat'].setValue(data.target.value);
  
  }
  generateReport() {
    
    this.loading = true;
    let data=this.InitiatedContractsReportForm.value;

    this.initiatedcontractsreport.getInitiatedContractsDetails(data.covidOnly).subscribe((resultArray: any) => {
      this.InitiatedContractsReportArray = resultArray;
      
      // console.log(resultArray);
      this.loading=false;

    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.InitiatedContractsReportArray = [];
    });
    this.ddlFormat="0";
  }
  reset(){
    this.ddlFormat=0;
    this.covidOnly = false;
     this.setFormControls();
    }
  generateReportFormat() {
    
    this.loading=true;
  let data=this.InitiatedContractsReportForm.value;
 
    this.initiatedcontractsreport.exportReportData(data.covidOnly, "InitiatedContracts","xlsxv").subscribe((res:any) => {
      
      importedSaveAs(res.body, "InitiatedContracts.xlsx");
      this.loading=false;
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
    this.ddlFormat="0";
  }
  validateExportType(){
    if(this.ddlFormat=="0" )
    return true;
    else
     return false;
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
