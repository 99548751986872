
export class HighLevelStatus {
 outreachEffort:string=null;
 userId:number;
 districtId:number=0;
 fromDate:string=null;
 toDate:string=null;
 reportName: string = "";
 outputType: string = "";
 covidOnly: boolean;
 ddlState : string = "";
    constructor(state : string, outreach_effort:string,user_id: number, district_id:number, from_date:string,to_date:string, covidOnly: boolean, report_name: string, output_type: string) {
        this.outreachEffort = outreach_effort;
        this.userId = user_id;
        this.districtId = district_id;
        this.fromDate = from_date;
        this.toDate = to_date;
        this.reportName=report_name;
        this.outputType = output_type;
        this.covidOnly = covidOnly;
        this.ddlState = state;
    }
}




 