import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';
import { FooterComponent } from './footer/footer.component';
import { FilterPipe } from '../../store/pipes/filter-pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '../../../alert/alert.component';
import { MessageServiceService } from '../../store/services/message-service.service';
import { AlertService } from '../../../alert.service';
import { StoreProfile } from '../../../models/storeProfile';
import { HeaderserviceService } from '../services/headerservice.service';
import { MasterlayoutComponent } from './masterlayout/masterlayout.component';
import { StoreOpportunityComponent } from './store-opportunity/store-opportunity.component';
import { LocationNumberPipe } from '../../contractaggreement/pipes/location-number.pipe';
import { TimeToDatePipe } from '../../contractaggreement/pipes/time-to-date.pipe';
import { HintsPopupComponent } from './popupHints/popuphints.component';
import { OnlyNumberirectiveDirective } from '../../../utility/only-numberirective.directive';
import { NumberhyphonparenthesisDirective } from '../../../utility/numberhyphonparenthesis.directive';
import { SafehtmlPipe } from '../services/safehtml.pipe';
import { CSTDateConvertPipe } from '../../store/pipes/cstDateConvert';
import { PhoneFormatPipe } from '../../../utility/phone-format.pipe';
import { ReplaceTextPipe } from '../../../utility/replace-text.pipe';
import { InvalidContractComponent } from './invalid-contract/invalid-contract.component';
import { CovidContactUsComponent } from './covid-contact-us/covid-contact-us.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgxSpinnerModule } from "ngx-spinner";
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { CheckboxModule } from 'primeng/checkbox';
import { LtcfContactUsComponent } from './ltcf-contact-us/ltcf-contact-us.component';
import { LtcfContactUsLocationsComponent } from './ltcf-contact-us-locations/ltcf-contact-us-locations.component';
import { ImmzContactUsComponent } from './immz-contact-us/immz-contact-us.component';
import { PreviousClientInterestComponent } from './previous-client-interest/previous-client-interest.component';
import { StoreRespondsYesOrNoComponent } from './store-responds-yes-or-no/store-responds-yes-or-no.component';
import { ClickOutsideDirective } from '../../../utility/click-outside.directive';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CalendarModule,
    DialogModule,
    RouterModule,
    InputSwitchModule,
    NgbModule,
    ConfirmDialogModule,
    InputMaskModule,
    NgxSpinnerModule,
    RecaptchaModule,
    TableModule,
    CheckboxModule
  ],
  declarations: [
    FieldErrorDisplayComponent,
    FooterComponent,
    OnlyNumberirectiveDirective,
    MasterlayoutComponent,
    AlertComponent,
    FilterPipe,
    SafehtmlPipe,
    LocationNumberPipe,
    TimeToDatePipe,
    MasterlayoutComponent,
    StoreOpportunityComponent,
    HintsPopupComponent,
    NumberhyphonparenthesisDirective,
    CSTDateConvertPipe,
    PhoneFormatPipe,
    ReplaceTextPipe,
    InvalidContractComponent,
    CovidContactUsComponent,
    LtcfContactUsComponent,
    ImmzContactUsComponent,
    PreviousClientInterestComponent,
    StoreRespondsYesOrNoComponent,
    ClickOutsideDirective,
    LtcfContactUsLocationsComponent
  ],
  providers: [
    HeaderserviceService,
    MessageServiceService,
    AlertService,
    StoreProfile,
    SafehtmlPipe,
    CSTDateConvertPipe,
    PhoneFormatPipe
  ],
  exports: [
    FieldErrorDisplayComponent,
    FooterComponent,
    LocationNumberPipe,
    TimeToDatePipe,
    HintsPopupComponent,
    OnlyNumberirectiveDirective,
    NumberhyphonparenthesisDirective,
    SafehtmlPipe,
    CSTDateConvertPipe,
    PhoneFormatPipe,
    NgxSpinnerModule,
    ReplaceTextPipe,
    ClickOutsideDirective
  ]
})
export class ReusableModule { }
