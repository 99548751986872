// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-resource{
    background: rgb(50, 50, 100) !important;;
}
.text-blue{
    color: #02a0c0 !important;;
}
.list-group-item {
    padding: 5px 10px !important;
}

.btn-lst {
    padding: 4px 4px !important;
    line-height: 0 !important;
}
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #02a0c0 !important;
    border-color: #02a0c0 !important;
}
.btn-primary {
    color: #fff;
    background-color: rgb(50, 50, 100) !important;
    border-color: rgb(50, 50, 100) !important;
}
.btn-primary:hover {
    color: #fff;
    background-color: rgb(50, 50, 100) !important;
    border-color: rgb(50, 50, 100) !important;
}
section.dark.page-header {
    color: #fff;
    background-color: rgb(50, 50, 100) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/resources/components/vis/vis.component.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;AAC3C;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,oCAAoC;IACpC,gCAAgC;AACpC;AACA;IACI,WAAW;IACX,6CAA6C;IAC7C,yCAAyC;AAC7C;AACA;IACI,WAAW;IACX,6CAA6C;IAC7C,yCAAyC;AAC7C;AACA;IACI,WAAW;IACX,6CAA6C;AACjD","sourcesContent":[".bg-resource{\r\n    background: rgb(50, 50, 100) !important;;\r\n}\r\n.text-blue{\r\n    color: #02a0c0 !important;;\r\n}\r\n.list-group-item {\r\n    padding: 5px 10px !important;\r\n}\r\n\r\n.btn-lst {\r\n    padding: 4px 4px !important;\r\n    line-height: 0 !important;\r\n}\r\n.show>.btn-primary.dropdown-toggle {\r\n    color: #fff;\r\n    background-color: #02a0c0 !important;\r\n    border-color: #02a0c0 !important;\r\n}\r\n.btn-primary {\r\n    color: #fff;\r\n    background-color: rgb(50, 50, 100) !important;\r\n    border-color: rgb(50, 50, 100) !important;\r\n}\r\n.btn-primary:hover {\r\n    color: #fff;\r\n    background-color: rgb(50, 50, 100) !important;\r\n    border-color: rgb(50, 50, 100) !important;\r\n}\r\nsection.dark.page-header {\r\n    color: #fff;\r\n    background-color: rgb(50, 50, 100) !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
