import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SessionDetails } from '../../../utility/session';
import { OpportunitiesRequiringActionRequest, OpportunitiesRequiringActionReportsRequest, OppourtunityReport } from '../../../models/Chart';


@Injectable()
export class ChartService {
    constructor(private http: HttpClient) {}    
    getOpportunitiesRequiringAction(requestData: OpportunitiesRequiringActionRequest){
        let url = `${environment.API_URL}/${environment.RESOURCES.GET_OPPORTUNITIES_REQUIRING_ACTION}/${requestData.userId}/${requestData.outreachEffortId} `;
        return this.http.get(url, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
    }

    getPriorityOpportunitiesRequiringAction(requestData: OpportunitiesRequiringActionRequest){
        let url = `${environment.API_URL}/${environment.RESOURCES.GET_PRIORITY_OPPORTUNITIES_REQUIRING_ACTION}/${requestData.userId}/${requestData.outreachEffortId} `;
        return this.http.get(url, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
    }

    getOpportunitiesRequiringActionReports(requestData: OpportunitiesRequiringActionReportsRequest){
        let url = `${environment.API_URL}/${environment.RESOURCES.GET_OPPORTUNITIES_REQUIRING_ACTION_REPORTS}/${requestData.reportId}/${requestData.reportType}/${requestData.userId}/${requestData.outreachEffortId} `;
        return this.http.get(url, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
    }

    getPriorityOpportunitiesRequiringActionReports(requestData: OpportunitiesRequiringActionReportsRequest){
        let url = `${environment.API_URL}/${environment.RESOURCES.GET_PRIORITY_OPPORTUNITIES_REQUIRING_ACTION_REPORTS}/${requestData.reportId}/${requestData.reportType}/${requestData.userId}/${requestData.outreachEffortId} `;
        return this.http.get(url, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
    }

    downloadOppourtunityReportData(requestData: OppourtunityReport){
        let url = `${environment.API_URL}/${environment.RESOURCES.DOWNLOAD_OPPOURTUNITY_REPORT}`;
        // let req_headers = Util.getRequestHeadersV2();//Added this is causing issue if we use directly so temp fix //TODO
        return this.http.post(url, requestData, Util.getRequestHeadersV2()).pipe(catchError(Util.handleErrorV2));
    }

    downloadPriorityOppourtunityReportData(requestData: OppourtunityReport){
        let url = `${environment.API_URL}/${environment.RESOURCES.DOWNLOAD_PRIORITY_OPPOURTUNITY_REPORT}`;
        // let req_headers = Util.getRequestHeadersV2();//Added this is causing issue if we use directly so temp fix //TODO
        return this.http.post(url, requestData, Util.getRequestHeadersV2()).pipe(catchError(Util.handleErrorV2))
    }

    getOppourtuntiyReportTypeOureachandFollowUpCount(requestData: OpportunitiesRequiringActionReportsRequest){
        let url = `${environment.API_URL}/${environment.RESOURCES.GET_OPPOURTUNITY_REPORT_OUTREACH_AND_FOLLOW_UP_COUNT}/${requestData.userId}/${requestData.reportId}/${requestData.outreachEffortId}/${requestData.reportType.length ? requestData.reportType: 'none'}`;
        return this.http.get(url, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
    }

}