export class ClinicList {
    clinicPk: number;
    clinicType: string;
    storeId: number;
    oldStoreId: number;
    isCanceled: number;
    dateAdded: Date;
    latitude: number;
    longitude: number;
    locationId: number;
}

export class ErrorS {
    errorCode: number;
    errorMessage: string;
}

export class ClinicAssignment {
    totalS: number;
    userPk: number;
    assignedTo: number;
    updateAction: number;
    clinicList: ClinicList[];
    errorS: ErrorS;
}

