import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LeadAwaitingRequest, ReassignLeadToClosestStore } from '../../../../app/models/leadAwaitingResponse';
import { Observable } from 'rxjs';


@Injectable()
export class LeadAwaitingResponseService {
    constructor(private http: HttpClient) {}

    getLeadAwaitingResponseList(requestData: LeadAwaitingRequest): Observable<any> {
        let url = environment.API_URL + environment.RESOURCES.LEAD_AWAITING_RESPONSE_OVER_THREE_DAYS ;
        return this.http.post(url, requestData, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
    }

    getLeadAwaitingResponseById(userId: number, id: number): Observable<any> {
        let url = `${environment.API_URL}${environment.RESOURCES.LEAD_AWAITING_RESPONSE_BY_ID}?id=${id}&userId=${userId}`;
        return this.http.get(url, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
    }

    reassignLeadAwaitingResponse(requestData: ReassignLeadToClosestStore){
        let url = `${environment.API_URL}${environment.RESOURCES.REASSIGN_CLOSEST_STOREBY_ID}`;
        return this.http.post(url, requestData, Util.getRequestHeadersV1())
        .pipe(catchError(Util.handleErrorV2));
    }
}