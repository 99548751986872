// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-msg {
    color: red;
  }
   .has-error label{
    color:red!important;
    }
    .has-error input{
    border:1px solid red !important;
    } 
    .has-error select{
    border:1px solid red !important;
    } 
    .ui-confirmdialog-message {
        white-space: pre-line;
    }
    .btn-teal {
        background-color: #2a7db5;
        color: #FFF !important;
    }
  
    .immunizationCloseBtn{
    padding-top: 0px;
    padding-left: 3px;
    padding-right: 3px;
    height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/contractaggreement/components/immunizations/immunizations.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;EACZ;GACC;IACC,mBAAmB;IACnB;IACA;IACA,+BAA+B;IAC/B;IACA;IACA,+BAA+B;IAC/B;IACA;QACI,qBAAqB;IACzB;IACA;QACI,yBAAyB;QACzB,sBAAsB;IAC1B;;IAEA;IACA,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".error-msg {\r\n    color: red;\r\n  }\r\n   .has-error label{\r\n    color:red!important;\r\n    }\r\n    .has-error input{\r\n    border:1px solid red !important;\r\n    } \r\n    .has-error select{\r\n    border:1px solid red !important;\r\n    } \r\n    .ui-confirmdialog-message {\r\n        white-space: pre-line;\r\n    }\r\n    .btn-teal {\r\n        background-color: #2a7db5;\r\n        color: #FFF !important;\r\n    }\r\n  \r\n    .immunizationCloseBtn{\r\n    padding-top: 0px;\r\n    padding-left: 3px;\r\n    padding-right: 3px;\r\n    height: 18px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
