import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Util } from '../../../../utility/util';
import { DatePipe } from '@angular/common';
import { TimeToDatePipe } from '../../../contractaggreement/pipes/time-to-date.pipe';
import { PcaContractsService } from '../../services/pca-contracts.service';
import { PcaImmunization, PcaContract, PcaEvent, PcaEventSchedule,PCACancerMetrics } from '../../../../models/pcaclinic';
import { SessionDetails } from '../../../../utility/session';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { String } from 'typescript-string-operations';
import { isatty } from 'tty';

@Component({
  selector: 'app-pca-location-list',
  templateUrl: './pca-location-list.component.html',
  styleUrls: ['./pca-location-list.component.css'],
  providers: [ TimeToDatePipe, DatePipe]
})
export class PcaLocationListComponent implements OnInit {
  contractForm:FormGroup;
  public addedImmunization: Array<PcaImmunization>;
  public contractData:PcaContract  = new PcaContract() ;
  location_number:number =0;
  locListErrModalDlg: boolean = false;
  locListModalDlg: boolean = false;
  dialogSummary: string;
  dialogMsg: string;
  isDateTimeConflictPresent = false;
  @Input('outReachProgramType')
  outReachProgramType: number;
  @Input('locationName')
  locationName: string;
  locationRequirementLabel:string ="";
  locationRequirementLabel1:string ="";
  locationRequirementLabel2:string ="";
  disableAddEvents:boolean=false;
  user_data: any;
  cancerMetrics: PCACancerMetrics[]=[];

  constructor(private _fb:FormBuilder,private pcaContractService: PcaContractsService,
    private _timeToDatePipe: TimeToDatePipe,
    private _datePipe: DatePipe,  private utility: Util) { }

  ngOnInit() {
    this.contractData = this.pcaContractService.fetchPcaContractAgreementData();
    let profile_data = SessionDetails.getStoreProfileData();
    this.user_data = SessionDetails.getUserProfileData();

    if(this.outReachProgramType==12){
      this.pcaContractService.getTotalCancelEvents(profile_data[0].storeId,this.contractData.clinicAgreementPk).subscribe(data=>{
        SessionDetails.SetTotalCancerEvents(data);
      },
      error=>{
        console.log(error);
      });
    }
  
    if(this.outReachProgramType==12){
      this.locationRequirementLabel = String.Format("Store {0} has a dedicated parking lot that can accomodate a mobile cancer screening unit.",
      this.contractData.pcaClinic.storeId != null && this.contractData.pcaClinic.storeId != undefined && this.contractData.pcaClinic.storeId != ""? this.contractData.pcaClinic.storeId:profile_data[0].storeId );
    }
    else  if(this.outReachProgramType==8 || this.outReachProgramType==10){
      this.locationRequirementLabel  =   String.Format("Store {0} has a dedicated parking lot that can accomodate a mobile cancer screening unit.",this.contractData.pcaClinic.storeId != null && this.contractData.pcaClinic.storeId != undefined && this.contractData.pcaClinic.storeId != ""? this.contractData.pcaClinic.storeId:profile_data[0].storeId  );
      this.locationRequirementLabel1 =   String.Format("Store {0} has a dedicated private health room or modular private health room that can accommodate free testing & counseling services.",this.contractData.pcaClinic.storeId != null && this.contractData.pcaClinic.storeId != undefined && this.contractData.pcaClinic.storeId != ""? this.contractData.pcaClinic.storeId:profile_data[0].storeId  );
      this.locationRequirementLabel2 =   String.Format("Store {0} has a dedicated parking lot that can accommodate a mobile testing unit that the testing partner will be parking on-site.",this.contractData.pcaClinic.storeId != null && this.contractData.pcaClinic.storeId != undefined && this.contractData.pcaClinic.storeId != ""? this.contractData.pcaClinic.storeId:profile_data[0].storeId  );
    }
    
    if(this.contractData.isLocationReqAvailable == 0 || this.contractData.isLocationReqAvailable == undefined){
      this.contractData.isLocationReqAvailable = null;
    }
    if(this.contractData.isHealthRoomAvailable == 0 || this.contractData.isHealthRoomAvailable== undefined){
      this.contractData.isHealthRoomAvailable = null;
    }
    
    this.contractForm = this._fb.group({
      contactFirstName: this._fb.control(this.contractData.pcaClinic.contactFirstName?this.contractData.pcaClinic.contactFirstName.trim():"", [Validators.required]),
      contactLastName: this._fb.control(this.contractData.pcaClinic.contactLastName? this.contractData.pcaClinic.contactLastName.trim():"", [Validators.required]),
      jobTitle: this._fb.control(this.contractData.pcaClinic.jobTitle?this.contractData.pcaClinic.jobTitle.trim():"", [Validators.required]),
      contactPhone: this._fb.control(this.contractData.pcaClinic.contactPhone, [Validators.required]),
      contactEmail: this._fb.control(this.contractData.pcaClinic.contactEmail?this.contractData.pcaClinic.contactEmail.trim():"" , [Validators.required]),
      address1: this._fb.control(this.contractData.pcaClinic.address1 !== null && this.contractData.pcaClinic.address1 !== undefined && this.contractData.pcaClinic.address1 !== "" ? this.contractData.pcaClinic.address1:profile_data[0].address, [Validators.required]),
      city: this._fb.control(this.contractData.pcaClinic.city !== null && this.contractData.pcaClinic.city !==undefined && this.contractData.pcaClinic.city !=="" ? this.contractData.pcaClinic.city: profile_data[0].city , [Validators.required]),
      zipCode: this._fb.control(this.contractData.pcaClinic.zipCode !== null && this.contractData.pcaClinic.zipCode !== undefined && this.contractData.pcaClinic.zipCode !== "" ? this.contractData.pcaClinic.zipCode : profile_data[0].zip, [Validators.required]),
      state: this._fb.control(this.contractData.pcaClinic.state !== null && this.contractData.pcaClinic.state !== undefined && this.contractData.pcaClinic.state !== "" ? this.contractData.pcaClinic.state: profile_data[0].state, [Validators.required]),
      storeId: this._fb.control(this.contractData.pcaClinic.storeId !== null && this.contractData.pcaClinic.storeId !== undefined && this.contractData.pcaClinic.storeId !== ""? this.contractData.pcaClinic.storeId: profile_data[0].storeId, [Validators.required]),
      isLocationReqAvailable: this._fb.control(this.contractData.isLocationReqAvailable, this.outReachProgramType===12 ? [Validators.required]:null),
      isHealthRoomAvailable: this._fb.control(this.contractData.isHealthRoomAvailable, (this.outReachProgramType===8 || this.outReachProgramType==10) && (this.contractData.isLocationReqAvailable ===0 || this.contractData.isLocationReqAvailable === undefined || this.contractData.isLocationReqAvailable === null) ? [Validators.required]:null),
      locations: this._fb.array([])
  });
  
    //work around to fill the input mask fields
    setTimeout(() => {
      const maskedInputControl = this.contractForm.get('contactPhone') as FormControl;
      maskedInputControl.setValue(maskedInputControl.value);
    });
    
  this.addedImmunization = this.contractData.immunization;
  if(this.contractData.pcaEvenScheduleList.length > 0)
  {    
    this.contractData.pcaEvenScheduleList.forEach(element=>{
      let pcaEventSchedule= new PcaEventSchedule();
      pcaEventSchedule.eventDate = element.eventDate;
      try{
      pcaEventSchedule.endTime = this._timeToDatePipe.transform(new Date(element.eventDate), element.endTime);
      pcaEventSchedule.startTime = this._timeToDatePipe.transform(new Date(element.eventDate),element.startTime );
      }
      catch(e){
        pcaEventSchedule.endTime = element.endTime;
        pcaEventSchedule.startTime = element.startTime;
      }
      
      pcaEventSchedule.estimatedQuantity = element.estimatedQuantity;
      pcaEventSchedule.location = element.location;     
      this.addLocation(pcaEventSchedule);
    });
  }
  else {    
  this.addLocation(undefined);
  }
  }

  addLocation(location){
    if(this.outReachProgramType === 12)
      this.diableEventsButton(true);
    if(!this.disableAddEvents){
      const control = <FormArray>this.contractForm.controls['locations'];
      const addrCtrl = this.initLocation(location);
      control.push(addrCtrl);
      this.pcaContractService.setValidationsRequiredStatus(true);
    }
  }
  initLocation(eventSchdule: PcaEventSchedule){
    this.location_number++;    
    if (eventSchdule === undefined) {
      eventSchdule = new PcaEventSchedule();
  
      eventSchdule.location = String.Format("{0} {1}", this.locationName, this.location_number);      
      return this._fb.group({
        location: this._fb.control(eventSchdule.location),
        immunizationPk: this._fb.control(this.contractData.immunization?.[0]?.immunizationPk), //future if change add multiple take care
        immunizationName: this._fb.control(this.contractData.immunization?.[0]?.immunizationName),//future if change add multiple take care
        estimatedQuantity: this._fb.control(eventSchdule.estimatedQuantity, [Validators.required]),     
        eventDate: this._fb.control((eventSchdule.eventDate === null || eventSchdule.eventDate === undefined || eventSchdule.eventDate === "" ) ? "":new Date( eventSchdule.eventDate ), [Validators.required]),
        startTime: this._fb.control(eventSchdule.startTime, [Validators.required]),
        endTime: this._fb.control(eventSchdule.endTime, [Validators.required])
      });
    }
    else {
      
      return this._fb.group({
        location: this._fb.control(eventSchdule.location),
        immunizationPk: this._fb.control(this.contractData.immunization?.[0]?.immunizationPk, [Validators.required]), //future if change add multiple take care
        immunizationName: this._fb.control(this.contractData.immunization?.[0]?.immunizationName, [Validators.required]), //future if change add multiple take care
        estimatedQuantity: this._fb.control(eventSchdule.estimatedQuantity, [Validators.required]),     
        eventDate: this._fb.control((eventSchdule.eventDate === null || eventSchdule.eventDate === undefined || eventSchdule.eventDate === "" ) ? "":new Date( eventSchdule.eventDate ), [Validators.required]),
        startTime: this._fb.control(eventSchdule.startTime, [Validators.required]),
        endTime: this._fb.control(eventSchdule.endTime, [Validators.required])
      });
    }
  }
  removeLocation(i: number) {
    let control = <FormArray>this.contractForm.controls['locations'];
    control.removeAt(i);
    this.location_number--;

    for (let index = 0; index < this.location_number; index++) {
        let fGrp: FormGroup = control.controls[index] as FormGroup;
        fGrp.controls['location'].setValue(String.Format("{0} {1}", this.locationName, index + 1));
    }
    this.pcaContractService.setValidationsRequiredStatus(true);
    if(this.outReachProgramType === 12)
      this.diableEventsButton(false);
}
displayFieldCss(field: string) {
  return {
    'has-error': this.isFieldValid(field),
    'has-feedback': this.isFieldValid(field)
  };
}
isFieldValid(field: string) {
  return !this.contractForm.get(field).valid && this.contractForm.get(field).touched;
}

isFormValid(): boolean {
  let frmar: FormArray = this.contractForm.get('locations') as FormArray;
  for (let index = 0; index < frmar.length; index++) {
      const element = frmar.controls[index] as FormGroup;
      this.utility.validateAllFormFields(element);
  }
  this.utility.validateAllFormFields(this.contractForm);
  return this.contractForm.valid;
}

isLocationReqAvailableChanged(checked){  
  if(!checked){
    this.contractForm.controls['isHealthRoomAvailable'].setValidators([Validators.required]);    
  }
  else {
    this.contractForm.controls['isHealthRoomAvailable'].setValidators(null);
  }
  this.contractForm.controls['isHealthRoomAvailable'].updateValueAndValidity();
  this.contractForm.updateValueAndValidity();
}
save(outReachPrgType?: string): boolean {
  if((this.outReachProgramType==8 || this.outReachProgramType==10 )&&  this.contractForm.value.isLocationReqAvailable == true){
    var isHealthRoomAvailableCtrl = this.contractForm.controls['isHealthRoomAvailable'];
    isHealthRoomAvailableCtrl.setValidators(null);;
    isHealthRoomAvailableCtrl.updateValueAndValidity();
    this.contractForm.updateValueAndValidity();
  }
  if (this.isFormValid()) {
    try{
      var locConflictList = [];
      var errMsgV1: string[] = [];
      for (let count = 0; count < this.contractForm.value.locations.length; count++) {
        try{
          let locEventDate:Date = new Date(this.contractForm.value.locations[count].eventDate);
          let locStartTime = new Date(locEventDate.getFullYear(), locEventDate.getMonth(), locEventDate.getDate(), this.contractForm.value.locations[count].startTime.getHours(), this.contractForm.value.locations[count].startTime.getMinutes(),0);
          
          let locEndTime = new Date(locEventDate.getFullYear(), locEventDate.getMonth(), locEventDate.getDate(), this.contractForm.value.locations[count].endTime.getHours(), this.contractForm.value.locations[count].endTime.getMinutes(),0);
          let locationsCpy = JSON.parse(JSON.stringify(this.contractForm.value.locations));
          var index = locationsCpy.findIndex(it => it.location === this.contractForm.value.locations[count].location);
                    if (index > -1) {
                        locationsCpy.splice(index, 1);
                    }
                    locationsCpy.forEach(element => {
                      element.eventDate = new Date(element.eventDate);
                      element.startTime = new Date(element.eventDate.getFullYear(), element.eventDate.getMonth(), element.eventDate.getDate(), new Date(element.startTime).getHours(),new Date(element.startTime).getMinutes(),0);
                      element.endTime = new Date(element.eventDate.getFullYear(), element.eventDate.getMonth(), element.eventDate.getDate(), new Date(element.endTime).getHours(),new Date(element.endTime).getMinutes(),0);
                  });
                  let conflictLocs = locationsCpy.filter(locItem => locEventDate.getFullYear() === locItem.eventDate.getFullYear() && locEventDate.getMonth() === locItem.eventDate.getMonth() && locEventDate.getDate() === locItem.eventDate.getDate() );
                    let endDateAdd39Mts: Date;
                    if (locEndTime.toString().toLowerCase().search('am') !== -1 || locEndTime.toString().toLowerCase().search('pm') !== -1) {
                        endDateAdd39Mts = this._timeToDatePipe.transform(locEventDate, locEndTime);
                    }
                    else {
                        endDateAdd39Mts = locEndTime;
                    }
                    let startDatetmToDt: Date;
                    if (locStartTime.toString().toLowerCase().search('am') !== -1 || locStartTime.toString().toLowerCase().search('pm') !== -1) {
                        startDatetmToDt = this._timeToDatePipe.transform(locEventDate, locStartTime);
                    }
                    else {
                        startDatetmToDt = locStartTime;
                    }
                    let add30MinTS: Date = new Date(locEventDate.getFullYear(), locEventDate.getMonth(), locEventDate.getDate(), endDateAdd39Mts.getHours(), endDateAdd39Mts.getMinutes() + 30, endDateAdd39Mts.getSeconds());
                    let locStart: Date = new Date(startDatetmToDt);

                    if (conflictLocs.length > 0) {
                        var conflictLocsCpy = JSON.parse(JSON.stringify(conflictLocs));
                        conflictLocsCpy.forEach(element => {
                            element.eventDate = new Date(element.eventDate);
                            element.startTime = new Date(element.eventDate.getFullYear(), element.eventDate.getMonth(), element.eventDate.getDate(), new Date(element.startTime).getHours(),new Date(element.startTime).getMinutes(),0);
                            element.endTime = new Date(element.eventDate.getFullYear(), element.eventDate.getMonth(), element.eventDate.getDate(), new Date(element.endTime).getHours(),new Date(element.endTime).getMinutes(),0);
                              });
                        
                        conflictLocs = conflictLocsCpy.filter(locItem => locEventDate.getFullYear() === locItem.eventDate.getFullYear() && locEventDate.getMonth() === locItem.eventDate.getMonth() && locEventDate.getDate() === locItem.eventDate.getDate() && ((locItem.startTime.valueOf() >= locStart.valueOf() && locItem.startTime.valueOf() < add30MinTS.valueOf()) || (locItem.endTime.valueOf() > locStart.valueOf() && locItem.endTime.valueOf() < add30MinTS.valueOf())));
                        if (conflictLocs.length > 0) {
                            locConflictList.push({
                                srcLocName: this.contractForm.value.locations[count],
                                dstLocName: conflictLocs
                            });
                        }
                    }
        } catch(e){

        }
      }
      let conflictClinicList: any[] = [];
            locConflictList.forEach(item => {

                item.dstLocName.forEach(element => {
                    let duplicateEntry = conflictClinicList.filter(l => l.location == element.location);
                    if (duplicateEntry.length <= 0) {
                        conflictClinicList.push(element);
                    }
                });

            });
     
            if (conflictClinicList.length > 0) {
                conflictClinicList.sort((leftItem,rightItem):number =>{
                    if(leftItem.location < rightItem.location ) return -1;
                    if(leftItem.location > rightItem.location ) return 1;
                    return 0;
                  });
                  
                if (conflictClinicList.length <= 2 && this.contractForm.value.locations.length <= 2) {
                    if(conflictClinicList.length === 2){
                    errMsgV1.push(String.Format(ErrorMessages['eventLocDateTimeSingleConflict'], conflictClinicList[1].location, this._datePipe.transform(conflictClinicList[0].eventDate, "shortDate"),  this._datePipe.transform(conflictClinicList[0].startTime, 'shortTime'),  this._datePipe.transform(conflictClinicList[0].endTime,'shortTime'), SessionDetails.GetopportunitiesData().businessName));
                    } else {
                        errMsgV1.push(String.Format(ErrorMessages['eventLocDateTimeSingleConflict'], conflictClinicList[0].location, this._datePipe.transform(conflictClinicList[0].eventDate, "shortDate"),  this._datePipe.transform(conflictClinicList[0].startTime, 'shortTime'),  this._datePipe.transform(conflictClinicList[0].endTime,'shortTime'), SessionDetails.GetopportunitiesData().businessName));
                    }
                    this.showDialog(ErrorMessages['resolve'], errMsgV1.join('<br/><br/>'));
                    this.isDateTimeConflictPresent = true;
                    return false;
                }
                if (conflictClinicList.length >= 1) {
                  errMsgV1 =[];                      
                    conflictClinicList.forEach(loc => {
                        errMsgV1.push(String.Format(ErrorMessages['eventLocDateTimeMultipleConflict'], loc.location, this._datePipe.transform(loc.eventDate, "shortDate"),  this._datePipe.transform(loc.startTime,'shortTime'),  this._datePipe.transform(loc.endTime,'shortTime'), SessionDetails.GetopportunitiesData().businessName));
                    });
                    if(conflictClinicList.length <=1){
                      errMsgV1 =[];                      
                      errMsgV1.push(String.Format(ErrorMessages['eventLocDateTimeSingleConflict'], conflictClinicList[0].location, this._datePipe.transform(conflictClinicList[0].eventDate, "shortDate"),  this._datePipe.transform(conflictClinicList[0].startTime, 'shortTime'),  this._datePipe.transform(conflictClinicList[0].endTime,'shortTime'), SessionDetails.GetopportunitiesData().businessName));
                      this.showDialog(ErrorMessages['resolve'], errMsgV1.join('<br/><br/>'));
                    }
                    else {
                    this.showDialog(ErrorMessages['resolve'], String.Format("{0}{1}", ErrorMessages['multipleEventsDatesConflictLineItem'], errMsgV1.join('<br/><br/>')));
                    }
                    this.isDateTimeConflictPresent = true;
                    return false;
                }
            }
    }catch (e) {                
      console.log(e);
    }
    if(this.outReachProgramType === 12 && !this.contractForm.value.isLocationReqAvailable){
      this.contractForm.value.isLocationReqAvailable = null;
      this.contractData.isLocationReqAvailable = null;
      this.contractForm.updateValueAndValidity();
      this.showDialog(ErrorMessages["locationRequirementsHeading"],ErrorMessages["locationRequirements"])

      return false;
    }
    if((this.outReachProgramType==8 || this.outReachProgramType==10 )&&  !this.contractForm.value.isHealthRoomAvailable 
      &&  !this.contractForm.value.isLocationReqAvailable){
      this.contractForm.value.isLocationReqAvailable = null;
      this.contractData.isLocationReqAvailable = null;
      this.contractForm.value.isHealthRoomAvailable = null;
      this.contractData.isHealthRoomAvailable = null;
      this.contractForm.updateValueAndValidity();
      this.showDialog(ErrorMessages["locationRequirementsHeading"],ErrorMessages["locationHealthRoomRequirements"]);

      return false;
    }
    this.pcaContractService.setTestingLocation(this.contractForm.value);
    this.pcaContractService.setValidationsRequiredStatus(false);
    return true;
  }
  
  if(this.outReachProgramType ===12 && !this.contractForm.value.isLocationReqAvailable){
    this.showDialog(ErrorMessages["locationRequirementsHeading"],ErrorMessages["locationRequirements"])
    return false;
  }
  if((this.outReachProgramType==8 || this.outReachProgramType==10 )&&  !this.contractForm.value.isHealthRoomAvailable 
  &&  !this.contractForm.value.isLocationReqAvailable){
  this.contractForm.value.isLocationReqAvailable = null;
  this.contractData.isLocationReqAvailable = null;
  this.contractForm.value.isHealthRoomAvailable = null;
  this.contractData.isHealthRoomAvailable = null;
  this.contractForm.updateValueAndValidity();
  this.showDialog(ErrorMessages["locationRequirementsHeading"],ErrorMessages["locationHealthRoomRequirements"]);

  return false;
}
  return false;
}
isClinicDateReminderBefore2WksReq(){
  if(!this.contractForm.valid){
    return false;
  }
  return this.utility.isClinicDateReminderBefore2WksReq(this.contractForm);
}
showDialog(msgSummary: string, msgDetail: string) {
  this.dialogMsg = msgDetail;
  this.dialogSummary = msgSummary;
  this.locListErrModalDlg = true;
}
closeErrModalDlg() {
  this.locListErrModalDlg = false;
}
simpleSave(): boolean {
  if(!this.isFormValidForSimpleSave()) {
       return false;
    }
    this.pcaContractService.setTestingLocation(this.contractForm.value);
    return true;
  }
  isFormValidForSimpleSave():boolean {
    let frmar: FormArray = this.contractForm.get('locations') as FormArray;
    let isFormValid:boolean = true;
    for (let index = 0; index < frmar.length; index++) {
        const element = frmar.controls[index] as FormGroup;
        isFormValid = this.utility.validateFormGroupForSimpleSave(element);
    }
    isFormValid = this.utility.validateFormGroupForSimpleSave(this.contractForm);
    //this.utility.validateAllFormFields(this.contractForm);
    return isFormValid ;//&& this.contractForm.valid;
}
getFormData(pcaObject:PcaContract){
  pcaObject.pcaClinic = new PcaEvent();
  var locForm = this.contractForm.value;
  
  pcaObject.pcaClinic.contactFirstName =locForm.contactFirstName;
  pcaObject.pcaClinic.contactLastName =locForm.contactLastName;
  pcaObject.pcaClinic.jobTitle =locForm.jobTitle;
  pcaObject.pcaClinic.contactPhone =locForm.contactPhone;
  pcaObject.pcaClinic.contactEmail =locForm.contactEmail;
  pcaObject.pcaClinic.address1 = locForm.address1;
  pcaObject.pcaClinic.city =locForm.city;
  pcaObject.pcaClinic.state =locForm.state;
  pcaObject.pcaClinic.zipCode =locForm.zipCode;
  pcaObject.pcaClinic.storeId =locForm.storeId;
  pcaObject.pcaClinic.immunization = locForm.locations;
  // pcaObject.pcaClinic.immunizationPk = locForm.locations[0].immunizationPk;
  pcaObject.pcaEvenScheduleList = [];
  locForm.locations.forEach(element => {
    let pcaEventSchedule = new PcaEventSchedule();
    pcaEventSchedule.eventDate = element.clinicDate!== null && element.clinicDate !== undefined ? JSON.parse(element.clinicDate): null;
    pcaEventSchedule.startTime = element.startTime!== null && element.startTime !== undefined ? JSON.parse(element.startTime):null;
    pcaEventSchedule.endTime =  element.endTime!== null && element.endTime !== undefined ?  JSON.parse(element.endTime): null;
    pcaEventSchedule.estimatedQuantity = element.estimatedQuantity;
    if(pcaEventSchedule.estimatedQuantity || pcaEventSchedule.estimatedQuantity === 0){
    pcaEventSchedule.location = element.location;
    }
    else {
      pcaEventSchedule.location = null;
    }
    pcaObject.pcaEvenScheduleList.push(pcaEventSchedule);
  });
}
diableEventsButton(isAdd:boolean){
  var userProfile = SessionDetails.GetUserProfile();
  //
  var totalNewEvents=0;
  for (let count = 0; count < this.contractForm.value.locations.length; count++) {
    if(new Date(this.contractForm.value.locations[count].eventDate).getFullYear()=== new Date().getFullYear()){
      totalNewEvents+=1;
    }
  }
  if(userProfile.userRole.toLowerCase()!== "admin"){
    if(totalNewEvents>=2) this.disableAddEvents=true;
    else this.disableAddEvents=false;
  }
}
calcualteNewEventsinCurrentYear(){
  var currentYearEvents=0;
  var nextYearEvents=0
  var schedulledCurrentYearEvents=0;
  var schedulledNextYearEvents=0;
  var events=[];
  
  var thisYear=new Date().getFullYear();  
  // for(let count=0;count<this.contractData.pcaEvenScheduleList.length;count++){
  //   if(new Date(this.contractData.pcaEvenScheduleList[count].eventDate).getFullYear()==thisYear){
  //     schedulledCurrentYearEvents+=1;
  //     events.push(this.contractData.pcaEvenScheduleList[count].location);
  //   }
  //   else if(new Date(this.contractData.pcaEvenScheduleList[count].eventDate).getFullYear()==thisYear+1){
  //     schedulledNextYearEvents+=1;
  //     events.push(this.contractData.pcaEvenScheduleList[count].location);
  //   }
  // }
  for (let count = 0; count < this.contractForm.value.locations.length; count++) {
   // if(events.findIndex(q=> q === this.contractForm.value.locations[count].location)=== -1){
      if(new Date(this.contractForm.value.locations[count].eventDate).getFullYear()=== thisYear){
        currentYearEvents+=1;
      }
      else if(new Date(this.contractForm.value.locations[count].eventDate).getFullYear()=== thisYear+1){
        nextYearEvents+=1;
      }
    //}
  }
  this.cancerMetrics=[];
  var pcaMetric=new PCACancerMetrics();
  pcaMetric.EventCount=currentYearEvents+schedulledCurrentYearEvents;
  pcaMetric.Year=thisYear;
  this.cancerMetrics.push(pcaMetric);

  pcaMetric=new PCACancerMetrics();
  pcaMetric.EventCount=nextYearEvents+schedulledNextYearEvents;
  pcaMetric.Year=thisYear+1;
  this.cancerMetrics.push(pcaMetric);
  return this.cancerMetrics;
}
isStartTimeEndTimeValid(){
  
  let isValidCode: Number = 0;
  try {
      this.contractForm.value.locations.forEach(loc => {
          try {
              if (loc.startTime != "")
                  try{
                  loc.startTime = this._datePipe.transform(loc.startTime, 'shortTime');
                  }catch(e){
                      console.log(e);        
                  }
              if (loc.endTime != "") {
                  try{
                  loc.endTime = this._datePipe.transform(loc.endTime, 'shortTime');
              }catch(e){
                  console.log(e);        
              }
              }
          } catch (e) {
              console.log(e); // here the exception is delibarately caught because when you click back, startTime & end
              // Time is not correct timeformat. Hence we are ignoring this by catching here.
          }
      });
      let today = new Date();
      for (let count = 0; count < this.contractForm.value.locations.length; count++) {
          
          let locClinicDate:Date = new Date(this.contractForm.value.locations[count].eventDate);
                  let locStartTime = this.contractForm.value.locations[count].startTime;
                  let locEndTime = this.contractForm.value.locations[count].endTime;
                  // if (this.contractForm.value.locations[count].isNoClinic >= 1 || 
                  //     this.contractForm.value.locations[count].isNoClinic === '1' || 
                  //     this.contractForm.value.locations[count].isReassign >= 1 || 
                  //     this.contractForm.value.locations[count].isReassign === '1') {
                  //     continue;
                  // }
                  let endDate: Date;
                  if(locEndTime!=null){
                  if (locEndTime.toString().toLowerCase().search('am') !== -1 || locEndTime.toString().toLowerCase().search('pm') !== -1) {
                      endDate = this._timeToDatePipe.transform(locClinicDate, locEndTime);
                  }
                  else {
                      endDate = locEndTime;
                  }
                }
                  let startDatetmToDt: Date;
                  if(locStartTime!=null){
                  if (locStartTime.toString().toLowerCase().search('am') !== -1 || locStartTime.toString().toLowerCase().search('pm') !== -1) {
                      startDatetmToDt = this._timeToDatePipe.transform(locClinicDate, locStartTime);
                  }
                  else {
                      startDatetmToDt = locStartTime;
                  }
                }
                  let locStart: Date = new Date(startDatetmToDt);
                  let locEnd: Date = new Date(endDate);
                  if(locEnd.valueOf()-locStart.valueOf()<= 0 ){
                      isValidCode= 2;
                      break;
                  }
                  else if(locEnd.valueOf()-locStart.valueOf()> 28800000 ){                                       
                      isValidCode = 1;
                      break;
                  }
                  else {
                      isValidCode = 0;
                  }
      }
  }
  catch (e) {
      return 3;
  }
  return isValidCode;
}
}
