// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-inputswitch:not(.ui-inputswitch-checked)::after {
	transition: color 0.5s;
	font-size: 0.9em;
	color: red !important;
	content: 'Nein';
	position: absolute;
	left: 26px;
	top: 6px;
}
.ui-inputswitch.ui-inputswitch-checked::after {
	transition: color 0.5s;
	font-size: 0.9em;
	color: red !important;
	content: 'Ja';
	position: absolute;
	left: 12px;
	top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/common/components/footer/footer.component.css"],"names":[],"mappings":"AAAA;CAEC,sBAAsB;CACtB,gBAAgB;CAChB,qBAAqB;CACrB,eAAe;CACf,kBAAkB;CAClB,UAAU;CACV,QAAQ;AACT;AACA;CAEC,sBAAsB;CACtB,gBAAgB;CAChB,qBAAqB;CACrB,aAAa;CACb,kBAAkB;CAClB,UAAU;CACV,QAAQ;AACT","sourcesContent":[".ui-inputswitch:not(.ui-inputswitch-checked)::after {\r\n\t-webkit-transition: color 0.5s;\r\n\ttransition: color 0.5s;\r\n\tfont-size: 0.9em;\r\n\tcolor: red !important;\r\n\tcontent: 'Nein';\r\n\tposition: absolute;\r\n\tleft: 26px;\r\n\ttop: 6px;\r\n}\r\n.ui-inputswitch.ui-inputswitch-checked::after {\r\n\t-webkit-transition: color 0.5s;\r\n\ttransition: color 0.5s;\r\n\tfont-size: 0.9em;\r\n\tcolor: red !important;\r\n\tcontent: 'Ja';\r\n\tposition: absolute;\r\n\tleft: 12px;\r\n\ttop: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
