import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { SessionDetails } from '../../../../utility/session';
import { PcaContractsService } from '../../services/pca-contracts.service';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { Util } from '../../../../utility/util';
import { TimeToDatePipe } from '../../../contractaggreement/pipes/time-to-date.pipe';
import { PcaContract, PcaImmunization } from '../../../../models/pcaclinic';
import { Router } from '@angular/router';
import { PcacontractAgreementService } from '../../Services/pcacontract-agreement.service';
 

@Component({
  selector: 'app-pca-immunizations',
  templateUrl: './pca-immunizations.component.html',
  styleUrls: ['./pca-immunizations.component.css'],
  providers: [TimeToDatePipe]
})
export class PcaImmunizationsComponent implements OnInit {
  immunizationForm: FormGroup;
  immunizations: any[]; 
  selectedImmunization: any;
  addedImmunizations: any[] = [];
  immunization_data: any[];
  immunization_data1: any[];
  boundImmunizations:any[];
  clinic_agreement_pk;
  display: boolean = false;
  dialogSummary: string;
  dialogMsg: string;
  validateLocForm:boolean = true;
  @Input('outReachProgramType')
  outReachProgramType: number;
  @Output() clinicSubmit: EventEmitter<number> = new EventEmitter();
  @Output() initializeLula:EventEmitter<string> = new EventEmitter();
  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService
    ,private pcaContractService:PcaContractsService,  private utility: Util, private _timeToDatePipe:TimeToDatePipe,
    private router:Router, private _contractService: PcacontractAgreementService ) {
 
   }

  ngOnInit() {
    this.spinner.show();
    this.formControls();
    this.clinic_agreement_pk = SessionDetails.GetAgreementPK();
    let pcaContractData = this.pcaContractService.fetchPcaContractAgreementData();

    this.pcaContractService.getImmunizationsList( this.clinic_agreement_pk, this.outReachProgramType).subscribe((data:any)=>
    {     
      // const addedServicesList = pcaContractData.immunization.map((immunization) => immunization.immunizationPk);
      //   const hideImmunizationServicePKList = environment.hideImmunizationServicePK.filter((item) => !addedServicesList.includes(String(item)));
      //   this.boundImmunizations = this.boundImmunizations.filter((immunization) => !hideImmunizationServicePKList.includes(immunization.immunizationPk));
      // if(pcaContractData.pcaClinic== null || pcaContractData.pcaClinic==undefined)
      // {

      //   this.boundImmunizations = data.immunizationList.filter(x=>x.immunizationId!==162);
      //   this.boundImmunizations = this.boundImmunizations.filter(x=>x.immunizationId!==165);
      //   this.boundImmunizations = this.boundImmunizations.filter(x=>x.immunizationId!==168);
      //   //162,165,168
      // }

      this.boundImmunizations = data.immunizationList;
      this.immunizations = this.boundImmunizations;
      if(pcaContractData == null || pcaContractData.immunization == null || pcaContractData.immunization == undefined){      
        this.getContractAgreementData(this.clinic_agreement_pk);
        this.getProofOfInsurance(this.clinic_agreement_pk);
      }
      else {
        this.addedImmunizations =pcaContractData.immunization;
        pcaContractData.immunization.map((immunization) =>{
          let index = this.boundImmunizations.findIndex(item=> immunization.immunizationPk == item.immunizationId);
          this.boundImmunizations.splice(index, 1);
        });
      }
      this.spinner.hide();
    });    
  }
  getProofOfInsurance(clinic_agreement_pk) {
    this._contractService.GetProofOfInsurance(clinic_agreement_pk).subscribe((data:any) => {
      this._contractService.setProofInsuranceDocumentList(data);
    });
  }
  getContractAgreementData(clinic_agreement_pk) {
   // this.spinner.show();   
   this.pcaContractService.getPCAContractAgreement(clinic_agreement_pk).subscribe((data:any) => {
    data.clinicList.forEach(element=>{
      try{
        element.endTime = this._timeToDatePipe.transform(new Date(element.clinicDate), element.endTime);
        element.startTime = this._timeToDatePipe.transform(new Date(element.clinicDate),element.startTime );
        }
        catch(e){

        }
    });
    var contractData = this.pcaContractService.cacheDBContractData(data);    
     if(this.addedImmunizations.length <=0 && contractData  && 
      contractData.immunization  && contractData.immunization.length >0  ){       
       
       this.addedImmunizations = contractData.immunization;
       contractData.immunization.map((immunization) => {

          let index = this.boundImmunizations.findIndex(item=>item.immunizationId==immunization.immunizationPk);
          this.boundImmunizations.splice(index, 1);
       })
     }
    if( contractData != null && contractData != undefined && 
      contractData.immunization != null && contractData.immunization != undefined ){       
     this.pcaContractService.setPcaContractData(contractData);
      }
      if(contractData.isCurrent!=null && contractData.isCurrent!=undefined && contractData.isCurrent==0 ){
        this.router.navigate(['/invalidCotract']);
      }
      if(contractData != null && contractData != undefined && contractData.isEmailSent != null &&
        ((  contractData.isEmailSent == 1 ) || 
        ( contractData.isApproved != null || contractData.isRhdApproved != null ))){
          if(contractData.store.state){
          this.initializeLula.emit(contractData.store.state);
          } 
          else {
            let profile_data = SessionDetails.getStoreProfileData();
            if(profile_data&& profile_data.length>0){
              this.initializeLula.emit(profile_data[0].state);
            }
          }
          this.clinicSubmit.emit(4);
        }
   },
   err => {
    switch (err) {
      case 500:
        this.showDialog(ErrorMessages['serverError'], err.json().Message);
        return false;
        case 400:
        this.showDialog(ErrorMessages['clientError'], err.json().Message);
        return false;   
      default:
        this.showDialog(ErrorMessages['serverError'], err.json().Message);
        return false;
    }
   }
   );
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  isFieldValid(field: string) {
    if (this.immunizationForm.get(field) != null)
      return !this.immunizationForm.get(field).valid && this.immunizationForm.get(field).touched;
  }
  formControls() {
    this.immunizationForm = this.formBuilder.group({
      immunizationSelected: this.formBuilder.control('', this.addedImmunizations.length > 0 ? null : [Validators.required])
    })
  }
  addImmunization() {
    
    if (!this.immunizationForm.valid) {
      
      let dtlMsg;
      if(this.addedImmunizations.length>0){
      dtlMsg = ErrorMessages['AtleastPcaImmunization'];
    }
    else {
      dtlMsg = ErrorMessages['MandatoryFields'];
    }
    if (this.addedImmunizations.length <= 0) { //&& this.everGreenValue !== ''
    dtlMsg = ErrorMessages['AtleastPcaImmunization'];
  }
  let err = ErrorMessages['errMsg'];
  this.showDialog(err, dtlMsg);
  this.utility.validateAllFormFields(this.immunizationForm);
  return;
    }
    if (this.immunizationForm.controls['immunizationSelected'].value == "" ) {
      let dtlMsg = ErrorMessages['AtleastPcaImmunization'];
      let err = ErrorMessages['errMsg'];
      this.showDialog(err, dtlMsg);
      this.utility.validateAllFormFields(this.immunizationForm);
      return;
    }
    this.addimmunizationData(); // this adds the data to local DS.
  }
  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
  }
  addimmunizationData() {
    this.addedImmunizations.push({
      "immunizationPk":this.immunizationForm.controls['immunizationSelected'].value ,
      "immunizationName": this.boundImmunizations.filter(f=>f.immunizationId==this.immunizationForm.controls['immunizationSelected'].value)[0].immunizationName,
      "immunizationSpanishName": null,
      "paymentTypeId": null,
      "paymentTypeName": null,
      "paymentTypeSpanishName": null,
      "sendInvoiceTo": 0,
      "price": 0,
      "directBillPrice":null,
    });
    const ind = this.boundImmunizations.findIndex(f=>f.immunizationId==this.immunizationForm.controls['immunizationSelected'].value);
    this.boundImmunizations.splice(ind, 1);
    this.immunizationForm.controls['immunizationSelected'].updateValueAndValidity();
    this.pcaContractService.setImmunizationsData(this.addedImmunizations);
    this.clearFormFields();
  }
  clearFormFields() {
    this.immunizationForm.value.immunizationSelected = '';
    this.immunizationForm = this.formBuilder.group({
      immunizationSelected: this.formBuilder.control('', this.addedImmunizations.length > 0 ? null : [Validators.required])
    });
  }
  deleteImmunization(index: any) {
    
    let immunization = this.addedImmunizations;
    this.boundImmunizations.push({
      "immunizationId": immunization[index].immunizationPk,
      "immunizationName": immunization[index].immunizationName,
      "immunizationSpanishName": null,
    });
    this.addedImmunizations.splice(index, 1);
    this.pcaContractService.setImmunizationsData(this.addedImmunizations);
    this.immunizationForm.controls['immunizationSelected'].updateValueAndValidity();
    this.validateLocForm = true;
  }
  save(outReachPrgType?: string): boolean {
    if (this.addedImmunizations.length > 0){
      this.pcaContractService.setImmunizationsData(this.addedImmunizations);
      return true;
    }
    else {     
      let dtlMsg = ErrorMessages['MandatoryFields'];
      if (this.addedImmunizations.length <= 0) {
        dtlMsg = ErrorMessages['AtleastPcaImmunization'];
      }
      let err = ErrorMessages['errMsg'];
      this.showDialog(err, dtlMsg);
      this.utility.validateAllFormFields(this.immunizationForm);
      return false;
    }
  }
  simpleSave(outReachPrgType?: string): boolean {
    this.immunizationForm.controls['immunizationSelected'].updateValueAndValidity();
    this.pcaContractService.setImmunizationsData(this.addedImmunizations);
    return true;
  }
  checkIfAnyImmunizationsAdded(){
    if (this.addedImmunizations.length > 0){
      return true;
    } else {
      this.utility.validateAllFormFields(this.immunizationForm);
      return false;
    }
  }
  getFormData(pcaObject:PcaContract){
    if(this.immunizationForm.controls['immunizationSelected'].value != "" ){
    pcaObject.immunization = new Array<PcaImmunization>();
    pcaObject.immunization.push({
      immunizationPk: this.immunizationForm.controls['immunizationSelected'].value,
      immunizationName: this.boundImmunizations.filter(f=>f.immunizationId==this.immunizationForm.controls['immunizationSelected'].value)[0].immunizationName,
      paymentTypeId: null
    });
    // pcaObject.immunization.immunizationPk = this.immunizationForm.controls['immunizationSelected'].value ;
    // pcaObject.immunization.immunizationName =  this.boundImmunizations.filter(f=>f.immunizationId==this.immunizationForm.controls['immunizationSelected'].value)[0].immunizationName;    
    // pcaObject.immunization.paymentTypeId = null;
    }
    else if(this.addedImmunizations.length > 0){
      pcaObject.immunization = new Array<PcaImmunization>();
      this.addedImmunizations.map((immunization: PcaImmunization) => {
        pcaObject.immunization.push({
          immunizationPk: immunization.immunizationPk,
          immunizationName: immunization.immunizationName,
          paymentTypeId: null
        });
      })
      // pcaObject.immunization.immunizationPk = this.addedImmunizations[0].immunizationPk ;
      // pcaObject.immunization.immunizationName =  this.addedImmunizations[0].immunizationName;    
      // pcaObject.immunization.paymentTypeId = null;
    }
    else {
      pcaObject.immunization = null;
    }
  }
}
