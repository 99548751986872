// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#q
{
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/patientcareadvocacy/components/pca-location-list/pca-location-list.component.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;AAChB","sourcesContent":["#q\r\n{\r\n    padding: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
