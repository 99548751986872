import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PcacontractAgreementService } from '../../Services/pcacontract-agreement.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Util } from '../../../../utility/util';
import { PCAContractAgreementModel, ClientInfo } from '../../../../models/PCAContractAgreementModel';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { String } from 'typescript-string-operations';
import { environment } from '../../../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { LulaAso, LulaGov, OpoLula, CancerLula } from '../../../../models/pcaTandC';
import { SessionDetails } from '../../../../utility/session';
import { Location } from '@angular/common';
import { LulaAsoRelease2 } from '../../../../models/pcaTandC-release2';


@Component({
  selector: 'app-rhd-contract-agreement',
  templateUrl: './rhd-contract-agreement.component.html',
  styleUrls: ['./rhd-contract-agreement.component.css']
})
export class RhdContractAgreementComponent implements OnInit {

  showHints: boolean = false;
  pageName: string = "rhdcontractagreement";

  isApproved: boolean = null;
  image_source: any;
  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  firstName: string = '';
  lastName: string = '';
  titleName: string = '';
  uploadedFileName: string = '';
  PCAContractForm: FormGroup;
  approvePCAContract: FormGroup;
  files: any;
  storeAddress: any;
  storeId: any;
  apprOrRejectTextBox: boolean = false;
  agreement_model: PCAContractAgreementModel = new PCAContractAgreementModel();
  private selectedLink: string;
  date: Date;
  dialogSummary: string;
  dialogMsg: string;
  display: boolean = false;
  proofInsurances = [];
  fileExt: string[] = ["JPG", "JPEG", "PDF"];
  uploadDoc: boolean = false;
  message_text: any;
  load: boolean = false;
  isRHDApproved: boolean;
  tandC: any = new LulaAso();
  clinicType: number = null;
  uploadTitle: string = "Upload Proof of Insurance";
  approveRejectEmail: any;
  disableBtn: boolean = null;
  isTestingPartner: boolean = false;
  disableSignature: boolean = true;
  //tempTncInsurance:string;
  lulaHdr: string = "LIMITED USE LICENSE AGREEMENT<br/>TESTING EVENT";
  private isFromPcaDetails: boolean = (sessionStorage["fromPcaDetails"]) ? true : false;
  private isFromActionItemsReport: boolean = (sessionStorage["fromActionItemsReport"]) ? true : false;
  oldPcaReleaseDate: any;
  PcaReleaseDate2022_1: any;
  headerLabel = "";
  serviceLabel = "";

  constructor(private _location: Location, private spinner: NgxSpinnerService, private router: Router, private utility: Util, private _contractService: PcacontractAgreementService, private formBuilder: FormBuilder) { }


  ngOnInit() {
    
    this.isRHDApproved = null;
    this.spinner.show();
    this.date = new Date();
    this.PCAContractForm = this.formBuilder.group({
      firstName: ['',
        Validators.required,
      ],
      lastName: ['',
        Validators.required,
      ],
      titleName: ['',
        Validators.required,
      ],
    });
    this.approvePCAContract = this.formBuilder.group({
      email_signature: this.formBuilder.control('', null),
      user_notes: this.formBuilder.control('', null),
      radioBtn: this.formBuilder.control('', Validators.required),
    });
    this.getProofOfInsurance(sessionStorage["clinic_agreement_pk"]);
    this.getPCAContractAgreementData(sessionStorage["clinic_agreement_pk"], null);
    this.isTestingPartner = sessionStorage["testing_partner"] == 'TP' ? true : false;
    sessionStorage.removeItem("fromPcaDetails");
    this.oldPcaReleaseDate = new LulaAso().tnCCutOffDate;
    this.PcaReleaseDate2022_1 = new LulaAsoRelease2().tnCCutOffDate;
  }
  goto(e: number) {

    if (e == 1) {
      this.step1 = true;
      this.step2 = false;
    }
    if (e == 2) {
      if (this.PCAContractForm.valid) {
        this.step1 = false;
        this.step2 = true;
      }
      else {
        this.utility.validateAllFormFields(this.PCAContractForm);
      }
    }
  }

  onFileChange(event) {
    this.files = event.target.files;
    this.uploadedFileName = event.target.files[0].name;

  }
  apprOrReject(e) {
    if (e.target.value == "1") {
      this.apprOrRejectTextBox = false;
    }
    if (e.target.value == "2") {
      this.apprOrRejectTextBox = true;
    }
  }
  getPCAContractAgreementData(agreement_pk, user_type) {    
    this._contractService.getPCAContractAgreement(agreement_pk, user_type).subscribe((data:any) => {      
      this.load = true;
      data.clinicList.forEach(element => {        
        let dateString = element.clinicDate.substring(0, element.clinicDate.indexOf('T'));
        let dateSplitTokens = dateString.split('-');
        var dt = new Date(dateSplitTokens[0], dateSplitTokens[1] - 1, dateSplitTokens[2], 18, 30, 30);
        element.clinicDate=dt;
      });
      this.agreement_model = data;
      if (SessionDetails.GetfromUserPage() == true
        && this.agreement_model.isCurrent != null && this.agreement_model.isCurrent != undefined && !this.agreement_model.isCurrent) {
        this.router.navigate(['/invalidCotract']);
      }
      if(new Date(Util.getFormatedDate(this.agreement_model.dateApproved)) >= new Date(this.PcaReleaseDate2022_1))
      {
        this.router.navigate(['/login']);
      }
      this.isApproved = data.isRHDApproved;
      this.showHints = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) == 'yes' || data.isRHDApproved) ? false : true;

      this.approveRejectEmail = data.walgreenCoInfo.rhdEmail;
      this.storeId = data.storeInfo.storeId;
      this.storeAddress = data.storeInfo.storeAddress;
      if(this.clinicType != 11 && this.clinicType != 12) {
        if(data.clinicImmunizationList.length){
          let serviceLabelList = data.clinicImmunizationList.map((immunization) => {
            let idx = immunization.immunizationName.indexOf("testing");
            return immunization.immunizationName.substring(0,idx);
          });
          serviceLabelList = serviceLabelList.sort();
          if(serviceLabelList.length > 1){
            let lastEle = serviceLabelList.pop();
            let label =  `${serviceLabelList.join(', ')} and ${lastEle}`;
            this.headerLabel = `${serviceLabelList.join(',')} and ${lastEle}`.replace(" ,", ", ");
            this.serviceLabel = `${label.replace(" ,", ", ")} testing and screening services and ${label.replace(" ,",", ").replace("HIV", "HIV/AIDS")} counseling to individuals`;
          } else {
            let label =  `${serviceLabelList.join(',')}`;
            this.headerLabel = `${serviceLabelList.join(',')}`.replace(" ,", ", ");
            this.serviceLabel = `${label.replace(" ,", ", ")} testing and screening services and ${label.replace(" ,",", ").replace("HIV", "HIV/AIDS")} counseling to individuals`;
          }
        }
      }
      if (this.agreement_model.isRHDApproved == true || this.agreement_model.isRHDApproved == false) {
        this.isRHDApproved = true;
        this.approvePCAContract.controls["email_signature"].setValue(this.agreement_model.isRHDApproved == true ? this.agreement_model.walgreenCoInfo.rhdName : null);
        this.approvePCAContract.controls["user_notes"].setValue(this.agreement_model.notes);
        if (this.agreement_model.isRHDApproved == false) {
          this.selectedLink = "Reject";
        }
        this.approvePCAContract.controls["radioBtn"].setValue(this.agreement_model.isRHDApproved == true ? "1" : this.agreement_model.isRHDApproved == false ? "2" : "");
      }
      this.clinicType = sessionStorage['clinic_type'];
      this.uploadTitle = "Upload Proof of Insurance or Letter of Self-Insurance";
      let tnCCutOffDate = new Date(this.tandC.tnCCutOffDate);
      let dateString = data.dateApproved.substring(0, data.dateApproved.indexOf('T')); // hack for TImezone for -9+
      let dateSplitTokens = dateString.split('-');
      var dateApproved = new Date(dateSplitTokens[0], dateSplitTokens[1] - 1, dateSplitTokens[2], 18, 30, 30);

      switch (sessionStorage["clinic_type"]) {
        case 10:
        case "10":
          this.tandC = new LulaGov(data.storeInfo.state);
          this.lulaHdr = "LIMITED USE LICENSE AGREEMENT<br/>TESTING EVENT";
          this.tandC.conditionalTnCInsurance = this.tandC.conditionalTnCInsurance.replace( new RegExp("@serviceValue", 'g'), this.headerLabel);
          // if (data.clinicList[0].clinicImzQtyList[0].immunizationName === "HIV testing and screening services and HIV/AIDS counseling to individuals"
          // && dateApproved>=tnCCutOffDate && data.dateApproved ) {
          //   this.tandC.insurance = this.tandC.conditionalTnCInsurance.replace('{0}', "HIV Testing and counseling services");
          // }
          // else if (data.clinicList[0].clinicImzQtyList[0].immunizationName === "Hepatitis C testing and screening services and Hepatitis C counseling to individuals"
          // && dateApproved>=tnCCutOffDate && data.dateApproved) {
          //   this.tandC.insurance =  this.tandC.conditionalTnCInsurance.replace('{0}', "HCV Testing and counseling services");
          // }
          // else if (data.clinicList[0].clinicImzQtyList[0].immunizationName === "HIV and Hepatitis C testing and screening services and HIV/AIDS and Hepatitis C counseling to individuals"
          // && dateApproved>=tnCCutOffDate && data.dateApproved) {
          //   this.tandC.insurance =  this.tandC.conditionalTnCInsurance.replace('{0}', "HIV and HCV Testing and counseling services");
          // }
          break;
        case 11:
        case "11":
          this.tandC = new OpoLula(data.storeInfo.state);
          this.lulaHdr = "LIMITED USE LICENSE AGREEMENT<br/>ORGAN DONOR REGISTRATION EVENT";
          break;
        case 12:
        case '12':
          this.tandC = new CancerLula(data.storeInfo.state);
          this.lulaHdr = "LIMITED USE LICENSE AGREEMENT<br/>CANCER SCREENING EVENT";
          if(dateApproved>=tnCCutOffDate === true && data.dateApproved)
          this.tandC.insurance = this.tandC.conditionalTnCInsurance.replace('{0}', "cancer screening services");
          break;          
        default:
          this.tandC = new LulaAso();
          this.lulaHdr = "LIMITED USE LICENSE AGREEMENT<br/>TESTING EVENT";
          if (data.clinicList[0].clinicImzQtyList[0].immunizationName === "HIV testing and screening services and HIV/AIDS counseling to individuals"
          && dateApproved>=tnCCutOffDate && data.dateApproved ) {
            this.tandC.insurance = this.tandC.conditionalTnCInsurance.replace('{0}', "HIV Testing and counseling services");
          }
          else if (data.clinicList[0].clinicImzQtyList[0].immunizationName === "Hepatitis C testing and screening services and Hepatitis C counseling to individuals"
          && dateApproved>=tnCCutOffDate && data.dateApproved) {
            this.tandC.insurance =  this.tandC.conditionalTnCInsurance.replace('{0}', "HCV Testing and counseling services");
          }
          else if (data.clinicList[0].clinicImzQtyList[0].immunizationName === "HIV and Hepatitis C testing and screening services and HIV/AIDS and Hepatitis C counseling to individuals"
          && dateApproved>=tnCCutOffDate && data.dateApproved) {
            this.tandC.insurance =  this.tandC.conditionalTnCInsurance.replace('{0}', "HIV and HCV Testing and counseling services");
          }
          break;
      }
      if(this.clinicType == 8 || this.clinicType == 10) {

        this.tandC.conditionalTnCInsurance = this.tandC.conditionalTnCInsurance.replace( new RegExp("@serviceValue", 'g'), this.headerLabel);
            
        this.tandC.use = this.tandC.use.map(paragraph => { return paragraph.replace(new RegExp('@serviceValue', 'g'), this.headerLabel);});


        this.tandC.term = this.tandC.term.replace( new RegExp("@serviceValue", 'g'), this.headerLabel);
        this.tandC.tradenames_publications =this.tandC.tradenames_publications.replace( new RegExp("@serviceValue", 'g'), this.headerLabel);
        this.tandC.indemnity = this.tandC.indemnity.map(paragraph => { return paragraph.replace(new RegExp('@serviceValue', 'g'), this.headerLabel);});
        this.tandC.surrender =this.tandC.surrender.replace( new RegExp("@serviceValue", 'g'), this.headerLabel);
        this.tandC.insurance= this.tandC.insurance.replace( new RegExp("@serviceValue", 'g'), this.headerLabel);
        this.tandC.miscellaneous=  this.tandC.miscellaneous.replace( new RegExp("@serviceValue", 'g'), this.headerLabel);
        this.tandC.conditionalTnCInsurance= this.tandC.conditionalTnCInsurance.replace( new RegExp("@serviceValue", 'g'), this.headerLabel);
      }
      this.spinner.hide();
    });
  }
  Save() {
    var client_info = new ClientInfo();
    if (this.approvePCAContract.value.email_signature.trim() == '' && this.approvePCAContract.value.user_notes.trim() == '') {
      if (!this.approvePCAContract.controls['radioBtn'].value) {
        this.showDialog('Approve Agreement Error', 'Please select either \"Approve\" or \"Request Changes\" option from Approve Agreement section.');
      }
    }
    if (this.approvePCAContract.valid) {

      this.disableBtn = true;
      if (this.agreement_model.isApproved) {
        switch (this.selectedLink) {
          case 'Approve':
            this.approvePCAContract.controls['email_signature'].setValidators([Validators.required]);
            this.agreement_model.isApproved = 1;
            this.agreement_model.isEmailSent = Number(this.agreement_model.isEmailSent);
            this.agreement_model.rhdSignature = this.approvePCAContract.controls["email_signature"].value.trim();
            this.agreement_model.isRHDApproved = 1;
            this.agreement_model.contractPostedByUser = 'Business User';
            this.agreement_model.rvpApprovedOrRejectedBy = sessionStorage["approveRejectEmail"];
            this.agreement_model.dateRHDApproved = new Date();
            client_info.client = this.agreement_model.testingPartnerInfo.businessName;
            client_info.name = this.agreement_model.clientInfo.name;
            client_info.title = this.agreement_model.clientInfo.title;
            client_info.reviewedDate = new Date();
            this.agreement_model.clientInfo = client_info;
            this._contractService.approveAgreementData(this.agreement_model.storeInfo.storeId, sessionStorage["clinic_type"], true, this.agreement_model).subscribe((data: any) => {
              this.disableBtn = null;
              switch (data.errorS.errorCode) {
                case 'success':
                case null:
                case "null":
                case 0:
                case '0':
                  SessionDetails.setIsPCA(true);
                  this.router.navigate(['communityoutreach/storehome']);
                  break;
                case -2:
                case '-2':
                  this.showDialog(ErrorMessages['contract'], data.errorS.errorMessage);
                  break;
                case -5:
                  this.router.navigate(['/invalidCotract']);
                  break;
                default:
                  this.showDialog(ErrorMessages['contract'], String.Format('Unhandled ErrorCode received from Service errorCode:{0}, errorMessage:{1}', data.errorS.errorCode, data.errorS.errorMessage));
                  break;
              }
            },
              err => {
                this.disableBtn = null;
                // this.spinner.hide();
                switch (err) {
                  case 500:
                    this.showDialog(ErrorMessages['contract'], String.Format('ErrorCode 500 received from Service errorMessage:{0}', err.json().Message));
                    break;
                  case 400:
                    this.showDialog(ErrorMessages['clientError'], err.json().Message);
                    break;
                  default:
                    this.showDialog(ErrorMessages['contract'], String.Format('Unhandled exception ErrorCode received from Service errorMessage:{0}', err.json().Message));
                    break;
                }
              });
            break;
          case 'Reject':
            if (this.approvePCAContract.controls["user_notes"].value.trim() == '') {
              this.approvePCAContract.controls['user_notes'].setValue('');
              this.approvePCAContract.controls['user_notes'].updateValueAndValidity();
              this.disableBtn = null;
              this.utility.validateAllFormFields(this.approvePCAContract);
              return false;
            }
            this.agreement_model.isApproved = 0;
            this.agreement_model.isEmailSent = 1;
            this.agreement_model.rhdSignature = null;
            this.agreement_model.isRHDApproved = 0;
            this.agreement_model.notes = this.approvePCAContract.controls["user_notes"].value.trim();
            this.agreement_model.contractPostedByUser = 'Business User';
            this.agreement_model.dateRHDApproved = new Date();
            this.agreement_model.rvpApprovedOrRejectedBy = sessionStorage["approveRejectEmail"];
            client_info.client = this.agreement_model.testingPartnerInfo.businessName;
            client_info.name = this.agreement_model.clientInfo.name.trim();
            client_info.title = this.agreement_model.clientInfo.title;
            client_info.reviewedDate = new Date();
            this.agreement_model.clientInfo = client_info;
            this._contractService.approveAgreementData(this.agreement_model.storeInfo.storeId, sessionStorage["clinic_type"], true, this.agreement_model).subscribe((data: any) => {
              this.disableBtn = null;
              switch (data.errorS.errorCode) {
                case 'success':
                case null:
                case "null":
                case 0:
                case '0':
                  SessionDetails.setIsPCA(true);
                  this.router.navigate(['communityoutreach/storehome']);
                  break;
                case -2:
                case '-2':
                  this.showDialog(ErrorMessages['contract'], data.errorS.errorMessage);
                  break;
                case -5:
                  this.router.navigate(['/invalidCotract']);
                  break;
                default:
                  this.showDialog(ErrorMessages['contract'], String.Format('Unhandled ErrorCode received from Service errorCode:{0}, errorMessage:{1}', data.errorS.errorCode, data.errorS.errorMessage));
                  break;
              }
            },
              err => {
                this.disableBtn = null;
                switch (err) {
                  case 500:
                    this.showDialog(ErrorMessages['contract'], String.Format('ErrorCode 500 received from Service errorMessage:{0}', err.json().Message));
                    break;
                  case 400:
                    this.showDialog(ErrorMessages['clientError'], err.json().Message);
                    break;
                  default:
                    this.showDialog(ErrorMessages['contract'], String.Format('Unhandled exception ErrorCode received from Service errorMessage:{0}', err.json().Message));
                    break;
                }
              });
        }
      }
      else {
        this.disableBtn = null;
        this.showDialog(ErrorMessages['contract'], "Sorry, the testing partner must approve and sign this agreement, prior to the RVP. A change request may have been submitted, after a link was sent to you for approval. Once the testing partner approves and signs, you will receive another email for your approval and signature.");
      }
    }
    else {
      this.utility.validateAllFormFields(this.approvePCAContract);
    }
  }
  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
  }
  isSelected(name: string): boolean {
    if (!this.selectedLink) {
      return false;
    }
    return (this.selectedLink === name);
  }
  isFieldValid(field: string) {
    return !this.approvePCAContract.get(field).valid && this.approvePCAContract.get(field).touched;
  }
  isFieldValidStep(field: string) {
    return !this.PCAContractForm.get(field).valid && this.PCAContractForm.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  displayFieldCssStep(field: string) {
    return {
      'has-error': this.isFieldValidStep(field),
      'has-feedback': this.isFieldValidStep(field)
    };
  }
  setradio(e: string): void {
    if (e == 'Approve') {
      this.disableSignature = true;
      this.approvePCAContract.controls['email_signature'].setValidators([Validators.required]);
      this.approvePCAContract.controls['email_signature'].updateValueAndValidity();
      this.approvePCAContract.controls['user_notes'].clearValidators();
      this.approvePCAContract.controls['user_notes'].updateValueAndValidity();
      this.approvePCAContract.controls['user_notes'].setValue('');
    }
    if (e == 'Reject') {
      this.disableSignature = null;
      this.approvePCAContract.controls['email_signature'].setValue('');
      this.approvePCAContract.controls['email_signature'].clearValidators();;
      this.approvePCAContract.controls['email_signature'].updateValueAndValidity();
      this.approvePCAContract.controls['user_notes'].setValidators([Validators.required]);
      this.approvePCAContract.controls['user_notes'].updateValueAndValidity();
    }
    this.selectedLink = e;
  }

  okClicked() {
    this.display = false;
  }
  Ok() {
    this.uploadDoc = false;
  }
  getProofOfInsurance(clinic_agreement_pk) {
    this._contractService.GetProofOfInsurance(clinic_agreement_pk).subscribe((data:any) => {
      this.proofInsurances = data;
    });
  }
  Delete(pk) {
    this._contractService.DeleteProofOfInsurance(pk).subscribe(data => {
      this.getProofOfInsurance(sessionStorage["clinic_agreement_pk"]);
    });
  }
  private isValidFiles(files) {
    return this.isValidFileExtension(files);
  }
  private isValidFileExtension(files) {

    for (var i = 0; i < files.length; i++) {
      // Get file extension
      var ext = files[i].name.toLocaleUpperCase().split('.').pop() || files[i].name;

      // Check the extension exists
      var exists = this.fileExt.filter(element => element == ext);

      if (exists.length <= 0) {
        return false;
      }
    }
    return true;
  }
  Download(file_name) {    
    window.open(environment.RESOURCES.PROOF_OF_DOCUMENTS + "/" + file_name);
  }

  print() {
    this.utility.print("asolulaContract", "Limited Use License Agreement");
  }
  cancel() {
    if (this.isFromPcaDetails) {
      sessionStorage.removeItem("clinic_agreement_pk");
      sessionStorage.removeItem("fromPcaDetails");
      sessionStorage.removeItem("clinic_type");
      this._location.back();
    }
    else if (this.isFromActionItemsReport) {
      sessionStorage.removeItem("clinic_agreement_pk");
      sessionStorage.removeItem("fromActionItemsReport");
      sessionStorage.removeItem("clinic_type");
      this._location.back();
    } else {
      this.router.navigate(['/communityoutreach/pcahome']);
    }
  }
}