export class ImmunizationCheck
{
    immunizationPk: number;
    immunizationName: string;
    spanishName: string;
    clinicTypeName:string;
    immunizationAbvEN:string;
    immunizationAbvSP:string;
    clinicType:number;
    price: number;
    directBillPrice: number;
    directBillVoucherPrice: number;
    isActive: boolean;
    isCurrentSeason:boolean;
    isBlockImmunization:boolean;
    allowOverride:boolean;

}