export const outreachType : any[] =[
  {
     
    "outreachStatus": "Immunization Program",
    "category": "IP",
    
  },
    {
      "outreachStatus": "Senior Outreach",
      "category": "SR",
    
    }

]