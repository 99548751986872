import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { MinimumShotExceptions } from '../../../models/MinimumShotExceptions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MinimumshotexceptionsService {
  private _baseURL: string  = environment.API_URL; 
  private _url: string = this._baseURL;
  private cURL;
  constructor(private http: HttpClient) { }

  public getExceptionShotDetailsReport(minimum_shot_exceptions:MinimumShotExceptions): Observable<any> {    
    this.cURL = this._url + "/api/JsMinimumShotExceptions_get/";
    return this.http.post(this.cURL,minimum_shot_exceptions,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  public exportReportData(minimum_shot_exceptions:MinimumShotExceptions) {
    let userData= SessionDetails.GetUserInfo()
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    let url = this._url + "/api/JsMinimumShotExceptions_downloadReport/";
    return this.http.post(url, minimum_shot_exceptions,{headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
    .pipe(catchError(Util.handleErrorV2));
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });
  }

}
