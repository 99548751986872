import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PotentiallocalbusinesslistService {
  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL;
  constructor(private http: HttpClient) { }

  public getBusinessAssignedToStore(store_id:string,outreach_effort:string) {
    let url = this._url + "/api/JsPotentialLocalBusinessList_get/"+ store_id +"/"+ outreach_effort;
    return this.http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  public exportReportData(store_id:string,outreach_effort:string,report_name:string,output_type:string) {
    let userData= SessionDetails.GetUserInfo()
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let url = this._url + "/api/JsPotentialLocalBusinessList_downloadReport/"+ store_id +"/"+ outreach_effort +"/"+ report_name + "/"+ output_type;
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    return this.http.get(url, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'}).pipe(catchError(Util.handleErrorV2));
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });
  }
}
