import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { StoreprofileuploaderService } from '../services/storeprofileuploader.service';
import { SessionDetails } from '../../../../../utility/session';

@Component({
  selector: 'app-uploadstoreprofiles',
  templateUrl: './uploadstoreprofiles.component.html',
  styleUrls: ['./uploadstoreprofiles.component.css'],
  providers: [StoreprofileuploaderService]
})
export class UploadstoreprofilesComponent implements OnInit {
  fileExt: string[] = ["XLSX", "XLS", "XLSM", "TXT"];
  @Output() loadPage = new EventEmitter<any>();
  //@Input() uploadedOnKey: string;
  storeProfileUploaderForm: FormGroup;
  isError: boolean = false;
  invalidFile: boolean = false;
  uploadData: boolean = false;
  uploadedFileName: string = '';
  dialogMessage: string = '';
  showInfoMsg: boolean = false;
  files: any;
  storeProfileUploadData: any;
  uploadedFilesList: any = [];
  hasFilesList: boolean = true;
  userProfile: any;
  uploadedOn: string;
  selectedFileKey: string = "";
  uploadMode: number = 0;
  cols: any = [];
  selectedFile: any = {};
  canShowActions: boolean = false;
  confirmMessage: string = "";
  canShowConfirmPop: boolean = false;
  loading: boolean = false;
  canOpenForEdit: boolean = true;
  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private store_profile_uploader_service: StoreprofileuploaderService) {
    this.loading = true;
    this.getUploadedFilesList();
  }

  ngOnInit() {
    this.cols = [
      { field: 'fileName', header: 'Uploaded Files' },
      // { field: 'mode', header: 'Update Mode' },
      { field: 'actions', header: '' }
    ];
    this.userProfile = SessionDetails.GetUserProfile();
  }

  getUploadedFilesList() {
    this.store_profile_uploader_service.getUploadedFilesList().subscribe((res:any) => {
      if (res.uploadedFilesList.length > 0) {
        this.hasFilesList = true;
        res.uploadedFilesList.forEach(item => {
          let cnt = this.uploadedFilesList.filter(obj => {
            return (obj.uploadedOn === item.uploadedOn);
          });
          if (cnt.length === 0) {
            this.uploadedFilesList.push({
              "fileName": (!item.mode || item.mode === "Full") ? "TribuneDirect_" + item.uploadedOn + ".TXT" : item.fileName,
              "uploadedOn": item.uploadedOn,
              "mode": (!item.mode || item.mode === "Full") ? 0 : 1
            })
          }
        });
        this.loading = false;
      } else {
        this.hasFilesList=false;
        this.uploadedFilesList.push({
          "fileName": "No files available to update for the current month.",
          "uploadedOn": '',
          "mode": ''
        });
        this.loading = false;
      }
      //this.uploadedFilesList.splice(5,(this.uploadedFilesList.length - 5));
    },
      error => {
        if (error.status === 417) {
          this.dialogMessage = "HTTP/1.1 417 Expectation Failed";
        } else {
          this.dialogMessage = "An error occurred. Please concact administrator.";
        }
      });
  }

  onFileChange(event) {
    if (!this.isValidFiles(event.target.files)) {
      console.log("wrong file");
      this.dialogMessage = "Only " + this.fileExt + " file types are allowed."
      this.invalidFile = true;
      return false;
    }
    this.files = event.target.files;
    this.uploadedFileName = event.target.files[0].name;
  }

  onSubmit() {
    this.uploadData = false;
    this.saveFiles(this.files);
  }

  saveFiles(files) {
    this.storeProfileUploadData = [];
    let dt = new Date();
    let m = (dt.getMonth() + 1).toString();
    m = (m.length == 1) ? '0' + m : m;
    let d = dt.getDate().toString();
    d = (d.length == 1) ? '0' + d : d;
    this.uploadedOn = m + "" + d + "" + dt.getFullYear() + "" + dt.getHours() + "" + dt.getMinutes() + "" + dt.getSeconds();
    if (files.length > 0) {
      let formData: FormData = new FormData();
      for (var j = 0; j < files.length; j++) {
        formData.append("file", files[j]);
      }
      this.spinner.show();
      this.store_profile_uploader_service.uploadStores(formData, this.uploadMode, this.uploadedOn, this.userProfile.userPk, this.userProfile.email.replace(/[.]/g, '^')).subscribe((data:any) => {
        this.spinner.hide();
        this.uploadedFileName = '';

        if (data.errorS && data.errorS.errorCode === -1) {
          this.dialogMessage = data.errorS.errorMessage;
          this.invalidFile = true;
        } else {
          let emit_json = {
            "uploadedOn": this.uploadedOn,
            "stepNumber": 2,
            "uploadMode": this.uploadMode
          };

          this.loadPage.emit(emit_json);
        }
      },
        error => {
          this.spinner.hide();
          this.isError = true;
          this.uploadedFileName = '';
          this.showInfoMsg = true;
          if (error.indexOf("417") > -1) {
            this.dialogMessage = "HTTP/1.1 417 Expectation Failed";
          } else {
            this.dialogMessage = "An error occurred. Please concact administrator.";
          }
        }
      )
    }
  }

  private isValidFiles(files) {
    return this.isValidFileExtension(files);
  }

  private isValidFileExtension(files) {
    for (var i = 0; i < files.length; i++) {
      // Get file extension
      var ext = files[i].name.toLocaleUpperCase().split('.').pop() || files[i].name;

      // Check the extension exists
      var exists = this.fileExt.filter(element => element == ext);

      if (exists.length <= 0) {
        return false;
      }
      // return false;
    }
    return true;
  }

  //isFieldValid(field: string) {
    //return !this.storeProfileUploaderForm.get(field).valid && this.storeProfileUploaderForm.get(field).touched;
  //}

  // displayFieldCss(field: string) {
  //   return {
  //     'has-error': this.isFieldValid(field),
  //     'has-feedback': this.isFieldValid(field)
  //   };
  // }

  Continue() {

  }

  Ok() {
    this.isError = false;
    this.invalidFile = false;
    this.showInfoMsg = false;
  }

  getSelectedFileReport(selected_file_key: string) {
    let emit_json = {
      "uploadedOn": selected_file_key, //this.selectedFileKey,
      "stepNumber": 2,
      "uploadMode": this.uploadMode
    };
    //window.setTimeout(function(){
    this.loadPage.emit(emit_json);
    //},1000);
  }

  getSelectedFileKey(event: any) {
    this.selectedFileKey = event.target.value;
  }

  setUploadMode(event: any) {
    this.uploadMode = event.target.value;
  }

  getHeaderClass(e: string, is_body: boolean) {
    let cls_name = "";
    switch (e) {
      case 'fileName':
        cls_name = (is_body && !this.hasFilesList)?'noFilesList': 'uTw150';
        break;
      case 'Update Mode':
      // cls_name = 'w65';
      // break;
      case 'actions':
        cls_name = (!is_body || (is_body && this.uploadedFilesList.length < 6)) ? 'w86' : 'overW86';
        break;
      case 'icon':
        cls_name = (this.uploadedFilesList.length < 6) ? 'i10' : 'inopad';
        break;
      default:
        cls_name = '';
        break;
    }
    return cls_name;
  }

  showActionsDialog(rowIndex: number) {
    this.selectedFile = this.uploadedFilesList[rowIndex];
    this.canShowActions = true;
  }

  openFile() {

  }

  removeFile() {

  }

  continueOnConfirm() {
    //Call the service.
    this.store_profile_uploader_service.deleteStoreUploadFile(this.selectedFile.uploadedOn).subscribe((result:any) => {
      if (result.errorS === null) {
        this.dialogMessage = "The file has been deleted successfully.";
        this.showInfoMsg = true;
        this.loading = true;
        this.uploadedFilesList = [];
        this.getUploadedFilesList();
      }
    }, error => {
      this.dialogMessage = "Error occured while deleting the file. Please contact administrator."
      this.showInfoMsg = true;
    });
    this.selectedFile = {};
    this.canShowConfirmPop = false;
  }

  cancelOnConfirm() {
    this.selectedFile = {};
    this.canShowConfirmPop = false;
  }

  getButtonContent(action: string) {
    let but_content: string = "";
    switch (action) {
      case "openOrEdit":
        but_content = 'Open';
        break;
      case "remove":
        but_content = 'Delete';
        break;
      default:
        break;
    }
    return but_content;
  }

  getHeader() {
    return "Delete File";
  }

  makeAction(action: string) {
    switch (action) {
      case "openOrEdit":
        let emit_json = {
          "uploadedOn": this.selectedFile.uploadedOn, //this.selectedFileKey,
          "stepNumber": 2,
          "uploadMode": this.selectedFile.mode
        };
        this.selectedFile = {};
        this.loadPage.emit(emit_json);
        break;
      case "remove":
        //Get the user confirmation before removing.
        this.canShowActions = false;
        this.confirmMessage = 'Deleting the file <b>' + this.selectedFile.fileName + '</b> cannot be undone. If you "Continue" the file will be permanently deleted.'
        this.canShowConfirmPop = true;
        break;
      default:
        break;
    }
  }

  getScrollableHeight() {
    // let ht = "";
    // if (this.uploadedFilesList.length > 5) {
    //   ht = '195px';
    // } else {
    //   ht = '139px';
    // }
  }
}
