export class LogOutreachStatus {
    businessPk: number;
    firstName: string;
    lastName: string;
    jobTitle: string;
    outreachProgram: string;
    outreachBusinessPk: number;
    contactDate: Date;
    outreachStatusId: number;
    outreachStatusTitle: string;
    feedback: string;
    createdBy: number;
    clinicAgreementPk: number;
    contactLogPk: number;
}

export class ClinicImzQtyList {
    clinicPk: number;
    immunizationPk: number;
    immunizationName: string;
    paymentTypeId: number;
    estimatedQuantity: number;
    paymentTypeName: string;
    vouchersDistributed: number;
}

export class ClinicList {
    pk: number;
    location: string;
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    contactPhone: string;
    clinicDate: string = '';
    startTime: string;
    endTime: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    isNoClinic: number;
    isCurrent: boolean;
    public latitude: string = ''
    public longitude: string = ''
    public previousContact: string = ''
    public previousLocation: string = ''
    public localEstmdShots: number
    public localvouchersDist: number
    clinicImzQtyList: ClinicImzQtyList[];
    constructor() {
        this.isNoClinic = 0;
        this.state = "";
    }
}

export class charityVoucherProgram {
    clinicAgreementPk: number;
    contactLogPk: number;
    isApproved: boolean;
    approvedOrRejectedBy: string;
    isEmailSent: boolean;
    dateCreated: Date;
    lastUpdatedDate: Date;
    contactWagUser: string;
    clinicList: ClinicList[];
}

export class ErrorS {
    errorCode: number;
    errorMessage: string;
}
export class ClinicImmunizationList {
    pk: number;
    clinicPk: number;
    immunizationPk: number;
    immunizationName: string;
    price: number;
    paymentTypeId: number;
    paymentTypeName: string;
    sendInvoiceTo: number;
    name: string;
    address1?: any;
    address2?: any;
    city?: any;
    state?: any;
    zip?: any;
    phone?: any;
    email?: any;
    isTaxExempt?: any;
    isCopay?: any;
    copayValue: number;
    isVoucherNeeded?: any;
    voucherExpirationDate?: any;
}


export class CharityProgram {
    //public clinicList: Array<ClinicList> = new Array<ClinicList>();
    logOutreachStatus: LogOutreachStatus;
    clinicImmunizationList: ClinicImmunizationList;
    charityVoucherProgram: charityVoucherProgram;
    isSendLocationChangeEmailToAppts: any;
    isSendRescheduleEmailToAppts: any;
    errorS: ErrorS;
    constructor() {
        this.isSendLocationChangeEmailToAppts = false;
        this.isSendRescheduleEmailToAppts = false;
    }
}

