import { Component, OnInit } from '@angular/core';
import { states } from '../../../../JSON/States';
import { SessionDetails } from '../../../../utility/session';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { AddTestingOrganizationService } from '../../Services/add-testing-organization.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AddOutreachOpportunity, OutreachEffortList } from '../../../../models/OutreachOpportunity';
import { AddBusinessService } from '../../../store/services/AddBusiness.Service';
import { Location } from '@angular/common';
import { ActionType } from '../../../../utility/enum';
import { ConfirmationService } from 'primeng/api';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { NgxSpinnerService } from "ngx-spinner";
import { String } from 'typescript-string-operations';
import { Util } from '../../../../utility/util';
import { isNgTemplate } from '@angular/compiler';

@Component({
  selector: 'app-addpca',
  templateUrl: './addpca.component.html',
  styleUrls: ['./addpca.component.css']
})

export class AddpcaComponent implements OnInit {

  showHints: boolean = (((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes')) || sessionStorage.actionType === "2" ? false : true;
  pageName: string = "addpca";

  private storeProfileInfo: any;
  states: any[];
  stateSpecific: string;
  selectedZipCode: string;
  selectedtesting_partner_name: string;
  selectedcontact_phone: string;
  selectedcontact_name: string;
  selectedtitle_of_contact: string;
  selectedcontact_e_mail_address: string;
  selectedtesting_partner_address: string;
  selectedtesting_partner_city: string;
  selectedtesting_partner_state: string;
  errorPopUp: boolean = false;
  location_back: boolean = false;
  Message: any;
  addPCAForm: FormGroup;
  TestingPartners: any[] = [];
  TotalTestingPartners: any[] = [];
  selectedTestingPartners: any = {};
  selectedState: string = '';
  orgTypeSelect: any = "";
  alaSQL: any;
  userProfile: any;
  businessId: any;
  errorMessage: any;
  differentState: boolean = false;
  isEdit: boolean = false;
  edit_data: any;
  btnDisbale: boolean = null;
  IsAdmin: boolean = true;
  btnSaveText: any;
  popupShow: boolean = false;
  dialogSummary: string = "";
  dialogMsg: string = "";
  disableFields: boolean;
  isStandardized: boolean;
  isInitiated: boolean = false;

  isScheduleClinics: boolean = false;
  btnCancel: any = 'Cancel';
  businessPk: any = 0;
  organization_type: any;
  disableBtn: boolean = null;
  constructor(private message_service: MessageServiceService, private utility: Util, private spinner: NgxSpinnerService, private confirmationService: ConfirmationService, private _location: Location, private _AddBusinessService: AddBusinessService, private formBuilder: FormBuilder, private _testingpartner: AddTestingOrganizationService) {
    this.alaSQL = require('alasql');
    this.message_service.getStoreProfile().subscribe(message => {

      this.storeProfileInfo = SessionDetails.getStoreProfileData();

      //this.storeProfileInfo = message.text;
      this.clearSelectedValues();
      // this.stateValue(this.stateSpecific);
      // let parters_info = this.alaSQL("SELECT distinct * FROM ? \
      // WHERE store_state = '" + this.selectedState + "' ", [this.nhd_json]);
      //this.selectedData(parters_info[0].testing_partner_name);
    });

  }

  ngOnInit() {
    this.disableFields = null;
    this.isStandardized = false;
    this.userProfile = SessionDetails.GetUserProfile();
    this.addPCAForm = this.formBuilder.group({
      testingOrgType: ['', Validators.required],
      organizationName: ['', [
        Validators.required,
        Validators.pattern("[a-zA-Z0-9_]+.*$")]],
      phone: ['', [
        Validators.required
      ]],
      tollFree: [null, ''],
      firstName: ['', [
        Validators.required,
        Validators.pattern("[a-zA-Z0-9_]+.*$")
      ]],
      lastName: ['', [
        Validators.required,
        Validators.pattern("[a-zA-Z0-9_]+.*$")
      ]],
      jobTitle: ['', ''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")
      ]],
      address1: ['', [
        Validators.required,
        Validators.pattern("[a-zA-Z0-9_]+.*$")
      ]],
      address2: ['', ''],
      city: ['', [
        Validators.required,
        Validators.pattern("[a-zA-Z0-9_]+.*$")
      ]],
      state: ['', [
        Validators.required,
        Validators.pattern("[a-zA-Z0-9_]+.*$")
      ]],
      zipCode: ['', [
        Validators.required,
        Validators.pattern("[a-zA-Z0-9_]+.*$")
      ]],
      //,ContractInitiated:[null,'']
    });
    this.states = states;
    this.btnSaveText = 'Save';
    this.IsAdmin = this.userProfile.userRole.toLowerCase() == "admin" ? true : false;
    if (SessionDetails.GetActionType() == ActionType.editOportunity) {
      this.isEdit = true;
      this.btnSaveText = 'Update';
      this.edit_data = SessionDetails.GetopportunitiesData();
      this.businessId = this.edit_data.businessPk;
      this.showSelectedData();
    }
    else {
      this.stateSpecific = SessionDetails.GetState();
      this.getTestingPartnerNames(this.stateSpecific);
    }

  }

  stateChange(value) {
    this.selectedState = value;
  }
  stateValue(e) {
    this.selectedState = e;
    this.TestingPartners = [];
    this.btnDisbale = true;
    this.orgTypeSelect = "";
    if (this.selectedState !== "") {
      this.getTestingPartnerNames(this.selectedState);
    }
  }
  getTestingPartnerNames(state_name) {
    this._testingpartner.getTestingPartnerNames(state_name).subscribe((data: any) => {

      if (data.length > 0) {
        this.btnDisbale = null;
        this.TestingPartners = data;
        this.TotalTestingPartners = data;
        var distinct = [];

        this.businessId = this.TestingPartners[0].businessPk;
      }
      else {
        this.btnDisbale = true;
        this.TotalTestingPartners = [];
      }

    });

  }
  TestingOrgTypeChange(e) {

    if (e !== "") {
      if (this.TotalTestingPartners.length > 0) {
        this.TestingPartners = this.TotalTestingPartners.filter(c => c.organizationType == e);
        if (this.TestingPartners.length > 0) {
          this.businessId = this.TestingPartners[0].businessPk;
          this.btnDisbale = null;
        }
        else {
          this.btnDisbale = true;
        }
      }
    }
    else {
      this.TestingPartners = [];
      if (this.TotalTestingPartners.length > 0) {
        if (this.TotalTestingPartners.length > 0) {
          this.TestingPartners = this.TotalTestingPartners;
          this.btnDisbale = null;
          this.businessId = this.TestingPartners[0].businessPk;
        }
        else {
          this.btnDisbale = true;
        }
      }

    }
  }

  selectedData(business_id) {
    this.businessId = business_id;
  }

  showSelectedData() {

    this.spinner.show();
    this._AddBusinessService.getPCAStoreBusinessDetails(this.businessId).subscribe((res:any) => {
      this.organization_type = res.organizationType;
      this.addPCAForm.controls['testingOrgType'].setValue(res.organizationType);
      this.addPCAForm.controls['address1'].setValue(res.address1);
      this.addPCAForm.controls['address2'].setValue(res.address2);
      this.addPCAForm.controls['organizationName'].setValue(res.businessName);
      this.addPCAForm.controls['city'].setValue(res.city);
      this.addPCAForm.controls['email'].setValue(res.email);
      this.addPCAForm.controls['firstName'].setValue(res.firstName);
      this.addPCAForm.controls['jobTitle'].setValue(res.jobTitle);
      this.addPCAForm.controls['lastName'].setValue(res.lastName);
      this.addPCAForm.controls['phone'].setValue(res.phone);
      this.addPCAForm.controls['state'].setValue(res.state);
      this.addPCAForm.controls['tollFree'].setValue(res.tollFree);
      this.addPCAForm.controls['zipCode'].setValue(res.zipCode);
      this.stateSpecific = this.addPCAForm.controls['state'].value;
      this.selectedState = this.addPCAForm.controls['state'].value;
      this.isStandardized = false; //res.isStandardized;

      res.outreachEffortList.forEach(item => {
        if (item.isCharityInitiated || item.isCommunityInitiated) {
          this.isInitiated = true;
        }
      })
      this.isScheduleClinics = res.isScheduledClinics;
      this.spinner.hide();
    });
  }
  addSelectedData() {
    
    this.spinner.show();
    this._AddBusinessService.getPCAStoreBusinessDetails(this.businessId).subscribe((res:any) => {
      this.disableFields = true;
      //this.isStandardized = true;
      this.businessPk = this.businessId;
      this.addPCAForm.controls['testingOrgType'].setValue(res.organizationType);
      this.addPCAForm.controls['address1'].setValue(res.address1);
      this.addPCAForm.controls['address2'].setValue(res.address2);
      this.addPCAForm.controls['organizationName'].setValue(res.businessName);
      this.addPCAForm.controls['city'].setValue(res.city);
      this.addPCAForm.controls['email'].setValue(res.email.trim());
      this.addPCAForm.controls['firstName'].setValue(res.firstName);
      this.addPCAForm.controls['jobTitle'].setValue(res.jobTitle);
      this.addPCAForm.controls['lastName'].setValue(res.lastName);
      this.addPCAForm.controls['phone'].setValue(res.phone);
      this.addPCAForm.controls['state'].setValue(res.state);
      this.addPCAForm.controls['tollFree'].setValue(res.tollFree);
      this.addPCAForm.controls['zipCode'].setValue(res.zipCode);
      this.stateSpecific = this.addPCAForm.controls['state'].value;
      this.selectedState = this.addPCAForm.controls['state'].value;
      this.spinner.hide();
    });
  }
  setDefaultValues() {

  }

  clearSelectedValues() {
    this.selectedZipCode = "";
    this.selectedtesting_partner_name = "";
    this.selectedcontact_phone = "";
    this.selectedcontact_name = "";
    this.selectedtitle_of_contact = "";
    this.selectedcontact_e_mail_address = "";
    this.selectedtesting_partner_address = "";
    this.selectedtesting_partner_city = "";
    this.selectedtesting_partner_state = "";
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  isFieldValid(field: string) {
    return !this.addPCAForm.get(field).valid && this.addPCAForm.get(field).touched;
  }
  Save() {
    if (this.addPCAForm.valid) {
      this.btnCancel = this.IsAdmin ? 'Cancel' : 'Ok';

      if(this.addPCAForm.value.phone.indexOf("X") > -1){
        this.addPCAForm.controls.phone.setValue(null);
        this.addPCAForm.controls.phone.updateValueAndValidity();
        this.dialogSummary = "Error";
        this.dialogMsg = "Mandatory fields are empty or have invalid entries. Please check the fields highlighted in red and enter or correct the information.";
        this.popupShow = true;
        return false;
      }

      if (this.addPCAForm.controls['tollFree'].value != null && this.addPCAForm.controls['tollFree'].value != '') {
        if ((this.addPCAForm.controls['tollFree'].value.replace(/[^0-9.]/g, "")).length > 11 || (this.addPCAForm.controls['tollFree'].value.replace(/[^0-9.]/g, "")).length < 10 || (parseInt((this.addPCAForm.controls['tollFree'].value.replace(/[^0-9.]/g, ""))) + 0) == 0 || (parseInt((this.addPCAForm.controls['tollFree'].value.replace(/[^0-9.]/g, ""))) + 0) < 100 || parseInt(this.addPCAForm.controls['tollFree'].value.slice(0, 1)) == 0) {
          this.dialogSummary = "Toll free number error.";
          this.dialogMsg = "Invalid toll free number. Please enter valid toll free number in toll free field.";
          this.popupShow = true;
          return false;
        }
      }
      if (this.selectedState !== SessionDetails.GetState()) {
        this.confirm("PCA ORGANIZATION", ErrorMessages["PCAAddWarning"]);
      }
      else {
        if (this.isEdit) {
          this.editTestingOrganizations();
        }
        else {
          this.saveTestingOrganizations();
        }

      }
    }
    else {
      this.utility.validateAllFormFields(this.addPCAForm);
      return;
    }
  }
  Cancel() {
    this._location.back();
  }
  Ok() {

    this.errorPopUp = false;
    if (this.location_back) {
      this._location.back();
    }
  }
  saveTestingOrganizations() {

    var store_id = SessionDetails.GetStoreId();
    var store_pk = SessionDetails.GetStorePk();
    var addPCAData = new AddOutreachOpportunity();
    addPCAData.organizationtype = Number(this.addPCAForm.controls["testingOrgType"].value);
    addPCAData.businessName = this.addPCAForm.controls["organizationName"].value;
    addPCAData.businessPk = this.businessPk;
    addPCAData.phone = this.addPCAForm.controls["phone"].value;
    addPCAData.tollFree = this.addPCAForm.controls["tollFree"].value;
    addPCAData.firstName = this.addPCAForm.controls["firstName"].value;
    addPCAData.lastName = this.addPCAForm.controls["lastName"].value;
    addPCAData.jobTitle = this.addPCAForm.controls["jobTitle"].value;
    addPCAData.email = this.addPCAForm.controls["email"].value;
    addPCAData.address1 = this.addPCAForm.controls["address1"].value;
    addPCAData.address2 = this.addPCAForm.controls["address2"].value;
    addPCAData.city = this.addPCAForm.controls["city"].value;
    addPCAData.state = this.addPCAForm.controls["state"].value;
    addPCAData.zipCode = this.addPCAForm.controls["zipCode"].value;
    addPCAData.isStandardized = false; //this.isStandardized ? true : false;
    addPCAData.outreachEffortList = [];
    //if (this.addPCAForm.controls['SeniorOutreach'].value) {
    var outreachEffort = new OutreachEffortList();
    outreachEffort.outreachBusinessPk = -1;
    outreachEffort.outreachProgram = "PCA";
    outreachEffort.storeId = store_id;
    outreachEffort.storePk = store_pk;
    outreachEffort.existingStoreId = -1
    outreachEffort.isCommunityInitiated = 0; 
    outreachEffort.isCharityInitiated = 0; 
    addPCAData.outreachEffortList.push(outreachEffort);
    //}
    this.disableBtn = true;

    this._AddBusinessService.addPCAOpportunity(addPCAData, this.userProfile.userPk, false).subscribe(res => {
      this.disableBtn = null;
      this.errorMessage = "";
      if (res.errorS != null) {
        if (res.errorS.errorCode > 0) {
          this.location_back = false;
          this.Message = "Error Message";
          this.errorPopUp = true;
          var business_name = res.errorS.errorMessage.split(',');
          var name = business_name;
          if (res.errorS.errorCode === 1 ) {
            this.errorMessage = String.Format(ErrorMessages["DuplicatePCAPhone"],res.errorS.errorMessage);
          }
          else if( res.errorS.errorCode === 2) {
            this.errorMessage = String.Format(ErrorMessages["DuplicatePCAAddress"],res.errorS.errorMessage);;
          }
          else if (res.errorS.errorCode === 3) {
            this.errorMessage = String.Format(res.errorS.errorMessage);
          }
          else if (res.errorS.errorCode == 0) {
            this.location_back = false;
            this.Message = "Error Message";
            this.errorPopUp = true;
            this.errorMessage = String.Format(ErrorMessages["DuplicateTestingOrg"], addPCAData.businessName);  
          }
          else {
              this.location_back = false;
              this.Message = "Error Message";
              this.errorPopUp = true;
              this.errorMessage = res.errorS&&res.errorS.errorMessage? res.errorS.errorMessage:"Unknown Error";          
            }
          
          }
        else if (res.errorS.errorCode == 0) {
          this.location_back = false;
          this.Message = "Error Message";
          this.errorPopUp = true;
          this.errorMessage = String.Format(ErrorMessages["DuplicateTestingOrg"], addPCAData.businessName);  
      }
        else {            
          this.location_back = false;
          this.Message = "Error Message";
          this.errorPopUp = true;
          this.errorMessage = res.errorS&&res.errorS.errorMessage? res.errorS.errorMessage:"Unknown Error";          
        }
      }
      if (res.errorS == null) {
        this.location_back = true;
        this.Message = "PCA ORGANIZATION";
        this.errorPopUp = true;
        this.errorMessage = "PCA organization added successfully.";
      }
    },
    err=>
    {
      this.Message = "PCA ORGANIZATION";
      this.errorPopUp = true;
      this.location_back = true;
      this.errorMessage = "Expectation Failed.";
    });
  }
  confirm(hdr: string, msg: string) {
    this.confirmationService.confirm({
      message: msg,
      header: hdr,
      accept: () => {
        if (this.isEdit)
          this.editTestingOrganizations();
        else
          this.saveTestingOrganizations();

        return true;
      },
      reject: () => {
        return false;
      }
    });
  }
  editTestingOrganizations() {
    var store_id = SessionDetails.GetStoreId();
    var store_pk = SessionDetails.GetStorePk();
    var addPCAData = new AddOutreachOpportunity();
    addPCAData.organizationtype = Number(this.addPCAForm.controls["testingOrgType"].value);
    addPCAData.businessName = this.addPCAForm.controls["organizationName"].value;
    addPCAData.phone = this.addPCAForm.controls["phone"].value;
    addPCAData.tollFree = this.addPCAForm.controls["tollFree"].value;
    addPCAData.firstName = this.addPCAForm.controls["firstName"].value;
    addPCAData.lastName = this.addPCAForm.controls["lastName"].value;
    addPCAData.jobTitle = this.addPCAForm.controls["jobTitle"].value;
    addPCAData.email = this.addPCAForm.controls["email"].value;
    addPCAData.address1 = this.addPCAForm.controls["address1"].value;
    addPCAData.address2 = this.addPCAForm.controls["address2"].value;
    addPCAData.city = this.addPCAForm.controls["city"].value;
    addPCAData.state = this.addPCAForm.controls["state"].value;
    addPCAData.zipCode = this.addPCAForm.controls["zipCode"].value;
    addPCAData.businessPk = this.businessId;
    addPCAData.isStandardized = false; //this.isStandardized ? true : false;
    addPCAData.outreachEffortList = [];

    var outreachEffort = new OutreachEffortList();
    outreachEffort = this.edit_data.outreachEffort.PCA;

    outreachEffort.storeId = store_id;
    outreachEffort.storePk = store_pk;
    outreachEffort.existingStoreId = store_id;

    addPCAData.outreachEffortList.push(outreachEffort);
    if ((this.isInitiated || this.isScheduleClinics) && this.addPCAForm.controls['testingOrgType'].value !== this.organization_type) {
      this.errorPopUp = true;
      this.Message = "PCA ORGANIZATION";
      this.errorMessage = "The Organization Type for this organization may not be updated while it has a pending Limited Use License Agreement.";

      if (this.isScheduleClinics) {
        this.errorMessage = "The Organization Type for this organization may not be updated while it has scheduled events.";
      }

      return;
    }
    this.disableBtn = true;
    this._AddBusinessService.updatePCAOpportunity(addPCAData, this.userProfile.userPk, false).subscribe(res => {
      this.disableBtn = null;
      this.errorMessage = "";
      if (res.errorS != null) {
        if (res.errorS.errorCode > 0) {
          this.location_back = false;
          this.Message = "Error Message";
          this.errorPopUp = true;
          if (res.errorS.errorCode === 1 ) {
          this.errorMessage = String.Format(ErrorMessages["DuplicatePCAPhone"],res.errorS.errorMessage);;
        }
        else if( res.errorS.errorCode === 2) {
          this.errorMessage = String.Format(ErrorMessages["DuplicatePCAAddress"],res.errorS.errorMessage);;
        }
        else if (res.errorS.errorCode === 3) {
          this.errorMessage = String.Format(res.errorS.errorMessage);
        }        
        else {
          this.errorMessage = res.errorS&&res.errorS.errorMessage? res.errorS.errorMessage:"Unknown Error";          
          }
        }
      else if (res.errorS.errorCode == 0) {
          this.location_back = false;
          this.Message = "Error Message";
          this.errorPopUp = true;
          this.errorMessage = String.Format(ErrorMessages["DuplicateTestingOrg"], addPCAData.businessName);  
      }
      else {            
        this.location_back = false;
        this.Message = "Error Message";
        this.errorPopUp = true;
        this.errorMessage = res.errorS&&res.errorS.errorMessage? res.errorS.errorMessage:"Unknown Error";          
      }
    }
      if (res.errorS == null) {
        this.location_back = true;
        this.Message = "PCA ORGANIZATION";
        this.errorPopUp = true;
        this.errorMessage = "PCA organization updated successfully.";
      }
    
  
    },
    err=>
    {
      this.Message = "PCA ORGANIZATION";
      this.errorPopUp = true;
      this.location_back = true;
      this.errorMessage = "Expectation Failed.";
    }
    );
  }

  onOnlyNumbers(event: any) {
    Util.onlyNumbers(event);
  }
  closePopup() {
    this.popupShow = false;
  }


}

