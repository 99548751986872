import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VaccinePurchasingReportService } from '../../services/vaccinepurchasing.services';
import * as appSettings from '../../globals';
//import { NGXLogger } from 'ngx-logger';
import { ApplicationSettings } from '../../../common/application-settings';
import { saveAs as importedSaveAs } from "file-saver";
import { VaccinePurchase } from '../../../../models/VaccinePurchase';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-covid-vaccine-purchasing-report',
  templateUrl: './covid-vaccine-purchasing-report.component.html',
  styleUrls: ['./covid-vaccine-purchasing-report.component.css']
})

export class CovidVaccinePurchasingReportComponent implements OnInit {
  vaccinePurchasingReportForm: FormGroup;
  vaccinePurchasingReportArray: any;
  ddlSeason: number;
  private isCurrentSeason = 1;
  fromDate: Date;
  toDate: Date;
  outreachStartDate = ApplicationSettings.outreachStartDateCovid;
  outreachStartYear = parseInt(appSettings.covidReportStartYear);
  minDate: Date;
  maxDate: Date;
  ddlFormat: any;
  private from_date: string;
  private to_date: string;
  totalRecords: number;
  loading: boolean;
  private currentSeason = appSettings.covidSeason;
  seasons: any = [];
  canExport: boolean = true;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  showGrid: boolean = false;
  ddlVaccines: any = [1];
  constructor(
    private fb: FormBuilder,
    private vaccinepurchasingreport: VaccinePurchasingReportService, //private logger: NGXLogger, 
    private spinner: NgxSpinnerService) {
  }
  ngOnInit() {
    for (let report_year: number = (new Date(this.outreachStartDate)).getFullYear(); report_year >= this.outreachStartYear; report_year--) {
      if (report_year <= 2016) {
        continue;
      }
      this.seasons.push({
        "yearName": report_year.toString() + "-" + (report_year + 1).toString(),
        "yearCode": report_year.toString()
      });
    }
    this.ddlSeason = (new Date(this.outreachStartDate)).getFullYear();
    this.setDates();
    this.fromDate = this.minDate;
    this.toDate = this.maxDate;
    this.ddlFormat = "0";
    this.setFormControls();
    //this.generateReport();
  }
  setFormControls() {
    this.vaccinePurchasingReportForm = this.fb.group({
      'fDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'tDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
      'ddlSeason': [this.ddlSeason],
      'ddlFormat': ["0"]
    });
  }
  generateReport(): void {
    if (!this.showGrid) {
      this.spinner.show();
    } else {
      this.loading = true;
    }
    //this.ddlSeason = this.ddlSeason;
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    //this.logger.log(this.from_date);
    //this.logger.log(this.to_date);
    let data = this.vaccinePurchasingReportForm.value;
    if (data.ddlSeason != this.currentSeason) {
      this.isCurrentSeason = 0;
    }
    let vaccine_purchase = new VaccinePurchase(data.fDate, data.tDate, this.isCurrentSeason, this.ddlVaccines.sort().join(','),true, "", "");
    this.vaccinepurchasingreport.getVaccinePurchasingDetailsReportData(vaccine_purchase).subscribe((resultArray: any) => {
      //this.datasource = resultArray
      this.totalRecords = resultArray.length;
      //this.logger.log("totalRecords:" + this.totalRecords);
      this.vaccinePurchasingReportArray = resultArray;
      //this.logger.log("this.vaccinePurchasingReportArray:" + this.vaccinePurchasingReportArray.length);
      setTimeout(() => {
        this.spinner.hide();
        this.loading = false;
        this.canExport = true;
        this.showGrid = true;
      }, 1000)
    }, error => {
      this.spinner.hide();
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.vaccinePurchasingReportArray = [];
      this.totalRecords = 0;
    });
  }
  generateReportFormat(): void {
    this.loading = true;
    let data = this.vaccinePurchasingReportForm.value;
    let vaccine_purchase = new VaccinePurchase(data.fDate, data.tDate, this.isCurrentSeason, this.ddlVaccines.sort().join(','),true, "VaccinePurchase", data.ddlFormat);
    this.vaccinepurchasingreport.exportReportData(vaccine_purchase).subscribe((res:any) => {
      importedSaveAs(res.body, 'VaccinePurchase.xlsx');
      this.loading = false;
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
  setDates() {
    let tempMinDate: Date;
    let tempMaxDate: Date;
    if(this.vaccinePurchasingReportForm!=undefined){
      this.ddlSeason=this.vaccinePurchasingReportForm.controls['ddlSeason'].value;

    }
    tempMinDate = new Date(ApplicationSettings.getCovidStartDate(this.ddlSeason));
    tempMaxDate = new Date(ApplicationSettings.getCovidEndDate(this.ddlSeason));
    this.minDate = new Date(tempMinDate);
    this.maxDate = new Date(tempMaxDate)
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    this.canExport = false;
    if(this.vaccinePurchasingReportForm!=undefined){
      this.vaccinePurchasingReportForm.patchValue({
        fDate:this.fromDate,
        tDate:this.toDate
      })
    }
  }
  setCalendarDates() {
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    this.canExport = false;
  }
  reset(): void {
    this.showGrid = false;
    this.loading = false;
    this.ddlSeason = this.currentSeason;
    this.setDates();
    this.isCurrentSeason = 1;
    this.ddlFormat = 0;
    this.setFormControls();
    //this.generateReport();
  }
  validateExportType() {
    if (this.ddlFormat == "0" || !this.canExport/*==false*/)
      return true;
    else
      return false;
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
  vaccineChange(ev, val) {
    if (ev.target.checked) {
      this.ddlVaccines.push(val);
    } else {
      let i = this.ddlVaccines.indexOf(val);
      this.ddlVaccines.splice(i, 1);
    }
  }
}
