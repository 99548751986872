import { Component, OnInit, ViewChild, SimpleChange, Input } from '@angular/core';
//import { Table } from '../../../../../../node_modules/primeng/table';
import { PreviewService } from '../../services/preview.service';
import { Table } from 'primeng/table';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { Location, LocationStrategy } from '@angular/common';
import { SessionDetails } from '../../../../utility/session';
import { defaultSiteDesignInfo } from '../../../../JSON/schedulerDefaultValues';
import { SchedulerregistrationService } from '../../services/schedulerregistration.service';
import { environment } from '../../../../../environments/environment';
import { CorporateTrackerSchedulerClinics } from '../../../../models/CorporateTrackerSchedulerClinics';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { ErrorMessages } from '../../../../../../src/app/config-files/error-messages';


@Component({
  selector: 'app-client-scheduler-appt-tracker',
  templateUrl: './client-scheduler-appt-tracker.component.html',
  styleUrls: ['./client-scheduler-appt-tracker.component.css']
})
export class ClientSchedulerApptTrackerComponent implements OnInit {
  first:number;
  cols: any[];
  index: number = 0;
  showVarHCSLocal: boolean = false;
  showVarHCS: boolean = false;
  searchValue: string = '';
  date3: any;
  appointmentCols: any[];
  corporate_client_list: any;
  appTbl: any = [];
  backVisible: boolean = false;
  scheduledAppointmentInfo: any;
  siteDesignInfo: any = {};
  @ViewChild('appointmenttbl', {static : false})
  private table: Table;
  @ViewChild('appointmenttbl', {static : false}) dataTableClinic: Table;
  BannerColor: string = '';
  BannerTextColor: string = '';
  ButtonBgColor: string = '';
  ButtonTextcolor: string = '';
  clientLogo: string = "";
  businessName: string = '';
  clinicsList: any = [];
  ddlApptType: number = 0;
  clinicPk: number = 0;
  totalScheduledCount: number = 0;
  totalUnscheduledCount: number = 0;
  totalImmunizers: number = 0;
  selectedDataList: any = [];
  @Input() clinicDetails: any;

  dialogSummary: string;
  dialogMsg: string;
  errorPopUp: boolean = false;
  savePopup: boolean = false;

  userProfile: any;

  showBlockUnblockButtons: boolean = false;
  // 

  ctrlKeyDown = false;
  recordsExist: boolean = false;
  confirmPopup: boolean = false;
  isBlockAppts: boolean;
  block_unblock_apts_obj: any = {
    "eeApptTimeSlots": []
  };
  confirmationId: any = [];
  pageIndex: number;
  constructor(private _location: Location, private previewService: PreviewService, private scheduler_regisration: SchedulerregistrationService, private spinner: NgxSpinnerService, private router: Router, private location: LocationStrategy, private messageService: MessageServiceService) {

    this.userProfile = SessionDetails.GetUserProfile();

    this.scheduledAppointmentInfo = SessionDetails.getSchedulerUserInfo();
    if (!this.scheduledAppointmentInfo) {
      if (sessionStorage["corporateTrackInfo"]) {
        this.scheduledAppointmentInfo = JSON.parse(sessionStorage["corporateTrackInfo"]);
        sessionStorage.removeItem("corporateTrackInfo");
      } else {
        this.scheduledAppointmentInfo = {
          "designPk": -1,
          "redirectTo": "schedulerRegistration",
          "apptPk": -1,
          "clinicPk": -1
        };
      }
    } else {
      sessionStorage.removeItem("sI");
    }
    this.scheduler_regisration.getSchedulerSiteDesign(this.scheduledAppointmentInfo.designPk).subscribe((result: any) => {
      if (Object.keys(result).length > 0) {
        if (!result.clientInfo.enryptedClientKey || this.clinicPk > 0) {
          result.siteLogoAndStyles = defaultSiteDesignInfo.defaultDesignValues;
          result.siteLandingPageText = defaultSiteDesignInfo.defaultTextValues;
        }
        
        this.siteDesignInfo = result;
        this.businessName = (this.siteDesignInfo.clientInfo.businessName) ? this.siteDesignInfo.clientInfo.businessName : '';
        this.BannerColor = this.siteDesignInfo.siteLogoAndStyles.bannerColor;
        this.clientLogo = ((this.siteDesignInfo.siteLogoAndStyles && this.siteDesignInfo.siteLogoAndStyles.logoFile &&
          this.siteDesignInfo.siteLogoAndStyles.logoFile !== "string") ? environment.RESOURCES.CORPORATE_SCHEDULER_UPLOADED_LOGOS_URL + this.siteDesignInfo.siteLogoAndStyles.logoFile : '');

        if (this.clinicPk) {
          this.clientLogo = "assets/images/Walgreens_Logo_2022.png";
        }
        this.BannerTextColor = this.siteDesignInfo.siteLogoAndStyles.bannerTextColor;
        //this.ButtonBgColor = this.siteDesignInfo.siteLogoAndStyles.buttonColor;
        this.ButtonBgColor = "#323264";
        this.ButtonTextcolor = this.siteDesignInfo.siteLogoAndStyles.buttonTextcolor;
      }
    });
  }

  ngOnInit() {
    if (!this.clinicDetails) {
      this.location.onPopState(() => {
        // set isBackButtonClicked to true.
        this.messageService.setNavigationClicked(true);
        return false;
      });
      document.addEventListener('keyup', () => {
        this.ctrlKeyDown = false;
      });
      document.addEventListener('keydown', (e) => {
        if ((e.which || e.keyCode) == 116 || ((e.which || e.keyCode) == 82 && this.ctrlKeyDown)) {
          // Pressing F5 or Ctrl+R
          //e.preventDefault();
          if (this.scheduledAppointmentInfo) {
            sessionStorage["corporateTrackInfo"] = JSON.stringify(this.scheduledAppointmentInfo);
          }
          if (this.siteDesignInfo) {
            sessionStorage["corporateTrackDesignInfo"] = JSON.stringify(this.siteDesignInfo);
          }
          if (this.clinicDetails) {
            sessionStorage["event_details"] = JSON.stringify(this.clinicDetails);
          }
        } else if ((e.which || e.keyCode) == 17) {
          // Pressing  only Ctrl
          this.ctrlKeyDown = true;
        }
      });
      window.onbeforeunload = (ev) => {
        if (this.scheduledAppointmentInfo) {
          sessionStorage["corporateTrackInfo"] = JSON.stringify(this.scheduledAppointmentInfo);
        }
        if (this.siteDesignInfo) {
          sessionStorage["corporateTrackDesignInfo"] = JSON.stringify(this.siteDesignInfo);
        }
        // if (this.clinicDetails) {
        //   sessionStorage["event_details"] = JSON.stringify(this.clinicDetails);
        // }
      };
    }
    if (this.clinicDetails && this.clinicDetails.clinicPk > 0) {
      sessionStorage.removeItem("sI");
      this.clinicPk = this.clinicDetails.clinicPk;
      this.backVisible = true;
    }

    this.getCorporateClientApptsCounts();
    this.showBlockUnblockButtons = (this.clinicPk > 0 || this.userProfile.userRole === 'Admin'
      || this.userProfile.userRole === 'Pharmacy Manager' || this.userProfile.userRole === 'Store Manager'
      || this.userProfile.userRole === 'District Manager' || this.userProfile.userRole === 'Healthcare Supervisor'
      || this.userProfile.userRole === 'Director - Rx and Retail Ops' || this.userProfile.userRole === 'Regional Vice President'
      || this.userProfile.userRole === 'Regional Healthcare Director') && (this.userProfile.userName !== 'sm_schedule.appointment');
    //this.getCorporateClientList();
    this.getAppointmentTrackingList();
    if (this.clinicPk > 0) {
      this.appointmentCols = [
        { field: 'businessClinic', header: 'Clinic' },
        { field: 'clinicAddress', header: 'Location' },
        { field: 'apptDate', header: 'Appt Date' },
        { field: 'apptAvlTimes', header: 'Appt Time' },
        { field: 'apptTimeSlot', header: 'Time Slot' },
        { field: 'PatientName', header: 'Name' },
        // { field: 'employeeId', header: 'Employee ID' },
        { field: 'confirmationId', header: 'Confirmation ID' }
      ];
    }
    else if (this.clinicPk == 0) {
      this.appointmentCols = [
        { field: 'businessClinic', header: 'Clinic' },
        { field: 'clinicAddress', header: 'Location' },
        { field: 'apptDate', header: 'Appt Date' },
        { field: 'apptAvlTimes', header: 'Appt Time' },
        { field: 'apptTimeSlot', header: 'Time Slot' },
        { field: 'confirmationId', header: 'Confirmation ID' }
      ];
    }

  }


  onTableHeaderCheckboxToggle(event: any) {
    if (event.checked == true) {
      if (this.first == 0) {
        this.pageIndex = 1
      }
      else if (this.first > 0) {
        this.pageIndex = (this.first / 10) + 1
      }
      let slicedArray = this.appTbl.slice(((this.pageIndex - 1) * (10)), this.pageIndex * 10);
      this.selectedDataList = slicedArray;
    }
  }

  paginate(event) {
    this.selectedDataList = [];
  }

  displayFieldCss(hName: string) {
    if (hName == 'Location') {
      return 'wth250';
    }

    else {
      return '';
    }
  }

  disabledEnableRow(obj: any) {
    if (obj && obj.isBlocked) {
      return 'trackerApptDisabled';
    } else {
      return 'trackerApptEnabled';
    }
  }

  clFld() {
    this.searchValue = '';
    this.table.reset();
  }
  handleChange(e) {
    this.index = e.index;
  }

  onClickedOutside(e: Event) {
    this.showVarHCS = false;
  }
  onClickedOutsidee(e: Event) {
    this.showVarHCSLocal = false;
  }
  storeSearchHCS() {
    this.showVarHCS = !this.showVarHCS;
  }

  getAppointmentTrackingList() {
    this.recordsExist = true;
    let is_client = (this.scheduledAppointmentInfo.designPk > 0) ? 1 : 0;
    let client_id = (is_client) ? this.scheduledAppointmentInfo.designPk : this.clinicPk;
    let corporate_tracker_sch_clinics = new CorporateTrackerSchedulerClinics(is_client, client_id, this.ddlApptType, "", "");
    this.previewService.getAppointmentTrackingDetails(corporate_tracker_sch_clinics).subscribe((data: any) => {
      this.clinicsList = data;
      this.appTbl = data;//.slice(start_number, (start_number + 10));
      this.recordsExist = data.length > 0 ? true : false;
      this.table.reset();
    });
  }

  filterChanged() {
    this.getAppointmentTrackingList();
  }

  refreshClicked() {
    this.ddlApptType = 0;
    this.dataTableClinic.first = 0;
    this.dataTableClinic.paginator;
    this.getAppointmentTrackingList();
    this.getCorporateClientApptsCounts();
  }

  getCorporateClientList() {
    this.previewService.getCorporateClientList().subscribe((data: any) => {
      this.corporate_client_list = data.corporateClientList;
    });
  }

  getCorporateClientApptsCounts() {
    this.previewService.getAppointmentTrackingCounts(this.scheduledAppointmentInfo.designPk, this.clinicPk).subscribe(result => {
      this.totalImmunizers = result[0].numImmunizers;
      this.totalScheduledCount = result[0].totalScheduled;
      this.totalUnscheduledCount = result[0].totalUnscheduled;
    });
  }

  refreshStoreSearch() {
    this.searchValue = "";
    this.ddlApptType = 0;
    this.selectedDataList = [];
    this.dataTableClinic.first = 0;
    this.dataTableClinic.paginator;
    this.getAppointmentTrackingList();
    this.getCorporateClientApptsCounts();
  }
  navigateApptTracker() {
    sessionStorage["corporateTrackInfo"] = JSON.stringify(this.scheduledAppointmentInfo);
    sessionStorage["corporateTrackDesignInfo"] = JSON.stringify(this.siteDesignInfo);
    if (this.clinicDetails) {
      sessionStorage["event_details"] = JSON.stringify(this.clinicDetails);
    }
    this.messageService.setNavigationClicked(false);
    //this.router.navigate(['/schedulerAppttracking']);
    if (this.clinicPk > 0) {
      this.router.navigate(['/corpScheduledAppts']);//communityoutreach/
    } else {
      this.router.navigate(['/schedulerAppttracking']);
    }
  }
  GoTo() {
    if (this.clinicPk > 0) {
      this.messageService.setNavigationClicked(false);
      sessionStorage.removeItem("event_details");
      this.router.navigate(['/communityoutreach/storehome'])
    }
  }

  blockUnblockAppts(is_block_appts: boolean) {
    this.isBlockAppts = is_block_appts;
    this.block_unblock_apts_obj.eeApptTimeSlots = [];
    this.confirmationId = [];
    if (is_block_appts) {
      this.selectedDataList.forEach(element => {
        var temp = {};
        if (!element.isBlocked && element.PatientName != 'PHARMACIST BREAK') {
          temp["designPk"] = (this.scheduledAppointmentInfo.designPk > 0) ? 1 : 0;
          temp["designerPk"] = (this.scheduledAppointmentInfo.designPk > 0) ? this.scheduledAppointmentInfo.designPk : 0;
          temp["clinicPk"] = element.clinicPk;
          temp["apptPk"] = element.apptPk;
          temp["apptTimePk"] = element.apptTimePk;
          this.block_unblock_apts_obj.eeApptTimeSlots.push(temp);
          if (element.confirmationId != null)
            this.confirmationId.push(element.confirmationId);
        }
      });
    } else {
      this.selectedDataList.forEach(element => {
        var temp = {};
        if (element.isBlocked || element.PatientName === 'PHARMACIST BREAK') {
          temp["designPk"] = (this.scheduledAppointmentInfo.designPk > 0) ? 1 : 0;
          temp["designerPk"] = (this.scheduledAppointmentInfo.designPk > 0) ? this.scheduledAppointmentInfo.designPk : 0;
          temp["clinicPk"] = element.clinicPk;
          temp["apptPk"] = element.apptPk;
          temp["apptTimePk"] = element.apptTimePk;
          this.block_unblock_apts_obj.eeApptTimeSlots.push(temp);
        }
      });
    }
    if (this.confirmationId.length > 0) {
      this.dialogMsg = "Are you sure you want to block the selected appointment(s)? Blocking any appointment slots that have already been scheduled will send an appointment cancellation email to the appointment holder. This action cannot be undone.";
      this.dialogSummary = "Confirm Block Appointments";
      this.confirmPopup = true;
      return false;
    }
    else if (this.block_unblock_apts_obj.eeApptTimeSlots.length > 0) {
      this.confirmblockUnblockAppts();
    } else {
      let msg: string = "";
      msg = 'Please select an appointment to ' + ((is_block_appts) ? 'block' : 'unblock');
      this.showDialogError("Block/Unblock Appointments", msg);
      //show message
    }
  }
  confirmblockUnblockAppts() {
    this.spinner.show();
    this.previewService.blockUnblockAppts(this.isBlockAppts, this.block_unblock_apts_obj).subscribe((result: any) => {
      this.spinner.hide();
      this.selectedDataList = [];
      let msg: string = "";
      let sch_unsch_text: string = "";
      if (this.ddlApptType === 1 || this.ddlApptType === 3) {
        sch_unsch_text = " Scheduled ";
      } else if (this.ddlApptType === 2) {
        sch_unsch_text = " Unscheduled ";
      } else if (this.ddlApptType === 0) {
        sch_unsch_text = " ";
      }
      msg = "Selected " + sch_unsch_text + "appointment(s) " + ((this.isBlockAppts) ? 'blocked' : 'unblocked') + " successfully."
      this.showDialogSaved("Block/Unblock Appointments", msg);
      this.confirmPopup = false;
    },
      err => {
        this.showDialogError("Block/Unblock Appointments", ErrorMessages.unKnownError);
        this.spinner.hide();
      });
}
Error() {
  this.errorPopUp = false;
}
Ok() {
  this.refreshStoreSearch();
  this.savePopup = false;
}
showDialogError(msgSummary: string, msgDetail: string) {
  this.dialogMsg = msgDetail;
  this.dialogSummary = msgSummary;
  this.errorPopUp = true;
}
showDialogSaved(msgSummary: string, msgDetail: string) {
  this.dialogMsg = msgDetail;
  this.dialogSummary = msgSummary;
  this.savePopup = true;
}
showRowCheckboxes() {
  return ((this.showBlockUnblockButtons) ? '' : 'showTrackerGridCheckbox');
}

ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
  if (changes["clinicDetails"]) {
    this.clinicDetails = changes["clinicDetails"].currentValue;
  }
}
}
