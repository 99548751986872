import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { Subject, Subscription } from '../../../../../../../node_modules/rxjs';

@Component({
  selector: 'app-storeprofileuploaderstatus',
  templateUrl: './storeprofileuploaderstatus.component.html',
  styleUrls: ['./storeprofileuploaderstatus.component.css']
})
export class StoreprofileuploaderstatusComponent implements OnInit {
  subscription: Subscription;
  step: number = 1;
  clinicstatus: string = "Completed Clinic";
  clickedPath = 'LocalClinicProgramDetails';
  message: any;
  store_id: any;

  @Input("totalSteps") totalSteps: any;
  @Input("viewSteps") viewSteps: any;
  @Input("currentStep") currentStep: any;
  @Output() loadPage = new EventEmitter<any>();

  private onDestroy$ = new Subject<void>();
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes["viewSteps"]) {
      this.viewSteps = changes["viewSteps"].currentValue;
    }
    if (changes["totalSteps"]) {
      this.totalSteps = changes["totalSteps"].currentValue;
    }
    this.currentStep = ((changes["currentStep"].currentValue) ? changes["currentStep"].currentValue : this.step);

    this.GoToStep(this.currentStep, false);
  }

  GoToStep(step: number, is_clicked: boolean) {
    if (step === 2) {
      step = 2;
      // this.clinicstatus = 'Completed Clinic';
    }
    if (step === -1) {
      if (this.totalSteps == 2) {
        step = 2;
        // this.clinicstatus = 'Cancelled Clinic';
      } else if (this.totalSteps == 3) {
        step = 3;
        // this.clinicstatus = 'Cancelled Clinic';
      }
    }

    this.step = step;
    if (step === 1 && is_clicked) {
      let emit_json = {
        "uploadedOn": "",
        "stepNumber": 1,
        "uploadMode": "-1"
      };
      this.loadPage.emit(emit_json);
    }
    return;
  }

}
