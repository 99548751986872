import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { DistrictCompliance } from '../../../models/DistrictCompliance';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class DistrictcomplianceService {
  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL; 
  constructor(private http: HttpClient) { }

  public getDistrictComplianceReportData(district_compliance : DistrictCompliance) {
    let url = this._url + "/api/JsDistrictCompliance_get";
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.post(url,district_compliance, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  public getDistrictComplianceReportDistrictsData() {
    let url = this._url + "/api/JsGetDistricts_DistrictCompliance";
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  public exportReportData(district_compliance : DistrictCompliance) {
    let user_data = SessionDetails.GetUserInfo();
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,user_data.userName + ':'+ user_data.password);                    
    let url = this._url + "/api/JsDistrictCompliance_downloadReport";
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    return this.http.post(url, district_compliance , {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
    .pipe(catchError(Util.handleErrorV2));
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });

  }

}
