import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, Form } from '@angular/forms';
import { OutreachProgramService } from '../../services/outreach-program.service';
import { LocationNumberPipe } from '../../pipes/location-number.pipe';
import { Clinic, Immunization2 } from '../../../../models/contract';
import { TimeToDatePipe } from '../../pipes/time-to-date.pipe';
import { DatePipe } from '@angular/common';
import { OutReachProgramType } from '../../../../models/OutReachPrograms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { SessionDetails } from '../../../../utility/session';
import { String } from 'typescript-string-operations';
import { Util } from '../../../../utility/util';
import { CommunityOutreach, EquityEvent } from '../../../../models/community-outreach-v1';
import { AlertService } from '../../../../alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as _ from 'lodash';
import { ClinicDetailsUtilities } from '../../../../utility/clinicDetailsUtilities';

@Component({
    selector: 'app-locations-list',
    templateUrl: './locations-list.component.html',
    styleUrls: ['./locations-list.component.css'],
    providers: [LocationNumberPipe, TimeToDatePipe, DatePipe]
})
export class LocationsListComponent implements OnInit {
    public contractForm: FormGroup;
    public addedImmunizations: Immunization2[];
    location_number: any = 0;
    @Input('outReachProgramType')
    outReachProgramType: string = OutReachProgramType.contracts;
    contractData: any;
    locListErrModalDlg: boolean = false;
    locListModalDlg: boolean = false;
    locConfirm250ModalDlg: boolean = false;
    coLocConfirm250ModalDlg: boolean = false;
    locConfirm25ModalDlg: boolean = false;
    dialogSummary: string;
    dialogMsg: string;
    fluExpiryDate: string = '';
    RoutineExpiryDate: string = '';
    CovidExpiryDate: string = '';
    userApprovedGt250: boolean = false;
    userApprovedLt25: boolean = false;
    isDateTimeConflictPresent = false;
    address1: string;
    address2: string;
    city: string;
    state: string;
    display_message: boolean = false;
    zipCode: string;
    previous_location_data: any[];
    previous_locations: any[];
    is_previous_locations: boolean = false;
    locations_data: FormGroup;
    locations_data1: FormGroup;
    event_details: any;
    @Output() clinicSubmit: EventEmitter<number> = new EventEmitter();
    @Output() showHideBtns: EventEmitter<number> = new EventEmitter();
    ccRegex: RegExp = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
    from_local_clinic: boolean = false;
    location25Names: string[] = [];
    location250Names: any[] = [];
    is_reAgreement: boolean = false;
    showDateTimeOverlpDlg: boolean = false;
    userOverriddenDateTimeConflict: boolean = false;
    OverrideOrOkTitle: string = "Override";
    userOverriddenVisitsGapConflict: boolean = false;
    showVisitsGapOverlpDlg: boolean = false;
    showCancelBtn: boolean = true;
    isCovidVaccineAvailable: boolean = false;
    isNonCovidVaccineAvailable: boolean = true;
    is_reAgreementCommOutreach: boolean = false;
    totalClinicsCount: number;

    constructor(private _fb: FormBuilder,
        private _localContractService: OutreachProgramService,
        private _locationNumberPipe: LocationNumberPipe,
        private _timeToDatePipe: TimeToDatePipe,
        private _datePipe: DatePipe, private confirmationService: ConfirmationService, private prouter: Router,
        private _alertService: AlertService,
        private utility: Util, private spinner: NgxSpinnerService) {
    }

    ngOnInit() {
        console.log(this.outReachProgramType + 'dhdh');
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        if (sessionStorage["AmendAgreement"] != null) {
            this.is_reAgreement = JSON.parse(sessionStorage["AmendAgreement"]) as boolean;
        }
        if (sessionStorage["AmendAgreementForCommOutreach"] != null) {
            this.is_reAgreementCommOutreach = JSON.parse(sessionStorage["AmendAgreementForCommOutreach"]) as boolean;
        }

        this._localContractService.clearSelectedInvoiceAndVouchersData();
        this.from_local_clinic = SessionDetails.GetfromUserPage();
        this.spinner.show();
        this.contractForm = this._fb.group({
            locations: this._fb.array([])
        });

        this.is_previous_locations = false;
        this.display_message = false;

        this.userApprovedGt250 = false;
        this.userApprovedLt25 = false;

        if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
            this.contractData = this._localContractService.fetchCOAgreementData();
        }
        else if (this.outReachProgramType === OutReachProgramType.equityevents) {

            this.contractData = this._localContractService.fetchCOAgreementData();
        }
        else {
            this.contractData = this._localContractService.fetchContractAgreementData();
        }

        if (this.outReachProgramType === OutReachProgramType.communityoutreach && this.is_reAgreementCommOutreach) {
            this.contractData = this._localContractService.fetchContractAgreementData();
        }
        else if(this.outReachProgramType === OutReachProgramType.equityevents && this.is_reAgreementCommOutreach)
        {this.contractData = this._localContractService.fetchContractAgreementData();}

        this.addedImmunizations = this.contractData.clinicImmunizationList;
        let oppurtunitiesData: any = SessionDetails.GetopportunitiesData();
        //For restrction states
        if (this.utility.isStoreIsfromRestrictedState()) {
            this._localContractService.getpreviousseasoncliniclocations(this.contractData.clinicAgreementPk, oppurtunitiesData.businessPk).subscribe((res: any) => {
                this.previous_location_data = res.previousLocationsList;
                if (this.previous_location_data.length > 1) {
                    this.is_previous_locations = true;
                    this.display_message = true;
                }
                else {
                    this.display_message = false;
                }
            });
        }

        if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
            this.isCovidVaccineAvailable = this._localContractService.getCOSelectedImmunizations().filter(p => p.immunizationName.search(/COVID-19/gi) >= 0).length > 0;
            let isFluVaccineAvailable: boolean = this._localContractService.getCOSelectedImmunizations().filter(p => p.immunizationName.search(/Influenza/gi) >= 0).length > 0;
            let isRoutineVaccineAvailable: boolean = false;

            isRoutineVaccineAvailable = this._localContractService.getCOSelectedImmunizations().filter(p => (p.immunizationName.search(/Influenza/gi) == -1) && p.immunizationName.search(/COVID-19/gi) == -1).length > 0;

            if (isFluVaccineAvailable || isRoutineVaccineAvailable) {
                this.isNonCovidVaccineAvailable = true;
            }
            else {
                this.isNonCovidVaccineAvailable = false;
            }
        }
        else if (this.outReachProgramType === OutReachProgramType.equityevents) {
          

            this.isCovidVaccineAvailable = this._localContractService.getCOSelectedImmunizations().filter(p => p.immunizationName.search(/COVID-19/gi) >= 0).length > 0;
            let isFluVaccineAvailable: boolean = this._localContractService.getCOSelectedImmunizations().filter(p => p.immunizationName.search(/Influenza/gi) >= 0).length > 0;
            let isRoutineVaccineAvailable: boolean = false;

            isRoutineVaccineAvailable = this._localContractService.getCOSelectedImmunizations().filter(p => (p.immunizationName.search(/Influenza/gi) == -1) && p.immunizationName.search(/COVID-19/gi) == -1).length > 0;

            if (isFluVaccineAvailable || isRoutineVaccineAvailable) {
                this.isNonCovidVaccineAvailable = true;
            }
            else {
                this.isNonCovidVaccineAvailable = false;
            }
        }
        else {
            this.isCovidVaccineAvailable = this._localContractService.getSelectedImmunizations().filter(p => p.immunizationName.search(/COVID-19/gi) >= 0).length > 0;
            let isFluVaccineAvailable: boolean = this._localContractService.getSelectedImmunizations().filter(p => p.immunizationName.search(/Influenza/gi) >= 0).length > 0;
            let isRoutineVaccineAvailable: boolean = false;

            isRoutineVaccineAvailable = this._localContractService.getSelectedImmunizations().filter(p => (p.immunizationName.search(/Influenza/gi) == -1) && p.immunizationName.search(/COVID-19/gi) == -1).length > 0;

            if (isFluVaccineAvailable || isRoutineVaccineAvailable) {
                this.isNonCovidVaccineAvailable = true;
            }
            else {
                this.isNonCovidVaccineAvailable = false;
            }
        }
        //this.addedImmunizations = this.contractData.contractAgreement.clinicImmunizations;
        if (this.contractData.clinicList.length > 0) { // for clinics which are already present
            this.totalClinicsCount = this.contractData.clinicList.length;
            this.contractData.clinicList.forEach((loc, index) => {
                let clinic_immunizations: any[] = [];
                this.addedImmunizations.forEach(rec => {
                    let imz: any = {};
                    let matching_imz = loc.clinicImzQtyList.filter(item => item.immunizationPk == rec.immunizationPk && item.paymentTypeId == rec.paymentTypeId);
                    imz.immunizationPk = rec.immunizationPk;
                    imz.immunizationName = rec.immunizationName;
                    imz.paymentTypeName = rec.paymentTypeName;
                    imz.paymentTypeId = rec.paymentTypeId;
                    imz.immunizationAbvNameEN = rec.immunizationAbvNameEN;
                    imz.estimatedQuantity = matching_imz[0] ? matching_imz[0].estimatedQuantity : "";

                    if (rec.immunizationName.toLowerCase().search('flu') !== -1 && rec.voucherExpirationDate !== undefined && rec.voucherExpirationDate !== null) {
                        this.fluExpiryDate = rec.voucherExpirationDate;
                    }
                    if (rec.immunizationName.toLowerCase().search('flu') === -1 && rec.voucherExpirationDate !== undefined && rec.voucherExpirationDate !== null) {
                        this.RoutineExpiryDate = rec.voucherExpirationDate;
                    }
                    if (rec.immunizationName.toLowerCase().search('covid') !== -1 && rec.voucherExpirationDate !== undefined && rec.voucherExpirationDate !== null) {
                        this.CovidExpiryDate = rec.voucherExpirationDate;
                    }
                    clinic_immunizations.push(imz);
                });

                loc.clinicImzQtyList = clinic_immunizations;
                this.addLocation(loc);
            });
            this.spinner.hide();
        }
        else { //new contract
            this.spinner.show();
            let location: Clinic = new Clinic();
            location.clinicDate = '';
            location.location = "CLINIC " + this._locationNumberPipe.transform(this.location_number + 1);

            if (oppurtunitiesData.businessPk !== undefined && oppurtunitiesData.businessPk !== null && oppurtunitiesData.businessPk !== "") {

                this._localContractService.getStoreBusinessDetails(oppurtunitiesData.businessPk).subscribe((res: any) => {
                    location.address1 = res.address1;
                    location.address2 = res.address2;
                    location.city = res.city;
                    location.state = res.state;
                    location.zipCode = res.zipCode;

                    let clinic_immunizations: any[] = [];
                    this.addedImmunizations = this.contractData.clinicImmunizationList;
                    this.addedImmunizations.forEach(rec => {
                        let imz: any = {};
                        imz.immunizationPk = rec.immunizationPk;
                        imz.immunizationName = rec.immunizationName;
                        imz.immunizationAbvNameEN = rec.immunizationAbvNameEN;
                        imz.paymentTypeName = rec.paymentTypeName;
                        imz.paymentTypeId = rec.paymentTypeId;
                        imz.estimatedQuantity = "";

                        if (rec.immunizationName.toLowerCase().search('flu') !== -1 && rec.voucherExpirationDate !== undefined && rec.voucherExpirationDate !== null) {
                            this.fluExpiryDate = rec.voucherExpirationDate;
                        }
                        if (rec.immunizationName.toLowerCase().search('flu') === -1 && rec.voucherExpirationDate !== undefined && rec.voucherExpirationDate !== null) {
                            this.RoutineExpiryDate = rec.voucherExpirationDate;
                        }
                        if (rec.immunizationName.toLowerCase().search('covid') !== -1 && rec.voucherExpirationDate !== undefined && rec.voucherExpirationDate !== null) {
                            this.CovidExpiryDate = rec.voucherExpirationDate;
                        }
                        clinic_immunizations.push(imz);
                    });

                    location.clinicImzQtyList = clinic_immunizations;
                    this.addLocation(location);
                    this.spinner.hide();
                }
                    ,
                    err => {
                        this.spinner.hide();
                        switch (err) {
                            case 500:
                                this.showDialog(ErrorMessages['serverError'], err.json().Message);
                                return false;
                            case 400:
                                this.showDialog(ErrorMessages['clientError'], err.json().Message);
                                return false;
                            default:
                                this.showDialog(ErrorMessages['serverError'], err.json().Message);
                                return false;
                        }
                    });
            } else {
                this.showDialog(ErrorMessages['serverError'], "An error has occurred. Pleaase contact administrartor.");
                return false;
            }
        }

        this.event_details = SessionDetails.GetEventDetails();
        if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
            this.isCovidVaccineAvailable = this._localContractService.getCOSelectedImmunizations().filter(p => p.immunizationName.search(/COVID-19/gi) >= 0).length > 0;
        }
        else if (this.outReachProgramType === OutReachProgramType.equityevents) {
            this.isCovidVaccineAvailable = this._localContractService.getCOSelectedImmunizations().filter(p => p.immunizationName.search(/COVID-19/gi) >= 0).length > 0;
        }
        else {
            this.isCovidVaccineAvailable = this._localContractService.getSelectedImmunizations().filter(p => p.immunizationName.search(/COVID-19/gi) >= 0).length > 0;
        }
        this.spinner.hide();
    }
    disableButton() {
        if (this.display_message || this.from_local_clinic || this.is_reAgreement) {
            return 'false';
        }
        else {
            return '';
        }
    }
    PreviousLocationChange(event) {
        if (event != "") {
            this.display_message = false;
            this.previous_locations = this.previous_location_data.filter(item => item.rowId == event);
        }
        else {
            this.display_message = true;

        }
    }
    initLocation(location: Clinic) {
        let clinic_immunizations: any[];
        let previous_display: boolean = true;
        this.location_number = this.location_number + 1;
        let covidVaccine = false, isVoucherOnly = false;
        if (this.previous_locations != undefined) {
            if (this.previous_locations.length > 0) {
                this.address1 = this.previous_locations[0].address;
                this.address2 = this.previous_locations[0].address2;
                this.city = this.previous_locations[0].city;
                this.state = this.previous_locations[0].state;
                this.zipCode = this.previous_locations[0].zip;
                previous_display = false;
            }
        }
        let added_immunizations;
        if (this.outReachProgramType == OutReachProgramType.communityoutreach) {
            added_immunizations = this._localContractService.getCOSelectedImmunizations();
        }
        else if (this.outReachProgramType == OutReachProgramType.equityevents) {
            added_immunizations = this._localContractService.getCOSelectedImmunizations();
        }
        else {
            added_immunizations = this._localContractService.getSelectedImmunizations();
        }
        covidVaccine = added_immunizations.filter(p => p.immunizationName.includes('COVID-19 Vaccine')).length > 0;
        isVoucherOnly = added_immunizations.filter(item => item.immunizationName.includes('Vaccine Voucher')).length > 0;

        if (location === undefined) {

            clinic_immunizations = [];
            location = new Clinic();

            // commenting 
            //location.clinicDate = this._datePipe.transform(new Date(today.getFullYear(),today.getMonth(), today.getDate(), 12,30,30),"MM/dd/yyyy hh:mm:ss");
            location.clinicDate = '';
            location.location = "CLINIC " + this._locationNumberPipe.transform(this.location_number);

            added_immunizations.forEach(rec => {
                let imz: any = {};
                imz.immunizationPk = rec.immunizationPk;
                imz.immunizationName = rec.immunizationName;
                imz.immunizationAbvNameEN = rec.immunizationAbvNameEN;
                imz.paymentTypeName = rec.paymentTypeName;
                imz.paymentTypeId = rec.paymentTypeId;
                imz.estimatedQuantity = "";

                if (rec.voucherExpirationDate !== undefined && rec.voucherExpirationDate !== null) {
                    if (rec.immunizationName.toLowerCase().search('flu') !== -1 && rec.voucherExpirationDate.valueOf() > 0) {
                        this.fluExpiryDate = rec.voucherExpirationDate;
                    }
                    if (rec.immunizationName.toLowerCase().search('flu') === -1 && rec.voucherExpirationDate.valueOf() > 0) {
                        this.RoutineExpiryDate = rec.voucherExpirationDate;
                    }
                    if (rec.immunizationName.toLowerCase().search('covid') !== -1 && rec.voucherExpirationDate !== undefined && rec.voucherExpirationDate !== null) {
                        this.CovidExpiryDate = rec.voucherExpirationDate;
                    }
                }
                clinic_immunizations.push(imz);
            });
        }
        else {
            clinic_immunizations = location.clinicImzQtyList;
            clinic_immunizations.forEach(rec => {
                if (rec.voucherExpirationDate !== undefined && rec.voucherExpirationDate !== null) {
                    if (rec.immunizationName.toLowerCase().search('flu') !== -1 && rec.voucherExpirationDate.valueOf() > 0) {
                        this.fluExpiryDate = rec.voucherExpirationDate;
                    }
                    if (rec.immunizationName.toLowerCase().search('flu') === -1 && rec.voucherExpirationDate.valueOf() > 0) {
                        this.RoutineExpiryDate = rec.voucherExpirationDate;
                    }
                    if (rec.immunizationName.toLowerCase().search('covid') !== -1 && rec.voucherExpirationDate !== undefined && rec.voucherExpirationDate !== null) {
                        this.CovidExpiryDate = rec.voucherExpirationDate;
                    }
                }
            });
            if (this.utility.isStoreIsfromRestrictedState() && location != undefined && previous_display) {
                this.address1 = location.address1;
                this.address2 = location.address2;
                this.city = location.city;
                this.state = location.state;
                this.zipCode = location.zipCode;
            }
        }

        if (this.outReachProgramType == OutReachProgramType.contracts) {
            let hasCorpInvoice = this.checkIfAnyImmunizationsHasCorpInvoice(location);
            let hasCorpInvAndVchrNd = this.checkIfAnyImmunizationsHasCorpInvoiceAndVoucherNeeded();
            // 775 fix
            let isNoClinic: number = (location.isNoClinic === undefined || location.isNoClinic === null || Number(location.isNoClinic) <= 0) ? 0 : Number(location.isNoClinic);
            if (covidVaccine && isVoucherOnly && hasCorpInvoice && hasCorpInvAndVchrNd) {
                isNoClinic = 1;
            }
            if (!hasCorpInvAndVchrNd) {
                isNoClinic = 0;
                location.isNoClinic = 0;
            }
            return this._fb.group({
                clinicImzQtyList: this.initImmunizations(clinic_immunizations),
                location: this._fb.control(location.location),
                contactFirstName: this._fb.control(location.contactFirstName, Validators.required),
                contactLastName: this._fb.control(location.contactLastName, Validators.required),
                contactEmail: this._fb.control(location.contactEmail, Validators.required),
                contactPhone: this._fb.control(location.contactPhone, Validators.required),
                clinicDate: this._fb.control(location.clinicDate !== '' && location.clinicDate !== null ? new Date(location.clinicDate) :
                    location.clinicDate == null ? '' : location.clinicDate, isNoClinic ? null : Validators.required),
                startTime: this._fb.control(location.startTime, isNoClinic ? null : Validators.required),
                endTime: this._fb.control(location.endTime, isNoClinic ? null : Validators.required),
                address1: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.address1 != null || this.address1 != undefined) ? this.address1 : location.address1 : location.address1, [Validators.required, Validators.maxLength(40)]),
                address2: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.address2 != null || this.address2 != undefined) ? this.address2 : location.address2 : location.address2, [Validators.maxLength(60)]),
                city: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.city != null || this.city != undefined) ? this.city : location.city : location.city, Validators.required),
                state: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.state != null || this.state != undefined) ? this.state : location.state : location.state, Validators.required),
                zipCode: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.zipCode != null || this.zipCode != undefined) ? this.zipCode : location.zipCode : location.zipCode, Validators.required),

                estShots: this._fb.control(location.estShots),
                isReassign: this._fb.control(location.isReassign >= 1 ? 1 : 0, Validators.required),
                isNoClinic: this._fb.control(hasCorpInvoice ? isNoClinic : { value: hasCorpInvAndVchrNd ? 1 : 0, disabled: true }, null),
                previousContact: this._fb.control(+location.previousContact, null),
                previousLocation: this._fb.control(+location.previousLocation, null),
                isCurrent: this._fb.control(1),
                fluExpiryDate: this._fb.control({ value: new Date(this.fluExpiryDate), disabled: true }, null),
                routineExpiryDate: this._fb.control({ value: new Date(this.RoutineExpiryDate), disabled: true }, null),
                covidExpiryDate: this._fb.control({ value: new Date(this.CovidExpiryDate), disabled: true }, null),
                latitude: this._fb.control(location.latitude),
                longitude: this._fb.control(location.longitude),
                visitType: this._fb.control(location.visitType == '0' ? "0" : location.visitType ? location.visitType.toString() : "", this.isCovidVaccineAvailable ? null : null),
                clinicFee: this._fb.control(location.clinicFee),
                vouchersDistributed: this._fb.control(location.vouchersDistributed, Validators.min(0)),
                isNoClinicFee: this._fb.control(location.isNoClinicFee),
                estimatedParticipants: this._fb.control(location.estimatedParticipants, Validators.required)
                // dose1: this._fb.control(location.dose1, null),
                // dose2: this._fb.control(location.dose2, null)
            });
        }

        else if (this.outReachProgramType == OutReachProgramType.communityoutreach) {

            return this._fb.group({
                clinicImzQtyList: this.initImmunizations(clinic_immunizations),
                location: this._fb.control(location.location),
                contactFirstName: this._fb.control(location.contactFirstName, Validators.required),
                contactLastName: this._fb.control(location.contactLastName, Validators.required),
                contactEmail: this._fb.control(location.contactEmail, Validators.required),
                contactPhone: this._fb.control(location.contactPhone, Validators.required),
                clinicDate: this._fb.control(location.clinicDate, Validators.required),
                startTime: this._fb.control(location.startTime, Validators.required),
                endTime: this._fb.control(location.endTime, Validators.required),

                //  disabling fields if the store state belongs to restricted state
                address1: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.address1 != null || this.address1 != undefined) ? this.address1 : location.address1 : location.address1, Validators.required),
                address2: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.address2 != null || this.address2 != undefined) ? this.address2 : location.address2 : location.address2),
                city: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.city != null || this.city != undefined) ? this.city : location.city : location.city, Validators.required),
                state: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.state != null || this.state != undefined) ? this.state : location.state : location.state, Validators.required),
                zipCode: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.zipCode != null || this.zipCode != undefined) ? this.zipCode : location.zipCode : location.zipCode, Validators.required),

                isCurrent: this._fb.control(location.isCurrent),
                previousContact: this._fb.control(+location.previousContact, null),
                previousLocation: this._fb.control(+location.previousLocation, null),

                visitType: this._fb.control(location.visitType ? location.visitType.toString() : "", this.isCovidVaccineAvailable ? null : null),

                coOutreachTypeId: this._fb.control(location.coOutreachTypeId, Validators.required),
                coOutreachTypeDesc: this._fb.control(location.coOutreachTypeDesc, null),
                latitude: this._fb.control(location.latitude),
                longitude: this._fb.control(location.longitude)
                // dose1: this._fb.control(location.dose1, null),
                // dose2: this._fb.control(location.dose1, null)
            });
        }
        else if (this.outReachProgramType == OutReachProgramType.equityevents) {
          

            let isNoClinic: number = (location.isNoClinic === undefined || location.isNoClinic === null || Number(location.isNoClinic) <= 0) ? 0 : Number(location.isNoClinic);
            return this._fb.group({
                clinicImzQtyList: this.initImmunizations(clinic_immunizations),
                location: this._fb.control(location.location),
                contactFirstName: this._fb.control(location.contactFirstName, Validators.required),
                contactLastName: this._fb.control(location.contactLastName, Validators.required),
                contactEmail: this._fb.control(location.contactEmail, Validators.required),
                contactPhone: this._fb.control(location.contactPhone, Validators.required),
                clinicDate: this._fb.control(location.clinicDate !== '' && location.clinicDate !== null ? new Date(location.clinicDate) :
                    location.clinicDate == null ? '' : location.clinicDate, isNoClinic ? null : Validators.required),
                startTime: this._fb.control(location.startTime, isNoClinic ? null : Validators.required),
                endTime: this._fb.control(location.endTime, isNoClinic ? null : Validators.required),
                address1: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.address1 != null || this.address1 != undefined) ? this.address1 : location.address1 : location.address1, [Validators.required, Validators.maxLength(40)]),
                address2: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.address2 != null || this.address2 != undefined) ? this.address2 : location.address2 : location.address2, [Validators.maxLength(60)]),
                city: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.city != null || this.city != undefined) ? this.city : location.city : location.city, Validators.required),
                state: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.state != null || this.state != undefined) ? this.state : location.state : location.state, Validators.required),
                zipCode: this._fb.control(this.utility.isStoreIsfromRestrictedState() ? (this.zipCode != null || this.zipCode != undefined) ? this.zipCode : location.zipCode : location.zipCode, Validators.required),
                coOutreachTypeId: this._fb.control(location.coOutreachTypeId, Validators.required),
                coOutreachTypeDesc: this._fb.control(location.coOutreachTypeDesc, null),
                estShots: this._fb.control(location.estShots),
                isReassign: this._fb.control(location.isReassign >= 1 ? 1 : 0, Validators.required),
                isNoClinic: this._fb.control(location.isNoClinic, null),
                previousContact: this._fb.control(+location.previousContact, null),
                previousLocation: this._fb.control(+location.previousLocation, null),
                isCurrent: this._fb.control(1),
                fluExpiryDate: this._fb.control({ value: new Date(this.fluExpiryDate), disabled: true }, null),
                routineExpiryDate: this._fb.control({ value: new Date(this.RoutineExpiryDate), disabled: true }, null),
                covidExpiryDate: this._fb.control({ value: new Date(this.CovidExpiryDate), disabled: true }, null),
                latitude: this._fb.control(location.latitude),
                longitude: this._fb.control(location.longitude),
                visitType: this._fb.control(location.visitType == '0' ? "0" : location.visitType ? location.visitType.toString() : "", this.isCovidVaccineAvailable ? null : null),
                clinicFee: this._fb.control(location.clinicFee),
                vouchersDistributed: this._fb.control(location.vouchersDistributed, Validators.min(0))

                
            });
        }
    }

    checkIfAnyImmunizationsHasCorpInvoice(location: Clinic): boolean {
        let added_immunizations = this._localContractService.getSelectedImmunizations();
        try {
            let hasCorpInvoice: Boolean = false;
            var fluType = 'flu';
            let fluDone: Boolean = false;
            let routineDone: Boolean = false;
            added_immunizations.forEach(rec => {
                if (rec.paymentTypeId == 13 && (rec.sendInvoiceTo === '1' || rec.sendInvoiceTo === 1)) {
                    hasCorpInvoice = true;
                    if (rec.immunizationName.toLowerCase().search(fluType) == -1 && !routineDone) {
                        location.routineExpiryDate = rec.voucherExpirationDate;
                        routineDone = true;
                    }
                    else {
                        if (!fluDone) {
                            location.fluExpiryDate = rec.voucherExpirationDate;
                            fluDone = true;
                        }
                    }
                }
            });
            if (hasCorpInvoice == true)
                return true;
        } catch (e) {
            if (e === true)
                return true;
        }
        return false;
    }

    checkIfAnyImmunizationsHasCorpInvoiceAndVoucherNeeded(): boolean {
        let added_immunizations = this._localContractService.getSelectedImmunizations();
        try {
            let hasCorpInvoiceNdVchrNd: Boolean = false;
            added_immunizations.forEach(rec => {
                if (rec.paymentTypeId == 13 || rec.paymentTypeId == 6 && (rec.sendInvoiceTo === '1' || rec.sendInvoiceTo === 1) && (rec.isVoucherNeeded === '1' || rec.isVoucherNeeded === 1)) {
                    hasCorpInvoiceNdVchrNd = true;
                }
            });
            if (hasCorpInvoiceNdVchrNd == true)
                return true;
        } catch (e) {
            if (e === true)
                return true;
        }
        return false;
    }

    initImmunizations(clinic_immunizations) {
        let imz_array: any[] = [];
        clinic_immunizations.forEach(imz => {
            imz.immunizationName = imz.immunizationName;
            imz.paymentTypeName = imz.paymentTypeName;
            imz_array.push(this._fb.group({
                clinicPk: this._fb.control(imz.clinicPk),
                immunizationPk: this._fb.control(imz.immunizationPk),
                estimatedQuantity: this._fb.control(imz.estimatedQuantity, Validators.required),  //(Number(imz.estimatedQuantity) == 0?'':imz.estimatedQuantity, Validators.required)
                immunizationName: this._fb.control(imz.immunizationName),
                paymentTypeName: this._fb.control(imz.paymentTypeName),
                paymentTypeId: this._fb.control(imz.paymentTypeId),
                immunizationAbvNameEN: this._fb.control(imz.immunizationAbvNameEN)
            }))
        })
        return this._fb.array(imz_array);
    }

    addLocation(location) {
        if (location && (this.is_reAgreement == true || this.is_reAgreementCommOutreach == true)) {
            this.calculateClinicFee(location);
        }
        const control = <FormArray>this.contractForm.controls['locations'];
        const addrCtrl = this.initLocation(location);
        // check "No Clinic - Voucher Distribution Only" when the only payment method 
        // for all Immunizations is the Voucher payment method
        let onlyVoucherPaymentMethodSelected = ClinicDetailsUtilities.CheckIfOnlyVoucherPaymentMethodIsSelectedForAllImmunizations(addrCtrl.value.clinicImzQtyList);

        if (onlyVoucherPaymentMethodSelected && (location === undefined || location?.pk === undefined)) {
            addrCtrl.controls['isNoClinic'].setValue(1);

            addrCtrl.controls['clinicDate'].setErrors(null);
            addrCtrl.controls['clinicDate'].setValidators(null);
            addrCtrl.controls['clinicDate'].setValue(null);

            addrCtrl.controls['startTime'].setErrors(null);
            addrCtrl.controls['startTime'].setValidators(null);
            addrCtrl.controls['startTime'].setValue(null);

            addrCtrl.controls['endTime'].setErrors(null);
            addrCtrl.controls['endTime'].setValidators(null);
            addrCtrl.controls['endTime'].setValue(null);

            addrCtrl.updateValueAndValidity();
        }
        let isAmmendValue = sessionStorage["IsEquityAmendAgreement"];
        
        if (
            this.outReachProgramType == OutReachProgramType.equityevents 
            && location != undefined 
            && location.vouchersDistributed != null 
            && isAmmendValue === "true"
        ) {
            addrCtrl.controls['vouchersDistributed'].setValue(location.vouchersDistributed ?? 0);
        }
        // Only when No Clinic - Voucher Distribution Only is checked
        else if(addrCtrl.controls['isNoClinic'] && !addrCtrl.controls['isNoClinic']?.value && onlyVoucherPaymentMethodSelected){
            const vouchersDistributedCount = ClinicDetailsUtilities.calculateVouchersDistributedCount(addrCtrl);
            addrCtrl.controls['vouchersDistributed'].setValue(vouchersDistributedCount ?? 0);
        }
        // When No Clinic - Voucher Distribution Only is not checked
        else if (addrCtrl.controls['isNoClinic'] && addrCtrl.controls['isNoClinic']?.value) {
            const vouchersDistributedCount = ClinicDetailsUtilities.GetAllEstimatedShotsValue(addrCtrl);
            addrCtrl.controls['vouchersDistributed'].setValue(vouchersDistributedCount ?? 0);
        }

        addrCtrl.updateValueAndValidity();
        this.locations_data1 = addrCtrl;
        control.push(addrCtrl);

        // 778 fix
        // if(this.isCovidVaccineAvailable&&location===undefined&& control.length>1){
        //     let index:number = this.contractForm.controls.locations['controls'].length;
        //     this.copyPreviousLocationData(index-1);
        // }
        if (location != undefined) {
            this.locations_data = Object.assign([], addrCtrl);
        }
        this._localContractService.setValidationsRequiredStatus(true);
        this._localContractService.SetIscanceled(true);
    }

    public calculateClinicFee(location) {
        let aggregatedEstimatedQuantity = 0;
        //key is the immunization name and value is estimated quantity count (number)
        let uniqueImmunizationShotsCount = new Map();
        
        location?.clinicImzQtyList?.forEach(immunization => { 
          if(!isNaN(parseInt(immunization?.estimatedQuantity)) && (parseInt(immunization?.estimatedQuantity) > 0)){
            uniqueImmunizationShotsCount[immunization.immunizationName] = parseInt(immunization?.estimatedQuantity);
          }
        });

        for(const key in uniqueImmunizationShotsCount){
          if (uniqueImmunizationShotsCount.hasOwnProperty(key)) {
            aggregatedEstimatedQuantity += uniqueImmunizationShotsCount[key];
          }
        }

        let paymentIdsForClinic = new Set<number>();
        location?.clinicImzQtyList?.forEach(immunization => {
            paymentIdsForClinic.add(immunization.paymentTypeId);
        });

        // if "Voucher: Corporate to Invoice Employer Directly" (id = 13) payment method is NOT SELECTED
        // then consider the calculatedClinicFee as is
        let calculatedClinicFee: number = ClinicDetailsUtilities.calculateClinicFeeForQty(aggregatedEstimatedQuantity);

        // if "Voucher: Corporate to Invoice Employer Directly" (id = 13) payment method is SELECTED along with other methods
        // then subtract the aggregatedEstimatedQuantity from "Vouchers Distributed" value and calculate the fee
        if (paymentIdsForClinic?.size > 1 && paymentIdsForClinic.has(13)) {
            var updatedQuantity = aggregatedEstimatedQuantity - (location?.vouchersDistributed.value ?? 0);
            calculatedClinicFee = ClinicDetailsUtilities.calculateClinicFeeForQty(updatedQuantity);
        }

        // if only the "Voucher: Corporate to Invoice Employer Directly" (id = 13) payment method is selected
        // then Clinic Fee is 0
        if (paymentIdsForClinic?.size == 1 && paymentIdsForClinic.has(13)) {
            calculatedClinicFee = 0;
        }

        // if "No Clinic - Voucher Distribution Only" checkbox is checked
        // then clinic fee is 0
        if (location?.isNoClinic ?? false) {
            calculatedClinicFee = 0;
        }

        // if "No Clinic Fee" checkbox is checked
        // then clinic fee should be zero
        if (location?.isNoClinicFee ?? false) {
            calculatedClinicFee = 0;
        }

        location.clinicFee = calculatedClinicFee;
    }


    copyPreviousLocationData(formIndex: number) {

        let ctrl = this.contractForm.controls.locations['controls'];

        ctrl[formIndex].get('address1').setValue(ctrl[formIndex - 1].get('address1').value);
        ctrl[formIndex].get('address2').setValue(ctrl[formIndex - 1].get('address2').value);
        ctrl[formIndex].get('city').setValue(ctrl[formIndex - 1].get('city').value);
        ctrl[formIndex].get('state').setValue(ctrl[formIndex - 1].get('state').value);
        ctrl[formIndex].get('zipCode').setValue(ctrl[formIndex - 1].get('zipCode').value);

    }
    removeLocation(i: number) {
        let control = <FormArray>this.contractForm.controls['locations'];
        control.removeAt(i);
        this.location_number--;

        for (let index = 0; index < this.location_number; index++) {
            let fGrp: FormGroup = control.controls[index] as FormGroup;
            fGrp.controls['location'].setValue("CLINIC " + this._locationNumberPipe.transform(index + 1));
        }
        this._localContractService.setValidationsRequiredStatus(true);
        this._localContractService.SetIscanceled(true);
    }

    isFieldValid(field: string) {
        return !this.contractForm.get(field).valid && this.contractForm.get(field).touched;
    }

    displayFieldCss(field: string) {
        return {
            'has-error': this.isFieldValid(field),
            'has-feedback': this.isFieldValid(field)
        };
    }

    getCOVIDVisitTypesValidity() {
        var pfizerRe = /Pfizer/gi;
        var modernaRe = /Moderna/gi;
        let isValid = false;
        let errMsgs: string[] = [];
        let pfizerClinics: any[] = [];
        let modernaClinics: any[] = [];
        for (let count = 0; count < this.contractForm.value.locations.length; count++) {
            let pfizerCovidImmList = this.contractForm.value.locations[count].clinicImzQtyList.filter(p => p.immunizationName.search(pfizerRe) >= 0);
            let modernaCovidImmList = this.contractForm.value.locations[count].clinicImzQtyList.filter(p => p.immunizationName.search(modernaRe) >= 0);
            if (pfizerCovidImmList.length > 0) {
                let totalCovidImmCount: number = 0;

                totalCovidImmCount = pfizerCovidImmList.map(m => m.estimatedQuantity).reduce((a, b) => a + b);
                if (totalCovidImmCount > 0) {
                    pfizerClinics.push(this.contractForm.value.locations[count]);
                }
            }
            if (modernaCovidImmList.length > 0) {
                let totalCovidImmCount: number = 0;

                totalCovidImmCount = modernaCovidImmList.map(m => m.estimatedQuantity).reduce((a, b) => a + b);
                if (totalCovidImmCount > 0) {
                    modernaClinics.push(this.contractForm.value.locations[count]);
                }
            }
        }
        let pfizerVisit1Date = pfizerClinics.filter(l => l.visitType == 1 && l.isNoClinic != 1);
        let pfizerVisit2Date = pfizerClinics.filter(l => l.visitType == 2 && l.isNoClinic != 1);
        let pfizerVisit3Date = pfizerClinics.filter(l => l.visitType == 3 && l.isNoClinic != 1);
        let pfizerVisit4Date = pfizerClinics.filter(l => l.visitType == 4 && l.isNoClinic != 1);
        let pfizerVisit5Date = pfizerClinics.filter(l => l.visitType == 5 && l.isNoClinic != 1);
        let pfizerVisit6Date = pfizerClinics.filter(l => l.visitType == 6 && l.isNoClinic != 1);
        let pfizerVisit7Date = pfizerClinics.filter(l => l.visitType == 7 && l.isNoClinic != 1);
        let pfizerVisit8Date = pfizerClinics.filter(l => l.visitType == 8 && l.isNoClinic != 1);
        let pfizerVisit9Date = pfizerClinics.filter(l => l.visitType == 9 && l.isNoClinic != 1);
        let pfizerVisit10Date = pfizerClinics.filter(l => l.visitType == 10 && l.isNoClinic != 1);


        if (pfizerVisit1Date.length > 0 && pfizerVisit2Date.length > 0) {
            let visit1Date: Date = (typeof pfizerVisit1Date[0].clinicDate == 'string') ? new Date(pfizerVisit1Date[0].clinicDate) : pfizerVisit1Date[0].clinicDate;
            let visit2Date: Date = (typeof pfizerVisit2Date[0].clinicDate == 'string') ? new Date(pfizerVisit2Date[0].clinicDate) : pfizerVisit2Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit1Date.getFullYear(), visit1Date.getMonth(), visit1Date.getDate(), 0, 0, 0), new Date(visit2Date.getFullYear(), visit2Date.getMonth(), visit2Date.getDate(), 0, 0, 0));
            if ((visit2Date.valueOf() - visit1Date.valueOf()) < 0 || diffDays < 21) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], pfizerVisit2Date[0].location, pfizerVisit2Date[0].visitType, pfizerVisit1Date[0].location, pfizerVisit1Date[0].visitType, 21, 'Pfizer'));
            }
        }
        if (pfizerVisit2Date.length > 0 && pfizerVisit3Date.length > 0) {
            let visit2Date: Date = (typeof pfizerVisit2Date[0].clinicDate == 'string') ? new Date(pfizerVisit2Date[0].clinicDate) : pfizerVisit2Date[0].clinicDate;
            let visit3Date: Date = (typeof pfizerVisit3Date[0].clinicDate == 'string') ? new Date(pfizerVisit3Date[0].clinicDate) : pfizerVisit3Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit2Date.getFullYear(), visit2Date.getMonth(), visit2Date.getDate(), 0, 0, 0),
                new Date(visit3Date.getFullYear(), visit3Date.getMonth(), visit3Date.getDate(), 0, 0, 0));
            if ((visit3Date.valueOf() - visit2Date.valueOf()) < 0 || diffDays < 21) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], pfizerVisit3Date[0].location, pfizerVisit3Date[0].visitType, pfizerVisit2Date[0].location, pfizerVisit2Date[0].visitType, 21, 'Pfizer'));
            }
        }
        if (pfizerVisit3Date.length > 0 && pfizerVisit4Date.length > 0) {
            let visit3Date: Date = (typeof pfizerVisit3Date[0].clinicDate == 'string') ? new Date(pfizerVisit3Date[0].clinicDate) : pfizerVisit3Date[0].clinicDate;
            let visit4Date: Date = (typeof pfizerVisit4Date[0].clinicDate == 'string') ? new Date(pfizerVisit4Date[0].clinicDate) : pfizerVisit4Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit3Date.getFullYear(), visit3Date.getMonth(), visit3Date.getDate(), 0, 0, 0),
                new Date(visit4Date.getFullYear(), visit4Date.getMonth(), visit4Date.getDate(), 0, 0, 0));
            if ((visit4Date.valueOf() - visit3Date.valueOf()) < 0 || diffDays < 21) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], pfizerVisit4Date[0].location, pfizerVisit4Date[0].visitType, pfizerVisit3Date[0].location, pfizerVisit3Date[0].visitType, 21, 'Pfizer'));
            }
        }
        if (pfizerVisit4Date.length > 0 && pfizerVisit5Date.length > 0) {
            let visit4Date: Date = (typeof pfizerVisit4Date[0].clinicDate == 'string') ? new Date(pfizerVisit4Date[0].clinicDate) : pfizerVisit4Date[0].clinicDate;
            let visit5Date: Date = (typeof pfizerVisit5Date[0].clinicDate == 'string') ? new Date(pfizerVisit5Date[0].clinicDate) : pfizerVisit5Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit4Date.getFullYear(), visit4Date.getMonth(), visit4Date.getDate(), 0, 0, 0),
                new Date(visit5Date.getFullYear(), visit5Date.getMonth(), visit5Date.getDate(), 0, 0, 0));
            if ((visit5Date.valueOf() - visit4Date.valueOf()) < 0 || diffDays < 21) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], pfizerVisit5Date[0].location, pfizerVisit5Date[0].visitType, pfizerVisit4Date[0].location, pfizerVisit4Date[0].visitType, 21, 'Pfizer'));
            }
        }
        if (pfizerVisit5Date.length > 0 && pfizerVisit6Date.length > 0) {
            let visit5Date: Date = (typeof pfizerVisit5Date[0].clinicDate == 'string') ? new Date(pfizerVisit5Date[0].clinicDate) : pfizerVisit5Date[0].clinicDate;
            let visit6Date: Date = (typeof pfizerVisit6Date[0].clinicDate == 'string') ? new Date(pfizerVisit6Date[0].clinicDate) : pfizerVisit6Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit5Date.getFullYear(), visit5Date.getMonth(), visit5Date.getDate(), 0, 0, 0),
                new Date(visit6Date.getFullYear(), visit6Date.getMonth(), visit6Date.getDate(), 0, 0, 0));
            if ((visit6Date.valueOf() - visit5Date.valueOf()) < 0 || diffDays < 21) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], pfizerVisit6Date[0].location, pfizerVisit6Date[0].visitType, pfizerVisit5Date[0].location, pfizerVisit5Date[0].visitType, 21, 'Pfizer'));
            }
        }
        if (pfizerVisit6Date.length > 0 && pfizerVisit7Date.length > 0) {
            let visit6Date: Date = (typeof pfizerVisit6Date[0].clinicDate == 'string') ? new Date(pfizerVisit6Date[0].clinicDate) : pfizerVisit6Date[0].clinicDate;
            let visit7Date: Date = (typeof pfizerVisit7Date[0].clinicDate == 'string') ? new Date(pfizerVisit7Date[0].clinicDate) : pfizerVisit7Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit6Date.getFullYear(), visit6Date.getMonth(), visit6Date.getDate(), 0, 0, 0),
                new Date(visit7Date.getFullYear(), visit7Date.getMonth(), visit7Date.getDate(), 0, 0, 0));
            if ((visit7Date.valueOf() - visit6Date.valueOf()) < 0 || diffDays < 21) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], pfizerVisit7Date[0].location, pfizerVisit7Date[0].visitType, pfizerVisit6Date[0].location, pfizerVisit6Date[0].visitType, 21, 'Pfizer'));
            }
        }
        if (pfizerVisit7Date.length > 0 && pfizerVisit8Date.length > 0) {
            let visit7Date: Date = (typeof pfizerVisit7Date[0].clinicDate == 'string') ? new Date(pfizerVisit7Date[0].clinicDate) : pfizerVisit7Date[0].clinicDate;
            let visit8Date: Date = (typeof pfizerVisit8Date[0].clinicDate == 'string') ? new Date(pfizerVisit8Date[0].clinicDate) : pfizerVisit8Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit7Date.getFullYear(), visit7Date.getMonth(), visit7Date.getDate(), 0, 0, 0),
                new Date(visit8Date.getFullYear(), visit8Date.getMonth(), visit8Date.getDate(), 0, 0, 0));
            if ((visit8Date.valueOf() - visit7Date.valueOf()) < 0 || diffDays < 21) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], pfizerVisit8Date[0].location, pfizerVisit8Date[0].visitType, pfizerVisit7Date[0].location, pfizerVisit7Date[0].visitType, 21, 'Pfizer'));
            }
        }
        if (pfizerVisit8Date.length > 0 && pfizerVisit9Date.length > 0) {
            let visit8Date: Date = (typeof pfizerVisit8Date[0].clinicDate == 'string') ? new Date(pfizerVisit8Date[0].clinicDate) : pfizerVisit8Date[0].clinicDate;
            let visit9Date: Date = (typeof pfizerVisit9Date[0].clinicDate == 'string') ? new Date(pfizerVisit9Date[0].clinicDate) : pfizerVisit9Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit8Date.getFullYear(), visit8Date.getMonth(), visit8Date.getDate(), 0, 0, 0),
                new Date(visit9Date.getFullYear(), visit9Date.getMonth(), visit9Date.getDate(), 0, 0, 0));
            if ((visit9Date.valueOf() - visit8Date.valueOf()) < 0 || diffDays < 21) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], pfizerVisit9Date[0].location, pfizerVisit9Date[0].visitType, pfizerVisit8Date[0].location, pfizerVisit8Date[0].visitType, 21, 'Pfizer'));
            }
        }
        if (pfizerVisit9Date.length > 0 && pfizerVisit10Date.length > 0) {
            let visit9Date: Date = (typeof pfizerVisit9Date[0].clinicDate == 'string') ? new Date(pfizerVisit9Date[0].clinicDate) : pfizerVisit9Date[0].clinicDate;
            let visit10Date: Date = (typeof pfizerVisit10Date[0].clinicDate == 'string') ? new Date(pfizerVisit10Date[0].clinicDate) : pfizerVisit10Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit9Date.getFullYear(), visit9Date.getMonth(), visit9Date.getDate(), 0, 0, 0),
                new Date(visit10Date.getFullYear(), visit10Date.getMonth(), visit10Date.getDate(), 0, 0, 0));
            if ((visit10Date.valueOf() - visit9Date.valueOf()) < 0 || diffDays < 21) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], pfizerVisit10Date[0].location, pfizerVisit10Date[0].visitType, pfizerVisit9Date[0].location, pfizerVisit9Date[0].visitType, 21, 'Pfizer'));
            }
        }
        let modernaVisit1Date = modernaClinics.filter(l => l.visitType == 1 && l.isNoClinic != 1);
        let modernaVisit2Date = modernaClinics.filter(l => l.visitType == 2 && l.isNoClinic != 1);
        let modernaVisit3Date = modernaClinics.filter(l => l.visitType == 3 && l.isNoClinic != 1);
        let modernaVisit4Date = modernaClinics.filter(l => l.visitType == 4 && l.isNoClinic != 1);
        let modernaVisit5Date = modernaClinics.filter(l => l.visitType == 5 && l.isNoClinic != 1);
        let modernaVisit6Date = modernaClinics.filter(l => l.visitType == 6 && l.isNoClinic != 1);
        let modernaVisit7Date = modernaClinics.filter(l => l.visitType == 7 && l.isNoClinic != 1);
        let modernaVisit8Date = modernaClinics.filter(l => l.visitType == 8 && l.isNoClinic != 1);
        let modernaVisit9Date = modernaClinics.filter(l => l.visitType == 9 && l.isNoClinic != 1);
        let modernaVisit10Date = modernaClinics.filter(l => l.visitType == 10 && l.isNoClinic != 1);


        if (modernaVisit1Date.length > 0 && modernaVisit2Date.length > 0) {
            let visit1Date: Date = (typeof modernaVisit1Date[0].clinicDate == 'string') ? new Date(modernaVisit1Date[0].clinicDate) : modernaVisit1Date[0].clinicDate;
            let visit2Date: Date = (typeof modernaVisit2Date[0].clinicDate == 'string') ? new Date(modernaVisit2Date[0].clinicDate) : modernaVisit2Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit1Date.getFullYear(), visit1Date.getMonth(), visit1Date.getDate(), 0, 0, 0), new Date(visit2Date.getFullYear(), visit2Date.getMonth(), visit2Date.getDate(), 0, 0, 0));
            if ((visit2Date.valueOf() - visit1Date.valueOf()) < 0 || diffDays < 28) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], modernaVisit2Date[0].location, modernaVisit2Date[0].visitType, modernaVisit1Date[0].location, modernaVisit1Date[0].visitType, 28, 'Moderna'));
            }
        }
        if (modernaVisit2Date.length > 0 && modernaVisit3Date.length > 0) {
            let visit2Date: Date = (typeof modernaVisit2Date[0].clinicDate == 'string') ? new Date(modernaVisit2Date[0].clinicDate) : modernaVisit2Date[0].clinicDate;
            let visit3Date: Date = (typeof modernaVisit3Date[0].clinicDate == 'string') ? new Date(modernaVisit3Date[0].clinicDate) : modernaVisit3Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit2Date.getFullYear(), visit2Date.getMonth(), visit2Date.getDate(), 0, 0, 0),
                new Date(visit3Date.getFullYear(), visit3Date.getMonth(), visit3Date.getDate(), 0, 0, 0));
            if ((visit3Date.valueOf() - visit2Date.valueOf()) < 0 || diffDays < 28) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], modernaVisit3Date[0].location, modernaVisit3Date[0].visitType, modernaVisit2Date[0].location, modernaVisit2Date[0].visitType, 28, 'Moderna'));
            }
        }
        if (modernaVisit3Date.length > 0 && modernaVisit4Date.length > 0) {
            let visit3Date: Date = (typeof modernaVisit3Date[0].clinicDate == 'string') ? new Date(modernaVisit3Date[0].clinicDate) : modernaVisit3Date[0].clinicDate;
            let visit4Date: Date = (typeof modernaVisit4Date[0].clinicDate == 'string') ? new Date(modernaVisit4Date[0].clinicDate) : modernaVisit4Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit3Date.getFullYear(), visit3Date.getMonth(), visit3Date.getDate(), 0, 0, 0),
                new Date(visit4Date.getFullYear(), visit4Date.getMonth(), visit4Date.getDate(), 0, 0, 0));
            if ((visit4Date.valueOf() - visit3Date.valueOf()) < 0 || diffDays < 28) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], modernaVisit4Date[0].location, modernaVisit4Date[0].visitType, modernaVisit3Date[0].location, modernaVisit3Date[0].visitType, 28, 'Moderna'));
            }
        }
        if (modernaVisit4Date.length > 0 && modernaVisit5Date.length > 0) {
            let visit4Date: Date = (typeof modernaVisit4Date[0].clinicDate == 'string') ? new Date(modernaVisit4Date[0].clinicDate) : modernaVisit4Date[0].clinicDate;
            let visit5Date: Date = (typeof modernaVisit5Date[0].clinicDate == 'string') ? new Date(modernaVisit5Date[0].clinicDate) : modernaVisit5Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit4Date.getFullYear(), visit4Date.getMonth(), visit4Date.getDate(), 0, 0, 0),
                new Date(visit5Date.getFullYear(), visit5Date.getMonth(), visit5Date.getDate(), 0, 0, 0));
            if ((visit5Date.valueOf() - visit4Date.valueOf()) < 0 || diffDays < 28) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], modernaVisit5Date[0].location, modernaVisit5Date[0].visitType, modernaVisit4Date[0].location, modernaVisit4Date[0].visitType, 28, 'Moderna'));
            }
        }
        if (modernaVisit5Date.length > 0 && modernaVisit6Date.length > 0) {
            let visit5Date: Date = (typeof modernaVisit5Date[0].clinicDate == 'string') ? new Date(modernaVisit5Date[0].clinicDate) : modernaVisit5Date[0].clinicDate;
            let visit6Date: Date = (typeof modernaVisit6Date[0].clinicDate == 'string') ? new Date(modernaVisit6Date[0].clinicDate) : modernaVisit6Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit5Date.getFullYear(), visit5Date.getMonth(), visit5Date.getDate(), 0, 0, 0),
                new Date(visit6Date.getFullYear(), visit6Date.getMonth(), visit6Date.getDate(), 0, 0, 0));
            if ((visit6Date.valueOf() - visit5Date.valueOf()) < 0 || diffDays < 28) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], modernaVisit6Date[0].location, modernaVisit6Date[0].visitType, modernaVisit5Date[0].location, modernaVisit5Date[0].visitType, 28, 'Moderna'));
            }
        }
        if (modernaVisit6Date.length > 0 && modernaVisit7Date.length > 0) {
            let visit6Date: Date = (typeof modernaVisit6Date[0].clinicDate == 'string') ? new Date(modernaVisit6Date[0].clinicDate) : modernaVisit6Date[0].clinicDate;
            let visit7Date: Date = (typeof modernaVisit7Date[0].clinicDate == 'string') ? new Date(modernaVisit7Date[0].clinicDate) : modernaVisit7Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit6Date.getFullYear(), visit6Date.getMonth(), visit6Date.getDate(), 0, 0, 0),
                new Date(visit7Date.getFullYear(), visit7Date.getMonth(), visit7Date.getDate(), 0, 0, 0));
            if ((visit7Date.valueOf() - visit6Date.valueOf()) < 0 || diffDays < 28) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], modernaVisit7Date[0].location, modernaVisit7Date[0].visitType, modernaVisit6Date[0].location, modernaVisit6Date[0].visitType, 28, 'Moderna'));
            }
        }
        if (modernaVisit7Date.length > 0 && modernaVisit8Date.length > 0) {
            let visit7Date: Date = (typeof modernaVisit7Date[0].clinicDate == 'string') ? new Date(modernaVisit7Date[0].clinicDate) : modernaVisit7Date[0].clinicDate;
            let visit8Date: Date = (typeof modernaVisit8Date[0].clinicDate == 'string') ? new Date(modernaVisit8Date[0].clinicDate) : modernaVisit8Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit7Date.getFullYear(), visit7Date.getMonth(), visit7Date.getDate(), 0, 0, 0),
                new Date(visit8Date.getFullYear(), visit8Date.getMonth(), visit8Date.getDate(), 0, 0, 0));
            if ((visit8Date.valueOf() - visit7Date.valueOf()) < 0 || diffDays < 28) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], modernaVisit8Date[0].location, modernaVisit8Date[0].visitType, modernaVisit7Date[0].location, modernaVisit7Date[0].visitType, 28, 'Moderna'));
            }
        }
        if (modernaVisit8Date.length > 0 && modernaVisit9Date.length > 0) {
            let visit8Date: Date = (typeof modernaVisit8Date[0].clinicDate == 'string') ? new Date(modernaVisit8Date[0].clinicDate) : modernaVisit8Date[0].clinicDate;
            let visit9Date: Date = (typeof modernaVisit9Date[0].clinicDate == 'string') ? new Date(modernaVisit9Date[0].clinicDate) : modernaVisit9Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit8Date.getFullYear(), visit8Date.getMonth(), visit8Date.getDate(), 0, 0, 0),
                new Date(visit9Date.getFullYear(), visit9Date.getMonth(), visit9Date.getDate(), 0, 0, 0));
            if ((visit9Date.valueOf() - visit8Date.valueOf()) < 0 || diffDays < 28) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], modernaVisit9Date[0].location, modernaVisit9Date[0].visitType, modernaVisit8Date[0].location, modernaVisit8Date[0].visitType, 28, 'Moderna'));
            }
        }
        if (modernaVisit9Date.length > 0 && modernaVisit10Date.length > 0) {
            let visit9Date: Date = (typeof modernaVisit9Date[0].clinicDate == 'string') ? new Date(modernaVisit9Date[0].clinicDate) : modernaVisit9Date[0].clinicDate;
            let visit10Date: Date = (typeof modernaVisit10Date[0].clinicDate == 'string') ? new Date(modernaVisit10Date[0].clinicDate) : modernaVisit10Date[0].clinicDate;
            let diffDays = this.utility.getDiffDays(new Date(visit9Date.getFullYear(), visit9Date.getMonth(), visit9Date.getDate(), 0, 0, 0),
                new Date(visit10Date.getFullYear(), visit10Date.getMonth(), visit10Date.getDate(), 0, 0, 0));
            if ((visit10Date.valueOf() - visit9Date.valueOf()) < 0 || diffDays < 28) {
                errMsgs.push(String.Format(ErrorMessages['covidVisitGap'], modernaVisit10Date[0].location, modernaVisit10Date[0].visitType, modernaVisit9Date[0].location, modernaVisit9Date[0].visitType, 28, 'Moderna'));
            }
        }

        return errMsgs;
    }
    save(outReachPrgType?: string): boolean {

        if (this.isFormValid()) {
            if (!this.userOverriddenDateTimeConflict) {
                this.contractForm.value.locations.forEach(loc => {
                    try {
                        if (loc.startTime != "" && Object.prototype.toString.call(loc.startTime) === '[object Date]')
                            try {
                                loc.startTime = this._datePipe.transform(loc.startTime, 'shortTime');
                            } catch (e) {
                                console.log(e);
                            }
                        if (loc.endTime != "" && Object.prototype.toString.call(loc.endTime) === '[object Date]') {
                            try {
                                loc.endTime = this._datePipe.transform(loc.endTime, 'shortTime');
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    } catch (e) {
                        console.log(e); // here the exception is delibarately caught because when you click back, startTime & end
                        // Time is not correct timeformat. Hence we are ignoring this by catching here.
                    }
                });
                try {
                    let locDateTimeConflict = false;
                    var locConflictList = [];
                    var errMsgV1: string[] = [];
                    //https://stackoverflow.com/questions/7486085/copying-array-by-value-in-javascript?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
                    for (let count = 0; count < this.contractForm.value.locations.length; count++) {
                        try {
                            //var loc = Object.assign({}, this.contractForm.value.locations[count]);
                            let locClinicDate: Date = new Date(this.contractForm.value.locations[count].clinicDate);
                            let locStartTime = this.contractForm.value.locations[count].startTime;
                            let locEndTime = this.contractForm.value.locations[count].endTime;
                            if (this.contractForm.value.locations[count].isNoClinic >= 1 ||
                                this.contractForm.value.locations[count].isNoClinic === '1' ||
                                this.contractForm.value.locations[count].isReassign >= 1 ||
                                this.contractForm.value.locations[count].isReassign === '1') {
                                continue;
                            }
                            let locationsCpy = JSON.parse(JSON.stringify(this.contractForm.value.locations));
                            var index = locationsCpy.findIndex(it => it.location === this.contractForm.value.locations[count].location);
                            if (index > -1) {
                                locationsCpy.splice(index, 1);
                            }
                            locationsCpy.forEach(element => {
                                element.clinicDate = new Date(element.clinicDate);
                            });
                            let conflictLocs = locationsCpy.filter(locItem => locClinicDate.getFullYear() === locItem.clinicDate.getFullYear() && locClinicDate.getMonth() === locItem.clinicDate.getMonth() && locClinicDate.getDate() === locItem.clinicDate.getDate() && (locItem.isNoClinic <= 0 || locItem.isNoClinic === '0' || locItem.isNoClinic === undefined || locItem.isNoClinic === null) && (locItem.isReassign <= 0 || locItem.isReassign === '0' || locItem.isReassign === undefined || locItem.isReassign === null));
                            let endDateAdd39Mts: Date;
                            if (locEndTime.toString().toLowerCase().search('am') !== -1 || locEndTime.toString().toLowerCase().search('pm') !== -1) {
                                endDateAdd39Mts = this._timeToDatePipe.transform(locClinicDate, locEndTime);
                            }
                            else {
                                endDateAdd39Mts = locEndTime;
                            }
                            let startDatetmToDt: Date;
                            if (locStartTime.toString().toLowerCase().search('am') !== -1 || locStartTime.toString().toLowerCase().search('pm') !== -1) {
                                startDatetmToDt = this._timeToDatePipe.transform(locClinicDate, locStartTime);
                            }
                            else {
                                startDatetmToDt = locStartTime;
                            }
                            let add30MinTS: Date = new Date(locClinicDate.getFullYear(), locClinicDate.getMonth(), locClinicDate.getDate(), endDateAdd39Mts.getHours(), endDateAdd39Mts.getMinutes() + 30, endDateAdd39Mts.getSeconds());
                            let locStart: Date = new Date(startDatetmToDt);

                            if (conflictLocs.length > 0) {
                                var conflictLocsCpy = JSON.parse(JSON.stringify(conflictLocs));
                                conflictLocsCpy.forEach(element => {
                                    element.clinicDate = new Date(element.clinicDate);
                                    element.startTime = this._timeToDatePipe.transform(element.clinicDate, element.startTime);
                                    element.endTime = this._timeToDatePipe.transform(element.clinicDate, element.endTime);
                                });


                                conflictLocs = conflictLocsCpy.filter(locItem => locClinicDate.getFullYear() === locItem.clinicDate.getFullYear() && locClinicDate.getMonth() === locItem.clinicDate.getMonth() && locClinicDate.getDate() === locItem.clinicDate.getDate() && ((locItem.startTime.valueOf() >= locStart.valueOf() && locItem.startTime.valueOf() < add30MinTS.valueOf()) || (locItem.endTime.valueOf() > locStart.valueOf() && locItem.endTime.valueOf() < add30MinTS.valueOf())));
                                if (conflictLocs.length > 0) {
                                    locConflictList.push({
                                        srcLocName: this.contractForm.value.locations[count],
                                        dstLocName: conflictLocs
                                    });
                                }
                            }
                        }
                        catch (e) {
                            console.log(e);
                        }
                    }
                } catch (e) {

                    console.log(e);
                }
                let conflictClinicList: any[] = [];
                locConflictList.forEach(item => {

                    item.dstLocName.forEach(element => {
                        let duplicateEntry = conflictClinicList.filter(l => l.location == element.location);
                        if (duplicateEntry.length <= 0) {
                            conflictClinicList.push(element);
                        }
                    });

                });

                if (conflictClinicList.length > 0) {
                    conflictClinicList.sort((leftItem, rightItem): number => {
                        if (leftItem.location < rightItem.location) return -1;
                        if (leftItem.location > rightItem.location) return 1;
                        return 0;
                    });

                    if (conflictClinicList.length <= 2 && this.contractForm.value.locations.length <= 2) {
                        if (conflictClinicList.length == 2) {
                            errMsgV1.push(String.Format(ErrorMessages['clincLocDateTimeSingleConflict'], conflictClinicList[1].location.replace(/CLINIC/g, ' LOCATION'), this._datePipe.transform(conflictClinicList[0].clinicDate, "shortDate"), this._datePipe.transform(conflictClinicList[0].startTime, 'shortTime'), this._datePipe.transform(conflictClinicList[0].endTime, 'shortTime'), SessionDetails.GetopportunitiesData().businessName));
                        } else {
                            errMsgV1.push(String.Format(ErrorMessages['clincLocDateTimeSingleConflict'], conflictClinicList[0].location.replace(/CLINIC/g, ' LOCATION'), this._datePipe.transform(conflictClinicList[0].clinicDate, "shortDate"), this._datePipe.transform(conflictClinicList[0].startTime, 'shortTime'), this._datePipe.transform(conflictClinicList[0].endTime, 'shortTime'), SessionDetails.GetopportunitiesData().businessName));
                        }
                        this.showDateTimeOverLpDlg(ErrorMessages['resolve'], errMsgV1.join('<br/><br/>'));
                        this.isDateTimeConflictPresent = true;
                        return false;
                    }
                    if (conflictClinicList.length >= 1) {
                        conflictClinicList.forEach(loc => {
                            errMsgV1.push(String.Format(ErrorMessages['clincLocDateTimeMultipleConflict'], loc.location.replace(/CLINIC/g, ' LOCATION'), this._datePipe.transform(loc.clinicDate, "shortDate"), this._datePipe.transform(loc.startTime, 'shortTime'), this._datePipe.transform(loc.endTime, 'shortTime'), SessionDetails.GetopportunitiesData().businessName));
                        });
                        this.showDateTimeOverLpDlg(ErrorMessages['resolve'], String.Format("{0}{1}", ErrorMessages['multipleClinicDatesConflictLineItem'], errMsgV1.join('<br/><br/>')));
                        this.isDateTimeConflictPresent = true;
                        return false;
                    }
                }
            }
            let errCOVIDVisits: string[] = this.getCOVIDVisitTypesValidity();
            if (errCOVIDVisits.length > 0 && !this.userOverriddenVisitsGapConflict) {
                this.showVisitsGapOverLpDlg("SCHEDULING WARNING", errCOVIDVisits.join('<br/><br/>'));
                return false;
            }
            this.isDateTimeConflictPresent = false;
            let errRestrictedStatesMsgs: string[] = this.getRestrictedStatesDatesValidity();
            if (errRestrictedStatesMsgs.length > 0) {
                this.showDialog(ErrorMessages['resolve'], errRestrictedStatesMsgs.join('<br/><br/>'));
                return false;
            }
            this._localContractService.setValidationsRequiredStatus(false);
            var estimatedQty = 0;
            var errMsg: string[] = [];
            var moreThan255ImmunizationError = false;
            let hasVouchersCountReducedTotalTo50 = false;
            this.location250Names = [];
            this.location25Names = [];
            let opportunitie_data = SessionDetails.GetopportunitiesData();
            let immunizationWithoutVoucherPaymentExists = false;
            let immunizationWithoutVoucherPaymentForEquityEvents = false;
            let locationNamesOfImmzWithoutVoucherPayment: string[] = [];
            for (let i = 0; i < this.contractForm.value.locations.length; i++) {
                // if(this.contractForm.value.locations[i].visitType==null|| (this.contractForm.value.locations[i].visitType&&this.contractForm.value.locations[i].visitType.length<=0)){
                //     this.contractForm.value.locations[i].visitType = null;
                //   }

                let newclinicImzQtyList = _.chain(this.contractForm.value.locations[i].clinicImzQtyList)
                    // Group the elements of Array based on `immunizationPk` property
                    .groupBy("immunizationPk")
                    // `key` is group's name (immunizationPk), `value` is the array of objects
                    .map((value, key) => ({ immPk: key, Immzs: value }))
                    .value();

                for (let j = 0; j < newclinicImzQtyList.length; j++) {
                    let qty: number = +newclinicImzQtyList[j].Immzs[0].estimatedQuantity;
                    let vaccineName: string = newclinicImzQtyList[j].Immzs[0].immunizationName;
                    if (qty >= 250 && vaccineName.search("Vaccine Voucher") === -1) {
                        moreThan255ImmunizationError = true;
                        this.location250Names.push({
                            estimatedQuantity: qty,
                            immunizationName: vaccineName,
                            location: this.contractForm.value.locations[i].location
                        });
                    }
                    else {
                        if (vaccineName.search("Vaccine Voucher") === -1) {
                            estimatedQty += qty;
                        }
                    }
                }

                // If user checked "No Clinic - Voucher Distribution Only" checkbox 
                // then check if any immunizations DOES NOT HAVE "Voucher: Corporate to Invoice Employer Directly" payment type 
                // and check if estimated shots qty of that particular immunization is greater than 0
                // if yes then display the pop information
                if (this.contractForm.value.locations[i].isNoClinic) {
                    for (const clinicImzQty of newclinicImzQtyList) {
                        immunizationWithoutVoucherPaymentExists = !clinicImzQty.Immzs.some(immunization => immunization.paymentTypeId == 13);
                        
                        if (immunizationWithoutVoucherPaymentExists && this.outReachProgramType != OutReachProgramType.equityevents) {
                            let totalEstimatedShots = this.contractForm.value.locations[i].clinicImzQtyList
                                .filter(imz => imz.immunizationPk == clinicImzQty.immPk)
                                .reduce((a, o) => { return a + parseInt(o.estimatedQuantity ?? 0) }, 0);
                            if (totalEstimatedShots > 0) {
                                locationNamesOfImmzWithoutVoucherPayment.push(this.contractForm.value.locations[i].location);
                                break;
                            }
                        }
                    }
                }
                let totalEstimatedQuantity = 0;
                newclinicImzQtyList.forEach(obj => {
                    obj.Immzs.forEach(immunization => {
                        totalEstimatedQuantity += Number(immunization.estimatedQuantity);
                    });
                });
                if (totalEstimatedQuantity < 50 && !moreThan255ImmunizationError && (this.contractForm.value.locations[i].isNoClinic == undefined ||
                    this.contractForm.value.locations[i].isNoClinic == null || Number(this.contractForm.value.locations[i].isNoClinic) <= 0)) {
                    //     if(!this.isCovidVaccineAvailable || this.isNonCovidVaccineAvailable){
                    if (opportunitie_data.covidOpportunityType == 1) {     
                        this.location25Names.push(this.contractForm.value.locations[i].location);
                    }
                    else if (opportunitie_data.covidOpportunityType !== 1) {     
                        this.location25Names.push(this.contractForm.value.locations[i].location);
                    }
                    estimatedQty = 0;
                    moreThan255ImmunizationError = false;
                    // }
                    // else if(this.isCovidVaccineAvailable && estimatedQty <1){  
                    //     this.location25Names.push(this.contractForm.value.locations[i].location);
                    //     estimatedQty = 0;
                    //     moreThan255ImmunizationError = false;    
                    // }
                }
                else if ((estimatedQty - +this.contractForm.value.locations[i].vouchersDistributed) < 50 && !moreThan255ImmunizationError && (this.contractForm.value.locations[i].isNoClinic == undefined ||
                    this.contractForm.value.locations[i].isNoClinic == null || Number(this.contractForm.value.locations[i].isNoClinic) <= 0)) {

                    this.location25Names.push(this.contractForm.value.locations[i].location);
                    estimatedQty = 0;
                    moreThan255ImmunizationError = false;
                    hasVouchersCountReducedTotalTo50 = true;
                }
                else {
                    estimatedQty = 0;
                }
                moreThan255ImmunizationError = false;
            }
            if (!this.userApprovedGt250 && this.location250Names.length > 0) {
                this.userApprovedGt250 = false;
                this.userApprovedLt25 = false;
            }
            // else {
            //     this.userApprovedGt250 = true;
            // }
            if (!this.userApprovedLt25 && this.location25Names.length > 0) {
                this.userApprovedLt25 = false;
            }
            // else {
            //     this.userApprovedLt25 = true;
            // }
            if(this.outReachProgramType != OutReachProgramType.equityevents)
            {
                if (hasVouchersCountReducedTotalTo50) {
                    var userProfile = SessionDetails.GetUserProfile();
                    const msgDetail = String.Format(ErrorMessages['MinNumberOfShots'], this.location25Names.join(',').replace(/CLINIC/g, ' LOCATION'));
                    if (userProfile.userRole.toLowerCase() == "admin") {
                        this.showConfirm25ImmDialog(ErrorMessages['resolve'], msgDetail);
                    }
                    else {
                        this.showDialogV2(ErrorMessages['resolve'], msgDetail);
                    }
    
                    return false;
                }

            }
           

            if (immunizationWithoutVoucherPaymentExists && locationNamesOfImmzWithoutVoucherPayment.length && this.outReachProgramType == OutReachProgramType.contracts) {
                const msgDetail = String.Format(ErrorMessages['immunizationWithoutVoucherPayment'], locationNamesOfImmzWithoutVoucherPayment.join(',').replace(/CLINIC/g, ' LOCATION'));
                const locationsFormArray = this.contractForm.controls['locations'] as FormArray;
                for (const locationFormGroup of locationsFormArray.controls) {
                    const location = locationFormGroup as FormGroup;
                    if (location.controls['isNoClinic'].value) {
                        location.controls['vouchersDistributed'].markAsPristine();
                        location.controls['vouchersDistributed'].markAsUntouched();
                    }
                    location.updateValueAndValidity();
                }
                this.showDialogV2(ErrorMessages['resolve'], msgDetail);
                return false;
            }

            // if (immunizationWithoutVoucherPaymentForEuityEvents && locationNamesOfImmzWithoutVoucherPayment.length && this.outReachProgramType == OutReachProgramType.equityevents) {
            //     const msgDetail = String.Format(ErrorMessages['immunizationWithoutVoucherPaymentForEuityEvents'], locationNamesOfImmzWithoutVoucherPayment.join(',').replace(/CLINIC/g, ' LOCATION'));
            //     const locationsFormArray = this.contractForm.controls['locations'] as FormArray;
            //     for (const locationFormGroup of locationsFormArray.controls) {
            //         const location = locationFormGroup as FormGroup;
            //         if (location.controls['isNoClinic'].value) {
            //             location.controls['vouchersDistributed'].markAsPristine();
            //             location.controls['vouchersDistributed'].markAsUntouched();
            //         }
            //         location.updateValueAndValidity();
            //     }
            //     this.showDialogV2(ErrorMessages['resolve'], msgDetail);
            //     return false;
            // }

            // this.outReachProgramType == OutReachProgramType.contracts

            if (this.outReachProgramType == OutReachProgramType.contracts) {
                if ((this.userApprovedGt250 || this.location250Names.length <= 0) &&
                    (this.userApprovedLt25 || this.location25Names.length <= 0)) {
                    this._localContractService.setValidationsRequiredStatus(false);
                    this._localContractService.setClinicLocations(this.contractForm.value);
                    this._localContractService.setCancelClinicLocations(this.contractForm.value);
                    return true;
                }
                else {
                    if (!this.userApprovedGt250 && this.location250Names.length > 0) {
                        this.location250Names.forEach(item => {
                            errMsg.push(String.Format(ErrorMessages['moreThan250Immunizations'], item.estimatedQuantity, item.immunizationName, item.location.replace(/CLINIC/g, ' LOCATION')));
                        });
                        if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
                            this.coShowConfirm250ImmDialog(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
                        } else {

                            this.showConfirm250ImmDialog(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
                        }

                        return false;
                    }
                    if (!this.userApprovedLt25 && this.location25Names.length > 0 && this.outReachProgramType === OutReachProgramType.contracts) {
                        var userProfile = SessionDetails.GetUserProfile();
                        if (userProfile.userRole.toLowerCase() == "admin") {
                            this.showConfirm25ImmDialog(ErrorMessages['resolve'],
                                String.Format(ErrorMessages['MinNumberOfShots'], this.location25Names.join(',').replace(/CLINIC/g, ' LOCATION')));
                        }
                        else {
                            this.showDialogV2(ErrorMessages['resolve'], String.Format(ErrorMessages['MinNumberOfShots'], this.location25Names.join(',').replace(/CLINIC/g, ' LOCATION')));
                        }
                    }
                    return false;
                }
            }
            else if (this.outReachProgramType == OutReachProgramType.communityoutreach) {
                if ((this.userApprovedGt250) || (this.location250Names.length <= 0)) {
                    this._localContractService.setValidationsRequiredStatus(false);
                    this._localContractService.setCOClinicLocations(this.contractForm.value);
                    this._localContractService.setCancelClinicLocations(this.contractForm.value);
                    return true;
                }
                else {
                    if (!this.userApprovedGt250 && this.location250Names.length > 0) {
                        this.location250Names.forEach(item => {
                            errMsg.push(String.Format(ErrorMessages['moreThan250Immunizations'], item.estimatedQuantity, item.immunizationName, item.location.replace(/CLINIC/g, ' LOCATION')));
                        });
                        if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
                            this.coShowConfirm250ImmDialog(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
                        } else {

                            this.showConfirm250ImmDialog(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
                        }

                        return false;
                    }
                    if (!this.userApprovedLt25 && this.outReachProgramType === OutReachProgramType.contracts) {
                        var userProfile = SessionDetails.GetUserProfile();
                        if (userProfile.userRole.toLowerCase() == "admin") {

                            this.showConfirm25ImmDialog(ErrorMessages['resolve'],
                                String.Format(opportunitie_data.covidOpportunityType == 1 ? ErrorMessages['MinNumberOfShotsForLTCF'] : ErrorMessages['MinNumberOfShots'], this.location25Names.join(',').replace(/CLINIC/g, ' LOCATION')));
                        }
                        else {
                            this.showDialogV2(ErrorMessages['resolve'], String.Format(opportunitie_data.covidOpportunityType == 1 ? ErrorMessages['MinNumberOfShotsForLTCF'] : ErrorMessages['MinNumberOfShots'], this.location25Names.join(',').replace(/CLINIC/g, ' LOCATION')));
                        }
                    }
                    return false;
                }
            }
            else if (this.outReachProgramType == OutReachProgramType.equityevents) {
                if ((this.userApprovedGt250) || (this.location250Names.length <= 0)) {
                    this._localContractService.setValidationsRequiredStatus(false);
                    this._localContractService.setCOClinicLocations(this.contractForm.value);
                    this._localContractService.setCancelClinicLocations(this.contractForm.value);
                    return true;
                }
                else {
                    if (!this.userApprovedGt250 && this.location250Names.length > 0) {
                        this.location250Names.forEach(item => {
                            errMsg.push(String.Format(ErrorMessages['moreThan250Immunizations'], item.estimatedQuantity, item.immunizationName, item.location.replace(/CLINIC/g, ' LOCATION')));
                        });
                        if (this.outReachProgramType === OutReachProgramType.equityevents) {
                            this.coShowConfirm250ImmDialog(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
                        } else {

                            this.showConfirm250ImmDialog(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
                        }

                        return false;
                    }
                    if (!this.userApprovedLt25 && this.outReachProgramType === OutReachProgramType.equityevents) {
                        var userProfile = SessionDetails.GetUserProfile();
                        if (userProfile.userRole.toLowerCase() == "admin") {

                            this.showConfirm25ImmDialog(ErrorMessages['resolve'],
                                String.Format(opportunitie_data.covidOpportunityType == 1 ? ErrorMessages['MinNumberOfShotsForLTCF'] : ErrorMessages['MinNumberOfShots'], this.location25Names.join(',').replace(/CLINIC/g, ' LOCATION')));
                        }
                        else {
                            this.showDialogV2(ErrorMessages['resolve'], String.Format(opportunitie_data.covidOpportunityType == 1 ? ErrorMessages['MinNumberOfShotsForLTCF'] : ErrorMessages['MinNumberOfShots'], this.location25Names.join(',').replace(/CLINIC/g, ' LOCATION')));
                        }
                    }
                    return false;
                }
            }
            else {
                if (!this.userApprovedGt250) {
                    this.location250Names.forEach(item => {
                        errMsg.push(String.Format(ErrorMessages['moreThan250Immunizations'], item.estimatedQuantity, item.immunizationName, item.location.replace(/CLINIC/g, ' LOCATION')));
                    });

                    if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
                        this.coShowConfirm250ImmDialog(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
                    } else {

                        this.showConfirm250ImmDialog(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
                    }

                    return false;
                }
                if (!this.userApprovedLt25 && this.outReachProgramType === OutReachProgramType.contracts) {
                    var userProfile = SessionDetails.GetUserProfile();
                    if (userProfile.userRole.toLowerCase() == "admin") {

                        this.showConfirm25ImmDialog(ErrorMessages['resolve'],
                            String.Format(ErrorMessages['MinNumberOfShots'], this.location25Names.join(',').replace(/CLINIC/g, ' LOCATION')));
                    }
                    else {
                        this._localContractService.setValidationsRequiredStatus(true);
                        this.showDialogV2(ErrorMessages['resolve'], this.location25Names.join(',').replace(/CLINIC/g, ' LOCATION'));
                    }
                }
                return false;
            }
        }
        else {
            let summary = ErrorMessages['MandatoryFields'];
            let errMsg = ErrorMessages['errMsg'];
            this.showDialog(errMsg, summary);
            return false;
        }
    }

    simpleSave(): boolean {
        if (!this.isFormValidForSimpleSave()) {
            return false;
        }

        this.contractForm.value.locations.forEach(loc => {
            try {
                if (loc.isNoClinic == true) {
                    loc.clinicDate = null;
                }
                if (loc.startTime != "" && Object.prototype.toString.call(loc.startTime) === '[object Date]')
                    try {
                        loc.startTime = this._datePipe.transform(loc.startTime, 'shortTime');
                    } catch (e) {
                        console.log(e);
                    }
                if (loc.endTime != "" && Object.prototype.toString.call(loc.endTime) === '[object Date]') {
                    try {
                        loc.endTime = this._datePipe.transform(loc.endTime, 'shortTime');
                    } catch (e) {
                        console.log(e);
                    }
                }
            } catch (e) {
                console.log(e); // here the exception is delibarately caught because when you say back, startTime & end
                // Time is not correct timeformat. Hence we are ignoring this by catching here.
            }

            // implemented in locations onchange event 
            // loc.clinicImzQtyList.forEach((element,index) => {
            //     
            //     let estimatedQty = loc.clinicImzQtyList.filter(p=>p.immunizationPk == element.immunizationPk)[0].estimatedQuantity;                
            //     loc.clinicImzQtyList.filter(p=>p.immunizationPk == element.immunizationPk).forEach(element => {
            //         element.estimatedQuantity = estimatedQty;
            //     });
            //     let frmar: FormArray = this.contractForm.get('locations') as FormArray;               
            //     let frmGrp = frmar.controls[0] as FormGroup;
            //     let clinicImzQtyListArr:any = frmar.controls[0].get('clinicImzQtyList') as FormArray ;
            //     let clinicImzQtyListGrp = clinicImzQtyListArr.controls[index] as FormGroup;
            //     clinicImzQtyListGrp.patchValue({
            //         estimatedQuantity: estimatedQty 
            //       });
            // });

        });
        if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
            this._localContractService.setCOClinicLocations(this.contractForm.value);
        }
        if (this.outReachProgramType === OutReachProgramType.equityevents) {
            this._localContractService.setCOClinicLocations(this.contractForm.value);
        }
        else {
            this._localContractService.setClinicLocations(this.contractForm.value);
        }
        return true;
    }

    isFormValid(): boolean {

        let frmar: FormArray = this.contractForm.get('locations') as FormArray;
        for (let index = 0; index < frmar.length; index++) {
            const element = frmar.controls[index] as FormGroup;
            this.utility.validateAllFormFields(element);
        }
        // return true;
        return this.contractForm.valid;
    }
    isFormValidForSimpleSave(): boolean {
        let frmar: FormArray = this.contractForm.get('locations') as FormArray;
        let isFormValid: boolean = true;
        for (let index = 0; index < frmar.length; index++) {
            const element = frmar.controls[index] as FormGroup;
            isFormValid = this.utility.validateFormGroupForSimpleSave(element);
        }
        return isFormValid;
    }

    checkSpecialCharacters(): boolean {
        try {
            for (let i = 0; i < this.contractForm.value.locations.length; i++) {
                if (!this.ccRegex.test(this.contractForm.value.locations[i].contactPhone.trim()) ||
                    !this.ccRegex.test(this.contractForm.value.locations[i].zipCode.trim())) {
                    throw false;
                }
                for (let j = 0; j < this.contractForm.value.locations[i].clinicImzQtyList.length; j++) {
                    if (!this.ccRegex.test(this.contractForm.value.locations[i].clinicImzQtyList[j].estimatedQuantity.trim())) {
                        throw false;
                    }
                }
            }
        } catch (e) {
            return e;
        }
    }
    isNonNoClinicPresent(): boolean {
        try {
            this.contractForm.value.locations.forEach(loc => {
                if (loc.isNoClinic <= 0 || loc.isNoClinic == false || loc.isNoClinic == undefined ||
                    loc.isNoClinic == null) {
                    throw true;
                }
            });
        } catch (e) { return e; }
        return false;
    }
    showDialog(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.locListErrModalDlg = true;
    }
    closeErrModalDlg() {
        this.locListErrModalDlg = false;
    }
    showDialogV2(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.locListModalDlg = true;
    }
    showConfirm250ImmDialog(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.locConfirm250ModalDlg = true;
    }
    coShowConfirm250ImmDialog(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.coLocConfirm250ModalDlg = true;
    }
    showConfirm25ImmDialog(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.locConfirm25ModalDlg = true;
    }

    okClickedV2() {
        this.locListModalDlg = false;
        switch (this.outReachProgramType) {
            case OutReachProgramType.contracts:
                break;
            case OutReachProgramType.communityoutreach:
                this.prouter.navigate(['./communityoutreach/storehome'])
            case OutReachProgramType.equityevents:
                this.prouter.navigate(['./communityoutreach/storehome'])
            default:
                return;
        }
    }
    coAcceptGt250Imm() {
        this.coLocConfirm250ModalDlg = false;
        this.userApprovedGt250 = true;
        if (this.location25Names.length <= 0) {
            this.userApprovedLt25 = true;
        }

        if (this.outReachProgramType == OutReachProgramType.equityevents) {
            if (this._localContractService.setEquityEventsClinicLocations(this.contractForm.value)) {
                if (this._localContractService.saveEquityEventsData()) {
                    if (this.submitEquityEventsData(false)) {

                    }
                }
            }

        }
        else if (this.outReachProgramType == OutReachProgramType.communityoutreach) {
            if (this._localContractService.setCOClinicLocations(this.contractForm.value)) {
                if (this._localContractService.saveCommunityOutreachData()) {
                    if (this.submitCoData(false)) {

                    }
                }
            }
        }

        return;
    }
    coRejectGt250Imm() {
        this.coLocConfirm250ModalDlg = false;
        this.userApprovedGt250 = false;
        return;
    }

    acceptGt250Imm() {

        this.userApprovedGt250 = true;
        if (this.location25Names.length <= 0) {
            this.userApprovedLt25 = true;
        }

        this.locConfirm250ModalDlg = false;
        if (this.userApprovedGt250 && this.userApprovedLt25) {
            if (this._localContractService.setClinicLocations(this.contractForm.value)) {
                this._alertService.clear();
                if (this.isNonNoClinicPresent()) {
                    if (this.outReachProgramType == OutReachProgramType.communityoutreach) {
                        this._alertService.info(ErrorMessages['coClinicDateReminderAfter2WeeksEN'], true);
                    }
                    else if (this.outReachProgramType == OutReachProgramType.equityevents) {
                        this._alertService.info(ErrorMessages['coClinicDateReminderAfter2WeeksEN'], true);
                    }
                    else {
                        this._alertService.info(ErrorMessages['clinicDateReminderAfter2WeeksEN'], true);
                    }
                }
                this.clinicSubmit.emit(3);
            }
        }
        return;
    }
    rejectGt250Imm() {

        this.locConfirm250ModalDlg = false;
        this.userApprovedGt250 = false;
        return;
    }
    acceptLt25Imm() {
        this.userApprovedLt25 = true;
        if (this.location250Names.length <= 0) {
            this.userApprovedGt250 = true;
        }
        this.locConfirm25ModalDlg = false;
        if (this.userApprovedGt250 && this.userApprovedLt25) {
            if (this._localContractService.setClinicLocations(this.contractForm.value)) {
                this._alertService.clear();
                if (this.outReachProgramType == OutReachProgramType.communityoutreach) {
                    this._alertService.info(ErrorMessages['coClinicDateReminderAfter2WeeksEN'], true);
                } else if (this.outReachProgramType == OutReachProgramType.equityevents) {
                    this._alertService.info(ErrorMessages['coClinicDateReminderAfter2WeeksEN'], true);
                } else {
                    this._alertService.info(ErrorMessages['clinicDateReminderAfter2WeeksEN'], true);
                }
                this.clinicSubmit.emit(3);
            }
        }
        return;
    }
    rejectLt25Imm() {
        this.locConfirm25ModalDlg = false;
        this.userApprovedLt25 = false;
        return;
    }
    confirm(hdr: string, msg: string) {
        this.confirmationService.confirm({
            message: msg,
            header: hdr,
            accept: () => {
                if (this._localContractService.setClinicLocations(this.contractForm.value)) {
                    this.clinicSubmit.emit(3);
                }
                return;
            },
            reject: () => {
                return;
            }
        });
    }
    confirmOverride(hdr: string, msg: string) {
        this.confirmationService.confirm({
            message: msg,
            header: hdr,
            accept: () => {

                if (this.outReachProgramType == OutReachProgramType.equityevents) {
                    this.submitEquityEventsData(true);
                }
                else if (this.outReachProgramType == OutReachProgramType.communityoutreach) {
                    this.submitCoData(true);
                }

                return;
            },
            reject: () => {
                return;
            }
        });
    }

    isClinicDateReminderBefore2WksReq(locString: String[]): Boolean {
        let isClinicDateReminder: Boolean = false;
        if (!this.doesClinicsHaveNoClinicAndMiscOptions()) {
            return false;
        }
        try {
            let today = new Date();
            for (let index = 0; index < this.contractForm.value.locations.length; index++) {
                const loc = this.contractForm.value.locations[index];
                if (loc.isNoClinic !== null && loc.isNoClinic !== undefined && loc.isNoClinic >= 1) continue;

                // let diffDays: number = loc.clinicDate.getMonth() == today.getMonth() ? Math.round(loc.clinicDate.getDate() - today.getDate())
                //  : this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(),0,0,0),
                //   new Date(loc.clinicDate.getFullYear(),loc.clinicDate.getMonth(), loc.clinicDate.getDate(),0,0,0));
                var loc_date = (typeof loc.clinicDate == 'string') ? new Date(loc.clinicDate) : loc.clinicDate;
                let diffDays: number = this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
                    new Date(loc_date.getFullYear(), loc_date.getMonth(), loc_date.getDate(), 0, 0, 0));
                if (diffDays < 14 && !this.is_reAgreement) {
                    isClinicDateReminder = true;
                    locString.push(this.location_number);
                }
            }
        } catch (e) {
            return e;
        }
        return isClinicDateReminder;
    }

    isStartTimeEndTimeValid() {
        let isValidCode: Number = 0;
        try {
            this.contractForm.value.locations.forEach(loc => {
                try {
                    if (loc.startTime != "" && Object.prototype.toString.call(loc.startTime) === '[object Date]')
                        try {
                            loc.startTime = this._datePipe.transform(loc.startTime, 'shortTime');
                        } catch (e) {
                            console.log(e);
                        }
                    if (loc.endTime != "" && Object.prototype.toString.call(loc.endTime) === '[object Date]') {
                        try {
                            loc.endTime = this._datePipe.transform(loc.endTime, 'shortTime');
                        } catch (e) {
                            console.log(e);
                        }
                    }
                } catch (e) {
                    console.log(e); // here the exception is delibarately caught because when you click back, startTime & end
                    // Time is not correct timeformat. Hence we are ignoring this by catching here.
                }
            });
            let today = new Date();
            for (let count = 0; count < this.contractForm.value.locations.length; count++) {
                let locClinicDate: Date = new Date(this.contractForm.value.locations[count].clinicDate);
                let locStartTime = this.contractForm.value.locations[count].startTime;
                let locEndTime = this.contractForm.value.locations[count].endTime;
                if (this.contractForm.value.locations[count].isNoClinic >= 1 ||
                    this.contractForm.value.locations[count].isNoClinic === '1' ||
                    this.contractForm.value.locations[count].isReassign >= 1 ||
                    this.contractForm.value.locations[count].isReassign === '1') {
                    continue;
                }
                let endDate: Date;
                if (locEndTime.toString().toLowerCase().search('am') !== -1 || locEndTime.toString().toLowerCase().search('pm') !== -1) {
                    endDate = this._timeToDatePipe.transform(locClinicDate, locEndTime);
                }
                else {
                    endDate = locEndTime;
                }
                let startDatetmToDt: Date;
                if (locStartTime.toString().toLowerCase().search('am') !== -1 || locStartTime.toString().toLowerCase().search('pm') !== -1) {
                    if (locStartTime.toString().toLowerCase().search('12:00 am') !== -1)
                        locStartTime = "00:00 AM"
                    if (locStartTime.toString().toLowerCase().search('12:30 am') !== -1)
                        locStartTime = "00:30 AM"
                    startDatetmToDt = this._timeToDatePipe.transform(locClinicDate, locStartTime);

                }
                else {
                    startDatetmToDt = locStartTime;
                }
                let locStart: Date = new Date(startDatetmToDt);
                let locEnd: Date = new Date(endDate);


                if (locEnd.valueOf() - locStart.valueOf() <= 0) {
                    isValidCode = 2;
                    break;
                }
                else if (locEnd.valueOf() - locStart.valueOf() > 28800000) {
                    isValidCode = 1;
                    break;
                }
                else {
                    isValidCode = 0;
                }
            }
        }
        catch (e) {
            return 3;
        }
        return isValidCode;
    }
    doesClinicsHaveNoClinicAndMiscOptions(): boolean {
        let hasCorpInvNdVchNd: boolean = this._localContractService.checkIfAnyImmunizationsHasCorpInvoiceAndVoucherNeeded(undefined);
        let hasNoClinicAndMiscOptions: boolean = false;
        this.contractForm.value.locations.forEach(element => {
            let isNoClinic: boolean = false;
            element.isNoClinic >= 1 && hasCorpInvNdVchNd ? isNoClinic = false : isNoClinic = true;
            hasNoClinicAndMiscOptions = hasNoClinicAndMiscOptions || isNoClinic;
        });
        return hasNoClinicAndMiscOptions;
    }

    submitCoData(is_override?: boolean): boolean {


        if (this.isFormValid()) {
            this.spinner.show();
            this.contractForm.value.locations.forEach(loc => {

                if (loc.startTime != "" && Object.prototype.toString.call(loc.startTime) === '[object Date]')
                    try {
                        loc.startTime = this._datePipe.transform(loc.startTime, 'shortTime');
                    } catch (e) { }
                try {
                    if (loc.endTime != "" && Object.prototype.toString.call(loc.endTime) === '[object Date]') {
                        loc.endTime = this._datePipe.transform(loc.endTime, 'shortTime');
                    }
                } catch (e) { }
            });
            if (this.outReachProgramType == OutReachProgramType.equityevents) {

                if (this._localContractService.setEquityEventsClinicLocations(this.contractForm.value)) {
                    if (this._localContractService.saveEquityEventsData()) {
                        var contact_date = new Date();
                        let coData: EquityEvent = this._localContractService.getEquityEventsData();
                        if (!this.is_reAgreementCommOutreach) {
                            coData.logOutreachStatus.businessPk = this.event_details.logOutreachStatus.businessPk;
                            coData.logOutreachStatus.firstName = this.event_details.logOutreachStatus.firstName;
                            coData.logOutreachStatus.lastName = this.event_details.logOutreachStatus.lastName;
                            coData.logOutreachStatus.jobTitle = this.event_details.logOutreachStatus.jobTitle;
                            coData.logOutreachStatus.outreachProgram = this.event_details.logOutreachStatus.outreachProgram;
                            coData.logOutreachStatus.outreachBusinessPk = this.event_details.logOutreachStatus.outreachBusinessPk;
                            coData.logOutreachStatus.contactDate = this.event_details.logOutreachStatus.contactDate;



                            coData.logOutreachStatus.outreachStatusId = this.event_details.logOutreachStatus.outreachStatusId;
                            coData.logOutreachStatus.outreachStatusTitle = this.event_details.logOutreachStatus.outreachStatusTitle;
                            coData.logOutreachStatus.feedback = this.event_details.logOutreachStatus.feedback;
                            coData.logOutreachStatus.createdBy = this.event_details.logOutreachStatus.createdBy;
                        }
                        coData.isApproved = 1;
                        coData.isEmailSent = 1;
                        coData.approvedOrRejectedBy = "CommunityUpdate@WagOutreach.com";
                        coData.contactLogPk = -1;
                        coData.clinicAgreementPk = -1;
                        if (this.is_reAgreementCommOutreach) {
                            coData.clinicAgreementPk = +sessionStorage['clinic_agreement_pk'];
                            coData.logOutreachStatus.businessPk = JSON.parse(sessionStorage['opportunitie_data']).businessPk;

                        }
                        var re = /COVID-19/gi;
                        coData.clinicList.forEach(cl => {
                            try {
                                if (cl.clinicDate === null || cl.clinicDate === undefined || cl.clinicDate === '') {
                                    cl.clinicDate = null;
                                } else {
                                    cl.clinicDate = this._datePipe.transform(cl.clinicDate, "MM/dd/yyyy");
                                }
                            } catch (e) {
                                console.log('error in saveCOTODB');
                            }
                            let covidImmList = cl.clinicImzQtyList.filter(p => p.immunizationName.search(re) >= 0);

                            covidImmList.length > 0 || this.isCovidVaccineAvailable ? cl.isCovidClinic = 1 : cl.isCovidClinic = 0;
                        });
                        coData.clinicImmunizationList.forEach(im => {
                            try {
                                if (im.voucherExpirationDate != null && im.voucherExpirationDate != undefined && im.voucherExpirationDate != '') {
                                    im.voucherExpirationDate = this._datePipe.transform(im.voucherExpirationDate, "MM/dd/yyyy");
                                }

                            } catch (e) {
                                console.log('error in saveCOTODB');
                            }
                        });
                        if (coData.clinicList.length <= 0) {
                            this.showDialog(ErrorMessages['equityEvents'], ErrorMessages['coClinicListEmpty']);
                            return false;
                        }
                        console.log(coData);
                        this._localContractService.saveEquityEventDataToDB(SessionDetails.GetStoreId(), 13, coData, is_override, this.is_reAgreementCommOutreach).subscribe((data: any) => {
                            this.spinner.hide();
                            switch (data.errorS.errorCode) {
                                case 0:
                                case '0':
                                    this.showDialogV2(ErrorMessages['equityEvents'], ErrorMessages['eqSaved']);
                                    return true;
                                case -4:
                                case '-4':
                                    this.showHideBtns.emit(1);
                                    this.OverrideOrOkTitle = "Override";
                                    this.showCancelBtn = true;
                                    this.confirmOverride(ErrorMessages['eventDateTimeConflict'],
                                        String.Format("{0}<br/><br/>{1}", data.errorS.errorMessage, ErrorMessages['eventDateTimeConflictErrText']));
                                    return false;
                                case -9:
                                case '-9':
                                    this.showDialogV2(ErrorMessages['equityEvents'], data.errorS.errorMessage);
                                    return true;
                                default:
                                    this.showDialog(ErrorMessages['equityEvents'], data.errorS.errorMessage);
                                    return false;
                            }
                        },
                            err => {
                                this.spinner.hide();
                                switch (err) {
                                    case 500:
                                        this.showDialogV2(ErrorMessages['serverError'], err.json().Message);
                                        return false;
                                    case 400:
                                        this.showDialog(ErrorMessages['clientError'], err.json().Message);
                                        return false;
                                    default:
                                        this.showDialog(ErrorMessages['serverError'], err.json().Message);
                                        return false;
                                }
                            });
                    }

                    return true;
                }
            }
            else if (this.outReachProgramType == OutReachProgramType.communityoutreach) {

                if (this._localContractService.setCOClinicLocations(this.contractForm.value)) {
                    if (this._localContractService.saveCommunityOutreachData()) {
                        var contact_date = new Date();
                        let coData: CommunityOutreach = this._localContractService.getCommunityOutreachData();
                        if (!this.is_reAgreementCommOutreach) {
                            coData.logOutreachStatus.businessPk = this.event_details.logOutreachStatus.businessPk;
                            coData.logOutreachStatus.firstName = this.event_details.logOutreachStatus.firstName;
                            coData.logOutreachStatus.lastName = this.event_details.logOutreachStatus.lastName;
                            coData.logOutreachStatus.jobTitle = this.event_details.logOutreachStatus.jobTitle;
                            coData.logOutreachStatus.outreachProgram = this.event_details.logOutreachStatus.outreachProgram;
                            coData.logOutreachStatus.outreachBusinessPk = this.event_details.logOutreachStatus.outreachBusinessPk;
                            coData.logOutreachStatus.contactDate = this.event_details.logOutreachStatus.contactDate;

                            // coData.logOutreachStatus.contactDate =  new Date(this.event_details.logOutreachStatus.contactDate.getFullYear(),
                            // this.event_details.logOutreachStatus.contactDate.getMonth()
                            // ,this.event_details.logOutreachStatus.contactDate.getDate(),contact_date.getHours(),
                            // contact_date.getMinutes(),contact_date.getSeconds(),
                            // contact_date.getMilliseconds());

                            coData.logOutreachStatus.outreachStatusId = this.event_details.logOutreachStatus.outreachStatusId;
                            coData.logOutreachStatus.outreachStatusTitle = this.event_details.logOutreachStatus.outreachStatusTitle;
                            coData.logOutreachStatus.feedback = this.event_details.logOutreachStatus.feedback;
                            coData.logOutreachStatus.createdBy = this.event_details.logOutreachStatus.createdBy;
                        }
                        coData.isApproved = 1;
                        coData.isEmailSent = 1;
                        coData.approvedOrRejectedBy = "CommunityUpdate@WagOutreach.com";
                        coData.contactLogPk = -1;
                        coData.clinicAgreementPk = -1;
                        if (this.is_reAgreementCommOutreach) {
                            coData.clinicAgreementPk = +sessionStorage['clinic_agreement_pk'];
                            coData.logOutreachStatus.businessPk = JSON.parse(sessionStorage['opportunitie_data']).businessPk;

                        }
                        var re = /COVID-19/gi;
                        coData.clinicList.forEach(cl => {
                            try {
                                if (cl.clinicDate === null || cl.clinicDate === undefined || cl.clinicDate === '') {
                                    cl.clinicDate = null;
                                } else {
                                    cl.clinicDate = this._datePipe.transform(cl.clinicDate, "MM/dd/yyyy");
                                }
                            } catch (e) {
                                console.log('error in saveCOTODB');
                            }
                            let covidImmList = cl.clinicImzQtyList.filter(p => p.immunizationName.search(re) >= 0);

                            covidImmList.length > 0 || this.isCovidVaccineAvailable ? cl.isCovidClinic = 1 : cl.isCovidClinic = 0;
                        });
                        coData.clinicImmunizationList.forEach(im => {
                            try {
                                if (im.voucherExpirationDate != null && im.voucherExpirationDate != undefined && im.voucherExpirationDate != '') {
                                    im.voucherExpirationDate = this._datePipe.transform(im.voucherExpirationDate, "MM/dd/yyyy");
                                }

                            } catch (e) {
                                console.log('error in saveCOTODB');
                            }
                        });
                        if (coData.clinicList.length <= 0) {
                            this.showDialog(ErrorMessages['comOutreach'], ErrorMessages['coClinicListEmpty']);
                            return false;
                        }

                        this._localContractService.saveCODataToDB(SessionDetails.GetStoreId(), 6, coData, is_override, this.is_reAgreementCommOutreach).subscribe((data: any) => {
                            this.spinner.hide();
                            switch (data.errorS.errorCode) {
                                case 0:
                                case '0':
                                    this.showDialogV2(ErrorMessages['comOutreach'], ErrorMessages['coSaved']);
                                    return true;
                                case -4:
                                case '-4':
                                    this.showHideBtns.emit(1);
                                    this.OverrideOrOkTitle = "Override";
                                    this.showCancelBtn = true;
                                    this.confirmOverride(ErrorMessages['eventDateTimeConflict'],
                                        String.Format("{0}<br/><br/>{1}", data.errorS.errorMessage, ErrorMessages['eventDateTimeConflictErrText']));
                                    //this.showDialog(ErrorMessages['comOutreach'], data.errorS.errorMessage);
                                    return false;
                                case -9:
                                case '-9':
                                    this.showDialogV2(ErrorMessages['comOutreach'], data.errorS.errorMessage);
                                    return true;
                                default:
                                    this.showDialog(ErrorMessages['comOutreach'], data.errorS.errorMessage);
                                    return false;
                            }
                        },
                            err => {
                                this.spinner.hide();
                                switch (err) {
                                    case 500:
                                        this.showDialogV2(ErrorMessages['serverError'], err.json().Message);
                                        return false;
                                    case 400:
                                        this.showDialog(ErrorMessages['clientError'], err.json().Message);
                                        return false;
                                    default:
                                        this.showDialog(ErrorMessages['serverError'], err.json().Message);
                                        return false;
                                }
                            });
                    }

                    return true;
                }
            }

        }
        else {
            let summary = ErrorMessages['MandatoryFields'];
            let errMsg = ErrorMessages['errMsg'];
            this.showDialog(errMsg, summary);
            return false;
        }
        return false;
    }

    //  Save Equity Events Data

    submitEquityEventsData(is_override?: boolean): boolean {


        if (this.isFormValid()) {
            this.spinner.show();
            this.contractForm.value.locations.forEach(loc => {

                if (loc.startTime != "" && Object.prototype.toString.call(loc.startTime) === '[object Date]')
                    try {
                        loc.startTime = this._datePipe.transform(loc.startTime, 'shortTime');
                    } catch (e) { }
                try {
                    if (loc.endTime != "" && Object.prototype.toString.call(loc.endTime) === '[object Date]') {
                        loc.endTime = this._datePipe.transform(loc.endTime, 'shortTime');
                    }
                } catch (e) { }
            });

            if (this._localContractService.setEquityEventsClinicLocations(this.contractForm.value)) {
                if (this._localContractService.saveEquityEventsData()) {
                    var contact_date = new Date();
                    let coData: EquityEvent = this._localContractService.getEquityEventsData();
                    if (!this.is_reAgreementCommOutreach) {
                        coData.logOutreachStatus.businessPk = this.event_details.logOutreachStatus.businessPk;
                        coData.logOutreachStatus.firstName = this.event_details.logOutreachStatus.firstName;
                        coData.logOutreachStatus.lastName = this.event_details.logOutreachStatus.lastName;
                        coData.logOutreachStatus.jobTitle = this.event_details.logOutreachStatus.jobTitle;
                        coData.logOutreachStatus.outreachProgram = this.event_details.logOutreachStatus.outreachProgram;
                        coData.logOutreachStatus.outreachBusinessPk = this.event_details.logOutreachStatus.outreachBusinessPk;
                        coData.logOutreachStatus.contactDate = this.event_details.logOutreachStatus.contactDate;



                        coData.logOutreachStatus.outreachStatusId = this.event_details.logOutreachStatus.outreachStatusId;
                        coData.logOutreachStatus.outreachStatusTitle = this.event_details.logOutreachStatus.outreachStatusTitle;
                        coData.logOutreachStatus.feedback = this.event_details.logOutreachStatus.feedback;
                        coData.logOutreachStatus.createdBy = this.event_details.logOutreachStatus.createdBy;
                    }
                    coData.isApproved = 1;
                    coData.isEmailSent = 1;
                    coData.approvedOrRejectedBy = "CommunityUpdate@WagOutreach.com";
                    coData.contactLogPk = -1;
                    coData.clinicAgreementPk = -1;
                    if (this.is_reAgreementCommOutreach) {
                        coData.clinicAgreementPk = +sessionStorage['clinic_agreement_pk'];
                        coData.logOutreachStatus.businessPk = JSON.parse(sessionStorage['opportunitie_data']).businessPk;

                    }
                    var re = /COVID-19/gi;
                    coData.clinicList.forEach(cl => {
                        try {
                            if (cl.clinicDate === null || cl.clinicDate === undefined || cl.clinicDate === '') {
                                cl.clinicDate = null;
                            } else {
                                cl.clinicDate = this._datePipe.transform(cl.clinicDate, "MM/dd/yyyy");
                            }
                        } catch (e) {
                            console.log('error in saveCOTODB');
                        }
                        let covidImmList = cl.clinicImzQtyList.filter(p => p.immunizationName.search(re) >= 0);

                        covidImmList.length > 0 || this.isCovidVaccineAvailable ? cl.isCovidClinic = 1 : cl.isCovidClinic = 0;
                    });
                    coData.clinicImmunizationList.forEach(im => {
                        try {
                            if (im.voucherExpirationDate != null && im.voucherExpirationDate != undefined && im.voucherExpirationDate != '') {
                                im.voucherExpirationDate = this._datePipe.transform(im.voucherExpirationDate, "MM/dd/yyyy");
                            }

                        } catch (e) {
                            console.log('error in saveCOTODB');
                        }
                    });
                    if (coData.clinicList.length <= 0) {
                        this.showDialog(ErrorMessages['equityEvents'], ErrorMessages['coClinicListEmpty']);
                        return false;
                    }
                    console.log(coData);
                    this._localContractService.saveEquityEventDataToDB(SessionDetails.GetStoreId(), 13, coData, is_override, this.is_reAgreementCommOutreach).subscribe((data: any) => {
                        this.spinner.hide();
                        switch (data.errorS.errorCode) {
                            case 0:
                            case '0':
                                this.showDialogV2(ErrorMessages['equityEvents'], ErrorMessages['eqSaved']);
                                return true;
                            case -4:
                            case '-4':
                                this.showHideBtns.emit(1);
                                this.OverrideOrOkTitle = "Override";
                                this.showCancelBtn = true;
                                this.confirmOverride(ErrorMessages['eventDateTimeConflict'],
                                    String.Format("{0}<br/><br/>{1}", data.errorS.errorMessage, ErrorMessages['eventDateTimeConflictErrText']));
                                return false;
                            case -9:
                            case '-9':
                                this.showDialogV2(ErrorMessages['equityEvents'], data.errorS.errorMessage);
                                return true;
                            default:
                                this.showDialog(ErrorMessages['equityEvents'], data.errorS.errorMessage);
                                return false;
                        }
                    },
                        err => {
                            this.spinner.hide();
                            switch (err) {
                                case 500:
                                    this.showDialogV2(ErrorMessages['serverError'], err.json().Message);
                                    return false;
                                case 400:
                                    this.showDialog(ErrorMessages['clientError'], err.json().Message);
                                    return false;
                                default:
                                    this.showDialog(ErrorMessages['serverError'], err.json().Message);
                                    return false;
                            }
                        });
                }

                return true;
            }
        }
        else {
            let summary = ErrorMessages['MandatoryFields'];
            let errMsg = ErrorMessages['errMsg'];
            this.showDialog(errMsg, summary);
            return false;
        }
        return false;
    }







    cancelLocationData(): boolean {
        var compare_objects = false;
        this.locations_data.value.previousContact = 0;
        this.locations_data.value.previousLocation = 0;
        this.locations_data1.value.previousContact = 0;
        this.locations_data1.value.previousLocation = 0;

        compare_objects = this.utility.compareTwoObjects(this.locations_data.value, this.locations_data1.value);
        return compare_objects;
    }

    checkIfAnyFluImmIsSelected() {
        let added_immunizations: Immunization2[] = [];
        if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
            added_immunizations = this._localContractService.getCOSelectedImmunizations();
        }
        else if (this.outReachProgramType === OutReachProgramType.equityevents) {
            added_immunizations = this._localContractService.getCOSelectedImmunizations();
        }
        else {
            added_immunizations = this._localContractService.getSelectedImmunizations();
        }
        try {
            added_immunizations.forEach(rec => {
                if (rec.immunizationName.toLowerCase().search('flu') != -1) {
                    throw true;
                }
            });
        } catch (e) {
            if (e === true)
                return true;
        }
        return false;
    }

    getRestrictedStatesDatesValidity(): string[] {
        let errMsgs: string[] = [];
        let isValidDates: boolean = true;
        let state = SessionDetails.GetState();

        if (this.utility.isStoreIsfromRestrictedState() && this.checkIfAnyFluImmIsSelected()) {
            for (let count = 0; count < this.contractForm.value.locations.length; count++) {
                let locClinicDate: Date = new Date(this.contractForm.value.locations[count].clinicDate);
                if (state == "MO") {
                    if (locClinicDate.getMonth() <= 2 && locClinicDate.getFullYear() == 2020) {
                        //TODO TO TEST
                        var userProfile = SessionDetails.GetUserProfile();
                        var lowerCaseName = userProfile.userRole.toLowerCase();
                        if (lowerCaseName == "director" ||
                            lowerCaseName == "supervisor" || lowerCaseName == "regionalvp" ||
                            lowerCaseName == "regionaldirector" || lowerCaseName == "admin" ||
                            lowerCaseName == "regional vice president" ||
                            lowerCaseName == "regional healthcare director" ||
                            lowerCaseName == "healthcare supervisor"
                            || lowerCaseName == "director - rx and retail ops" || lowerCaseName == "district manager") {
                            continue;
                        }
                        errMsgs.push(String.Format(ErrorMessages['restrictedStateDateConflict'], this.contractForm.value.locations[count].location));
                        isValidDates = false;
                    }
                }
                else if (state == "DC") {
                    if (locClinicDate.getMonth() <= 1) {
                        var userProfile = SessionDetails.GetUserProfile();
                        var lowerCaseName = userProfile.userRole.toLowerCase();
                        if (lowerCaseName == "director" ||
                            lowerCaseName == "supervisor" || lowerCaseName == "regionalvp" ||
                            lowerCaseName == "regionaldirector" || lowerCaseName == "admin" ||
                            lowerCaseName == "regional vice president" ||
                            lowerCaseName == "regional healthcare director" ||
                            lowerCaseName == "healthcare supervisor"
                            || lowerCaseName == "director - rx and retail ops" || lowerCaseName == "district manager") {
                            continue;
                        }
                        errMsgs.push(String.Format(ErrorMessages['restrictedStateDateConflict'], this.contractForm.value.locations[count].location));
                        isValidDates = false;
                    }
                }
            }
        }
        return errMsgs;
    }
    showDateTimeOverLpDlg(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.showDateTimeOverlpDlg = true;
    }
    OverRideDateTimeDlg() {
        this.userOverriddenDateTimeConflict = true;
        this.showDateTimeOverlpDlg = false;
        if (this.outReachProgramType === OutReachProgramType.contracts) {
            if (this.save()) {
                this.clinicSubmit.emit(3);
            }
        }
        else if (this.outReachProgramType === OutReachProgramType.equityevents) {
            this.clinicSubmit.emit(3);
        }
        else if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
            this.clinicSubmit.emit(3);
        }
    }
    CancelDateTimeDlg() {
        this.userOverriddenDateTimeConflict = false;
        this.showDateTimeOverlpDlg = false;
    }
    showVisitsGapOverLpDlg(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.showVisitsGapOverlpDlg = true;
    }
    OverRideVisitsGapDlg() {
        this.userOverriddenVisitsGapConflict = true;
        this.showVisitsGapOverlpDlg = false;
        if (this.outReachProgramType === OutReachProgramType.contracts) {
            if (this.save()) {
                this.clinicSubmit.emit(3);
            }
        }
        else if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
            this.clinicSubmit.emit(3);
        }
        else if (this.outReachProgramType === OutReachProgramType.equityevents) {
            this.clinicSubmit.emit(3);
        }
    }
    CancelVisitsGapDlg() {
        this.userOverriddenVisitsGapConflict = false;
        this.showVisitsGapOverlpDlg = false;

    }

    canDeleteTheClinic(clinicIndex: number) {
        if (this.contractForm.controls.locations.value.length > 1 && !this.is_reAgreement) {
            return true;
        }
        else if (this.is_reAgreement) {
            if (clinicIndex < this.totalClinicsCount) {
                return false;
            }
            else {
                return true;
            }
        }
    }
}
