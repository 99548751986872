
export class HighLevelCompliance {
    outreachEffort: string = null;
    yearStartDate: string = null;
    quarter: string = null;
    reportName: string = "";
    outputType: string = "";
    constructor(outreach_effort: string, year_start_date: string, quarter, report_name: string, output_type: string) {
        this.outreachEffort = outreach_effort;
        this.yearStartDate = year_start_date;
        this.quarter = quarter;
        this.reportName = report_name;
        this.outputType = output_type;
    }
}