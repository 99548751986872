import { Component, OnInit, ViewChild,  Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { OutreachProgramService } from '../../services/outreach-program.service';
import { ContractData, WalgreenCo, Client, Clinic, LegalNoticeAddress, LanguageVariables } from '../../../../models/contract';//Contract
import { ValidationHandler } from '../../Helpers/ValidationHandler';
import { AlertService } from '../../../../alert.service';
import { ContractComponent } from '../local-contracts/contract.component';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { Util } from '../../../../utility/util';
import { String, StringBuilder } from 'typescript-string-operations';
import { SessionDetails } from '../../../../utility/session';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css'],
  providers: [ValidationHandler,DatePipe]
})
export class AgreementComponent implements OnInit {
  userProfile: any;
  InvoiceInformation:any=[];
  clinicImmQtyList: any[] = [];
  immunizationFromDB: any[] = [];
  displayInvoiceAndVoucher:boolean=false;
  isStoreBusinessClinicAdded:boolean =false;
  clinicAgreement: ContractData;
  currentUser = [];
  userRecords = [];
  immunizationInformation = [];
  clinicInformation = [];
  clientInformation: Client;
  walGreensInfo: WalgreenCo;
  printAgreement: boolean = false;
  isAmendAgreement : boolean = false;
  agreementEmails: string = '';
  dialogSummary: string;
  dialogMsg: string;
  confirmDialogSummary: string;
  confirmDialogMsg: string;
  showConfirmDialog = false;
  is_email: boolean;
  display: boolean = false;
  displayAmend: boolean = false;
  enlanguageSelection: boolean;
  splanguageSelection: boolean;
  englishLanguage: string;
  enheader: string;
  enattachment: string;
  
  spanishLanguage: string;
  headerText: string;
  attachment: string;
  imz: string;
  payment: string;
  rates: string;
  language: string;
  success_message: boolean = false;
  today:Date = new Date();
  currentLanguage: string;
  regularContract: FormGroup;
  isSelected:boolean = false;
  isCovidVaccineAvailable:boolean = false;
  @Output() clinicSubmit: EventEmitter<number> = new EventEmitter();
  @ViewChild(ContractComponent) cmp: ContractComponent;

  /////////////////////////////////////////// LANGUAGE Variables ///////////////////////
  languageVariables = new LanguageVariables();
  
 fluExpiryDate = '';
 routineExpiryDate = '';
 sendInvoiceTo= '';
 isTaxExempt = ''
 isCopay= '';
 voucherNeeded = ''
 voucherExpiry = ''
 copayValue = '';
 attn = "Attn"
 OverrideOrOkTitle:string = "Override";
 showCancelBtn:boolean = true
 isCovidVoucher: boolean = false;
 opportunityData;
 privateClinicFees:string = "";
 showPreUniversalContract:boolean = false;
 siteMinimum:number = 25;
//  isFluOrStandardCovid: boolean = false;
//   isLtcfFluOrLtcfCovid: boolean = false;
  //////////////////////////////////////////

  constructor(private _apiservice: OutreachProgramService,  private _fb: FormBuilder,private router: Router, 
    private utility: Util,
    private _datePipe: DatePipe, private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService) {
    this.success_message = false;
  }

  ngOnInit() {
    
    window.scrollTo(0, 0);
    this.userProfile = SessionDetails.GetUserProfile();
    this.opportunityData = SessionDetails.GetopportunitiesData();
    this.privateClinicFees = this.opportunityData?this.opportunityData.privateClinicFees:"8.5";
    this.printAgreement = false;
    this.clinicAgreement = this._apiservice.fetchContractAgreementData();
    this.userRecords = this.clinicAgreement.clinicImmunizationList;
    let covidVaccine = this.userRecords.filter(p=>p.immunizationName.includes('COVID19 Vaccine (')).length > 0;
    //let CovidVoucher = this.userRecords.filter(item=>item.immunizationName.includes('Vaccine Voucher')).length > 0;
    if(covidVaccine){
      this.isCovidVoucher = true;
    }
    // else{
    //   this.isCovidVoucher = true;
    // }
    if(sessionStorage["AmendAgreement"]!=null&&sessionStorage["AmendAgreement"]=="true"){
      this.isAmendAgreement = true;
    }
    else{
      this.isAmendAgreement = false;
    }
    this.displayInvoiceInfo();
    this.GetClinicInformation();
    //this.GetImmunizationformation();
    this.GetClientInformation();
    this.GetWalgreensCOformation();
    this.GetClientLegalInfo();
    this.enlanguageSelection = true;
    this.splanguageSelection = false;
    if(this.splanguageSelection) {
      this.languageChangeToSpanish();
    } else {
      this.languageChangeToEnglish();
    }
    this.isSelected = false;
    let isApproved:string = null;
    if(this.clinicAgreement.isApproved == null){
      isApproved = '';
    } else if(Number(this.clinicAgreement.isApproved) >=1 ){
      isApproved = '1';
    } else if(Number(this.clinicAgreement.isApproved) <=0 ){
      this.isSelected = true;
      isApproved = '2';
    }
    
    this.regularContract = this._fb.group({
      email_signature: this._fb.control(this.clinicAgreement.signature, null),
      user_notes: this._fb.control(this.clinicAgreement.notes, null),
      radioBtn: this._fb.control(isApproved, null),
      email: this._fb.control('', null)
    });
    this.isCovidVaccineAvailable =   this.clinicAgreement.clinicImmunizationList.filter(p=>p.immunizationName.search(/COVID19/gi)>=0).length>0;
    let opportunitie_data = SessionDetails.GetopportunitiesData();    
    if(opportunitie_data.covidOpportunityType==2 || opportunitie_data.covidOpportunityType==0 || opportunitie_data.fluOpportunityType==0)
    {
      // this.isFluOrStandardCovid = true;
      this.siteMinimum = 25;        
    }
    if(opportunitie_data.covidOpportunityType==1 || opportunitie_data.fluOpportunityType==3 )
    {
      // this.isLtcfFluOrLtcfCovid = true;
      this.siteMinimum = 15;

    }
  }

  getPaymentTypes(imz)
  {
    
    let paymentTypes = '';
    let immzs = this.clinicAgreement.clinicImmunizationList.filter(p=>p.immunizationPk == imz.immunizationPk);
    immzs.forEach((element, index, array) => {
      paymentTypes += `<p class="payment-mb10"><span>`
      paymentTypes += element.paymentTypeName;
      if(index !== (array.length -1)) {
        paymentTypes += ',';
      }
      paymentTypes += `</span></p>`
    });
    return paymentTypes;
  }

  displayInvoiceInfo()
  {
    
      if (this.userRecords != null)
      {
        let corp=this.userRecords.filter(c=>c.paymentTypeId=="6");
        let routineExpDateLst=corp.filter(c=>c.immunizationName.toLowerCase().search('flu') == -1);
        let covidExpDateLst=corp.filter(c=>c.immunizationName.toLowerCase().search('covid')!==-1);
        let flueExpDateLst=corp.filter(c=>c.immunizationName.toLowerCase().search('flu') != -1);
          if(corp!=null && corp.length>0)
          {
            this.displayInvoiceAndVoucher=true;
              this.InvoiceInformation.push({ 
              "paymentTypeId": corp[0].paymentTypeId,
              "sendInvoiceTo": corp[0].sendInvoiceTo,
              "name": corp[0].name,
              "phone": corp[0].phone,
              "fax": corp[0].fax,
              "email": corp[0].email,
              "address1": corp[0].address1,
              "address2": corp[0].address2,
              "city": corp[0].city,
              "isTaxExempt": corp[0].isTaxExempt,
              "state": corp[0].state,
              "zip": corp[0].zip,
              "isCopay": corp[0].isCopay,
              "copayValue": corp[0].copayValue,
              "isVoucherNeeded": corp[0].isVoucherNeeded,
              "fluvoucherExpirationDate": flueExpDateLst.length>0? flueExpDateLst[0].voucherExpirationDate : null,
              "routinevoucherExpirationDate": routineExpDateLst.length>0? routineExpDateLst[0].voucherExpirationDate : null,
              "covidvoucherExpirationDate": covidExpDateLst.length>0? covidExpDateLst[0].voucherExpirationDate : null
            });
          }
      }   
  
  }
  updateClinicalReqsFields(){
    let today:Date = new Date();
    this.clinicAgreement.clinicImmunizationList.forEach(imm=>{
      if(imm && imm.isClinicalReqd == true){
        imm.clinicalReqdApprovedBy = this.userProfile.userPk;
      }
    });
  }
  public GetClinicInformation() {
    
    this.clinicInformation = this.clinicAgreement.clinicList;
    this.clinicInformation.forEach(cl=>{
      try{
      if(typeof(cl.clinicDate)=='string'){
      let dateString = cl.clinicDate.substring(0, cl.clinicDate.indexOf('T'));
      if(dateString.length>0){
      let dateSplitTokens = dateString.split('-');
      if(dateSplitTokens.length>0){
      var dt = new Date(dateSplitTokens[0], dateSplitTokens[1] - 1, dateSplitTokens[2], 18, 30, 30);
      var clDate = new Date(dt);
      cl.clinicDate = clDate.getMonth() + 1 +"/" +clDate.getDate() +"/" + clDate.getFullYear();      
      }
      }
    }
  }
    catch(e){
      console.log(e);
    }
    });
    if(this.clinicAgreement.isApproved!=null){
    this.clinicAgreement.isApproved = Number( this.clinicAgreement.isApproved);    }
  }

  public GetClientInformation() {
    this.clientInformation = this.clinicAgreement.clientInfo != null ? this.clinicAgreement.clientInfo : new Client();
  }

  public GetWalgreensCOformation() {
    
    this.walGreensInfo = this.clinicAgreement.walgreenCoInfo != null ? this.clinicAgreement.walgreenCoInfo : new WalgreenCo();
    if(this.walGreensInfo.preparedDate != null && this.walGreensInfo.preparedDate != undefined && this.walGreensInfo.preparedDate != ''){
      this.walGreensInfo.preparedDate = this._datePipe.transform(this.walGreensInfo.preparedDate,"MM/dd/yyyy");
    }
  }
  GetClientLegalInfo() {
    
    this.clinicAgreement.legalNoticeAddressInfo = this.clinicAgreement.legalNoticeAddressInfo != null ? this.clinicAgreement.legalNoticeAddressInfo : new LegalNoticeAddress();
    if(this.clinicAgreement.legalNoticeAddressInfo.attentionTo != null && this.clinicAgreement.legalNoticeAddressInfo.attentionTo != ''){
      this.attn = "Attn:";
    } else {
      this.attn = "";
    }
  }
  generateArray(obj) {
    return Object.keys(obj).map((key) => { return obj[key] });
  }
  btnClick() {
    this.clinicSubmit.emit(1);
  };

  saveAgreement() {
    this.spinner.show();
    this.clinicAgreement.contractPostedByUser = 'Walgreens User';
    this.clinicAgreement.isEmailSent = 0;
    this.clinicAgreement.contactWagUser = '';
    this.clinicAgreement.isApproved = null;
    this.clinicAgreement.businessUserEmails = this.agreementEmails;
    this.makeValidBooleans();
   

    this.saveAgreementData(false, false);

    if(sessionStorage.getItem('Old_immunizationForm') !== null){
      sessionStorage.removeItem('Old_immunizationForm');
    }
  }

  saveAgreementData(is_email: boolean, is_override?: boolean) {
    
    if(sessionStorage["AmendAgreement"] && sessionStorage["AmendAgreement"]=="true")
    {
      if(!this.clinicAgreement.parentClinicAgreementId || this.clinicAgreement.parentClinicAgreementId==0)
      {
        if(sessionStorage["isStoreBusinessClinicAdded"] && sessionStorage["isStoreBusinessClinicAdded"]=="true")
            {
              this.isStoreBusinessClinicAdded=true;
            }

        if( sessionStorage["OldImmunizations"] && sessionStorage["clinicList"])
        {
          this.immunizationFromDB=JSON.parse(sessionStorage["OldImmunizations"]);
         
          this.immunizationFromDB.forEach(rec => {
          
            rec.voucherExpirationDate =rec.voucherExpirationDate!=null ? this._datePipe.transform(rec.voucherExpirationDate,"MM/dd/yyyy"):rec.voucherExpirationDate;
            if(rec.paymentTypeId=="6")
            {
            rec.isCopay>=1||  rec.isCopay===true? rec.isCopay=1: rec.isCopay==null?rec.isCopay=null: rec.isCopay=0;
            rec.copayValue = (rec.copayValue==null) ? 0 : rec.copayValue;
            rec.isTaxExempt>=1|| rec.isTaxExempt===true? rec.isTaxExempt=1:rec.isTaxExempt==null?rec.isTaxExempt=null:rec.isTaxExempt=0;
            rec.isVoucherNeeded>=1|| rec.isVoucherNeeded===true? rec.isVoucherNeeded=1:rec.isVoucherNeeded==null? rec.isVoucherNeeded=null:rec.isVoucherNeeded=0;
            }
            else
            {
             rec.isCopay>=1||  rec.isCopay===true? rec.isCopay=1:  rec.isCopay=null;
             
             rec.isTaxExempt>=1|| rec.isTaxExempt===true? rec.isTaxExempt=1:rec.isTaxExempt=null;
             rec.isVoucherNeeded>=1|| rec.isVoucherNeeded===true? rec.isVoucherNeeded=1:rec.isVoucherNeeded=null;
 
            }
          });
         
          this.clinicImmQtyList=[];
              this.clinicAgreement.clinicList.forEach((loc, index) => { 
                loc.clinicImzQtyList.forEach(ele => {
                  ele.clinicPk=null;
                  ele.estimatedQuantity=Number(ele.estimatedQuantity);
                  this.clinicImmQtyList.push(ele);  
                });
                
              });

           let diff : any  = this.utility.getDifference(this.clinicAgreement.clinicImmunizationList, this.immunizationFromDB);
          
          let diffClinicList: any  = this.utility.getDifference(this.clinicImmQtyList,JSON.parse(sessionStorage["clinicList"]));
          if(!this.isStoreBusinessClinicAdded &&( Object.keys(diff).length ==0 && Object.keys(diffClinicList).length ==0))
          {
            this.displayAmend=true;
            this.spinner.hide();
            return;
           } 
        
        }

        this.clinicAgreement.parentClinicAgreementId=this.clinicAgreement.clinicAgreementPk;
        this.clinicAgreement.clinicAgreementPk=null;
        this.clinicAgreement.isApproved=null;
        this.clinicAgreement.approvedOrRejectedBy=null;
        this.clinicAgreement.signature=null;
        this.clinicAgreement.notes=null;        
      }
    }
    this.clinicAgreement.modifiedBy=this.userProfile.userPk;       
    if (is_email) {
      this.clinicAgreement.isEmailSent = 1;
      this.clinicAgreement.isApproved = null;
    }
    this.updateClinicalReqsFields();

    // Checking if any of the start and end times are in datetime format. If yes, Converting them before sending it to the API.
    this.clinicAgreement.clinicList.forEach(element => {
      if(Object.prototype.toString.call(element.startTime) === '[object Date]')
      {
      try{
        element.startTime = this._datePipe.transform(element.startTime, 'shortTime');
        }catch(e){
            console.log(e);        
        }
      }
      if(Object.prototype.toString.call(element.endTime) === '[object Date]')
      {
        try{
          element.endTime = this._datePipe.transform(element.endTime, 'shortTime');
          }catch(e){
              console.log(e);        
          }
      }
    });

    this._apiservice.saveAgreementData(SessionDetails.GetStoreId(), 1, this.clinicAgreement, is_override).subscribe((data: any) => {
      this.spinner.hide();
      switch (data.errorS.errorCode) {
        case null:
        case "null":
        case '0':
        case 0:
          this.showDialog(ErrorMessages['contract'], is_email ? ErrorMessages['contractEmailSent']: ErrorMessages['contractSaved'], true);
          break;
        case -4:
        case '-4':          
          if (is_email) {
            if(!this.clinicAgreement.isEmailSent) {
            this.clinicAgreement.isEmailSent = 0;
            }
            this.clinicAgreement.isApproved = null;
          }
          this.OverrideOrOkTitle = "Override";
          this.showCancelBtn = true;
          const msg = String.Format("{0}<br/><br/>{1}", data.errorS.errorMessage, ErrorMessages['eventDateTimeConflictErrText']);
          this.is_email = is_email;
          this.showConfirmDialogV2(ErrorMessages['eventDateTimeConflict'], msg);

          // this.confirmOverlap(ErrorMessages['eventDateTimeConflict'],
          //  String.Format("{0}<br/><br/>{1}",data.errorS.errorMessage,ErrorMessages['eventDateTimeConflictErrText']), is_email);
          //this.showDialog(ErrorMessages['resolve'], data.errorS.errorMessage, false);
          break;
        default:
          if (is_email) {
            this.clinicAgreement.isEmailSent = 0;
            this.clinicAgreement.isApproved = null;
          }
          this.showDialog(ErrorMessages['contract'], data.errorS.errorMessage, false);
          break;
      }

    },
      err => {
        this.spinner.hide();
        if (is_email) {
          this.clinicAgreement.isEmailSent = 0;
          this.clinicAgreement.isApproved = null;
        }
        switch (err) {
          case 500:
            this.showDialog(ErrorMessages['serverError'], err.json().Message, false);
            break;
            case 400:
            this.showDialog(ErrorMessages['clientError'], err.json().Message, false);
            break;
          default:
            this.showDialog(ErrorMessages['serverError'], err.json().Message, false);
            break;
        }
      
      });

  }

  showConfirmDialogV2(msgSummary: string, msgDetail: string) {
    this.confirmDialogSummary = msgSummary;
    this.confirmDialogMsg = msgDetail;
    this.showConfirmDialog = true;
  }

  confirmDialogOverrideAction(){
    this.showConfirmDialog = false;
    this.saveAgreementData(this.is_email, true);
    return true;
  }

  confirmDialogCancelAction(){
    this.showConfirmDialog = false;
  }
  
  sendEmail() {
    let emailList:string = '';
    if(this.regularContract.value.email != null){
      emailList = this.regularContract.value.email.replace(/ /g,"");
    }
    if (!this.utility.validateMultipleEmailsCommaSeparated(emailList)) {
      this.regularContract.controls['email'].setValidators([Validators.required]);
      this.regularContract.controls['email'].updateValueAndValidity();
      this.utility.validateAllFormFields(this.regularContract);
      let sumMsg = ErrorMessages['errMsg'];
      let sMsg = ErrorMessages['emailList'];
      this.showDialog(sumMsg, sMsg, false);
      return;
    }
    if(this.clinicAgreement.clinicList.length <=0){
      let sumMsg = ErrorMessages['errMsg'];
      let sMsg = ErrorMessages['clinicListEmpty'];
      this.showDialog(sumMsg, sMsg, false);
      return;
    }
    this.spinner.show();
    this.clinicAgreement.isEmailSent = 1;
    this.clinicAgreement.isApproved = null;
    this.clinicAgreement.businessUserEmails = emailList.trim();
    this.clinicAgreement.contractPostedByUser = 'Walgreens User'
    this.clinicAgreement.contactWagUser = '';
    this.makeValidBooleans();
    this.saveAgreementData(true, false);

    if(sessionStorage.getItem('Old_immunizationForm') !== null){
      sessionStorage.removeItem('Old_immunizationForm');
    }
  }
  makeValidBooleans(){
    this.clinicAgreement.clinicImmunizationList.forEach(im=>{
      if(Number(im.sendInvoiceTo)<=0 ){
        im.isTaxExempt= null;
        im.isCopay= null;
        im.isVoucherNeeded=null;
      } else {
        Number(im.isTaxExempt)>=1  ? im.isTaxExempt=1:im.isTaxExempt=0;
        Number(im.isCopay)>=1? im.isCopay=1:im.isCopay=0;
        Number(im.isVoucherNeeded)>=1? im.isVoucherNeeded=1:im.isVoucherNeeded=0;
        im.copayValue = Number(im.copayValue);
        if(im.voucherExpirationDate != null && im.voucherExpirationDate != undefined && im.voucherExpirationDate !=''){
          im.voucherExpirationDate = this._datePipe.transform(im.voucherExpirationDate,"MM/dd/yyyy");
        }
      }
    });
    this.clinicAgreement.clinicList.forEach(cl=>{
      cl.previousContact = 0;
      cl.previousLocation = 0;
      Number(cl.isNoClinic)>=1 ? cl.isNoClinic=1:cl.isNoClinic=0;
      Number(cl.isReassign)>=1? cl.isReassign=1:cl.isReassign=0;
      Number(cl.isCurrent) >=1? cl.isCurrent=1:cl.isCurrent=0;
     
      if( cl.clinicDate === null || cl.clinicDate === undefined || cl.clinicDate ===''){
          cl.clinicDate = null;
      } else {
        cl.clinicDate = this._datePipe.transform(cl.clinicDate,"MM/dd/yyyy");
      }
      let covidImmList = cl.clinicImzQtyList.filter(p=>p.immunizationName.includes("COVID19"));

      covidImmList.length>0 || this.isCovidVaccineAvailable ? cl.isCovidClinic = 1:cl.isCovidClinic=0;      
      if(cl.visitType==null|| (cl.visitType&&cl.visitType.length<0)){
        cl.visitType = null;
      }
    });
  }

  print(){
    this.utility.print("approveContract","Walgreens Community Off-site Agreement");
  }
  cancelAgreementClicked() {
    if(sessionStorage.getItem('Old_immunizationForm') !== null){
      sessionStorage.removeItem('Old_immunizationForm');
    }

    if(sessionStorage["AmendAgreement"] && sessionStorage["AmendAgreement"]=="true")
      this.router.navigate(['./communityoutreach/LocalClinicProgramDetails']);
    else
      this.router.navigate(['./communityoutreach/storehome']);
  }

  showDialog(msgSummary: string, msgDetail: string, success_message: boolean) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
    this.success_message = success_message;
  }
  okClicked() {
    this.display = false;
    if (this.success_message) {
      this.router.navigateByUrl('/communityoutreach/storehome');
    }    
  }

  languageChangeToSpanish() {
    
    this.enlanguageSelection = false;
    this.splanguageSelection = true;
    let data = this._apiservice.languageChange(0,this.isCovidVaccineAvailable,true);
    this.isCovidVaccineAvailable =   this.clinicAgreement.clinicImmunizationList.filter(p=>p.immunizationName.search(/COVID19/gi)>=0).length>0;
    if(data)
     {
      this.spanishLanguage = data.spanish;
      this.headerText = data.header;
      this.attachment = data.attachmentheader;
      this.imz = data.imzsp;
      this.payment = data.paymentsp;
      this.rates = data.ratessp;

      this.languageVariables.businessName = data.clientsp;
      this.languageVariables.name = data.namesp;
      this.languageVariables.eSignature =data.eSignatureSP;
      this.languageVariables.date = data.dateSP;
      this.languageVariables.dose = data.doseSP;
      this.languageVariables.district = data.districtSP;

      this.languageVariables.title = data.titlesp;
      this.languageVariables.attentionTo = data.attentionsp;
      this.languageVariables.address1 = data.address1sp;
      this.languageVariables.address2 = data.address2sp;
      this.languageVariables.city = data.citysp;
      this.languageVariables.state = data.statesp;
      this.languageVariables.zip = data.zipcodesp;
      //this.legal_notice = data.legalsp;
      //this.client_info = data.clientinfosp;
      this.languageVariables.approveAgrmnt = data.approveAgrmntSP;
      this.languageVariables.approveText = data.approveTextSP;
      this.languageVariables.electrSign = data.electrSignSP;
      this.languageVariables.notes = data.notesSP;
      this.languageVariables.pageTitle = data.pageTitleSP;

      this.languageVariables.inWitnessWhereOff= data.inWitnessWhereOffSP;
      this.languageVariables.inWitnessWhereOff2ndLine = data.inWitnessWhereOff2ndLineSP;
      this.languageVariables.sendLegalNoticeToClient = data.sendLegalNoticeToClientSP;
      this.languageVariables.sendLegalNoticeToWalgreens = data.sendLegalNoticeToWalgreensSP;
      this.languageVariables.wallGreensCommOffsiteTitle1 = data.wallGreensCommOffsiteTitle1SP;
      this.languageVariables.wallGreensCommOffsiteTitle2 = data.wallGreensCommOffsiteTitle2SP;
      this.languageVariables.wallGreensResponsibilities = data.wallGreensResponsibilitiesSP;
      this.languageVariables.wallGreensResponsibilitiesPara1Heading = data.wallGreensResponsibilitiesPara1HeadingSP;
      this.languageVariables.wallGreensResponsibilitiesCvrdServices = data.wallGreensResponsibilitiesCvrdServicesSP;
      this.languageVariables.wallGreensResponsibilitiesCvrdServices = data.wallGreensResponsibilitiesCvrdServicesSP;
      this.languageVariables.profJudgementHeading = data.profJudgementHeadingSP;
      this.languageVariables.profJudgementText = data.profJudgementTextSP;
      this.languageVariables.provHealthCrProfHdng = data.provHealthCrProfHdngSP;
      this.languageVariables.provHealthCrProfText = data.provHealthCrProfTextSP;
      this.languageVariables.clientsResponsibilites = data.clientsResponsibilitesSP;
      this.languageVariables.vouchersHeading = data.vouchersHeadingSP;
      this.languageVariables.vouchersText = data.vouchersTextSP;
      this.languageVariables.offSiteLocation = data.offSiteLocationSP;
      this.languageVariables.eligibleParticipants = data.eligibleParticipantsSP;
      this.languageVariables.important = data.importantSP;
      if(this.checkIfNonFluImmIsSelected()){
        this.languageVariables.offSiteLocationText1 = String.Format(data.offSiteLocationText1SP, data.contractYearText1SP);      
      } else {
        this.languageVariables.offSiteLocationText1 = String.Format(data.offSiteLocationText1SP, data.influenzaSeasonText1SP);  
      }
      this.languageVariables.eligibleParticipantsText1 = data.eligibleParticipantsTextSP;
      this.languageVariables.siteMin = data.siteMinSP;
      this.languageVariables.siteAvg = data.siteAvgSP;
      if(this.checkIfNonFluImmIsSelected()){
        this.languageVariables.siteMinText = String.Format(data.siteMinTextSP,data.contractYearText2SP, data.contractYearText2SP);
      } else {
        this.languageVariables.siteMinText = String.Format(data.siteMinTextfluSP,data.influenzaSeasonText2SP, data.influenzaSeasonText2SP);  
      }
      this.languageVariables.siteAvgText = data.siteAvgTextSP;
      this.languageVariables.pmtAndBIlling = data.pmtAndBIllingSP;
      this.languageVariables.paymentHd =data.paymentSP;
      this.languageVariables.paymentText = data.paymentTextSP;
      this.languageVariables.privateClinicFees = data.privateClinicFeesSP;
      this.languageVariables.privateClinicFeesText = data.privateClinicFeesTextSP;
      this.languageVariables.latePayment = data.latePaymentSP;
      this.languageVariables.latePaymentText = data.latePaymentTextSP;
      this.languageVariables.termAndTermCaps = data.termAndTermCapsSP;
      this.languageVariables.termAndTerm = data.termAndTermSP;
      this.languageVariables.termAndTermText = data.termAndTermTextSP;
      this.languageVariables.effectAndTerminiation = data.effectAndTerminiationSP;
      this.languageVariables.effectAndTerminiationText = data.effectAndTerminiationTextSP;
      this.languageVariables.waiver = data.waiverSP;
      this.languageVariables.waiverText = data.waiverTextSP;
      this.languageVariables.insurAndIndemnify = data.insurAndIndemnifySP;
      this.languageVariables.insurance = data.insuranceSP;
      this.languageVariables.insuranceText = data.insuranceTextSP;
      this.languageVariables.indemnification = data.indemnificationSP;
      let indemnificationTAndCChangedDate = new Date(data.indemnificationTAndCChangedDate);
      let universalContractReleasedate = data.universalContractReleaseDate?new Date(data.universalContractReleaseDate):null;
      let dateApproved = null;
      if(this.clinicAgreement && this.clinicAgreement.dateApproved){
        dateApproved = new Date(this.clinicAgreement.dateApproved);
     }
     if(dateApproved === null || dateApproved>=indemnificationTAndCChangedDate ){
      this.languageVariables.indemnificationText = data.indemnificationTextSP;
     }
     else {
      this.languageVariables.indemnificationText = data.indemnificationTextSP;
     }
      this.languageVariables.generalTerms = data.generalTermsSP;
      this.languageVariables.businessConfidentiality = data.businessConfidentialitySP;
      this.languageVariables.businessConfidentialityText = data.businessConfidentialityTextSP;
      this.languageVariables.confidentiality = data.confidentialitySP;
      this.languageVariables.confidentialityP1 = data.confidentialityP1SP;
      this.languageVariables.phi = data.phiSP;
      this.languageVariables.phiAb = data.phiAbSP;
      this.languageVariables.confidentialityP2 = data.confidentialityP2SP;
      this.languageVariables.confidentialityUniversalText = data.confidentialityTextSP;

      this.languageVariables.Advertising = data.AdvertisingSP;
      this.languageVariables.AdvertisingText = data.AdvertisingTextSP;
      this.languageVariables. frcMaj = data.frcMajSP;
      this.languageVariables.frcMajText = data.frcMajTextSP;
      this.languageVariables.compliance = data.complianceSP;
      this.languageVariables.complianceText = data.complianceTextSP;
      this.languageVariables.assignment = data.assignmentSP;
      this.languageVariables.assignmentText = data.assignmentTextSP;
      this.languageVariables.notices = data.noticesSP;
      this.languageVariables.noticesText = data.noticesTextSP;
      this.languageVariables.entireAgr = data.entireAgrSP;
      this.languageVariables.entireAgrText = data.entireAgrTextSP;
      this.languageVariables.counterPart = data.counterPartsHeadingSP;
      this.languageVariables.counterPartsText = data.counterPartsTextSP;
      this.languageVariables.rights = data.rightsSP;
      this.languageVariables.approve = data.approveSP;
      this.languageVariables.reject = data.rejectSP;
      this.languageVariables.submit= data.submitSP;
      this.languageVariables.printAgreementTxt = data.printAgreementTxtCapsSP;

      this.languageVariables.clinicLocation= data.clinicLocationSP;
      this.languageVariables.clinicDate = data.clinicDateSP;
      this.languageVariables.clinicTime = data.clinicTimeSP;
      this.languageVariables.contact= data.contactSP;
      this.languageVariables.phone = data.phoneSP;
      this.languageVariables.estShots = data.estShotsSP;
      this.languageVariables.location = data.locationSP;
      this.languageVariables.time = data.timeSP;
      this.languageVariables.email = data.emailSP;
      this.languageVariables.emailAgrmntTo= data.emailAgreementToSP;
      this.languageVariables.multipleEmailAddrss = data.multipleEmailAddressSP;
      this.languageVariables.sendAgreeMnt = data.sendAgreementSP;
      this.languageVariables.editAgreeMnt = data.editAgreementSP;
      this.languageVariables.saveAgreeMnt = data.saveAgreementSP;
      this.languageVariables.cancelTxt = data.cancelSP;
      this.languageVariables.noteTxt = data.noteTextSP;
      this.languageVariables.table1 = data.table1SP;
      this.fluExpiryDate= data.fluExpiryDateSP;
      this.routineExpiryDate= data.routineExpiryDateSP;      
      this.sendInvoiceTo= data.sendInvoiceToSP;
      this.isTaxExempt = data.isTaxExemptSP;
      this.isCopay= data.isCopaySP;
      this.voucherNeeded = data.voucherNeededSP;
      this.voucherExpiry = data.voucherExpirySP;
      this.copayValue = data.copayValueSP;
      this.languageVariables.contactName = data.contactNameSP;
      this.languageVariables.contactPhone = data.contactPhoneSP;
      this.languageVariables.contactEmail = data.contactEmailSP;
    }
  }
  languageChangeToEnglish() {
    this.splanguageSelection = false;
    this.enlanguageSelection = true;
    this.isCovidVaccineAvailable =   this.clinicAgreement.clinicImmunizationList.filter(p=>p.immunizationName.search(/COVID19/gi)>=0).length>0;
    let data = this._apiservice.languageChange(0,this.isCovidVaccineAvailable,true);
    if(data){
      this.englishLanguage = data.english;
      this.enheader = data.englishheader;
      this.enattachment = data.englishattachment;
      this.imz = data.imzen;
      this.payment = data.payen;
      this.rates = data.ratesen;


      this.languageVariables.businessName = data.clienten;
      this.languageVariables.name = data.nameen;
      this.languageVariables.eSignature = data.eSignatureEN;
      this.languageVariables.date = data.dateEN;
      this.languageVariables.dose = data.doseEN;
      this.languageVariables.district = data.districtEN;

      this.languageVariables.title = data.titleen;
      this.languageVariables.attentionTo = data.attentionen;
      this.languageVariables.address1 = data.address1en;
      this.languageVariables.address2 = data.address2en;
      this.languageVariables.city = data.cityen;
      this.languageVariables.state = data.stateen;
      this.languageVariables.zip = data.zipcodeen;
      //this.legal_notice = data.legalsp;
      //this.client_info = data.clientinfosp;
      this.languageVariables.approveAgrmnt = data.approveAgrmntEN;
      this.languageVariables.approveText = data.approveTextEN;
      this.languageVariables.electrSign = data.electrSignEN;
      this.languageVariables.notes = data.notesEN;
      this.languageVariables.pageTitle = data.pageTitleEN;

      this.languageVariables.inWitnessWhereOff= data.inWitnessWhereOffEN;
      this.languageVariables.inWitnessWhereOff2ndLine = data.inWitnessWhereOff2ndLineEN;
      this.languageVariables.sendLegalNoticeToClient = data.sendLegalNoticeToClientEN;
      this.languageVariables.sendLegalNoticeToWalgreens = data.sendLegalNoticeToWalgreensEN;
      this.languageVariables.wallGreensCommOffsiteTitle1 = data.wallGreensCommOffsiteTitle1EN
      this.languageVariables.wallGreensCommOffsiteTitle2 = data.wallGreensCommOffsiteTitle2EN;
      this.languageVariables.wallGreensResponsibilities = data.wallGreensResponsibilitiesEN;
      this.languageVariables.wallGreensResponsibilitiesPara1Heading = data.wallGreensResponsibilitiesPara1HeadingEN;
      this.languageVariables.wallGreensResponsibilitiesCvrdServices = data.wallGreensResponsibilitiesCvrdServicesEN;
      this.languageVariables.wallGreensResponsibilitiesCvrdServices = data.wallGreensResponsibilitiesCvrdServicesEN;
      this.languageVariables.profJudgementHeading = data.profJudgementHeadingEN;
      this.languageVariables.profJudgementText = data.profJudgementTextEN;
      this.languageVariables.provHealthCrProfHdng = data.provHealthCrProfHdngEN;
      this.languageVariables.provHealthCrProfText = data.provHealthCrProfTextEN;
      this.languageVariables.clientsResponsibilites = data.clientsResponsibilitesEN;
      this.languageVariables.vouchersHeading = data.vouchersHeadingEN;
      this.languageVariables.vouchersText = data.vouchersTextEN;
      this.languageVariables.offSiteLocation = data.offSiteLocationEN;
      this.languageVariables.eligibleParticipants = data.eligibleParticipantsEN;
      this.languageVariables.important = data.importantEN;
      if(this.checkIfNonFluImmIsSelected()){
        this.languageVariables.offSiteLocationText1 = String.Format(data.offSiteLocationText1EN, data.contractYearText1EN);      
      } else {
        this.languageVariables.offSiteLocationText1 = String.Format(data.offSiteLocationText1EN, data.influenzaSeasonText1EN);  
      }
      this.languageVariables.eligibleParticipantsText1 = data.eligibleParticipantsTextEN;
      this.languageVariables.siteMin = data.siteMinEN;
      this.languageVariables.siteAvg = data.siteAvgEN;
      if(this.checkIfNonFluImmIsSelected()){
        this.languageVariables.siteMinText = String.Format(data.siteMinTextEN,data.contractYearText2EN, data.contractYearText2EN);
      } else {
        this.languageVariables.siteMinText = String.Format(data.siteMinTextfluEN,data.influenzaSeasonText2EN, data.influenzaSeasonText2EN);  
      }
      this.languageVariables.siteAvgText = data.siteAvgTextEN;
      this.languageVariables.pmtAndBIlling = data.pmtAndBIllingEN;
      this.languageVariables.paymentHd =data.paymentEN;
      this.languageVariables.paymentHd =data.paymentEN;
      this.languageVariables.singleStarTAndC = data.singleStarTAndC;
      this.languageVariables.article2Text = data.article2Text;
      if(this.checkIfCovidImmForCorpInvoiceSelected()){
        this.enheader = this.showPreUniversalContract?data.directBillEnglishheader:data.englishheader;
        this.languageVariables.paymentText = !this.showPreUniversalContract?data.paymentTextEN:data.paymentTextDirectBillEN;
      }
      else {
        this.enheader = data.englishheader;
      this.languageVariables.paymentText = data.paymentTextEN;
      }
      this.languageVariables.privateClinicFees = data.privateClinicFeesEN;
      this.languageVariables.privateClinicFeesText = data.privateClinicFeesTextEN;
      this.languageVariables.latePayment = data.latePaymentEN;
      this.languageVariables.latePaymentText = data.latePaymentTextEN;
      this.languageVariables.termAndTermCaps = data.termAndTermCapsEN;
      this.languageVariables.termAndTerm = data.termAndTermEN;
      this.languageVariables.termAndTermText = data.termAndTermTextEN;
      this.languageVariables.effectAndTerminiation = data.effectAndTerminiationEN;
      this.languageVariables.effectAndTerminiationText = data.effectAndTerminiationTextEN;
      this.languageVariables.waiver = data.waiverEN;
      this.languageVariables.waiverText = data.waiverTextEN;
      this.languageVariables.insurAndIndemnify = data.insurAndIndemnifyEN;
      this.languageVariables.insurance = data.insuranceEN;
      this.languageVariables.insuranceText = data.insuranceTextEN;
      this.languageVariables.indemnification = data.indemnificationEN;
      let indemnificationTAndCChangedDate = new Date(data.indemnificationTAndCChangedDate);
      let dateApproved = null;
      if(this.clinicAgreement && this.clinicAgreement.dateApproved){
        dateApproved = new Date(this.clinicAgreement.dateApproved);
     }     
     if(this.isCovidVaccineAvailable== true && (dateApproved === null || dateApproved>=indemnificationTAndCChangedDate )){
      this.languageVariables.indemnificationText = data.indemnificationTextEN;
     }
     else {
      this.languageVariables.indemnificationText = data.indemnificationTextEN;
     }
      this.languageVariables.generalTerms = data.generalTermsEN;
      this.languageVariables.businessConfidentiality = data.businessConfidentialityEN;
      this.languageVariables.businessConfidentialityText = data.businessConfidentialityTextEN;
      this.languageVariables.confidentiality = data.confidentialityEN;
      this.languageVariables.confidentialityP1 = data.confidentialityP1EN;
      this.languageVariables.phi = data.phiEN;
      this.languageVariables.phiAb = data.phiAbEN;
      this.languageVariables.confidentialityP2 = data.confidentialityP2EN;
      this.languageVariables.confidentialityUniversalText = data.confidentialityTextEN;
      this.languageVariables.Advertising = data.AdvertisingEN;
      this.languageVariables.AdvertisingText = data.AdvertisingTextEN;
      this.languageVariables.frcMaj = data.frcMajEN;
      this.languageVariables.frcMajText = data.frcMajTextEN;
      this.languageVariables.compliance = data.complianceEN;
      this.languageVariables.complianceText = data.complianceTextEN;
      this.languageVariables.assignment = data.assignmentEN;
      this.languageVariables.assignmentText = data.assignmentTextEN;
      this.languageVariables.notices = data.noticesEN;
      this.languageVariables.noticesText = data.noticesTextEN;
      this.languageVariables.entireAgr = data.entireAgrEN;
      this.languageVariables.entireAgrText = data.entireAgrTextEN;
      this.languageVariables.counterPart = data.counterPartsHeadingEN;
      this.languageVariables.counterPartsText = data.counterPartsTextEN;
      this.languageVariables.rights = data.rightsEN;

      this.languageVariables.approve = data.approveEN;
      this.languageVariables.reject = data.rejectEN;
      this.languageVariables.submit= data.submitEN;
      this.languageVariables.printAgreementTxt = data.printAgreementTxtCapsEN;

      this.languageVariables.clinicLocation= data.clinicLocationEN;
      this.languageVariables.clinicDate = data.clinicDateEN;
      this.languageVariables.clinicTime = data.clinicTimeEN;
      this.languageVariables.contact= data.contactEN;
      this.languageVariables.phone = data.phoneEN;
      this.languageVariables.estShots = data.estShotsEN;
      this.languageVariables.location = data.locationEN;
      this.languageVariables.time = data.timeEN;
      this.languageVariables.email = data.emailEN;
      this.languageVariables.emailAgrmntTo= data.emailAgreementToEN;
      this.languageVariables.multipleEmailAddrss = data.multipleEmailAddressEN;
      this.languageVariables.sendAgreeMnt = data.sendAgreementEN;
      this.languageVariables.editAgreeMnt = data.editAgreementEN;
      this.languageVariables.saveAgreeMnt = data.saveAgreementEN;
      this.languageVariables.cancelTxt = data.cancelEN;
      this.languageVariables.noteTxt = data.noteTextEN;
      this.languageVariables.table1 = data.table1EN;
      this.fluExpiryDate= data.fluExpiryDateEN;
      this.routineExpiryDate= data.routineExpiryDateEN;
      this.languageVariables.estShots = data.estShotsEN;
      this.sendInvoiceTo= data.sendInvoiceToEN;
      this.isTaxExempt = data.isTaxExemptEN;
      this.isCopay= data.isCopayEN;
      this.voucherNeeded = data.voucherNeededEN;
      this.voucherExpiry = data.voucherExpiryEN;
      this.copayValue = data.copayValueEN;
      this.languageVariables.contactName = data.contactName;
      this.languageVariables.contactPhone = data.contactPhone;
      this.languageVariables.contactEmail = data.contactEmail;
    }

    this.language = "TRANSLATE TO ENGLISH";
  }

changeLanguageToSpanish(){
    this.enlanguageSelection = false;
    this.splanguageSelection = true;
    this.languageChangeToSpanish();
   }

   changeLanguageToEnglish(){
    this.enlanguageSelection = true;
    this.splanguageSelection = false;
    this.languageChangeToEnglish();
   }

  
  checkIfAnyImmunizationsHasCorpInvoiceAndVoucherNeeded(location: Clinic): boolean {
    return this._apiservice.checkIfAnyImmunizationsHasCorpInvoiceAndVoucherNeeded(location) && Number(location.isNoClinic) >=1;
  }
  checkIfFluImmForCorpInvoiceSelected():boolean{
    return this._apiservice.checkIfFluImmForCorpInvoiceSelected();
  }
  checkIfNonFluImmForCorpInvoiceSelected():boolean{
    return this._apiservice.checkIfNonFluImmForCorpInvoiceSelected();
  }
  checkIfCovidImmForCorpInvoiceSelected():boolean{
    return this._apiservice.checkIfCovidImmForCorpInvoiceSelected();
  }
  checkIfNonFluImmIsSelected():boolean{
    return this._apiservice.checkIfNonFluImmIsSelected();
  }
  getVoucherExpiryDate(clinicLoc:Clinic):string{
    let vchrDate:Date;
    this.userRecords.forEach(imz=>{
      if(Number(imz.isVoucherNeeded) >=1 && Number(imz.paymentTypeId) == 6 &&
       (imz.sendInvoiceTo === '1' || Number(imz.sendInvoiceTo) === 1) && imz.immunizationName.toLowerCase().search('flu') !== -1)
      vchrDate=new Date(imz.voucherExpirationDate);
      return vchrDate;
    });
    return this._datePipe.transform(vchrDate,'MM/dd/yyyy');
  }
  getRoutineExpiryDate(clinicLoc:Clinic):string{
    let vchrDate:Date;
    this.userRecords.forEach(imz=>{
      if(Number(imz.isVoucherNeeded) >=1 && Number(imz.paymentTypeId) == 6 &&
       (imz.sendInvoiceTo === '1' || Number(imz.sendInvoiceTo) === 1) && imz.immunizationName.toLowerCase().search('flu') == -1)
      vchrDate=new Date(imz.voucherExpirationDate);
      return vchrDate;
    });
    return this._datePipe.transform(vchrDate,'MM/dd/yyyy');
  }
  getCovidExpiryDate():string{
    let vchrDate:Date;
    this.userRecords.forEach(imz=>{
      if(Number(imz.isVoucherNeeded) >=1 && Number(imz.paymentTypeId) == 6 &&
       (imz.sendInvoiceTo === '1' || Number(imz.sendInvoiceTo) === 1) && imz.immunizationName.toLowerCase().search('flu') == -1 && imz.immunizationName.toLowerCase().search('covid') !== -1)
      vchrDate=new Date(imz.voucherExpirationDate);
      return vchrDate;
    });
    return this._datePipe.transform(vchrDate,'MM/dd/yyyy');
  }
  isFieldValid(field: string) {
    return !this.regularContract.get(field).valid && this.regularContract.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  enableContractEditStatus(){
    if(this.clinicAgreement != null && this.clinicAgreement != undefined ) {
    if(this.clinicAgreement.isEmailSent != null &&
      (  this.clinicAgreement.isEmailSent == 1 ) && 
      ( this.clinicAgreement.isApproved == null || this.clinicAgreement.isApproved == 1  )){
        return false;
      }
    }
      return true;
  }
  showHideSaveBtnStatus(){    
    if(this.clinicAgreement != null && this.clinicAgreement != undefined ) {
    if(this.clinicAgreement.isEmailSent != null &&
      (  this.clinicAgreement.isEmailSent == 1 ) && 
      ( this.clinicAgreement.isApproved == null || this.clinicAgreement.isApproved == 1 || this.clinicAgreement.isApproved == 0  )){
        return false;
      }
    }
    return true;
  }

  confirmOverlap(hdr: string, msg: string, is_email: boolean) {
    
    this.confirmationService.confirm({
      message: msg,
      header: hdr,
      accept: () => {
        this.saveAgreementData(is_email, true);
        return true;
      },
      reject: () => {
        return false;
      }
    });
  }
  amendOkClicked()
  {
    this.displayAmend=false;
  }
  checkToPrintAsterisk(immAbvName:string,immName:string):boolean{
    let bRet:boolean =false;
    if(immAbvName&&immAbvName.search(/Two Dose/gi)>=0){
      bRet= true;
    }
    return bRet;
  }
  checkForDoubleAsteriskRates():boolean{    
    return this.clinicAgreement.clinicImmunizationList.filter(p=>p.immunizationName.search(/Dose/gi)>=0).length>0;
  }
}
