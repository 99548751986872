// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select {
     height: 39px;
    padding: 0px;
   
}
td.col1 {
    width: 1300px;
}

.blueBg {
    background: #2a7db5 ;
    border: 1px solid white;
  }
  text-white-gray {
    color: rgba(255, 255, 255, 0.8) !important;
}

.disabled {
  opacity: 0.65;
  cursor: default !important;
  pointer-events: none;
}

p {
    font-family: Lato;
  }

  .has-error label{
    color:red!important;
    }
    .has-error input{
    border:1px solid red !important;
    } 
    .has-error select{
    border:1px solid red !important;
    } `, "",{"version":3,"sources":["webpack://./src/app/modules/admin/components/user-profile/user-profile.component.css"],"names":[],"mappings":"AAAA;KACK,YAAY;IACb,YAAY;;AAEhB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,oBAAoB;IACpB,uBAAuB;EACzB;EACA;IACE,0CAA0C;AAC9C;;AAEA;EACE,aAAa;EACb,0BAA0B;EAC1B,oBAAoB;AACtB;;AAEA;IACI,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;IACnB;IACA;IACA,+BAA+B;IAC/B;IACA;IACA,+BAA+B;IAC/B","sourcesContent":["select {\r\n     height: 39px;\r\n    padding: 0px;\r\n   \r\n}\r\ntd.col1 {\r\n    width: 1300px;\r\n}\r\n\r\n.blueBg {\r\n    background: #2a7db5 ;\r\n    border: 1px solid white;\r\n  }\r\n  text-white-gray {\r\n    color: rgba(255, 255, 255, 0.8) !important;\r\n}\r\n\r\n.disabled {\r\n  opacity: 0.65;\r\n  cursor: default !important;\r\n  pointer-events: none;\r\n}\r\n\r\np {\r\n    font-family: Lato;\r\n  }\r\n\r\n  .has-error label{\r\n    color:red!important;\r\n    }\r\n    .has-error input{\r\n    border:1px solid red !important;\r\n    } \r\n    .has-error select{\r\n    border:1px solid red !important;\r\n    } "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
