// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-border-tbl td,
  .no-border-tbl th {
    border-top: none !important;
  }

  .no-border-tbl td {
    vertical-align: top !important;
  }
  .black-font, .font-weight-bold, b{
      color:#000000 !important; 
  }

  .service-agreement-chbx{
    padding-left: 42px !important;
    padding-bottom: 20px;
  }

  .service-agreement-chbx > span {
    padding-left: 10px;
    display: flex;
  }
`, "",{"version":3,"sources":["webpack://./src/app/modules/contractaggreement/components/contract-agreement-2023/agreement.component-2023.css"],"names":[],"mappings":"AAAA;;IAEI,2BAA2B;EAC7B;;EAEA;IACE,8BAA8B;EAChC;EACA;MACI,wBAAwB;EAC5B;;EAEA;IACE,6BAA6B;IAC7B,oBAAoB;EACtB;;EAEA;IACE,kBAAkB;IAClB,aAAa;EACf","sourcesContent":[".no-border-tbl td,\r\n  .no-border-tbl th {\r\n    border-top: none !important;\r\n  }\r\n\r\n  .no-border-tbl td {\r\n    vertical-align: top !important;\r\n  }\r\n  .black-font, .font-weight-bold, b{\r\n      color:#000000 !important; \r\n  }\r\n\r\n  .service-agreement-chbx{\r\n    padding-left: 42px !important;\r\n    padding-bottom: 20px;\r\n  }\r\n\r\n  .service-agreement-chbx > span {\r\n    padding-left: 10px;\r\n    display: flex;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
