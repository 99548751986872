import { Component, OnInit } from '@angular/core';
import { defaultSiteDesignInfo } from '../../../../JSON/schedulerDefaultValues';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { LocationStrategy } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { SessionDetails } from '../../../../utility/session';
import { SchedulerregistrationService } from '../../../admin/services/schedulerregistration.service';
import { ErrorMessages } from "../../../../config-files/error-messages";

@Component({
  selector: 'scheduledApptsRepForReminderEmail',
  templateUrl: './scheduledApptsRepForReminderEmail.component.html',
  styleUrls: ['./scheduledApptsRepForReminderEmail.component.css']
})
export class ScheduledApptsRepForReminderEmailComponent implements OnInit {
  showMe: boolean = false;
  corpDesignInfo: any;
  corporateTrackInfo:any;
  clientLogo: any;
  BannerColor: string = '';
  BannerTextColor: string = '';
  ButtonBgColor: string = '';
  ButtonTextcolor: string = '';
  ctrlKeyDown = false;
  clientInfo: any;
  clinicEventDetails: any;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  loading: boolean;
  constructor(private messageService: MessageServiceService, private location: LocationStrategy, private scheduler_regisration: SchedulerregistrationService) {
    this.location.onPopState(() => {
      this.messageService.setNavigationClicked(true);
      return false;
    });
    this.clinicEventDetails = SessionDetails.GetEventDetails();

    if (sessionStorage["corporateTrackDesignInfo"]) {
      this.corpDesignInfo = JSON.parse(sessionStorage["corporateTrackDesignInfo"]);
      sessionStorage.removeItem("corporateTrackDesignInfo");
    }

    if (this.clinicEventDetails && Object.keys(this.clinicEventDetails).length > 0) {
      sessionStorage.removeItem('event_details');
    }

    document.addEventListener('keyup', () => {
      this.ctrlKeyDown = false;
    });
    document.addEventListener('keydown', (e) => {
      if ((e.which || e.keyCode) == 116 || ((e.which || e.keyCode) == 82 && this.ctrlKeyDown)) {
        // Pressing F5 or Ctrl+R
        //e.preventDefault();
        if (this.clientInfo) {
          sessionStorage["corporateTrackInfo"] = JSON.stringify(this.clientInfo);
        }
        if (this.corpDesignInfo) {
          sessionStorage["corporateTrackDesignInfo"] = JSON.stringify(this.corpDesignInfo);
        }
        if (this.clinicEventDetails) {
          sessionStorage["event_details"] = JSON.stringify(this.clinicEventDetails);
        }
      } else if ((e.which || e.keyCode) == 17) {
        // Pressing  only Ctrl
        this.ctrlKeyDown = true;
      }
    });
    window.onbeforeunload = (ev) => {
      if (this.clientInfo) {
        sessionStorage["corporateTrackInfo"] = JSON.stringify(this.clientInfo);
      }
      if (this.corpDesignInfo) {
        sessionStorage["corporateTrackDesignInfo"] = JSON.stringify(this.corpDesignInfo);
      }
      if (this.clinicEventDetails) {
        sessionStorage["event_details"] = JSON.stringify(this.clinicEventDetails);
      }
    }
  }

  ngOnInit() {
    let my_int_timer;
    this.clientInfo = {
      "designPk": -1,
      "redirectTo": "schedulerRegistration",
      "apptPk": -1,
      "clinicPk": -1
    };
    sessionStorage["corporateTrackInfo"] = JSON.stringify(this.clientInfo);
    this.scheduler_regisration.getSchedulerSiteDesign(this.clientInfo.designPk).subscribe((result:any) => {
      if (Object.keys(result).length > 0) {
        if (!result.clientInfo.enryptedClientKey || this.clinicEventDetails.clinicPk > 0) {
          result.siteLogoAndStyles = defaultSiteDesignInfo.defaultDesignValues;
          result.siteLandingPageText = defaultSiteDesignInfo.defaultTextValues;
        }
        this.corpDesignInfo = result;
        this.BannerColor = this.corpDesignInfo.siteLogoAndStyles.bannerColor;
        this.clientLogo = ((this.corpDesignInfo.siteLogoAndStyles && this.corpDesignInfo.siteLogoAndStyles.logoFile &&
          this.corpDesignInfo.siteLogoAndStyles.logoFile != "string") ? environment.RESOURCES.CORPORATE_SCHEDULER_UPLOADED_LOGOS_URL + this.corpDesignInfo.siteLogoAndStyles.logoFile : '');

        if (this.clinicEventDetails.clinicPk) {
          this.clientLogo = "assets/images/Walgreens_Logo_2022.png";
        }
        this.BannerTextColor = this.corpDesignInfo.siteLogoAndStyles.bannerTextColor;
        this.ButtonBgColor = this.corpDesignInfo.siteLogoAndStyles.buttonColor;
        this.ButtonTextcolor = this.corpDesignInfo.siteLogoAndStyles.buttonTextcolor;
        this.showMe = true;
      }
    } ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}

