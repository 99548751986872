export const STEPS = {
    immunization: 'immunization',
    location: 'location',
    witness: 'witness',
    review: 'review'
};

export const COSTEPS = {
    immunization: 'immunization',
    location: 'location'
};

