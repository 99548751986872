import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalErrorHandler } from './GlobalErrorHandler';
//import { LoggerService } from './services/LoggerService';
import { StoreOpportunityComponent } from './components/store-opportunity/store-opportunity.component';
//import { AgmCoreModule } from '@agm/core';
//import { GMapsService } from './services/gmaps-service.service';

import {ReplaceTextPipe} from '../../../app/utility/replace-text.pipe'

@NgModule({
  imports: [
    CommonModule,
    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   apiKey: ''
    // })    
  ],
  declarations: [],
    providers: [{provide: ErrorHandler, useClass: GlobalErrorHandler},ReplaceTextPipe],
})
export class CommonComponentsModule { }
