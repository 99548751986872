import { Injectable } from '@angular/core';
import { Util } from '../../../utility/util';
import { environment } from '../../../../environments/environment';
import { SessionDetails } from '../../../utility/session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PcacontractAgreementService {
  proofInsurances=null;
  constructor(private _http: HttpClient) { }
  getPCAContractAgreement(agreement_pk, user_type) {
    //var url = environment.API_URL + environment.RESOURCES.GET_PCA_CONTRACTAGREEMENT + "?clinic_agreement_pk=" + agreement_pk+"/" +"user_type="+user_type+"";
    var url = environment.API_URL + environment.RESOURCES.GET_PCA_CONTRACTAGREEMENT + "/" + agreement_pk + "/" + user_type + "";
    return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  saveAgreementData(store_id, clinic_type, is_pca, clinic_agreement) {
    let url = environment.API_URL + environment.RESOURCES.UPDATE_CONTRACT_AGREEMENT + "/" + store_id + "/" + clinic_type;
    return this._http.post(url, clinic_agreement, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  approveAgreementData(store_id, clinic_type, is_pca, clinic_agreement) {
    let url = environment.API_URL + environment.RESOURCES.APPROVE_PCA_CONTRACT_AGREEMENT + "/" + store_id + "/" + clinic_type;
    return this._http.post(url, clinic_agreement, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  updateContractForTestingPartner(store_id, clinic_type, clinic_agreement) {
    let url = environment.API_URL + environment.RESOURCES.UPDATE_CONTRACT_AGREEMNET_TESTING_PARTNER + "/" + store_id + "/" + clinic_type;
    return this._http.post(url, clinic_agreement, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  // UploadProofOfInsurance()
  // {
  //   let url = environment.API_URL + environment.RESOURCES.UPDATE_CONTRACT_AGREEMENT + "/" + store_id + "/" + clinic_type +"/"+is_pca;
  //   return this._http.post(url, clinic_agreement,Util.getRequestHeaders()).map((response: Response) => response.json());
  // }

  UploadProofOfInsurance(clinic_agreement_pk, upload_image) {
    let headers: Headers = new Headers();
    let url = environment.API_URL + environment.RESOURCES.UPLOAD_PROOF_OF_INSURANCE + "/" + clinic_agreement_pk;
    let userData = SessionDetails.GetUserInfo();
    let token = btoa(userData.userName + ':' + userData.password);

    let req_headers = new HttpHeaders()
    .set('Authorization','Basic '+ token);
    
    const httpOptions = {
      headers: req_headers
    };
  // files = null;
  return this._http.post(url, upload_image, httpOptions).pipe(catchError(Util.handleErrorV2));
}
GetProofOfInsurance(clinic_agreement_pk) {
  let url = environment.API_URL + environment.RESOURCES.GET_PROOF_OF_INSURANCE + "/" + clinic_agreement_pk ;
  return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}
DeleteProofOfInsurance(pk) {
  let url = environment.API_URL + environment.RESOURCES.DELETE_PROOF_OF_INSURANCE + "/" + pk ;
  return this._http.delete(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}
setProofInsuranceDocumentList(value){
  this.proofInsurances = value;
}
getProofInsuranceDocumentList(){
  return this.proofInsurances ;
}
}
