export class MinimumShotExceptions {
    fromDate:string;
    toDate:string;
    isCurrentSeason: boolean;
    includeDates:boolean;
    reportName: string = "";
    outputType: string = "";
    covidOnly: boolean;
    constructor(from_date: string, to_date: string, iscurrent_season: boolean, include_date_range: boolean, report_name: string, output_type: string, covid_only:boolean) {
        this.fromDate = from_date;
        this.toDate=to_date;
        this.isCurrentSeason = iscurrent_season;
        this.includeDates = include_date_range;
        this.reportName=report_name;
        this.outputType = output_type;
        this.covidOnly = covid_only;
    }
}