import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ImmunizationCheckService } from '../../services/immunization-check.service';
import { ImmunizationCheck } from '../../../../models/Immunization-check';
import { SessionDetails } from '../../../../utility/session';
import { Router } from '@angular/router';

@Component({
  selector: 'app-immunization-check',
  templateUrl: './immunization-check.component.html',
  styleUrls: ['./immunization-check.component.css'],
  providers: [ImmunizationCheckService]
})
export class ImmunizationCheckComponent implements OnInit {
  cols: any[];
  immunizationChecks: ImmunizationCheck[] = [];
  totalData: ImmunizationCheck[] = [];
  test: string;
  recordsExist: boolean = false;
  // @ViewChild('tblImmunizationChecks') dataTableUser:DataTable;
  constructor(private spinner: NgxSpinnerService, private immunizationCheckService: ImmunizationCheckService,
    private router: Router) {

  }

  ngOnInit() {
    this.getImmunizationCheckDetails();
    this.cols = [
      { field: 'immunizationName', header: 'Immunization Name' },
      { field: 'price', header: 'Price' },
      { field: 'directBillPrice', header: 'Direct Bill Price' },
      { field: 'directBillVoucherPrice', header: 'Direct Bill Voucher Price' },
      { field: 'clinicTypeName', header: 'Clinic Type' },
      //{ field: 'isCurrentSeason', header: 'Current Season' },
      { field: 'isActive', header: 'Active' }
    ]
  }

  getImmunizationCheckDetails() {
    this.immunizationCheckService.getAllImmunizationChecks().subscribe((res:any) => {
      this.totalData = res;
      if (res && res.length > 0) {
        this.immunizationChecks = this.totalData.filter(p => p.isActive);
        this.recordsExist = true;
      }
      else {
        this.immunizationChecks = [];
        this.recordsExist = false;
      }
      this.spinner.hide();
      console.log(this.recordsExist);
    },
      error => {
        this.spinner.hide();
        console.log(error);
      })
  }

  getImmCheckCls(value: string) {
    switch (value) {
      case 'Immunization Name':
        return 'w400';
      case 'Clinic Type':
      case 'Price':
      case 'Active':
        return 'w100';
      case 'Direct Bill Price':
        return 'w200';
      default:
        return '';
    }
  }
  editImmunization(value: ImmunizationCheck) {
    SessionDetails.SetImmunizationCheckPK(value.immunizationPk);
    this.router.navigate(['/communityoutreach/edit-immunization']);
  }

  addImmunizationCheck() {
    SessionDetails.SetImmunizationCheckPK(0);
    this.router.navigate(['/communityoutreach/add-immunization']);
  }
  onChange(value) {
    if (value === "0")
      this.immunizationChecks = this.totalData.filter(p => p.isActive);
    else if (value === "1")
      this.immunizationChecks = this.totalData.filter(p => !p.isActive);
    else if (value === "2")
      this.immunizationChecks = this.totalData;
  }
}
