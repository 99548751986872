import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PcaContractsService } from '../../services/pca-contracts.service';
import { DatePipe } from '@angular/common';
import { Util } from '../../../../utility/util';
import { PcaContract, Store, OpportunityInfo } from '../../../../models/pcaclinic';
import { NgxSpinnerService } from "ngx-spinner";
import { SessionDetails } from '../../../../utility/session';
import { ContractData } from '../../../../models/contract';
import { OutreachProgramService } from '../../../contractaggreement/services/outreach-program.service';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TimeToDatePipe } from '../../../contractaggreement/pipes/time-to-date.pipe';
import { Router } from '@angular/router';
import { PcacontractAgreementService } from '../../Services/pcacontract-agreement.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-pca-agreement',
  templateUrl: './pca-agreement.component.html',
  styleUrls: ['./pca-agreement.component.css'],
  providers: [DatePipe, TimeToDatePipe]
})
export class PcaAgreementComponent implements OnInit {
  contractData: PcaContract = new PcaContract();
  orthoDoxContract: ContractData = new ContractData();
  dialogSummary: string;
  dialogMsg: string;
  display: boolean = false;
  success_message: boolean = true;
  regularContract: FormGroup;
  @Input('outReachProgramType')
  outReachProgramType: number;
  tAndC: any;
  lulaHdr: string = "LIMITED USE LICENSE AGREEMENT<br/>TESTING EVENT";
  hideSaveBtn: boolean = false;
  disableSendOrSave: boolean = null;
  proofInsurances=null;
  serviceLabel = "";
  headerLabel = "";
  //tempTncInsurance:string;
  @Output() clinicSubmit: EventEmitter<number> = new EventEmitter();
  constructor(private pcaContractService: PcaContractsService, private utility: Util,
    private _datePipe: DatePipe, private spinner: NgxSpinnerService,
    private _apiservice: OutreachProgramService, private _timeToDatePipe: TimeToDatePipe,
    private _fb: FormBuilder, private router: Router, private _contractService: PcacontractAgreementService) { }

  ngOnInit() {
    this.contractData = this.pcaContractService.getPcaContractData();
      if(this.outReachProgramType != 11 && this.outReachProgramType != 12) {
        if(this.contractData.immunization.length){
          let serviceLabelList = this.contractData.immunization.map((immunization) => {
            let idx = immunization.immunizationName.indexOf("testing");
            return immunization.immunizationName.substring(0,idx);
          });
          serviceLabelList = serviceLabelList.sort();
          if(serviceLabelList.length > 1){
            let lastEle = serviceLabelList.pop();
            let label =  `${serviceLabelList.join(', ')} and ${lastEle}`;
            this.headerLabel = `${serviceLabelList.join(',')} and ${lastEle}`.replace(" ,", ", ");
            this.serviceLabel = `${label.replace(" ,", ", ")} testing and screening services and ${label.replace(" ,",", ").replace("HIV", "HIV/AIDS")} counseling to individuals`;
          } else {
            let label =  `${serviceLabelList.join(',')}`;
            this.headerLabel = `${serviceLabelList.join(',')}`.replace(" ,", ", ");
            this.serviceLabel = `${label.replace(" ,", ", ")} testing and screening services and ${label.replace(" ,",", ").replace("HIV", "HIV/AIDS")} counseling to individuals`;
          }
        }
    }
    
    
    if (this.outReachProgramType == 12) this.hideSaveBtn = true;
    else
      this.hideSaveBtn = this.contractData.isEmailSent == 1 && (this.contractData.isApproved != 0) && (this.contractData.isRhdApproved != 0)
    this.contractData.pcaEvenScheduleList.forEach(element => {
      try {
        element.endTime = this._timeToDatePipe.transform(new Date(element.eventDate), element.endTime);
        element.startTime = this._timeToDatePipe.transform(new Date(element.eventDate), element.startTime);
      }
      catch (e) {

      }
    });
    this.regularContract = this._fb.group({
      email: this._fb.control('', null)
    });

    if (this.contractData.testingPartner == null || this.contractData.testingPartner.businessName == "") {

      this.pcaContractService.getTpOpportunityDetailsUsingBusinessPk(SessionDetails.GetopportunitiesData().businessPk).subscribe((data:any) => {
        this.contractData.testingPartner = data;
        this.contractData.testingPartner.zip = data.zipCode;
        this.contractData.licenseAgreementNum = this.contractData.store.storeId + "_" + this.contractData.testingPartner.businessName.toUpperCase().replace(/[^A-Z0-9]+/ig, "").substr(0, 6) + "_" + this._datePipe.transform(new Date(), "MMddyyyy") + "-" + this._datePipe.transform(new Date(), "HHmm");
      },
        err => {
          console.log(err.json().Message);
        }
      );
      if (this.contractData.store == null) {
        this.contractData.store = new Store();
        this.setStoreData();
      }
      if (this.contractData.testingPartner == null) {
        this.contractData.testingPartner = new OpportunityInfo();
      }
    }
    else if (this.contractData.licenseAgreementNum == null || this.contractData.licenseAgreementNum == undefined ||
      this.contractData.licenseAgreementNum == "") {
      this.contractData.licenseAgreementNum = this.contractData.store.storeId + "_" + this.contractData.testingPartner.businessName.toUpperCase().replace(/[^A-Z0-9]+/ig, "").substr(0, 6) + "_" + this._datePipe.transform(new Date(), "MMddyyyy") + "-" + this._datePipe.transform(new Date(), "HHmm");
    }

    this.tAndC = this.pcaContractService.getLulaTandC();
    switch (this.outReachProgramType) {
      case 11:
        this.lulaHdr = "LIMITED USE LICENSE AGREEMENT<br/>ORGAN DONOR REGISTRATION EVENT";
        break;
      case 12:
        this.lulaHdr = "LIMITED USE LICENSE AGREEMENT<br/>CANCER SCREENING EVENT";
        break;
      default:
        this.lulaHdr = "LIMITED USE LICENSE AGREEMENT<br/>TESTING EVENT";
        break;
    }
    this.loadtAndCInsurance();
  }
  setStoreData() {
    let profile_data = SessionDetails.getStoreProfileData();
    this.contractData.store = profile_data[0];
    this.contractData.store.zipCode = this.contractData.store.zip;
  }
  sendEmail() {
    this.disableSendOrSave = true;
    let emailList: string = '';
    if (this.regularContract.value.email != null) {
      emailList = this.regularContract.value.email.replace(/ /g, "");
    }
    if (!this.utility.validateEmail(emailList)) {
      this.regularContract.controls['email'].setValidators([Validators.required]);
      this.regularContract.controls['email'].updateValueAndValidity();
      this.utility.validateAllFormFields(this.regularContract);
      let sumMsg = ErrorMessages['errMsg'];
      let sMsg = ErrorMessages['emailList'];
      this.showDialog(sumMsg, sMsg, false);
      this.disableSendOrSave = null;
      return;
    }
    this.contractData.clinicAgreementPk = SessionDetails.GetAgreementPK();
    this.utility.transformPcaAgreementToOrthoDoxContractJson(this.contractData, emailList, this._datePipe, this.orthoDoxContract);
    this.orthoDoxContract.isApproved = null;
    this.orthoDoxContract.isRHDApproved = null;
    this.orthoDoxContract.dateApproved = null;
    this.orthoDoxContract.dateRHDApproved = null;
    if (this.orthoDoxContract.clinicList.length <= 0) {
      let sumMsg = ErrorMessages['errMsg'];
      let sMsg = ErrorMessages['clinicListEmpty'];
      this.showDialog(sumMsg, sMsg, false);
      return;
    }
    this.orthoDoxContract.isEmailSent = 1;
    if (this.contractData.licenseAgreementNum == null || this.contractData.licenseAgreementNum == undefined ||
      this.contractData.licenseAgreementNum == "") {
      this.contractData.licenseAgreementNum = this.contractData.store.storeId + "_" + this.contractData.testingPartner.businessName.toUpperCase().replace(/[^A-Z0-9]+/ig, "").substr(0, 6) + "_" + this._datePipe.transform(new Date(), "MMddyyyy") + "-" + this._datePipe.transform(new Date(), "HHmm");
    }
    this.spinner.show();
    this._apiservice.saveAgreementData(SessionDetails.GetStoreId(), this.outReachProgramType, this.orthoDoxContract).subscribe((data: any) => {
      this.spinner.hide();

      switch (data.errorS.errorCode) {
        case '0':
        case 0:
          this.disableSendOrSave = null;
          this.showDialog(ErrorMessages['asoLula'], ErrorMessages['asolulaEmailSent'], true);
          break;
        case -4:
        case '-4':
          this.disableSendOrSave = null;
          this.contractData.isEmailSent = 0;
          this.contractData.isApproved = null;

          this.showDialog(ErrorMessages['asoLula'], data.errorS.errorMessage, false);
          break;
        default:
          this.contractData.isEmailSent = 0;
          this.disableSendOrSave = null;
          this.contractData.isApproved = null;

          this.showDialog(ErrorMessages['asoLula'], data.errorS.errorMessage, false);
          break;
      }

    },
      err => {
        this.disableSendOrSave = null;
        this.spinner.hide();
        this.contractData.isEmailSent = 0;
        this.contractData.isApproved = null;
        switch (err) {
          case 500:
            this.showDialog(ErrorMessages['serverError'], err.json().Message, false);
            break;
          case 400:
            this.showDialog(ErrorMessages['clientError'], err.json().Message, false);
            break;
          default:
            this.showDialog(ErrorMessages['serverError'], err.json().Message, false);
            break;
        }
      });
  }
  saveAgreement() {
    this.disableSendOrSave = true;
    let emailList: string = '';
    if (this.regularContract.value.email != null) {

      emailList = this.regularContract.value.email.replace(/ /g, "");
    }

    this.contractData.clinicAgreementPk = SessionDetails.GetAgreementPK();
    this.utility.transformPcaAgreementToOrthoDoxContractJson(this.contractData, emailList, this._datePipe, this.orthoDoxContract);
    //this.orthoDoxContract.businessUserEmails
    this.orthoDoxContract.isEmailSent = 0;
    this.orthoDoxContract.isApproved = null;
    this.orthoDoxContract.isRHDApproved = null;
    this.orthoDoxContract.dateApproved = null;
    this.orthoDoxContract.dateRHDApproved = null;
    this.spinner.show();
    this._apiservice.saveAgreementData(SessionDetails.GetStoreId(), this.outReachProgramType, this.orthoDoxContract).subscribe((data: any) => {
      this.spinner.hide();


      switch (data.errorS.errorCode) {
        case '0':
        case 0:
          this.showDialog(ErrorMessages['asoLula'], ErrorMessages['asoLulaSaved'], true)
          this.disableSendOrSave = null;
          break;
        case -4:
        case '-4':
          this.disableSendOrSave = null;
          this.contractData.isEmailSent = 0;
          this.contractData.isApproved = null;
          this.showDialog(ErrorMessages['asoLula'], data.errorS.errorMessage, false);
          break;
        default:
          this.disableSendOrSave = null;
          this.contractData.isEmailSent = 0;
          this.contractData.isApproved = null;
          this.showDialog(ErrorMessages['asoLula'], data.errorS.errorMessage, false);
          break;
      }

    },
      err => {
        this.disableSendOrSave = null;
        this.spinner.hide();
        this.contractData.isEmailSent = 0;
        this.contractData.isApproved = null;
        switch (err) {
          case 500:
            this.showDialog(ErrorMessages['serverError'], err.json().Message, false);
            break;
          case 400:
            this.showDialog(ErrorMessages['clientError'], err.json().Message, false);
            break;
          default:
            this.showDialog(ErrorMessages['serverError'], err.json().Message, false);
            break;
        }

      });
  }

  isFieldValid(field: string) {
    return !this.regularContract.get(field).valid && this.regularContract.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  showDialog(msgSummary: string, msgDetail: string, success_message: boolean) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
    this.success_message = success_message;
  }
  okClicked() {
    this.display = false;
    this.disableSendOrSave = null;
    if (this.success_message) {
      this.router.navigateByUrl('/communityoutreach/storehome');
    }
  }
  btnClick() {
    this.clinicSubmit.emit(1);
  };
  cancelAgreementClicked() {
    this.router.navigate(['./communityoutreach/storehome']);
  }
  print() {
    this.utility.print("asolulaContract", "Limited Use License Agreement");
  }

  loadtAndCInsurance() {
    let tnCCutOffDate = new Date(this.tAndC.tnCCutOffDate);
    let dateApproved = null;
    if(this.contractData && this.contractData.dateApproved){
       dateApproved = new Date(this.contractData.dateApproved);
    }
    switch (this.outReachProgramType) {
      case 10:
      case 8:
          if(this.contractData.immunization[0].immunizationName==="HIV testing and screening services and HIV/AIDS counseling to individuals"  
          && (dateApproved === null || dateApproved>=tnCCutOffDate || this.contractData.isApproved===0 ) ){
          this.tAndC.insurance = this.tAndC.conditionalTnCInsurance.replace('{0}', "HIV Testing and counseling services");
        }
          else if(this.contractData.immunization[0]?.immunizationName==="Hepatitis C testing and screening services and Hepatitis C counseling to individuals" 
          && (dateApproved === null || dateApproved>=tnCCutOffDate || this.contractData.isApproved===0 ) ){
              this.tAndC.insurance =  this.tAndC.conditionalTnCInsurance.replace('{0}', "HCV Testing and counseling services");
        }
          else if(this.contractData.immunization[0].immunizationName==="HIV and Hepatitis C testing and screening services and HIV/AIDS and Hepatitis C counseling to individuals"
          && (dateApproved === null || dateApproved>=tnCCutOffDate || this.contractData.isApproved===0 ) ){
          this.tAndC.insurance = this.tAndC.conditionalTnCInsurance.replace('{0}', "HIV and HCV Testing and counseling services");
        }
        break;
      case 12:
          if (dateApproved === null || dateApproved>=tnCCutOffDate || this.contractData.isApproved===0 ) {
          this.tAndC.insurance = this.tAndC.conditionalTnCInsurance.replace('{0}', "cancer screening services");
        }
        break;
    }
    if((!this.contractData.isApproved && this.contractData.isApproved != null )||(!this.contractData.isRhdApproved&&this.contractData.isRhdApproved!=null))
      this.proofInsurances = this._contractService.getProofInsuranceDocumentList();
    }
    Download(file_name) {
      window.open(environment.RESOURCES.PROOF_OF_DOCUMENTS + "/" + file_name);
    }
  }
  

