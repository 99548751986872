// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
section.dark.page-header {
		color:#fff;
		background-color:#2a7db5 !important;
	}
  section.page-header.page-header-xs
  {
      padding: 10px 0 10px 0;
  }
  ol{
      flex-direction: column;
  }
  section.page-header .breadcrumb>li+li:before {
	content:"";
}
section.dark .form-control {
     background-color: #fff; 
}`, "",{"version":3,"sources":["webpack://./src/app/modules/common/components/header/header.component.css"],"names":[],"mappings":";AACA;EACE,UAAU;EACV,mCAAmC;CACpC;EACC;;MAEI,sBAAsB;EAC1B;EACA;MACI,sBAAsB;EAC1B;EACA;CACD,UAAU;AACX;AACA;KACK,sBAAsB;AAC3B","sourcesContent":["\r\nsection.dark.page-header {\r\n\t\tcolor:#fff;\r\n\t\tbackground-color:#2a7db5 !important;\r\n\t}\r\n  section.page-header.page-header-xs\r\n  {\r\n      padding: 10px 0 10px 0;\r\n  }\r\n  ol{\r\n      flex-direction: column;\r\n  }\r\n  section.page-header .breadcrumb>li+li:before {\r\n\tcontent:\"\";\r\n}\r\nsection.dark .form-control {\r\n     background-color: #fff; \r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
