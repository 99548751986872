export const defaultSiteDesignInfo: any = {
    "defaultTextValues": {
      "headerText": "Walgreens Immunization Clinic",
      "bodyText": "There's one thing we know about flu season and that is it's unpredictable.&lt;br/&gt;&lt;br/&gt;That's why the CDC and other health organizations widely recommend that everyone 6 months of age and older get a flu shot every year as the best protection possible against the flu.&lt;br/&gt;&lt;br/&gt;Thank you for taking this important step in protecting both yourself as well as those around you from the adverse effect of the flu.&lt;br/&gt;&lt;br/&gt;Please use this page to create your appointment for your upcoming flu clinic.",
      "footerText": "All participants are <strong>recommended</strong> to wear a face covering, comfortable clothing and a short sleeve shirt on the day of the clinic.&lt;br/&gt;&lt;br/&gt;In addition, it is required for us to provide you with the Vaccine Information Statement (VIS) prior to receiving your vaccination.  Please review the VIS by clicking &lt;a href=\"https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/VIS Form.pdf\" target=\"_blank\"&gt;here&lt;/a&gt;."
    },
    "defaultDesignValues": {
      "mastheadLogo": "Wags Logo",
      "logoFile": "walgreens_email_logo.png",
      "bannerColor": "#02a0c0",
      "bannerTextColor": "#FFFFFF",
      "buttonColor": "#02a0c0",
      "buttonTextColor": "#FFFFFF",
      "alternateText": "Walgreens"
    }
  }