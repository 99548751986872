import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ActionitemsandmetricsService {
  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL;
  constructor(private http: HttpClient) { }

  public getActionItemsReportData(user_id:string,report_id:string,is_pca:boolean,is_flu:boolean=false, organization_type?:number) {
    let url = this._url + "/api/JsActionItems_get/" + user_id +"/" + report_id + "/" + is_pca+"/" + is_flu;
     if(is_pca){
      url=url+"/"+organization_type;
    }
    return this.http.get(url,  Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  public getMetricsReportData(user_id:string,report_id:string,is_pca:boolean, organization_type?:number) {
    let url = this._url + "/api/JsMetrics_get/" + user_id +"/" + report_id + "/" + is_pca;
     if(is_pca){
      url=url+"/"+organization_type;
    }
    return this.http.get(url,  Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  public exportActionItemsReportData(user_id:string,report_id:string,is_pca:boolean, report_name:string,output_type:string,is_flu:boolean=false, organization_type?:number) {    
    let url = this._url + "/api/JsActionItems_downloadReport/"+ user_id + "/" + report_id  + "/" + is_pca +  "/" + report_name + "/" + output_type + "/" + is_flu;
    
    if(is_pca){
      url=url+"/"+organization_type;
    }
    return this.exportReportData(url);
    /*let headers: Headers = new Headers();
    let userData= SessionDetails.GetUserInfo()
    let token =  window.btoa(userData.userName+':'+userData.password);
    headers.append("Content-Type", "application/json");
    headers.append('Authorization', 'Basic '+token)
    let url = this._url + "/api/JsActionItems_downloadReport/"+ user_id + "/" + report_id + "/" + report_name + "/" + output_type;
    let options = new RequestOptions({ responseType: ResponseContentType.Blob, headers: headers });
    return this.http.get(url, options).map(res => {
      var contentDisposition = res.headers.get('Content-Disposition');
      var result = contentDisposition.split(';')[1].trim().split('=')[1];
      this.filename = result;
      return res.blob()
    });*/
  }

  public exportMetricsReportData(user_id:string,report_id:string,is_pca:boolean,
    report_name:string,output_type:string, organization_type?:number) {
    let url = this._url + "/api/JsMetrics_downloadReport/"+ user_id + "/" + report_id + "/" + is_pca +  "/" + report_name + "/" + output_type;
    if(is_pca){
      url=url+"/"+organization_type;
    }
    return this.exportReportData(url);
    /*let headers: Headers = new Headers();
    let userData= SessionDetails.GetUserInfo()
    let token =  window.btoa(userData.userName+':'+userData.password);
    headers.append("Content-Type", "application/json");
    headers.append('Authorization', 'Basic '+token)
    let url = this._url + "/api/JsMetrics_downloadReport/"+ user_id + "/" + report_id + "/" + report_name + "/" + output_type;
    let options = new RequestOptions({ responseType: ResponseContentType.Blob, headers: headers });
    return this.http.get(url, options).map(res => {
      var contentDisposition = res.headers.get('Content-Disposition');
      var result = contentDisposition.split(';')[1].trim().split('=')[1];
      this.filename = result;
      return res.blob()
    });*/
  }

  exportReportData(url:string){
    let userData= SessionDetails.GetUserInfo()
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    return this.http.get(url, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
    .pipe(catchError(Util.handleErrorV2));
  }
    public getOrganizationTypes() {
    let url : any = this._url + "/api/getOrganizationsList_get";
    return this.http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
}
