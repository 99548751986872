export const LTCFTypesValues:any[] = [
    {
      "id":"SNF",
      "name":"SNF"
    },
    {
        "id":"ALF",
        "name":"ALF"
      }
      ,
    {
        "id":"IDD",
        "name":"IDD"
      }
      ,
    {
        "id":"HUD",
        "name":"HUD"
      }
      ,
    {
        "id":"VET",
        "name":"VET"
      }
      ,
    {
        "id":"RES",
        "name":"RES"
      } ,
      {
        "id":"Other",
        "name":"Other"
      }   
  ]