export const noClientInterestOptions: string[] = [
    'No interest due to service fee',
    '>75 Miles',
    'Opt Out – Using another partner',
    'Opt Out – No clinic interest',
    'No Clinic – In-store appointments',
    'Duplicate',
    'Invalid facility information – unable to contact',
    'Capacity Constraint',
    'No Response after 3 attempts',
    'Other'
];