export const SiteRequiredFields: any[] =
[  {
       "fieldName": "firstName",
       "displayName": "First Name",
       "isFieldVisible": true,
       "isFieldRequired": false
     },
     {
       "fieldName": "middleName",
       "displayName": "Middle Name",
       "isFieldVisible": false,
       "isFieldRequired": false
     },
     {
       "fieldName": "lastName",
       "displayName": "Last Name Initial",
       "isFieldVisible": true,
       "isFieldRequired": false
     },
     {
       "fieldName": "employeeId",
       "displayName": "Employee ID",
       "isFieldVisible": true,
       "isFieldRequired": false
     },
     {
       "fieldName": "Email",
       "displayName": "Email",
       "isFieldVisible": true,
       "isFieldRequired": true
     }
]