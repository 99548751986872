import { Component, OnInit } from '@angular/core';
import { HeaderserviceService } from '../../../common/services/headerservice.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-dropped-store-reassignments',
  templateUrl: './dropped-store-reassignments.component.html',
  styleUrls: ['./dropped-store-reassignments.component.css']
})
export class DroppedStoreReassignmentsComponent implements OnInit {
  cols: any[];
  droppedStoreDlg: boolean = false;  
  errorShow: boolean = false;
  successShow: boolean = false;
  SelectedDroppedStores: any;
  droppedStores: any[] ;
  errorMessageShow: string;
  reassignShow: boolean= false;
  recordsExist: boolean = true;
  constructor(private service: HeaderserviceService,private spinner: NgxSpinnerService,) { }

  ngOnInit() {

    this.cols = [
      { field: 'storeId', header: 'Dropped Store #' },
      { field: 'DroppedStoreAddress', header: 'Dropped Store Address' },
      { field: 'closestStoreId', header: 'Closest Store #' },
      { field: 'ClosestStoreAddress', header: 'Closest Store Address' },
      { field: 'distance', header: 'Distance (mi)' },
      { field: 'opportunitiesCount', header: 'Opportunities' },
      { field: 'eventsCount', header: 'Events' }];

      this.getDroppedStoresForReassignment();

  }
  getDroppedStoresForReassignment() {
    this.spinner.show();
    this.service.getDroppedStoresForReassignment().subscribe((data) => {
      this.droppedStores = data;
      if (data.length > 0) {
        this.recordsExist = true;
      }
      else {
        this.recordsExist = false;
      }
      this.spinner.hide();
    } ,err=>{
      this.spinner.hide();
      });   
  }

  getHeaderWidth(colName: string) {
    switch (colName) {
      case "Dropped Store #":
        return 'w80';
      case "Dropped Store Address":
        return 'w250';
      case "Closest Store #":
        return 'w80';
      case "Closest Store Address":
        return 'w250';
      case "Distance (mi)":
        return 'w60';
      case "Opportunities":
        return 'w115 wb';
      case "Events":
        return 'w75';
      default:
        return '';
    }
  }
  reassignToClosetStore(isReassignAllstores:boolean) {  
    this.spinner.show();    
    if (this.SelectedDroppedStores === undefined || this.SelectedDroppedStores === null || this.SelectedDroppedStores === '') {
      this.spinner.hide();
      this.droppedStoreDlg = true;
      this.reassignShow=false;
    }
    else {
      if(isReassignAllstores===false && this.SelectedDroppedStores.length === this.droppedStores.length)
      {
        this.reassignShow=true;
        this.spinner.hide();
        return;

      }
      this.reassignShow=false;
      this.service.reassignDroppedStoresToClosestStore(this.SelectedDroppedStores).subscribe((data: any) => {
        this.spinner.hide();
          this.successShow = true;         
      },
        error => {
          this.spinner.hide();
          this.errorShow = true;
          this.errorMessageShow = error.errorS.errorMessage;
        
        }
      );
    }

  }

  successOkClick() {
    this.successShow = false;
    this.getDroppedStoresForReassignment();
  }
}
