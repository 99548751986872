export const COVIDTandCData: any =
{
    "spanish":"<p class=black-font>This<b> <i>COVID-19 IMMUNIZATION SERVICE AGREEMENT</i></b>  &nbsp;(\"<b>Agreement</b>\") by and between the party indicated below (\"<b>Client</b>\"), and Walgreen Co., on behalf of itself and its subsidiaries and affiliates (\"<b>Walgreens</b>\")</b> is made and entered into on the date last signed by an authorized representative of both the Client and Walgreens (the \"<b>Effective Date</b>\"). Walgreens and Client may be individually referred to as a \"<b>Party</b>\"</b> or collectively as the \"<b>Parties.</b>\"<br/></p>",
    "header":"<p class=black-font>For good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, Client and Walgreens, by their signatures below, hereby agree Walgreens will provide the immunizations indicated in Table 1, attached hereto and incorporated herein, consisting of dispensing and administering of such immunizations (\"<b>COVID Immunizations</b>\") to a participant population agreed to by the Parties (\"<b>Participants</b>\") at mutually agreed upon location(s) outside of Walgreens’ store locations, referred to as off-site location(s).</p>",
    "attachmentheader":"<p align=center><b>ATTACHMENT A</b></p><p>In accordance with the Agreement, for each COVID Immunization administered whether through a Voucher, at Private Clinic(s) or both, Walgreens will be entitled to reimbursement at the rates set forth in Table 1, below.  Additionally, for each COVID Immunization at a Private Clinic, Walgreens will also be entitled to reimbursement of the Private Clinic management fees more fully described herein and subject to Section 2.2 of the Agreement. Client acknowledges that the fees and rates set forth in the Agreement are Walgreens’ Confidential Information and Client agrees not to disclose this information to any third-party other than as minimally necessary under the terms of this Agreement.  Unless otherwise indicated below, the rates listed in Table 1 are inclusive of the cost of vaccine, dispensing fee, administration fee and any applicable taxes imposed in connection with COVID Immunizations. For purposes of this Attachment A, “Government Supplied COVID-19 Vaccine” means that the United States government has acquired the COVID-19 vaccine and is making these publicly funded vaccines available to select vaccine providers.</p>",
    "imzsp":"Government Supplied COVID-19 Vaccine*",
    "paymentsp":"Método De Pago",
    "ratessp":"el Precio",
    "imzen":"Government Supplied COVID-19 Vaccine*",
    "payen":"Payment",
    "ratesen":"Rate",
   "english":"<p class=black-font>This<b> <i>COVID-19 IMMUNIZATION SERVICE AGREEMENT</i></b>  &nbsp;(\"<b>Agreement</b>\") by and between the party indicated below (\"<b>Client</b>\"), and Walgreen Co., on behalf of itself and its subsidiaries and affiliates (\"<b>Walgreens</b>\")</b> is made and entered into on the date last signed by an authorized representative of both the Client and Walgreens (the \"<b>Effective Date</b>\"). Walgreens and Client may be individually referred to as a \"<b>Party</b>\"</b> or collectively as the \"<b>Parties.</b>\"<br/></p>",
    "englishheader":"<p class=black-font>For good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, Client and Walgreens, by their signatures below, hereby agree Walgreens will provide the immunizations indicated in Table 1, attached hereto and incorporated herein, consisting of dispensing and administering of such immunizations (\"<b>COVID Immunizations</b>\") to a participant population agreed to by the Parties (\"<b>Participants</b>\") at mutually agreed upon location(s) outside of Walgreens’ store locations, referred to as off-site location(s).</p>",
    "directBillEnglishheader":"<p class=black-font>For good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, Client and Walgreens, by their signatures below, hereby agree Walgreens will provide the immunizations indicated in Attachment A, attached hereto and incorporated herein, consisting of dispensing and administering of such immunizations (\"<b>COVID Immunizations</b>\") to a participant population agreed to by the Parties (\"<b>Participants</b>\") at mutually agreed upon location(s) outside of Walgreens’ store locations, referred to as off-site location(s).</p>",
   "englishattachment":"<h3 class='text-center mb-5' style='font-size:18px !important;color:#212529 !important;'>ATTACHMENT A</h3><p class=\"black-font\">In accordance with the Agreement, for each COVID Immunization administered whether through a Voucher, at Private Clinic(s) or both, Walgreens will be entitled to reimbursement at the rates set forth in Table 1, below.  Additionally, for each COVID Immunization at a Private Clinic, Walgreens will also be entitled to reimbursement of the Private Clinic management fees more fully described herein and subject to Section 2.2 of the Agreement. Client acknowledges that the fees and rates set forth in the Agreement are Walgreens’ Confidential Information and Client agrees not to disclose this information to any third-party other than as minimally necessary under the terms of this Agreement.  Unless otherwise indicated below, the rates listed in Table 1 are inclusive of the cost of vaccine, dispensing fee, administration fee and any applicable taxes imposed in connection with COVID Immunizations. For purposes of this Attachment A, “Government Supplied COVID-19 Vaccine” means that the United States government has acquired the COVID-19 vaccine and is making these publicly funded vaccines available to select vaccine providers.</p>",
   "clientsp":"CLIENTE:",
   "namesp":"NOMBRE:",
   "titlesp":"TÍTULO:",
   "clienten":"Business Name:",
   "nameen":"Name:",
   "eSignatureSP": "eSig:",
   "eSignatureEN": "eSig:",
   "titleen":"Title:",
   "attentionsp": "Atención",
   "address1sp": "Dirección 1",
   "address2sp": "Dirección 2",
   "citysp": "Ciudad",
   "statesp": "Estado",
   "zipcodesp": "Código Postal:",
    "attentionen": "Attention",
    "address1en":"Address1",
    "address2en":"Address2",
    "cityen":"City",
    "stateen":"State",
    "zipcodeen":"Zip",
    "legalsp":"Envíele Avisos Legales al Cliente A",
    "clientinfosp":"Vigencia",
     "legalen":"Send Legal Notice To Client At",
    "clientinfoen":"Client Information",
    "inWitnessWhereOffEN":"IN WITNESS WHEREOF,",
    "inWitnessWhereOffSP": "IN WITNESS WHEREOF,",
    "inWitnessWhereOff2ndLineEN":"Client and Walgreens have executed this Agreement.",
     "inWitnessWhereOff2ndLineSP":"Client and Walgreens have executed this Agreement.",
     "sendLegalNoticeToClientEN":"Send Legal Notice To Client At:",
     "sendLegalNoticeToClientSP":"Envíele Avisos Legales al Cliente A:",
     "sendLegalNoticeToWalgreensEN":"Send Legal Notice To Walgreens At:",
     "sendLegalNoticeToWalgreensSP":"Envíele Avisos Legales al Walgreens A:",
     "wallGreensCommOffsiteTitle1EN":"WALGREENS COMMUNITY OFF-SITE CLINIC AGREEMENT",
     "wallGreensCommOffsiteTitle1SP":"WALGREENS CONTRATO DE CLÍNICA AMBULATORIA COMUNITARIA",
     "wallGreensCommOffsiteTitle2EN":"TERMS AND CONDITIONS",
     "wallGreensCommOffsiteTitle2SP":"TERMS AND CONDITIONS",
     "wallGreensResponsibilitiesEN":" WALGREENS’ RESPONSIBILITIES",// deliberately extra space
     "wallGreensResponsibilitiesSP":"RESPONSABILIDADES DE WALGREENS",
     "wallGreensResponsibilitiesPara1HeadingEN":"COVID Immunizations",
     "wallGreensResponsibilitiesPara1HeadingSP":"COVID Immunizations",
     "wallGreensResponsibilitiesCvrdServicesEN":" Subject to the limitations or restrictions imposed by federal and state contracts, laws, and regulations, and the availability of the appropriate COVID Immunization, Walgreens will administer the COVID Immunizations to Participants either directly or through an authorized provider. With respect to such COVID Immunizations, the Parties will comply with the procedures set forth herein. When required by state law, Walgreens will require Participants to provide a valid prescription from their physician or allow the health care professional to contact their physician to obtain a valid prescription; however, for COVID Immunizations, Walgreens will be responsible for obtaining standing orders from physicians. Participants will be required to complete a Walgreens’ vaccine administration record and consent form before receiving a COVID Immunization.",
    "wallGreensResponsibilitiesCvrdServicesSP":" Subject to the limitations or restrictions imposed by federal and state contracts, laws, and regulations, and the availability of the appropriate COVID Immunization, Walgreens will administer the COVID Immunizations to Participants either directly or through an authorized provider. With respect to such COVID Immunizations, the Parties will comply with the procedures set forth herein. When required by state law, Walgreens will require Participants to provide a valid prescription from their physician or allow the health care professional to contact their physician to obtain a valid prescription; however, for COVID Immunizations, Walgreens will be responsible for obtaining standing orders from physicians. Participants will be required to complete a Walgreens’ vaccine administration record and consent form before receiving a COVID Immunization.",
    "profJudgementHeadingEN":"Professional Judgment",
    "profJudgementHeadingSP":"Professional Judgment",
    "profJudgementTextEN":" Walgreens may withhold administration of COVID Immunizations to a Participant for good cause, including but not necessarily limited to, insufficient information for the Pharmacist to verify a Participant received a first dose of an applicable COVID Immunization, requests by Participant for services inconsistent with the legal and regulatory requirements; or where, in the professional judgment of the health care professional, the services should not be rendered. The Parties agree that, other than as required by law, Walgreens is not responsible for the tracking of individual Participant(s) first or second doses of any COVID Immunization.  Furthermore, Walgreens has no duty to contact Participant(s) concerning follow-up COVID Immunizations to receive any applicable second dose.",
    "profJudgementTextSP":" Walgreens may withhold administration of COVID Immunizations to a Participant for good cause, including but not necessarily limited to, insufficient information for the Pharmacist to verify a Participant received a first dose of an applicable COVID Immunization, requests by Participant for services inconsistent with the legal and regulatory requirements; or where, in the professional judgment of the health care professional, the services should not be rendered. The Parties agree that, other than as required by law, Walgreens is not responsible for the tracking of individual Participant(s) first or second doses of any COVID Immunization.  Furthermore, Walgreens has no duty to contact Participant(s) concerning follow-up COVID Immunizations to receive any applicable second dose.",
    "provHealthCrProfHdngEN":"Provision of Healthcare Professional",
    "provHealthCrProfHdngSP":"Provision of Healthcare Professional",
    "provHealthCrProfTextEN":" If the Parties agree in writing that Walgreens will administer COVID Immunizations at locations outside of Walgreens’ store locations (<b>\"Private Clinics\"</b>), Walgreens will provide Client with the appropriate number of qualified health care professionals and technicians to provide such immunizations at the Private Clinic locations based upon and in reliance on Client’s good-faith estimates of Participant volume. Any requests for additional personnel will be subject to agreement by Walgreens.",
    "provHealthCrProfTextSP":" If the Parties agree in writing that Walgreens will administer COVID Immunizations at locations outside of Walgreens’ store locations (<b>\"Private Clinics\"</b>), Walgreens will provide Client with the appropriate number of qualified health care professionals and technicians to provide such immunizations at the Private Clinic locations based upon and in reliance on Client’s good-faith estimates of Participant volume. Any requests for additional personnel will be subject to agreement by Walgreens.",
    "clientsResponsibilitesEN":"CLIENT’S RESPONSIBILITIES",
    "clientsResponsibilitesSP":"CLIENT’S RESPONSIBILITIES",
    "vouchersHeadingEN":"Vouchers",
    "vouchersHeadingSP":"Vouchers",
    "vouchersTextEN":" If the Parties agree in writing that Walgreens will administer COVID Immunizations upon receipt of a Walgreens’ approved vouchers issued to Participants by Client (<b>\"Vouchers\"</b>), Client will provide Participants with a Voucher, which Participants may redeem at a participating Walgreens store location.  Client may not rescind, retract, reduce or deny payment owed to Walgreens for claims where COVID Immunizations were provided to its Participants, even if Client no longer considers the individual presenting the Voucher to be a Participant.",
    "vouchersTextSP":" If the Parties agree in writing that Walgreens will administer COVID Immunizations upon receipt of a Walgreens’ approved vouchers issued to Participants by Client (<b>\"Vouchers\"</b>), Client will provide Participants with a Voucher, which Participants may redeem at a participating Walgreens store location.  Client may not rescind, retract, reduce or deny payment owed to Walgreens for claims where COVID Immunizations were provided to its Participants, even if Client no longer considers the individual presenting the Voucher to be a Participant.",
    "offSiteLocationEN":"Private Clinic Locations.",
    "offSiteLocationSP":"Private Clinic Locations.",
    "importantEN":"IMPORTANT",
    "importantSP":"IMPORTANT",
    "offSiteLocationText1EN":" If the Parties agree in writing, that Walgreens will administer COVID Immunizations at Private Clinics, Client will provide Participants with notice of the dates, times and locations for such Private Clinics.  For all Private Clinics, Client will provide a private, clean room location, tables and chairs for Walgreens' personnel and Participants.  Where applicable, Client agrees to assist Walgreens in the collection of Participants’ Third Party Payors (defined below) eligibility information and any additional reasonably requested information, in order to help expedite the delivery of COVID Immunizations.  For each Private Clinic location, Client agrees to reimburse Walgreens, in addition to the rates listed in Table 1 of Attachment A, the greater of: (i) a flat Private Clinic management fee of four thousand two hundred fifty dollars ($4,250.00) per day the Private Clinic operates, or (ii) the Private Clinic management fee for each COVID Immunization dose administered as set forth in Table 2 of Attachment A, below.  The Private Clinic management fees, contained herein will be billed directly to Client and will not be used by either Party for billing to Third Party Payors.",
    "offSiteLocationText1SP":" If the Parties agree in writing, that Walgreens will administer COVID Immunizations at Private Clinics, Client will provide Participants with notice of the dates, times and locations for such Private Clinics.  For all Private Clinics, Client will provide a private, clean room location, tables and chairs for Walgreens' personnel and Participants.  Where applicable, Client agrees to assist Walgreens in the collection of Participants’ Third Party Payors (defined below) eligibility information and any additional reasonably requested information, in order to help expedite the delivery of COVID Immunizations.  For each Private Clinic location, Client agrees to reimburse Walgreens, in addition to the rates listed in Table 1 of Attachment A, the greater of: (i) a flat Private Clinic management fee of four thousand two hundred fifty dollars ($4,250.00) per day the Private Clinic operates, or (ii) the Private Clinic management fee for each COVID Immunization dose administered as set forth in Table 2 of Attachment A, below.  The Private Clinic management fees, contained herein will be billed directly to Client and will not be used by either Party for billing to Third Party Payors.",
    "eligibleParticipantsEN":"Eligible Participants.",
    "eligibleParticipantsSP":"Eligible Participants.",
    "eligibleParticipantsTextEN":" Prior to the distribution of COVID Immunizations to the general population, the Parties acknowledge specific populations have been or may be designated by the applicable Federal, State, or local  authorities (\"<b>Jurisdiction</b>\") to be eligible for the COVID Immunizations.  Client represents Participants receiving COVID Immunizations under this Agreement are eligible to receive COVID Immunizations based on the applicable Jurisdiction’s requirements (e.g. State vaccine distribution plans using CDC designations: Phase 1a, 1b, 1c,  2 etc.) and at no time will Client allow a Participant to receive COVID Immunizations under this Agreement until eligible under the applicable Jurisdiction’s criteria. The Parties agree that when providing COVID Immunizations, Walgreens will act in good faith reliance as it relates to Client’s determination of Participants’ eligibility in each Jurisdiction.",
    //"eligibleParticipantsSP":" Prior to the distribution of COVID Immunizations to the general population, the Parties acknowledge specific populations have been or may be designated by the applicable Federal, State, or local  authorities (\"<b>Jurisdiction</b>\") to be eligible for the COVID Immunizations.  Client represents Participants receiving COVID Immunizations under this Agreement are eligible to receive COVID Immunizations based on the applicable Jurisdiction’s requirements (e.g. State vaccine distribution plans using CDC designations: Phase 1a, 1b, 1c, 2 etc.) and at no time will Client allow a Participant to receive COVID Immunizations under this Agreement until eligible under the applicable Jurisdiction’s criteria. The Parties agree that when providing COVID Immunizations, Walgreens will act in good faith reliance as it relates to Client’s determination of Participants’ eligibility in each Jurisdiction.",
    "eligibleParticipantsTextSP":" Prior to the distribution of COVID Immunizations to the general population, the Parties acknowledge specific populations have been or may be designated by the applicable Federal, State, or local  authorities (\"<b>Jurisdiction</b>\") to be eligible for the COVID Immunizations.  Client represents Participants receiving COVID Immunizations under this Agreement are eligible to receive COVID Immunizations based on the applicable Jurisdiction’s requirements (e.g. State vaccine distribution plans using CDC designations: Phase 1a, 1b, 1c, 2 etc.) and at no time will Client allow a Participant to receive COVID Immunizations under this Agreement until eligible under the applicable Jurisdiction’s criteria. The Parties agree that when providing COVID Immunizations, Walgreens will act in good faith reliance as it relates to Client’s determination of Participants’ eligibility in each Jurisdiction.",
    "siteMinEN":"Site Minimum",
    "siteMinSP":"Site Minimum",
    "siteAvgEN":"Site Average",
    "siteAvgSP":"Site Average",
    "siteMinTextEN":"(\"<b>Site Minimum</b>\"). If Walgreens determines that the Site Minimum is not achieved for the {0} (determined by taking the total number of Immunizations administered at all off-site locations divided by the number of off-site locations in such {1} ",
    "siteMinTextfluEN":"(\"<b>Site Minimum</b>\"). If Walgreens determines that the Site Minimum is not achieved for the {0} (determined by taking the total number of Immunizations administered at all off-site locations divided by the number of off-site locations in such {1} ",
    "siteMinTextSP":"(\"<b>Site Minimum</b>\"). If Walgreens determines that the Site Minimum is not achieved for the {0} (determined by taking the total number of Immunizations administered at all off-site locations divided by the number of off-site locations in such {1} ",
    "siteMinTextfluSP":"(\"<b>Site Minimum</b>\"). If Walgreens determines that the Site Minimum is not achieved for the {0} (determined by taking the total number of Immunizations administered at all off-site locations divided by the number of off-site locations in such {1} ",
    "siteAvgTextEN":"(\"<b>Site Average</b>\")), at Walgreens’ discretion, it will invoice Client for the difference between the Site Minimum and Site Average multiplied by the number of off-site events. The sum of which will be multiplied by the lowest reimbursement rate set forth in table in Attachment A and Client shall pay such amount within 30 days of being invoiced by Walgreens.",
    "siteAvgTextSP":"(\"<b>Site Average</b>\")), at Walgreens’ discretion, it will invoice Client for the difference between the Site Minimum and Site Average multiplied by the number of off-site events. The sum of which will be multiplied by the lowest reimbursement rate set forth in table in Attachment A and Client shall pay such amount within 30 days of being invoiced by Walgreens.",
    "pmtAndBIllingEN":"PAYMENT AND BILLING",
    "pmtAndBIllingSP":"PAYMENT AND BILLING",
    "paymentEN":"Payment For Government Supplied.",
    "paymentSP":"Payment For Government Supplied.",
    "paymentTextEN":"3.1 <u>Payment For Government Supplied</u>. For Government Supplied COVID-19 Vaccines (as defined in Table 1 above) where: (i) Participant provides evidence of coverage under third-party insurance or a government funded program (e.g., Medicare)  (\"<b>Third Party</b>\")  prior to the provision of Covered Services; (ii) and Walgreens is contracted with that Third Party, Walgreens will submit the claim for applicable Covered Services to such Third Party.",
    "paymentTextDirectBillEN":"3.1 <u>Payment</u>. For COVID Immunizations (listed in Attachment A), Walgreens will invoice Client for said immunizations and Client agrees to reimburse Walgreens within thirty (30) days from receipt of the applicable monthly invoice at the remittance address located on the invoice.  However, in the event the Parties agree in writing and where: (i) Participant provides evidence of coverage under third-party insurance or a government funded program (e.g., Medicare) (\"<b>Third Party Payor</b>\") prior to the provision of COVID Immunizations; and (ii) Walgreens is contracted with that Third Party Payor, Walgreens will submit the claim for applicable COVID Immunizations to such Third Party Payor. If evidence of coverage under a Third Party Payor is not provided at the time of service or a claim for reimbursement is denied by a Third Party Payor, the Parties agree that Walgreens may seek reimbursement from the Client.",
    "paymentTextSP":"3.1 <u>Payment For Government Supplied</u>. For Government Supplied COVID-19 Vaccines (as defined in Table 1 above) where: (i) Participant provides evidence of coverage under third-party insurance or a government funded program (e.g., Medicare)  (\"<b>Third Party</b>\")  prior to the provision of Covered Services; (ii) and Walgreens is contracted with that Third Party, Walgreens will submit the claim for applicable Covered Services to such Third Party.",
    "paymentTextDirectBillSP":"3.1 <u>Payment</u>. For COVID Immunizations (listed in Attachment A), Walgreens will invoice Client for said immunizations and Client agrees to reimburse Walgreens within thirty (30) days from receipt of the applicable monthly invoice at the remittance address located on the invoice.  However, in the event the Parties agree in writing and where: (i) Participant provides evidence of coverage under third-party insurance or a government funded program (e.g., Medicare) (\"<b>Third Party Payor</b>\") prior to the provision of COVID Immunizations; and (ii) Walgreens is contracted with that Third Party Payor, Walgreens will submit the claim for applicable COVID Immunizations to such Third Party Payor. If evidence of coverage under a Third Party Payor is not provided at the time of service or a claim for reimbursement is denied by a Third Party Payor, the Parties agree that Walgreens may seek reimbursement from the Client.",
    "privateClinicFeesEN":"Private Clinic Fees.",
    "privateClinicFeesSP":"Private Clinic Fees.",
    "privateClinicFeesTextEN":" Where Client and Walgreens have agreed to hold Private Clinics, Walgreens will invoice Client for Private Clinic management fee(s) owed, in addition to any amounts agreed to in Section 3.1. Client hereby agrees to reimburse Walgreens these charges within thirty (30) days from receipt of the applicable monthly invoice at the remittance address located on the invoice.",
    "privateClinicFeesTextSP":" Where Client and Walgreens have agreed to hold Private Clinics, Walgreens will invoice Client for Private Clinic management fee(s) owed, in addition to any amounts agreed to in Section 3.1. Client hereby agrees to reimburse Walgreens these charges within thirty (30) days from receipt of the applicable monthly invoice at the remittance address located on the invoice.",
    "latePaymentEN":"Late Payment.",
    "latePaymentSP":"Late Payment.",
    "latePaymentTextEN":" Failure by Client to reimburse Walgreens by the due dates indicated on the invoices described herein, may subject Client to additional late payment interest charges, where allowed by law.",
    "latePaymentTextSP":" Failure by Client to reimburse Walgreens by the due dates indicated on the invoices described herein, may subject Client to additional late payment interest charges, where allowed by law.",
    "termAndTermCapsEN":"TERM AND TERMINATION",
    "termAndTermCapsSP":"DURACIÓN Y TERMINACIÓN",
    "termAndTermEN":"Term and Termination",
    "termAndTermSP":"Term and Termination",
    "termAndTermTextEN":" This Agreement will become effective on the Effective Date and shall continue in full force and effect for an initial term of one (1) year. Upon expiration of the initial term, this Agreement will automatically renew for successive one (1) year terms at the then current Walgreens rates in effect for COVID Immunizations, which will be made available upon request.  Either Party may terminate this Agreement at any time without cause by giving at least thirty (30) days’ prior written notice to the other Party.",
    "termAndTermTextSP":" This Agreement will become effective on the Effective Date and shall continue in full force and effect for an initial term of one (1) year. Upon expiration of the initial term, this Agreement will automatically renew for successive one (1) year terms at the then current Walgreens rates in effect for COVID Immunizations, which will be made available upon request.  Either Party may terminate this Agreement at any time without cause by giving at least thirty (30) days’ prior written notice to the other Party.",
    "effectAndTerminiationEN":"Effect of Termination",
    "effectAndTerminiationSP":"Effect of Termination",
    "effectAndTerminiationTextEN":" Termination will have no effect upon the rights or obligations of the Parties arising out of any transactions occurring prior to the effective date of such termination.",
    "effectAndTerminiationTextSP":" Termination will have no effect upon the rights or obligations of the Parties arising out of any transactions occurring prior to the effective date of such termination.",
    "waiverEN":"Waiver",
    "waiverSP":"Waiver",
    "waiverTextEN":" No waiver by either Party with respect to any breach or default of any right or remedy and no course of dealing may be deemed to constitute a continuous waiver of any other breach or default or of any other right or remedy unless such waiver is expressed in writing by the Party to be bound.",
    "waiverTextSP":" No waiver by either Party with respect to any breach or default of any right or remedy and no course of dealing may be deemed to constitute a continuous waiver of any other breach or default or of any other right or remedy unless such waiver is expressed in writing by the Party to be bound.",
    "insurAndIndemnifyEN":"INSURANCE AND INDEMNIFICATION ",
    "insurAndIndemnifySP":"INSURANCE AND INDEMNIFICATION ",
    "insuranceEN":"Insurance",
    "insuranceSP":"Insurance",
    "insuranceTextEN":" Each party will self-insure or maintain at its sole expense, and in amounts consistent with industry standards, insurance for general and professional liability and such other insurance as may be necessary to insure the party, its employees, and agents against any claim or claims for damages arising out of or in connection with its duties and obligations under this Agreement. Upon request from Walgreens, Client will provide a memorandum of insurance or certificate of insurance. Walgreens’ insurance information is available at www.walgreens.com/insurance.",
    "insuranceTextSP":" Each party will self-insure or maintain at its sole expense, and in amounts consistent with industry standards, insurance for general and professional liability and such other insurance as may be necessary to insure the party, its employees, and agents against any claim or claims for damages arising out of or in connection with its duties and obligations under this Agreement. Upon request from Walgreens, Client will provide a memorandum of insurance or certificate of insurance. Walgreens’ insurance information is available at www.walgreens.com/insurance.",
    "indemnificationEN":"Indemnification",
    "indemnificationSP":"Indemnification",
    "indemnificationTextEN":" To the extent permitted by law, each Party will indemnify, defend, and hold harmless the other Party, including its employees and agents, from and against any and all third-party claims or liabilities arising from the negligence or wrongful act of the indemnifying Party, its employees, or agents in carrying out its duties and obligations under the terms of this Agreement. EXCEPT FOR ANY EXPRESS WARRANTIES SET FORTH IN THIS AGREEMENT, THE PARTIES HEREBY DISCLAIM ANY IMPLIED WARRANTIES OF ANY KIND, INCLUDING WARRANTY OF MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE. In no event shall either Party be liable to the other Party for any indirect, special, or consequential damages or lost profits, arising out of or related to performance of this Agreement or a breach of this Agreement, even if advised of the possibility of such damages or lost profits. This Section will survive the termination of this Agreement. Notwithstanding the foregoing, the Parties agree that Walgreens is not liable for activities covered by the Public Readiness and Emergency Preparedness (PREP) Act, and the foregoing obligations of indemnity shall not apply to Walgreens for any claims or liabilities arising out of activities covered by the PREP Act and any other applicable laws related to vaccines and/or health care providers.",
    "indemnificationTextSP":" To the extent permitted by law, each Party will indemnify, defend, and hold harmless the other Party, including its employees and agents, from and against any and all third-party claims or liabilities arising from the negligence or wrongful act of the indemnifying Party, its employees, or agents in carrying out its duties and obligations under the terms of this Agreement. EXCEPT FOR ANY EXPRESS WARRANTIES SET FORTH IN THIS AGREEMENT, THE PARTIES HEREBY DISCLAIM ANY IMPLIED WARRANTIES OF ANY KIND, INCLUDING WARRANTY OF MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE. In no event shall either Party be liable to the other Party for any indirect, special, or consequential damages or lost profits, arising out of or related to performance of this Agreement or a breach of this Agreement, even if advised of the possibility of such damages or lost profits. This Section will survive the termination of this Agreement. Notwithstanding the foregoing, the Parties agree that Walgreens is not liable for activities covered by the Public Readiness and Emergency Preparedness (PREP) Act, and the foregoing obligations of indemnity shall not apply to Walgreens for any claims or liabilities arising out of activities covered by the PREP Act and any other applicable laws related to vaccines and/or health care providers.",
    "generalTermsEN":" GENERAL TERMS ",// deliberately extra space
    "generalTermsSP":" GENERAL TERMS ",
    "businessConfidentialityEN":"Business Confidentiality.",
    "businessConfidentialitySP":"Business Confidentiality.",
    "businessConfidentialityTextEN":" The Parties acknowledge that certain proprietary and/or technical and business information may be disclosed between the Parties (\"<b>Confidential Information</b>\").  Accordingly, each Party will maintain the confidentiality of all such Confidential Information, including, without limitation, implementing those precautions such Party employs with respect to its own proprietary and Confidential Information and disclosing Confidential Information only to those employees who have a need to know in order to effectuate the purpose(s) of this Agreement and to maintain compliance with applicable laws.  In no event will either Party use the other Party’s Confidential Information to benefit itself or others, except as otherwise not prohibited under this Agreement. Confidential Information shall not include information: (i) generally known to the public or the industry without breach of this Agreement; (ii) independently developed by the receiving Party; (iii) known to or in the possession of the receiving Party prior to the disclosure pursuant to this Agreement; (iv) disclosed to the receiving Party by a third party without the confidentiality obligations set forth herein; or (v) required to be disclosed by any court or government agency; provided however, to the extent allowed by law, the receiving Party shall provide written notice of such planned disclosure to the disclosing Party allowing reasonable time for the disclosing Party to raise any objections to such disclosure.  This Section will survive the termination of this Agreement.",
    "businessConfidentialityTextSP":" The Parties acknowledge that certain proprietary and/or technical and business information may be disclosed between the Parties (\"<b>Confidential Information</b>\").  Accordingly, each Party will maintain the confidentiality of all such Confidential Information, including, without limitation, implementing those precautions such Party employs with respect to its own proprietary and Confidential Information and disclosing Confidential Information only to those employees who have a need to know in order to effectuate the purpose(s) of this Agreement and to maintain compliance with applicable laws.  In no event will either Party use the other Party’s Confidential Information to benefit itself or others, except as otherwise not prohibited under this Agreement. Confidential Information shall not include information: (i) generally known to the public or the industry without breach of this Agreement; (ii) independently developed by the receiving Party; (iii) known to or in the possession of the receiving Party prior to the disclosure pursuant to this Agreement; (iv) disclosed to the receiving Party by a third party without the confidentiality obligations set forth herein; or (v) required to be disclosed by any court or government agency; provided however, to the extent allowed by law, the receiving Party shall provide written notice of such planned disclosure to the disclosing Party allowing reasonable time for the disclosing Party to raise any objections to such disclosure.  This Section will survive the termination of this Agreement.",
    "confidentialityEN":"Confidentiality of PHI",
    "confidentialitySP":"Confidencialidad de PHI",
    "confidentialityP1EN":" Both Parties warrant that they will maintain and protect the confidentiality of all individually identifiable health information specifically relating to Participants ",
    "confidentialityP1SP":" Both Parties warrant that they will maintain and protect the confidentiality of all individually identifiable health information specifically relating to Participants ",
    "phiEN":"(<b>\"Protected Health Information\"</b> or",
    "phiSP":"(\"Protected Health Information\" or",
    "phiAbEN":"\"PHI\"",
    "phiAbSP":"\"PHI\"",
    "confidentialityP2EN":" in accordance with the Health Insurance Portability and Accountability Act of 1996 and all applicable federal and state laws and regulations. However, nothing herein will limit either Party’s use of any de-identified Participant information that does not contain PHI. This Section will survive the termination of this Agreement.",
    "confidentialityP2SP":" in accordance with the Health Insurance Portability and Accountability Act of 1996 and all applicable federal and state laws and regulations. However, nothing herein will limit either Party’s use of any de-identified Participant information that does not contain PHI. This Section will survive the termination of this Agreement.",
    "AdvertisingEN":"Advertising",
    "AdvertisingSP":"Advertising",
    "AdvertisingTextEN":" Neither Party may advertise or use any trademarks, service marks, or symbols of the other Party without first receiving the written consent of the Party owning the mark and/or symbol.",
    "AdvertisingTextSP":" Neither Party may advertise or use any trademarks, service marks, or symbols of the other Party without first receiving the written consent of the Party owning the mark and/or symbol.",
    "frcMajEN":"Force Majeure",
    "frcMajSP":"Force Majeure",
    "frcMajTextEN":" The performance by either Party hereunder will be excused to the extent of circumstances beyond such Party’s reasonable control, such as flood, tornado, earthquake, or other natural disaster, epidemic, pandemic (except to the extent of such pandemic at the time of execution of the Agreement), war, material destruction of facilities, fire, acts of terrorism, acts of God, etc.  In such event, the Parties will use their best efforts to resume performance as soon as reasonably possible under the circumstances.",
    "frcMajTextSP":" The performance by either Party hereunder will be excused to the extent of circumstances beyond such Party’s reasonable control, such as flood, tornado, earthquake, or other natural disaster, epidemic, pandemic (except to the extent of such pandemic at the time of execution of the Agreement), war, material destruction of facilities, fire, acts of terrorism, acts of God, etc.  In such event, the Parties will use their best efforts to resume performance as soon as reasonably possible under the circumstances.",
    "complianceEN":"Compliance",
    "complianceSP":"Compliance",
    "complianceTextEN":" The Parties will comply with all applicable laws, rules, and regulations for each jurisdiction in which COVID Immunizations are provided under this Agreement.  Each Party will cooperate with reasonable requests by the other Party for information that is needed for its compliance with applicable laws, rules, and/or regulations.",
    "complianceTextSP":" The Parties will comply with all applicable laws, rules, and regulations for each jurisdiction in which COVID Immunizations are provided under this Agreement.  Each Party will cooperate with reasonable requests by the other Party for information that is needed for its compliance with applicable laws, rules, and/or regulations.",
    "assignmentEN":"Assignment",
    "assignmentSP":"Assignment",
    "assignmentTextEN":" Neither Party may assign this Agreement to a third-party without the prior written consent of the other Party, except that either Party will have the right to assign this Agreement to any direct or indirect parent, subsidiary or affiliated company or to a successor company without such consent. Any permitted assignee will assume all obligations of its assignor under this Agreement. No assignment will relieve any Party of responsibility for the performance of any obligations which have already occurred. This Agreement will inure to the benefit of and be binding upon each Party, its respective successors and permitted assignees.",
    "assignmentTextSP":" Neither Party may assign this Agreement to a third-party without the prior written consent of the other Party, except that either Party will have the right to assign this Agreement to any direct or indirect parent, subsidiary or affiliated company or to a successor company without such consent. Any permitted assignee will assume all obligations of its assignor under this Agreement. No assignment will relieve any Party of responsibility for the performance of any obligations which have already occurred. This Agreement will inure to the benefit of and be binding upon each Party, its respective successors and permitted assignees.",
    "noticesEN":"Notices",
    "noticesSP":"Notices",
    "noticesTextEN":" All notices provided for herein must be in writing, sent by U.S. certified mail, return receipt requested, postage prepaid, or by overnight delivery service providing proof of receipt to the address set forth following the signature blocks. Notices will be deemed delivered upon receipt or upon refusal to accept delivery.",
    "noticesTextSP":" All notices provided for herein must be in writing, sent by U.S. certified mail, return receipt requested, postage prepaid, or by overnight delivery service providing proof of receipt to the address set forth following the signature blocks. Notices will be deemed delivered upon receipt or upon refusal to accept delivery.",
    "entireAgrEN":"Entire Agreement and Pricing Updates",
    "entireAgrSP":"Entire Agreement and Pricing Updates",
    "entireAgrTextEN":" This Agreement, which includes any and all attachments, exhibits, riders, and other documents referenced herein, constitutes the entire and full agreement between the Parties relating to the subject matter herein and supersedes any previous contract, for which the signatories are authorized to sign for, and except as otherwise permitted hereunder, no changes, amendments, or alterations will be effective unless reduced to a writing signed by a representative of each Party. Any prior agreements, documents, understandings, or representations relating to the subject matter of this Agreement not expressly set forth herein or referred to or incorporated herein by reference are of no force or effect.  Notwithstanding the forgoing, as vaccines are developed or changed and necessitate an update to Attachment A, Walgreens will provide written notice to Client with the applicable changes and their effective date (\"<b>Update Notification</b>\").   If Client does not agree to the changes, within 15 days after its receipt of the Update Notification it must provide written notification to Walgreens at the addresses listed below, in which case such objection will be treated by Walgreens as Client’s intent to terminate the Agreement, and such termination will become effective upon receipt and processing by Walgreens. Client's failure to notify Walgreens of its objection to the Update Notification within the timeframe set forth above, shall be deemed Client's acceptance of the Update Notification, which shall be added to and made part of the Agreement as of the effective date set forth in such Update Notification.",
    "entireAgrTextSP":" This Agreement, which includes any and all attachments, exhibits, riders, and other documents referenced herein, constitutes the entire and full agreement between the Parties relating to the subject matter herein and supersedes any previous contract, for which the signatories are authorized to sign for, and except as otherwise permitted hereunder, no changes, amendments, or alterations will be effective unless reduced to a writing signed by a representative of each Party. Any prior agreements, documents, understandings, or representations relating to the subject matter of this Agreement not expressly set forth herein or referred to or incorporated herein by reference are of no force or effect.  Notwithstanding the forgoing, as vaccines are developed or changed and necessitate an update to Attachment A, Walgreens will provide written notice to Client with the applicable changes and their effective date (\"<b>Update Notification</b>\").   If Client does not agree to the changes, within 15 days after its receipt of the Update Notification it must provide written notification to Walgreens at the addresses listed below, in which case such objection will be treated by Walgreens as Client’s intent to terminate the Agreement, and such termination will become effective upon receipt and processing by Walgreens. Client's failure to notify Walgreens of its objection to the Update Notification within the timeframe set forth above, shall be deemed Client's acceptance of the Update Notification, which shall be added to and made part of the Agreement as of the effective date set forth in such Update Notification.",
    "counterPartsHeadingEN":"Counterparts",
    "counterPartsHeadingSP":"Counterparts",
    "counterPartsTextEN":" This Agreement may be executed in two or more counterparts, each of which together shall be deemed an original, but all of which together shall constitute one and the same instrument, provided that each Party has received the other Party’s executed instruments.  In the event that any signature is delivered by facsimile transmission or by e-mail delivery of a \".pdf\" format data file (or similar format however designated), such signature shall create a valid and binding obligation of the Party executing (or on whose behalf such signature is executed) with the same force and effect as if such facsimile or \".pdf\" signature page were an original thereof.",
    "counterPartsTextSP":" This Agreement may be executed in two or more counterparts, each of which together shall be deemed an original, but all of which together shall constitute one and the same instrument, provided that each Party has received the other Party’s executed instruments.  In the event that any signature is delivered by facsimile transmission or by e-mail delivery of a \".pdf\" format data file (or similar format however designated), such signature shall create a valid and binding obligation of the Party executing (or on whose behalf such signature is executed) with the same force and effect as if such facsimile or \".pdf\" signature page were an original thereof.",
    "rightsEN":"All rights reserved.",
    "rightsSP":"Todos los derechos reservados.",
    "approveAgrmntEN":"Approve Agreement",
    "approveAgrmntSP":"Aprobar el acuerdo",
    "approveTextEN":"Carefully review the Community Off-Site Agreement. If you agree to the conditions of the contract, please check 'Approve' below and type your name into the Electronic Signature field. If there are any discrepancies in the Agreement, reject the Agreement and provide corrections in the notes field.",
    "approveTextSP":"Examine cuidadosamente el Acuerdo para Clínica Externa en la Comunidad. Si está de acuerdo con las condiciones del contrato, favor de marcar 'Aprobado' adelante y entre su nombre en el campo Firma Electrónica. Si hubiese alguna discrepancia, rechace el Acuerdo y provea las correcciones en el campo Apuntes.",
    "electrSignEN":"Electronic Signature",
    "electrSignSP":"Firma Electrónica",
    "notesEN":"Notes:",
    "notesSP":"Notas:",
    "pageTitleEN":"",
    "pageTitleSP":"CONTRATO DE CLÍNICA AMBULATORIA COMUNITARIA",
    "dateEN":"Date:",
    "dateSP":"FECHA",
    "districtEN":"District#:",
    "districtSP":"DISTRITO#:",
    "approveEN":"Approve",
    "approveSP":"Aprobar",
    "rejectEN":"Request Changes",
    "rejectSP":"Solicitar cambios",
    "submitEN":"Submit",
    "submitSP":"Enviar",
    "cancelEN":"Cancel",
    "cancelSP":"Cancelar",
    "printAgreementEN":"Print Agreement",
    "printAgreementSP":"Acuerdo de impresión",
    "clinicLocationEN":"Clinic:",
    "clinicLocationSP":"Ubicación de la clínica:",
    "clinicDateEN":"Clinic Date:",
    "clinicDateSP":"Fecha de la clínica:",
    "clinicTimeEN":"Clinic Time:",
    "clinicTimeSP":"Tiempo de la clínica:",
    "contactEN":"Contact:",
    "contactSP":"Contacto:",
    "phoneEN":"Phone:",
    "phoneSP":"Teléfono:",
    "estShotsEN":"Est. Shots",
    "estShotsSP":"Est. Shots",
    "locationEN":"Location:",
    "locationSP":"Ubicación:",
    "timeEN":"Time:",
    "timeSP":"Hora:",
    "emailEN":"Email:",
    "emailSP":"Email:",
    "emailAgreementToEN":"Email Agreement to:",
    "emailAgreementToSP":"Acuerdo de correo electrónico a:",
    "multipleEmailAddressEN":'Multiple email addresses may be entered by placing a comma between addresses.',
    "multipleEmailAddressSP":'Se pueden ingresar varias direcciones de correo electrónico colocando una coma entre las direcciones.',
    "sendAgreementEN":"SEND AGREEMENT",
    "sendAgreementSP":'ENVIAR ACUERDO',
    "editAgreementEN":'EDIT AGREEMENT',
    "editAgreementSP":'EDITAR ACUERDO',
    "saveAgreementEN":"SAVE AGREEMENT",
    "saveAgreementSP":"GUARDAR ACUERDO",
    "noteTextEN":"* Please note, once a contract has been signed by the client, it cannot be edited.",
    "noteTextSP":"* Tenga en cuenta que una vez que el cliente ha firmado un contrato, no se puede editar.",
    "printAgreementTxtCapsEN":"PRINT AGREEMENT",
    "printAgreementTxtCapsSP":"ACUERDO IMPRESO",
    "table1EN":'Table 1',
    "table1SP":'Tabla 1',
    "attnEN":"Attn",
    "fluExpiryDateEN":"Flu Exp. Date:",
    "fluExpiryDateSP":"Fecha de vencimiento de la gripe:",
    "routineExpiryDateEN":"Routine Exp. Date:",
    "routineExpiryDateSP":"Fecha de vencimiento de rutina:",
    "sendInvoiceToEN":'Send Invoice to:',
    "sendInvoiceToSP":'Enviar factura a:',
    'isTaxExemptEN':'Is Employer Tax Exempt?',
    'isTaxExemptSP':'¿El empleador está exento de impuestos?',
    'isCopayEN':'Will patient pay the portion of the cost. Is there a copay?',
    'isCopaySP':'Pagará el paciente la parte del costo. ¿Hay un copago?',
    'voucherNeededEN':'Voucher Needed:',
    'voucherNeededSP':'Vale necesario:',
    'voucherExpiryEN':'Expiration Date:',
    'voucherExpirySP':'Fecha de caducidad:',
    'copayValueEN':'Copay Value:',
    'copayValueSP':'Valor de copago:',
    'influenzaSeasonText1EN':'per influenza season (August – April)',
    'influenzaSeasonText1SP':'per influenza season (August – April)',
    'contractYearText1EN':'per contract year',
    'contractYearText1SP':'per contract year',
    'influenzaSeasonText2EN':'influenza season',
    'influenzaSeasonText2SP':'influenza season',
    'contractYearText2EN':'contract year',
    'contractYearText2SP':'contract year',
    'contactName':'Contact Name:',
    'contactPhone':'Contact Phone:',
    'contactEmail':'Contact Email:',
    'contactNameSP':'Contact Name:',
    'contactPhoneSP':'Contact Phone:',
    'contactEmailSP':'Contact Email:',
    'doseEN':'COVID Clinic:',
    'doseSP':'COVID Clinic:',
    'indemnificationTAndCChangedDate':'12/28/2020',
    'confidentialityTextEN':'',
    'confidentialityTextSP':''
  }