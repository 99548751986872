import { Component, OnInit } from '@angular/core';
import {  FormGroup,  FormBuilder, Validators } from '@angular/forms';
import { UseraccessService } from '../../services/useraccess.service';
import { UserAccessLog } from '../../../../models/UserAccessLog';
import * as appReportSettings from '../../globals';
import { Router } from '../../../../../../node_modules/@angular/router';
import { ApplicationSettings } from '../../../common/application-settings';
import { saveAs as importedSaveAs } from "file-saver";
import { SessionDetails } from '../../../../utility/session';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-useraccesslog',
  templateUrl: './useraccesslog.component.html',
  styleUrls: ['./useraccesslog.component.css']
})
export class UseraccesslogComponent implements OnInit {
  ddlFormat: any;
  loading:boolean=true;
  userAccessLogForm: FormGroup;
  userAccessLogArray:any=[];
  totalRecords:number;
  ddlSeason:number;
  seasons:any=[];
  outreachStartDate=ApplicationSettings.outreachStartDateIP;
  ddlDistrict:number;
  districts:any=[];
  canExport:any;
  isAdmin:boolean=false;

  userProfile:any;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";

  showGrid:boolean = false;

  constructor(private fb: FormBuilder,private user_access_log:UseraccessService,private router:Router, private spinner: NgxSpinnerService) { 
    this.userProfile = SessionDetails.GetUserProfile();
  }

  ngOnInit() {
    this.ddlFormat="xlsx";    
    this.ddlSeason = (new Date(this.outreachStartDate)).getFullYear();     
    this.setFormControls();  
    for (let report_year: number = parseInt(appReportSettings.reportStartYear); report_year <= new Date(this.outreachStartDate).getFullYear(); report_year++) {
      this.seasons.push({
        "yearName": report_year.toString() + " - " + (report_year + 1).toString(),
        "yearCode": report_year.toString()
      });
    }
    this.user_access_log.getDistricts().subscribe((districtsarray: any) => {
      
      this.districts = districtsarray;         
      if (this.userProfile.userRole.toLowerCase() == "admin" ){
        this.isAdmin=true;
        this.ddlDistrict=0;
        this.ddlDistrict.toString();
        this.setFormControls();
     }
     else{
        this.setFormControls();
        this.isAdmin=false;
		   this.ddlDistrict=this.districts[0].districtID;this.ddlDistrict.toString();
        this.userAccessLogForm.controls['ddlDistrict'].setValue(this.districts[0].districtID);
		
     }      
  
    //this.generateReport();
    });
  }
  setFormControls() {
   
    this.userAccessLogForm = this.fb.group({
      'ddlDistrict':['0'],
      'ddlSeason':[this.ddlSeason],
             'ddlFormat': [this.ddlFormat]
      });
     //this.generateReport();
   }
  generateReport(): void {
    
    if(!this.showGrid){
      this.spinner.show();
    }else{
      this.loading = true;
    }
    
    let data= this.userAccessLogForm.value;
    let ual: UserAccessLog;
    ual = new UserAccessLog(this.ddlDistrict.toString(),data.ddlSeason.toString(), "", "");
    
      this.user_access_log.getUserAccessLog(ual).subscribe((resultArray: any) => {
        this.totalRecords = resultArray.length;
        this.userAccessLogArray = resultArray;
     
        setTimeout(() => {
          this.spinner.hide();
          this.loading = false;
          this.showGrid = true;
          this.canExport = true;
        }, 1000)
      }  ,error =>{
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
        this.spinner.hide();
        this.userAccessLogArray = [];
        this.totalRecords = 0;
      });
      this.ddlFormat="xlsx";
  }

  generatereportformat(): void {
    this.loading = true;
    let data= this.userAccessLogForm.value;
    let ual: UserAccessLog;
    ual = new UserAccessLog(this.ddlDistrict.toString(),this.ddlSeason.toString(), "UserAccessLog", "xlsxv");
    this.user_access_log.exportReportData(ual).subscribe((res: any) => {      
      importedSaveAs(res.body, res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1]);
      this.loading=false;
      this.canExport=true;
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
    this.ddlFormat="xlsx";
  }

  export(x){
    
    this.ddlDistrict= x.target.value;
    
  }
  canExportOnchange(){
    this.canExport=false;
    //this.generateReport();
  }
  // validateExportType(){
  //   if(this.ddlFormat=="0" || !this.canExport)
  //   return true;
  //   else
  //    return false;
  // }

  reset(){
    this.showGrid=false;
    this.loading = false;
    this.spinner.hide();
    this.ddlFormat="0";    
    this.ddlSeason = (new Date(this.outreachStartDate)).getFullYear();
    this.ddlDistrict = (this.isAdmin)? '0' : this.districts[0].districtID;
    this.setFormControls();
  }
    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}
