export class AddBusiness {
                 public businessId:number = -1;
                 public BusinessName:string = '';
                 public Phone:string = '';
                 public FirstName:string = '';
                 public LastName:string = '';
                 public EMail:string = '';
                 public TollFree:string = '';
                 public Industry:string = '';
                 public JobTitle: string = '';
                 public WebsiteURL:string ='';
                 public Address1:string ='';
                 public EmploymentSize:number=0;
                 public Address2:string ='';
                 public CharityHHSVoucher:boolean=false;
                 public CommunityOutreach:boolean=false;
                 public City:string='';
                 public ZipCode:string = '';
                 public ZipCode4:string = '';
                 public state:string = '';
                 public County:string="";
                 public SeniorOutreach:boolean=false;
                 public ImmunizationProgram:boolean=false;
                 public BusinessSelected:string;
                 public BusinessInfoSelected:number=1;
                 public SeniorOutReachStore:string;
                 public ImmunizationProgramStore:string;
                 public ContractInitiated:boolean = false;
                 public ContractRejected:boolean = false;
                 public ContractSent:boolean = false;
                 public ScheduledEvent:boolean = false;
                 public addressmatchkey:string ="";
    constructor( ){}
    }