import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Util } from '../../../../../utility/util';
import { ImmunizationCheck } from '../../../../../models/Immunization-check';
import { ImmunizationCheckService } from '../../../services/immunization-check.service';
import { SessionDetails } from '../../../../../utility/session';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-add-immunization-check',
  templateUrl: './add-edit-immunization-check.component.html',
  styleUrls: ['./add-edit-immunization-check.component.css'],
  providers: [ImmunizationCheckService]
})
export class AddEditImmunizationCheckComponent implements OnInit {

  dropDownList: any[];
  isEdit: boolean = false;
  errorShow: boolean = false;
  successImmunizationCheck: boolean = false;
  addImmunizationCheck: FormGroup;
  immunizationCheck: ImmunizationCheck;
  immunizationPk: number;
  buttonName: string = 'Save';
  successMessage: string;
  errorMessageShow: string;
  headerMessage: string = 'Success';
  overrideAgreements: boolean = false;
  overrideAgreementMessage: string;

  constructor(private formBuilder: FormBuilder, private utility: Util, private immunzationService: ImmunizationCheckService, private router: Router, private spinner: NgxSpinnerService) {
    this.isEdit = false;
    this.buttonName = 'Save';
  }

  ngOnInit() {
    this.addImmunizationCheck = this.formBuilder.group({
      ImmunizationName: [null, Validators.required],
      SpanishName: [null, ''],
      Price: [null, Validators.required],
      DirectBillPrice: [null, ''],
      DirectBillVoucherPrice: [null, ''],
      ClinicType: ['', Validators.required],
      ImmunizationAbvEN: [null, ''],
      ImmunizationAbvSP: [null, ''],
      IsActive: [true, '']
      //,IsCurrentSeason: [false, '']
      //,IsBlockImmunization: [false, '']
    });
    this.getClinicTypes();
    this.immunizationPk = SessionDetails.GetImmunizationCheckPK();
    if (this.immunizationPk && this.immunizationPk > 0) {
      this.isEdit = true;
      this.getImmunizationCheckData(this.immunizationPk);
      this.buttonName = 'Update';
    }
  }

  isFieldValid(field: string) {
    return !this.addImmunizationCheck.get(field).valid && this.addImmunizationCheck.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  Cancel() {
    this.router.navigate(['/communityoutreach/immunizationcheck']);
    //navigation and clear
  }
  onAddEditImmunizationSubmit() {
    if (this.isEdit)
      this.EditImmunization();
    else
      this.AddImmunization();
  }
  EditImmunization() {
    if (!this.addImmunizationCheck.valid) {
      this.utility.validateAllFormFields(this.addImmunizationCheck);
      return;
    }
    else {
      //manual validations
    }
    //other validations

    //
    this.spinner.show();
    this.AssignFieldValues();
    this.immunzationService.updateImmunizationCheck(this.immunizationCheck).subscribe((res:any) => {
      this.spinner.hide();
      if (res.errorS !== null && res.errorS.errorCode === -1) {
        this.errorShow = true;
        this.errorMessageShow = res.errorS.errorMessage;
      }
      else if (res.conflictAgreements && res.conflictAgreements.length > 0) {
        this.overrideAgreements = true;
        this.overrideAgreementMessage = "Are you sure you want to continue? Revising the immunizations and rates will update the affected immunizations and rates in all agreements that have not been approved.";
      }
      else {
        this.successImmunizationCheck = true;
        if (res.clinicType === 3) {
          this.headerMessage = 'Reminder Alert';
          this.successMessage = 'Immunization updated successfully.<br/><br/> When Corporate Immunizations are modified update the Corporate Upload spreadsheet template and provide it to the Walgreens Client Services team.'
        }
        else {
          this.headerMessage = 'Success'
          this.successMessage = 'Immunization updated successfully';
        }
      }

    }, error => {
      this.spinner.hide();
      console.log("TODO");
      switch (error.status) {
        case 500:
          alert('ErrorCode 500 received from Service');
          break;
        case 400:
          alert('Unhandled exception error code received from Service');
          break;
        default:
          alert('Unhandled exception error code received from Service');
          break;
      }
    })
  }
  AddImmunization() {
    if (!this.addImmunizationCheck.valid) {
      this.utility.validateAllFormFields(this.addImmunizationCheck);
      return;
    }
    else {
      //manual validations
    }
    //other validations

    //
    //add and save the data
    this.immunizationCheck = new ImmunizationCheck();
    this.AssignFieldValues();
    this.spinner.show();
    this.immunzationService.createImmunizationCheck(this.immunizationCheck).subscribe((res:any) => {
      this.spinner.hide();
      if (res.errorS !== null && res.errorS.errorCode === -1) {
        this.errorShow = true;
        this.errorMessageShow = res.errorS.errorMessage;
      }
      else {
        this.successImmunizationCheck = true;
        if (res.clinicType === 3) {
          this.headerMessage = 'Reminder Alert';
          this.successMessage = 'Immunization created successfully.<br/><br/> When Corporate Immunizations are modified update the Corporate Upload spreadsheet template and provide it to the Walgreens Client Services team.'
        }
        else {
          this.headerMessage = 'Success'
          this.successMessage = 'Immunization created successfully';
        }
      }
    },
      error => {
        this.spinner.hide();
        switch (error) {
          case 500:
            alert('ErrorCode 500 received from Service');
            break;
          case 400:
            alert('Unhandled exception error code received from Service');
            break;
          default:
            alert('Unhandled exception error code received from Service');
            break;
        }
      })
  }

  AssignFieldValues() {
    this.immunizationCheck.immunizationName = this.addImmunizationCheck.controls['ImmunizationName'].value;
    this.immunizationCheck.spanishName = this.addImmunizationCheck.controls['SpanishName'].value;
    this.immunizationCheck.price = this.addImmunizationCheck.controls['Price'].value;
    this.immunizationCheck.directBillPrice = this.addImmunizationCheck.controls['DirectBillPrice'].value;
    this.immunizationCheck.directBillVoucherPrice = this.addImmunizationCheck.controls['DirectBillVoucherPrice'].value;
    this.immunizationCheck.clinicType = this.addImmunizationCheck.controls['ClinicType'].value;
    this.immunizationCheck.immunizationAbvEN = this.addImmunizationCheck.controls['ImmunizationAbvEN'].value;
    this.immunizationCheck.immunizationAbvSP = this.addImmunizationCheck.controls['ImmunizationAbvSP'].value;
    this.immunizationCheck.isActive = this.addImmunizationCheck.controls['IsActive'].value;
    //this.immunizationCheck.isCurrentSeason = this.addImmunizationCheck.controls['IsCurrentSeason'].value;
    //this.immunizationCheck.isBlockImmunization = this.addImmunizationCheck.controls['IsBlockImmunization'].value;
  }
  getImmunizationCheckData(pk) {
    this.spinner.show();
    this.immunzationService.getImmunizationCheckByPk(pk).subscribe((res:any) => {
      this.immunizationCheck = res;
      let edit_immunization = this.immunizationCheck;
      this.addImmunizationCheck.controls['ImmunizationName'].setValue(edit_immunization.immunizationName);
      this.addImmunizationCheck.controls['SpanishName'].setValue(edit_immunization.spanishName);
      this.addImmunizationCheck.controls['Price'].setValue(edit_immunization.price.toFixed(2));
      if(edit_immunization.directBillPrice)
      this.addImmunizationCheck.controls['DirectBillPrice'].setValue(edit_immunization.directBillPrice.toFixed(2));
        
      if(edit_immunization.directBillVoucherPrice)
        this.addImmunizationCheck.controls['DirectBillVoucherPrice'].setValue(edit_immunization.directBillVoucherPrice.toFixed(2));
      this.addImmunizationCheck.controls['ClinicType'].setValue(edit_immunization.clinicType);
      this.addImmunizationCheck.controls['ImmunizationAbvEN'].setValue(edit_immunization.immunizationAbvEN);
      this.addImmunizationCheck.controls['ImmunizationAbvSP'].setValue(edit_immunization.immunizationAbvSP);
      this.addImmunizationCheck.controls['IsActive'].setValue(edit_immunization.isActive);
      //this.addImmunizationCheck.controls['IsCurrentSeason'].setValue(edit_immunization.isCurrentSeason);
      //this.addImmunizationCheck.controls['IsBlockImmunization'].setValue(edit_immunization.isBlockImmunization);
      this.spinner.hide();
    },
      err => {
        this.spinner.hide();
        console.log("TODO");
        switch (err.status) {
          case 500:
            alert('ErrorCode 500 received from Service');
            break;
          case 400:
            alert('Unhandled exception error code received from Service');
            break;
          default:
            alert('Unhandled exception error code received from Service');
            break;
        }
      })
  }
  getClinicTypes() {
    
    this.spinner.show();
    this.immunzationService.getAllClinicTypes().subscribe((res:any) => {
      this.dropDownList = [];
      this.dropDownList = res;
      this.spinner.hide();

    },
      err => {
        this.spinner.hide();
        switch (err) {
          case 500:
            alert('ErrorCode 500 received from Service');
            break;
          case 400:
            alert('Unhandled exception error code received from Service');
            break;
          default:
            alert('Unhandled exception error code received from Service');
            break;
        }
      });
  }
  successOkClick() {
    this.successImmunizationCheck = false;
    this.router.navigate(['/communityoutreach/immunizationcheck']);
  }
  continueAgreementClick() {
    this.overrideAgreements = false;
    this.immunizationCheck.allowOverride = true;
    this.EditImmunization();
  }
  formatPrice(e,item:string){
    this.addImmunizationCheck.controls[item].setValue(this.utility.transformtoFixed(e));
  }
}
