import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Util } from '../../../../../utility/util';

@Component({
  selector: 'app-updatestoreprofile',
  templateUrl: './updatestoreprofile.component.html',
  styleUrls: ['./updatestoreprofile.component.css']
})
export class UpdatestoreprofileComponent implements OnInit {

  private group;
  storeUpdateFormGroup: FormGroup;

  private updateFormControls: any = ["storeId", "address", "city", "state", "zip", "areaNumber", "areaName", "districtNumber", "districtName", "regionNumber", "regionName"];

  uploadedFileKey: string = "";
  uploadMode: number = -1;
  selectedStore: any = {};

  @Output() updateProfile = new EventEmitter<any>();
  @Output() cancelUpdateProfile = new EventEmitter<any>();

  canCancelUpdate: boolean = false;
  canUpdateStore: boolean = true;
  dialogMessage: string = "";
  showInfoMsg: boolean = false;
  constructor(private formBuilder: FormBuilder, private utility: Util) {
  
  }

  ngOnInit() {
    this.group = this.formBuilder.group({});
    this.createForm();
    this.storeUpdateFormGroup = this.group;
  }

  createForm() {
    this.updateFormControls.forEach(control => {
      switch (control.toLowerCase()) {
        case "storeid": 
        case "regionnumber":
        case "districtnumber":
        case "areanumber":
          let number_pattern = /^[0-9\b]+$/; //numbers without blanks
          this.group.addControl(control, this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(number_pattern)])));
          break;
        case "address":
         let addr_pattern = /^[^'"]{1,500}$/;
          this.group.addControl(control, this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(addr_pattern)])));
          break;
        case "city":
        case "districtname":
        case "regionname":
        case "areaname":
          let name_pattern = /^[^'"0-9]{1,500}$/;
          this.group.addControl(control, this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(name_pattern)])));
          break;
        case "state":
          let state_pattern = /^[a-zA-Z]{2}$/;
          this.group.addControl(control, this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(state_pattern)])));
          break;
        case "zip":
          let zip_pattern = /^[0-9]{3,5}$/
          this.group.addControl(control, this.formBuilder.control('', Validators.compose([Validators.required, Validators.pattern(zip_pattern)])));
          break;
      }
    });

  }

  cancelUpdate() {
    this.canCancelUpdate = true;
    this.cancelUpdateProfile.emit();
  }
  updateStore() {
    this.utility.validateAllFormFields(this.storeUpdateFormGroup);
    if (this.storeUpdateFormGroup.valid) {
      this.canUpdateStore = true;
      this.updateProfile.emit(this.selectedStore);
    }
  }
  displayColMDCss(field: string) {
    let return_value: boolean = false;
    if (this.storeUpdateFormGroup.get(field))
      return_value = this.storeUpdateFormGroup.get(field) && !this.storeUpdateFormGroup.get(field).valid && this.storeUpdateFormGroup.get(field).touched;

    return {
      'has-error': return_value,
      'has-feedback': return_value
    };
  }
}
