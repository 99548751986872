import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { ImmunizationComponent } from './components/immunization/immunization.component';
import { SenioroutreachComponent } from './components/senioroutreach/senioroutreach.component';
import { TrainingvideosComponent } from './components/trainingvideos/trainingvideos.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { PcaresourcesComponent } from './components/pcaresources/pcaresources.component';
import { ImmunizationProgramEmailTemplateComponent } from './components/immunization-program-email-template/immunization-program-email-template.component';
import { SeniorOutreachEmailTemplateComponent } from './components/senior-outreach-email-template/senior-outreach-email-template.component';
import { PatientCareAdvocacyEmailTemplateComponent } from './components/patient-care-advocacy-email-template/patient-care-advocacy-email-template.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { RouterModule } from '@angular/router';
import { VisComponent } from './components/vis/vis.component';
import { VissComponent } from './components/viss/viss.component';
import { ResourcecountService } from './services/resourcecount.service';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    RouterModule,
    NgxSpinnerModule
  ],
  declarations: [
    ImmunizationComponent, 
    SenioroutreachComponent, 
    TrainingvideosComponent, 
    VideoPlayerComponent, 
    PcaresourcesComponent, 
    ImmunizationProgramEmailTemplateComponent, 
    SeniorOutreachEmailTemplateComponent, 
    PatientCareAdvocacyEmailTemplateComponent, 
    FavoritesComponent, 
    VisComponent, 
    VissComponent,
  ],
  providers: [
   ResourcecountService,
]
})
export class ResourcesModule { }
