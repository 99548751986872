import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { GroupiduploaderService } from '../../services/groupiduploader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationSettings } from '../../../common/application-settings';

@Component({
  selector: 'app-groupidsuploader',
  templateUrl: './groupidsuploader.component.html',
  styleUrls: ['./groupidsuploader.component.css'],
  providers: [GroupiduploaderService]
})
export class GroupidsuploaderComponent implements OnInit {
  fileExt: string[] = ["XLSX", "XLS", "XLSM"];
  type: any;
  message: string;
  ipSeason: number;
  showMessage: boolean = false;
  showMessageAfter: boolean = false;
  uploadedFileName: any;
  files: any;
  isArchive: boolean = true;
  groupIdUploaderForm: FormGroup;
  submitted: boolean = false;
  fileError: boolean = false;
  color: any;
  isContinue: boolean = false;
  status: string;
  showPopUp: boolean = false;
  beforeFluCount: number = 0;
  beforeRoutineCount: number = 0;
  afterFluCount: number = 0;
  afterRoutineCount: number = 0;
  beforeFluVoucherCount : number =0;
  afterFluVoucherCount : number = 0;
  beforeRoutineVoucherCount: number = 0;
  afterRoutineVoucherCount: number = 0;

  constructor(private groupiduploaderService: GroupiduploaderService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.groupIdUploaderForm = this.formBuilder.group({
      type: ['', Validators.required],
      inputFile: [null, Validators.required],
      isArchive: [false]
    });
    this.getGroupIdsCount(false);
  }
  getGroupIdsCount(uploaded: boolean) {
    this.groupiduploaderService.getGroupIdsCount().subscribe((data:any) => {
      if (data.length > 0) {
        data.forEach(element => {
          if (element.description == 'Flu') {
            if (!uploaded) {
              this.beforeFluCount = element.unusedGroupIdsCount;
            } else {
              this.afterFluCount = element.unusedGroupIdsCount;
            }
          }
          else if(element.description == 'FLU Vouchers'){
            if (!uploaded) {
              this.beforeFluVoucherCount = element.unusedGroupIdsCount;
            } else {
              this.afterFluVoucherCount = element.unusedGroupIdsCount;
            }
          }
          else if(element.description == 'Routine Vouchers'){
            if (!uploaded) {
              this.beforeRoutineVoucherCount = element.unusedGroupIdsCount;
            } else {
              this.afterRoutineVoucherCount = element.unusedGroupIdsCount;
            }
          }
          else {
            if (!uploaded) {
              this.beforeRoutineCount = element.unusedGroupIdsCount;
            } else {
              this.afterRoutineCount = element.unusedGroupIdsCount;
            }
          }
        });
      }
    });
  }
  cancelArchive() {
    this.showPopUp = false;
    this.isContinue = false;
  }
  archiveGroupIds() {
    this.showPopUp = false;
    this.isContinue = true;
    this.onSubmit();
  }
  onFileChange(event) {
    this.files = event.target.files;
    this.uploadedFileName = event.target.files[0].name;
    this.isValidFileExtension(this.files);
  }
  get f() { return this.groupIdUploaderForm.controls; }
  onSubmit(): void {
    this.submitted = true;
    this.type = this.groupIdUploaderForm.value.type;
    this.isArchive = this.groupIdUploaderForm.value.isArchive;
    if (this.groupIdUploaderForm.invalid) {
      return;
    }
    else if (this.fileError) {
      return;
    }
    else if (!this.isContinue) {
      this.showPopUp = true;
      this.message = this.isArchive ? "Are you sure you want to archive unassigned Group IDs? Uploaded Group IDs will replace all unassigned Group IDs." :
        "Are you sure you want to proceed without archiving unassigned Group IDs? Uploaded Group IDs will be appended to the unassigned Group IDs.";
      return;
    }
    else {
      this.isContinue = false;
      let cur_date = new Date();
      let s_date = new Date(ApplicationSettings.getIMmunizationStartDate(cur_date.getFullYear()));
      this.ipSeason = s_date.getFullYear();
      if (this.files.length > 0) {
        let formData: FormData = new FormData();
        for (var j = 0; j < this.files.length; j++) {
          formData.append("file", this.files[j]);
          this.spinner.show();
          this.groupiduploaderService.upload(formData, this.groupIdUploaderForm.value, this.ipSeason).subscribe((data:any) => {
            this.color = "red";
            this.showMessage = true;
            this.showMessageAfter = false;
            this.spinner.hide();
            this.uploadedFileName = '';
            if (data == "success") {
              this.showMessageAfter = true;
              this.getGroupIdsCount(true);
              this.color = "black";
              this.status = this.type + " Group IDs uploaded successfully.";
            }
            else if (data == "-1") {
              this.status = this.type + " type does not exist in database.";
            }
            else if (data == "-2") {
              this.status = "The upload process failed. \"GROUP ID\" coloumn is MISSING from the worksheet that's been uploaded.";
            }
            else if (data == "-3") {
              this.status = "The upload process failed. The spreadsheet does not contain valid rows.";
            }
            else if (data == "-4") {
              this.status = "The upload process failed. The spreadsheet does not contain a data sheet named \"" + this.type + "\".";
            }
            else if (data == "-5") {
              this.status = this.type + " Group IDs already exists in database.";
            }
            else {
              this.status = data;
            }
          },
            error => {
              if (error.indexOf("417") > -1) {
                this.spinner.hide();
                //this.status = this.type + " Group IDs upload Failed.";
                this.status = "HTTP/1.1 417 Expectation Failed";
              }
            })
        }
      }
    }
  }
  private isValidFileExtension(files) {
    for (var i = 0; i < files.length; i++) {
      // Get file extension
      var ext = files[i].name.toLocaleUpperCase().split('.').pop() || files[i].name;

      // Check the extension exists
      var exists = this.fileExt.filter(element => element == ext);

      if (exists.length <= 0) {
        this.fileError = true;
        return false;
      }
    }
    this.fileError = false;
    return true;
  }
}
