import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserauthenticationComponent } from './components/userauthentication/userauthentication.component';
import { ReusableModule } from '../common/components/reusable.module';
import { LoginComponent } from './components/login/login.component';
import { FormsModule }   from '@angular/forms';
import { SignoutComponent } from './components/signout/signout.component';
import { DialogModule } from 'primeng/dialog';
import { EncryptLinkService } from './services/encrypt-link.service';
import { UnauthorizeComponent } from './components/unauthorize/unauthorize.component';
import { LandingComponent } from './components/landing/landing.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    ReusableModule,
    FormsModule,
    DialogModule,NgxSpinnerModule,TableModule
  ],
  declarations: [UserauthenticationComponent,UnauthorizeComponent, LoginComponent, SignoutComponent, UnauthorizeComponent,LandingComponent],
  providers:[EncryptLinkService]
})
export class AuthModule { }
