
export class AddOutreachOpportunity {    
    addressMatchKey: string;  
    totalS: number;
    businessPk: number;
    outreachBusinessPk:number;
    businessName: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    phone: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    tollFree: string;
    ext: string;
    alternateContactExt: string;
    industry: string;
    others: string;
    employmentSize: number;
    residents: number;
    isCheckDnc:any;
    isScheduledClinics:any;
    isOverrideDupCheck:any;
    isRemoveContactLog:any;
    canReassign:any;
    organizationtype?:number;
    isStandardized:boolean;
    isCovidOpportunity:boolean;
    isFluOpportunity:boolean;
    isNoContractOpportunity:boolean;
    privateClinicFees:string;
    outreachEffortList: OutreachEffortList[];
    errorS: ErrorS;
    pkhash:string;
    covidOpportunityType: number;
    fluOpportunityType:number;
    targetPopulation: string;
    communityPartner?: boolean;
    communityPartnerName: string;
    healthDeptInvolved: string;
    opportunityHostLocation: string;
    vaccineInventorySource: string;
    covidClinicType:string;
    notes:string;
    LTCFType:string;
    ccn:string;
    alternateFirstName:string;
    alternateLastName:string;
    alternatePhone:string;
    alternateJobTitle:string;
    alternateEMail:string;

    constructor() {
        this.outreachEffortList = new Array<OutreachEffortList>();
        this.organizationtype = null;
        this.isStandardized = false;
        this.isCovidOpportunity = null;
        this.isFluOpportunity = null;
        this.privateClinicFees = "8.50";
        this.notes="";
    }
}


export class OutreachEffortList {
    outreachProgram: string;
    outreachBusinessPk: number;
    storeId: number;
    storePk: number;
    isCharityInitiated: any;
    isCommunityInitiated: any;
    existingStoreId:number;
    isChecked:any;
}
export class ErrorS {
    errorCode: number;
    errorMessage: string;
}

export class SelectType{
    id: number;
    value: string;
}