import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class DashboardServiceService {

  constructor(private http: HttpClient) { }

  getClosestStore(user_pk: number, district_id: number, business_pk: number,is_clinic:boolean,is_reassign_to_hcs:boolean) {
    let url = environment.API_URL + environment.RESOURCES.GET_CLOSEST_STORE + "/" + user_pk + "/" + business_pk  + "/" + is_clinic + "/" + is_reassign_to_hcs + "/"+ district_id ;
    return this.http.get(url, Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }
  updateClinicAssignment(clinic_assignment_model,is_override?:boolean) {
    let url = environment.API_URL + environment.RESOURCES.UPDATE_CLINIC_ASSIGNMENT;
    if(is_override){
      url = url + "/" + is_override;
    }
    return this.http.post(url,clinic_assignment_model, Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }
  updateLocalLeadsAssignment(local_leads_model) {
    let url = environment.API_URL + environment.RESOURCES.UPDATE_LOCAL_LEADS;
    return this.http.post(url,local_leads_model, Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }

  updateCanceledClinics(canceled_clinics_model){
    let url=environment.API_URL+environment.RESOURCES.CONFIRM_CANCELLED_APPOINTMENTS;
    return this.http.post(url, canceled_clinics_model,Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));    
  }
}
