export enum ActionType {    
    addOportunity= 1,
    editOportunity = 2,
   
}

export enum ProgramType {    
    SR= 1,
    IP = 2,
    SRIP=3,
    PCA=4,
   
}

export enum PageType{
    landingPage=1,
    storePage=2,
    scheduleEventPage=3,
    followupPage=4,
    oppurtunity=5

}