// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-msg {
    color: red;
  }
  .fix-error-icon {
    top: 27px;
  }
  .logTitle {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #3096D8;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/common/components/field-error-display/field-error-display.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;EACZ;EACA;IACE,SAAS;EACX;EACA;IACE,yCAAyC;IACzC,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".error-msg {\r\n    color: red;\r\n  }\r\n  .fix-error-icon {\r\n    top: 27px;\r\n  }\r\n  .logTitle {\r\n    font-family: Arial, Helvetica, sans-serif;\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n    color: #3096D8;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
