import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
@Component({
    selector: 'app-charts',
    templateUrl: './charts.component.html',
    styleUrls: ['./charts.component.css']
})
export class ChartsComponent implements OnInit {

    districtPortalActivityBar: any = [];
    dataStatsByProgramBar: any = [];
    dataStatsByProgramBar2: any = [];

    imzSchedulingAndClinicDatesBar: any = [];
    pcaSchedulingAndEventDatesBar: any = [];

    srEventDatesBar: any = [];
    srEventScheduledBar: any = [];
    srEventTypesBar: any = [];

    statusOfLocalClinicsPie: any = [];
    statusOfCommunityOutreachClinicsPie: any = [];
    statusOfCharityPie: any = [];
    statusOfCorpClinicsPie: any = [];

    avgFluShotsAdminBar: any = [];
    pcaEventsBar: any = [];

    constructor() { }

    ngOnInit() {

        //Chart 1
        this.districtPortalActivityBar = new Chart('districtPortalActivityBar', {
            type: 'bar',
            data: {
                labels: ["2986", "3406", "4258", "4463", "4502", "5284", "6764", "6796", "7100", "7457", "9059", "9439", "11739", "12772"].reverse(),
                datasets: [
                    {
                        label: 'Logins',
                        barPercentage: 0.5,
                        barThickness: 16,
                        maxBarThickness: 16,
                        minBarLength: 2,
                        data: [95, 15, 41, 12, 16, 2, 24, 3, 2, 24, 27, 102, 50, 89].reverse(),
                        backgroundColor: '#2a7db5',
                    }, {
                        label: 'Outreach Contacts',
                        barPercentage: 0.5,
                        barThickness: 16,
                        maxBarThickness: 16,
                        minBarLength: 2,
                        data: [43, 35, 56, 24, 26, 20, 35, 15, 6, 26, 20, 35, 5, 6].reverse(),
                        backgroundColor: '#17adc2'
                    }, {
                        label: 'IP Completed',
                        barPercentage: 0.5,
                        barThickness: 16,
                        maxBarThickness: 16,
                        minBarLength: 2,
                        data: [9, 6, 4, 0, 5, 4, 6, 1, 0, 5, 4, 10, 1, 7].reverse(),
                        backgroundColor: '#7dc546'
                    }, {
                        label: 'SR Completed',
                        barPercentage: 0.5,
                        barThickness: 16,
                        maxBarThickness: 16,
                        minBarLength: 2,
                        data: [1, 3, 0, 2, 1, 1, 3, 0, 6, 1, 1, 8, 0, 2].reverse(),
                        backgroundColor: '#f4ae32'
                    }, {
                        label: 'PCA Completed',
                        barPercentage: 0.5,
                        barThickness: 16,
                        maxBarThickness: 16,
                        minBarLength: 2,
                        data: [1, 0, 1, 3, 0, 1, 0, 1, 0, 0, 1, 0, 2, 0].reverse(),
                        backgroundColor: '#a43794'
                    }

                ]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom'
                    }
                },
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        title: {
                            text: 'Store'
                        },
                        grid: {
                            display: false,
                        },
                        stacked: true
                    }
                }
            }
        });

        //Chart 2
        this.dataStatsByProgramBar = new Chart('dataStatsByProgramBar', {
            type: 'bar',
            data: {
                labels: ["InfoUSA Conversions", "WAG Added Conversions", "Maintained", "At Risk", "Lost"],
                datasets: [
                    {
                        label: 'SR',
                        barPercentage: 0.5,
                        barThickness: 16,
                        maxBarThickness: 16,
                        minBarLength: 2,
                        data: [4, 0, 41, 12, 16],
                        backgroundColor: '#2a7db5',
                    }, {
                        label: 'IP',
                        barPercentage: 0.5,
                        barThickness: 16,
                        maxBarThickness: 16,
                        minBarLength: 2,
                        data: [489, 5, 56, 24, 26],
                        backgroundColor: '#17adc2'
                    }, {
                        label: 'Both',
                        barPercentage: 0.5,
                        barThickness: 16,
                        maxBarThickness: 16,
                        minBarLength: 2,
                        data: [503, 44, 4, 0, 5],
                        backgroundColor: '#7dc546'
                    }, {
                        label: 'Total',
                        barPercentage: 0.5,
                        barThickness: 16,
                        maxBarThickness: 16,
                        minBarLength: 2,
                        data: [996, 49, 4, 0, 5],
                        backgroundColor: '#f4ae32'
                    }
                ]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom'
                    }
                },
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        title: {
                            text: ''
                        },
                        grid: {
                            display: false,
                        },
                        stacked: true
                    }
                }
            }
        });


        let dataStatsByProgramDataBar2 = {
            labels: ['SR', 'IP', 'Both', 'Total'],

            datasets: [
                {
                    label: 'InfoUSA Conversions',
                    backgroundColor: '#2a7db5',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [158, 458, 503, 995]
                },
                {
                    label: 'WAG Added Conversions',
                    backgroundColor: '#17adc2',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [187, 350, 490, 840]
                },
                {
                    label: 'Maintained',
                    backgroundColor: '#7dc546',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [145, 345, 475, 875]
                },
                {
                    label: 'At Risk',
                    backgroundColor: '#f4ae32',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [150, 40, 200, 345]
                },
                {
                    label: 'Lost',
                    backgroundColor: '#a43794',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [72, 50, 100, 190]
                }
            ]
        };

        this.dataStatsByProgramBar2 = new Chart('dataStatsByProgramBar2', {
            type: 'bar',
            data: dataStatsByProgramDataBar2,
            options: {
                plugins:{
                    legend: {
                        position: 'bottom'
                    },
                },
                scales: {
                    xAxis: {
                        grid: {
                            offset: true
                        }

                    },
                    yAxis: {
                        display: true
                    }
                }
            }
        });


        //Chart 3
        let imzSchedulingAndClinicDatesData = {
            labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr'],
            datasets: [
                {
                    label: 'Scheduled Clinic',
                    backgroundColor: '#2a7db5',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [100, 574, 2351, 7817, 11497, 5917, 688, 161, 142, 59, 60, 36]
                },
                {
                    label: 'Clinic Date',
                    backgroundColor: '#7dc546',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [31, 19, 14, 395, 15384, 15871, 2537, 168, 11, 2, 4, 0]
                }
            ]
        };

        this.imzSchedulingAndClinicDatesBar = new Chart('imzSchedulingAndClinicDatesBar', {
            type: 'bar',
            data: imzSchedulingAndClinicDatesData,
            options: {
                plugins:{
                    legend: {
                        position: 'bottom'
                    }
                },
                scales: {
                    x: {
                        offset: true
                    },
                    y: {
                        display: true
                    }
                }
            }
        });
        //Chart 4
        let avgFluShotsAdminData = {
            labels: ['Corporate', 'Local', 'Community Outreach'],
            datasets: [
                {
                    label: 'Standard Quadrivalent (Flu)',
                    backgroundColor: '#2a7db5',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [59, 32, 22]
                },
                {
                    label: 'Influenza - High-Dose (+65)',
                    backgroundColor: '#17adc2',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [6, 14, 10]
                },
                {
                    label: 'Influenza - High-Dose',
                    backgroundColor: '#7dc546',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [, 17, 9]
                }
            ]
        };

        this.avgFluShotsAdminBar = new Chart('avgFluShotsAdminBar', {
            type: 'bar',
            data: avgFluShotsAdminData,
            options: {
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                },
                scales: {
                    x: {
                        grid: {
                            offset: true
                        }

                    },
                    y: {
                        display: true
                    }
                }
            }
        });

        //Chart 5
        let statusOfLocalClinicsData = {
            labels: ['Review & Assign', 'Contact Client', 'Confirmed', 'Completed', 'Cancelled'],
            datasets: [
                {
                    data: [40, 939, 3246, 9865, 798],
                    borderColor: '#ffffff',
                    backgroundColor: [
                        "#f4ae32",
                        "#2a7db5",
                        "#17adc2",
                        "#7dc546",
                        "#a1a1a1"
                    ]
                }
            ]
        };

        this.statusOfLocalClinicsPie = new Chart('statusOfLocalClinicsPie', {
            type: 'doughnut',
            data: statusOfLocalClinicsData,

            options: {
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                        align: 'start'
                    }
                }
            }, 
        });

        //Chart 6
        let statusOfCommunityOutreachClinicsData = {
            labels: ['Confirmed', 'Completed', 'Cancelled'],
            datasets: [
                {
                    data: [564, 2433, 517],
                    borderColor: '#ffffff',
                    backgroundColor: [
                        "#17adc2",
                        "#7dc546",
                        "#a1a1a1"
                    ]
                }
            ]
        };

        this.statusOfCommunityOutreachClinicsPie = new Chart('statusOfCommunityOutreachClinicsPie', {
            type: 'doughnut',
            data: statusOfCommunityOutreachClinicsData,

            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                        align: 'start'
                    }
                }
            },
        });

        //Chart 7
        let statusOfCharityData = {
            labels: ['Review & Assign', 'Vouchers Sent', 'Completed', 'Cancelled'],
            datasets: [
                {
                    data: [11, 911, 1371, 323],
                    borderColor: '#ffffff',
                    backgroundColor: [
                        "#f4ae32",
                        "#a43794",
                        "#7dc546",
                        "#a1a1a1"
                    ]
                }
            ]
        };

        this.statusOfCharityPie = new Chart('statusOfCharityPie', {
            type: 'doughnut',
            data: statusOfCharityData,

            options: {
                plugins:{
                    legend: {
                        position: 'bottom',
                        align: 'start'
                    }
                }
            }
        });

        //Chart 8
        let statusOfCorpClinicsData = {
            labels: ['Review & Assign', 'Contact Client', 'Confirmed', 'Completed', 'Cancelled'],
            datasets: [
                {
                    data: [36, 254, 494, 1354, 85],
                    borderColor: '#ffffff',
                    backgroundColor: [
                        "#f4ae32",
                        "#2a7db5",
                        "#17adc2",
                        "#7dc546",
                        "#a1a1a1"
                    ]
                }
            ]
        };

        this.statusOfCorpClinicsPie = new Chart('statusOfCorpClinicsPie', {
            type: 'doughnut',
            data: statusOfCorpClinicsData,

            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                        align: 'start'
                    }
                }
            }
        });

        // Chart 9
        let srEventDatesData = {
            labels: ['Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
            datasets: [
                {
                    label: 'Scheduled Event',
                    backgroundColor: '#794ee6',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [220, 154, 32, 147, 285, 312, 565, 687, 642, 654, 456, 315]
                },
                {
                    label: 'Event Date',
                    backgroundColor: '#f4ae32',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [332, 216, 256, 387, 763, 871, 433, 142, 217, 338, 270, 216]
                }
            ]
        };

        this.srEventDatesBar = new Chart('srEventDatesBar', {
            type: 'bar',
            data: srEventDatesData,
            options: {
                plugins: {
                    legend: {
                        position: 'bottom'
                    }
                },
                scales: {
                    x: {
                        display: true
                    },
                    y: {
                        display: true
                    }
                }
            }
        });

        //Chart 10
        this.srEventTypesBar = new Chart('srEventTypesBar', {
            type: 'bar',
            data: {
                labels: ["Vaccinations", "Senior Day", "Immunizations", "Flu", "Other", "Open House", "Local Business", "Education", "Presentation", "Social Event (Bingo, etc.)", "Drop Off Materials", "Health & Wellness"].reverse(),
                datasets: [
                    {
                        barPercentage: 0.5,
                        barThickness: 10,
                        maxBarThickness: 12,
                        minBarLength: 2,
                        data: [8, 19, 37, 122, 66, 6, 23, 49, 50, 80, 190, 393].reverse(),
                        backgroundColor: '#f4ae32',
                    }
                ]
            },
            options: {
                responsive: true,
                plugins:{
                    legend: {
                        display: false
                    },
                },
                scales: {
                    x: {
                        stacked: false
                    },
                    y: {
                        stacked: false
                    }
                }
            }
        });

        // Chart 11
        let pcaSchedulingAndEventDatesData = {
            labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr'],
            datasets: [
                {
                    label: 'Scheduled Event',
                    backgroundColor: '#a1a1a1',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [101, 246, 169, 124, 163, 17, 16, 0, 0, 40, 220, 186]
                },
                {
                    label: 'Event Date',
                    backgroundColor: '#882979',
                    barPercentage: 0.5,
                    barThickness: 12,
                    maxBarThickness: 14,
                    minBarLength: 2,
                    data: [42, 471, 213, 95, 105, 122, 116, 110, 34, 5, 34, 35]
                }
            ]
        };

        this.pcaSchedulingAndEventDatesBar = new Chart('pcaSchedulingAndEventDatesBar', {
            type: 'bar',
            data: pcaSchedulingAndEventDatesData,
            options: {
                plugins: {
                    legend: {
                        position: 'bottom',
                    }
                },
                scales: {
                    x: {
                        display: true
                    },
                    y: {
                        display: true
                    }
                }
            }
        });
        //Chart 12
        let pcaEventsData = {
            labels: ['AIDS Service', 'Community Based', 'Health Department', 'Organ Procurement', 'Cancer Screening Services'].reverse(),
            datasets: [
                {
                    data: [73, 664, 102, 144, 2].reverse(),
                    borderColor: '#ffffff',
                    barPercentage: 0.5,
                    barThickness: 10,
                    maxBarThickness: 12,
                    minBarLength: 2,
                    backgroundColor: [
                        "#882979",
                        "#882979",
                        "#882979",
                        "#882979",
                        "#882979"
                    ],
                }
            ]
        };

        this.pcaEventsBar = new Chart('pcaEventsBar', {
            type: 'bar',
            data: pcaEventsData,
            options: {
                plugins:{
                    legend: {
                        display: false
                    }
                },
                scales: {
                    x: {
                        display: true,
                        grid: {
                            offset: false
                        }
                    },
                    y: 
                        {
                            grid: {
                                display: false,
                            },
                            display: true
                        }
                }
            }
        });
    }
}