import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash'; 

@Pipe({
  name: 'UniquePipeClinicDetails',
  pure: false
})

export class UniquePipeClinicDetails implements PipeTransform {
    transform(value: any): any{      
        if(value!== undefined && value!== null){
            let index = 0;
            value.forEach(element => {
                element.addControl('indexVal', new FormControl('', null));            
                element.patchValue({
                    indexVal: index 
                  });
                  index = index + 1;
            });     
            return _.uniqBy(value, 'value.immunizationPk');
        }
        return value;
    }
}