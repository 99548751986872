// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-border-tbl td,
  .no-border-tbl th {
    border-top: none !important;
  }

  .no-border-tbl td {
    vertical-align: top !important;
  }
  .black-font, .font-weight-bold, b{
      color:#000000 !important; 
  }

`, "",{"version":3,"sources":["webpack://./src/app/modules/contractaggreement/components/contract-agreement/agreement.component.css"],"names":[],"mappings":"AAAA;;IAEI,2BAA2B;EAC7B;;EAEA;IACE,8BAA8B;EAChC;EACA;MACI,wBAAwB;EAC5B","sourcesContent":[".no-border-tbl td,\r\n  .no-border-tbl th {\r\n    border-top: none !important;\r\n  }\r\n\r\n  .no-border-tbl td {\r\n    vertical-align: top !important;\r\n  }\r\n  .black-font, .font-weight-bold, b{\r\n      color:#000000 !important; \r\n  }\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
