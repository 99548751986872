export const covidClinicTypeValues:any[] = [
    {
      "id":"Team member",
      "name":"Team member"
    },
    {
      "id":"Teachers or childcare workers",
      "name":"Teachers or childcare workers"
    },
    {
      "id":"Equity",
      "name":"Equity"
    },
    {
      "id":"LTCF",
      "name":"LTCF"
    },
    {
      "id":"School Campaign",
      "name":"School Campaign"
    },
    {
      "id":"Community college",
      "name":"Community college"
    },
    {
      "id":"B2B",
      "name":"B2B"
    },
    {
      "id":"Others",
      "name":"Others"
    },
    {
      "id":"School children",
      "name":"School children"
    }
  ]