// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-msg {
  color: red;
}

has-error label {
  color: red !important;
}

.has-error input {
  border: 1px solid red !important;
}

.has-error select {
  border: 1px solid red !important;
}

.mt-23{
  margin-top: 23px !important;
}

::ng-deep a.p-accordion-header-link
{
  color: #333333;
  background-color: #f4f4f4;
}

::ng-deep .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #007ad9;
  border-color: #007ad9;
  color: #ffffff !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/store/components/opportunity/opportunity.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;;EAEE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,yBAAyB;EACzB,6BAA6B;EAC7B,4BAA4B;AAC9B","sourcesContent":[".error-msg {\r\n  color: red;\r\n}\r\n\r\nhas-error label {\r\n  color: red !important;\r\n}\r\n\r\n.has-error input {\r\n  border: 1px solid red !important;\r\n}\r\n\r\n.has-error select {\r\n  border: 1px solid red !important;\r\n}\r\n\r\n.mt-23{\r\n  margin-top: 23px !important;\r\n}\r\n\r\n::ng-deep a.p-accordion-header-link\r\n{\r\n  color: #333333;\r\n  background-color: #f4f4f4;\r\n}\r\n\r\n::ng-deep .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {\r\n  background: #007ad9;\r\n  border-color: #007ad9;\r\n  color: #ffffff !important;\r\n  border-bottom-right-radius: 0;\r\n  border-bottom-left-radius: 0;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
