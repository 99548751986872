import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddpcaComponent } from './components/addpca/addpca.component';
import { RouterModule } from '@angular/router';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { LulaContractAgreementForUserComponent } from './components/lula-contract-agreement-for-user/lula-contract-agreement-for-user.component';
import { PcadetailsComponent } from './components/pcadetails/pcadetails.component';
import { RhdContractAgreementComponent } from './components/rhd-contract-agreement/rhd-contract-agreement.component';
import { AddTestingOrganizationService } from './Services/add-testing-organization.service';
import { ReusableModule } from '../common/components/reusable.module';
import { PcacontractAgreementService } from './Services/pcacontract-agreement.service';
import { AsolulaComponent } from './components/asolula/asolula.component';
import { PcaImmunizationsComponent } from './components/pca-immunizations/pca-immunizations.component';
import { PcaLocationComponent } from './components/pca-location/pca-location.component';
import { PcaPharmacyInfoComponent } from './components/pca-pharmacy-info/pca-pharmacy-info.component';
import { PcaAgreementComponent } from './components/pca-agreement/pca-agreement.component';
import { PcaContractsService } from './services/pca-contracts.service';
import { PcaLocationListComponent } from './components/pca-location-list/pca-location-list.component';
import { NgPipesModule } from 'ngx-pipes';
import { GovlulaAgreementComponent } from './components/govlula-agreement/govlula-agreement.component';
import { PcadetailstepsComponent } from './components/pcadetailsteps/pcadetailsteps.component';
import { PcaDetailsService } from './Services/pcadetailsservice.service';
import { OpolulaComponent } from './components/opolula/opolula.component';
import { CsslulaComponent } from './components/csslula/csslula.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InputMaskModule } from 'primeng/inputmask';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AccordionModule } from 'primeng/accordion';
import {ConfirmationService} from 'primeng/api';
import { PcaAgreementComponent2022 } from './components/pca-agreement-Release2/pca-agreement-Release2.component';
import { LulaContractAgreementForUserrelease2Component } from './components/lula-contract-agreement-for-user-release2/lula-contract-agreement-for-user-release2.component';
import { RhdContractAgreementrelease2Component } from './components/rhd-contract-agreement-release2/rhd-contract-agreement-release2.component';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    RouterModule,
    ReusableModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    ConfirmDialogModule,
    CalendarModule,
    DialogModule,
    KeyFilterModule,
    NgPipesModule,
    InputMaskModule,NgxSpinnerModule
  ],
  declarations: [AddpcaComponent, LulaContractAgreementForUserComponent,LulaContractAgreementForUserrelease2Component,
     PcadetailsComponent, RhdContractAgreementComponent,RhdContractAgreementrelease2Component, AsolulaComponent, PcaImmunizationsComponent,
      PcaLocationComponent, PcaPharmacyInfoComponent, PcaAgreementComponent,PcaAgreementComponent2022, PcaLocationListComponent, 
      GovlulaAgreementComponent,PcadetailstepsComponent, OpolulaComponent, CsslulaComponent],
     providers:[AddTestingOrganizationService,PcacontractAgreementService,PcaContractsService, ConfirmationService,PcaDetailsService]
})
export class PatientcareadvocacyModule { }
