import { Injectable } from "@angular/core";
export class SR {
    contacts: string;
    lastContact: string;
    outreachStatus: string;
    contactLogPk: string;
    outreachStatusTitle: string;
    outreachProgram:string;
    isScheduledClinics: boolean;
    outreachBusinessPk : number;
    clinicAgreementPk : number;
    
}

export class IP {
    contacts: string;
    lastContact: string;
    outreachStatus: string;
    contactLogPk: string;
    outreachStatusTitle: string;
    outreachProgram:string;
    isScheduledClinics: boolean;
    outreachBusinessPk : number;
    clinicAgreementPk : number;
   
   
}
export class PCA {
    contacts: string;
    lastContact: string;
    outreachStatus: string;
    contactLogPk: string;
    outreachStatusTitle: string;
    outreachProgram:string;
    isScheduledClinics: boolean;
    outreachBusinessPk : number;
    clinicAgreementPk : number;
    
}

export class OutreachEffort {
    SR: SR;
    IP: IP;
    PCA: PCA;
    constructor()
    {
        this.SR=new SR();
        this.IP=new IP();
        this.PCA=new PCA();
    }
}
@Injectable()
export class Opportunities {
    storeId: number;
    businessPk: number;
    businessName: string;
    industry: string;
    others: string;
    firstName: string;
    lastName: string;
    contactName: string;
    phone: string;
    address: string;
    state: string;
    jobTitle: string;
    zip: string;
    createDate: Date;
    isStandardized: boolean;
    isMilitaryOpportunity:boolean;
    isPreviousClient:boolean;
    isInterestClickedInRetentionEmail:boolean;
    employmentSize?: any;
    contactEmail?: any;
    accountType: string;
    isExistingBusiness: boolean;
    isB2BSmallBusiness: boolean;
    isDirectB2BMail: boolean;
    contactLogPk: string;
    outreachEffort: OutreachEffort;
    contacts: string;
    lastContact: string;
    outreachStatusTitle: string;
    outreachStatus: string;
    businessType: number;
    clinicTime?: any;
    clinicDate?: any;
    sicCode: number;
    leadStoreId?: any;
    sicPriority: number;
    naClinicGroupId?: any;
    isPCA:any;
    organizationType?:number;
    isCovidOpportunity:boolean ;
    isFluOpportunity:boolean;
    isNoContractOpportunity:boolean;
    covidOpportunityType:number;
    fluOpportunityType:number;
    isTopPriorityFromLead:boolean;
    isHighPriority:boolean;
    constructor()
    {
        this.outreachEffort=new OutreachEffort();
        this.isPCA = false;
        this.isCovidOpportunity = null;
        this.covidOpportunityType = null;
    }
}