import { WalgreenCo, Client } from "./contract";

export class PcaEvent {   
    // public immunizationPk:string;
    // public immunizationName:string;
    public contactFirstName:string;
    public contactLastName:string;
    public jobTitle:string;
    public contactPhone:string;
    public contactEmail:string;
    public address1:string;
    public city:string;
    public state:string;
    public zipCode:string;
    public storeId:string;
    public locations: any;
    public immunization: Array<PcaImmunization>

    constructor(){
        // this.immunizationPk="";
        this.immunization = new Array<PcaImmunization>();
        // this.immunizationName="";
        this.contactFirstName="";
        this.contactLastName="";
        this.jobTitle="";
        this.contactPhone="";
        this.contactEmail="";
        this.address1="";
        this.city="";
        this.state="";
        this.zipCode="";
        this.storeId="";

    }
}
export class PcaEventSchedule{
    public location:string;
    public eventDate:string;
    public startTime:string;
    public endTime:string;
    public estimatedQuantity:number;
    constructor(){
        this.eventDate=null;
        this.startTime=null;
        this.endTime=null;
        this.estimatedQuantity = null;
    }
}
export class PcaContract{ 
    
    public licenseAgreementNum:string;
    public businessUserEmails:string;
    public isEmailSent:number;
    public isApproved:number;
    public isRhdApproved:number;
    public isEmailSentToRHD:number; //todo remove this variable after live
    public clinicAgreementPk:number;
    public isLocationReqAvailable:number;
    public isHealthRoomAvailable:number
    public isCurrent:number ;
    public approvedOrRejectedBy:string;
    public contactLogPk:number;
    public lastUpdatedDate:string;
    public dateCreated:Date;
    public dateApproved:Date;
    public dateRHDApproved:Date;
    public notes:string;
    public signature:string;
    public rhdSignature:string;
    public totalS:number;
    public immunization:Array<PcaImmunization> ; 
    public pcaClinic:PcaEvent;
    public pcaEvenScheduleList :Array<PcaEventSchedule>;
    public walgreenCoInfo:WalgreenCo;
    public testingPartner:OpportunityInfo;
    public store:Store;
    public clientInfo: Client;
    constructor(){
        this.pcaClinic = null;
        this.immunization = null;
        this.walgreenCoInfo = null;
        this.testingPartner = null;
        this.store = null;
        this.pcaEvenScheduleList= new Array<PcaEventSchedule>();
        this.isApproved = null;
        this.isEmailSent = null;
        this.isCurrent = 1;
        this.dateApproved = null;
    }
    init(){
        this.pcaClinic = new PcaEvent();
        this.immunization = new Array<PcaImmunization>();
        this.walgreenCoInfo = new WalgreenCo();
        this.testingPartner = new OpportunityInfo();
        this.store = new Store(); 
    }
    wipeData(){
        this.isApproved = null;
        this.isEmailSent = null;
        this.isRhdApproved = null;
        this.notes = null;
        this.immunization = null;
        this.pcaClinic = null;
        this.walgreenCoInfo  = null;
        this.testingPartner = null;
        this.store = null;
        this.pcaEvenScheduleList = [];
        this.clientInfo = null;
 
    }
}
export class PcaImmunization {
    public immunizationPk: string = "";
    public immunizationName: string = "";
    public paymentTypeId?:string = "";
}
export class Errors{
    public errorCode:number;
    public errorMessage:string;
}
export class OpportunityInfo {
    public businessName:string;
    public email:string;
    public firstName:string;
    public lastName:string;
    public jobTitle:string;
    public address1:string;
    public address2:string;
    public phone:string;
    public city:string;
    public state:string;
    public zip:string;
    public zipCode:string;
    constructor(){
        this.businessName = "";
        this.email = "";
        this.firstName = "";
        this.lastName = "";
        this.jobTitle = "";
        this.address1 = "";
        this.address2 = "";
        this.phone = "";
        this.city = "";
        this.state = "";
        this.zip = "";
        this.zipCode="";
    }
}
export class Store {
    public storeId:string;
    public storePk:string;
    public address:string;
    public address2:string;
    public city:string;
    public state:string;    
    public zipCode:string;
    public zip:string;
}
export class PCACancerMetrics{
    public EventCount:number;
    public Year:number;
}