import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class StoresListService {
 baseUrl = 'http://localhost:49940/api/Values/';
 storeslist: any[] = [];
//  private defaultStore = new BehaviorSubject<stor
  constructor(private http: HttpClient) { }
  
}
