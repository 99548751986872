export const LocalField: any[] = [{
    "groupName": "Opportunity Information",
    "aliasNames": {
        "businessContactEmail": "Contact Email",
        "firstName": "Contact First Name",
        "lastName": "Contact Last Name",
        "phone": "Contact Phone",
        "jobTitle": "Contact Job Title",
        "clientReportingEmail": "Client Reporting Email",
        "b2BTeamEmail": "B2B Team Email",
        "salesForceOpportunityId": "Salesforce Opportunity ID",
        "salesForceAccountName": "Salesforce Account Name"
    }
},
{
    "groupName": "Billing & Vaccine Information",
    "aliasNames": {
        "storeID": "Assigned Store",
        "naClinicPlanId": "Plan ID",
        "recipientId": "ID Recipient",
        "naClinicAddlComments": "Additional Comments/Instructions",
        "naClinicSplBillingInstr": "Special Billing Instructions",
        "estimatedQuantity": "Est Vol",
        "totalImmAdministered": "Total Administered",
        "coPayROUTINEGroupId": "Routine Group ID",
        "coPayFLUGroupId": "Flu Group ID",
        "vouchersDistributed": "Vouchers Distributed",
        "vouchersRedeemed": "Vouchers Redeemed",
        "isNoClinicFee": "No Fee",
    }
},
{
    "groupName": "Clinic Information",
    "aliasNames": {
        "naClinicLocation": "Clinic",
        "naContactFirstName": "First Name",
        "naContactLastName": "Last Name",
        "naClinicContactPhone": "Phone#",
        "naContactEmail": "Email",
        "isNoClinic": "No Clinic - Voucher Distribution Only",
        "isApptSchedulerEnabled": "Appointment Scheduler Enabled",
        "naClinicAddress1": "Address 1",
        "naClinicAddress2": "Address 2",
        "naClinicCity": "City",
        "naClinicState": "State",
        "naClinicZip": "Zip",
        "clinicRoom": "Clinic Room",
        "clinicDate": "Clinic Date",
        "naClinicStartTime": "Start Time",
        "naClinicEndTime": "End Time",
        "confirmedClientName": "Confirmed By",
        "reassignType": "Reassign Type",
        "reassignedTo": "Reassign To",
        "visitType": "Visit Type",
        "coOutreachTypeId": {
            "label": "Outreach Type",
            "values": {
                "1": "Brownbag",
                "2": "Health Fair",
                "3": "Presentation/Education",
                "4": "Other",
                "5": "COVID Clinic"
            }
        },
        "coOutreachTypeDesc": "Other",
        "apptSchedulerType": {
            "label": "Appointment Scheduler Type",
            "values": {
                "1": "Community Outreach Portal Scheduler",
                "2": "CURA Patient Scheduler",
                "3": "CURA Patient Regional Scheduler",
                "0": "Community Outreach Portal Scheduler" //adding for logging purpose
            }
        },
    }
}, {
    "groupName": "Pharmacist Information",
    "aliasNames": {
        "pharmacistName": "Rx Host",
        "pharmacistPhone": "Rx Phone#",
        "totalHours": "Clinic Hours",
        "feedback": "Feedback/ Notes"
    }
}
]