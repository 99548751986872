import { Component, OnInit,  ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Schedulevent } from '../../../../models/schedulevent';
import { Util } from '../../../../utility/util';
import { ScheduleeventService } from '../../services/scheduleevent.service';
import { SessionDetails } from '../../../../utility/session';
import { FollowUp } from '../../../../models/FollowUp';
import { DatePipe, Time, Location } from '@angular/common';
import { MessageServiceService } from '../../services/message-service.service';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { FormCanDeactivate } from '../../../../guards/form-can-deactivate/form-can-deactivate';
import { TimeToDatePipe } from '../../../contractaggreement/pipes/time-to-date.pipe';

@Component({
  selector: 'app-schedule-event',
  templateUrl: './schedule-event.component.html',
  styleUrls: ['./schedule-event.component.css'],
  providers: [TimeToDatePipe, DatePipe]
})
export class ScheduleEventComponent extends FormCanDeactivate implements OnInit {
  @ViewChild('form')
  form: NgForm;
  showHints: boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : true;
  pageName: string = "scheduleevent";
  schedule_event_form: FormGroup;
  schedule_event: Schedulevent;
  defaultTime: Date = new Date('Mon Jan 1 1900 00:00:00')
  eventdate: string;
  event_details: any;
  startTime: any;
  endTime: any;
  isEdit: boolean = false;
  displayOnSucc: boolean = false;
  startTimeToDo: string = "";
  eventStartTimeValid: Date;
  eventEndTimeValid: Date;
  savePopUp: boolean = false;
  continuePopUp: boolean = false;
  dialogSummary: string;
  dialogMsg: string;
  doNotSaveText: string = "";
  setUp: string = "";
  isEventTypeReadOnly: boolean = false;
  isWallgreenRoleReadOnly: boolean = false;
  schedule_event_check = new Schedulevent();
  showCanRouteChange: boolean;
  disable_button: boolean = false;
  showStartTimeEndTimeValid :boolean = false;
  isTimeValid:Number = 0;
  continueToSaveClicked :Number = 1;
  constructor(private formBuilder: FormBuilder, private _location: Location, private router: Router,
    private message_service: MessageServiceService, private _datePipe: DatePipe, private utility: Util, private schedule_event_service: ScheduleeventService,
    private _timeToDatePipe: TimeToDatePipe) {
    super();
    this.message_service.getCanShowRouteChangeConfirmation().subscribe(message => {
      this.showCanRouteChange = message.text;
    });
  }

  ngOnInit() {
    this.schedule_event_form = this.formBuilder.group({
      eventdate: ['', Validators.required],
      eventStartTime: ['', Validators.required],
      eventEndTime: ['', Validators.required],
      eventTypeId: ['', Validators.required],
      walgreensRoleId: ['', null],
      provideEducationalMaterial: ['', null],
      description: ['', null],
      numberOfAttendees: ['', null],
      eventDescription: ['', null],
      walgreensRoleDescription: ['', null]
    });

    this.isEventTypeReadOnly = true;
    this.isWallgreenRoleReadOnly = true;
    this.event_details = SessionDetails.GetEventDetails();
    this.schedule_event_check = this.schedule_event_form.value;
    if (this.event_details != null && this.event_details.contactLogPk != null) {
      this.getScheduleEvents(this.event_details.contactLogPk)
    }
    let today = new Date();
    this.defaultTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
  }
  getScheduleEvents(contact_log_pk) {
    var schedule_event = new Schedulevent();
    this.isEdit = true;
    this.schedule_event_service.getScheduleEvents(contact_log_pk).subscribe((schedule_events:Schedulevent) => {
      schedule_event = schedule_events;
      this.schedule_event_check = schedule_event;
      this.schedule_event_form.controls['eventdate'].setValue(new Date(schedule_event.dateOfEvent));
      this.schedule_event_form.controls['eventStartTime'].setValue(schedule_event.eventStartTime.replace("  ", " "));//schedule_event.eventStartTime);
      this.schedule_event_form.controls['eventEndTime'].setValue(schedule_event.eventEndTime.replace("  ", " "));// schedule_event.eventEndTime);
      this.schedule_event_form.controls['eventTypeId'].setValue(String(schedule_event.eventTypeId));
      if (schedule_event.eventTypeId != 8) {
        this.isEventTypeReadOnly = true;
      }
      this.schedule_event_form.controls['walgreensRoleId'].setValue(String(schedule_event.walgreensRoleId));
      if (schedule_event.eventTypeId != 3) {
        this.isWallgreenRoleReadOnly = true;
      }
      this.schedule_event_form.controls['provideEducationalMaterial'].setValue(schedule_event.provideEducationalMaterial == true ? "1" : "2");
      this.schedule_event_form.controls['description'].setValue(schedule_event.description);
      this.schedule_event_form.controls['numberOfAttendees'].setValue(schedule_event.numberOfAttendees);
      this.schedule_event_form.controls['walgreensRoleDescription'].setValue(schedule_event.walgreensRoleDescription);
      this.schedule_event_form.controls['eventDescription'].setValue(schedule_event.eventDescription);
      this.schedule_event_check = this.schedule_event_form.value;
    });
  }

  isFieldValid(field: string) {
    return !this.schedule_event_form.get(field).valid && this.schedule_event_form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  onSubmit() {
    if (this.isEdit) {
      this.updateEvent();
    }
    else {
      this.addEvent();
    }
  }
  addEvent() {
    
    if (this.schedule_event_form.controls['eventTypeId'].value == "8") {
      this.schedule_event_form.controls['eventDescription'].setValidators([Validators.required])
      this.schedule_event_form.controls['eventDescription'].updateValueAndValidity()
    }
    if (this.schedule_event_form.controls['walgreensRoleId'].value == "3") {
      this.schedule_event_form.controls['walgreensRoleDescription'].setValidators([Validators.required])
      this.schedule_event_form.controls['walgreensRoleDescription'].updateValueAndValidity()
    }
    var scheduleEvent = new FollowUp();
    if (this.schedule_event_form.valid) {
      if (this.schedule_event_form.controls['eventStartTime'].value != "")
        this.startTime = this._datePipe.transform(this.schedule_event_form.controls['eventStartTime'].value, 'shortTime');
      if (this.schedule_event_form.controls['eventEndTime'].value != "") {
        this.endTime = this._datePipe.transform(this.schedule_event_form.controls['eventEndTime'].value, 'shortTime');
      }
      scheduleEvent.srEventScheduledDetails.dateOfEvent = new Date(this.schedule_event_form.controls['eventdate'].value);
      
      let endDate: Date;
      let startDate: Date;
      if (this.endTime.toString().toLowerCase().search('am') !== -1 || this.endTime.toString().toLowerCase().search('pm') !== -1) {
          endDate = this._timeToDatePipe.transform(scheduleEvent.srEventScheduledDetails.dateOfEvent, this.endTime);
      }
      if (this.startTime.toString().toLowerCase().search('am') !== -1 || this.startTime.toString().toLowerCase().search('pm') !== -1) {
        startDate = this._timeToDatePipe.transform(scheduleEvent.srEventScheduledDetails.dateOfEvent, this.startTime);
    }
   
    let locStart: Date = new Date(startDate);
    let locEnd: Date = new Date(endDate);
    if(locEnd.getHours()-locStart.getHours()< 0 &&this.continueToSaveClicked != 2){
      this.showStartTimeEndTimeValid = true;
      this.dialogSummary = "Error";
      this.dialogMsg = ErrorMessages['clinicLengthAlert'];
      this.isTimeValid = 2;
      return false;
  }
  else if(locEnd.getHours()-locStart.getHours()> 8 &&this.continueToSaveClicked != 2){     
    this.continueToSaveClicked = 0;
    this.showStartTimeEndTimeValid = true;
    this.dialogSummary = "Alert";
    this.dialogMsg = ErrorMessages['clinicLengthMoreThan8Hrs'];
    this.isTimeValid = 1; 
      return false;
  }
  
      scheduleEvent.srEventScheduledDetails.dateOfEvent = this._datePipe.transform(scheduleEvent.srEventScheduledDetails.dateOfEvent, "MM/dd/yyyy");
      scheduleEvent.srEventScheduledDetails.eventStartTime = this.startTime;
      scheduleEvent.srEventScheduledDetails.eventEndTime = this.endTime;
      scheduleEvent.srEventScheduledDetails.numberOfAttendes = +this.schedule_event_form.controls['numberOfAttendees'].value;
      scheduleEvent.srEventScheduledDetails.eventTypeId = +this.schedule_event_form.controls['eventTypeId'].value;
      scheduleEvent.srEventScheduledDetails.walgreensRoleId = +this.schedule_event_form.controls['walgreensRoleId'].value;
      if (this.schedule_event_form.controls['provideEducationalMaterial'].value == "1") {
        scheduleEvent.srEventScheduledDetails.provideEducationalMaterial = true;
      }
      else {
        scheduleEvent.srEventScheduledDetails.provideEducationalMaterial = false;
      }

      scheduleEvent.srEventScheduledDetails.description = this.schedule_event_form.controls['description'].value;
      scheduleEvent.srEventScheduledDetails.walgreensRoleDescription = this.schedule_event_form.controls['walgreensRoleDescription'].value;
      scheduleEvent.srEventScheduledDetails.eventDescription = this.schedule_event_form.controls['eventDescription'].value;

      scheduleEvent.logOutreachStatus.businessPk = this.event_details.logOutreachStatus.businessPk;;
      scheduleEvent.logOutreachStatus.firstName = this.event_details.logOutreachStatus.firstName;
      scheduleEvent.logOutreachStatus.lastName = this.event_details.logOutreachStatus.lastName;
      scheduleEvent.logOutreachStatus.jobTitle = this.event_details.logOutreachStatus.jobTitle;
      scheduleEvent.logOutreachStatus.outreachProgram = this.event_details.logOutreachStatus.outreachProgram;
      scheduleEvent.logOutreachStatus.outreachBusinessPk = this.event_details.logOutreachStatus.outreachBusinessPk;
      scheduleEvent.logOutreachStatus.contactDate = this.event_details.logOutreachStatus.contactDate;
      
      scheduleEvent.logOutreachStatus.outreachStatusId = this.event_details.logOutreachStatus.outreachStatusId;
      scheduleEvent.logOutreachStatus.outreachStatusTitle = this.event_details.logOutreachStatus.outreachStatusTitle;
      scheduleEvent.logOutreachStatus.feedback = this.event_details.logOutreachStatus.feedback;
      scheduleEvent.logOutreachStatus.createdBy = this.event_details.logOutreachStatus.createdBy;
      scheduleEvent.logOutreachStatus.clinicAgreementPk = 0;
      this.disable_button = true;
      this.schedule_event_service.postScheduleEvent(scheduleEvent).subscribe((data) => {      
        if (data === "success") {
          this.displayOnSucc = true;
          this.disable_button = false;
        }
      },
        err => {
          this.disable_button = false;
        });


    } else {
      this.utility.validateAllFormFields(this.schedule_event_form); //{7}
    }
  }
  updateEvent() {
    if (this.schedule_event_form.controls['eventTypeId'].value == "8") {
      this.schedule_event_form.controls['eventDescription'].setValidators([Validators.required])
      this.schedule_event_form.controls['eventDescription'].updateValueAndValidity()
    }
    if (this.schedule_event_form.controls['walgreensRoleId'].value == "3") {
      this.schedule_event_form.controls['walgreensRoleDescription'].setValidators([Validators.required])
      this.schedule_event_form.controls['walgreensRoleDescription'].updateValueAndValidity()
    }
    var scheduleEvent = new Schedulevent();
    if (this.schedule_event_form.valid) {

      this.eventStartTimeValid = new Date(this.schedule_event_form.controls['eventStartTime'].value);
      this.eventEndTimeValid = new Date(this.schedule_event_form.controls['eventEndTime'].value);

      if (this.eventStartTimeValid.toString().toLowerCase() == "invalid date") {
        scheduleEvent.eventStartTime = this.schedule_event_form.controls['eventStartTime'].value;
      }
      else {
        if (this.schedule_event_form.controls['eventStartTime'].value != "")
          scheduleEvent.eventStartTime = this._datePipe.transform(this.schedule_event_form.controls['eventStartTime'].value, 'shortTime');
      }
      if (this.eventEndTimeValid.toString().toLowerCase() == "invalid date") {
        scheduleEvent.eventEndTime = this.schedule_event_form.controls['eventEndTime'].value;
      }
      else {
        if (this.schedule_event_form.controls['eventEndTime'].value != "")
          scheduleEvent.eventEndTime = this._datePipe.transform(this.schedule_event_form.controls['eventEndTime'].value, 'shortTime');
      }
      scheduleEvent.contactLogPk = this.event_details.contactLogPk;
      scheduleEvent.dateOfEvent = new Date(this.schedule_event_form.controls['eventdate'].value);
      let endDate: Date;
      let startDate: Date;
      
      if ( scheduleEvent.eventEndTime.toString().toLowerCase().search('am') !== -1 ||  scheduleEvent.eventEndTime.toString().toLowerCase().search('pm') !== -1) {
          endDate = this._timeToDatePipe.transform(scheduleEvent.dateOfEvent, scheduleEvent.eventEndTime);
      }
      if ( scheduleEvent.eventStartTime.toString().toLowerCase().search('am') !== -1 ||  scheduleEvent.eventStartTime.toString().toLowerCase().search('pm') !== -1) {
        startDate = this._timeToDatePipe.transform(scheduleEvent.dateOfEvent, scheduleEvent.eventStartTime);
    }
   
    let locStart: Date = new Date(startDate);
    let locEnd: Date = new Date(endDate);
    if(locEnd.getHours()-locStart.getHours()< 0 && this.continueToSaveClicked != 2){
      this.showStartTimeEndTimeValid = true;
      this.dialogSummary = "Error";
      this.dialogMsg = ErrorMessages['clinicLengthAlert'];
      this.isTimeValid = 2;
      return false;
  }
  else if(locEnd.getHours()-locStart.getHours()> 8 && this.continueToSaveClicked != 2){     
    this.continueToSaveClicked = 0;
    this.showStartTimeEndTimeValid = true;
    this.dialogSummary = "Alert";
    this.dialogMsg = ErrorMessages['clinicLengthMoreThan8Hrs'];
    this.isTimeValid = 1; 
      return false;
  }
      scheduleEvent.dateOfEvent = this._datePipe.transform(scheduleEvent.dateOfEvent, "MM/dd/yyyy");
      scheduleEvent.numberOfAttendees = +this.schedule_event_form.controls['numberOfAttendees'].value;
      scheduleEvent.eventTypeId = +this.schedule_event_form.controls['eventTypeId'].value;
      scheduleEvent.walgreensRoleId = +this.schedule_event_form.controls['walgreensRoleId'].value;
      if (this.schedule_event_form.controls['provideEducationalMaterial'].value == "1") {
        scheduleEvent.provideEducationalMaterial = true;
      }
      else {
        scheduleEvent.provideEducationalMaterial = false;
      }

      scheduleEvent.description = this.schedule_event_form.controls['description'].value;
      scheduleEvent.walgreensRoleDescription = this.schedule_event_form.controls['walgreensRoleDescription'].value;
      scheduleEvent.eventDescription = this.schedule_event_form.controls['eventDescription'].value;
      this.disable_button = true;
      this.schedule_event_service.upadteScheduleEvent(scheduleEvent).subscribe((data) => {
        if (data === "success") {
          this.disable_button = false;
          this.displayOnSucc = true;

        }
      },
      err=>{
        this.disable_button = false;
      }
      );

    } else {
      this.utility.validateAllFormFields(this.schedule_event_form); //{7}
    }
  }
  onScheduleDateSelected(selectedDate: Date) {
    this.schedule_event_form.controls['eventdate'].setValue(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12, 30, 55));
  }
  reset() {
    this.schedule_event_form.reset();
  }

  eventTypeClick() {
    this.isEventTypeReadOnly = true;
    this.schedule_event_form.controls['eventDescription'].setValue("");
    this.schedule_event_form.controls['eventDescription'].clearValidators();
    this.schedule_event_form.controls['eventDescription'].updateValueAndValidity();

  }
  eventTypeOtherClick() {
    this.isEventTypeReadOnly = false;
  }
  walgreensRoleClick() {
    this.isWallgreenRoleReadOnly = true;
    this.schedule_event_form.controls['walgreensRoleDescription'].setValue("");
    this.schedule_event_form.controls['walgreensRoleDescription'].clearValidators();
    this.schedule_event_form.controls['walgreensRoleDescription'].updateValueAndValidity();
  }
  walgreensRoleOtherClick() {
    this.isWallgreenRoleReadOnly = false;
  }
  onClick() {
    this.message_service.sendProfileChange("profilechange");
    this.schedule_event_check = this.deepCopy(this.schedule_event_form.value);
    this.router.navigateByUrl('/communityoutreach/storehome');
  }
  onCancel() {

    var compare_objects = false;

    if (this.event_details != null && this.event_details.contactLogPk != null) {
      compare_objects = this.utility.compareTwoObjects(this.schedule_event_check, this.schedule_event_form.value);
      if (compare_objects) {
        this.doNotSaveText = "Cancel";
        this.setUp = "Ok";
        this.showDialog(ErrorMessages['unSavedData'], ErrorMessages['schedule_alert']);
      }
      else {
        this._location.back();
      }
    }
    else {
      if (!this.event_details || (this.event_details != null && this.event_details.contactLogPk == null)) {
        this._location.back();
      }
      else {
        this.continuePopUp = true;
      }


    }

  }
  doNotSave() {

    this.schedule_event_check = this.deepCopy(this.schedule_event_form.value);
    this._location.back();
  }
  Continue() {
    this.savePopUp = false;
  }
  continueSetup() {
    this.continuePopUp = false;
  }
  showDialog(msgSummary: string, msgDetail: string) {
    this.savePopUp = true;
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
  }

  onlyNumbers(event: any) {
    Util.onlyNumbers(event);
  }
  getModelDiff() {
    var diff = this.utility.getDifference(this.schedule_event_check, this.schedule_event_form.value);
    return diff;
  }

  leaveRoute() {
    this.showCanRouteChange = false;
    this.message_service.setCanShowRouteChangeConfirmation(false);
    this.message_service.setCanRouteChange(true);
  }
  cancelRoute() {
    this.showCanRouteChange = false;
    this.message_service.setCanShowRouteChangeConfirmation(false);
    this.message_service.setCanRouteChange(false);
  }
  deepCopy(oldObj: any) {
    var newObj = oldObj;
    if (oldObj && typeof oldObj === "object") {
      newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
      for (var i in oldObj) {
        newObj[i] = this.deepCopy(oldObj[i]);
      }
    }
    return newObj;
  }
  disableButton() {
    if (this.disable_button) {
      return 'false';
    }
    else {
      return '';
    }

  }
  EditTimes(){
    this.showStartTimeEndTimeValid=false;
  }
  ContinueToNextStep(){
    this.showStartTimeEndTimeValid=false;
    this.continueToSaveClicked = 2;
    this.onSubmit();
  }
}
