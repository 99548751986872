import { Component, OnInit,ViewChild } from '@angular/core';
import * as appSettings from '../../globals';
import { ApplicationSettings } from '../../../common/application-settings';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MinimumshotexceptionsService } from '../../services/minimumshotexceptions.service';
import { MinimumShotExceptions } from '../../../../models/MinimumShotExceptions';
import { saveAs as importedSaveAs } from "file-saver";
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from "ngx-spinner";
import {TableModule, Table} from 'primeng/table'

@Component({
  selector: 'app-minimum-shot-exceptions',
  templateUrl: './minimumshotexceptions.component.html',
  styleUrls: ['./minimumshotexceptions.component.css']
})
export class MinimumShotExceptionsComponent implements OnInit {
  minimumShotExceptionsForm: FormGroup;
  minimumShotExceptionsReportArray: any;
  ddlSeason: number;
  ddlClinicStatus: string;
  ddlClinicType: string;
  fromDate: Date;
  toDate: Date;
  ddlFormat: any;
  minDate: any;
  maxDate: any;
  season: any = [];
  filterByDate: boolean = false;
  canExport: boolean = true;
  outreachStartDate = ApplicationSettings.outreachStartDateIP;
  groupIdLaunchDate = "05/01/2020";
  seasons: any = [];
  private isCurrentSeason = 1;
  totalRecords: number;
  loading: boolean;
  includeDates: boolean = false;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  private currentSeason = appSettings.ipSeason;

  private from_date: string;
  private to_date: string;
  rowGroupMetadata: any;
 first = 0;
  showGrid:boolean=false;
  covidOnly:boolean = false;
  constructor(private minimum_shot_exceptions_service: MinimumshotexceptionsService, private spinner: NgxSpinnerService,private fb: FormBuilder) { }
  @ViewChild('minimumshottable', { static: false }) table:Table;
  ngOnInit() {
    for (let report_year: number = (new Date()).getFullYear(); report_year > (new Date(this.groupIdLaunchDate)).getFullYear(); report_year--) {
      this.seasons.push({
        "yearName": report_year.toString() + "-" + (report_year + 1).toString(),
        "yearCode": report_year.toString()
      });
    }
    this.includeDates = false;
    this.ddlSeason = (new Date(this.outreachStartDate)).getFullYear();
    this.setDates();
    this.ddlFormat = "0";
    this.fromDate = this.minDate;
    this.toDate = this.maxDate;
    this.setFormControls();
    //this.generateReport();
 	setTimeout(()=>{
      this.setCurrentPage(1);
    },2000)
  }
setFormControls() {
    this.minimumShotExceptionsForm = this.fb.group({
      'fromDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'toDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
      'ddlSeason': [this.currentSeason],
      'includeDates': [false],
      'ddlFormat': ["0"],
      'covidOnly': [true]
    });
  }
  generateReport(): void { 
    if (!this.showGrid) {
      this.spinner.show();
    } else {
      this.loading = true;
    }
    let data = this.minimumShotExceptionsForm.value;
    let d1 = data.fromDate;
    let d2 = data.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
  
    if (data.ddlSeason != this.currentSeason) {
      this.isCurrentSeason = 0;
    }
    else {
      this.isCurrentSeason = 1;
    }
    let mse = new MinimumShotExceptions(data.fromDate, data.toDate, JSON.parse(this.isCurrentSeason.toString()), JSON.parse(data.includeDates.toString()), "", "", this.covidOnly);
    this.minimum_shot_exceptions_service.getExceptionShotDetailsReport(mse).subscribe((resultArray: any) => {
      this.totalRecords = resultArray.length;
      this.minimumShotExceptionsReportArray = resultArray;
      setTimeout(() => {
        this.spinner.hide();
        this.loading = false;
        this.canExport = true;
        this.showGrid = true;
		setTimeout(()=>{
          this.setCurrentPage(1);
         },500)
      }, 1000)
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
      this.minimumShotExceptionsReportArray = [];
      this.totalRecords = 0;
    });

  }

  generateReportFormat(): void { 
    this.loading = true;
    let data = this.minimumShotExceptionsForm.value;
    let d1 = data.fromDate;
    let d2 = data.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`; 
    if (data.ddlSeason != this.currentSeason) {
      this.isCurrentSeason = 0;
    }
    else {
      this.isCurrentSeason = 1;
    }
    let mse = new MinimumShotExceptions(data.fromDate, data.toDate, JSON.parse(this.isCurrentSeason.toString()), JSON.parse(data.includeDates.toString()), "MinimumShotExceptions", "xlsxv", this.covidOnly);

    this.minimum_shot_exceptions_service.exportReportData(mse).subscribe((res:any) => {
      importedSaveAs(res.body, "MinimumShotExceptions.xlsx");
      this.loading = false;
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
  setDates() {
    
    if(this.minimumShotExceptionsForm != undefined){
      this.ddlSeason=this.minimumShotExceptionsForm.controls['ddlSeason'].value;
    }  
  
    let tempMinDate: Date;
    let tempMaxDate: Date;
    tempMinDate = new Date(ApplicationSettings.getIMmunizationStartDate(this.ddlSeason));
    tempMaxDate = new Date(ApplicationSettings.getImmunizationEndDate(this.ddlSeason));
    this.minDate = new Date(tempMinDate);
    this.maxDate = new Date(tempMaxDate);
    
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    this.canExport = false;
    if(this.minimumShotExceptionsForm != undefined){
    this.minimumShotExceptionsForm.controls['fromDate'].setValue(this.fromDate);
    this.minimumShotExceptionsForm.controls['toDate'].setValue(this.toDate);
    }
    else{
      
    }
  }
 setCurrentPage(n: number) {
//     let paging = {
//     first: ((n - 1) * this.table.rows),
//     rows: this.table.rows
// };
this.first=((n - 1) * this.table.rows);
}
  setCalendarDates() {
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    this.canExport = false;
  }
  reset(): void {
    //this.loading = true;
    this.ddlSeason = this.currentSeason;
    this.ddlFormat = 0;
    this.setDates();
    this.showGrid = false;
    this.setFormControls();
    //this.generateReport();
  }
  validateExportType() {
    if (this.ddlFormat == "0" || !this.canExport)
      return true;
    else
      return false;
  }
  canExportOnchange() {
    this.canExport = false;
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
