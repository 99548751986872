
    export class ClinicList {
        businessPk: number;
        outreachBusinessPk: number;
        clinicPk: number;
        clinicType: string;
        storeId: number;
        districtNumber: number;
        businessName: string;
        naClinicLocation: string;
        naClinicAddress1: string;
        naClinicAddress2: string;
        naClinicCity: string;
        naClinicState: string;
        naClinicZip: string;
        storeAddress: string;
        isStoreUpdated: boolean;
        contactPhone: string;
        naClinicDate: Date;
        isCurrent: boolean;
        isCanceled: boolean;
        isChecked: boolean;
    }

    export class DashBoard {
        userPk: number;
        assignedTo: number;
        pageIndex: number;
        filterBy: string;
        filterById: string;
        searchByValue: string;
        sortByField: string;
        totalCount: number;
        startIndex: number;
        finalIndex: number;
        noOfRecordsInPage:number;
        sortOrder:boolean;
        clinicList: ClinicList[];
    }
