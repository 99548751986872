import { Injectable } from '@angular/core';
import { WeeklyVaccinePurchasing } from '../../../models/WeeklyVaccinePurchasing';
import { SessionDetails } from '../../../utility/session';
import { Util } from '../../../utility/util';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
@Injectable()
export class WeeklyvaccinepurchasingService {
  private _baseURL: string  = environment.API_URL;
  private _url: string = this._baseURL;

  public filename: any;
  
  constructor(private http: HttpClient) { }
  public getVaccinePurchase(season,date, isCovid){
    let url = this._url+"/api/JsWeeklyVaccineReportSeasonWeeks_get/"+date+"/"+season+"/"+isCovid ;
    return this.http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  public exportReportData(weekly_vaccine_purchase:WeeklyVaccinePurchasing) {
    let user_data = SessionDetails.GetUserInfo();
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,user_data.userName + ':'+ user_data.password);                    
    let url = this._url + "/api/JsWeeklyVaccinePurchases_downloadReport";
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    return this.http.post(url,weekly_vaccine_purchase, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'}).pipe(catchError(Util.handleErrorV2));
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });
  }
}
