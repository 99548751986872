import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../../../common/application-settings';
import { Router } from '../../../../../../node_modules/@angular/router';
import { HighLevelStatus } from '../../../../models/HighLevelStatus';
import { SessionDetails } from '../../../../utility/session';
//import * as appReportSettings from '../../globals';
import { saveAs as importedSaveAs } from "file-saver";
import { HighlevelstatusService } from '../../services/highlevelstatus.service';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { states } from '../../../../JSON/States';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-highlevelstatusreport',
  templateUrl: './highlevelstatusreport.component.html',
  styleUrls: ['./highlevelstatusreport.component.css']
})
export class HighlevelstatusreportComponent implements OnInit {
  userProfile: any;
  showFilters: boolean = false;
  isPCA : boolean = false;
  highLevelStatusArray: any;
  private outreachEffort: number;
  totalRecords: number;
  ddlFormat: any;
  minDate: any;
  maxDate: any;
  fromDate: any;
  toDate: any;
  loading: boolean = false;
  canExport: boolean = true;
  showDistrictNames: boolean = false;
  districtNames: any = [];
  ddlDistrict: string;
  reportName:string="HighLevelStatus";
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  covidOnly: boolean = false;
  stateList: any = [];
  ddlState : string = "All";
  private selectedDistrictId:number=null;
  highLevelForm: FormGroup;
  constructor(private router: Router, private highLevelStatus: HighlevelstatusService, private fb: FormBuilder) {
    this.userProfile = SessionDetails.GetUserProfile();
    this.showDistrictNames = (this.userProfile.userRole.toLowerCase() != "admin" && this.userProfile.userRole.toLowerCase() != "district manager");
    if (this.router.url.indexOf('immunization') > -1) {
      this.showFilters = true;
      this.outreachEffort = ApplicationSettings.outreachEffortIP;
    }
    else if( this.router.url.indexOf('patientcareadvocacy') > -1){
      this.isPCA=true;
      this.outreachEffort = 4;
    } else {
      this.outreachEffort = ApplicationSettings.outreachEffortSO;
    }
  }
  ngOnInit() {
    if (this.showDistrictNames) {
      this.highLevelStatus.getDistrictNames(this.userProfile.userPk).subscribe((resultArray: any) => {
        this.districtNames.push({
          "districtNumber": 0,
          "Name": "All Districts",
          "userPk": this.userProfile.userPk
        });
        resultArray.forEach(element => {
          this.districtNames.push(element);
        });
      });
      this.ddlDistrict = this.userProfile.userPk;
      this.selectedDistrictId = 0;
    }
    this.ddlFormat = "0";

    if (this.showFilters || this.isPCA) {
      this.setDates();
    }else{
      this.fromDate = (ApplicationSettings.getOutreachStartDate());
      this.toDate = (ApplicationSettings.getOutreachEndDate());
      if(this.outreachEffort == 1){
        //this.fromDate = new Date("09/01/" + this.fromDate.getFullYear());
        let dt = new Date();
        this.fromDate = ApplicationSettings.getSRStartDate(dt.getFullYear());
        this.toDate = ApplicationSettings.getSREndDate(dt.getFullYear());
      }
    }
    if(this.isPCA){
      this.reportName= this.reportName + "PCA";
    }
    this.setFormControls();
    this.getStates();
    this.generateReport();
  };
  setFormControls(){
    this.highLevelForm = this.fb.group({
      'fromDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'toDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
      'ddlDistrict': ["0"],
      'ddlState': ["All"],
      'ddlFormat': ["xlsxv"]
    });
  }
  getStates():void{
    this.stateList = states;
  }

  generateReport(): void {
    this.loading = true;
    let hlsr: HighLevelStatus;
     
      let f_date = new Date(this.fromDate);
      let t_date = new Date(this.toDate);
      var fr_date = f_date.getMonth() + 1 + "/" + f_date.getDate() + "/" + f_date.getFullYear();
      var tt_date = t_date.getMonth() + 1 + "/" + t_date.getDate() + "/" + t_date.getFullYear();
      let formData = this.highLevelForm.value;
      this.ddlState=this.highLevelForm.get("ddlState").value;
      hlsr = new HighLevelStatus(this.ddlState, this.outreachEffort.toString(), ((this.showDistrictNames) ? this.ddlDistrict : this.userProfile.userPk), formData.ddlDistrict, new Date(formData.fromDate).toLocaleDateString(),new Date(formData.toDate).toLocaleDateString() , this.covidOnly, "", "");
    this.highLevelStatus.getHighLevelStatusReportData(hlsr).subscribe((resultArray: any) => {
      this.totalRecords = resultArray.length;
       
      this.highLevelStatusArray = resultArray;
      setTimeout(() => {
        this.loading = false;
        this.canExport = true;
      }, 1000)
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.highLevelStatusArray = [];
      this.totalRecords = 0;
    });
  }
  generatereportformat(): void {
    this.loading = true;
    let hlsr: HighLevelStatus;
    
      let f_date = new Date(this.fromDate);
      let t_date = new Date(this.toDate);
      var fr_date = f_date.getMonth() + 1 + "/" + f_date.getDate() + "/" + f_date.getFullYear();
      var tt_date = t_date.getMonth() + 1 + "/" + t_date.getDate() + "/" + t_date.getFullYear();
      let formData = this.highLevelForm.value;
      hlsr = new HighLevelStatus(this.ddlState, this.outreachEffort.toString(), ((this.showDistrictNames) ? this.ddlDistrict : this.userProfile.userPk), this.selectedDistrictId,  formData.fromDate, formData.toDate, this.covidOnly, this.reportName, 'xlsxv');
    
    this.highLevelStatus.exportReportData(hlsr).subscribe((res:any) => {
      importedSaveAs(res.body, this.reportName+".xlsx");
      this.loading = false;
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }

  setDates() {
    let tempMinDate: Date;
    let tempMaxDate: Date;
    var tmp_year = new Date();
    if (this.isPCA) {
      tempMinDate = new Date(ApplicationSettings.getPCAStartDate(tmp_year.getFullYear()));
      tempMaxDate = new Date(ApplicationSettings.getPCAEndDate(tmp_year.getFullYear()));
    }
    else {
      tempMinDate = new Date(ApplicationSettings.getIMmunizationStartDate(tmp_year.getFullYear()));
      tempMaxDate = new Date(ApplicationSettings.getImmunizationEndDate(tmp_year.getFullYear()));
      if (this.outreachEffort == 1) {
        // tempMinDate = new Date("09/01/" + tempMinDate.getFullYear());
        // tempMaxDate = new Date("08/31/" + tempMaxDate.getFullYear());
        let dt = new Date();
        tempMinDate = ApplicationSettings.getSRStartDate(dt.getFullYear());
        tempMaxDate = ApplicationSettings.getSREndDate(dt.getFullYear());
      }
    }
    this.minDate = new Date(tempMinDate);
    this.maxDate = new Date(tempMaxDate)
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    this.canExport = false;
  }
  reset() {
    this.ddlFormat = "xlsxv";
    this.ddlState = "All";
    this.covidOnly = false;
    if (this.showFilters || this.isPCA) {     
      this.ddlDistrict = this.userProfile.userPk;
      this.selectedDistrictId = 0;
      this.setDates();
    }else{
      this.fromDate = (ApplicationSettings.getOutreachStartDate());
      this.toDate = (ApplicationSettings.getOutreachEndDate());
       if(this.outreachEffort == 1){
        let dt = new Date();
        this.fromDate = ApplicationSettings.getSRStartDate(dt.getFullYear());
        this.toDate = ApplicationSettings.getSREndDate(dt.getFullYear());
        //this.fromDate = new Date("09/01/" + this.fromDate.getFullYear());
        //this.toDate = new Date("08/31/" + this.toDate.getFullYear());
      }
      this.ddlDistrict = this.userProfile.userPk;
      this.selectedDistrictId = 0;
    }
    this.setFormControls();
    this.generateReport();
  }

  validateExportType() {
    if (this.ddlFormat == "0" || !this.canExport)
      return true;
    else
      return false;
  }
  canExportOnchange() {
    this.canExport = false;
  }

  onDistrictChange(event: Event) {
    let ctrl = event.target || event.currentTarget || event.srcElement;
    let select_element_text = ctrl['options'][ctrl['options'].selectedIndex].text;
 
    var tmp = this.districtNames.filter(district => district.userPk.toString() == this.ddlDistrict && select_element_text.indexOf(district.districtNumber)>-1);
    this.selectedDistrictId = (tmp.length > 0) ? tmp[0].districtNumber : 0;
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}


