export class RestrictedClinicsAndOpportunitiesReport {
    fromDate:string;
    toDate:string;
    includeDates:string;
    state:string;
    reportType:string;
    reportName:string = null;
    outputType:string = null;
    constructor(from_date:string,to_date:string,include_dates:string,state:string,report_type:string,report_name?: string,output_type?: string) {
        this.fromDate = from_date;
        this.toDate = to_date;
        this.includeDates=include_dates;
        this.state=state;
        this.reportType=report_type;
        this.outputType = output_type;
        this.reportName = report_name;
    }
}