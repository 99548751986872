   export class OpportunityInfo {
        businessPk: number;
        outreachBusinessPk: number;
        clinicAgreementPk: number;
        contractedStoreId: number;
        storeId: number;
        storeState: string;
        regionNumber: number;
        clinicType: number;
        businessName: string;
        firstName: string;
        lastName: string;
        businessAddress: string;
        businessAddress2: string;
        businessCity: string;
        businessState: string;
        businessZip: string;
        jobTitle: string;
        businessContactEmail: string;
        phone: string;
        maxClinicLocationId: number;
        naClinicAddlComments: string;
        naClinicSplBillingInstr: string;
        recipientId: string;
        naClinicPlanId: string;
        naClinicGroupId: string;
        pharmacistName: string;
        pharmacistPhone: string;
        totalHours: string;
        naClinicCopay:any;
        naClinicCoverageType:any;
    }

    export class ClinicImzQtyList {
        clinicPk: number;
        immunizationPk: number;
        immunizationName: string;
        paymentTypeId: number;
        paymentTypeName: string;
        estimatedQuantity: number;
        totalImmAdministered: number;
        vouchersDistributed: number;
    }

    export class ClinicList {
        clinicPk: number;
        clinicStoreId: number;
        clinicNumber: number;
        naClinicLocation: string;
        naContactFirstName: string;
        naContactLastName: string;
        naContactEmail: string;
        naClinicAddress1: string;
        naClinicAddress2: string;
        naClinicCity: string;
        naClinicState: string;
        naClinicZip: string;
        naClinicContactPhone: string;
        clinicDate: Date;
        clinicScheduledOn: Date;
        naClinicStartTime: string;
        naClinicEndTime: string;
        isReassign: number;
        isNoClinic: boolean;
        confirmedClientName: string;
        clinicLatitude: number;
        clinicLongitude: number;
        isCompleted: boolean;
        isConfirmed: boolean;
        isCancelled: boolean;
        coPayFLUGroupId: string;
        coPayROUTINEGroupId: string;
        fluExpiryDate: Date;
        routineExpiryDate: Date;
        coOutreachTypeId: number;
        coOutreachTypeDesc: string;
        clinicImzQtyList: ClinicImzQtyList[];
        constructor(){
            this.isNoClinic = false;
            //this.clinicDate = new Date();
            //this.naClinicStartTime = new Date(this.clinicDate.getFullYear(), this.clinicDate.getMonth(), this.clinicDate.getDate(), 8, 0, 0);
        }
    }

    export class ClinicImmunizationList {
        pk: number;
        clinicPk: number;
        immunizationPk: number;
        immunizationName: string;
        price: number;
        paymentTypeId: number;
        paymentTypeName: string;
        sendInvoiceTo: number;
        name: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        zip: string;
        phone: string;
        email: string;
        isTaxExempt: boolean;
        isCopay: boolean;
        copayValue: number;
        isVoucherNeeded: boolean;
        voucherExpirationDate: Date;
    }

    export class ClinicsUpdateHistoryLogList {
        pk: number;
        updatedOn: Date;
        updateAction: string;
        updatedField: string;
        updateSubCategory: string;
        updatedValue: string;
        updatedBy: string;
    }

    export class ErrorS {
        errorCode: number;
        errorMessage: string;
    }

    export class LocalClinicDetails {
        opportunityInfo: OpportunityInfo;
        clinicList: ClinicList[];
        clinicImmunizationList: ClinicImmunizationList[];
        clinicsUpdateHistoryLogList: ClinicsUpdateHistoryLogList[];
        errorS: ErrorS;
    }


