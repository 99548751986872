import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceText'
})
export class ReplaceTextPipe implements PipeTransform {
  transform(value: any, outreach_pk:any, is_event:boolean, args?: any): any {
    if (value.toLowerCase() == 'contact client') {
      return ((!is_event)?"Contact Client/Organization":((outreach_pk==4)?"Contact Organization":value));
    }
    if (value.toLowerCase() == 'complete/report event') {
      return 'Complete';
    }
    return value;
  }
}