
import {throwError as observableThrowError,  Observable, pipe } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SessionDetails } from '../../../utility/session';
import { Util } from '../../../utility/util';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class GroupiduploaderService {

  constructor(private http: HttpClient) { }

  getGroupIdsCount() {
    let url = environment.API_URL + environment.RESOURCES.GET_UNASSIGNED_GROUPID_COUNT;
    return this.http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  upload(files: FormData, data: any, ipSeason: number) {
    let headers: Headers = new Headers();
    let url = environment.API_URL + environment.RESOURCES.GROUPID_UPLOAD + "/" + data.type + "/" + ipSeason + "/" + data.isArchive;
    let userData = SessionDetails.GetUserInfo();
    //let token = btoa(userData.userName + ':' + userData.password);
     let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders().set('Authorization','Basic '+ usernamePassword);

    return this.http.post(url, files, {headers:req_headers})
    .pipe(catchError(Util.handleErrorV2));
  }
  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    //console.warn(errMsg);
    return observableThrowError(errMsg);
  }
}
