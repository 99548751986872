import { Component, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  header_title : string;
  constructor(router:Router,titleService:Title) { 
    router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        let title = this.getTitle(router.routerState, router.routerState.root).join('-');
       titleService.setTitle(title);
       if(title=="contract")
       this.header_title = "Community Off-Site Agreement";
       else if(title=="addBusiness")
       this.header_title = "Add an Opportunity";
       else if(title=="editBusiness")
       this.header_title = "Business Profiles";
       else
       this.header_title= "COMMUNITY OUTREACH"
      }
    });
  }

  ngOnInit() {
  }
  getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if(state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

}
