import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Util } from '../../../utility/util';


@Injectable()
export class WeeklygroupidassignmentsService {
  private _baseURL: string = "http://localhost:52823";
  private _url: string = this._baseURL + "/getGroupIdReportSeasonWeeks";
  constructor(private http: HttpClient) { }
  public getGroupId(season,date){
    let url = this._url+"/"+date+"/"+season ;
    return this.http.get(url).pipe(catchError(Util.handleErrorV2));
  }

}
