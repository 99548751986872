import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-clinicdetailsstatus',
  templateUrl: './clinicdetailsstatus.component.html',
  styleUrls: ['./clinicdetailsstatus.component.css']
})
export class ClinicdetailsstatusComponent implements OnInit {
  subscription: Subscription;
  step: number = 1;
  clinicstatus: string = "Completed Clinic";
  clickedPath = 'LocalClinicProgramDetails';
  message: any;
  store_id: any;

  @Input("totalSteps") totalSteps: any;
  @Input("viewSteps") viewSteps: any;
  @Input("currentStep") currentStep: any;
  @Input("isNoClinic")  isNoClinic:number;

  step1Part1Text:string = "Contract Approved";
  step1Part2Text:string = "- Contact Client";
  step2Text:string = "Confirmed Clinic";
  private onDestroy$ = new Subject<void>();

  constructor(private router: Router) {
    if (router.url.search('LocalClinicProgramDetails') > 0) {
      this.clickedPath = 'LocalClinicProgramDetails';
    }
    else if (router.url.search('CommunityOutreachProgramDetails') > 0) {
      this.clickedPath = 'CommunityOutreachProgramDetails';
    }
    else if (router.url.search('CorporateClinicProgramDetails') > 0) {
      this.clickedPath = 'CorporateClinicProgramDetails';
    }
  }
  ngOnInit() {
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes["viewSteps"]) {
      this.viewSteps = changes["viewSteps"].currentValue;
    }
    if (changes["totalSteps"]) {
      this.totalSteps = changes["totalSteps"].currentValue;
    }
    if (changes["isNoClinic"]) {
      this.isNoClinic = changes["isNoClinic"].currentValue;
    }
    this.currentStep = ((changes["currentStep"] && changes["currentStep"].currentValue) ? changes["currentStep"].currentValue : this.step);
    
    this.GoToStep(this.currentStep);
  }

  GoToStep(step: number) {
    if (step == 2) {
      step = 2;
      this.clinicstatus = 'Completed Clinic';
    }
    if (step == -1) {
      if (this.totalSteps == 2) {
        step = 2;
        this.clinicstatus = 'Cancelled Clinic';
      } else if (this.totalSteps == 3) {
        step = 3;
        this.clinicstatus = 'Cancelled Clinic';
      }
    }

    this.step = step;
    if(this.isNoClinic==1){
      this.step1Part1Text = "Provide Vouchers";
      this.step1Part2Text = "";
      this.step2Text = "Vouchers Provided";  
      if(this.clinicstatus !== 'Cancelled Clinic'){
        this.clinicstatus = "Completed";
      }
    }
    else {
      this.step1Part1Text = "Contract Approved";
      this.step1Part2Text = "– Contact Client";
      this.step2Text = "Confirmed Clinic";
    }
    return;
  }
}
