// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-msg {
  color: red;
}
 .has-error label{
  color:red!important;
  }
  .has-error input{
  border:1px solid red !important;
  } 
  .has-error select{
  border:1px solid red !important;
  } 
`, "",{"version":3,"sources":["webpack://./src/app/modules/contractaggreement/components/businesscontactpage/businesscontactpage.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;CACC;EACC,mBAAmB;EACnB;EACA;EACA,+BAA+B;EAC/B;EACA;EACA,+BAA+B;EAC/B","sourcesContent":[".error-msg {\r\n  color: red;\r\n}\r\n .has-error label{\r\n  color:red!important;\r\n  }\r\n  .has-error input{\r\n  border:1px solid red !important;\r\n  } \r\n  .has-error select{\r\n  border:1px solid red !important;\r\n  } \r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
