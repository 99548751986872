// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-content {
    padding: 5px 0;
}

.radio, .checkbox {
    margin: 0 2px 5px 0 !important;
}

.error-msg {
    color: red;
  }
   .has-error label{
    color:red!important;
    }
    .has-error input{
    border:1px solid red !important;
    } 
 `, "",{"version":3,"sources":["webpack://./src/app/modules/store/components/schedule-event/schedule-event.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,UAAU;EACZ;GACC;IACC,mBAAmB;IACnB;IACA;IACA,+BAA+B;IAC/B","sourcesContent":[".tab-content {\r\n    padding: 5px 0;\r\n}\r\n\r\n.radio, .checkbox {\r\n    margin: 0 2px 5px 0 !important;\r\n}\r\n\r\n.error-msg {\r\n    color: red;\r\n  }\r\n   .has-error label{\r\n    color:red!important;\r\n    }\r\n    .has-error input{\r\n    border:1px solid red !important;\r\n    } \r\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
