import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { outreachType } from '../../../JSON/outReachType';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { Router } from '@angular/router';
import { outreachTypePCA } from '../../../JSON/outReachTypePCA';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class OpportunitiesService {

  opportunities: any[] = [];
  private business_pk: any;
  data_role: any;
  constructor(private _http: HttpClient, private router: Router,private util:Util) {

  }
  
  getOpportunities(store_id: number, user_pk: number, user_role: string) {  
    let quarter = this.util.getCurrentQuarter();
    let url = environment.API_URL + environment.RESOURCES.GET_ASSIGNED_BUSINESSES + "/" + store_id
      + "/" + user_pk + "/" + user_role + "/" + quarter
       + " ";
    return this._http.get(url,Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }

  getMetricsCounts(user_pk) {
    let url=environment.API_URL + environment.RESOURCES.GET_METRICS_COUNTS+"/"+user_pk;
    return this._http.get(url,Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }
  getActionItemsCounts(user_pk,store_id) {
    let url=environment.API_URL + environment.RESOURCES.GET_ACTION_ITEM_COUNTS+"/"+user_pk+"/"+store_id;
    return this._http.get(url,Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }
  getPCADashboardSnapshot(user_pk,store_id) {
    
    let url=environment.API_URL + environment.RESOURCES.GET_PCA_DASHBOARD_SNAPSHOT+"/"+user_pk+"/"+store_id;
    return this._http.get(url,Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }
  setBusinessPk(data: any) {
    this.business_pk = data;
    localStorage.setItem("business_pk", data);
  }

  getNationalContractClients() {
    let url=environment.API_URL + environment.RESOURCES.GET_NATIONAL_CONTRACT_CLIENTS;
    return this._http.get(url,Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
    //return Observable.of(dncClients);
  }
  deleteNationalContractClients(DNCData: any,userPk:any) {
    let url = environment.API_URL + environment.RESOURCES.DELETE_NATIONAL_CONTRACT_CLIENTS + "/"+userPk;
    return this._http.post(url, DNCData,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  // setLoginUserInfo(user_role: any) {
  //   return this.http.get(this.baseUrl + "GetUserInformation?user_role=" + user_role + "").map((response: Response) => response.json());
  // }
  getOutreachStatus(): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_OUTREACH_STATUS_LABELS;
    return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  getOutreachType() {
    return outreachType;
  }
  getOutreachTypePCA() {
    return outreachTypePCA;
  }
  
  getHCSClinicDetails(dash_board_data): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_DASHBOARD_DATA;
    return this._http.post(url,dash_board_data,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  getLocalLeads(local_leads): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_LOCAL_LEADS;
    return this._http.post(url,local_leads,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  getConfigDates(): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_CONFIG_DATES;
    return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  // private handleError(error: any) {
  //   let errMsg = (error.message) ? error.message :
  //     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
  //   //console.warn(errMsg);
  //   return Observable.throw(errMsg);
  // }

  getPriorityOpportunities(local_leads): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_PRIORITY_OPPORTUNITIES;
    return this._http.post(url,local_leads,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  updateOutreachStatusToNoOutreach(outreachBusinessPk: number){
    const url = `${environment.API_URL}${environment.RESOURCES.UPDATE_OUTREACH_STATUS_TO_NOOUTREACH}/${outreachBusinessPk}`;
    return this._http.put(url, null, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  deleteOpporunityInfo(businessPk: number){
    const url = `${environment.API_URL}${environment.RESOURCES.DELETE_OPPORTUNITY_INFO}/${businessPk}`;
    return this._http.delete(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
}