// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.oSearchHCS{
    background: white;
    position: absolute;
    width: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    right: 20px;
    padding: 10px;
    z-index: 9;
}

#Clinics  th.ui-state-default {
    background-color: #a43794 !important;
    color:#ffffff !important;
}

#hcActionItems a {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/admin/components/client-scheduler-appt-tracker/client-scheduler-appt-tracker.component.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,4EAA4E;IAC5E,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,UAAU;AACd;;AAEA;IACI,oCAAoC;IACpC,wBAAwB;AAC5B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["\r\n.oSearchHCS{\r\n    background: white;\r\n    position: absolute;\r\n    width: 400px;\r\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\r\n    text-align: center;\r\n    right: 20px;\r\n    padding: 10px;\r\n    z-index: 9;\r\n}\r\n\r\n#Clinics  th.ui-state-default {\r\n    background-color: #a43794 !important;\r\n    color:#ffffff !important;\r\n}\r\n\r\n#hcActionItems a {\r\n    text-decoration: underline;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
