import { Component, OnInit, ViewChild } from '@angular/core';
import { SessionDetails } from '../../../../utility/session';
import { ProgramType } from '../../../../utility/enum';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { FollowUp } from '../../../../models/FollowUp';
import { ScheduleeventService } from '../../services/scheduleevent.service';
import { Router } from '@angular/router';
import { FollowUpDetailsList, FollowUpDetails } from '../../../../models/FollowUpDetailsList';
import { MessageServiceService } from '../../services/message-service.service';
import { Location, DatePipe } from '@angular/common';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { Util } from '../../../../utility/util';
import { FormCanDeactivate } from '../../../../guards/form-can-deactivate/form-can-deactivate';

@Component({
  selector: 'app-followup',
  templateUrl: './followup.component.html',
  styleUrls: ['./followup.component.css'],
  providers: [DatePipe]
})
//
export class FollowupComponent extends FormCanDeactivate implements OnInit {
  @ViewChild('form')
  form: NgForm;
   
  showHints: boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : true;
  pageName: string = "followUp";

  defaultDate: Date = new Date();
  maxDateValue: Date = new Date();
  minDateValue: Date = new Date();
  date3: Date;
  sr_followup: boolean = false;
  ip_followup: boolean = false;
  followup_date: Date;
  followupForm: FormGroup;
  followup_save: any[] = [];
  get_contact_log: any;
  follow_up: FollowUp;
  displayOnSucc: boolean = false;
  get_follow_up: any;
  get_followup_data: any = 0;
  status: boolean = false;
  contact_log_IP: any = 0;
  contact_log_SR: any = 0;
  page_name: any = "";
  savePopUp: boolean = false;
  dialogSummary: string;
  dialogMsg: string;
  follow_up_details: FollowUpDetailsList = new FollowUpDetailsList();
  doNotSaveText: string = "";
  setUp: string = "";
  user_profile: any;
  showCanRouteChange: boolean;
  disable_button: boolean = false;
  follow_up_title:any;
  email_pattern = /^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/;
  formError = false;
  constructor(private formBuilder: FormBuilder, private _location: Location,
    private schedule_event_service: ScheduleeventService, private router: Router, private utility: Util, private message_service: MessageServiceService,
    private date_pipe: DatePipe) {
    super();
    this.message_service.getCanShowRouteChangeConfirmation().subscribe(message => {
      this.showCanRouteChange = message.text;
    });
  }

  ngOnInit() {
    this.user_profile = SessionDetails.GetUserProfile();
    let email = this.user_profile.email;
    let today = new Date();
    this.date3 = new Date();
    this.minDateValue = new Date();
    this.date3.setDate(this.date3.getDate() + 7);
    // this.minDateValue = new Date(today.getFullYear(), 8, 1);
    if (SessionDetails.GetProgramType() == ProgramType.SR) {
      this.sr_followup = true;
      this.ip_followup = false;
      this.follow_up_title = 'Senior Outreach Follow-Up Email Reminder';
    }
    if (SessionDetails.GetProgramType() == ProgramType.PCA) {
      this.sr_followup = true;
      this.ip_followup = false;
      this.follow_up_title = 'PCA Follow-Up Email Reminder';
    }
    if (SessionDetails.GetProgramType() == ProgramType.IP) {
      this.sr_followup = false;
      this.ip_followup = true;
    }
    if (SessionDetails.GetProgramType() == ProgramType.SRIP) {
      this.follow_up_title = 'Senior Outreach Follow-Up Email Reminder';
      this.sr_followup = true;
      this.ip_followup = true;
    }

    this.followupForm = this.formBuilder.group({
      imzemail: [email, [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/)]],
      imzdate: [this.date3, Validators.required],
      sremail: [email, [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/)]],
      srdate: [this.date3, Validators.required],
      doNotSendImz: [false, null],
      doNotSendSR: [false, null]

    });
    this.status = SessionDetails.GetFollowUp();
    this.page_name = SessionDetails.GetPageName();
    if (this.status) {
      this.get_follow_up = SessionDetails.GetopportunitiesData();
      this.getFollowpData();
    }
    this.follow_up_details = this.followupForm.value;
  }
  isFieldValid(field: string) {
    return !this.followupForm.get(field).valid && this.followupForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  onSave() {    
    if (this.followupForm.valid) {
      
      var followup_details_list = new FollowUpDetails();
      var followup_details_list1 = new FollowUpDetailsList();
      if(!this.email_pattern.test(this.followupForm.controls["imzemail"].value.trim()) || !this.email_pattern.test(this.followupForm.controls["sremail"].value.trim())){
        //this.showDialog("Error","Invalid Email pattern.");
        this.dialogMsg="Invalid Email Pattern.";
        this.dialogSummary = "Error";
        this.formError = true;
        return;
      }

      if (this.get_followup_data.length > 0) {
        for (let i = 0; i < this.get_followup_data.length; i++) {
          if (this.get_followup_data[i].outreachEffortCode == "IP") {
            followup_details_list.contactLogPk = this.get_followup_data[i].contactLogPk;
            followup_details_list.emailReminder = this.followupForm.controls["doNotSendImz"].value;
            followup_details_list.outreachEffortCode = this.get_followup_data[i].outreachEffortCode;
            followup_details_list.outreachPk = this.get_followup_data[i].outreachPk;
            followup_details_list.updatedBy = this.get_followup_data[i].updatedBy;
            followup_details_list.updatedDate = new Date();
            followup_details_list.emailTo = this.followupForm.controls["imzemail"].value;
            followup_details_list.followupDate = this.followupForm.controls["imzdate"].value;
            if(followup_details_list.followupDate != null && followup_details_list.followupDate != undefined && followup_details_list.followupDate != ''){
            followup_details_list.followupDate = this.date_pipe.transform(followup_details_list.followupDate, "MM/dd/yyyy");
            }
          }
          if (this.get_followup_data[i].outreachEffortCode == "SR" || this.get_followup_data[i].outreachEffortCode == "PCA") {
            followup_details_list.contactLogPk = this.get_followup_data[i].contactLogPk;
            followup_details_list.emailReminder = this.followupForm.controls["doNotSendSR"].value;
            followup_details_list.outreachEffortCode = this.get_followup_data[i].outreachEffortCode;
            followup_details_list.outreachPk = this.get_followup_data[i].outreachPk;
            followup_details_list.updatedBy = this.get_followup_data[i].updatedBy;
            followup_details_list.updatedDate = new Date();
            followup_details_list.emailTo = this.followupForm.controls["sremail"].value;
            followup_details_list.followupDate = this.followupForm.controls["srdate"].value;
            if(followup_details_list.followupDate != null && followup_details_list.followupDate != undefined && followup_details_list.followupDate != ''){
            followup_details_list.followupDate = this.date_pipe.transform(followup_details_list.followupDate, "MM/dd/yyyy");
            }
          }
          followup_details_list1.followUpDetailsList.push(followup_details_list);
          this.follow_up_details = followup_details_list1;
          followup_details_list = new FollowUpDetails();

        }
        this.disable_button = true;
        this.schedule_event_service.updateFollowUpEvent(followup_details_list1).subscribe((data: any) => {
          if (data === "success") {
            this.displayOnSucc = true;
            this.disable_button = false;
          }
        },
        err=>{
          this.dialogMsg="An error has occurred and your contact log has not been added successfully. Please try reentering the log. If the status is not updated, please contact a site administrator.";
          this.dialogSummary = "Error";
          this.formError = true;        
         this.disable_button = false;
        });
      }
      else {
        this.follow_up = new FollowUp();
        this.get_contact_log = SessionDetails.GetLogContact();
        this.follow_up.logOutreachStatus.businessPk = this.get_contact_log.logOutreachStatus.businessPk;
        this.follow_up.logOutreachStatus.firstName = this.get_contact_log.logOutreachStatus.firstName;
        this.follow_up.logOutreachStatus.lastName = this.get_contact_log.logOutreachStatus.lastName;
        this.follow_up.logOutreachStatus.jobTitle = this.get_contact_log.logOutreachStatus.jobTitle;
        this.follow_up.logOutreachStatus.outreachProgram = this.get_contact_log.logOutreachStatus.outreachProgram;
        this.follow_up.logOutreachStatus.outreachBusinessPk = this.get_contact_log.logOutreachStatus.outreachBusinessPk;
        this.follow_up.logOutreachStatus.contactDate = this.get_contact_log.logOutreachStatus.contactDate;
        
        this.follow_up.logOutreachStatus.outreachStatusId = this.get_contact_log.logOutreachStatus.outreachStatusId;
        this.follow_up.logOutreachStatus.outreachStatusTitle = this.get_contact_log.logOutreachStatus.outreachStatusTitle;
        this.follow_up.logOutreachStatus.feedback = this.get_contact_log.logOutreachStatus.feedback;
        this.follow_up.logOutreachStatus.createdBy = this.get_contact_log.logOutreachStatus.createdBy;
        this.follow_up.logOutreachStatus.clinicAgreementPk = 0;
        this.follow_up.followUpDetails.createdBy = this.follow_up.logOutreachStatus.createdBy;

        if (this.follow_up.logOutreachStatus.outreachProgram == 'IP') {
          this.follow_up.followUpDetails.emailTo = this.followupForm.value.imzemail;
          this.follow_up.followUpDetails.followupDate = this.followupForm.value.imzdate;
          if(this.follow_up.followUpDetails.followupDate != null && this.follow_up.followUpDetails.followupDate != undefined && this.follow_up.followUpDetails.followupDate != ''){
            this.follow_up.followUpDetails.followupDate = this.date_pipe.transform(this.follow_up.followUpDetails.followupDate, "MM/dd/yyyy");
            }
          this.follow_up.followUpDetails.isSendEmailReminder = this.followupForm.value.doNotSendImz;
        }
        if (this.follow_up.logOutreachStatus.outreachProgram == 'SR' || this.follow_up.logOutreachStatus.outreachProgram == 'PCA') {
          this.follow_up.followUpDetails.emailTo = this.followupForm.value.sremail;
          this.follow_up.followUpDetails.followupDate = this.followupForm.value.srdate;
          if(this.follow_up.followUpDetails.followupDate != null && this.follow_up.followUpDetails.followupDate != undefined && this.follow_up.followUpDetails.followupDate != ''){
            this.follow_up.followUpDetails.followupDate = this.date_pipe.transform(this.follow_up.followUpDetails.followupDate, "MM/dd/yyyy");
            }
          this.follow_up.followUpDetails.isSendEmailReminder = this.followupForm.value.doNotSendSR;
        }
        this.disable_button = true;
        this.schedule_event_service.postFollowUpEvent(this.follow_up).subscribe((data: any) => {
          if (data === "success") {
            this.displayOnSucc = true;
            this.disable_button = false;
          }
        },
       err=>{
        this.dialogMsg="An error has occurred and your contact log has not been added successfully. Please try reentering the log. If the status is not updated, please contact a site administrator.";
        this.dialogSummary = "Error";
        this.formError = true;
        this.disable_button = false;
       }
        );
      }
    }
  }
  onFolloUpIPDateSelected(select_date: Date) {
    this.followupForm.controls['imzdate'].setValue(new Date(select_date.getFullYear(), select_date.getMonth(), select_date.getDate(), 12, 30, 55));
  }
  onFolloUpSRDateSelected(select_date: Date) {
    this.followupForm.controls['srdate'].setValue(new Date(select_date.getFullYear(), select_date.getMonth(), select_date.getDate(), 12, 30, 55));
  }
  onClick() {
    this.follow_up_details=this.deepCopy(this.followupForm.value);
    this.message_service.sendProfileChange("profilechange");
    this.router.navigateByUrl('/communityoutreach/storehome');
  }
  getFollowpData() {

    if (this.page_name != "") {
      if (this.get_follow_up.outreachEffortCode == "IP") {
        this.contact_log_IP = this.get_follow_up.contactLogPk;
      }
      if (this.get_follow_up.outreachEffortCode == "SR") {
        this.contact_log_SR = this.get_follow_up.contactLogPk;
      }
      if (this.get_follow_up.outreachEffortCode == "PCA") {
        this.contact_log_SR = this.get_follow_up.contactLogPk;
      }
    }
    else {
      if (this.get_follow_up.outreachEffort.IP.outreachStatus == 2) {
        this.contact_log_IP = this.get_follow_up.outreachEffort.IP.contactLogPk;
      }
      if (this.get_follow_up.outreachEffort.SR.outreachStatus == 2) {
        this.contact_log_SR = this.get_follow_up.outreachEffort.SR.contactLogPk;
      }
      if (this.get_follow_up.outreachEffort.PCA.outreachStatus == 2) {
        this.contact_log_SR = this.get_follow_up.outreachEffort.PCA.contactLogPk;
      }
    }
    this.schedule_event_service.getFollowUpEvent(this.contact_log_IP, this.contact_log_SR).subscribe((data: any) => {
      this.get_followup_data = data.followUpDetailsList;
      if (this.get_followup_data.length > 0) {
        for (let i = 0; i < this.get_followup_data.length; i++) {
          if (this.get_followup_data[i].outreachEffortCode == "IP") {
            this.followupForm.controls["imzemail"].setValue(this.get_followup_data[i].emailTo);
            this.followupForm.controls["imzdate"].setValue(new Date(this.get_followup_data[i].followupDate));
            this.followupForm.controls["doNotSendImz"].setValue(this.get_followup_data[i].emailReminder);
          }
          if (this.get_followup_data[i].outreachEffortCode == "SR" || this.get_followup_data[i].outreachEffortCode == "PCA") {
            this.followupForm.controls["sremail"].setValue(this.get_followup_data[i].emailTo);
            this.followupForm.controls["srdate"].setValue(new Date(this.get_followup_data[i].followupDate));
            this.followupForm.controls["doNotSendSR"].setValue(this.get_followup_data[i].emailReminder);
          }
        }
      }
      this.follow_up_details = this.followupForm.value;
    });

  }
  onCancel() {
    var compare_objects = false;
    if (this.status) {
      compare_objects = this.utility.compareTwoObjects(this.follow_up_details, this.followupForm.value);
      if (compare_objects) {
        this.doNotSaveText = "Cancel";
        this.setUp = "Ok";
        // this.showDialog(ErrorMessages['unSavedData'], ErrorMessages['contract_alert']);
        this.showCanRouteChange = true;        
      }
      else {
        this._location.back();
      }
    }
    else {
      
      this.doNotSaveText = "Do Not Save";
      this.setUp = "Continue Setup";
      this.showDialog(ErrorMessages['unSavedData'], ErrorMessages['followp_alert']);
      
    }

  }
  doNotSave() {
    if (this.doNotSaveText == "Cancel") {
      this.savePopUp = false;
    }
    else if (this.doNotSaveText == "Do Not Save") {
      
      this.follow_up_details=this.deepCopy(this.followupForm.value);
      this._location.back();
    }
  }
  Continue() {
    if (this.setUp == "Continue Setup") {
      this.savePopUp = false;
    }
    else if (this.setUp == "Ok") {
      this._location.back();
    }
  }
  showDialog(msgSummary: string, msgDetail: string) {
    this.savePopUp = true;
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
  }

  getModelDiff() {
    var diff = this.utility.getDifference(this.follow_up_details, this.followupForm.value);
    return diff;
  }

  leaveRoute() {
    this.router.navigateByUrl("/communityoutreach/storehome");
  }
  cancelRoute() {
    this.showCanRouteChange = false;
    return false; 
  }
  deepCopy(oldObj: any) {
    var newObj = oldObj;
    if (oldObj && typeof oldObj === "object") {
        newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
        for (var i in oldObj) {
            newObj[i] = this.deepCopy(oldObj[i]);
        }
    }
    return newObj;
  }
  disableButton() {
    if (this.disable_button) {
      return 'false';
    }
    else {
      return '';
    }

  }
}
