    
    export class ClinicDetailsDelete {
        clinicPk: number;
        userPk: number;
        clinicType: number;
        username: string;
        clinicName: string;
        clinicStoreId: number;
        businessName: string;
        contractedStoreId: number;
        pData : string;
    }


