export class WorkflowMonitor {
    userId: number;
    userRole: string;
    outreachEffort: number;
    reportName: string = "";
    outputType: string = "";
    constructor(user_id: number, user_role: string, outreach_effort: number, report_name: string, output_type: string) {
        this.userId = user_id;
        this.userRole=user_role;
        this.outreachEffort = outreach_effort;
        this.reportName=report_name;
        this.outputType = output_type;
    }
}