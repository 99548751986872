import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { SessionDetails } from '../../../../utility/session';
import { ActionitemsandmetricsService } from '../../services/actionitemsandmetrics.service';
import { saveAs as importedSaveAs } from "file-saver";
import { Compiler } from '@angular/core';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { Router } from '@angular/router';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { PcacontractAgreementService } from '../../../../modules/patientcareadvocacy/Services/pcacontract-agreement.service';
import { Util } from '../../../../utility/util';
import { LulaAso } from '../../../../models/pcaTandC';
import { LulaAsoRelease2 } from '../../../../models/pcaTandC-release2';

@Component({
  selector: 'app-pca-action-items-ip',
  templateUrl: './pcaactionitems.component.html',
  styleUrls: ['./pcaactionitems.component.css']
})
export class PcaActionItemsComponent implements OnInit {
  @ViewChildren('column') columns: QueryList<any>; 
  actionItemsAndMetricArray: any;
  hcsActionItemsOrMetricsList: any;
  ddlReports: number;
  ddlFormat: string;
  loading: boolean = true;
  userProfile: any;
  totalRecords: number = 0;
  reportType: string = "";
  reportId: number;
  cols: any = [];
  canExport:any;
  data:any;
  recordsCountText ="";
  OrganizationTypes:any[]=[];
  ddlOrganizationType:number=0;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  oldPcaReleaseDate: any;
  PcaReleaseDate2022_1: any;

  constructor(private router: Router, private actionitems_metrics_service: ActionitemsandmetricsService, 
    private message_service:MessageServiceService, private _compiler: Compiler,private _contractService: PcacontractAgreementService) {
    this._compiler.clearCache();
    this.userProfile = SessionDetails.GetUserProfile();
    if (sessionStorage["actionItemOrMetricId"]) {
      let item_details = sessionStorage["actionItemOrMetricId"].split(':');
      if (item_details.length > 0) {
        this.reportType = item_details[0];
        this.reportId = item_details[1];
      }
    }
    if (this.reportType == "actionitem") {
      if (SessionDetails.getPCAActionItemsList().length > 0) {
        this.hcsActionItemsOrMetricsList = SessionDetails.getPCAActionItemsList();
      }
    }
    else {
      if (SessionDetails.getPCAActionItemsList().length > 0) {
        this.hcsActionItemsOrMetricsList = SessionDetails.getPCAMetricsList();
      }
    }
    this.oldPcaReleaseDate = new LulaAso().tnCCutOffDate;
    this.PcaReleaseDate2022_1 = new LulaAsoRelease2().tnCCutOffDate;
  }

  ngOnInit() {    
    this.ddlFormat = "xlsxv";
    this.ddlReports = this.reportId;
    this.message_service.setReportTitle(this.getTitleForDashboardReports());
    this.getOrgnizationTypes();
    this.generateReport();
  }

  reportChanged() {
    this.actionItemsAndMetricArray=[];
    this.recordsCountText ="";
    this.reportId = this.ddlReports;
    this.message_service.setReportTitle(this.getTitleForDashboardReports());
    this.generateReport();
  }

  generateReport(): void {
    
    this.loading = true;
    if (this.reportType == "actionitem") {
      this.actionitems_metrics_service.getActionItemsReportData(this.userProfile.userPk, this.ddlReports.toString(),true,false, this.ddlOrganizationType).subscribe((resultArray: any) => {
        this.totalRecords = resultArray.length;
        if(resultArray.length > 0){
          setTimeout(() => {
            this.recordsCountText = "There are " + this.totalRecords +  " records."
            console.log('line 219');
          }, 1000);
        }else{
          this.recordsCountText =  "No records found."
        }
        this.actionItemsAndMetricArray = resultArray;
        this.columns.forEach(col => {
          col.hidden = (this.actionItemsAndMetricArray[0][col.field]);
        });
        setTimeout(() => {
          this.loading = false;
          this.canExport=true;
        }, 1000)
      }  ,error =>{
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
        this.actionItemsAndMetricArray = [];
        this.totalRecords = 0;
      });
    
    } else if (this.reportType == "metric") {   
          
      this.actionitems_metrics_service.getMetricsReportData(this.userProfile.userPk, this.ddlReports.toString(),true, this.ddlOrganizationType).subscribe((resultArray: any) => {
        this.totalRecords = resultArray.length;
        this.actionItemsAndMetricArray = resultArray;
        if(resultArray.length > 0){
          this.recordsCountText = "There are " + this.totalRecords +  " records."
        }else{
          this.recordsCountText =  "No records found."
        }
        this.columns.forEach(col => {
          col.hidden = (this.actionItemsAndMetricArray[0][col.field]);
        });
        setTimeout(() => {
          this.loading = false;
          this.canExport=true;
        }, 1000)
      },error =>{
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
        this.actionItemsAndMetricArray = [];
        this.totalRecords = 0;
      });
    }
  }

  getEventDates(eventDate:string)
  {
    if(eventDate.indexOf(',') > -1)
    {
      
    
      let eventDateStr = '';
      let eventDatesArr = eventDate.split(',');
      eventDatesArr.forEach(element => {
              if(eventDateStr!='')
              {
                eventDateStr +=','+ element.split('-')[1];
              }
              else{

                eventDateStr += element.split('-')[1];
              }

              // eventDateStr += this.datePipe.transform(element.split('-')[1],"yyyy-MM-dd")
      }); 
      eventDate =eventDateStr;
      return eventDate
    }
    else{ 
      return eventDate.split('-')[1]; 
    }
  }
  getTitleForDashboardReports() {
    var title = "";
    let rpt_id:string=this.reportId.toString();
    let report_type:string=this.reportType;
    
    switch (rpt_id) {
      case "1":
        title = ((report_type == "actionitem") ? "Agreements to be approved/Signed" : "Review Agreements Drafted but Not Sent");
        break;
      case "2":
        title = "Review Agreements Pending Partner Approval";
        break;
      case "3":
        title = "Review Events with Signed Agreements to be Completed";
        break;
      case "4":
        title = "Review Events Completed to Date";
        break;
      case "5":
        title = "Review Agreements with Requested Changes";
        break;
      default:
        break;
    }
    return title.toUpperCase();
  }

  generatereportformat(): void {    
    this.loading = true;
    let report_name: string = "";
    if (this.reportType == "actionitem") {
      switch (this.ddlReports.toString()) {
        case "1":
          report_name = "AgreementsToBeApprovedSigned";
          break;         
        default:
          break;
      }
      this.actionitems_metrics_service.exportActionItemsReportData(this.userProfile.userPk, this.ddlReports.toString(),true, report_name, this.ddlFormat,false,this.ddlOrganizationType).subscribe((res:any) => {
        importedSaveAs(res.body, report_name+".xlsx");
        this.loading = false;
      })
    } else if (this.reportType == "metric") {
      switch (this.ddlReports.toString()) {
        case "1":
          report_name = "ReviewAgreementsDraftedButNotSent";
          break;
        case "2":
          report_name = "ReviewAgreementsPendingTestingPartnerApproval";
          break;
        case "3":
          report_name = "ReviewEventsWithSignedAgreementsToBeExecuted";
          break;
        case "4":
          report_name = "ReviewEventsExecutedToDate";
          break;
        case "5":
          report_name = "ReviewAgreementsWithRequestedChanges";
          break;
        default:
          break;
      }
      this.actionitems_metrics_service.exportMetricsReportData(this.userProfile.userPk, this.ddlReports.toString(),true, report_name, this.ddlFormat, this.ddlOrganizationType).subscribe((res:any) => {
        importedSaveAs(res.body, report_name+".xlsx");
        this.loading = false;
      }  ,error =>{
        this.showDialog("Error", ErrorMessages["error"], true);
        this.loading = false;
      });
    
    }
  }
  
  openContractAgreement(data:any){    
    
    this._contractService.getPCAContractAgreement(data.clinicAgreementPk, null).subscribe((data1:any) => {      
      sessionStorage["clinic_agreement_pk"] = data.clinicAgreementPk;
      sessionStorage["fromActionItemsReport"] = true;
      sessionStorage["clinic_type"] = data.clinicType;

      if(new Date(Util.getFormatedDate(data1.dateApproved)) >= new Date(this.PcaReleaseDate2022_1) || Util.getFormatedDate(data1.dateApproved) == null)
      {
      this.router.navigate(['/rhdContractAgreement-2023-1']);
      }
      else
      {
        this.router.navigate(['/rhdContractAgreement']);
      }      
            
    });    
   
  }
    getOrgnizationTypes(){
    this.actionitems_metrics_service.getOrganizationTypes().subscribe((result:any)=>{
      this.OrganizationTypes=result;
    });
  }
    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}

