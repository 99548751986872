import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Util } from '../../../utility/util';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PotentialClientInterestService {

  constructor(private _http: HttpClient, private router:Router) { }

  SendPotentialClientInterestEmail(potentialInterestBusinessPk:any,  potentialInterestEmail:any) {
    
    let url = environment.API_URL + environment.RESOURCES.POTENTIAL_CLIENT_INTEREST_NEED_MORE_INFO + "/" + potentialInterestBusinessPk + "/" + potentialInterestEmail+ "/";
    return this._http.post(url, null, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}


sendEmailToClientWhenStoreRespondsYes(leadPk:any, storeResForLeadStoreId:any) {
  
  let url = environment.API_URL + environment.RESOURCES.STORE_CLICKED_YES_FOR_CONTACTUS + "/" + leadPk + "/" + storeResForLeadStoreId + "/";
  return this._http.post(url, null, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}

sendEmailToClientWhenStoreRespondsNo(leadPk:any, storeResForLeadStoreId:any) {
  
  let url = environment.API_URL + environment.RESOURCES.STORE_CLICKED_NO_CONTACTUS + "/" + leadPk + "/" + storeResForLeadStoreId + "/";
  return this._http.post(url, null, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}


}
