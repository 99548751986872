import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionDetails } from '../../../utility/session';
import { catchError } from 'rxjs/operators';
import { JsFluOppContactStatusModel } from '../../../models/JsFluOppContactStatusModel';

@Injectable()
export class FluOpportunitiesContactStatusService {
  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL;
  constructor(private http: HttpClient) { }

  public exportData(post_Model:JsFluOppContactStatusModel) {    
    let url : any = this._url + "/api/JsStatsReporting/getFluOppContactStatusReport";
    return this.http.post(url, post_Model, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
    
  }
  public getTotalRecordsCountForFluOppContactStatusReport(post_Model:JsFluOppContactStatusModel) {    
    
    let url : any = this._url + "/api/JsStatsReporting/getTotalRecordsCountForFluOppContactStatusReport";
    return this.http.post(url, post_Model, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
      
  }  
}
