import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FollowUp } from '../../../models/FollowUp';
import { Util } from '../../../utility/util';
import { Router } from '@angular/router';
import { Schedulevent } from '../../../models/schedulevent';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
@Injectable()
export class ScheduleeventService {

  constructor(private http: HttpClient, private router: Router) { }
  getScheduleEvents(contact_log_pk) {
    let url = environment.API_URL + environment.RESOURCES.GET_SCHEDULE_EVENT + "/" + contact_log_pk;
    return this.http.get(url,Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }
  postFollowUpEvent(follow_up) {
    let url = environment.API_URL + environment.RESOURCES.POST_FOLLOWUP_EVENT;
    return this.http.post(url, follow_up,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  postScheduleEvent(scheduleEvent: FollowUp) {
    let url = environment.API_URL + environment.RESOURCES.POST_SCHEDULE_EVENT;
    return this.http.post(url, scheduleEvent,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  upadteScheduleEvent(scheduleEvent: Schedulevent) {
    let url = environment.API_URL + environment.RESOURCES.UPDATE_SCHEDULE_EVENT;
    return this.http.post(url, scheduleEvent,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  postSimpleUpEvent(contact_log) {
    let url = environment.API_URL + environment.RESOURCES.POST_SIMPLE_EVENT;
    return this.http.post(url, contact_log,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  updateStatusEvents(outreachBusinessPkusiness) {
    let url = environment.API_URL +  "/api/ContactLog_ResetValues/" + outreachBusinessPkusiness;
    return this.http.get(url,Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }

  updateFollowUpEvent(follow_up) {
    let url = environment.API_URL + environment.RESOURCES.UPDATE_FOLLOWUP_EVENT;
    return this.http.post(url, follow_up,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  getFollowUpEvent(contact_log_pk_IP, contact_log_pk_SR) {
    let url = environment.API_URL + environment.RESOURCES.GET_FOLLOWUP_EVENT + "/" + contact_log_pk_IP + "/" + contact_log_pk_SR;
    return this.http.get(url,Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }

}
