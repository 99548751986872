import { Injectable } from '@angular/core';
import { Util } from '../../../utility/util';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PcaDetailsService {
  constructor(private _http: HttpClient, private router: Router) { 
  }
  
  //Gets the PCA Event details from DB using API Call.
  getPcaDetails(business_clinic_pk, user_role) {
    let url = environment.API_URL + environment.RESOURCES.GET_PCA_DETAILS + "/" + business_clinic_pk + "/" + user_role;
    return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  
  //Updates the PCA Event details into DB using API Call.
  updatePCADetails(business_clinic_pk, clinic_details) {
    var url = environment.API_URL + environment.RESOURCES.UPDATE_PCA_DETAILS + "/" + business_clinic_pk;
    return this._http.post(url, clinic_details, Util.getRequestHeadersV1());
  }

  //Deletes an agreement from DB using API Call.
  deletePCADetails(clinic_details) {
    var url = environment.API_URL + environment.RESOURCES.DELETE_CLINIC_DETAILS;
    return this._http.post(url, clinic_details, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));;
  }

  removeClinicCancelledStatus(clinic_pk) {    
    var url = environment.API_URL + environment.RESOURCES.REMOVE_CLINIC_CANCELLED_STATUS+"/"+clinic_pk+"/false";
    return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
}
