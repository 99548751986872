import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AdminModule } from '../app/modules/admin/admin.module';
import { AuthModule } from '../app/modules/auth/auth.module';
import { ReportsModule } from '../app/modules/reports/reports.module';
import { StoreModule } from '../app/modules/store/store.module';

import { CommonComponentsModule } from '../app/modules/common/common.module';
import { AdminRouteGuard } from './guards/admin.route.guard';
import { HomeRouteGuard } from './guards/home.route.gaurd';


// Components
import { AppComponent } from './app.component';

import { HeaderComponent } from '../app/modules/common/components/header/header.component';
// Routes
import { APP_ROUTES } from './routes/app.routes';
import { ReusableModule } from './modules/common/components/reusable.module';
//import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { LandingComponent } from './modules/auth/components/landing/landing.component';
import { AuthenticationService } from './modules/common/services/authentication.service';
import { ClinicDetailsModule } from './modules/clinicdetails/clinicdetails.module';
import { ResourcesModule } from './modules/resources/resources.module';
import { HomeModule } from './modules/home/home.module';
import { ContractagreementModule } from './modules/contractaggreement/contractagreement.module';
import { CanDeactivateGuard } from './guards/can-deactivate/can-deactivate.guard';
import { PatientcareadvocacyModule } from './modules/patientcareadvocacy/patientcareadvocacy.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { TableModule  } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { InputMaskModule } from 'primeng/inputmask';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MessagesModule } from 'primeng/messages';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent, 
   // LandingComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AccordionModule,
    ResourcesModule,
    AdminModule,
    AuthModule,
    HomeModule,
    ReportsModule,
    StoreModule,
    InputMaskModule,
    KeyFilterModule,
    CommonComponentsModule,
    AutoCompleteModule,
    ReusableModule,
    FormsModule,
    ButtonModule,
    RouterModule.forRoot(APP_ROUTES, {}),
    BrowserAnimationsModule,
    ClinicDetailsModule,
    TooltipModule,
    PatientcareadvocacyModule,
    ContractagreementModule,
    NgxSpinnerModule,
    TableModule,
    MessagesModule,
    NgbModule,
    SharedModule,    
    //LoggerModule.forRoot({ serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [AuthenticationService, HomeRouteGuard, AdminRouteGuard, CanDeactivateGuard],
  bootstrap: [AppComponent]
 
})
export class AppModule { }
