import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { SessionDetails } from '../../../utility/session';
import { Util } from '../../../utility/util';


@Injectable()
export class CovidclinicsService {
  private _baseURL:string =  environment.API_URL;
  filename: string;
  constructor(private http: HttpClient) { }
  public getCovidClinicsDetails(data: any): Observable<any> {
    let url : any = this._baseURL + "/api/JsCovidClinicsDetails_get";
    return this.http.post(url, data, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  public exportReportData(data:any) {
    let user_data = SessionDetails.GetUserInfo();
    //let headers: Headers = new Headers();
    //headers.append("Content-Type", "application/json");
    //let token = window.btoa(user_data.userName + ':' + user_data.password);
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,user_data.userName + ':'+ user_data.password);                    
    //headers.append('Authorization', 'Basic ' + usernamePassword);
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    let url = this._baseURL + "/api/JsCovidClinicsDetails_downloadReport";

    //let options = new RequestOptions({ responseType: ResponseContentType.Blob, headers: headers });
    return this.http.post(url, data, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'}).pipe(catchError(Util.handleErrorV2));
    /*.map(res => {
      var contentDisposition = res.headers.get('Content-Disposition');
      var result = contentDisposition.split(';')[1].trim().split('=')[1];
      this.filename = result;
      return res.blob()
    });*/
  }
}
