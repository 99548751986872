import { Pipe, PipeTransform } from '@angular/core';
import { SessionDetails } from '../../../utility/session';

@Pipe({
    name: 'FilterPipe',
})
export class FilterPipe implements PipeTransform {
    transform(value: any, input: string) {
        if (input) {
            input = input.toLowerCase();
            return value.filter(function (el: any) {
                return (el.businessName!=null || el.businessName!="") ? el.businessName.toLowerCase().indexOf(input) > -1 : ""
            })
        }
        return value;
    }

    maxDate(value: any, input: string) {
        if (input) {
            input = input.toLowerCase();
            return value.filter(function (el: any) {
                return el.businessName.toLowerCase().indexOf(input) > -1 || el.phone.toLowerCase().indexOf(input) > -1;
            })
        }
        return value;
    }

    sortBy(array: Array<Object>, args: string, status: string = null): Array<Object> {
        if (array == null) {
            return null;
        }
        array.sort((a: any, b: any) => {
            if (status != "latest") {
                switch (args.toLowerCase()) {
                    case 'contactdate':
                    return FilterPipe._orderByDescComparator(a.value[0][args], b.value[0][args]);
                    case 'eventdate':                    
                        return FilterPipe._orderByDescComparator(a[args], b[args]);
                    case 'employmentsize':
                        // if (a[args].toLowerCase() == "n/a") {
                        //     a[args] = '-1';
                        // }
                        // if (b[args].toLowerCase() == "n/a") {
                        //     b[args] = '-1';
                        // }
                        // return -FilterPipe._orderByComparator(a[args], b[args]);
                        return FilterPipe._orderByComparatorBooleanValuesPC(a,b,false);
                    case 'lastcontact':
                    if (a[args] == null) {
                        a[args] = '-1';
                    }
                    if (b[args] == null) {
                        b[args] = '-1';
                    }
                        return FilterPipe._orderByComparator(a[args], b[args]);
                    case 'outreacheffort':
                        return FilterPipe._orderByComparatorV1(a[args], b[args]);
                    case 'ip':                        
                    let is_ip= SessionDetails.getIsIP();
                    if(!is_ip){
                          if(a.isPreviousClient || b.isPreviousClient){
                            return FilterPipe._orderByComparatorBooleanValuesPC(a,b); 
                          }                        
                          else {
                          return FilterPipe._orderByComparatorV1(a['outreachEffort'], b['outreachEffort'],false);
                          }
                        }
                        else {
                            return FilterPipe._orderByComparatorV1(a['outreachEffort'], b['outreachEffort'],false);
                            }
                    case 'ispreviousclient':
                        //return FilterPipe._orderByComparatorBooleanValues(a[args], b[args]);
                        return FilterPipe._orderByComparatorBooleanValuesPC(a,b);
                        case 'iscovidopportunity':
                            return FilterPipe._orderByComparatorBooleanValuesisCOVIDOpp(a,b);
                    default:
                        return FilterPipe._orderByComparator(a[args], b[args]);

                }
            }

            else if (status == "latest") {
                if (a[args] == null) {
                    a[args] = '-1';
                }
                if (b[args] == null) {
                    b[args] = '-1';
                }
                return FilterPipe._orderByDescComparator(a[args], b[args]);
            }
        });
        return array;
    }
    sortByUpcoming(array: Array<Object>, args: string, status: string = null): Array<Object> {
        if (array == null) {
            return null;
        }
        array.sort((a: any, b: any) => {
            
            if (status != "latest") {
                switch (args.toLowerCase()) {                  
                    //case 'eventdate':                   
                        //return FilterPipe._orderByComparator(a[args], b[args]);                 
                 
                    default:
                        return FilterPipe._orderByComparator(a[args], b[args]);
                }
            }

            else if (status == "latest") {
                if (a[args] == null) {
                    a[args] = '-1';
                }
                if (b[args] == null) {
                    b[args] = '-1';
                }
                return FilterPipe._orderByDescComparator(a[args], b[args]);
            }
        });
        return array;
    }

    static _orderByComparator(a: any, b: any): number {
        if(a==null)
        a = '0001-01-01T00:00:00';
        if(b==null)
        b = '0001-01-01T00:00:00'
        if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
            //Isn't a number so lowercase the string to properly compare
            if (a < b) return -1;
            if (a > b) return 1;
        }
        else {
            //Parse strings as numbers to compare properly
            if (parseFloat(a) < parseFloat(b)) return -1;
            if (parseFloat(a) > parseFloat(b)) return 1;
        }

        return 0; //equal each other
    }

    static _orderByDescComparator(a: any, b: any): number {
        if(a==null)
        a = '0001-01-01T00:00:00';
        if(b==null)
        b = '0001-01-01T00:00:00'
        if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
            //Isn't a number so lowercase the string to properly compare
            if (a < b) return 1;
            if (a > b) return -1;
        }
        else {
            //Parse strings as numbers to compare properly
            if (parseFloat(a) < parseFloat(b)) return 1;
            if (parseFloat(a) > parseFloat(b)) return -1;
        }

        return 0; //equal each other
    }

    static _orderByComparatorV1(a: any, b: any,isSr:boolean=true): number {

        if(isSr){
        if(a===undefined && b===undefined ) return 0;
        else if (a['SR'].outreachProgram !== undefined && b['SR'].outreachProgram !== undefined) return 0;
        else if (a['SR'].outreachProgram !== undefined &&( b['SR'].outreachProgram === undefined )) return -1;
        else if (( a['SR'].outreachProgram == undefined )&& b['SR'].outreachProgram !== undefined) return 1;
        //   else if (a['IP'].outreachProgram !== undefined && b['SR'].outreachProgram !== undefined ) return -1;
        else return 0;
        }
        else{
            if(a===undefined && b===undefined ) return 0;
            else if (a['IP'].outreachProgram !== undefined && b['IP'].outreachProgram !== undefined) return 0;
            else if (a['IP'].outreachProgram !== undefined &&( b['IP'].outreachProgram === undefined)) return -1;
            else if ((a['IP'].outreachProgram == undefined ) && b['IP'].outreachProgram !== undefined) return 1;
            //   else if (a['IP'].outreachProgram !== undefined && b['SR'].outreachProgram !== undefined ) return -1;
            else return 0;
    }

    }

    static _orderByComparatorBooleanValues(a: any, b: any): number {

        if (a < b) return 1;
        if (a > b) return -1;
        else return 0;

    }
    static _orderByComparatorBooleanValuesPC(a, b, isPC:boolean = true): number {

        if (a['employmentSize'].toLowerCase() == "n/a") {
            a['employmentSize'] = '-1';
        }
        if (b['employmentSize'].toLowerCase() == "n/a") {
            b['employmentSize'] = '-1';
        }
        if(!isPC){
            return (-FilterPipe._orderByComparator(a['employmentSize'], b['employmentSize'])
            || FilterPipe._orderByComparatorV1(a['outreachEffort'],b['outreachEffort'],false)
                || FilterPipe._orderByComparatorBooleanValues(a['isPreviousClient'],b['isPreviousClient'])
            // 
          );

}        else
        {
        return (FilterPipe._orderByComparatorBooleanValues(a['isPreviousClient'],b['isPreviousClient'])
        || FilterPipe._orderByComparatorV1(a['outreachEffort'],b['outreachEffort'],false)
    || -FilterPipe._orderByComparator(a['employmentSize'], b['employmentSize']));
        }

}
static _orderByComparatorBooleanValuesisCOVIDOpp(a, b, isPC:boolean = true): number {
    if (a['employmentSize'].toLowerCase() == "n/a") {
        a['employmentSize'] = '-1';
    }
    if (b['employmentSize'].toLowerCase() == "n/a") {
        b['employmentSize'] = '-1';
    }
    if(!isPC){
        return (-FilterPipe._orderByComparator(a['employmentSize'], b['employmentSize'])
        || FilterPipe._orderByComparatorV1(a['outreachEffort'],b['outreachEffort'],false)
            || FilterPipe._orderByComparatorBooleanValues(a['isCovidOpportunity']?true:false,b['isCovidOpportunity']?true:false)
        // 
      );

}        else
    {
    return (FilterPipe._orderByComparatorBooleanValues(a['isCovidOpportunity']?true:false,b['isCovidOpportunity']?true:false)
    || FilterPipe._orderByComparatorV1(a['outreachEffort'],b['outreachEffort'],false)
|| -FilterPipe._orderByComparator(a['employmentSize'], b['employmentSize']));
    }

}
}