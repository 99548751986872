import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationSettings } from '../../../common/application-settings';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { saveAs as importedSaveAs } from "file-saver";
import { SessionDetails } from '../../../../utility/session';
import { TestingEvents } from '../../../../models/TestingEvents';
import { TestingeventsService } from '../../services/testingevents.service';
import {PaginatorModule, Paginator} from 'primeng/paginator'
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from "ngx-spinner";
import { String } from 'typescript-string-operations';

@Component({
  selector: 'app-testingeventsreport',
  templateUrl: './testingeventsreport.component.html',
  styleUrls: ['./testingeventsreport.component.css']
})
export class TestingeventsreportComponent implements OnInit { 
  testingEventsReportArray: any;  
  userProfile: any;
  ddlSeason: number;
  eventStatuses:any[]=[];
  eventStatusesToBind:any[]=[];
  ddlEventStatus: any[] = []
  //ddlClinicStatus: string;
  //ddlClinicType: string;
  fromDate: Date;
  toDate: Date;
  ddlFormat: any;
  minDate:any;
  maxDate:any;
  outreachStartDate = ApplicationSettings.outreachStartDateIP;
  private from_date: string;
  private to_date: string;
  private outreachEffort = 4;
  totalRecords: number;
  loading: boolean;
  OrganizationTypes:any=[];
  orgTypesToBind:any=[];
  //ddlOrganizationType:number=0;
  ddlOrganizationType: any = []
  checked: boolean = false;
  private currentSeason = ApplicationSettings.pcaSeason;//  appSettings.ipSeason;
  private apiMethodName: string = "";
  season:any=[];
  filterByDate:boolean=false;
  canExport:boolean=true;
  pageIndex:number=1;
  isFiscalYearChanged:boolean = false;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";

  isPagerClick:boolean=false;

  showGrid:boolean = false;
  canDisablePager:boolean = false;

  @ViewChild('plbcsPager') plbcsPager: Paginator;
  testingEventsForm: FormGroup;
  pageSize: number=50;
  constructor(private fb : FormBuilder,
    private testingeventsreport: TestingeventsService, private spinner: NgxSpinnerService
  ) { 
    this.userProfile = SessionDetails.GetUserProfile();    
    this.getOrgnizationTypes();
    this.loadEventStatuses();
  }

  ngOnInit() {
    this.ddlSeason = this.currentSeason;
    //this.defaultCheck();
    for (let report_year: number = 2019; report_year < (this.ddlSeason + 1); report_year++) {
      this.season.push({
        "yearName": report_year.toString(),// + " - " + (report_year + 1).toString(),
        "yearCode": report_year.toString()
      });
    }
    this.setDates(true);
    this.fromDate = this.minDate;
    this.toDate = this.maxDate;
    this.ddlFormat = "xlsxv";
    this.setFormControls();
   
    //this.generateReport();
  };
  setFormControls(){
    this.testingEventsForm = this.fb.group({
      'fromDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'toDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
      'ddlSeason': [this.currentSeason],
      'orgType': [null],
      'eventStatus': [null],
      'filterByDate': [this.filterByDate],
      'ddlFormat': ["xlsxv"]
    });
  }
  setDates(is_page_load:boolean){
    
    if(this.testingEventsForm != undefined){
      this.ddlSeason=this.testingEventsForm.controls['ddlSeason'].value;
    } 
    this.fiscalYearChange(false);
    this.canExport=false;
    this.pageIndex=1;
    this.canDisablePager = true;
    if(!is_page_load){
     // this.plbcsPager.changePage(this.pageIndex-1);
} 
    if(this.testingEventsForm != undefined){
     this.testingEventsForm.controls['fromDate'].setValue(this.fromDate);
  
    this.testingEventsForm.controls['toDate'].setValue(this.toDate);
  }
  }

  fiscalYearChange(is_fiscalyear_changed:boolean){
    let tempMinDate:Date;
    let tempMaxDate:Date;
    tempMinDate=new Date(ApplicationSettings.getPCAStartDate(this.ddlSeason -1));
    tempMaxDate=new Date(ApplicationSettings.getPCAEndDate(this.ddlSeason -1));
    this.minDate=new Date(tempMinDate);
    this.maxDate=new Date(tempMaxDate)
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    if(is_fiscalyear_changed){
      this.isFiscalYearChanged = true;
      this.pageIndex = 1;
    }
  }

  showReportClick(){
    if(this.isFiscalYearChanged){
      this.plbcsPager.changePage(0);
    }else{
      this.generateReport();
    }
  }
  setCalendarDates(){
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    this.canExport=false;
  }
  
  generateReport(): void {
  
    let msg = "";
    if (this.ddlOrganizationType.length == 0) {
      msg = "Organization Type";
    }
    if (this.ddlEventStatus.length == 0) {
      msg += (msg.length > 0) ? " and Event Status" : "Event Status";
    }
    if (this.ddlOrganizationType.length == 0 || this.ddlEventStatus.length == 0) {
      this.showDialog("Alert", String.Format(ErrorMessages["scheduleClinicReportFilterError"], msg), true);
      return;
    }
    if(!this.showGrid){
      this.spinner.show();
    }
    else{
      this.loading = true;
    }
    let data = this.testingEventsForm.value;
    let d1 = this.fromDate;
    let d2 = this.toDate;
    if (this.filterByDate) {
      this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
      this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;     
    }
    else {
      this.from_date = "";
      this.to_date = "";
    }
    let f_date = this.fromDate.getMonth() +1 +"/" + this.fromDate.getDate() + "/" + this.fromDate.getFullYear();
    let t_date = this.toDate.getMonth() +1 +"/" + this.toDate.getDate() + "/" + this.toDate.getFullYear();
    //let data = this.testingEventsForm.value;
    let testing_events = new TestingEvents(this.userProfile.userPk,
      this.outreachEffort,
      (this.ddlEventStatus.length > 1) ? this.ddlEventStatus.join(',') : this.ddlEventStatus.join(),
      data.filterByDate,    
      data.fromDate,
      data.toDate,
      data.ddlSeason, ((data.ddlSeason == this.currentSeason) ? false : true),
       this.pageIndex, 
      (this.ddlOrganizationType.length > 1) ? this.ddlOrganizationType.join(',') : this.ddlOrganizationType.join(),
      '','',this.pageSize);
    this.testingeventsreport.getTestingEventsDetails(testing_events).subscribe((resultArray: any) => {
      if(this.isFiscalYearChanged){
        this.isFiscalYearChanged = false;
      }
    this.totalRecords = resultArray.Table1[0].totalTestingEvents;     
    this.testingEventsReportArray =resultArray.Table;
      setTimeout(() => {
        this.loading = false;
        this.spinner.hide();
        this.canDisablePager = false;
        this.showGrid=true;
        this.canExport=true;
      }, 1000)
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
      this.testingEventsReportArray = [];
      this.totalRecords = 0;
    });
    this.canExport=true;
  }
  generateReportFormat(): void {
    
    this.loading = true;
    let data = this.testingEventsForm.value;
    let f_date = this.fromDate.getMonth() +1 +"/" + this.fromDate.getDate() + "/" + this.fromDate.getFullYear()
    let t_date = this.toDate.getMonth() +1 +"/" + this.toDate.getDate() + "/" + this.toDate.getFullYear()
    //let data = this.testingEventsForm.value;
    let testing_events = new TestingEvents(this.userProfile.userPk,
      this.outreachEffort,      
      (this.ddlEventStatus.length > 1) ? this.ddlEventStatus.join(',') : this.ddlEventStatus.join(),
      data.filterByDate, data.fromDate, data.toDate,
      data.ddlSeason,((data.ddlSeason == this.currentSeason)?false:true),0,
      (this.ddlOrganizationType.length > 1) ? this.ddlOrganizationType.join(',') : this.ddlOrganizationType.join(),
      "TestingEventsDetails",data.ddlFormat);
    this.testingeventsreport.exportReportData(testing_events).subscribe((res:any) => {
      importedSaveAs(res.body, "TestingEventsDetails.xlsx");
      this.loading = false;
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }

  onFilterByDateChanged(){
    console.log(this.filterByDate);
    this.canExport=false;
    this.canDisablePager = true;
 	  this.pageIndex=1;
  }

  reset(){
    //this.loading = true;
    this.showGrid = false;
    this.isPagerClick=true;
    this.ddlSeason = this.currentSeason;
    this.defaultCheck();
    this.ddlFormat="xlsxv";
    this.pageIndex = 1;   
    this.filterByDate=false;
    this.setDates(true);
    this.setFormControls();
  }
  validateExportType(){
    if(this.ddlFormat=="0" || !this.canExport)
      return true;
    else
      return false;
  }
  canExportOnchange(){
    this.canExport=false;
    this.pageIndex = 1;
    this.canDisablePager = true;
  }

  paginate(event) {
    if(this.canDisablePager){
      return false;
    }
    if(this.isFiscalYearChanged){
      event.first = 0;
    }
    var curr_page_rec_count = event.first + event.rows;
    var page_to_get = curr_page_rec_count / event.rows;
    page_to_get = Math.ceil(page_to_get);    
    this.pageIndex = (this.isFiscalYearChanged)? this.pageIndex : page_to_get;
    this.loading = true;
    this.pageSize=event.rows;
    this.generateReport();
  }

  getOrgnizationTypes(){
    this.testingeventsreport.getOrganizationTypes().subscribe((result:any)=>{
      let cnt =0;
      if(result.length >0){
        result.forEach(element => {
          this.OrganizationTypes.push({
              "name": element.name,
              "value":element.fieldValue,
              "description":element.description,
              "isDefaultChecked":true
            });
            cnt++;
        });
        this.defaultCheck();
      }
      //this.OrganizationTypes=result;
    });
  }
  defaultCheck() {
    this.ddlOrganizationType = [];
    this.ddlEventStatus = [];
    this.OrganizationTypes.forEach(v => {
      if (v.isDefaultChecked) {
        this.ddlOrganizationType.push(v.value)
      }
    });
    this.eventStatuses.forEach(v => {
      if (v.isDefaultChecked) {
        this.ddlEventStatus.push(v.value)
      }
    });
  }
  loadEventStatuses(){
    this.eventStatuses = [
      new ClinicStatusArray("Contact Organization",0, true),
      new ClinicStatusArray("Confirmed",1, true),
      new ClinicStatusArray("Cancelled",2, true),
      new ClinicStatusArray("Completed",3, true)
    ];
  }

  bindOrgType(org_type: any) {
    return (this.ddlOrganizationType.indexOf(org_type) > -1) ? org_type : null;
  }

  bindEventStatus(event_status: any) {
    return (this.ddlEventStatus.indexOf(event_status) > -1) ? ((event_status == 0) ? true : event_status) : null;
  }

  orgTypeChange(ev, val) {
    if (ev.target.checked) {
      this.ddlOrganizationType.push(val);
    } else {
      let i = this.ddlOrganizationType.indexOf(val);
      this.ddlOrganizationType.splice(i, 1);
    }
    this.canDisablePager = true;
    this.pageIndex = 1;
    this.plbcsPager.changePage(this.pageIndex - 1);
  }
  eventStatusChange(ev, val) {
    if (ev.target.checked) {
      this.ddlEventStatus.push(val);
    } else {
      let i = this.ddlEventStatus.indexOf(val);
      this.ddlEventStatus.splice(i, 1);
    }
    this.canDisablePager = true;
    this.pageIndex = 1;
    this.plbcsPager.changePage(this.pageIndex - 1);
  }

    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}
export class ClinicStatusArray {
  constructor(public name: string,public value: number, public isDefaultChecked:boolean) { }
  
}