export class ClinicsList {
    clinicPk: number;
    clinicStoreId: number;
    clinicDate: string;
    clinicStartTime: string;
    clinicEndTime: string;
    isScheduled: boolean;
    immunizers: number;
    maxImmunizers?: number;
    signupEndDate: any;
    constructor() {
        this.isScheduled = false;
    }
}

export class ClientInfo {
    clientId: number;
    businessName: string;
    siteStatus: number;
    encryptedClientKey: string;
    encryptedSiteLink: string;
    encryptedClientApptTrackingLink: string;
}

export class SiteLogoAndStyles {
    mastheadLogo: string;
    logoFile: string;
    bannerColor: string;
    bannerTextColor: string;
    buttonColor: string;
    buttonTextColor: string;
    alternateText: string;
}

export class SiteLandingPageText {
    headerText: string;
    bodyText: string;
    footerText: string;
}

export class SiteRequiredRegisField {
    fieldName: string;
    displayName: string;
    isFieldVisible: number;
    isFieldRequired: number;
}

export class UpdateSchedulerSiteDesign {
    clinicsList: ClinicsList[];
    clientInfo: ClientInfo;
    siteLogoAndStyles: SiteLogoAndStyles;
    siteLandingPageText: SiteLandingPageText;
    siteRequiredRegisFields: SiteRequiredRegisField[];
}

