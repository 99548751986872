import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from '../../../utility/util';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientgroupService {
  constructor(private _http: HttpClient) { }

  getSourcePatientGroups(searchStringObj): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_SOURCE_PATIENTGROUPS;
    return this._http.post(url, searchStringObj, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  getTargetPatientGroups(searchStringObj): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_TARGET_PATIENTGROUPS;
    return this._http.post(url, searchStringObj, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  reassignPatients(obj): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.MOVE_PATIENTS_BETWEEN_PATIENTGROUPS;
    return this._http.post(url, obj, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  

}
