
import {of as observableOf,  BehaviorSubject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment'; 
import { Util } from '../../../utility/util';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import {UnsubscribeEmailData} from '../../../models/UnsubscribeEmailData';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UnsubscribeService {
  
  constructor(private _http: HttpClient) {
    
   }

  public UnsubscribeEmailGroup(unsubscribeEmailData: UnsubscribeEmailData) {
    
    let url = environment.API_URL + "/api/UnsubscribeEmailGroup";
    return this._http.post(url, unsubscribeEmailData, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
}

