    export class DetailList {
        outreachBusinessPk: number=-1;
        clinicPk: number=-1;
        apptPk: number=-1;
        firstName: string='';
        middleName?: string='';
        lastName: string='';
        email: string='';
        employeeId: string='';
        apptDate: string='';
        apptTime: string='';
        apptTimeOfDay: string='';
        apptRoom: string='';
        confirmationId: string=null;
        clinicState: string='';
        clinicLocation: string='';
        clinicStoreId:number=-1;
        clientId?:number=null;
        naClinicGroupId?:number=null;
        schedulerRegistration?:string='';
        storeAddress?:string='';
        storeState:string = "OT";
        isCovidClinic:boolean = false;
        isDose2ClinicSchedulerEnabled:boolean;
        selectedImmunization:  { clinicPk: number; immunizationPk: number; }[] = [];
        constructor(){};
    }
    export class ErrorS {
        errorCode: number;
        errorMessage: string;
    }
    export class schedulerRegistrationDetails {
        detailList: DetailList[];
        errorS: ErrorS;
        constructor(){
            this.detailList=new Array<DetailList>();
        };
    }