import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ErrorMessages } from '../../../..//config-files/error-messages';
import { recaptchaRequest } from '../../../..//models/localLeadsSave';
import { stateCodenName } from '../../../..//modules/contractaggreement/components/contact-us/stateCodenName';
import { LeadOpportunitiesService } from '../../../..//modules/contractaggreement/services/lead-opportunities.service';
import { Util } from '../../../..//utility/util';

@Component({
  selector: 'app-immz-contact-us',
  templateUrl: './immz-contact-us.component.html',
  styleUrls: ['./immz-contact-us.component.css']
})
export class ImmzContactUsComponent implements OnInit {
  routerSubscription: Subscription;
  leadGenerationForm: FormGroup;
  statecodenname: any;
  dialogMsg: string = '';
  dialogSummary: string = '';
  locListModalDlg: boolean = false;
  successDlg: boolean = false;
  vacn: any = [];
  paymentType: any = [];

  constructor(private formBuilder: FormBuilder, private utility: Util, private leadService: LeadOpportunitiesService, private spinner: NgxSpinnerService, public router: Router) {

  }

  ngOnInit() {


  }
  NavigateToContactUsPage() {
    this.router.navigateByUrl("/contact-us");
  }
  NavigateToLTCFContactUsPage() {
    this.router.navigateByUrl("/LTCF-Contact-Us");
  }

}
