import { FormGroup, FormControl } from "@angular/forms";
import { ErrorHandler, Injectable, Injector } from "@angular/core";

import { LogData } from "../../models/logData";
//import { LoggerService } from "./services/LoggerService";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) { }
  handleError(error) {
   
    //const loggingService = this.injector.get(LoggerService);
  
    const message = error.message ? error.message : error.toString();
    const stackTrace = error.stack? error.stack:error.toString();
    let msg = message + stackTrace;
    var logData=new LogData()
    logData.message=msg;
    logData.type="ERROR";
    // loggingService.logMessage(logData).subscribe(res => {
   
    // });
    // log on the server
      //loggingService.log( {msg});
    }
  
  
  }
  


