import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { Util } from '../../../../../utility/util';
import { SessionDetails } from '../../../../../utility/session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class StoreprofileuploaderService {
  private _baseURL: string = "";
  private fileName:string ="";
  fileExt: string = "";
  constructor(private http: HttpClient) {

  }
  getUploadedFilesList() {
    let url = environment.API_URL + environment.RESOURCES.GET_STOREPROFILE_UPLOADED_FILES;
    return this.http.get(url, Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }

  uploadStores(files, upload_mode, uploaded_on, user_pk, user_email) {
     this._baseURL = environment.API_URL + environment.RESOURCES.UPLOAD_STORE_PROFILES + "/" + upload_mode + "/" + uploaded_on + "/" + user_pk + "/" + user_email;
    let userData = SessionDetails.GetUserInfo();
    let token = btoa(userData.userName + ':' + userData.password);

    let req_headers = new HttpHeaders()
    .set('Authorization','Basic '+ token);

    return this.http.post(this._baseURL, files, {headers:req_headers})
    .pipe(catchError(Util.handleErrorV2));

  }

  getGeneratedStoreProfileReport(uploaded_file_key:string,upload_mode:number) {
    let url = environment.API_URL + environment.RESOURCES.GET_GENERATED_STOREPROFILE_REPORT + "/" + uploaded_file_key + "/" + upload_mode; 
    return this.http.get(url, Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }

  updateAStoreProfile(uploaded_file_key: string, store_id:number,store_profile:any,upload_mode:number){
    let url = environment.API_URL + environment.RESOURCES.UPDATE_STOREUPLOAD_PROFILE + "/" + uploaded_file_key + "/" + store_id + "/" + upload_mode;
    let userData = SessionDetails.GetUserInfo();
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set('Authorization','Basic '+ usernamePassword);

    return this.http.post(url, store_profile, {headers:req_headers})
    .pipe(catchError(Util.handleErrorV2));
  }

  deleteStoreProfile(uploaded_file_key: string, store_id:number){
    let url = environment.API_URL + environment.RESOURCES.DELETE_STOREUPLOAD_PROFILE + "/" + uploaded_file_key + "/" + store_id; 
    return this.http.get(url, Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }

  deleteStoreUploadFile(uploaded_file_key: string){
    let url = environment.API_URL + environment.RESOURCES.DELETE_STOREUPLOAD_FILE + "/" + uploaded_file_key; 
    return this.http.get(url, Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }

  public downloadStoreProfileUploadReport(uploaded_file_key:string,upload_mode:number) {
      let userData= SessionDetails.GetUserInfo()
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    let url = environment.API_URL + environment.RESOURCES.DOWNLOAD_STOREPROFILE_REPORT + "/" + uploaded_file_key + "/" + upload_mode;
       return this.http.get(url, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
       .pipe(catchError(Util.handleErrorV2));
    //    .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.fileName = result;
    //   this.fileExt = this.fileName.substring(this.fileName.indexOf('.'));
    //   return res.blob()
    // });
  }

  public updateStoreProfilesInSite(uploaded_file_key: string, upload_mode: number){
    let url = environment.API_URL + environment.RESOURCES.UPDATE_STOREPROFILES_INSITE + "/" + uploaded_file_key + "/" + upload_mode; 
    return this.http.get(url, Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }
}
