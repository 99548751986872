import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { VaccinePurchase } from '../../../models/VaccinePurchase';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RevisedClinicDatesAndVolumesService {  
  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL;
  constructor(private http: HttpClient) { }
  public getRevisedClinicDatesAndVolumeReportData(vaccine_purchase:VaccinePurchase) {
    
    let url = this._url + "/api/JsRevisedClinicDatesAndVolumes_get/";
    return this.http.post(url,vaccine_purchase,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  public exportReportData(vaccine_purchase:VaccinePurchase) {
    let user_data = SessionDetails.GetUserInfo();
    
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,user_data.userName + ':'+ user_data.password);                    
    
    let url = this._url + "/api/JsRevisedClinicDatesAndVolumes_downloadReport/";
    let req_headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('Authorization','Basic '+ usernamePassword);
    return this.http.post(url,vaccine_purchase, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'}).pipe(catchError(Util.handleErrorV2));
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });
  }
}
