import {
    Pipe,
    PipeTransform
} from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'CustomGroupByPipe',
    pure: false
})
export class CustomGroupByPipe implements PipeTransform {
    transform(value: Array<any>): Array<any> {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!value) {
            return null;
        }

        const newJson = _.chain(value)
            // Group the elements of Array based on `immunizationPk` property
            .groupBy("immunizationPk")
            // `key` is group's name (immunizationPk), `value` is the array of objects
            .map((value, key) => ({ immPk: key, Immzs: value }))
            .value();
        return newJson;
    }

}


@Pipe({
    name: 'CustomLocationGroupByPipe',
    pure: false
})
export class CustomLocationGroupByPipe implements PipeTransform {
    transform(value: any): Array<any> {
        
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!value) {
            return null;
        }

        const newJson =  _.chain(value)
        // Group the elements of Array based on `color` property
        .groupBy("immunizationPk")
        // `key` is group's name (color), `value` is the array of objects
        .map((value, key) => ({ ImmPk: key, Immzs: value ,estimatedQuantity: _.sumBy(value, 'estimatedQuantity'),}))
        .value();
        return newJson;
    }

}


@Pipe({
    name: 'CustomAgreementGroupByPipe',
    pure: false
})
export class CustomAgreementGroupByPipe implements PipeTransform {
    transform(value: any): Array<any> {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!value) {
            return null;
        }

        const newJson =  _.chain(value)
        // Group the elements of Array based on `color` property
        .groupBy("immunizationPk")
        // `key` is group's name (color), `value` is the array of objects
        .map((value, key) => ({ ImmPk: key, Immzs: value}))
        .value();
        return newJson;
    }

}