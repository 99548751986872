import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { BusinesscontactpageComponent } from '../businesscontactpage/businesscontactpage.component';
import { ApproveagreementComponent } from '../approveagreement/approveagreement.component';
import { FieldErrorDisplayComponent } from '../../../common/components/field-error-display/field-error-display.component';
import { ContractapproveService } from '../../services/contractapprove.service';

import { ClientInfo } from '../../../../models/client-info';
import { SessionDetails } from '../../../../utility/session';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { universalContractReleaseDate } from '../../../../JSON/UniversalTandC';
import { getContractComponentName } from '../../../../utility/loadContracts';

@Component({
  selector: 'app-contractapprove',
  templateUrl: './contractapprove.component.html',
  styleUrls: ['./contractapprove.component.css']
})
export class ContractapproveComponent implements OnInit {
  step: any = 1;
  message: string;
  client: string;
  name: string;
  title: string;
  englishLanguage: string;
  enheader: string;
  enattachment: string;
  language: string;
  showBtnEn: boolean = false;
  showBtnSp: boolean = true;
  enlanguageSelection: boolean;
  splanguageSelection: boolean;
  spanishLanguage: string;
  headerText: string;
  attachment: string;
  imz: string;
  payment: string;
  rates: string;
  test: string;
  errormessage: string;
  currentLanguage: string = 'english';
  isInvalidContract = false;
  dialogMsg:string ;
  display:boolean = false;
  showPreUniversalContract: boolean = false;
  componentToRenderName : string ='';
  routePageName : string ='';
  @ViewChild(BusinesscontactpageComponent) business_contact_pgae: BusinesscontactpageComponent;
  @ViewChild(ApproveagreementComponent) approve_agreement: ApproveagreementComponent;
  @ViewChild(FieldErrorDisplayComponent) field_error_display;

  firstChild: string;

  @Output() serviceDataArrived: EventEmitter<string> = new EventEmitter<string>();

  public heroForm: FormGroup;
  constructor(private _apiservice: ContractapproveService, private router: Router, private client_info: ClientInfo) {
  }

  ngOnInit() {
    
    let agreementPk =sessionStorage["clinic_agreement_pk"];
    if(agreementPk == null || agreementPk == "null"  || agreementPk == undefined ){
      this.dialogMsg = ErrorMessages['nullClinicAgreementPk'];
      this.display = true;
      return;
    }  
    
    this._apiservice.getContractAgreementApproveData(agreementPk, "Client").subscribe((data:any) => { 
      let contractDataDBSnapShot = data;
      
      let contractdateApproved = null;
      // if((contractDataDBSnapShot && contractDataDBSnapShot.dateApproved)){
      //   contractdateApproved = new Date(contractDataDBSnapShot.dateApproved);
         
      // }
      // this.showPreUniversalContract = true;
      this.componentToRenderName= getContractComponentName(data.dateApproved,'app-approveagreement');
      this.routePageName= getContractComponentName(data.dateApproved,'contractapprove-viewcontract');
      let clientData = data.clientInfo;
      let legalInfo = data.legalNoticeAddressInfo;
      if (data.clientInfo != undefined && data.clientInfo != null &&
        data.legalNoticeAddressInfo != undefined && data.clientInfo != null) {
        this.client_info.address1 = data.legalNoticeAddressInfo.address1;
        this.client_info.address2 = data.legalNoticeAddressInfo.address2;
        this.client_info.attentionto = data.legalNoticeAddressInfo.attentionTo;
        this.client_info.cityname = data.legalNoticeAddressInfo.city;
        this.client_info.clientname = data.clientInfo.client;
        this.client_info.headername = data.clientInfo.name;
        this.client_info.statename = data.legalNoticeAddressInfo.state;
        this.client_info.titlename = data.clientInfo.title;
        this.client_info.zipcode = data.legalNoticeAddressInfo.zipCode;
        this._apiservice.setSelectedClientLegalInfo(this.client_info);

        this.business_contact_pgae.populateUIData();
      }
      SessionDetails.fromUserPage(false);
      if (data.errorS.errorCode == -5) {
        this.isInvalidContract = true;
      }
      else {
        if (data.isApproved == true || data.isApproved == 1 || data.isApproved == false || data.isApproved == 0) {
          SessionDetails.fromUserPage(true);
          // if(this.showPreUniversalContract)
          // this.router.navigateByUrl("/viewcontract");
          // else
          this.router.navigateByUrl(this.routePageName);

 
   
        }
      }
    });
  }

  GoToStep(step: number) {
    
    let agreementPk =sessionStorage["clinic_agreement_pk"];
    if(agreementPk == null || agreementPk == "null"  || agreementPk == undefined ){     
      return;
    }  
    if (this.OnSave()) {
      this.step = step;
    }

  }
  OnSave() {
    if (this.step === 1) {
      return this.business_contact_pgae.save()
    }
    else {
      return true;
    }

  }
  languageChangeToSpanish() {
    this.showBtnEn = true;
    this.showBtnSp = false;

    if (this.step === 1) {
      this.business_contact_pgae.languageChangeToSpanish();
    }
    if (this.approve_agreement !== undefined)
      this.approve_agreement.languageChangeToSpanish();
    this.currentLanguage = 'spanish';
  }

  languageChangeToEnglish() {
    this.showBtnSp = true;
    this.showBtnEn = false;
    if (this.step === 1) {
      this.business_contact_pgae.languageChangeToEnglish();
    }
    if (this.approve_agreement !== undefined) {
      this.approve_agreement.languageChangeToEnglish();
    }
    this.currentLanguage = 'english';

  }
}
