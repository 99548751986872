
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Util } from '../../../utility/util';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UserProfileService {
  user_role: string;
  search_string: string;
  RoleName: string;
  users: any[] = [];
  constructor(private _http: HttpClient) {
  }
  GetAllUsers() {
    return this._http.get(environment.API_URL+ "/api/values/GetUserInformation").pipe(catchError(Util.handleErrorV2));
  }



  public GetDirectB2B(searchString) {
    return this._http.get(environment.API_URL + "/api/values/GetB2BResults?user_role=" + searchString + "").pipe(catchError(Util.handleErrorV2));
  }
  public GetAutoCompleteData() {
    return this._http.get(environment.API_URL + "/api/values/AutoCompleteData").pipe(catchError(Util.handleErrorV2));
  }
  public GetAvailableLocations(user_role) {
    return this._http.get(environment.API_URL + "/api/values/GetAvailableLocations?user_role=" + user_role + "").pipe(catchError(Util.handleErrorV2));
  }
  public GetUserInfoBySearch(user_role, search_string) {
    let _url: string = environment.API_URL + "/api/values/GetUserInformationBySearch?user_role=" + user_role + "&search_string=" + search_string + "";
    return this._http.get(_url);
  }

  private formatErrors(error: any) {
    return observableThrowError(error.json());
  }
  ///
  deleteUser(user_pk, user_email) {
    return this._http.get(environment.API_URL + "/api/delete_user?user_pk= " + user_pk + "&updated_by=" + user_email + "", Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));;
  }
  GetUserByRole(RoleName: string): Observable<any> {
    return this._http.get(environment.API_URL + "/api/get_bindUserProfileData?user_role=" + RoleName + "", Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  

  GetUserInformation(user_role) {
    return this._http.get(environment.API_URL + "/api/get_bindUserProfileData?user_role=" + user_role + "", Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  GetUserInformationSearch(user_role, search_string) {
    return this._http.get(environment.API_URL + "/api/get_bindUserProfileData?user_role=" + user_role + "&search_value=" + search_string + "", Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));;
  }
  GetUserById(id: number): Observable<any> {
    return this._http.get(environment.API_URL + "/api/values/GetUserInformation?id=" + id + "").pipe(catchError(Util.handleErrorV2));;
  }
  AddUserProfile(user_profile,code_value) {
    let url = environment.API_URL + "/api/add_user_profile";
    return this._http.post(url, user_profile, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  bindDropDownList(user_role)
  {
    let url = environment.API_URL + "/api/bindDropdownList?user_role="+user_role+"";
    return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  GetUserRoles() {
    let url = environment.API_URL + environment.RESOURCES.GET_USER_ROLES;
    return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  EditUserProfile(pk) {
    let url = environment.API_URL + "/api/editUserProfile?user_name="+pk+"";
    return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

}
