import { Component } from '@angular/core';
import { TableInterface, Column} from '../../../../shared/models/tableInterface'
import { SessionDetails } from '../../../../utility/session';
import { ChartService } from '../../Services/chartService'; 
import { OpportunitiesRequiringActionResponse, OppourtunityReport,  OppourtunityInterface} from '../../../../models/Chart';
import { forkJoin, of, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError } from 'rxjs/operators';
import * as xlsx from 'xlsx';
import { saveAs as importedSaveAs } from "file-saver";
@Component({
  selector: 'app-priority-oppourtunities-requiring-action',
  templateUrl: './priority-oppourtunities-requiring-action.component.html',
  styleUrls: ['./priority-oppourtunities-requiring-action.component.css']
})
export class PriorityOppourtunitiesRequiringActionComponent {

  oppourtinitesRequiringActionsList: OpportunitiesRequiringActionResponse[] =[];
  noOutReachOpportunitiesColumns;
  user_profile: any;
  tableOptions: TableInterface;
  selectedReport: OppourtunityInterface;
  loading = false;
  subscription: Subscription;
  reportSubscription: Subscription;
  tableColumnNames = ["Store ID","District","Client Name","PK Hash","Date Initiated","Proposed Clinic Dates", "Status", "Requested Changes"]
  reportName: string;
  reportNames : OpportunitiesRequiringActionResponse[] = []
  btnLabel = "Export";
  downloadStartedSpinner = false;
  noOfCallsNeeded = 0;
  totalRecordsCount = 0;
  counter = 1;

  constructor(
    private chartService: ChartService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(){
    this.spinner.show();
    this.loading = false;
    this.selectedReport = SessionDetails.GetPriorityOpportunityRequiringActionMetricId();
    if(this.selectedReport.fieldValue == 4)
      this.tableColumnNames[4] = "Last Sent Date";
    this.user_profile = SessionDetails.GetUserProfile();
    this.tableOptions = {
      columns: [],
      data : [],
      isSearchable: false,
      isSortable: false,
      rowsPerPage: 50,
      rowsPerPageOptions: [10,50,100],
      hasPagination: false
    };
    forkJoin([this.chartService.getPriorityOpportunitiesRequiringAction({ userId: this.user_profile.userPk,outreachEffortId: 3}), this.selectedReport.description.trim() !== "Priority No Outreach" && this.selectedReport.description.trim() !== "Priority Follow-up" ? (this.chartService.getPriorityOpportunitiesRequiringActionReports({reportId: this.selectedReport.fieldValue, reportType: "xzc", userId: this.user_profile.userPk, outreachEffortId: 3}).pipe(catchError(e => of(e)))) : (this.chartService.getOppourtuntiyReportTypeOureachandFollowUpCount({reportId: this.selectedReport.fieldValue, reportType: "priority", userId: this.user_profile.userPk, outreachEffortId: 3}).pipe(catchError(e => of(e))))]).subscribe((data:any ) => {
      this.reportNames = data[0];
      this.reportName = data[0].filter((report: OpportunitiesRequiringActionResponse) => report.fieldValue == this.selectedReport.fieldValue)?.[0]?.description?.trim();
      this.oppourtinitesRequiringActionsList = data[0];
      this.btnLabel = this.reportName !== "Priority No Outreach" && this.reportName !== "Priority Follow-up" ? "Export": "Export To Excel";
      if(!data[1]?.statusText){
        if(this.reportName !== "Priority No Outreach" && this.reportName !== "Priority Follow-up")
          this.handleOtherType(data[1]);
        else 
          this.handleOutreachandFollowType(data[1]);
      };
      this.spinner.hide();
    },
    (error) => {
      console.log("Error while fetching the Data from API in priority opportunities list");
      this.loading = true;
      this.spinner.hide();
    });
  }

  exportToExcel(responseJsonForExcel: any,counter:number, name: string) {
    let size  = (counter-1) * (50000) + 1 +'_'+ (counter * 50000)		
    const ext = ".xlsx";
    const fileName = name +'_'+ size + ext;
    importedSaveAs(responseJsonForExcel, fileName);
  }

  handleOutreachandFollowTypeClick(){
    this.spinner.show();
    this.chartService.getOppourtuntiyReportTypeOureachandFollowUpCount({reportId: this.selectedReport.fieldValue, reportType: "priority", userId: this.user_profile.userPk, outreachEffortId: 3}).subscribe(res => {
      this.handleOutreachandFollowType(res);
      this.spinner.hide();
    }, (error => {
      console.log("Error in getting record count");
      this.spinner.hide();
    }));
  }

  handleOutreachandFollowType(data){
    this.totalRecordsCount = data?.[0]?.RecordCount || 0 ;
    this.noOfCallsNeeded =  Math.ceil(this.totalRecordsCount / 50000);
  }

  handleOtherType(data){
    let tData = [];
    let columns: Column[] = [];
    if(data.length){
      let cols: any = Object.keys(data?.[0]);
      tData = data
      if(this.reportName !== "Priority No Outreach" && this.reportName !== "Priority Follow-up") {
        columns = cols.map((col, idx) => {
          return {
            field: col,
            header: this.tableColumnNames[idx]
          }
        });
      } else {
        columns =[];
        tData = [];
      };
    } else if(this.reportName === "Priority No Outreach" || this.reportName === "Priority Follow-up"){
      this.loading = false;
    } else {
      this.loading = true;
      columns = [];
      tData = [];
    }
    this.tableOptions = {...this.tableOptions , 
      columns: columns,
      data : tData,
    };
  }

  reportChanged(event: any){
    this.spinner.show();
    this.loading = false;
    this.noOfCallsNeeded = 0;
    this.downloadStartedSpinner = false;
    this.selectedReport = this.reportNames.filter((report: OpportunitiesRequiringActionResponse) => report.fieldValue ==  Number(event.target.value))?.[0];
    if(this.selectedReport.fieldValue == 4)
      this.tableColumnNames[4] = "Last Sent Date";
    else 
      this.tableColumnNames[4] = "Date Initiated";
    this.reportName =this.selectedReport?.description?.trim();
    SessionDetails.SetPriorityOpportunityRequiringActionMetricId(this.selectedReport);
    //TBD need to unsubscribe need to impl
    this.btnLabel = this.reportName !== "Priority No Outreach" && this.reportName !== "Priority Follow-up" ? "Export": "Export To Excel";
    if(this.reportName === "Priority No Outreach" || this.reportName === "Priority Follow-up"){
      this.handleOutreachandFollowTypeClick();
      return;
    }
    this.chartService.getPriorityOpportunitiesRequiringActionReports({reportId: this.selectedReport.fieldValue, reportType: "xzc", userId: this.user_profile.userPk, outreachEffortId: 3}).subscribe((data:any ) => {
     let cols = [];
      if(data.length){
        cols = Object.keys(data?.[0]);
        if(this.reportName !== "Priority No Outreach" && this.reportName !== "Priority Follow-up") {
          cols = cols.map((col,idx) => {
            return {
              field: col,
              header: this.tableColumnNames[idx]
            }
          });
        } else {
          cols = [];
          data = [];
        }
      } else if(this.reportName === "Priority No Outreach" || this.reportName === "Priority Follow-up"){
        this.loading = false;
      } else {
        cols = [];
        this.loading = true;
      } 
      this.tableOptions = {...this.tableOptions,
        columns: cols,
        data : data,
      };
      this.spinner.hide();
    }, (error) => {
      this.tableOptions = {
        columns: [],
        data : [],
        isSearchable: false,
        isSortable: false,
        rowsPerPage: 50,
        rowsPerPageOptions: [10,50,100],
        hasPagination: false
      };
      this.loading = true;
      this.spinner.hide();
    });
  }

  generatereportformat(){
    this.spinner.show();
    let postData: OppourtunityReport = {
      userid: this.user_profile.userPk,
      reportName : this.selectedReport.description,
      ddlFormat: "0",
      reportid: this.selectedReport.fieldValue,
      reportype: "3",
      outreachpk: 3
    }
    if(this.reportName === "Priority No Outreach" || this.reportName === "Priority Follow-up"){
      postData.pagesize = 50000;
      postData.pageindex = this.counter;
      this.downloadStartedSpinner = true;
    };
    let fileName = this.reportName.split(" ").join("");
    postData.reportName = fileName;
    this.chartService.downloadPriorityOppourtunityReportData(postData).subscribe((res: any) => {
      if(this.reportName === "Priority No Outreach" || this.reportName === "Priority Follow-up"){
        this.exportToExcel(res.body,this.counter, fileName);
        this.counter++;
        if(this.counter > this.noOfCallsNeeded){
          this.downloadStartedSpinner = false;
          this.spinner.hide();    
        }                    
        if(this.counter <= this.noOfCallsNeeded){
          this.generatereportformat()
        }else {
          this.counter = 1;
        }       
      }else {
        importedSaveAs(res.body, `${fileName}.xlsx`)
        this.spinner.hide();
      }
    },(e) => {
      console.log("Error in report download from oppourtunity report");
      this.spinner.hide();
    }); 
  }

}
