import { Component, OnInit } from '@angular/core';
import * as appSettings from '../../globals';
import { WeeklyvaccinepurchasingService } from '../../services/weeklyvaccinepurchase.service';
import { ApplicationSettings } from '../../../common/application-settings';
import { WeeklyVaccinePurchasing } from '../../../../models/WeeklyVaccinePurchasing';
import { saveAs as importedSaveAs } from "file-saver";
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-weeklyvaccine',
  templateUrl: './weeklyvaccinepurchase.component.html',
  styleUrls: ['./weeklyvaccinepurchase.component.css']
})
export class WeeklyvaccinepurhaseComponent implements OnInit {
  weeklyVaccinePurchaseReportArray: any = [];
  columnCount: number = 0;
  private outreachStartDate: string;
  ddlYear: any = [];
  season: string;

  loading: boolean;

  private isCurrentSeason: number;
  private currentSeason = appSettings.ipSeason;

  column1Links: any = [];
  column2Links: any = [];
  column3Links: any = [];
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  ddlVaccines: string = "2";

  constructor(
    private WeeklyVaccinePurchase: WeeklyvaccinepurchasingService,  private spinner: NgxSpinnerService) {
  }
  ngOnInit() {
    this.season = this.currentSeason.toString();
    this.outreachStartDate = ApplicationSettings.outreachStartDateIP;
    this.isCurrentSeason = ((this.season) != (new Date(this.outreachStartDate).getFullYear().toString()) ? 0 : 1);
    for (let report_year: number = parseInt(appSettings.reportStartYear); report_year <= new Date(this.outreachStartDate).getFullYear(); report_year++) {
      if(report_year<=2020){
        continue;
      }
      if (report_year > 2014) {
        this.ddlYear.push({
          "yearName": report_year.toString() + " - " + (report_year + 1).toString(),
          "yearCode": report_year.toString()
        });
      }
    }
    this.bindReportSeasonWeeks();
  }

  onSeasonChange() {
    if (this.season) {
      this.isCurrentSeason = ((this.season) != (new Date(this.outreachStartDate).getFullYear().toString()) ? 0 : 1);
      this.bindReportSeasonWeeks();
    }
  }
  bindReportSeasonWeeks() {

    this.columnCount = 0;
    this.column1Links = [];
    this.column2Links = [];
    this.column3Links = [];

    this.loading = true;
    this.spinner.show();
    let outreach_start_date = "05-01-" + this.season;
    this.WeeklyVaccinePurchase.getVaccinePurchase(this.season, outreach_start_date, false).subscribe((resultArray: any) => {
      this.weeklyVaccinePurchaseReportArray = resultArray;
      this.columnCount = Math.ceil(resultArray.Length / 3);

      this.columnCount = Math.round(resultArray.length / 3);
      let i = 1;
      for (let j = 0; j < this.weeklyVaccinePurchaseReportArray.length; j++) {
        if (j <= this.columnCount * i) {
          if (i == 1) {
            this.column1Links.push(this.weeklyVaccinePurchaseReportArray[j]);
          } else if (i == 2) {
            this.column2Links.push(this.weeklyVaccinePurchaseReportArray[j]);
          } else if (i == 3) {
            this.column3Links.push(this.weeklyVaccinePurchaseReportArray[j]);
          }
        }
        if (j == (this.columnCount * i)) {
          i++;
        }
      }

      this.loading = false;
      this.spinner.hide();
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.spinner.hide();
      this.loading = false;
    });
  }

  getReport(file: any) {

    this.loading = true;
    this.spinner.show();
    //let from_date = file.weekRange.split('^')[0];
    //let to_date = file.weekRange.split('^')[1];
    let from_date = file.displayName.split(" - ")[0];
    let to_date = file.displayName.split(" - ")[1].substring(file.displayName.split(" - ")[1], file.displayName.split(" - ")[1].indexOf(' '));
    let file_name = "IP_VPR_" + from_date.replace(/-/g, '').replace(/\//g, '') + "-" + to_date.replace(/-/g, '').replace(/\//g, '') + ((this.season >= "2018") ? ".xlsx" : ".xls");
    let wvp: WeeklyVaccinePurchasing = new WeeklyVaccinePurchasing(from_date.replace("-", "/"), to_date.replace("-", "/"), file_name, this.isCurrentSeason, "", "",this.ddlVaccines,false);
    this.WeeklyVaccinePurchase.exportReportData(wvp).subscribe((res: any) => {
      importedSaveAs(res.body, file_name);
      this.loading = false;
      this.spinner.hide();
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
    });
  }
    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}
