import { Component, OnInit } from '@angular/core';
import { SessionDetails } from '../../../../utility/session';
import { Router } from '@angular/router';
import { ResourcecountService } from '../../services/resourcecount.service';
import { ResourceCountDetails } from '../../../../models/resourceCountDetails';
import { saveAs as importedSaveAs } from "file-saver";
import { HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-immunization',
  templateUrl: './immunization.component.html',
  styleUrls: ['./immunization.component.css']
})
export class ImmunizationComponent implements OnInit {
  userProfile = SessionDetails.GetUserProfile();
  displayDownload:boolean=false;
  selectedIndex:number;
  selectedResourcesFiles:any=[];
  totalItems: number;
  isAdmin : boolean = false;
  dialogMessage: string = "";
  

  walgreensResources=
  {
    "resources": {
        /*"COVID19Resources": [
          {
            "resourceName": "Walgreens Immunizations COVID-19 FAQ - English/Spanish",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Walgreens_Immunizations_COVID-19_FAQ_ENG_SPA.pdf"
              }
            ]
          },
          {
            "resourceName": "Uninsured COVID-19 Vaccine & Testing <br /> Buckslip - English",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/FY24_COVID_Uninsured_POD_PRINT_ENG.pdf"
              }
            ]
          },
          {
            "resourceName": "Uninsured COVID-19 Vaccine & Testing <br /> Buckslip - Spanish",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/FY24_COVID_Uninsured_POD_PRINT_SPANISH.pdf"
              }
            ]
          },
          {
            "resourceName": "What to Expect Guide - English",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/COVID19_Vaccine_WhatToExpect_Guide_Residents.pdf"
              }
            ]
          },
          {
            "resourceName": "What to Expect Guide - Spanish",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/COVID19_Vaccine_WhatToExpect_Guide_Spanish.pdf"
              }
            ]
          },
           {
             "resourceName": "On-Site Clinics Preparation Guide",
             "resourceFiles": [
               {
                 "resourceType": "PDF",
                 "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Clinic_Prep_Guide.pdf"
               }
             ]
           },
           {
             "resourceName": "B2B VAR Form - English",
             "resourceFiles": [
               {
                 "resourceType": "PDF",
                 "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/COVID-19_VACCINE_Standard_VAR.pdf"
               }
             ]
           },
            ],*/
      "DigitalSupport": [
          /*{
            "resourceName": "LTCF Facility Preparation Guide",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/LTCF_Facility_Preparation_Guide.pdf"
              }
            ]
          },*/

          {
            "resourceName": "LTCF Admin - Patient Registration Guide",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/LTCFAdmin_Patient_Registration.pdf"
              }
            ]
          },
          /* {
            "resourceName": "LTCF - Patient Insurance, Consent, &  Assessment Guide",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/LTCFAdmin_Patient_Consent_Guide.pdf"
              }
            ]
          },
          {
             "resourceName": "CP LTCF Patient Insurance & Assessment Guide",
             "resourceFiles": [
               {
                 "resourceType": "PDF",
                 "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/LTCF_Insurance_Assessment_Guide.pdf"
               }
             ]
           },*/
          {
            "resourceName": "LTCF Admin - Dashboard Access",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/LTCFAdmin_Dashboard_Access.pdf"
              }
            ]
          },
          {
            "resourceName": "CuraPatient Add User Guide",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/LTCFAdmin_Add_User_Guide.pdf"
              }
            ]
          }
          /*{
            "resourceName": "LTCF VAR form - Spanish",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/COVID-19_VACCINE_LTCF_VAR_SP.pdf"
              }
            ]
          },
          {
            "resourceName": "LTCF VAR form - GA",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/COVID-19_VACCINE_LTCF_VAR_GA.pdf"
              }
            ]
          },
          {
            "resourceName": "LTCF VAR form - NC",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/COVID-19_VACCINE_LTCF_VAR_NC.pdf"
              }
            ]
          },
          {
            "resourceName": "LTCF VAR form - SC",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/COVID-19_VACCINE_LTCF_VAR_SC.pdf"
              }
            ]
          },
          {
            "resourceName": "Moderna COVID-19 EUA Fact Sheet",
            "resourceFiles": [
              {
                "resourceType": "PDF",
                "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Moderna_COVID-19_EUA_Fact_Sheet.pdf"
              }
            ]
          },*/
          /* {
             "resourceName": "COVID-19 EUA Fact Sheets" 
           }
           ,
           {
             "resourceName": "Sample Call Script for COVID-19 Clinics",
             "resourceFiles": [
               {
                 "resourceType": "PDF",
                 "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Sample_Call_Script_COVID-19_Initial_Outreach.pdf"
               }
             ]
           }*/
        ],
//       "MarketingMaterials": [
//          {
//             "resourceName": "Editable Clinic Flyer",
//             "resourceFiles": [
//           {
//       "resourceType": "PDF",
//       "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Editable_Clinic_Flyer.pdf"
//           }
//         ] 
//       },
//       {
//   "resourceName": "Immunization Pull Through Toolkit",
//   "resourceFiles": [
//     {
//       "resourceType": "PDF",
//       "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Immunization_Pull_Through_Toolkit.pdf"
//     } 
//   ]
// }      
//       ],
      "BeneficialResources": [
        {
          "resourceName": "Appointment Scheduler Job Aid – Client",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Appointment_Scheduling_Guide.pdf"
            }
          ] 
        },
        {
          "resourceName": "Appointment Scheduler Job Aid - Team Member",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Off-Site_Appointment_Scheduling_Job_Aid.pdf"
            }
          ] 
        },        
        {
          "resourceName": "Clinic Upload Template",
          "resourceFiles": [
            {
              "resourceType": "EXCEL",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/ClinicUploadTemplate.xlsm"
            }
          ] 
        },
        {
          "resourceName": "Offsite Patient Clinic Sign Up Sheet - Editable",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/OffsiteClinicPatientSignUpSheet-Editable.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            } ]
          },
        {
          "resourceName": "Offsite Patient Clinic Sign Up Sheet",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/OffsiteClinicPatientSignUpSheet.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
         /*{
           "resourceName": "On-Site Immunization Clinics: Client Guide",
           "resourceFiles": [
             {
               "resourceType": "PDF",
               "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/On-Site_Immunization_Client_Guide.pdf"
             },
             {
               "resourceType": "PDF NOTES",
               "resourcePath": ""
             },
             {
               "resourceType": "PPTX",
               "resourcePath": ""
             },
             {
               "resourceType": "EXCEL",
               "resourcePath": ""
             }
           ]
         },                   
        {
          "resourceName": "Printable Call Script",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Imz_Call_Script.pdf"
            },
            {
              "resourceType": "PDF NOTES",
              "resourcePath": ""
            },
            {
              "resourceType": "PPTX",
              "resourcePath": ""
            },
            {
              "resourceType": "EXCEL",
              "resourcePath": ""
            }
          ]
        },
         {
           "resourceName": "Social Distancing Signage",
           "resourceFiles": [
             {
               "resourceType": "PDF",
               "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/COVID_SocialDistancing.pdf"
             },
             {
               "resourceType": "PDF NOTES",
               "resourcePath": ""
             },
             {
               "resourceType": "PPTX",
               "resourcePath": ""
             },
             {
               "resourceType": "EXCEL",
               "resourcePath": ""
             }
           ]
         }
         ,
         {
           "resourceName": "Standard English VAR",
           "resourceFiles": [
             {
               "resourceType": "PDF",
               "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/prefilledVarForm_EN.pdf"
             },
             {
               "resourceType": "PDF NOTES",
               "resourcePath": ""
             },
             {
               "resourceType": "PPTX",
               "resourcePath": ""
             },
             {
               "resourceType": "EXCEL",
               "resourcePath": ""
             }
           ]
         }
         ,       
         {
           "resourceName": "Walgreens W9",
           "resourceFiles": [
             {
               "resourceType": "PDF",
               "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/W9form_102018.pdf"
             },
             {
               "resourceType": "PDF NOTES",
               "resourcePath": ""
             },
             {
               "resourceType": "PPTX",
               "resourcePath": ""
             },
             {
               "resourceType": "EXCEL",
               "resourcePath": ""
             }
           ]
         }*/
       
      ]/*,
          "HealthEquityResources": [
        {
          "resourceName": "Field Leadership Outreach",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/HealthEquityFluShotClinics_FieldLeadershipOutreachEmail.pdf"
            }
          ] 
        }
        ,
             {
          "resourceName": "Field Leadership Talking Points",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/HealthEquityFluShotClinics_FieldLeadershipTalkingPoints.pdf"
            }
          ] 
        } 
         ,
             {
          "resourceName": "Flu Educational Flyer",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/HealthEquity_FluEducationalFlyer.pdf"
            }
          ] 
        }
        ,
             {
          "resourceName": "Flu Poster",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/HealthEquity_FluPoster.pdf"
            }
          ] 
        }
        ,
        {
     "resourceName": "Flu Shot Clinics - Partnership Interest Email",
     "resourceFiles": [
       {
         "resourceType": "PDF",
         "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/FluShotClinics_PartnershipInterestEmail.pdf"
       }
     ] 
   }
         ,
             {
          "resourceName": "Partner Social Media Templates - English",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/FluShotClinics_PartnerSocialMediaTemplates.pdf"
            }
          ] 
        }
        ,
        {
     "resourceName": "Partner Social Media Templates - Spanish",
     "resourceFiles": [
       {
         "resourceType": "PDF",
         "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/FluShotClinics_PartnerNewsletterTemplateSpanish.pdf"
       }
     ] 
   }
        ,
             {
          "resourceName": "Partner Newsletter Template - English",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/FluShotClinics_PartnerNewsletterTemplate.pdf"
            }
          ] 
        }
        ,
        {
     "resourceName": "Partner Newsletter Template - Spanish",
     "resourceFiles": [
       {
         "resourceType": "PDF",
         "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/FluShotClinics_PartnerSocialMediaTemplatesSpanish.pdf"
       }
     ] 
   }
        ,
             {
          "resourceName": "Sample Call Script - Health Equity Initiative",
          "resourceFiles": [
            {
              "resourceType": "PDF",
              "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/HealthEquityInitiative_TeamMemberSampleCallScript.pdf"
            }
          ] 
        }
          ]*/
    }
  } 

  constructor(private router:Router,private resourcecountService: ResourcecountService,private  spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.displayDownload = false;
    this.isAdmin = this.userProfile.isAdmin;
  }

  scrollDownSample(){
    window.document.getElementById('smapleCallScript').scrollIntoView();
  }
  visresources(){
  this.router.navigate(['/communityoutreach/vissresouces']) ;
  }
  showDownloadDialog(index,resource_category)
  {
    this.selectedResourcesFiles=this.walgreensResources.resources[resource_category][index].resourceFiles;
    this.displayDownload=true;
  }

  dispSelectedResource(selected_type){
    
  }

  
  getCount(value: string) { 
    this.spinner.show(); 
    let getData: ResourceCountDetails = {
      userId: this.userProfile.userPk,
      reportName: value
    };
      this.resourcecountService.getResourceCount(getData).subscribe((res: HttpResponse<Blob>) => {
      importedSaveAs(res.body, 'Resources & Materials Tracking.xlsx'); 
      this.spinner.hide();  
    }, error => {
      this.spinner.hide(); 
      if (error.status === 417) {
        this.dialogMessage = "An error occurred";
      } else {
        this.dialogMessage = "An error occurred.";
      }
    });
  }
  
  handleClick(event: Event, resourceName: string ,resourceLink: string) {
    let postData: ResourceCountDetails = {
      userId: this.userProfile.userPk,
      title : resourceName,
      resourceClickedTime: new Date().toISOString(),
      resourceLink: resourceLink,
      count : 0,
      pk : 0
    }
    this.resourcecountService.resourceItemCount(postData).subscribe((data :any)=>{
    },(e) => {
      console.log("Error");
    });
  }
}