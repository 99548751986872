import { Component, OnInit, Input } from '@angular/core';
import { ContractapproveService } from '../../services/contractapprove.service';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { states } from '../../../../JSON/States';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { ClientInfo } from '../../../../models/client-info';
import { Util } from '../../../../utility/util';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-businesscontactpage',
  templateUrl: './businesscontactpage.component.html',
  styleUrls: ['./businesscontactpage.component.css'],
  providers: [DatePipe]
})
export class BusinesscontactpageComponent implements OnInit {
  @Output() public childControls = new EventEmitter();
  @Input("currentLanguage")
  currentLanguage: string;
  showBtnEn: boolean = false;
  showBtnSp: boolean = true;
  enlanguageSelection: boolean;
  splanguageSelection: boolean;
  spanishLanguage: string;
  headerText: string;
  attachment: string;
  json: string;
  imz: string;
  payment: string;
  rates: string;
  englishLanguage: string;
  enheader: string;
  enattachment: string;
  client_name: string;
  header_name: string;
  title_name: string;
  attention: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  client_info: string;
  legal_notice: string;
  clientName: string;
  headerName: string;
  titleName: string;
  test: string;
  firsttime: number = 0;
  errorMessage: string;
  client: any;
  name: any;
  title: any;
  attention_to: any;
  address_1: any;
  address_2: any;
  city_name: any;
  state_name: any;
  zip_code: any;
  clientlegalform: FormGroup;
  client_information: any[];
  legal_information: any[];
  clientinfo: ClientInfo = new ClientInfo();
  states: any[];
  dialogSummary: string;
  dialogMsg: string;
  display:boolean = false;
  @ViewChild("clientForm", { read: ElementRef }) tref: ElementRef;


  @Output() isChildFormValid: EventEmitter<any> = new EventEmitter<any>();
  constructor(private _apiservice: ContractapproveService, private _fb: FormBuilder, private utility: Util
    , private clientInfo: ClientInfo, private _datePipe:DatePipe) {
    this.clientinfo = this.clientInfo;
  }
  ngOnInit() {
    this.states = states.sort((a,b)=>a.id.localeCompare(b.id));
    this.clientlegalform = this._fb.group({
      clientname: this._fb.control('', Validators.required),
      headername: this._fb.control('', Validators.required),
      titlename: this._fb.control('', Validators.required),
      businessApprovedDate:this._fb.control(this._datePipe.transform(new Date(),'MM/dd/yyyy'), null),
      attentionto: this._fb.control('', Validators.required),
      address1: this._fb.control('', Validators.required),
      address2: this._fb.control('', null),
      cityname: this._fb.control('', Validators.required),
      statename: this._fb.control('', Validators.required),
      zipcode: this._fb.control('', Validators.required)
    });
    this.GetClientLegalInfo();
    this.changeLanguage();
  }
  populateUIData(){
    this.clientinfo = this._apiservice.getSelectedClientLegalInfo();
        if (this.clientinfo != undefined) {
          this.clientlegalform = this._fb.group({
            clientname: this._fb.control(this.clientinfo.clientname, Validators.required),
            headername: this._fb.control(this.clientinfo.headername, Validators.required),
            titlename: this._fb.control(this.clientinfo.titlename, Validators.required),
            businessApprovedDate:this._fb.control(this._datePipe.transform(new Date(),'MM/dd/yyyy'), null),
            attentionto: this._fb.control(this.clientinfo.attentionto, Validators.required),
            address1: this._fb.control(this.clientinfo.address1, Validators.required),
            address2: this._fb.control(this.clientinfo.address2, null),
            cityname: this._fb.control(this.clientinfo.cityname, Validators.required),
            statename: this._fb.control(this.clientinfo.statename, Validators.required),
            zipcode: this._fb.control(this.clientinfo.zipcode, Validators.required)
          });
        }
  }
  isFieldValid(field: string) {
    return !this.clientlegalform.get(field).valid && this.clientlegalform.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  GetClientLegalInfo() {
    this.clientinfo = this._apiservice.getSelectedClientLegalInfo();
    if (this.clientinfo != undefined) {
      this.clientlegalform = this._fb.group({
        clientname: this._fb.control(this.clientinfo.clientname, Validators.required),
        headername: this._fb.control(this.clientinfo.headername, Validators.required),
        titlename: this._fb.control(this.clientinfo.titlename, Validators.required),
        businessApprovedDate:this._fb.control(this._datePipe.transform(new Date(),'MM/dd/yyyy'), null),
        attentionto: this._fb.control(this.clientinfo.attentionto, Validators.required),
        address1: this._fb.control(this.clientinfo.address1, Validators.required),
        address2: this._fb.control(this.clientinfo.address2, null),
        cityname: this._fb.control(this.clientinfo.cityname, Validators.required),
        statename: this._fb.control(this.clientinfo.statename, Validators.required),
        zipcode: this._fb.control(this.clientinfo.zipcode, Validators.required)
      });
    }
  }
  save(): boolean {
    if (this.clientlegalform.valid) {
      
      this._apiservice.setSelectedClientLegalInfo(this.clientlegalform.value);
      return true;
    }
    else {
      this.utility.validateAllFormFields(this.clientlegalform);
      this.showDialog(ErrorMessages['errMsg'],ErrorMessages['MandatoryFields'])
      return false;
    }
  }

  languageChangeToSpanish() {
    this.enlanguageSelection = false;
    this.splanguageSelection = true;
    
    let data:any = this._apiservice.languageChange();
    if(data){
      this.client_name = data.clientsp;
      this.header_name = data.namesp;
      this.title_name = data.titlesp;
      this.attention = data.attentionsp;
      this.address1 = data.address1sp;
      this.address2 = data.address2sp;
      this.city = data.citysp;
      this.state = data.statesp;
      this.zipcode = data.zipcodesp;
      this.legal_notice = data.legalsp;
      this.client_info = data.clientinfosp;
    }

    this.showBtnEn = true;
    this.showBtnSp = false;

  }
  languageChangeToEnglish() {
    this.splanguageSelection = false;
    this.enlanguageSelection = true;
    let data:any = this._apiservice.languageChange();
    if(data)
     {
      this.client_name = data.clienten;
      this.header_name = data.nameen;
      this.title_name = data.titleen;
      this.attention = data.attentionen;
      this.address1 = data.address1en;
      this.address2 = data.address2en;
      this.city = data.cityen;
      this.state = data.stateen;
      this.zipcode = data.zipcodeen;
      this.legal_notice = data.legalen;
      this.client_info = data.clientinfoen;
    }
    this.showBtnSp = true;
    this.showBtnEn = false;
  }

  okClicked(){
    this.display = false;
  }

  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
  }
  changeLanguage() {
    
    if (this.currentLanguage == 'spanish' ) {
      this.languageChangeToSpanish();
    } else {
      this.languageChangeToEnglish();
    }
  }
}
