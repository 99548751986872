import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';

import { WorkflowService } from './workflow.service';

@Injectable()
export class WorkflowGuard {
    constructor(private router: Router, private workflowService: WorkflowService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let path: string = route.routeConfig.path;

        return this.verifyWorkFlow(path);
    }

    verifyWorkFlow(path)  {

        // If any of the previous steps is invalid, go back to the first invalid step
        let firstPath = this.workflowService.getFirstInvalidStep(path);
        if (firstPath.length > 0) {
           // console.log("Redirected to '" + firstPath + "' path which it is the first invalid step.");
           // let url = `/${firstPath}`;
          //  this.router.navigate([url]);
            return firstPath;
        };

        return path;
    }
}


