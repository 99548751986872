import { Component } from '@angular/core';
import { Column, TableInterface} from '../../../../shared/models/tableInterface'
import { SessionDetails } from '../../../../utility/session';
import { ChartService } from '../../Services/chartService'; 
import { OpportunitiesRequiringActionResponse, OppourtunityReport, OppourtunityInterface } from '../../../../models/Chart';
import { forkJoin, of, Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { catchError } from 'rxjs/operators';
import * as xlsx from 'xlsx';
import { saveAs as importedSaveAs } from "file-saver";

@Component({
  selector: 'app-oppourtunities-requiring-action',
  templateUrl: './oppourtunities-requiring-action.component.html',
  styleUrls: ['./oppourtunities-requiring-action.component.css']
})
export class OppourtunitiesRequiringActionComponent {

  oppourtinitesRequiringActionsList: OpportunitiesRequiringActionResponse[] =[];
  reportNames: OpportunitiesRequiringActionResponse[] = [];
  noOutReachOpportunitiesColumns;
  user_profile: any;
  tableOptions: TableInterface = {} as TableInterface;
  subscription: Subscription;
  reportSubscription: Subscription;
  selectedReport: OppourtunityInterface;
  loading = false;
  tableColumnNames = ["Store ID","District","Client Name","PK Hash","Date Initiated","Proposed Clinic Dates", "Status", "Requested Changes"]
  reportName: string;
  btnLabel = "Export";
  downloadStartedSpinner = false;
  noOfCallsNeeded = 0;
  totalRecordsCount = 0;
  counter = 1;

  constructor(
    private chartService: ChartService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(){
    this.spinner.show();
    this.loading = false;
    this.selectedReport = SessionDetails.GetOpportunityRequiringActionMetricId();
    if(this.selectedReport.fieldValue == 4)
      this.tableColumnNames[4] = "Last Sent Date" 
    this.user_profile = SessionDetails.GetUserProfile();
    this.tableOptions = {
      columns: [],
      data : [],
      isSearchable: false,
      isSortable: false,
      rowsPerPage: 50,
      rowsPerPageOptions: [10,50,100],
      hasPagination: false
    };
    forkJoin([this.chartService.getOpportunitiesRequiringAction({ userId: this.user_profile.userPk,outreachEffortId: 3}), this.selectedReport.description.trim() !== "No Outreach" && this.selectedReport.description.trim() !== "Follow-up" ? this.chartService.getOpportunitiesRequiringActionReports({reportId: this.selectedReport.fieldValue, reportType: "cxzc", userId: this.user_profile.userPk, outreachEffortId: 3}).pipe(catchError(e => of(e))): this.chartService.getOppourtuntiyReportTypeOureachandFollowUpCount({reportId: this.selectedReport.fieldValue, reportType: "cxzc", userId: this.user_profile.userPk, outreachEffortId: 3}).pipe(catchError(e => of(e)))]).subscribe((data: any) => {
      this.reportNames = data[0];
      this.reportName = data[0].filter((report: OpportunitiesRequiringActionResponse) => report.fieldValue == this.selectedReport.fieldValue)?.[0]?.description?.trim();
      this.oppourtinitesRequiringActionsList = data[0];
      this.btnLabel = this.reportName !== "No Outreach" && this.reportName !== "Follow-up" ? "Export": "Export To Excel";
      if(this.reportName !== "No Outreach" && this.reportName !== "Follow-up")
        this.handleOtherType(data[1]);
      else 
        this.handleOutreachandFollowType(data[1])
      this.spinner.hide();
    },
    (error) => {
      console.log("Error while fetching the Data from API in opportunities list");
      this.spinner.hide();
    });
  }

  // updateTableOptions(){
    
  // }

  handleOutreachandFollowTypeClick(){
    this.spinner.show();
    this.chartService.getOppourtuntiyReportTypeOureachandFollowUpCount({reportId: this.selectedReport.fieldValue, reportType: "", userId: this.user_profile.userPk, outreachEffortId: 3}).subscribe(res => {
      this.handleOutreachandFollowType(res);
      this.spinner.hide();
    }, (error => {
      console.log("Error in getting record count");
      this.spinner.hide();
    }));
  }

  handleOutreachandFollowType(data){
    this.totalRecordsCount = data?.[0]?.RecordCount || 0;
    this.noOfCallsNeeded =  Math.ceil(this.totalRecordsCount / 50000);
  }

  handleOtherType(data){
    let tData = [];
    let columns: Column[] = [];
    if(!data?.statusText){
      if(data.length){
        let cols: any = Object.keys(data?.[0]);
        tData = data;
        if(this.reportName !== "No Outreach" && this.reportName !== "Follow-up") {
          columns = cols.map((col, idx) => {
            return {
              field: col,
              header: this.tableColumnNames[idx]
            }
          });
        } else {
          columns = [];
          tData = [];
        }
      } else if(this.reportName === "No Outreach" || this.reportName === "Follow-up"){
        this.loading = false;
      } else {
        this.loading = true;
        columns = [];
        tData = [];
      }
      this.tableOptions = {
        ...this.tableOptions,
        columns: columns,
        data : tData,
      };
    };
  }

  exportToExcel(responseJsonForExcel: any,counter:number, name: string) {
    let size  = (counter-1) * (50000) + 1 +'_'+ (counter * 50000)		
    const ext = ".xlsx";
    const fileName = name +'_'+ size + ext;
    importedSaveAs(responseJsonForExcel, fileName);
  }

  reportChanged(event: any){
    this.spinner.show();
    this.loading = false;
    this.noOfCallsNeeded = 0;
    this.downloadStartedSpinner = false;
    this.selectedReport = this.reportNames.filter((report: OpportunitiesRequiringActionResponse) => report.fieldValue == Number(event.target.value))?.[0];
    this.reportName = this.selectedReport?.description?.trim();
    this.btnLabel = this.reportName !== "No Outreach" && this.reportName !== "Follow-up" ? "Export": "Export To Excel";
    SessionDetails.SetOpportunityRequiringActionMetricId(this.selectedReport);
    if(this.selectedReport.fieldValue == 4)
      this.tableColumnNames[4] = "Last Sent Date";
    else 
      this.tableColumnNames[4] = "Date Initiated";
    let columns: Column[] = [];
    if(this.reportName === "No Outreach" || this.reportName === "Follow-up"){
      this.handleOutreachandFollowTypeClick();
      return;
    }
    //TBD need to unsubscribe need to impl
    this.chartService.getOpportunitiesRequiringActionReports({reportId: this.selectedReport.fieldValue, reportType: "cxzc", userId: this.user_profile.userPk, outreachEffortId: 3}).subscribe((data:any ) => {
      if(data.length){
        let cols: any = Object.keys(data?.[0]);
        if(this.reportName !== "No Outreach" && this.reportName !== "Follow-up") {
          columns = cols?.map((col, idx) => {
            return {
              field: col,
              header: this.tableColumnNames[idx]
            }
          });
        } else {
          columns = [];
          data = [];
        }
      } else if(this.reportName === "No Outreach" || this.reportName === "Follow-up"){
        this.loading = false; 
      } else {
        columns = [];
        this.loading = true;
      };
      this.tableOptions = {...this.tableOptions,
        columns: columns,
        data : data,
      };
      this.spinner.hide();
    }, (error) => {
      this.tableOptions = {...this.tableOptions,
        columns: [],
        data : [],
      };
      this.loading = true;
      this.spinner.hide();
    })
  }

  generatereportformat(){
    this.spinner.show();
    let postData: OppourtunityReport = {
      userid: this.user_profile.userPk,
      reportName : this.selectedReport.description,
      ddlFormat: "xlsxv",
      reportid: this.selectedReport.fieldValue,
      reportype: "3",
      outreachpk: 3
    };
    if(this.reportName === "No Outreach" || this.reportName === "Follow-up"){
      postData.pagesize = 50000;
      postData.pageindex = this.counter;
      this.downloadStartedSpinner = true;
    };
    let fileName = this.reportName.split(" ").join("");
    postData.reportName = fileName;
    this.chartService.downloadOppourtunityReportData(postData).subscribe((res: any) => {
      if(this.reportName === "No Outreach" || this.reportName === "Follow-up"){
        this.exportToExcel(res.body,this.counter, fileName);
        this.counter++;
        if(this.counter > this.noOfCallsNeeded){
            this.downloadStartedSpinner = false;
            this.spinner.hide();    
        };                  
        if(this.counter <= this.noOfCallsNeeded){
          this.generatereportformat()
        } else
          {
          this.counter = 1;
        };      
      }else {
        importedSaveAs(res.body, `${fileName}.xlsx`)
        this.spinner.hide();
      }
    },(e) => {
      console.log("Error in report download from oppourtunity report", e);
      this.spinner.hide();
    }); 
  }
}