import {Immunization, Immunization2} from './contract'

export class coClinics {
  public location: string;
  public  contactFirstName: string;
   public contactLastName: string;
  public  contactEmail: string;
  public  contactPhone: string;
   public clinicDate: string;
  public  startTime: string;
   public endTime: string;
  public  address1: string;
  public address2: string;
  public  city: string;
  public  state: string;
  public  zipCode: string;
  public  isCurrent: number;
  public coOutreachTypeId: number;
  public coOutreachTypeDesc: string;
  public latitude: string = '';
  public longitude: string = '';
  public isCovidClinic:number = 0;
  public  clinicImzQtyList: Immunization[]=[];
  constructor(){
    this.isCurrent = 1;
}
}
export class ErrorS {
   public errorCode: string;
   public errorMessage: string;
   constructor(){
     this.errorCode='0';
     this.errorMessage = '';
   }
}
export class LogOutreachStatus {
   public businessPk: number;
  public  firstName: string;
   public lastName: string;
   public jobTitle: string;
  public  outreachProgram: string;
  public  outreachBusinessPk: number;
   public contactDate: Date;
   public outreachStatusId: number;
   public outreachStatusTitle: string;
   public feedback: string;
   public createdBy: number;
   public clinicAgreementPk: number;
   constructor(){
    this.businessPk = 0;
    this.clinicAgreementPk = -1;
    this.outreachBusinessPk = -1;

   }
}
export class CommunityOutreach {
  public logOutreachStatus: LogOutreachStatus = new LogOutreachStatus();
  public clinicAgreementPk: number;
  public contactLogPk: number;
  public isApproved: number;
  public approvedOrRejectedBy: string;
  public isEmailSent: number;
  public dateCreated: string;
  public lastUpdatedDate: string;
  public contactWagUser: string;
  public clinicImmunizationList: Immunization2[];
  public clinicList: Array<coClinics> 
  public errorS: ErrorS 
  constructor(){
    this.clinicAgreementPk = -1;
    this.contactLogPk = -1;
    this.isApproved = 0;
    this.approvedOrRejectedBy = "CommunityUpdate@WagOutreach.com";
    this.isEmailSent = 0;
    this.clinicImmunizationList = [];
    this.clinicList  = new Array<coClinics>();
    this.errorS = new ErrorS();
    this.logOutreachStatus = new LogOutreachStatus();
  }
}

export class EquityEvent {
  public logOutreachStatus: LogOutreachStatus = new LogOutreachStatus();
  public clinicAgreementPk: number;
  public contactLogPk: number;
  public isApproved: number;
  public approvedOrRejectedBy: string;
  public isEmailSent: number;
  public dateCreated: string;
  public lastUpdatedDate: string;
  public contactWagUser: string;
  public clinicImmunizationList: Immunization2[];
  public clinicList: Array<coClinics> 
  public errorS: ErrorS 
  constructor(){
    this.clinicAgreementPk = -1;
    this.contactLogPk = -1;
    this.isApproved = 0;
    this.approvedOrRejectedBy = "CommunityUpdate@WagOutreach.com";
    this.isEmailSent = 0;
    this.clinicImmunizationList = [];
    this.clinicList  = new Array<coClinics>();
    this.errorS = new ErrorS();
    this.logOutreachStatus = new LogOutreachStatus();
  }
}

export class CommOutreachProgram {
  public clinicAgreementPk: number;
  public contactLogPk: number;
  public isApproved: number;
  public approvedOrRejectedBy: string;
  public isEmailSent: number;
  //public dateCreated: string;
  //public lastUpdatedDate: string;
  //public contactWagUser: string;
  public clinicImmunizationList: Immunization2[];
  public clinicList: Array<coClinics> 
  public errorS: ErrorS 
  constructor(){
    this.clinicAgreementPk = -1;
    this.contactLogPk = -1;
    this.isApproved = 0;
    this.approvedOrRejectedBy = "CommunityUpdate@WagOutreach.com";
    this.isEmailSent = 0;
    this.clinicImmunizationList = [];
    this.clinicList  = new Array<coClinics>();
    this.errorS = new ErrorS();    
  }
}

export class CommunityOutreachServiceModel {
  public logOutreachStatus: LogOutreachStatus;
  public commOutreachProgram: CommOutreachProgram;
  constructor(){
    this.logOutreachStatus = new LogOutreachStatus();
    this.commOutreachProgram = new CommOutreachProgram();
  }
}


export class EquityEventsProgram {
  public clinicAgreementPk: number;
  public contactLogPk: number;
  public isApproved: number;
  public approvedOrRejectedBy: string;
  public isEmailSent: number;
  //public dateCreated: string;
  //public lastUpdatedDate: string;
  //public contactWagUser: string;
  public clinicImmunizationList: Immunization2[];
  public clinicList: Array<coClinics> 
  public errorS: ErrorS 
  constructor(){
    this.clinicAgreementPk = -1;
    this.contactLogPk = -1;
    this.isApproved = 0;
    this.approvedOrRejectedBy = "CommunityUpdate@WagOutreach.com";
    this.isEmailSent = 0;
    this.clinicImmunizationList = [];
    this.clinicList  = new Array<coClinics>();
    this.errorS = new ErrorS();    
  }
}

export class EquityEventsServiceModel {
  public logOutreachStatus: LogOutreachStatus;
  public commOutreachProgram: CommOutreachProgram;
  constructor(){
    this.logOutreachStatus = new LogOutreachStatus();
    this.commOutreachProgram = new CommOutreachProgram();
  }
}

