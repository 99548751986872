import { Component, OnInit } from '@angular/core';
import {  FormGroup,Validators,FormBuilder} from '@angular/forms';
import { saveAs as importedSaveAs } from "file-saver";
import { Scheduledeventsreportservice } from '../../services/scheduledeventsreport.services';
import { ScheduledEventsReport } from '../../../../models/ScheduledEventsReport';
import { SessionDetails } from '../../../../utility/session';
import { ApplicationSettings } from '../../../common/application-settings';
import { environment } from "../../../../../environments/environment";
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-scheduledeventsreport',
  templateUrl: './scheduledeventsreport.component.html',
  styleUrls: ['./scheduledeventsreport.component.css']
})

export class ScheduledeventsreportComponent implements OnInit {
  scheduledEventsReportForm: FormGroup;
  scheduledEventsReportArray: any;
  ddlTimePeriod: string=ApplicationSettings.getSRCurrentQuarter();
  private outreachEffort = environment.outreachEffort;
  totalRecords: number;
  loading: boolean;
  ddlYear: any = ApplicationSettings.getOutreachReportYears();
  private dt = new Date();
  private outreachStartDate: Date=new Date(ApplicationSettings.getSRStartDate(this.dt.getFullYear()));
  ddlFiscalYear: any=(this.outreachStartDate).getFullYear() + 1;
  ddlQuarter: any;
  ddlFormat: string;
  private isCurrentSeason: number;
  private userProfile: any;
  //outreachReportStartYear=2014;  
  Quarter = ApplicationSettings.getReportYearQuarters();
  canExport:boolean=true;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";

  showGrid:boolean = false;

  constructor( private fb: FormBuilder, private Scheduledeventsreport: Scheduledeventsreportservice, private spinner: NgxSpinnerService) {
    this.userProfile = SessionDetails.GetUserProfile();
  }

  ngOnInit() { 
    this.ddlFormat = "xlsx";
    this.isCurrentSeason = ((this.ddlFiscalYear) != (new Date(this.outreachStartDate).getFullYear()+1) ? 0 : 1);
    
    this.setFormControls();
    //this.generateReport();
  };
  setFormControls() {
    this.scheduledEventsReportForm = this.fb.group({
      'ddlFiscalYear': [this.ddlFiscalYear,null],
      'ddlTimePeriod': [this.ddlTimePeriod,null],
      'ddlFormat': [this.ddlFormat]
    });
  
     //this.generateReport();
   }
  
  generateReport(): void {
    
    if(!this.showGrid){
      this.spinner.show();
    }else{
      this.loading = true;
    }
    let data = this.scheduledEventsReportForm.value;
    this.ddlFiscalYear = data.ddlFiscalYear;
    this.isCurrentSeason = ((this.ddlFiscalYear) != (new Date(this.outreachStartDate).getFullYear()+1) ? 0 : 1);
    //let quarter_start_date:any = new Date(new Date(((this.isCurrentSeason)?"09/01/":"09/01/") + (this.ddlFiscalYear -1)));
    //quarter_start_date = ((quarter_start_date.getMonth() + 1) + "/" + quarter_start_date.getDate() + "/" + quarter_start_date.getFullYear());
    //let quarter_start_date = ((this.outreachStartDate.getMonth() + 1) + "/" + this.outreachStartDate.getDate() + "/" + this.outreachStartDate.getFullYear());
 
    let quarter_start_date = ApplicationSettings.getSRStartDate(data.ddlFiscalYear -1);
    let se: ScheduledEventsReport = new ScheduledEventsReport(this.userProfile.userPk, this.userProfile.userRole, quarter_start_date, data.ddlTimePeriod, this.outreachEffort, this.isCurrentSeason, "ScheduledEvents", "xlsxv");
   
    this.Scheduledeventsreport.getScheduledEventsReportData(se).subscribe((resultArray: any) => {
      this.scheduledEventsReportArray = resultArray;
	  this.totalRecords = resultArray.length;
      setTimeout(() => {
        this.loading = false;
        this.spinner.hide();
        this.showGrid = true;
        this.canExport=true;
      }, 1000)

      //console.log(res);
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
      this.scheduledEventsReportArray = [];
      this.totalRecords = 0;
    });
  }
  generatereportformat(): void {
    this.loading=true;
    let data = this.scheduledEventsReportForm.value;
    this.ddlFiscalYear = data.ddlFiscalYear;
    this.isCurrentSeason = ((this.ddlFiscalYear) != (new Date(this.outreachStartDate).getFullYear()+1) ? 0 : 1);
    //let quarter_start_date:any = new Date(new Date(((this.isCurrentSeason)?"09/01/":"09/01/") + (this.ddlFiscalYear -1)));
    //quarter_start_date = ((quarter_start_date.getMonth() + 1) + "/" + quarter_start_date.getDate() + "/" + quarter_start_date.getFullYear());
    //let quarter_start_date = ((this.outreachStartDate.getMonth() + 1) + "/" + this.outreachStartDate.getDate() + "/" + this.outreachStartDate.getFullYear());
    let quarter_start_date = ApplicationSettings.getSRStartDate(this.ddlFiscalYear -1);
     
    let se: ScheduledEventsReport = new ScheduledEventsReport(this.userProfile.userPk, this.userProfile.userRole, quarter_start_date, data.ddlTimePeriod, this.outreachEffort, this.isCurrentSeason, "ScheduledEvents","xlsxv");
    
    this.Scheduledeventsreport.exportReportData(se).subscribe((res:Response) => {
      importedSaveAs(res.body, res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1]);
      this.loading=false;
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
  validateExportType(){
    if(this.ddlFormat=="0" || !this.canExport)
    return true;
    else
     return false;
  }
  canExportOnchange(){
    this.canExport=false;
  }

  reset(): void {
    //this.loading = true;
    this.showGrid = false;
    this.ddlFormat = "xlsx";
    this.ddlTimePeriod = ApplicationSettings.getSRCurrentQuarter();
    this.ddlFiscalYear = (this.outreachStartDate).getFullYear() + 1;
    this.setFormControls();
    //this.generateReport();
  }

    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}