export class DistrictCompliance {
    districtId:number;
    outreachEffort:number;
    yearStartDate:string;
    quarter:number;
    outputType: string = null;
    reportName: string = null;
    constructor(district_id: number, outreach_effort: number, year_start_date: string, quarter: number, output_type?: string, report_name?: string, ) {
      this.districtId = district_id;
      this.outreachEffort = outreach_effort;
      this.yearStartDate = year_start_date;
      this.quarter = quarter;
      this.outputType = output_type;
      this.reportName = report_name;
    }
  }