import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TableInterface } from '../../models/tableInterface';
import * as moment from 'moment';

@Component({
  selector: 'app-table-component',
  templateUrl: './table-component.component.html',
  styleUrls: ['./table-component.component.css']
})
export class TableComponent {
  @ViewChild('mytable') tableRef: ElementRef<HTMLTableElement>
  @ViewChild('searchTable') tableSearchRef: ElementRef;
  columns!: Array<string>;
  data!: any; 
  searchLocalValue: string;
  showSearchBar = false;
  headerCloumns: Array<string>;
  @Input()
  tableOptions: TableInterface;
  ngOnInit() {
    // this.tableRef.nativeElement.childNodes
    // console.log("tableoptionssss=", this.tableOptions);
  }
  ngOnChanges(){
    this.columns = this.tableOptions.columns.map((headerData) => headerData.field);
    this.headerCloumns = this.tableOptions.columns.map((headerColumns) => headerColumns.header);
    this.data = this.tableOptions.data;
  }

  checkValidDate(date){
    const formats = [
      moment.ISO_8601,
      "MM/DD/YYYY  :)  HH*mm*ss"
    ];
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{0,3}/.test(date)) return false;
    if(new Date(date).getFullYear() > 1970) {
      return moment(date, formats, true).isValid();
    }
    return false;
  }

  handleSearchClick(){
    console.log("not implemented") //todo
  }
}
