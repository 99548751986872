import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { Util } from '../../../../utility/util';
import { PcaContractsService } from '../../services/pca-contracts.service';
import { PcaImmunization,  PcaContract } from '../../../../models/pcaclinic';

@Component({
  selector: 'app-pca-location',
  templateUrl: './pca-location.component.html',
  styleUrls: ['./pca-location.component.css']
})
export class PcaLocationComponent implements OnInit {
  @Input('group')
  public locationForm: FormGroup;
  @Input('eventNumber')
  public eventNumber:number;
  public addedImmunization: PcaImmunization;
  public contractData:PcaContract  = new PcaContract() ;
  defaultStartTime: Date;
  defaultEndTime:Date;
  defaultDate: Date = new Date();
  maxDateValue: Date = new Date();
  minDateValue: Date = new Date();
  invalidDates:Date[] = [];
  serviceLabel: string = "";
  @Input('outReachProgramType')
  outReachProgramType: number;
  constructor(private utility: Util, private spinner: NgxSpinnerService,
    private pcaContractService:PcaContractsService) { }

  ngOnInit() {
    this.spinner.show();
    let today = new Date();
    this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    if(this.outReachProgramType===12) this.maxDateValue = new Date(today.getFullYear() + 1, 12);
    else this.maxDateValue = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate() - 1);
    this.defaultEndTime = this.defaultStartTime = null;//new Date(today.getFullYear(), today.getMonth(), today.getDate(),8,0,0);
    this.contractData = this.pcaContractService.fetchPcaContractAgreementData();
    this.invalidDates.push(new Date(2020,5,27));
    if(this.outReachProgramType !== 11 && this.outReachProgramType !== 12){
      if(this.contractData.immunization.length){
        let serviceLabelList = this.contractData.immunization.map((immunization) => {
          let idx = immunization.immunizationName.indexOf("testing");
          return immunization.immunizationName.substring(0,idx);
        });
        serviceLabelList = serviceLabelList.sort();
        if(serviceLabelList.length > 1){
          let lastEle = serviceLabelList.pop();
          let label =  `${serviceLabelList.join(',')} and ${lastEle}`;
          this.serviceLabel = `${label.replace(" ,", ", ")} testing and screening services and ${label.replace(" ,",", ").replace("HIV", "HIV/AIDS")} counseling to individuals`;
        } else {
          let label =  `${serviceLabelList.join(',')}`;
          this.serviceLabel = `${label.replace(" ,", ", ")} testing and screening services and ${label.replace(" ,",", ").replace("HIV", "HIV/AIDS")} counseling to individuals`;
        }

      }
    }
    if(this.eventNumber>this.contractData.pcaEvenScheduleList.length ){
      this.defaultEndTime = this.defaultStartTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(),8,0,0);
      } 
      else{
        let startTime:Date = new Date(this.contractData.pcaEvenScheduleList[this.eventNumber-1].startTime);
        if(startTime.getHours()<12){
          this.defaultEndTime = this.defaultStartTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(),8,0,0);
        }
        else {
        this.defaultStartTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(),12,0,0);
        }
        let endTime:Date = new Date(this.contractData.pcaEvenScheduleList[this.eventNumber-1].endTime);
        if(endTime.getHours()<12){
        this.defaultEndTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(),8,0,0);
        }
        else{
        this.defaultEndTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(),12,0,0);
        }
        } 
    this.spinner.hide();
    
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  isFieldValid(field: string) {
    if (this.locationForm.get(field) !== null) {
      return !this.locationForm.get(field).valid && this.locationForm.get(field).touched;
    }
  }
  isFormValid(): boolean {
    this.utility.validateAllFormFields(this.locationForm);
    return this.locationForm.valid;
  }
  save(outReachPrgType?: string): boolean {
    if (this.isFormValid()) {
      this.pcaContractService.setTestingLocation(this.locationForm.value);
      return true;
    }
    return false;
  }
  isValidArrayField(fields: string, index: number) {
    let return_value = false;
    Object.keys(this.locationForm.controls).forEach(field => {
      const control = this.locationForm.get(field);
      if (control instanceof FormArray) {
        return_value = !control.controls[index].get(fields).valid && control.controls[index].get(fields).touched;
      }
    });
    return return_value;
  }
}
