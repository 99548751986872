// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row{
    margin-bottom: 5px !important;
}
a:focus {  border:  1px #003366 solid  !important; }
input:focus {  border:  1px #003366 solid  !important;  }
button:focus {  border:  1px #003366 solid  !important;  }
select.form-control:focus {
    border: 1px solid #003366 !important;
    }`, "",{"version":3,"sources":["webpack://./src/app/modules/admin/components/walgreens-scheduler-registration/walgreens-scheduler-registration.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;AACA,WAAW,sCAAsC,EAAE;AACnD,eAAe,sCAAsC,GAAG;AACxD,gBAAgB,sCAAsC,GAAG;AACzD;IACI,oCAAoC;IACpC","sourcesContent":[".row{\r\n    margin-bottom: 5px !important;\r\n}\r\na:focus {  border:  1px #003366 solid  !important; }\r\ninput:focus {  border:  1px #003366 solid  !important;  }\r\nbutton:focus {  border:  1px #003366 solid  !important;  }\r\nselect.form-control:focus {\r\n    border: 1px solid #003366 !important;\r\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
