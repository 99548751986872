import { universalContractReleaseDate2022,universalContractReleaseDate2023, universalContractReleaseDate } from '../JSON/UniversalTandC';
export function  getContractComponentName (releaseDate: Date, componentToRender:string):string
{ 
  ///app-agreement-2024 need to add
    if(componentToRender.toLocaleLowerCase()==='app-agreement')
    { 
      if(releaseDate== undefined || releaseDate==null)
     {
      return 'app-agreement-2024';

     }
     else
     {

      let  contractdateApproved:Date =  releaseDate;
      let uniContractReleaseDate:Date = new Date (universalContractReleaseDate);
      let uniContractReleaseDate2022:Date = new Date(universalContractReleaseDate2022);
      let uniContractReleaseDate2023:Date = new Date(universalContractReleaseDate2023);
      if(!contractdateApproved || (contractdateApproved<uniContractReleaseDate2022))
      {
        return 'app-agreement';
      }
     else if(releaseDate < new Date('05/02/2023'))
      {
        return 'app-agreement';
      }
      else if((contractdateApproved > uniContractReleaseDate2022) && (contractdateApproved < uniContractReleaseDate ))
      {
        return 'app-agreement-2022'; 
      }
      else if((contractdateApproved > uniContractReleaseDate2023) && (contractdateApproved < uniContractReleaseDate ))
        {
          return 'app-agreement-2023'; 
        }
      else if(contractdateApproved > uniContractReleaseDate)
      { 
        return 'app-agreement-2024';
      } 
      else 
      { 
        return 'app-agreement-2024';
      } 
     }
     
    }
   else if(componentToRender.toLocaleLowerCase()==='app-approveagreement')
    { 

      if(releaseDate== undefined || releaseDate==null)
      {
        // return 'app-approveagreement-2023';
        return 'app-approveagreement-2024';
 
      }
      else
      {
        let  contractdateApproved:Date =  releaseDate;
        let uniContractReleaseDate:Date = new Date (universalContractReleaseDate);
        let uniContractReleaseDate2022:Date = new Date(universalContractReleaseDate2022);
        let uniContractReleaseDate2023:Date = new Date(universalContractReleaseDate2023);
        if(!contractdateApproved || (contractdateApproved<uniContractReleaseDate2022))
        {
          return 'app-approveagreement';
        }
        else if((contractdateApproved > uniContractReleaseDate2022) && (contractdateApproved < uniContractReleaseDate ))
        {
          return 'app-approveagreement-2022'; 
        }
        else if((contractdateApproved > uniContractReleaseDate2023) && (contractdateApproved < uniContractReleaseDate ))
        { 
          return 'app-approveagreement-2023';
        } 
        else if(contractdateApproved > uniContractReleaseDate)
          { 
            return 'app-approveagreement-2024';
          } 
        else  
        { 
          return 'app-approveagreement-2024';
        } 


      }

    
    }
    else if(componentToRender.toLocaleLowerCase()==='userauthentication-viewcontract' || componentToRender.toLocaleLowerCase()==='contactlog-viewcontract' || componentToRender.toLocaleLowerCase()==='contractapprove-viewcontract' ||componentToRender.toLocaleLowerCase()==='localclincdetails-viewcontract')
    {
//  debugger;
        let  contractdateApproved:Date =  releaseDate;
        let uniContractReleaseDate:Date = new Date (universalContractReleaseDate);
        let uniContractReleaseDate2022:Date = new Date(universalContractReleaseDate2022);
        let uniContractReleaseDate2023:Date = new Date(universalContractReleaseDate2023);
        if(!contractdateApproved || (contractdateApproved<uniContractReleaseDate2022))
        {
          return '/viewcontract';
        }
        else if((contractdateApproved > uniContractReleaseDate)||(contractdateApproved  > new Date('04/23/2024')))
          { 
            return '/viewcontract-2024-1';
          }  
          else if((contractdateApproved > uniContractReleaseDate2023) && (contractdateApproved < uniContractReleaseDate ))
            {
              return '/viewcontract-2023-1'; 
            }
        else if((contractdateApproved > uniContractReleaseDate2022) && (contractdateApproved < uniContractReleaseDate ))
        {
          return '/viewcontract-2022-1'; 
        }
        
       
        else {
          return '/viewcontract-2024-1';
        } 

    }
    
  

}

