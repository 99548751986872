import { Component,  OnInit } from '@angular/core';
import {  FormGroup,  FormBuilder, Validators } from '@angular/forms';
import * as appSettings from '../../globals';
//import { NGXLogger } from 'ngx-logger';
import { ApplicationSettings } from './../../../common/application-settings';
import { ImmunizationFinanceService } from '../../services/immunizationfinance.service';
import { saveAs as importedSaveAs } from "file-saver";
import { ImmunizationFinance } from '../../../../models/ImmunizationFinance';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-immunizationfinancereport',
  templateUrl: './immunizationfinancereport.component.html',
  styleUrls: ['./immunizationfinancereport.component.css']
})
export class ImmunizationfinancereportComponent implements OnInit {
  immunizationFinanceReportForm: FormGroup;
  immunizationFinanceReportArray: any;
  outreachStartDate = ApplicationSettings.outreachStartDateIP;
  groupIdLaunchDate = ApplicationSettings.groupIdLaunchDate;
  private datasource: any;
ddlSeason: number;
private isCurrentSeason = 1;
fromDate: Date;
  ddlFormat: any;
  toDate: Date;
  maxDate: Date;
  minDate: Date;
private from_date: string;
  private to_date: string;
totalRecords: number;
  loading: boolean;
checked: boolean = false;
  private currentSeason = appSettings.ipSeason;
  filterByDate: boolean=false;
  seasons: any = [];
  canExport:boolean=true;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
showGrid:boolean=false;
  covidOnly: boolean = false;

  constructor(
    private fb: FormBuilder,
    /*private logger: NGXLogger, */private imzFinanceService: ImmunizationFinanceService, private spinner: NgxSpinnerService) {
  }
  ngOnInit() {
    for (let report_year: number = (new Date(this.outreachStartDate)).getFullYear(); report_year >= parseInt(appSettings.reportStartYear); report_year--) {
      if(report_year>2017)
      {
      this.seasons.push({
        "yearName": report_year.toString() + "-" + (report_year + 1).toString(),
        "yearCode": report_year.toString()
      });
    }     
     
    }     
    this.ddlFormat = "0";
    this.filterByDate = false;
    this.ddlSeason = (new Date(this.outreachStartDate)).getFullYear();
    this.setDates();
  this.setFormControls();
 //this.generateReport();
  }
  onFilterChange(eve: any) {
  }
  setFormControls() {
   this.immunizationFinanceReportForm = this.fb.group({
      'fDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'tDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'ddlSeason': [this.ddlSeason],
      'chkFilterByDate': this.filterByDate,
      'ddlFormat': [this.ddlFormat]
    });
    //this.generateReport();
  }
  
  generateReport(): void {
    
    if(!this.showGrid){
      this.spinner.show();
    }else{
      this.loading = true; 
    }
    //this.ddlSeason   =  this.immunizationFinanceReportForm.controls['ddlSeason'].value;
    //this.ddlFormat   =  this.immunizationFinanceReportForm.controls['ddlFormat'].value;
    //this.filterByDate=  this.immunizationFinanceReportForm.controls['chkFilterByDate'].value;
    //this.fromDate    =  this.immunizationFinanceReportForm.controls['fDate'].value;
    //this.toDate      =  this.immunizationFinanceReportForm.controls['tDate'].value;
    let data = this.immunizationFinanceReportForm.value;

    let d1 = data.fDate;
    let d2 = data.tDate;
    this.from_date = (d1.getMonth() + 1) + "/" + (d1.getDate()) + "/" + (d1.getFullYear());
    this.to_date = (d2.getMonth() + 1) + "/" + (d2.getDate()) + "/" + (d2.getFullYear());

if (this.ddlSeason != this.currentSeason) {
      this.isCurrentSeason = 0;
    }else{
      this.isCurrentSeason = 1;
    }
    
    this.filterByDate=this.immunizationFinanceReportForm.get('chkFilterByDate').value;
    let immz_finance = new ImmunizationFinance(this.from_date, this.to_date, this.isCurrentSeason, this.ddlSeason, this.filterByDate, this.covidOnly, "ImmunizationFinance", "xlsxv");
    this.imzFinanceService.getImmunizationFinanceReportData(immz_finance).subscribe((resultArray: any) => {
      this.datasource = resultArray
      this.totalRecords = this.datasource.length;
      //this.logger.log("totalRecords:" + this.totalRecords);
      this.immunizationFinanceReportArray = this.datasource;
      setTimeout(() => {
this.loading = false;
        this.spinner.hide();
        this.showGrid = true;
        this.canExport=true;
      }, 1000)
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
      this.immunizationFinanceReportArray = [];
      this.totalRecords = 0;
    });
}

  generateReportFormat(): void {
    
    this.loading=true;
    let data = this.immunizationFinanceReportForm.value;
    this.filterByDate=this.immunizationFinanceReportForm.get('chkFilterByDate').value;
    let immz_finance = new ImmunizationFinance(this.from_date, this.to_date, this.isCurrentSeason, this.ddlSeason, this.filterByDate,this.covidOnly, "ImmunizationFinance", "xlsxv");
    this.imzFinanceService.exportReportData(immz_finance).subscribe((res:any) => {
      importedSaveAs(res.body, "ImmunizationFinance.xlsx");
      this.loading=false;
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
  setDates() {
    if(this.immunizationFinanceReportForm != undefined){
      this.ddlSeason=this.immunizationFinanceReportForm.controls['ddlSeason'].value;
    } 
    let tempMinDate: Date;
    let tempMaxDate: Date;
    //this.ddlSeason   =  this.immunizationFinanceReportForm.controls['ddlSeason'].value;
   // this.ddlFormat   =  this.immunizationFinanceReportForm.controls['ddlFormat'].value;
    tempMinDate = new Date(ApplicationSettings.getIMmunizationStartDate(this.ddlSeason));
    tempMaxDate = new Date(ApplicationSettings.getImmunizationEndDate(this.ddlSeason));
    this.minDate = new Date(tempMinDate);
    this.maxDate = new Date(tempMaxDate);
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    //this.immunizationFinanceReportForm.controls['fDate'].setValue(this.fromDate);
   // this.immunizationFinanceReportForm.controls['tDate'].setValue(this.toDate);
    this.canExport=false;
    if(this.immunizationFinanceReportForm != undefined){
      this.immunizationFinanceReportForm.controls['fDate'].setValue(this.fromDate);
      this.immunizationFinanceReportForm.controls['tDate'].setValue(this.toDate);
      }

  }
  setDates1() {
    let tempMinDate: Date;
    let tempMaxDate: Date;
    tempMinDate = new Date(ApplicationSettings.getIMmunizationStartDate(this.ddlSeason));
    tempMaxDate = new Date(ApplicationSettings.getImmunizationEndDate(this.ddlSeason));
    this.minDate = new Date(tempMinDate);
    this.maxDate = new Date(tempMaxDate)
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    this.canExport=false;

  }
  reset(): void {
    //this.loading = true;
    this.ddlSeason = this.currentSeason;
    this.filterByDate=false;
    this.setDates();
    this.isCurrentSeason=1;
    this.ddlFormat=0;
    this.showGrid = false;
    this.covidOnly = false;
     this.setFormControls();  
    //this.generateReport();
  }
  canExportOnchange(){
    this.canExport=false;
    }
  validateExportType(){
    //this.ddlFormat   =  this.immunizationFinanceReportForm.controls['ddlFormat'].value;
    if(this.ddlFormat=="0" || !this.canExport)
    return true;
    else
     return false;
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
