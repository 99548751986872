
import {of as observableOf,  BehaviorSubject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IPTandCData } from '../../../JSON/IPTandC';
import { Util } from '../../../utility/util';
import { Clinic } from '../../../models/contract';
import { thankyouMessageData } from '../../../JSON/thankyouMessage';
import { Router } from '@angular/router';
import { UniversalTandCData } from '../../../JSON/UniversalTandC';
import { catchError,retry, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UniversalTandCData2022 } from '../../../../app/JSON/UniversalTandC-2022';
import { UniversalTandCData2023 } from '../../../../app/JSON/UniversalTandC-2023';
import {UniversalTandCData2024} from '../../../../app/JSON/UniversalTandC-2024';

@Injectable()
export class ContractapproveService {
  private messageSource = new BehaviorSubject<string>("default message");
  currentMessage = this.messageSource.asObservable();
  contract: any;
  private formData: any;
  private contractData: any;
  private client_info: any;
  private legal_info: any;
  cancel_check:boolean = false;
  showViewCancelBtn:boolean = false;
  constructor(private _http: HttpClient, private router:Router) {
    // Observable.of(immunizationsArray).subscribe(res => {
    // this.contract = res;
    //   this.updateContractDetails(this.contract);
    // });
  }

  getContractAgreementApproveData(clinic_agreement_pk: number, user_type?: string) {
    let get_contract_param = clinic_agreement_pk + ((user_type != undefined) ? "/" + user_type : "");
    let url = environment.API_URL + environment.RESOURCES.GET_CONTRACT_AGREEMENT + "/" + get_contract_param;
    return this._http.get(url,Util.getRequestHeadersV1()).pipe(retry(4),
      tap(rsp=>{this.formData=rsp;}),
      catchError(error => Util.handleErrorV2(error)));
    //.map((response: Response) => this.formData = response.json());
  }
 
  languageChange(year?:number,isCOVIDVaccineAvailable:boolean = false,loadUniversalTAndC:boolean=true,loadoldUTandC?:boolean) {    

    if(loadUniversalTAndC && year==undefined){
      // return (UniversalTandCData2023);
      return (UniversalTandCData2024);
  }
   else if(loadUniversalTAndC && year==0){
      return (UniversalTandCData);
  }
  else if(loadUniversalTAndC && year==2022)
  {
    return (UniversalTandCData2022);
  }
  else if(year==2022)
  {
    return (UniversalTandCData2022);
  }
  else if(loadUniversalTAndC && year==2023)
  {
    return (UniversalTandCData2023);
  }
  else if(year==2023)
  {
    return (UniversalTandCData2023);
  }
  else if(loadUniversalTAndC && year==2024)
    {
      return (UniversalTandCData2024);
    }
    else if(year==2024)
      {
        return (UniversalTandCData2024);
      }
    else if(year==2023)
    {
      return (UniversalTandCData2024);
    }
  else{
    if(isCOVIDVaccineAvailable){
      return COVIDTandCData;
    }
    else {
    return IPTandCData;
    }
  }
}
  changeMessage(message: string) {
    this.messageSource.next(message)
  }
  getSelectedClientLegalInfo(): any {
    return this.client_info;
  }
  getSelectedLegalInfo(): any {
    return this.legal_info;
  }

  setSelectedClientLegalInfo(data: any) {
    this.client_info = data;
  }
  setSelectedLegalInfo(data: any) {
    this.legal_info = data;
  }

  private contractDetails: BehaviorSubject<any> = new BehaviorSubject("");
  currentContract = this.contractDetails.asObservable();

  updateContractDetails(contract: any) {
    this.contractDetails.next(contract);
  }

  saveAgreementData(store_id, clinic_type, clinic_agreement) {
    let url = environment.API_URL + environment.RESOURCES.UPDATE_CONTRACT_AGREEMENT + "/" + store_id + "/" + clinic_type;
    return this._http.post(url, clinic_agreement,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}


checkIfAnyImmunizationsHasCorpInvoiceAndVoucherNeeded(location: Clinic): boolean {
  let added_immunizations = this.formData.clinicImmunizationList;
  try {
      let hasCorpInvoiceNdVchrNd: Boolean = false;
      var fluType = 'flu';
      added_immunizations.forEach(rec => {
          if (Number(rec.paymentTypeId) == 6 && (rec.sendInvoiceTo === '1' || Number(rec.sendInvoiceTo) === 1 ) &&
           ( rec.isVoucherNeeded === '1' || Number(rec.isVoucherNeeded) === 1)) {
              hasCorpInvoiceNdVchrNd = true;
          }
      });
      if (hasCorpInvoiceNdVchrNd == true)
          return true;
  } catch (e) {
      if (e === true)
          return true;
  }
  return false;
}

checkIfFluImmForCorpInvoiceSelected() {
  let added_immunizations = this.formData.clinicImmunizationList;
  try {
    added_immunizations.forEach(rec => {
      if (Number(rec.paymentTypeId) == 6 && (rec.sendInvoiceTo === '1' || Number(rec.sendInvoiceTo) === 1) &&
      ( rec.isVoucherNeeded === '1' || Number(rec.isVoucherNeeded) === 1) &&
       rec.immunizationName.toLowerCase().search('flu') !== -1) {
        throw true;
      }
    });
  } catch (e) {
    if (e === true)
      return true;
  }
  return false;
}

checkIfNonFluImmForCorpInvoiceSelected() {
  let added_immunizations = this.formData.clinicImmunizationList;
  try {
    added_immunizations.forEach(rec => {
      if (Number(rec.paymentTypeId) == 6 && (rec.sendInvoiceTo === '1' || Number(rec.sendInvoiceTo) === 1) &&
      ( rec.isVoucherNeeded === '1' || Number(rec.isVoucherNeeded) === 1) &&
       rec.immunizationName.toLowerCase().search('flu') == -1) {
        throw true;
      }
    });
  } catch (e) {
    if (e === true)
      return true;
  }
  return false;
}
checkIfCovidImmForCorpInvoiceSelected() {
  let added_immunizations = this.formData.clinicImmunizationList;
  try {
      added_immunizations.forEach(rec => {
          if (rec.paymentTypeId == 6 
              && rec.immunizationName.toLowerCase().search('flu') == -1 && rec.immunizationName.toLowerCase().search('covid') !== -1) {
              throw true;
              // && (rec.sendInvoiceTo === '1' || Number(rec.sendInvoiceTo) === 1) &&
              // (rec.isVoucherNeeded === '1' || Number(rec.isVoucherNeeded) === 1)
          }
          // else if(rec.paymentTypeId==6 && rec.sendInvoiceTo===true && rec.immunizationName.toLowerCase().search('flu') == -1){
          //     this.hasNonFluImmSelected = true;
          // }
      });
  } catch (e) {
      if (e === true)
          return true;
  }
  return false;
}
checkIfNonFluImmIsSelected( ):boolean {
  let added_immunizations = this.formData.clinicImmunizationList;
  try {
      added_immunizations.forEach(rec => {
          if ( rec.immunizationName.toLowerCase().search('flu') == -1) {
              throw true;
          }});
  } catch (e) {
      if (e === true)
          return true;
  }
  return false;
}

getThankyouMessage() {
  return thankyouMessageData;
}
setShowViewCancelBtn(value:boolean){
  this.showViewCancelBtn = value;
}
getShowViewCancelBtn(){
  return this.showViewCancelBtn;
}
rejectClinicCancellationByClient(clinic_pk) {    
  var url = environment.API_URL + environment.RESOURCES.REJECT_CLINIC_CANCELLATION_BY_CLIENT+"/"+clinic_pk;
  return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}
approveClinicCancellationByClient(clinic_pk) {    
  var url = environment.API_URL + environment.RESOURCES.APPROVE_CLINIC_CANCELLATION_BY_CLIENT+"/"+clinic_pk;
  return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}
}
function COVIDTandCData(COVIDTandCData: any): Observable<any> {
  throw new Error('Function not implemented.');
}

