
export class UserAccessLog {
    districtId: string;
    season: string = null;
    reportName: string = "";
    outputType: string = "";
    constructor(district_id:string, season: string,report_name:string,output_type: string) {
         this.districtId = district_id;
        this.season = season;
        this.reportName = report_name;
        this.outputType = output_type;
    }
}