import { Component, OnInit, ViewChild } from '@angular/core';
import {  FormGroup,  FormBuilder, Validators } from '@angular/forms';
import { DistrictCompliance } from '../../../../models/DistrictCompliance';
import { DistrictcomplianceService } from '../../services/districtcompliance.service'
import { ApplicationSettings } from '../../../common/application-settings';
import { saveAs as importedSaveAs } from "file-saver";
import { Paginator } from '../../../../../../node_modules/primeng/paginator';
import { ErrorMessages } from "../../../../config-files/error-messages";

@Component({
  selector: 'app-districtcompliancereport',
  templateUrl: './districtcompliancereport.component.html',
  styleUrls: ['./districtcompliancereport.component.css']
})
export class DistrictCompliancereportComponent implements OnInit {
  ddlFormat: any;
  districtComplianceReportForm:FormGroup;
  districtComplianceReportArray: any;
  districtComplianceReportSource: any;
  ddlYear: any;
  ddlQuarter: any; 
  ddlDistrict1: any=133;
  totalRecords: number = 0;
  rowGroupMetadata: any;
  private outreachEffort = ApplicationSettings.outreachEffortSO;
  yearStartDate: any;
  districts: any;
  years: any;
  quarters: any;
  canExport: any;
  loading: any;
  canShowNoBusinessesText: boolean = false;
  private outreachStartDate:any;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  showGrid:boolean=false;
  @ViewChild('dcPager') dcPager: Paginator;
  constructor(private fb: FormBuilder,private district_compliance_report: DistrictcomplianceService) { }

  ngOnInit() {   
    this.outreachStartDate = ApplicationSettings.getSRStartDate(new Date().getFullYear());
   
    this.ddlFormat = "xlsxv";
    this.years = ApplicationSettings.getOutreachReportYears();
    this.quarters = ApplicationSettings.getReportYearQuarters();
    if (this.years.length > 0) {
      this.ddlYear = new Date(this.outreachStartDate).getFullYear();
    }
    if (this.quarters.length > 0) {
      this.ddlQuarter = ApplicationSettings.getSRCurrentQuarter();
    }
    var is_current_season = ((parseInt(this.ddlYear)) !== (new Date(this.outreachStartDate).getFullYear() + 1) ? 0 : 1);
    this.yearStartDate =this.outreachStartDate;// ((is_current_season) ? "09/01/" : "09/01/") + this.ddlYear;
  
    this.setFormControls();
    this.district_compliance_report.getDistrictComplianceReportDistrictsData().subscribe((result: any) => {
      this.districts = result;
      if (this.districts.length > 0) {
        this.ddlDistrict1 = 133;//this.districts[0].districtID;
        
        //this.generateReport();
      }
    }
    );
      
  }

  setFormControls() {
   
    this.districtComplianceReportForm = this.fb.group({
      'ddlDistrict':[this.ddlDistrict1,null],
      'ddlYear':[this.ddlYear,null],
      'ddlQuarter':[this.ddlQuarter,null],
             'ddlFormat': [this.ddlFormat]
      });
     //this.generateReport();
   }
  generateReport(): void {
    this.loading = true;
    //var is_current_season = ((parseInt(this.ddlYear)) !== (new Date(this.outreachStartDate).getFullYear() + 1) ? 0 : 1);
    this.yearStartDate = ApplicationSettings.getSRStartDate(this.districtComplianceReportForm.controls["ddlYear"].value); //((is_current_season) ? "09/01/" : "09/01/") + (this.ddlYear);
    this.dcPager.changePage(0);
    let data= this.districtComplianceReportForm.value;
    let dc: DistrictCompliance = new DistrictCompliance(data.ddlDistrict, this.outreachEffort, this.yearStartDate, data.ddlQuarter, "", "");
    this.district_compliance_report.getDistrictComplianceReportData(dc).subscribe((resultArray: any) => {
      if (resultArray.length == 0) {
        this.canShowNoBusinessesText = true;
      } else {
        this.canShowNoBusinessesText = false;
      }
      this.districtComplianceReportSource = resultArray;
      this.totalRecords = this.districtComplianceReportSource.length;
      var temp = this.districtComplianceReportSource.slice(0, 20);
      this.showGrid = true;
      this.updateRowGroupMetaData(temp);
      this.districtComplianceReportArray = temp;
      setTimeout(() => {
        this.loading = false;
        this.canExport = true;
      }, 1000)
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.districtComplianceReportSource = [];
      this.totalRecords = 0;
      this.showGrid = false;
    });
    this.ddlFormat = "xlsxv";
  }
  generateReportFormat() {
    this.loading = true;
    //var is_current_season = ((parseInt(this.ddlYear)) !== (parseInt(this.outreachStartDate.getFullYear()) +1) ? 0 : 1);
    this.yearStartDate = ApplicationSettings.getSRStartDate(this.districtComplianceReportForm.controls["ddlYear"].value);  //((is_current_season) ? "09/01/" : "09/01/") + (this.ddlYear);
    this.districtComplianceReportForm.updateValueAndValidity();
    let data= this.districtComplianceReportForm.value;
    let dc: DistrictCompliance = new DistrictCompliance(data.ddlDistrict, this.outreachEffort, this.yearStartDate, data.ddlQuarter, "xlsxv", "DistrictCompliance");
    this.district_compliance_report.exportReportData(dc).subscribe((res:any) => {
      importedSaveAs(res.body, "DistrictCompliance.xlsx");
      this.loading = false;
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
    this.ddlFormat = "xlsxv";
  }
  canExportOnchange() {
    
    this.canExport = false;
  }
  validateExportType() {
    if (this.ddlFormat == "0" || !this.canExport)
      return true;
    else
      return false;
  }

  updateRowGroupMetaData(datasource: any) {
    this.rowGroupMetadata = {};
    if (datasource) {
      for (let i = 0; i < datasource.length; i++) {
        let rowData = datasource[i];
        let compliant_non_compliant = rowData.Compliant_Non_Compliant;
        let store_count = rowData.storeCount;
        if (i == 0) {
          this.rowGroupMetadata[compliant_non_compliant + "_" + store_count] = { index: 0, size: 1, count: ((store_count > 0) ? store_count : 0) };
        }
        else {
          let previousRowData = datasource[i - 1];
          let previousRowGroup = previousRowData.Compliant_Non_Compliant + "_" + previousRowData.storeCount;
          if (compliant_non_compliant + "_" + store_count === previousRowGroup) {
            this.rowGroupMetadata[compliant_non_compliant + "_" + store_count].size++;
            if (store_count > 0) {
              this.rowGroupMetadata[compliant_non_compliant + "_" + store_count].count += ((store_count > 0) ? store_count : 0);
            }
          }
          else {
            this.rowGroupMetadata[compliant_non_compliant + "_" + store_count] = { index: i, size: 1, count: ((store_count > 0) ? store_count : 0) };
          }
        }
      }
    }
  }

  paginate(event) {
    this.rowGroupMetadata = {};
    this.districtComplianceReportArray = [];
    this.loading = true;
    var curr_page_rec_count = event.first + event.rows;
    var page_to_get = curr_page_rec_count / 50;
    page_to_get = Math.ceil(page_to_get);
    var start_number = ((event.first > 40) ? (event.first - (50 * (page_to_get - 1))) : event.first);
    if (this.districtComplianceReportSource) {
      var temp = this.districtComplianceReportSource.slice(start_number, (start_number + 20));
      this.updateRowGroupMetaData(temp);
      this.districtComplianceReportArray = temp;
      this.totalRecords = this.districtComplianceReportSource.length;
    }
    this.loading = false;
  }
   //Shows the message dialog.
   showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
