import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment.prod';
import { Util } from '../../../utility/util';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class EncryptLinkService {
  constructor(private http:HttpClient) {   }
  
   getEncryptLink(user_pk, email){
    let url = environment.API_URL + environment.RESOURCES.SEND_USER_ACCESS_LINK +"/"+email +"/"+user_pk; 
    return this.http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

}
