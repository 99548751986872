import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionDetails } from '../../../utility/session';
import { catchError } from 'rxjs/operators';

@Injectable()
export class DatastatsreportingService {
  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL;
  constructor(private http: HttpClient) { }

  public exportData(report_name:string) {
    let api_url ="";
    switch (report_name) {
      case "DataStatistics":
        api_url = environment.RESOURCES.GET_STATS_DATA + "/" + report_name
        break;
      case "OpportunityCountsBySIC":
        api_url = environment.RESOURCES.GET_OPPORTUNITIES_BY_SIC + "/" + report_name
        break;
      case "Assigned30PlusOpportunities":
        api_url = environment.RESOURCES.GET_ASSIGNED_30PLUS_OPPORTUNITIES + "/" + report_name
        break;
      case "StoreCountByAssignedOpportunities":
        api_url = environment.RESOURCES.GET_STORE_COUNT_BY_ASSIGNED_OPPORTUNITIES + "/" + report_name
        break;
      case "StoreCountByAssignedRange":
        api_url = environment.RESOURCES.GET_STORE_COUNT_BY_ASSIGNED_RANGE + "/" + report_name
        break;
      case "IPClinicsBySIC":
        api_url = environment.RESOURCES.GET_IP_CLINICS_BY_SIC + "/" + report_name
        break;
      case "SREventsBySIC":
        api_url = environment.RESOURCES.GET_SR_EVENTS_BY_SIC + "/" + report_name
        break;
      default:
        break;
    }
    if (api_url != "") {
      let url = this._url + api_url
      // let options: RequestOptions = Util.getRequestHeaders();
      // options.responseType = ResponseContentType.Blob;
      let user_data= SessionDetails.GetUserInfo()
      let req_headers = new HttpHeaders()
      .set('Content-Type','application/json')
      .set('Authorization','Basic '+ SessionDetails.getE(SessionDetails.absJ,user_data.userName + ':'+ user_data.password));
      return this.http.get(url, {headers:req_headers,observe:'response' as 'body',responseType:'blob' as 'json'})
      .pipe(catchError(Util.handleErrorV2));
      // .map(res => {
      //   var contentDisposition = res.headers.get('Content-Disposition');
      //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
      //   this.filename = result;
      //   return res.blob();
      // });
    }
  }
}
