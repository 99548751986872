import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationSettings } from '../../../common/application-settings';
import { ScheduledClinicStatus } from '../../../../models/ScheduledClinicStatus';
import { ScheduledClinicstatusService } from '../../services/scheduledclinicstatus.service';
import * as appSettings from '../../globals';
import { saveAs as importedSaveAs } from "file-saver";
import { SessionDetails } from '../../../../utility/session';
import { Paginator } from '../../../../../../node_modules/primeng/paginator';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { ErrorMessages } from "../../../../config-files/error-messages";

@Component({
  selector: 'app-scheduledclinicstatus',
  templateUrl: './scheduledclinicstatus.component.html',
  styleUrls: ['./scheduledclinicstatus.component.css']
})
export class ScheduledclinicstatusComponent implements OnInit {
  scheduledClinicStatusArray: any;
  ddlFormat: any;
  storeId: number;
  contacted: number; 
  ddlStatus: number;
  outreachStartDate: any;
  outreachEndDate: any;
  ddlContacted: any;
  fromDate: any;
  toDate: any;
  minDate: any;
  maxDate: any;
  from_date: any;
  isDisableDates:boolean=false;
  to_date: any;
  totalRecords: any;
  scheduledClinicStatusReportArray: any;
  private scheduledClinicStatusDataSource: any;
  loading: any;
  canExport: boolean = true;;
  statusArray: any;
  private outreachEffort = appSettings.outreachEffortIP;
  rowGroupMetadata: any;
  canShowNoBusinessesText: boolean = false;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  @ViewChild('scPager') scPager: Paginator;
  constructor( private scheduledclinicStatusreport: ScheduledClinicstatusService, private message_service: MessageServiceService) {
    this.message_service.getStoreID().subscribe(x => {
      this.storeId = x.text;
      this.loading = true;
      this.generateReport();
    });
  }
  ngOnInit() {
    let date = new Date();
    date.setDate(date.getDate() + 3);
    this.storeId = SessionDetails.GetStoreId();
    this.ddlFormat = "xlsxv";
    this.ddlContacted = "3";
    this.ddlStatus = -1;
    this.isDisableDates=true;
    this.outreachStartDate = ApplicationSettings.getIMmunizationStartDate(new Date().getFullYear());
    this.outreachEndDate = ApplicationSettings.getImmunizationEndDate(new Date().getFullYear());
    this.setDates();
    this.bindDropdown(-1);
    setTimeout(() => {
        this.generateReport();
      }, 1000)
    
  }
  bindDropdown(id:any) {
    this.scheduledclinicStatusreport.getScheduledClinicStatusOutreachStatus().subscribe((resultArray: any) => {
      var temp : any;
      if(id==1)
      {
        this.isDisableDates=false;
        temp = resultArray.filter(x => x.category == 'AC'  && x.pk!=0); 
        this.ddlStatus=-1;
      }
      else if(id==0)
      {
        this.isDisableDates=true;
        temp = resultArray.filter(x => x.category == 'AC' && x.pk==0); 
        this.ddlStatus=-1;
      }
      else
      {
        this.isDisableDates=true;
       temp = resultArray.filter(x => x.category == 'AC'); 
       this.ddlStatus=-1;
      } 
      this.statusArray = temp;
      setTimeout(() => {
        this.loading = false;
        this.canExport = true;
      }, 1000)
    }
    );
  }
  setDates() {
    this.minDate = this.fromDate = new Date(this.outreachStartDate);
    this.maxDate = this.toDate = new Date(this.outreachEndDate);
    this.toDate.setMonth(this.toDate.getMonth());
    this.canExport = false;
    //this.toDate.setDate(this.toDate.getDate()+1);
  }
  setCalendarDates() {
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    this.canExport = false;
  }
  reset() {
    this.ddlContacted = "3";
    this.ddlFormat = "xlsxv";
    this.ddlStatus = -1;
    this.setDates();
    this.generateReport();
  }
  generateReport() {
    this.loading = true;
    this.scPager.changePage(0);
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    let scheduled_clinic_status = new ScheduledClinicStatus(this.storeId, this.outreachEffort, this.ddlContacted, this.from_date, this.to_date, this.ddlStatus);
    this.scheduledclinicStatusreport.getScheduledClinicStatusDetails(scheduled_clinic_status).subscribe((resultArray: any) => {
      if (resultArray.length == 0) {
        this.canShowNoBusinessesText = true;
      } else {
        this.canShowNoBusinessesText = false;
      }
      this.totalRecords = resultArray.length;
      //this.updateRowGroupMetaData(resultArray);
      this.scheduledClinicStatusDataSource = resultArray;
      var temp = this.scheduledClinicStatusDataSource.slice(0, 10);
      this.updateRowGroupMetaData(temp);
      this.scheduledClinicStatusReportArray = temp;
      setTimeout(() => {
        this.loading = false;
        this.canExport = true;
      }, 1000)
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.scheduledClinicStatusReportArray = [];
      this.totalRecords = 0;
    });
	
    this.canExport = true;
  }
  generateReportFormat(): void {
    this.loading = true;
    let scheduled_clinics_status = new ScheduledClinicStatus(this.storeId,
      this.outreachEffort,
      this.ddlContacted, this.fromDate, this.toDate, this.ddlStatus, "ScheduledClinicStatus", this.ddlFormat);
    this.scheduledclinicStatusreport.exportReportData(scheduled_clinics_status).subscribe((res:any) => {
      importedSaveAs(res.body, "ScheduledClinicStatus.xlsx");
      this.loading = false;
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
	
  }
  updateRowGroupMetaData(datasource: any) {
    this.rowGroupMetadata = {};
    if (datasource) {
      for (let i = 0; i < datasource.length; i++) {
        let rowData = datasource[i];
        let storeid = rowData.storeid;
        let bName = rowData.businessName;
        let bAddr = rowData.businessAddress;
        let store_address = rowData.storeAddress;
        if (i == 0) {
          this.rowGroupMetadata[storeid + "_" + store_address] = { index: 0, size: 1 };
          this.rowGroupMetadata[storeid + "_" + store_address][bName + "_" + bAddr] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = datasource[i - 1];
          let previousRowGroup = previousRowData.storeid + "_" + previousRowData.storeAddress;
          let previousInnerRowGroup = previousRowData.businessName + "_" + previousRowData.businessAddress;
          if (storeid + "_" + store_address === previousRowGroup) {
            this.rowGroupMetadata[storeid + "_" + store_address].size++;
            if (bName + "_" + bAddr === previousInnerRowGroup) {
              this.rowGroupMetadata[storeid + "_" + store_address][bName + "_" + bAddr].size++;
            } else {
              this.rowGroupMetadata[storeid + "_" + store_address][bName + "_" + bAddr] = { index: i, size: 1 };
            }
          }
          else {
            this.rowGroupMetadata[storeid + "_" + store_address] = { index: i, size: 1 };
            this.rowGroupMetadata[storeid + "_" + store_address][bName + "_" + bAddr] = { index: i, size: 1 };
          }
        }
      }
    }
  }

  paginate(event) {
    this.rowGroupMetadata = {};
    this.scheduledClinicStatusReportArray = [];
    this.loading = true;
    var curr_page_rec_count = event.first + event.rows;
    var page_to_get = curr_page_rec_count / 50;
    page_to_get = Math.ceil(page_to_get);
    var start_number = ((event.first > 40) ? (event.first - (50 * (page_to_get - 1))) : event.first);
    if (this.scheduledClinicStatusDataSource) {
      var temp = this.scheduledClinicStatusDataSource.slice(start_number, (start_number + 10));
      this.updateRowGroupMetaData(temp);
      this.scheduledClinicStatusReportArray = temp;
      this.totalRecords = this.scheduledClinicStatusDataSource.length;
    }
    this.loading = false;
    //console.log(this.rowGroupMetadata);
  }
  canExportOnchange() {
    this.bindDropdown(this.ddlContacted);
    this.canExport = false;
  }
  
    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}
