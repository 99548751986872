import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject ,  Observable } from 'rxjs';

@Injectable()
export class MessageServiceService {
    private subject = new Subject<any>();
    private subjectHint = new Subject<any>();
    private subjectProfile = new Subject<any>();
    private turnOffOnHintsFromFooter = new Subject<any>();
    private turnOffOnHintsFromComponent = new Subject<any>();
    private stepValue = new Subject<any>();
    private canRouteChangeConfirmDialog=new Subject<any>();
    private canRouteChange = new Subject<boolean>();
    private dashBoard = new Subject<any>();
    private reportTitle = new Subject<any>();
    private isNavigationClicked = new BehaviorSubject<boolean>(false);
    private showHintsOnButtonClick = new Subject<boolean>();
    private hintsType = new Subject<any>();
    private showHintsOnToggleChange = new Subject<boolean>();
    private showTabbedHints = new Subject<boolean>();

    sendStoreId(message: string) {
        this.subject.next({ text: message});
    }

    clearStoreId() {
        this.subject.next({text: ""});
    }

    getStoreID(): Observable<any> {
        return this.subject.asObservable();
    }
    getStepValue(): Observable<any> {
        return this.stepValue.asObservable();
    }

    setStepValue(step_value: number) {
        this.stepValue.next({ text: step_value });
    }
    clearStepValue() {
        this.stepValue.next({text: ""});
    }
    sendDashboard(dash_board: string) {
        this.dashBoard.next({ text: dash_board});
    }

    clearDashboard() {
        this.dashBoard.next({text: ""});
    }

    getDashboard(): Observable<any> {
        return this.dashBoard.asObservable();
    }

    sendStoreProfile(store_id: string) {
        this.subject.next({ text: store_id});
    }

    clearStoreProfile() {
        this.subject.next({text: ""});
    }

    getStoreProfile(): Observable<any> {
        return this.subject.asObservable();
    }
      
    sendProfileChange(message: string) {
        this.subjectProfile.next({ text: message });
    }

    getProfileChange(): Observable<any> {
        return this.subjectProfile.asObservable();
    }

    setTurnOnOffLocalHintsFromFooter(turn_on_off: boolean) {
        this.turnOffOnHintsFromFooter.next({ text: turn_on_off });
    }

    getTurnOnOffLocalHintsFromFooter(): Observable<any> {
        return this.turnOffOnHintsFromFooter;
    }

    setTurnOnOffLocalHintsFromComponent(turn_on_off: boolean) {
        this.turnOffOnHintsFromComponent.next({ text: turn_on_off });
    }

    getTurnOnOffLocalHintsFromComponent(): Observable<any> {
        return this.turnOffOnHintsFromComponent;
    }

    setCanShowRouteChangeConfirmation(can_show:boolean){
        this.canRouteChangeConfirmDialog.next({text:can_show})
    }
    getCanShowRouteChangeConfirmation(): Observable<any> {
       return this.canRouteChangeConfirmDialog;
    }

    setCanRouteChange(can_change:boolean){
        this.canRouteChange.next(can_change)
    }
    getCanRouteChange(): Observable<boolean> {
       return this.canRouteChange;
    }

    setReportTitle(title: string) {
        this.reportTitle.next(title);
    }
    
    getReportTitle(): Observable<any> {
        return this.reportTitle.asObservable();
     }

     setNavigationClicked(is_navigation_clicked:boolean){
         this.isNavigationClicked.next(is_navigation_clicked);
     }
     
     getNavigationClicked(): Observable<boolean> {
        return this.isNavigationClicked;
     }
 
     setShowHintsOnButtonClick(can_show: boolean) {
        this.showHintsOnButtonClick.next(can_show);
    }
    
    getShowHintsOnButtonClick(): Observable<any> {
        return this.showHintsOnButtonClick.asObservable();
     }
     
    setHintsType(title: string) {
        this.hintsType.next(title);
    }
    
    getHintsType(): Observable<any> {
        return this.hintsType.asObservable();
     }

     setShowHintsOnToggleChange(can_show: boolean) {
        // Old Line was like this  this.showHintsOnToggleChange.next(can_show);
        this.turnOffOnHintsFromFooter.next({ text: can_show });
    }
    
    getShowHintsOnToggleChange(): Observable<any> {
        return this.showHintsOnToggleChange.asObservable();
    }

    setShowTabbedHints(can_show: boolean) {
        this.showTabbedHints.next(can_show);
    }
    
    getShowTabbedHints(): Observable<any> {
        return this.showTabbedHints.asObservable();
     }
}
