import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MessageServiceService } from '../../modules/store/services/message-service.service';

export interface CanDeactivate<T> {
    canDeactivate(component: T, route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class NavigationCanDeactivateGuard implements CanDeactivate<any> {
    constructor(private messageService: MessageServiceService) {
        this._subscription = true;
        this.messageService.getNavigationClicked().subscribe(next => {
            this._subscription = next;
        });
    }
    private _subscription;
    canDeactivate() {
        if (this._subscription) {
            this.messageService.setNavigationClicked(true);
            // push current state again to prevent further attempts.
            history.pushState(null, null, location.href);
            return false;
        } else {
            return true;
        }
    }
}
