
import {throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CorporateuploaderService {
  private _baseURL: string = "http://localhost:52823/api/Upload?clinic_type='corporate'";
  constructor(private http: HttpClient) { }

  upload(files, clinic_type,user_pk,user_email) {

    let headers: Headers = new Headers();
  
    this._baseURL = environment.API_URL + environment.RESOURCES.CLINIC_UPLOAD+"/"+clinic_type+"/"+user_pk+"/"+user_email;
    let userData = SessionDetails.GetUserInfo();
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
  
    let req_headers = new HttpHeaders()
    .set('Authorization','Basic '+ usernamePassword);
    
    const httpOptions = {
      headers: req_headers
    };

    return this.http.post(this._baseURL, files,httpOptions)
    .pipe(catchError(Util.handleErrorV2));

  }
  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    //console.warn(errMsg);
    return observableThrowError(errMsg);
  }
  
  getCorporateSchedulerClinics(client_pk,page_index){
    let url = environment.API_URL + environment.RESOURCES.GET_CORPORATE_SCHEDULER_CLINICS +"/"+client_pk +"/"+page_index;
    return this.http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
}
