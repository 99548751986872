
import {throwError as observableThrowError,  Observable ,  of } from 'rxjs';
import { FormGroup, FormControl, FormArray, AbstractControl, Validators } from "@angular/forms";
import { SessionDetails } from './session';
import { environment } from '../../environments/environment';
import { PcaContract, PcaImmunization } from '../models/pcaclinic';
import { ContractData, Immunization2, Clinic, Immunization } from '../models/contract';
import { DatePipe } from '@angular/common';
import { StringBuilder } from 'typescript-string-operations';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';

@Injectable()
export class Util {


    public static handleError(error: any, router: Router) {
        router.navigate(['/unauthorize']);
        return observableThrowError(error);

    }
  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
public static handleErrorV1<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
public static handleErrorV2( error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return observableThrowError(error);
    }
  
    public static getFormatedDate(date: Date) {
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(date, 'MM/dd/yyyy');
    }
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormArray) {
                control.controls.forEach(ctrl => {
                    if (ctrl instanceof FormGroup) {
                        this.validateAllFormFields(ctrl);
                    }
                })
            }
            else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }
    validateFormGroupForSimpleSave(frmGrp: FormGroup): boolean {
        try {
            let isFieldValid: boolean = true;
            Object.keys(frmGrp.controls).forEach(field => {
                const control = frmGrp.get(field);
                if (control instanceof FormControl) {
                    if (control.value != null && control.value !== '' && !control.valid && !control.disabled) {

                        control.markAsTouched({ onlySelf: true });
                        isFieldValid = false;
                    }
                }
                else if (control instanceof FormArray) {
                    control.controls.forEach(ctrl => {
                        if (ctrl instanceof FormGroup) {
                            isFieldValid = this.validateFormGroupForSimpleSave(ctrl);
                        }
                    })
                }
                else if (control instanceof FormGroup) {
                    isFieldValid = this.validateFormGroupForSimpleSave(control);
                }
            });
            return isFieldValid;
        } catch (e) {
            return false;
        }
    }
    letter(nNum) {
        var a = "A".charCodeAt(0);
        return String.fromCharCode(a + nNum - 1);
    }

    colLetterToNum(val: any) {
        var base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', i, j, result = 0;

        for (i = 0, j = val.length - 1; i < val.length; i += 1, j -= 1) {
            result += Math.pow(base.length, j) * (base.indexOf(val[i]) + 1);
        }

        return result;
    }

    numberToLetters(location_count) {
        var result
        result = (location_count >= 26 ? String.fromCharCode(65 + (location_count % 26 == 0 ? Math.ceil(location_count / 26) - 1
            : Math.ceil(location_count / 26) - 2)).toString() + ""
            + String.fromCharCode(65 + location_count % 26).toString() : String.fromCharCode(65 + location_count % 26).toString());
        return result;
    }
    validateEmail(value: string): boolean {
        let is_valid: boolean = true;
        var emailTokens = value.split(',');
        var regex_pattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        var fn = Validators.pattern(regex_pattern);
        emailTokens.forEach(email => {
            if (!regex_pattern.test(email)) {
                is_valid = false;
                return false;
            }

        });
        return is_valid;
    }

    validateMultipleEmailsCommaSeparated(value) {
        var result = value.split(",");
        for(var i = 0;i < result.length;i++)
        if(!this.validateEmail(result[i])) 
                return false;           
        return true;
    }

    validateSingleEmail(value: string): boolean {
        var regex_pattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        let is_valid: boolean = true;
        if (!regex_pattern.test(value)) {
            is_valid = false;
            return false;
        }
        return is_valid;

    }
    getCurrentQuarter(): number {
        let quarter: number = 0;
        var date = new Date();
        switch ((new Date()).getMonth() + 1) {
            case 6:
            case 7:
            case 8:
                quarter = 1;
                break;
            case 9:
            case 10:
            case 11:
                quarter = 2;
                break;
            case 12:
            case 1:
            case 2:
                quarter = 3;
                break;
            case 3:
            case 4:
            case 5:
                quarter = 4;
                break;
        }

        return quarter;
    }

    validatePhoneNo(value: string): boolean {
        var regex_pattern = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
        let is_valid: boolean = true;
        if (!regex_pattern.test(value.trim())) {
            is_valid = false;
            return false;
        }
        return is_valid;
    }

    public static telephoneNumberFormat(number: string) {
        var val = number.replace(/\D/g, '');
        number = val;

        if (val.length > 3 && val.length < 7) {
            number = val.substring(0, 3) + '-' + val.substring(3, 6);
        }

        if (val.length >= 7) {
            number = val.substring(0, 3) + '-' + val.substring(3, 6) + '-'
                + val.substring(6, 10);
        }
        return number;
    }
    public static telephoneNumber(number: string) {
        if (number != null && number != "") {
            var val = number.replace(/\D/g, '');
            number = val;
        }

        return number;
    }

    public static onlyNumbers(event: any) {

        if (event.key == '.' || event.key == "Del") {
            event.preventDefault();
            return;
        }

        if (event.charCode == 37) { event.preventDefault(); return; }
        var keys = {
            'escape': 27, 'backspace': 8, 'tab': 9, 'enter': 13, 'delete': 46, 'arrowleft': 37, 'arrowright': 39,
            '0': 48, '1': 49, '2': 50, '3': 51, '4': 52, '5': 53, '6': 54, '7': 55, '8': 56, '9': 57
        };
        for (var index in keys) {
            if (!keys.hasOwnProperty(index)) continue;
            if (event.charCode == keys[index] || event.keyCode == keys[index]) {
                return; //default event
            }
        }
        event.preventDefault();
    }

    public static onlyLettersSpaceAndNumbers(event: any): boolean {

        if (event.key == '.' || event.key == "Del") {
            event.preventDefault();
            return false;
        }

        if (event.charCode == 37) { 
            event.preventDefault(); 
            return false;
        }

        var numberKeys = {
            'escape': 27, 'backspace': 8, 'tab': 9, 'enter': 13, 'space': 32,
            '0': 48, '1': 49, '2': 50, '3': 51, '4': 52, '5': 53, '6': 54, '7': 55, '8': 56, '9': 57
        };

        if(Object.values(numberKeys).some(numKey => numKey == event.charCode) 
            || (event.charCode >= 65 && event.charCode <= 90) 
            || (event.charCode >= 97 && event.charCode <= 122) 
        ){
            return true;
        }
        event.preventDefault();
    }

    public static onlyNumberDecimals(event: any) {
        let decimalValue = event.target || event.srcElement;//event.srcElement.value;
        var charCode = (event.which) ? event.which : event.keyCode;

        if (decimalValue.value != '' && decimalValue.value.lastIndexOf(".") > 0 &&
            decimalValue.value.split(".").length > 1 && event.key == '.') {
            event.preventDefault();
            return;
        }

        if (charCode != 46 && charCode > 31
            && (charCode < 48 || charCode > 57)) {

            event.preventDefault(); // peserve old value
        }
        return true;
    }


    // public static getRequestHeaders(): RequestOptions {
    //     let userData = SessionDetails.GetUserInfo();
    //     let headers = new Headers({ 'Content-Type': 'application/json' });
    //     headers.append('Cache-Control', 'no-cache, no-store, must-revalidate');
    //     headers.append('Pragma', 'no-cache');
    //     let token: string = '';
    //     if (userData && Object.keys(userData).length > 0) {
    //         token = window.btoa(userData.userName + ':' + userData.password);
    //         headers.append('Authorization', 'Basic ' + token)
    //     }
    //     return new RequestOptions({ headers: headers });;
    // }
    public static getRequestHeadersV1() {
        let userData = SessionDetails.GetUserInfo();
        let token: string = '';
        if (userData && Object.keys(userData).length > 0) {
           // token = btoa(userData.userName + ':' + userData.password);            
            token = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);            
        }
        let req_headers = new HttpHeaders()
        .set('Content-Type','application/json')
        .set('Cache-Control','no-cache, no-store, must-revalidate')
        .set('Pragma','no-cache')
        .set('Authorization','Basic '+ token);
     
        const httpOptions = {
            headers: req_headers
          };
        return httpOptions;
    }
    public static getRequestHeadersV2() {
        let userData = SessionDetails.GetUserInfo();
        let usernamePassword: string = '';
        if (userData && Object.keys(userData).length > 0) {
            usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                       
        }
        let req_headers = new HttpHeaders()
        .set('Content-Type','application/json')
        .set('Cache-Control','no-cache, no-store, must-revalidate')
        .set('Pragma','no-cache')
        .set('Authorization','Basic '+ usernamePassword)
        // .set('observe','response'  as 'body') // not working 
        // .set('responseType','blob' as 'json');
     
        const httpOptions = {
            headers: req_headers,
            observe:'response' as 'body',
            responseType:'blob' as 'json'
          };
        return httpOptions;
    }
    compareTwoObjects(x, y) {
        var i, l, leftChain, rightChain;

        function compare2Objects(x, y) {
            var p;

            // remember that NaN === NaN returns false
            // and isNaN(undefined) returns true
            if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
                return true;
            }

            // Compare primitives and functions.     
            // Check if both arguments link to the same object.
            // Especially useful on the step where we compare prototypes
            if (x === y) {
                return true;
            }

            // Works in case when functions are created in constructor.
            // Comparing dates is a common scenario. Another built-ins?
            // We can even handle functions passed across iframes
            if ((typeof x === 'function' && typeof y === 'function') ||
                (x instanceof Date && y instanceof Date) ||
                (x instanceof RegExp && y instanceof RegExp) ||
                (x instanceof String && y instanceof String) ||
                (x instanceof Number && y instanceof Number)) {
                return x.toString() === y.toString();
            }

            // At last checking prototypes as good as we can
            if (!(x instanceof Object && y instanceof Object)) {
                return false;
            }

            if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
                return false;
            }

            if (x.constructor !== y.constructor) {
                return false;
            }

            if (x.prototype !== y.prototype) {
                return false;
            }

            // Check for infinitive linking loops
            if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
                return false;
            }

            // Quick checking of one object being a subset of another.
            // todo: cache the structure of arguments[0] for performance
            for (p in y) {
                if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                    return false;
                }
                else if (typeof y[p] !== typeof x[p]) {
                    return false;
                }
            }

            for (p in x) {
                if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                    return false;
                }
                else if (typeof y[p] !== typeof x[p]) {
                    return false;
                }

                switch (typeof (x[p])) {
                    case 'object':
                    case 'function':

                        leftChain.push(x);
                        rightChain.push(y);

                        if (!compare2Objects(x[p], y[p])) {
                            return false;
                        }

                        leftChain.pop();
                        rightChain.pop();
                        break;

                    default:
                        if (x[p] !== y[p]) {
                            return false;
                        }
                        break;
                }
            }

            return true;
        }

        if (arguments.length < 1) {
            return true; //Die silently? Don't know how to handle such case, please help...
            // throw "Need two or more arguments to compare";
        }

        for (i = 1, l = arguments.length; i < l; i++) {

            leftChain = []; //Todo: this can be cached
            rightChain = [];

            if (compare2Objects(arguments[0], arguments[1])) {
                return false;
            }
        }

        return true;
    }

    isStoreIsfromRestrictedState() {
        var resrict_states = []; //"DC" -- Removing DC state as per task# 1363
        var current_date = new Date();
        var storeState = SessionDetails.GetState();
        if (resrict_states.indexOf(storeState) > -1) {
            if ( current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO)) {
                return true;
            }
             if ( current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }


    getDifference(o1, o2) {
        var diff = {};
        var tmp = null;
        if (o1 && o2) {
            if (JSON.stringify(o1) === JSON.stringify(o2)) return diff;

            for (var k in o1) {
                if (o2 && Array.isArray(o1[k]) && Array.isArray(o2[k])) {
                    tmp = o1[k].reduce((p, c, i) => {

                        //if(o2[k][i]){        
                        var _t = this.getDifference(c, o2[k][i]);
                        if (_t && Object.keys(_t).length > 0) {
                            p.push(_t);
                            return p;
                        }
                        //}
                    }, []);
                    if (tmp && Object.keys(tmp).length > 0)
                        diff[k] = tmp;
                } else if (o2 && k != 'clinicDate' && typeof (o1[k]) === "object" && typeof (o2[k]) === "object") {

                    tmp = this.getDifference(o1[k], o2[k]);
                    if (tmp && Object.keys(tmp).length > 0)
                        diff[k] = tmp;
                } else if (o2 && o1[k] !== o2[k]) {
                    diff[k] = o2[k]
                }
            }
        }
        return diff;
    }

    isEmptyObject(obj) {
        var name;
        for (name in obj) {
            return false;
        }
        return true;
    };

    getObjDiff(obj1, obj2) {
        var result = {};
        var change;
        for (var key in obj1) {
            if (typeof obj2[key] == 'object' && typeof obj1[key] == 'object') {
                change = this.getObjDiff(obj1[key], obj2[key]);
                if (this.isEmptyObject(change) === false) {
                    result[key] = change;
                }
            }
            else if (key!='isReassign' && obj2[key] != obj1[key]) {
                result[key] = obj2[key];
            }
        }
        return result;
    }

    getDiffDays(date1: Date, date2: Date) {
        var one_day = 1000 * 60 * 60 * 24;
        // Convert both dates to milliseconds
        var date1_ms = date1.getTime();
        var date2_ms = date2.getTime();

        // Calculate the difference in milliseconds
        var difference_ms = date2_ms - date1_ms;

        // Convert back to days and return
        return Math.round((difference_ms / one_day) + 1);
    }
    AllowAdminsToEditDataForRestrictedState() {
        var resrict_states = []; // "DC" -- Removing DC state as per task# 1363
        var userProfile = SessionDetails.GetUserProfile();
        var current_date = new Date();
        
        if (resrict_states.indexOf(SessionDetails.GetState()) > -1){
            
            if(userProfile.userRole.toLowerCase() == "admin" || userProfile.userRole.toLowerCase() == "healthcare supervisor"){
                return false;
            }
            else if (current_date > new Date(environment.DC_State_From) && current_date < new Date('5/1/2023')) {
                return false;
            }
            else {
                return true;
            }
        }
      }
      isClinicDateReminderBefore2WksReq(form:FormGroup){
        let isClinicDateReminder: Boolean = false;
          try{
            let today = new Date();
            for(let index=0;index < form.value.locations.length; index++){
              const loc = form.value.locations[index];
               var loc_date = (typeof loc.eventDate == 'string')?new Date(loc.eventDate):loc.eventDate;
               let diffDays = this.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(),0,0,0),
               new Date(loc_date.getFullYear(),loc_date.getMonth(), loc_date.getDate(),0,0,0));
             if (diffDays < 14) {
                 isClinicDateReminder = true;          
             }
            }
          }catch (e) {
                  return e;
              }
          return isClinicDateReminder;
      }
      transformPcaAgreementToOrthoDoxContractJson(contractData:PcaContract,businessUserEmails:string, _datePipe:DatePipe, orthoDoxContract:ContractData)
      {
        orthoDoxContract.isApproved = contractData.isApproved;
        orthoDoxContract.isLocationReqAvailable = contractData.isLocationReqAvailable;
        orthoDoxContract.isHealthRoomAvailable = contractData.isHealthRoomAvailable
        orthoDoxContract.approvedOrRejectedBy = contractData.approvedOrRejectedBy;
        orthoDoxContract.businessUserEmails = businessUserEmails;
        orthoDoxContract.clientInfo = contractData.clientInfo;
        orthoDoxContract.licenseAgreementNum = contractData.licenseAgreementNum;
        orthoDoxContract.isRHDApproved = contractData.isRhdApproved;
        
        if(contractData.isHealthRoomAvailable == 0 || contractData.isHealthRoomAvailable == undefined){
            orthoDoxContract.isHealthRoomAvailable = null;
        }
        if(contractData.isLocationReqAvailable == 0 || contractData.isLocationReqAvailable == undefined){
            orthoDoxContract.isLocationReqAvailable = null;
        }
         
        orthoDoxContract.contracTthankYou = null ;//todo
        orthoDoxContract.clinicAgreementPk = contractData.clinicAgreementPk;
        orthoDoxContract.contactLogPk = contractData.contactLogPk;
    
        orthoDoxContract.contractPostedByUser =  'Walgreens User';
        orthoDoxContract.contactWagUser = ''; 
        orthoDoxContract.walgreenCoInfo = contractData.walgreenCoInfo;
        if(orthoDoxContract.walgreenCoInfo){
        orthoDoxContract.walgreenCoInfo.rhdName = orthoDoxContract.walgreenCoInfo.rhdName ? orthoDoxContract.walgreenCoInfo.rhdName.trim():null;
        }
        orthoDoxContract.legalNoticeAddressInfo = null;
        orthoDoxContract.clinicAgreementPk  = contractData.clinicAgreementPk;    
        orthoDoxContract.isEmailSent =contractData.isEmailSent;
        orthoDoxContract.dateCreated = contractData.dateCreated;
         
        orthoDoxContract.notes = contractData.notes;
        orthoDoxContract.signature = contractData.signature;
        orthoDoxContract.rhdSignature = contractData.rhdSignature;
        if(contractData.immunization){
            contractData.immunization.map((immunization: PcaImmunization) => {
                let immunization2 = new Immunization2();
                immunization2.immunizationName = immunization.immunizationName;
                immunization2.immunizationPk = immunization.immunizationPk;  
                orthoDoxContract.clinicImmunizationList.push(immunization2);
            })
            
        }
        else {
            orthoDoxContract.clinicImmunizationList = [];
        }
        contractData.pcaEvenScheduleList.forEach(item =>{
          let clinic = new Clinic();
          clinic.address1 = contractData.pcaClinic && contractData.pcaClinic.address1?contractData.pcaClinic.address1.trim():null;
          clinic.address2 = "";
          clinic.city = contractData.pcaClinic.city;          
          let date = new Date(item.eventDate);
          clinic.clinicDate = date.getMonth() + 1 + "/" + (date.getDate() ) + "/" + date.getFullYear();
			if (typeof item.startTime === 'object') {
                clinic.startTime = _datePipe.transform(item.startTime, "shortTime");
            }
            else {
                clinic.startTime = item.startTime
            }
            if (typeof item.endTime === 'object') {
                clinic.endTime = _datePipe.transform(item.endTime, "shortTime");
            }
            else {
                clinic.endTime = item.endTime
            }
          if(contractData.immunization){
              contractData.immunization.map((immunizationObj) => {
                let immunization = new Immunization();
                immunization.immunizationPk = immunizationObj.immunizationPk;
                immunization.estimatedQuantity = item.estimatedQuantity;
                immunization.immunizationName = immunizationObj.immunizationName;
                clinic.clinicImzQtyList.push(immunization);
            });

          }
          else {
            clinic.clinicImzQtyList = [];
          }
           
          clinic.contactEmail = contractData.pcaClinic.contactEmail?contractData.pcaClinic.contactEmail.trim():contractData.pcaClinic.contactEmail;
          clinic.contactFirstName = contractData.pcaClinic && contractData.pcaClinic.contactFirstName? contractData.pcaClinic.contactFirstName.trim():null;
          clinic.contactLastName = contractData.pcaClinic && contractData.pcaClinic.contactLastName? contractData.pcaClinic.contactLastName.trim():null;
          clinic.contactPhone = contractData.pcaClinic.contactPhone;
          clinic.jobTitle = contractData.pcaClinic.jobTitle? contractData.pcaClinic.jobTitle.trim():null;
          clinic.state = contractData.pcaClinic.state;
          clinic.zipCode = contractData.pcaClinic.zipCode; 
          clinic.location = item.location;     
         
          orthoDoxContract.clinicList.push(clinic);
        });
      }
	isAdminAllowedToAddGroupId(): boolean {
	        let is_admin_allowed_to_add_groupid: boolean = false;
	        var user_profile = SessionDetails.GetUserProfile();
	        if (user_profile.userRole.toLowerCase() === "admin") {
	            var authorized_admin_users = environment.addCoPayGroupIdAdmins.split(',');
	            authorized_admin_users.forEach(email => {
	                if (email.toLowerCase() === user_profile.email.toLowerCase())
	                    is_admin_allowed_to_add_groupid = true;
	                return;
	            });
	        }
	        return is_admin_allowed_to_add_groupid;
	    }

      print( documentId:string, pageTitle:string, docprintContents:string=null ) {
    
        let printContents, popupWin;
    
        var builder = new StringBuilder('<div class="container"><div class="row">');

        builder.append(docprintContents!=null? docprintContents:document.getElementById(documentId).innerHTML);
        builder.append('</div></div>');
        printContents = builder.toString();
        popupWin = window.open('', '', 'top=10,left=20,height=600,width=600');
        popupWin.document.open();
        let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent)
        if(isIEOrEdge==true){
            
        popupWin.document.write(`
            <html>
            <head>
            <meta http-equiv="X-UA-Compatible" content="IE=11" />
              <title>${pageTitle}</title>
              <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600%7CRaleway:300,400,500,600,700%7CLato:300,400,400italic,600,700" rel="stylesheet" type="text/css" />
              <link href="https://fonts.googleapis.com/css?family=La+Belle+Aurore" rel="stylesheet">
              <!-- CORE CSS -->
              <link rel="stylesheet" href="../assets/css/theme.css" />
              <link rel="stylesheet" href="../assets/css/font-awesome.min.css" />
              <link rel="stylesheet" href="../assets/css/primeng.min.css" />
              <link href="../assets/plugins/bootstrap/css/bootstrap.min.css" rel="stylesheet" type="text/css" />
            
              <!-- THEME CSS -->
              <link href="../assets/css/essentials.css" rel="stylesheet" type="text/css" />
              <link href="../assets/css/lulaieprint.css" rel="stylesheet" type="text/css" />
              <link href="https://ng-forms-fields-validation.firebaseapp.com/validateSubmit" rel="stylesheet" type="text/css"/>
            
              <!-- PAGE LEVEL SCRIPTS -->
              <link href="../assets/css/header-1.css" rel="stylesheet" type="text/css" />
              <link href="../assets/css/color_scheme/darkblue.css" rel="stylesheet" type="text/css" id="color_scheme" />
              <link href="../assets/css/media.css" rel="stylesheet" type="text/css" />
              <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600%7CRaleway:300,400,500,600,700%7CLato:300,400,400italic,600,700" rel="stylesheet" type="text/css" />
  <link href="https://fonts.googleapis.com/css?family=La+Belle+Aurore" rel="stylesheet">
              <style>
              element.style {
                height: 135px;
              }
             
              .table td, .table th {
                border-top: 0px solid #e9ecef;
            }
            .signatureFont{
                font-family: 'La Belle Aurore';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
              }
              .inWitnessFont2022{
                text-align: center;
              }
              .inputSignFont{
                font-family: 'La Belle Aurore' !important;
                font-size:18px !important;
              }
              @media print{
                  .container{
                      width:1200px;
                  }
                .twoColmns{
                    -moz-column-count: 2;
                        -webkit-column-count: 2; 
                        column-count: 2;
                  }
              }
              </style>
<script>
function printPreview() {
    setTimeout(function(){ window.print();window.close(); }, 2000);
  }
  </script>
            </head>
          <body onload="window.printPreview()">${printContents}</body>
            </html>`
        );
      } else  {
        popupWin.document.write(`
            <html>
            <head>
            <title>${pageTitle}</title>
              <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600%7CRaleway:300,400,500,600,700%7CLato:300,400,400italic,600,700" rel="stylesheet" type="text/css" />
              <link href="https://fonts.googleapis.com/css?family=La+Belle+Aurore" rel="stylesheet">
              <!-- CORE CSS -->
              <link rel="stylesheet" href="../assets/css/theme.css" />
              <link rel="stylesheet" href="../assets/css/font-awesome.min.css" />
              <link rel="stylesheet" href="../assets/css/primeng.min.css" />
              <link href="../assets/plugins/bootstrap/css/bootstrap.min.css" rel="stylesheet" type="text/css" />
              <!-- THEME CSS -->
              <link href="../assets/css/essentials.css" rel="stylesheet" type="text/css" />
              <link href="../assets/css/layout.css" rel="stylesheet" type="text/css" />
              <link href="https://ng-forms-fields-validation.firebaseapp.com/validateSubmit" rel="stylesheet" type="text/css"/>
              <!-- PAGE LEVEL SCRIPTS -->
              <link href="../assets/css/header-1.css" rel="stylesheet" type="text/css" />
              <link href="../assets/css/color_scheme/darkblue.css" rel="stylesheet" type="text/css" id="color_scheme" />
              <link href="../assets/css/media.css" rel="stylesheet" type="text/css" />
              <style>
              element.style {
                height: 135px;
              }
              .table td, .table th {
                border-top: 0px solid #e9ecef;
            }
            .signatureFont{
                font-family: 'La Belle Aurore';
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
              }
              .inputSignFont{
                font-family: 'La Belle Aurore' !important;
                font-size:18px !important;
              }
              .inWitnessFont2022{
                text-align: center;
              }
              </style>
<script>
function printPreview() {
    setTimeout(function(){ window.print();window.close(); }, 2000);
  }
  </script>
            </head>
          <body onload="window.printPreview()">${printContents}</body>
            </html>`
        );
      }
        popupWin.document.close();
    
      }
      IsReassignmentAllowedForRestrictedState(stateSpecific:string){  

        let reassignmentAllowed:boolean = true;        
        var current_date = new Date();        
          if (stateSpecific=="MO" && current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO) ) {
            reassignmentAllowed = false;
          }
          else if (stateSpecific=="DC" && current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)) {
            reassignmentAllowed = false;
          }
          else {
            reassignmentAllowed = true;
          }
        
        return reassignmentAllowed;
        }

        transformtoFixed(e){
            return parseFloat(e.target.value).toFixed(2);
        }

       
      
      
}