import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-profile-upload-complete',
  templateUrl: './store-profile-upload-complete.component.html',
  styleUrls: ['./store-profile-upload-complete.component.css']
})

export class StoreProfileUploadCompleteComponent implements OnInit {

  uploadedFileKey: string = "tst";
  uploadMode: number = -1;

  completedStatusMsg: string="";
  statusMessage: string = "";
  constructor() { }

  ngOnInit() {
    this.statusMessage = "<h3>The " + ((Number(this.uploadMode) === 0) ? "Monthly" : "Partial") + " Store update completed successfully.</h3>";
    if (this.completedStatusMsg) {
      this.statusMessage += "<br /><br />";
      this.statusMessage += "<span style='color:red'>The following stores have failed to get Geocoded.</span><br />";
      this.statusMessage += this.completedStatusMsg.replace(/\r\n/g,'<br />');
    }
  }

  //****** DO NOT DELETE THESE FUNCTIONS AS THEY ARE BEING USED IN PARENT COMPONENT -- START *****//
  getButtonContent(action: string) {
    let but_content: string = "";
    return but_content;
  }

  getHeader() {
    return "";
  }
  //****** DO NOT DELETE THESE FUNCTIONS AS THEY ARE BEING USED IN PARENT COMPONET -- END *****//
}
