
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { commonMenuItems } from '../../../JSON/Menu';
import { environment } from '../../../../environments/environment';
import { StoreProfile } from '../../../models/storeProfile';
import { Util } from '../../../utility/util';
import { adminFooterMenuItems, storeManagerFooterMenuItems, districtManagerFooterMenuItems, healthcareSupervisorFooterMenuItems, directorRxRetailFooterMenuItems, regionalVicePresidentFooterMenuItems } from '../../../JSON/footerMenu';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HeaderserviceService {
  constructor( private _http: HttpClient, private store_profile: StoreProfile) { }
  getCommonMenuItems() {
    return commonMenuItems;
  }

  getAdminFooterMenu() {
    return adminFooterMenuItems;
  }
  getStoreManagerFooterMenu() {
    return storeManagerFooterMenuItems;
  }

  getDistrictManagerFooterMenu() {
    return districtManagerFooterMenuItems;
  }

  getHealthcareSupervisorFooterMenu() {
    return healthcareSupervisorFooterMenuItems;
  }

  getDirectorRxRetailFooterMenu() {
    return directorRxRetailFooterMenuItems;
  }
  getRegionalVicePresidentFooterMenu() {
    return regionalVicePresidentFooterMenuItems;
  }
  storeSearch(user_pk: number, store_search: string, store_id: number): Observable<any> {
    if (store_search.length >= 2) {
      let startTime: Date = new Date();      
      let url = environment.API_URL + environment.RESOURCES.GET_STORE_PROFILE + "/" + user_pk + "/" + store_search + "/" + 0;
      return this._http.get(url, Util.getRequestHeadersV1())      
        .pipe(map(response=>this.extractData(response)),
          catchError(Util.handleErrorV2));
    }
    return observableOf([]);
  }

  private extractData(res) {
    let startTime: Date = new Date();
    if(res.data){
    return res.data;
    }
    else {
      return null;
    }
  }
  // private handleError(error: Response | any) {
  //   // In a real world app, we might use a remote logging infrastructure
  //   let errMsg: string;
  //   if (error instanceof Response) {
  //     const body = error.json() || '';
  //     const err = body.error || JSON.stringify(body);
  //     errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
  //   } else {
  //     errMsg = error.message ? error.message : error.toString();
  //   }
  //   console.error(errMsg);
  //   return Observable.throw(errMsg);
  // }
  getStoresProfile(user_pk: number, store_search: string, store_id: number) {
    let url = environment.API_URL + environment.RESOURCES.GET_STORE_PROFILE + "/" + user_pk + "/" + store_search + "/" + store_id;
    return this._http.get(url, Util.getRequestHeadersV1())
    .pipe(catchError(Util.handleErrorV2));
  }

  getWagsOutreachCounts(): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_WAGSOUTREACH_COUNTS;
    return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  getDroppedStoresForReassignment(): Observable<any> {    
    let url = environment.API_URL + environment.RESOURCES.DROPPED_STORES_FOR_REASSIGNMENT;
    return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  reassignDroppedStoresToClosestStore(SelectedDroppedStores: any) {
    let url = environment.API_URL + environment.RESOURCES.REASSIGN_DROPPEDSTORES_TO_CLOSESTSTORE;
    return this._http.post(url, SelectedDroppedStores,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
}
