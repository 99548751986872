import { Component, OnInit, ElementRef, HostListener, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { Observable, Subject, Observer, Subscription ,  of } from 'rxjs';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { StoreModel } from '../../../../models/Store';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { ActionType } from '../../../../utility/enum';
import { SessionDetails } from '../../../../utility/session';

import { StoresListService } from '../../../store/services/stores-list.service';
import { StoreProfile } from '../../../../models/storeProfile';
import { OpportunitiesService } from '../../../store/services/opportunities.service';
import { HeaderserviceService } from '../../services/headerservice.service';
import { AlertService } from '../../../../alert.service';
import { environment } from '../../../../../environments/environment';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap} from 'rxjs/operators';
import { UserRole } from '../../../../config-files/UserRole';

type searchResult ={address:string,address2:String,areaNumber:Number,city:String,districtNumber:Number,lastStatusProvided:String,regionNumber:Number,state:String,storeId:Number,storePk:Number,zip:String}
@Component({
  selector: 'app-masterlayout',
  templateUrl: './masterlayout.component.html',
  styleUrls: ['./masterlayout.component.css']
})
export class MasterlayoutComponent implements OnInit {
  showVar: boolean = false;
  isAdmin: boolean = false;
  isPowerUser: boolean = false;
  headerTitle: string;
  menu: any[]
  openElements: boolean[] = [];
  storeInfo: any;
  displayName: any;
  displayNumber: any;
  lastAccess: any = new Date();
  storeAddress: string = '';
  stores: StoreModel[] = [];
  stores1: any[] = [];
  searching = false;
  searchFailed = false;
  visible: boolean = true;
  model: any;
  results: Object;
  searchTerm$ = new Subject<string>();
  search$: Observable<string[]>;
  selectedNumber: string = '';
  titleName: any;
  storeID: any = "";
  onSelection: boolean = false;
  dashboard: boolean = false;
  isStoreTitle: boolean = true;
  isShowHeaderFooter: boolean = true;
  subscription: Subscription;
  message: any;
  userProfile: any;
  isFirstLogin: boolean = false;
  devWarning:boolean=false;
  isIP:boolean=false;
  isPCA:boolean=false;
  isSR:boolean=false;
  siteMaintenanceDlg:boolean=false;
  isClientRequired:boolean= false;
  @ViewChild('storeSearch') storeSearchEleRef : ElementRef;
  loadAPI: Promise<any>;
  ChangecorpStoreApptTrackerBannerColor: any = false;
  state:any;
  constructor(public router: Router, 
                private message_service: MessageServiceService, 
                private _opportunityService: OpportunitiesService, 
                titleService: Title, 
                private _headerservice: HeaderserviceService, 
                private _storeslistservice: StoresListService, 
                private store_profile: StoreProfile,private alertService: AlertService) {
                  
   
    this.subscription = this.alertService.getWarningAlert().subscribe((wAlert:any) => {
      this.state = JSON.parse(sessionStorage.store_profile)[0].state
      if (wAlert.pca==true) {
        this.isPCA = true;
        this.isSR=false;
        this.isIP=false;
        this.isPCA = true;
        this.isSR = false;
        this.isIP = false;

      }
      else if(wAlert.sr==true){
        //this.isSR = true;
        this.isPCA = false;
        this.isIP=false;        
      }
      else if(wAlert.ip==true){
        this.isSR = false;
        this.isPCA = false;
        this.isIP=true;
        
      }
      else{
        this.isSR = false;
        this.isPCA = false;
        this.isIP=false;        
      }
    });
    if (sessionStorage.is_pca == "false" && sessionStorage.is_sr == "false") {
      this.isIP = false;
      this.isSR = false;
      this.isPCA = false;
      SessionDetails.setIsPCA(false);
      SessionDetails.setIsSR(false);
    } else if (sessionStorage.is_pca == "true" && sessionStorage.is_sr == "false")  {
      this.isPCA = true;
      this.isSR = false;
      this.isIP = false;
      SessionDetails.setIsPCA(true);
      SessionDetails.setIsSR(false);
    }else if (sessionStorage.is_pca == "false" && sessionStorage.is_sr == "true")  {
      this.isPCA = false;
      //this.isSR = true;
      this.isIP = false;
      SessionDetails.setIsPCA(false);
      SessionDetails.setIsSR(true);
    }      
             
              
     
           
    this.loadAPI = new Promise((resolve) => {
      if(window.location.href.indexOf("landing")===-1){
        this.loadScript(); 
      }
      resolve(true);
    });
    router.events.subscribe((event:any) => {
      // if(window.location.pathname === '/communityoutreach/contract'){
      //   this.devWarning=true;
      // }
      // else{
      //   this.devWarning=false;
      // }
      if (event instanceof NavigationEnd) {
        let title = this.getTitle(router.routerState, router.routerState.root).join('-');
        titleService.setTitle("Walgreens Community Outreach");
        if (document.getElementById('menu-overlay')) {
          let a = document.getElementsByClassName('video supports csstransitions csstransforms3d noscroll');
          a[0].classList.remove('noscroll');
          let b = document.getElementsByClassName('navbar-collapse float-right nav-main-collapse collapse show');
          let val=b[0] as HTMLElement;
          val.style.display = 'none';
          b[0].classList.remove('show');
          let c = document.getElementsByClassName('btn btn-mobile btn-mobile-active');
          c[0].classList.remove('btn-mobile-active');
          let d = document.getElementsByClassName('btn btn-mobile');
          d[0].classList.add('collapsed');
          let e = document.getElementById('menu-overlay');
          e.remove();
        }

        if(environment.isSiteDeploying){
          this.siteMaintenanceDlg=true;
        }
        switch (title) {          
          case "contract":
            this.headerTitle = "COMMUNITY OFF-SITE AGREEMENT";
            this.isStoreTitle = false;
            break;
          case "Corporate Clinic Details":
            this.headerTitle = "CLINIC DETAILS";
            this.isStoreTitle = false;
            break;
            case "storeprofileuploader":
            this.headerTitle = "UPLOAD STORE PROFILE";
            this.isStoreTitle = true;
            break;
          case "immunizationCheck":
            this.headerTitle = "IMMUNIZATION & RATES";
            this.isStoreTitle = true; 
            break; 
            case "Patient Reassignment":
            this.headerTitle = "PATIENT REASSIGNMENT";
            this.isStoreTitle = true; 
            break;        
          case "Dropped Store Reassignments":
            this.headerTitle = "DROPPED STORE REASSIGNMENTS";
            this.isStoreTitle = true; 
            break;
          case "Group Ids Uploader":
            this.headerTitle = "GROUP ID UPLOADER";
            this.isStoreTitle = true; 
            break;       
          case "addimmunizationCheck":
            this.headerTitle = "ADD IMMUNIZATION & RATE";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
          case "editimmunizationCheck":
            this.headerTitle = "EDIT IMMUNIZATION & RATE";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
          case "addBusiness":
            if (SessionDetails.GetActionType() == ActionType.addOportunity) {
              this.headerTitle = "ADD AN OPPORTUNITY";
              this.isStoreTitle = false;
              this.isShowHeaderFooter = true;
            }
            else {
              this.headerTitle = "OPPORTUNITY PROFILE";
              this.isStoreTitle = false;
              this.isShowHeaderFooter = true;
            }
            break;
          case "contactlog":
            this.headerTitle = "CONTACT LOG HISTORY";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
          case "charityProgram":
            this.headerTitle = "CHARITY (WAG FREE FLU VOUCHER)";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
            case "addpca":
            this.headerTitle = "PCA ORGANIZATION";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
            case "asolula":
            this.headerTitle = "ASO LIMITED USE LICENSE AGREEMENT";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
            case "opolula":
            this.headerTitle = "OPO LIMITED USE LICENSE AGREEMENT";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
            case "csslula":
            this.headerTitle = "CSS LIMITED USE LICENSE AGREEMENT";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
            case "governmentlula":
            this.headerTitle = "GOVERNMENT LIMITED USE LICENSE AGREEMENT";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
          case "scheduleevent":
            this.headerTitle = "SR SCHEDULED EVENT INFORMATION";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
          case "userprofile":
            this.headerTitle = "USER MANAGEMENT";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
            case "edit_user":
            case "add-user":
            this.headerTitle = "USER PROFILE";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
          case "corporateScheduler":
            this.headerTitle = "APPOINTMENT SCHEDULER";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
          case "Local Clinic Details":
            this.headerTitle = "CLINIC DETAILS";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
          case "pcaresources":
            this.headerTitle = "PATIENT CARE & ADVOCACY MATERIALS";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
            case "Community Outreach Clinic Details":
            this.headerTitle = "CLINIC DETAILS";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
            case "Immunization Program Email Template":
              this.headerTitle = "IMMUNIZATION PROGRAM GUIDELINES";
              this.isStoreTitle = true;
              this.isShowHeaderFooter = true;
              break;
              case "Senior Outreach Email Template":
                this.headerTitle = "SENIOR OUTREACH";
                this.isStoreTitle = true;
                this.isShowHeaderFooter = true;
                break;
                case "Patient Care Advocacy Email Template":
                  this.headerTitle = "PATIENT CARE & ADVOCACY EVENTS";
                  this.isStoreTitle = true;
                  this.isShowHeaderFooter = true;
                  break;
          case "followUp":
            this.headerTitle = "SCHEDULED FOLLOW-UP REMINDERS";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
          case "Charity Program Clinic Details":
            this.headerTitle = "CLINIC DETAILS";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
          case "contractAgreementForUser":
            this.headerTitle = "COMMUNITY OFF-SITE CLINIC AGREEMENT";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = false;
            break;
          case "resources":
            this.headerTitle = "OUTREACH MATERIALS";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
          case "scheduledappointmentsreport":
            this.headerTitle = "SCHEDULED APPOINTMENTS REPORT";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
          case "soresources":
            this.headerTitle = "SENIOR OUTREACH MATERIALS";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
          case "vissresouces":
            this.headerTitle = "VACCINE INFORMATION STATEMENTS (VISs)";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
          case "imzresources":
            this.headerTitle = "IMMUNIZATION OUTREACH MATERIALS";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
          case "Community Outreach Program":
            this.headerTitle = "COMMUNITY OUTREACH";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
          case "equityevents":  
          this.headerTitle = "EQUITY EVENT";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
            case "equityeventdetails":  
            this.headerTitle = "EQUITY EVENT DETAILS";
              this.isStoreTitle = false;
              this.isShowHeaderFooter = true;
              break;  
          case "clientSchedulerApptTracker":
            this.headerTitle = "IMMUNIZATION CLINIC";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = true;
            break;
          case "viewcontract":
            this.headerTitle = "COMMUNITY OFF-SITE AGREEMENT";
            this.isStoreTitle = false;
            this.isShowHeaderFooter = false;
            break;
          case "activityreport":
            this.headerTitle = "ACTIVITY REPORT";
            this.isStoreTitle = true;
            break;
          case "scheduledclinicsreport":
            this.headerTitle = "SCHEDULED CLINICS REPORT";
            this.isStoreTitle = true;
            break;
            case "servicefeesreport":
              this.headerTitle = "SERVICE FEE REPORT";
              this.isStoreTitle = true;
              break;
          case "scheduledcovidclinicsreport":
            this.headerTitle = "SCHEDULED COVID CLINICS REPORT";
            this.isStoreTitle = true;
            break;
          case "covidclinicsreport":
            this.headerTitle = "COVID CLINICS REPORT";
            this.isStoreTitle = true;
            break;
            case "covidoppOutreachstatusreport":
              this.headerTitle = "COVID OPPORTUNITIES CONTACT STATUS REPORT";
              this.isStoreTitle = true;
              break;
          case "scheduledeventsreport":
            this.headerTitle = "SCHEDULED EVENTS REPORT";
            this.isStoreTitle = true;
            break;
          case "vaccinepurchasingreport":
            this.headerTitle = "VACCINE PURCHASING REPORT";
            this.isStoreTitle = true;
            break;
          case "covidvaccinepurchasingreport":
            this.headerTitle = "COVID VACCINE PURCHASING REPORT";
            this.isStoreTitle = true;
            break;
          case "immunizationfinancereport":
            this.headerTitle = "IMMUNIZATION FINANCE REPORT";
            this.isStoreTitle = true;
            break;
          case "covidimmunizationfinancereport":
            this.headerTitle = "COVID IMMUNIZATION FINANCE REPORT";
            this.isStoreTitle = true;
            break;
          case "groupidassignmentreport":
            this.headerTitle = "GROUP ID ASSIGNMENT REPORT";
            this.isStoreTitle = true;
            break;
          case "covidgroupidassignmentreport":
            this.headerTitle = "COVID GROUP ID ASSIGNMENT REPORT";
            this.isStoreTitle = true;
            break;
          case "schedulerappttrackingcomponent":
          case "scheduledapptsrepforreminderemailcomponent":
          //case "scheduledappointmentsreport":
            this.headerTitle = "SCHEDULED APPOINTMENTS REPORT";
            this.isStoreTitle = true;
            break;
          case "revisedclinicdatesreport":
            this.headerTitle = "REVISED CLINIC DATES & VOLUMES REPORT";
            this.isStoreTitle = true;
            break;
          case "covidrevisedclinicdatesreport":
            this.headerTitle = "COVID REVISED CLINIC DATES & VOLUMES REPORT";
            this.isStoreTitle = true;
            break;
          case "weeklygroupidassignmentsreport":
            this.headerTitle = "WEEKLY GROUP ID ASSIGNMENTS REPORT";
            this.isStoreTitle = true;
            break;
          case "weeklyvaccinepurchasereport":
            this.headerTitle = "WEEKLY VACCINE PURCHASING REPORT";
            this.isStoreTitle = true;
            break;
          case "covidweeklyvaccinepurchasereport":
            this.headerTitle = "COVID WEEKLY VACCINE PURCHASING REPORT";
            this.isStoreTitle = true;
            break;
          case "workflowmonitorreport":
            this.headerTitle = "WORKFLOW MONITOR REPORT";
            this.isStoreTitle = true;
            break;
          case "restrictedstateopportunitiesreport":
            this.headerTitle = "RESTRICTED STATE OPPORTUNITIES REPORT";
            this.isStoreTitle = true;
            break;
          case "restrictedstateclinicsreport":
            this.headerTitle = "RESTRICTED STATE CLINICS REPORT";
            this.isStoreTitle = true;
            break;
          case "highlevelstatusreport":
            this.headerTitle = this.getRoleTitleForReports(this.userProfile.userRole).toUpperCase() + "HIGH LEVEL STATUS REPORT";
            this.isStoreTitle = true;
            break;
          case "covidhighlevelstatusreport":
            this.headerTitle = this.getRoleTitleForReports(this.userProfile.userRole).toUpperCase() + "COVID HIGH LEVEL STATUS REPORT";
            this.isStoreTitle = true;
            break;
          case "storecompliancereport":
            this.headerTitle = "STORE COMPLIANCE REPORT";
            this.isStoreTitle = true;
            break;
          case "districtcompliancereport":
            this.headerTitle = "DISTRICT COMPLIANCE REPORT";
            this.isStoreTitle = true;
            break;
          case "localopportunitieslist":
            this.headerTitle = "LOCAL OPPORTUNITIES LIST REPORT";
            this.isStoreTitle = true;
            break;
          case "localopportunitiescontactstatusreport":
            this.headerTitle = "LOCAL OPPORTUNITIES CONTACT STATUS REPORT";
            this.isStoreTitle = true;
            break;
          case "useraccesslogreport":
            this.headerTitle = "USER ACCESS LOG REPORT";
            this.isStoreTitle = true;
            break;
            case "Data Stats Reporting":
              this.headerTitle = "DATA STATS REPORTING";
              this.isStoreTitle = true;
              break;
              case "Opportunities Contact Status":
              this.headerTitle = "OPPORTUNITIES CONTACT STATUS";
              this.isStoreTitle = true;
              break;
              case "COVID Schools Lead Generation Report":
                this.headerTitle = "COVID SCHOOLS LEAD GENERATION REPORT";
                this.isStoreTitle = true;
                break;
              case "LTCF Lead Generation Report":
                this.headerTitle = "LTCF LEAD GENERATION REPORT";
                this.isStoreTitle = true;
                break;
              case "Lead Generation Report":
                this.headerTitle = "LEAD GENERATION REPORT";
                this.isStoreTitle = true;
                break;
          case "minimumshotexceptionsreport":
            this.headerTitle = "MINIMUM SHOT EXCEPTIONS REPORT";
            this.isStoreTitle = true;
            break;
          case "covidminimumshotexceptionsreport":
            this.headerTitle = "COVID MINIMUM SHOT EXCEPTIONS REPORT";
            this.isStoreTitle = true;
            break;
          case "initiatedcontractsreport":
            this.headerTitle = "INITIATED CONTRACT REPORT";
            this.isStoreTitle = true;
            break;
          case "scheduledclinicstatusreport":
            this.headerTitle = "SCHEDULED CLINICS STATUS REPORT";
            this.isStoreTitle = true;
            break;
            case "Favorites":
            this.headerTitle = "COMMUNITY OUTREACH PORTAL";
            this.isStoreTitle = true;
            break;
          case "testingeventsreport":
            this.headerTitle = "EVENTS REPORT";
            this.isStoreTitle = true;
            break;
          case "hcsactionitems":
          case "pcaactionitems":
          
            this.headerTitle = this.getTitleForDashboardReports();
            this.message_service.getReportTitle().subscribe((title:any) => {
              this.headerTitle = title;
            });
            this.isStoreTitle = true;
            break;       
            case "fluhcsactionitems":            

            this.headerTitle = this.getTitleForDashboardReports(false);
            this.message_service.getReportTitle().subscribe(title => {
              this.headerTitle = title;
            });
            this.isStoreTitle = true;
            break;             
          case "trainingvideos":
            this.headerTitle = "TRAINING VIDEOS";
            this.isStoreTitle = true;
            break;
            case "organizationProfile":
            this.headerTitle = "PCA ORGANIZATION";
            this.isStoreTitle = true;
            break;
            case "pcadetails":
            this.headerTitle = "EVENT DETAILS";
            this.isStoreTitle = false; 
            break;
            case "immunizationdashboard":
            this.headerTitle = "IMMUNIZATION DASHBOARD";
            this.isStoreTitle = true; 
            SessionDetails.setIsPCA(false); SessionDetails.setIsSR(false);
            this.alertService.sendWarningAlert(true,false,false);
            break;
            case "home":
              this.headerTitle = "COMMUNITY OUTREACH";
              this.isStoreTitle = true; 
              SessionDetails.setIsPCA(false); SessionDetails.setIsSR(false);
              this.alertService.sendWarningAlert(true,false,false);
              break;
            case "pcadashboard":
            this.headerTitle = "PATIENT CARE & ADVOCACY DASHBOARD";
            this.isStoreTitle = true; 
            SessionDetails.setIsPCA(true);SessionDetails.setIsSR(false);
            this.isClientRequired = true;
            this.alertService.sendWarningAlert(false,false,true);
            break;
            case "rhdContractAgreement":
            case "ASO Lula":
            case "Government LULA":
            case "OPO Lula":
            case "CSS Lula":
            this.headerTitle = "LIMITED USE LICENSE AGREEMENT";
            this.isStoreTitle = false; 
            this.isClientRequired = false;
            this.alertService.sendWarningAlert(false,false,true);
            break;
          default:
            this.headerTitle = "COMMUNITY OUTREACH";
            this.isStoreTitle = true;
            this.isShowHeaderFooter = true;
            break;
        }
      }
    });
    this.userProfile = SessionDetails.GetUserProfile();
    this.onSelection = false;    
    this.subscription = this.message_service.getProfileChange().subscribe((message:any) => {
      this.message = message;
      if (this.message.text != "") {
        this.getStoreAddress(this.userProfile.userPk, null, SessionDetails.GetStoreId());
      }
    });

    if (this.router.url === '/communityoutreach/viewcontactlog') {
      this.isPowerUser = false;
      this.isStoreTitle = false;
    }    
    // need to revisit this    
    if(sessionStorage.store_profile)
    {
      this.state = JSON.parse(sessionStorage.store_profile)[0].state
    }
  }

  ngOnInit() {  
    this.alertService.ChangecorpStoreApptTrackerBannerColor$.subscribe((res)=>
    {     
        this.ChangecorpStoreApptTrackerBannerColor = res;
    })   
    this.onSelection = false;
    this.GetMenu();
    this.storeID = SessionDetails.GetStoreId(); 
    this.getStoreAddress(this.userProfile.userPk, null, this.storeID == null ? null : this.storeID);
    this.menu[3].navigationItems[0].navigationItems = this.menu[3].navigationItems[0].navigationItems.sort(function (a, b) {
      var textA = a.title.toLowerCase();
      var textB = b.title.toLowerCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

  //   if (sessionStorage.getItem('configDates') == null) {
      
  //     //For local debugging purpose only
  // sessionStorage.setItem('configDates',
  //  `[{\"key\":\"srSeason\",\"startDate\":\"2019-09-01T00:00:00\",
  //  \"endDate\":\"1901-01-01T00:00:00\",\"isActive\":true,\"remarks\":null,
  //  \"createdDate\":\"2020-01-03T14:29:16.937\",\"createdUser\":41,
  //  \"modifiedDate\":null,\"modifiedUser\":null},
  //  {\"key\":\"IPSeason\",\"startDate\":\"2018-05-01T00:00:00\",
  //  \"endDate\":\"1901-01-01T00:00:00\",\"isActive\":true,\"remarks\":null,
  //  \"createdDate\":\"2020-01-03T14:29:16.937\",\"createdUser\":41,
  //  \"modifiedDate\":null,\"modifiedUser\":null},
   
  //  {\"key\":\"moRestricted\",\"startDate\":\"12/01/2021\",
  //  \"endDate\":\"02/01/2022\",\"isActive\":true,\"remarks\":null,
  //  \"createdDate\":\"2020-01-03T14:29:16.937\",\"createdUser\":41,
  //  \"modifiedDate\":null,\"modifiedUser\":null},
   
  //  {\"key\":\"dcRestricted\",
  //  \"startDate\":\"4/10/2022\",\"endDate\":\"02/01/2023\",
  //  \"isActive\":true,\"remarks\":null,\"createdDate\":\"2020-01-03T14:29:16.937\",
  //  \"createdUser\":41,\"modifiedDate\":null,\"modifiedUser\":null},
  //  {\"key\":\"outreach\",\"startDate\":\"2019-09-01T00:00:00\",
  //  \"endDate\":\"1901-01-01T00:00:00\",\"isActive\":true,\"remarks\":null,
  //  \"createdDate\":\"2020-01-03T14:29:16.937\",\"createdUser\":41,\"modifiedDate\":null,
  //  \"modifiedUser\":null}]`)

  //     //  this._opportunityService.getConfigDates().subscribe((data:any) => {
  //     //    sessionStorage.setItem('configDates', JSON.stringify(data));
  //     //  })
  //   };
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  formatter = (result: searchResult) => 
    result.storeId + " - " +
    result.address + ", " + result.city + ", "
    + result.state + " " + result.zip || ''
    
    search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      //filter(term => term.length >= 2),
      tap(() => this.searching = true),
      switchMap(term =>
        this._headerservice.storeSearch(this.userProfile.userPk, String(term).trim(), 0).pipe(
          tap(() => {}),
          catchError((err) => {
            //this.searchFailed = true;]
            console.log('search crashed.' + err);
            //this.siteMaintenanceDlg = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );



  getStoreAddress(user_pk, store_search, store_id) {    
    let url = this.router.url;
    if (url != null && url != "") {
      let data = url.split("/");
      if (data != null) {
        if (data[2] == "home") {
          this.dashboard = true;
        }
      }
    }
    this._headerservice.getStoresProfile(user_pk, store_search, store_id).subscribe((res:any) => {      
      this.store_profile = res.data;
      SessionDetails.setStoreProfileData(res.data);
      SessionDetails.StoreId(this.store_profile[0].storeId);
      SessionDetails.StorePk(res.data[0].storePk);
      SessionDetails.setState(this.store_profile[0].state);
      this.storeAddress =
        this.store_profile[0].storeId + " - " +
        this.store_profile[0].address + ", " + this.store_profile[0].city + ", "
        + this.store_profile[0].state + " " + this.store_profile[0].zip;
      this.lastAccess = this.store_profile[0].lastStatusProvided;
      if (this.onSelection) {
        this.message_service.sendStoreId(this.store_profile[0].storeId);
      }
      if (this.onSelection && this.dashboard) {
        this.message_service.sendDashboard(this.store_profile[0].storeId);
      }
      this.onSelection = false;
    });
  }
  onSelect(item) {    
    this.onSelection = true;
    this.model = item.item.name;
    //SessionDetails.StoreId(item.item.storeId);
    this.getStoreAddress(this.userProfile.userPk, null, item.item.storeId);
    this.visible = false;
    this.showVar = false;
  }
  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  storeSearch() {
    this.model = '';
    this.showVar = !this.showVar;
    setTimeout(() => {
      this.storeSearchEleRef.nativeElement.focus();
    }, 100);
  }
  onClickedOutside(e: Event) {
    this.isStoreTitle = true;
    if (this.titleName == 'contract') {
      this.isAdmin = false;
    }
    this.showVar = false;
  }
  GetMenu() {
   
    let role_based_menu: any = [];
    let temp_menu = this._headerservice.getCommonMenuItems();
    let tmp_parent_menu: any = {};
    for (let i = 0; i < temp_menu.length; i++) {
      if (temp_menu[i].forRoles.indexOf(this.userProfile.userRole) > -1 || temp_menu[i].forRoles.indexOf("all") > -1) {
        tmp_parent_menu = {};
        tmp_parent_menu["title"] = temp_menu[i].title;
        //tmp_parent_menu["active"]=temp_menu[i].active;
        tmp_parent_menu["url"] = temp_menu[i].url;
        tmp_parent_menu["navigationItems"] = [];
        if (temp_menu[i].navigationItems) {
          for (let j = 0; j < temp_menu[i].navigationItems.length; j++) {
            if (temp_menu[i].navigationItems[j].forRoles.indexOf(this.userProfile.userRole) > -1 || temp_menu[i].navigationItems[j].forRoles.indexOf("all") > -1) {
              let temp_item: any = {};
              temp_item["title"] = temp_menu[i].navigationItems[j].title;
              temp_item["url"] = temp_menu[i].navigationItems[j].url;
              let sub_nav_items: any = {};
              if (temp_menu[i].navigationItems[j].navigationItems) {
                for (let k = 0; k < temp_menu[i].navigationItems[j].navigationItems.length; k++) {
                  if (temp_menu[i].navigationItems[j].navigationItems[k].forRoles.indexOf(this.userProfile.userRole) > -1 
                        || temp_menu[i].navigationItems[j].navigationItems[k].forRoles.indexOf("all") > -1 
                        || ['Data Stats Reporting'].indexOf(temp_menu[i].navigationItems[j].navigationItems[k].title) > -1 
                        || ['Opportunities Contact Status'].indexOf(temp_menu[i].navigationItems[j].navigationItems[k].title) > -1 
                            && [41].indexOf(this.userProfile.userPk) > -1) {
                    if (!sub_nav_items.navigationItems) {
                      sub_nav_items["navigationItems"] = [];
                    }
                    sub_nav_items["navigationItems"].push({
                      "title": temp_menu[i].navigationItems[j].navigationItems[k].title.replace('{role_title}', this.getRoleTitleForReports(this.userProfile.userRole)),
                      "url": temp_menu[i].navigationItems[j].navigationItems[k].url
                    });
                  }
                }
              }
              if (sub_nav_items.navigationItems && sub_nav_items.navigationItems.length > 0) {
                temp_item["navigationItems"] = sub_nav_items.navigationItems;
              }
                  
              if (['Dropped Store Reassignments', 'Immunizations & Rates', 'Store Profile Uploader', 'Group Id Uploader'].indexOf(temp_item["title"]) > -1 && 
              [41, 20896].indexOf(this.userProfile.userPk) > -1) {
                tmp_parent_menu["navigationItems"].push(temp_item);
              } else if(['Dropped Store Reassignments', 'Immunizations & Rates', 'Store Profile Uploader','Group Id Uploader'].indexOf(temp_item["title"]) === -1){
                tmp_parent_menu["navigationItems"].push(temp_item);
              }
            }
          }
        }
        role_based_menu.push(tmp_parent_menu);
      }
    }
    this.menu = role_based_menu;
    // this.menu[3].navigationItems[0].navigationItems = this.menu[3].navigationItems[0].navigationItems.sort(function (a, b) {
    //   var textA = a.title.toLowerCase();
    //   var textB = b.title.toLowerCase();
    //   return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    // });
    //this.menu = this._headerservice.getCommonMenuItems();

    switch (this.userProfile.userRole.toLowerCase()) {
      case "admin":
        this.isAdmin = true;
        break;
      case "store manager":
        this.displayName = "Store";
        break;
      case "pharmacy manager":
        this.isAdmin = false;
        break;
      case "district manager":
        this.isPowerUser = true;
        this.isAdmin = false;
        this._headerservice.getStoresProfile(this.userProfile.userPk, null, null).subscribe((res:any) => {
          this.storeInfo = res.data;
        });
        this.displayName = this.userProfile.locationType;
        this.displayNumber = this.userProfile.assignedLocations;
        break;
      case "director - rx and retail ops":
        this.isPowerUser = true;
        this.isAdmin = false;
        this._headerservice.getStoresProfile(this.userProfile.userPk, null, null).subscribe((res:any) => {
          this.storeInfo = res.data;
        });
        this.displayName = this.userProfile.locationType;
        this.displayNumber = this.userProfile.assignedLocations;
        break;
      case "healthcare supervisor":
        this.isPowerUser = true;
        this.isAdmin = false;
        this._headerservice.getStoresProfile(this.userProfile.userPk, null, null).subscribe((res:any) => {
          this.storeInfo = res.data;
        });
        this.displayName = this.userProfile.locationType;
        this.displayNumber = this.userProfile.assignedLocations;
        break;
      case "regional vice president":
      case "regional healthcare director":
        this.isPowerUser = true;
        this.isAdmin = false;
        this._headerservice.getStoresProfile(this.userProfile.userPk, null, null).subscribe((res:any) => {
          this.storeInfo = res.data;
        });
        this.displayName = this.userProfile.locationType;
        this.displayNumber = this.userProfile.assignedLocations;
        break;

      case (UserRole.PODUSER):
        this.isPowerUser = true;
        this.isAdmin = false;
        this._headerservice.getStoresProfile(this.userProfile.userPk, null, null).subscribe((res:any) => {
          this.storeInfo = res.data;
        });
        this.displayName = this.userProfile.locationType;
        this.displayNumber = this.userProfile.assignedLocations;
        break;
      default:
        break;
    }
  }

  getRoleTitleForReports(user_role: string) {
    var title = "";
    switch (user_role) {
      case "Regional Healthcare Director":
      case "Regional Vice President":
        title = "Region ";
        break;
      case "Healthcare Supervisor":
      case "Director - Rx and Retail Ops":
        title = "Area ";
        break;
      case "Pod Healthcare Supervisor":
        title = "Pod ";
        break;
      case "District Manager":
        title = "District ";
        break;
      default:
        title = "";
        break;
    }
    return title;
  }

  getTitleForDashboardReports(forCovid:boolean=true) {
    var title = "";
    let rpt_id: string = "";
    let report_type: string = ""
    if (sessionStorage["actionItemOrMetricId"]) {
      let item_details = sessionStorage["actionItemOrMetricId"].split(':');
      if (item_details.length > 0) {
        report_type = item_details[0];
        rpt_id = item_details[1];
      }
    }
    switch (rpt_id) {
      case "1":
        title = ((report_type == "actionitem") ? "Corporate Clinics to be Reviewed/Assigned" : "Review Clinics Completed to Date");
        break;
      case "2":
        title = "Review Clinics Pending Signed Contract";
        break;
      case "3":
        title = ((report_type == "actionitem") ? "Local Leads to be Reviewed/Assigned" : "Review Contracts Drafted but Not Sent");
        break;
      case "4":
        title = ((report_type == "actionitem") ? "Corporate Clinics to be Confirmed" : "Review Clinics with Signed Contract to be Completed");
        break;
      case "5":
        title=((report_type=="actionitem")?"Corporate Clinics to be Completed":"Review Contracts with Requested Changes");
        break;
        case "6":
        if(!forCovid){
          title = ((report_type == "actionitem") ? "Clinics to be Reviewed/Assigned" : "Review LTCFs Not Contacted");
        }
        else {
          title = ((report_type == "actionitem") ? "COVID Clinics to be Reviewed/Assigned" : "Review LTCFs Not Contacted");
        }
        
        break;
      case "7":
        if(!forCovid){
        title = ((report_type == "actionitem") ? "Clinics to be Confirmed" : "Review Clinics Completed to Date");
      }
      else {
        title = ((report_type == "actionitem") ? "COVID Clinics to be Confirmed" : "Review COVID Clinics Completed to Date");
      }
        break;
      case "8":
        if(!forCovid){
        title = ((report_type == "actionitem") ? "Clinics to be Completed" : "Review Clinics Pending Signed Contract");
        }
        else {
        title = ((report_type == "actionitem") ? "COVID Clinics to be Completed" : "Review COVID Clinics Pending Signed Contract");
        }
        break;
        case "9":
           if(report_type == "actionitem"){
            title = "Previous Clients to be Contacted";
            }
        else if(!forCovid){
        title = ((report_type == "actionitem") ? "" : "Review Contracts Drafted but Not Sent");
        }
       
        else {
        title = ((report_type == "actionitem") ? "" : "Review COVID Contracts Drafted but Not Sent");
        }
        break;
        case "10":
        if(!forCovid){
        title = ((report_type == "actionitem") ? "" : "Review Clinics with Signed Contracts to be Completed");
        }
        else {
        title = ((report_type == "actionitem") ? "" : "Review COVID Clinics with Signed Contracts to be Completed");
        }
        break;
        case "11":
        title = ((report_type == "actionitem") ? "" : "Review COVID Contracts with Requested Changes");
          break;
        case "12":
        title = ((report_type == "actionitem") ? "" : "Review COVID LTCFs Not Contacted");
        break;
      default:
        break;
    }
    return title.toUpperCase();
  }

  selectDropdownStore(selectedData: any) {    
    this.onSelection = true;
    this.getStoreAddress(this.userProfile.userPk, null, selectedData);
  }
  navigateToLink(link_info: any,title:any) {
 
    if (link_info.title == "Immunization Program" || title == "Immunization Program") {
      this.alertService.sendWarningAlert(true, false, false);
    }
    else if (link_info.title == "Senior Outreach" || title == "Senior Outreach") {
      this.alertService.sendWarningAlert(false, true, false);
    }
    else if (link_info.title == "Patient Care & Advocacy" || title == "Patient Care & Advocacy") {
      this.alertService.sendWarningAlert(false, false, true);
    } 
    else if (link_info.title == "Store") {
      if (sessionStorage.is_pca == "false" && sessionStorage.is_sr == "false") {
        this.isIP = true; 
        this.isSR = false; 
        this.isPCA = false;
        SessionDetails.setIsPCA(false); 
        SessionDetails.setIsSR(false);
      } else if (sessionStorage.is_pca == "true" && sessionStorage.is_sr == "false") {
        this.isPCA = true; 
        this.isSR = false; 
        this.isIP = false;
        SessionDetails.setIsPCA(true);
        SessionDetails.setIsSR(false);
      } else if (sessionStorage.is_pca == "false" && sessionStorage.is_sr == "true") {
        this.isPCA = false; 
        //this.isSR = true; 
        this.isIP = false;
        SessionDetails.setIsPCA(false); SessionDetails.setIsSR(true);
      }
    }
    
 

    this.message_service.setNavigationClicked(false);
    if (link_info.url) {
      this.router.navigate([link_info.url]);
    }
    else {
      this.router.navigate([null]);
    }
  }
  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = ["../../../../../assets/js/scripts.js"];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    }
  }
}

