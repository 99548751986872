import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { SessionDetails } from '../../../utility/session';
import { CorporateTrackerSchedulerClinics } from '../../../models/CorporateTrackerSchedulerClinics';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Directive()
@Injectable()
export class PreviewService {

	BannerColor: string = '';
	BannerTextColor: string = '#ffffff';
	ButtonColor: string = '#02a0c0';
	ButtonTextColor: string = '#ffffff';
	landingPageHeaderText: string = '';
	landingPageBodyText: string = '';
	landingPageFooterText: string = '';
	@Output() change: EventEmitter<string> = new EventEmitter();

	constructor(private _http: HttpClient) {

	}

	SetBannerColor(bannerColor: string) {
		this.BannerColor = bannerColor;
	}
	GetBannerColor() {
		return this.BannerColor;
	}
	getAppointmentTrackingCounts(client_id:number,clinic_pk:number){
		let url = environment.API_URL + "/api/JsCorporateApptsCounts/" + client_id + "/" + clinic_pk;
		return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}

	getAppointmentTrackingDetails(corporate_tracker_sch_clinics:CorporateTrackerSchedulerClinics){
		let url = environment.API_URL + "/api/JsCorporateAppointments_get";
		return this._http.post(url, corporate_tracker_sch_clinics, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}
	getCorporateSchedulerClinics(client_pk) {
		let url = environment.API_URL + environment.RESOURCES.GET_CORPORATE_SCHEDULER_CLINICS + "/" + client_pk;
		return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}
	getCorporateClientList() {
		let url = environment.API_URL + environment.RESOURCES.GET_CORPORATE_CLIENTS;
		return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}
	getSchedulerSiteDesign(client_id) {
		let url = environment.API_URL + environment.RESOURCES.GET_SCHEDULER_SITE_DESIGN + "/" + client_id;
		return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}
	updateSchedulerClientSetUp(scheduler_site_design,is_block_appts) {
		let url = environment.API_URL + environment.RESOURCES.UPDATE_SCHEDULER_CLIENT_SETUP+"/"+is_block_appts;
		return this._http.post(url, scheduler_site_design, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}
	imageUpload(upload_image) {
		let headers: Headers = new Headers();
		let url = environment.API_URL + environment.RESOURCES.CLINIC_UPLOAD_IMAGE;
		let userData = SessionDetails.GetUserInfo();
		//let token = btoa(userData.userName + ':' + userData.password);
                let usernamePassword = SessionDetails.getE(SessionDetails.absJ,userData.userName + ':'+ userData.password);                    
        let req_headers = new HttpHeaders()
        .set('Authorization','Basic '+ usernamePassword);

		const httpOptions = {
            headers: req_headers
          };
		
		return this._http.post(url, upload_image, httpOptions).pipe(catchError(Util.handleErrorV2));
	}

	blockUnblockAppts(is_block_appts:boolean,block_unblock_obj:any) {
		let url = environment.API_URL + "/api/SchedulerEeApptDetails_blockUnblockScheduledEeAppts/"+is_block_appts;
		//return this._http.post(url, block_unblock_obj, Util.getRequestHeaders()).map((response: Response) => response.json());
		return this._http.post(url,block_unblock_obj, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));;
	}

	getClinicInfoForTracker(store_id:number, business_pk:number, clinic_pk:number) {
		let url = environment.API_URL + "/" +environment.RESOURCES.GET_CLINIC_INFO_FOR_TRACKER + "/" + store_id + "/" + business_pk + "/" + clinic_pk; 
		return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}
}
