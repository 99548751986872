export class OpportunitiesList {
    businessPk: number;
    outreachBusinessPk: number;
    storeId: number;
    lastContactDate: Date;
    outreachStatusId: number;
    isStatusUpdated: boolean;
    latitude: number;
    longitude: number;
    locationId: number;
    assignToDistrict: number;
}

export class LocalLeadsSave {
    userPk: number;
    assignedTo: number;
    updateAction: number;
    isAssignAllInDistrict: boolean;
    assignToDistrict: number;
    override_sr_events:boolean;
    opportunitiesList: OpportunitiesList[];
    disposition: string;
}

export class recaptchaRequest {
    secret:string = '6LeKNuIUAAAAAM1PI5hdJEKntORMP9statIe1H1N';
    response:string;    
}



