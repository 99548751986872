import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import * as _ from "lodash";
import { Util } from '../../../../utility/util';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { NgxSpinnerService } from "ngx-spinner";
import { UnsubscribeEmailData } from '../../../../models/UnsubscribeEmailData';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UnsubscribeService } from '../../services/unsubscribe.service';


@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {
  templateName: any;
  emailId: string;
  isShown: boolean = false;
  alertMessage: string = `Sorry to hear you're thinking of leaving us.`;
  successMessage: string;


  constructor(private route: ActivatedRoute, private unsubscribeService : UnsubscribeService) {
    this.isShown = true;
  }
  ngOnInit() {
    this.isShown = false;
    this.route.queryParamMap.subscribe(queryParams => {
      this.templateName = queryParams.get("templateName");
      this.emailId = queryParams.get("emailId");
      this.successMessage = `If you would no longer like to receive email notifications at <b>${this.emailId || ""}</b> from the Walgreens Community Outreach Program, click the Unsubscribe button below.`;
    })
  }



  unsubscribeemail() {


    // here need to pass templateName and EmailId as String List
    
    var inputData = new UnsubscribeEmailData();
    inputData.EmailId = this.emailId;
    inputData.TemplateName = this.templateName;


    this.unsubscribeService.UnsubscribeEmailGroup(inputData).subscribe((data) => {
      if (data == 'Unsubscribed') {
        this.isShown = true;
    this.alertMessage = 'Unsubscribe successful';
    this.successMessage = 'Your email has been successfully unsubscribed from this list!';
  }
      else {
        this.alertMessage = 'Unsubscribe Failed';
      }

    });

  }
}
