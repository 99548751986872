// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container .panel-default {
    width: 407px;
    margin-left: 300px;
    margin-top: 200px;
    padding: 25px;
    border-radius: 10px;
    background-color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/components/login/login.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".container .panel-default {\r\n    width: 407px;\r\n    margin-left: 300px;\r\n    margin-top: 200px;\r\n    padding: 25px;\r\n    border-radius: 10px;\r\n    background-color: black;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
