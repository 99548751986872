export class ImmunizationFinance {
    fromDate:string;
    toDate:string;
    isCurrentSeason:number;
    ipSeason:number;
    includeDates:boolean;
    reportName:string = null;
    outputType:string = null;
    covidOnly:boolean;
    constructor(from_date:string,to_date:string,is_current_season:number,ip_season:number,include_dates:boolean,covid_only:boolean,report_name?: string,output_type?: string) {
        this.fromDate = from_date;
        this.toDate = to_date;
        this.isCurrentSeason = is_current_season;
        this.ipSeason = ip_season;
        this.includeDates = include_dates;
        this.outputType = output_type;
        this.reportName = report_name;
        this.covidOnly = covid_only;
    }
}