export class WeeklyVaccinePurchasing {
    fromDate: string;
    toDate: string;
    fileName:string;
    isCurrentSeason: number;
    reportName: string = "";
    outputType: string = "";
    vaccineType: string;
    covidOnly:boolean;
    constructor(from_date: string, to_date: string, file_name: string, is_current_season: number, report_name: string, output_type: string, vaccine_type:string,covidOnly:boolean) {
        this.fromDate = from_date;
        this.toDate=to_date;
        this.fileName = file_name;
        this.isCurrentSeason=is_current_season;
        this.reportName=report_name;
        this.outputType = output_type;
        this.vaccineType = vaccine_type;
        this.covidOnly=covidOnly
    }
}