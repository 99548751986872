
import {interval as observableInterval,  Observable, interval } from 'rxjs';

import {take} from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { states } from '../../../../JSON/States';
import { Util } from '../../../../utility/util';
import { SchedulerregistrationService } from '../../services/schedulerregistration.service';
import { schedulerRegistrationDetails, DetailList } from '../../../../models/schedulerRegistrationDetails';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { Router } from '@angular/router';
import { SessionDetails } from '../../../../utility/session';
import { defaultSiteDesignInfo } from '../../../../JSON/schedulerDefaultValues';
import { environment } from '../../../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { TimeToDatePipe } from '../../../contractaggreement/pipes/time-to-date.pipe';
import { ArrayType } from '@angular/compiler';

@Component({
  selector: 'app-walgreens-scheduler-registration',
  templateUrl: './walgreens-scheduler-registration.component.html',
  styleUrls: ['./walgreens-scheduler-registration.component.css'],
  providers: [DatePipe,TimeToDatePipe]
})
export class WalgreensSchedulerRegistrationComponent implements OnInit {
  dateValue: Date;
  schedulerRegistrationFormGroup: FormGroup;
  displayFormData: any;
  controlsArray: FormArray;
  headers: Array<string>;
  states: any = [];
  row1Data: any = [];
  row2Data: any = [];
  row3Data: any = [];
  row4Data: any = [];
  row5Data: any = [];
  selectedValues: any = {};
  selectedValuesRaw: schedulerRegistrationDetails;
  fieldsInfo: any = {};
  clinicLocationsList: any = [];
  apptDatesList: any = [];
  apptImmunizationsList : any = [];
  editimmunizationdata: any = [];
  selectedImmuzvalues: any = [];
  apptTimesOfDay: any = [];
  apptTimesOfDayList: any = [];
  apptTimesList: any = [];
  apptRoomsList: any = [];
  selectvalues: any = [];
  selectedLocationDateGroupId: number;
  selectedLocationId: number;
  private apptPk: number = -1;
  private clinicPk:number = -1;
  private clientId: number;
  clientLogo: string = "";
  siteDesignInfo: any = {};
  errorMessages: any = {};
  alaSQL: any;
  dialogSummary: string;
  dialogMsg: string;
  errorPopUp: boolean = false;
  isDisable: boolean = null;
  isSelected: boolean = false;
  selectedlocationvalue: string = '';
  @Input() inputArray: ArrayType[];
  private group = this.formBuilder.group({});
  private schedulerUserInfo: any;
  selectedImmunizations: { clinicPk: number; immunizationPk: number }[] = [];
  name_pattern = /^(?!\d+$)(?:[a-zA-Z \.\,\'\-]*)?$/;
  email_pattern = /^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/;
  //email_pattern = /(?=^.{1,50}$)^[a-zA-Z0-9][a-zA-Z0-9.!@#$%&*+_-]+[a-zA-Z0-9]@[a-zA-Z0-9][a-zA-Z0-9._-]+\.[a-zA-Z]{2,3}$/;
  constructor(private formBuilder: FormBuilder, private utility: Util, private element: ElementRef,
    private scheduler_registration: SchedulerregistrationService, private router: Router,
     private spinner: NgxSpinnerService, private _datePipe: DatePipe,
     private _timeToDatePipe: TimeToDatePipe) {
    this.schedulerUserInfo = SessionDetails.getSchedulerUserInfo();
    this.selectedValuesRaw = new schedulerRegistrationDetails();
    this.selectedValuesRaw.detailList.push(new DetailList());
    this.alaSQL = require('alasql');
    this.apptPk = -1;//2;1;
    this.clientId = -1;// 12;
    if (this.schedulerUserInfo) {
      this.clientId = this.schedulerUserInfo.designPk;
      this.apptPk = this.schedulerUserInfo.appointmentPK;
    } else {
      this.schedulerUserInfo = {
        "designPk": -1,
        "redirectTo": "schedulerRegistration",
        "apptPk": -1,
        "clinicPK": -1
      };
      // if (sessionStorage["scheduler_site_set_up"]) {
      //   this.clientId = JSON.parse(sessionStorage["scheduler_site_set_up"]).clientInfo.clientId;
      //   this.schedulerUserInfo.designPk=this.clientId;
      // }
    }
    if (sessionStorage["registrationDetails"]) {
      var regDetails= JSON.parse(sessionStorage["registrationDetails"]);
      this.apptPk = regDetails.apptPk;
      this.schedulerUserInfo.appointmentPK = this.apptPk;
      this.schedulerUserInfo.clinicPK = regDetails.clinicPk;
    }
    this.scheduler_registration.getSchedulerSiteDesign(this.schedulerUserInfo.designPk).subscribe((result:any) => {
      if (Object.keys(result).length > 0) {
        if (!result.clientInfo.enryptedClientKey) {
          result.siteLogoAndStyles = defaultSiteDesignInfo.defaultDesignValues;
          result.siteLandingPageText = defaultSiteDesignInfo.defaultTextValues;
        }
        this.siteDesignInfo = result;
        this.clientLogo = ((this.siteDesignInfo.siteLogoAndStyles && this.siteDesignInfo.siteLogoAndStyles.logoFile &&
          this.siteDesignInfo.siteLogoAndStyles.logoFile !== "string") ? environment.RESOURCES.CORPORATE_SCHEDULER_UPLOADED_LOGOS_URL + this.siteDesignInfo.siteLogoAndStyles.logoFile : '');
        this.displayFormData = this.siteDesignInfo.siteRequiredRegisFieldList;
        this.displayFormData.push(
          {
            "fieldName": "clinicState",
            "displayName": "State",
            "isFieldRequired": 1,
            "isFieldVisible": 1
          }, {
            "fieldName": "clinicLocation",
            "displayName": "Location",
            "isFieldRequired": 1,
            "isFieldVisible": 1
          }, {
            "fieldName": "apptDate",
            "displayName": "Date",
            "isFieldRequired": 1,
            "isFieldVisible": 1
          }, {
            "fieldName": "apptTimeOfDay",
            "displayName": "Time of Day",
            "isFieldRequired": 1,
            "isFieldVisible": 1
          }, {
            "fieldName": "apptTime",
            "displayName": "Available Times",
            "isFieldRequired": 1,
            "isFieldVisible": 1
          }, {
            "fieldName": "apptRoom",
            "displayName": "Available Rooms",
            "isFieldRequired": 1,
            "isFieldVisible": 1
          },{
            "fieldName": "selectedImmunization",
            "displayName": "immunization",
            "isFieldRequired": 1,
            "isFieldVisible": 1
          });
      }
      this.displayFormData.forEach(control => {
        switch (control.fieldName) {
          case "firstName":
          case "middleName":
            let is_name_required = ((control.isFieldRequired) ? Validators.required : null);
            this.group.addControl(control.fieldName, this.formBuilder.control('', Validators.compose([is_name_required, Validators.maxLength(20), Validators.pattern(this.name_pattern)])));
            break;
            case "lastName":
            let l_name_pattern = /^[a-zA-Z]{1,1}$/;
            let l_is_name_required = ((control.isFieldRequired) ? Validators.required : null);
            this.group.addControl(control.fieldName, this.formBuilder.control('', Validators.compose([l_is_name_required, Validators.pattern(l_name_pattern)])));
            break;
          case "email":
          case "Email":
            control.fieldName = control.fieldName.toLowerCase();
            let is_email_required = ((control.isFieldRequired) ? Validators.required : null);
            this.group.addControl(control.fieldName, this.formBuilder.control('', Validators.compose([is_email_required, Validators.maxLength(50),Validators.pattern(this.email_pattern)])));
            break;
          case "employeeId":
            let employee_id_pattern = /(?=^.{1,500}$)^[a-zA-Z0-9-]/;
            let employee_id_required = ((control.isFieldRequired) ? Validators.required : null);
            this.group.addControl(control.fieldName, this.formBuilder.control('', Validators.compose([employee_id_required, Validators.pattern(employee_id_pattern)])));
            break;
          case "clinicState":
          case "clinicLocation":
          case "apptDate":
          case "apptTime":
          case "apptTimeOfDay":
          case "apptRoom":
            let is_required = ((control.isFieldRequired) ? Validators.required : null);
            this.group.addControl(control.fieldName, this.formBuilder.control('', Validators.compose([is_required])));
            break;
          case "selectedImmunization": 
             this.createSelectImmunization(control);
          break;
        }
          
      });
      this.createFormElementsArray();
    });

    this.scheduler_registration.getSchedulerClinicLocations(this.clientId, this.apptPk).subscribe((clinic_locations_results:any) => {
      if (clinic_locations_results&&clinic_locations_results.clinicStateList.length > 0) {
        this.fieldsInfo = clinic_locations_results;
        if (this.fieldsInfo.clinicStateList.length == 1 && this.apptPk == -1) {
          let obs = interval(50).pipe(take(1));
          var t1 = this.selectedValuesRaw;
          // this.selectedValues.apptRoom =  t1[0].apptRoom;
          // this.schedulerRegistrationFormGroup.get('apptRoom').setValue(t1[0].apptRoom);
          // this.selectedValues.apptTime = t1[0].apptTime;
          // this.schedulerRegistrationFormGroup.get('apptTime').setValue(t1[0].apptTime);
          // this.selectedValues.apptDate = t1[0].apptDate;
          // //this.selectedValues.apptDate = this.schedulerRegistrationFormGroup.get('apptDate').value;
          // this.schedulerRegistrationFormGroup.get('apptDate').setValue(t1[0].apptDate);
          // this.selectedValues.apptTimeOfDay = t1[0].apptTimeOfDay;
          // this.schedulerRegistrationFormGroup.get('apptTimeOfDay').setValue(t1[0].apptTimeOfDay);
          // this.selectedValues.clinicLocation = t1[0].clinicLocation;
          // this.schedulerRegistrationFormGroup.get('clinicLocationt').setValue(t1[0].clinicLocation);
          // this.selectedValues.clinicState = t1[0].clinicState;
          // this.schedulerRegistrationFormGroup.get('clinicState').setValue(t1[0].clinicState);
          // this.selectedValues.email = t1[0].email;
          // this.schedulerRegistrationFormGroup.get('email').setValue(t1[0].email);
          // this.selectedValues.employeeId = t1[0].employeeId;
          // this.schedulerRegistrationFormGroup.get('employeeId').setValue(t1[0].employeeId);
          // this.selectedValues.firstName = t1[0].firstName;
          // this.schedulerRegistrationFormGroup.get('firstName').setValue(t1[0].firstName);
          // this.selectedValues.lastName = t1[0].lastName;
          // this.schedulerRegistrationFormGroup.get('lastName').setValue(t1[0].lastName);
          // this.selectedValues.middleName = t1[0].middleName;
          // this.schedulerRegistrationFormGroup.get('middleName').setValue(t1[0].middleName);
          // this.selectedValues.clinicStoreId = t1[0].clinicStoreId;
          // this.selectedValues.clinicPk=t1[0].clinicPk;
          // this.selectedValues.clinicLocationId=t1[0].clinicLocationId;
          
          var t2 = this.selectedValues;
          var t3 = this.fieldsInfo.clinicStateList;
          let schRegFormGroup:FormGroup = this.schedulerRegistrationFormGroup;
          obs.subscribe(function (value) {
            t1.detailList[0].clinicState = t3[0];
            t2.clinicState = t3[0];
            schRegFormGroup.get('clinicState').setValue(t2.clinicState);
          });
          setTimeout(() => {
            this.loadClinicLoctions(t2.clinicState);
          }, 1000);
        }
      }
      if (this.apptPk) {
        this.scheduler_registration.getSchedulerApptDetails(this.apptPk).subscribe((appt_details_results:any) => {
          sessionStorage.removeItem("registrationDetails");
          if (appt_details_results.detailList.length > 0) {
            this.selectedValuesRaw = appt_details_results;
            this.selectedValues = appt_details_results.detailList[0];
            this.selectedImmuzvalues = this.fieldsInfo.sCLMScheduleImmunizationLists.filter(item => item.clinicPk === this.selectedValues.clinicPk  && item.estimatedQuantity > 0) ;
            this.editimmunizationdata = this.selectedValues.SEADImmunizationDetailByApptPK;
            this.selectedImmuzvalues = this.selectedImmuzvalues.map(item => ({
              ...item,
              isSelected: this.editimmunizationdata.some(imm => item.immunizationPk === imm.immunizationPk)
            }));          
            this.selectedImmunizations = this.selectedImmuzvalues.filter(immunization => immunization.isSelected).map(immunization => ({
              clinicPk: immunization.clinicPk,
              immunizationPk: immunization.immunizationPk
          }));
            this.schedulerRegistrationFormGroup.get('apptRoom').setValue(this.selectedValues.apptRoom);
            this.schedulerRegistrationFormGroup.get('apptTime').setValue(this.selectedValues.apptTime);
            this.schedulerRegistrationFormGroup.get('apptDate').setValue(this.selectedValues.apptDate);
            this.schedulerRegistrationFormGroup.get('apptTimeOfDay').setValue(this.selectedValues.apptTimeOfDay);
            this.schedulerRegistrationFormGroup.get('clinicLocation').setValue(this.selectedValues.clinicLocation);
            this.schedulerRegistrationFormGroup.get('clinicState').setValue(this.selectedValues.clinicState);
            this.schedulerRegistrationFormGroup.get('email').setValue(this.selectedValues.email);
          
            const immunizationArray = this.schedulerRegistrationFormGroup.get('selectedImmunization') as FormArray;
            immunizationArray.clear();
            this.selectedImmuzvalues.forEach(immunization => {
              immunizationArray.push(this.formBuilder.control(immunization.isSelected));
            });
      
            if(this.selectedValues.employeeId && this.schedulerRegistrationFormGroup.get('employeeId')) {
              this.schedulerRegistrationFormGroup.get('employeeId').setValue(this.selectedValues.employeeId);
            }
            if(this.selectedValues.firstName && this.schedulerRegistrationFormGroup.get('firstName')) {
              this.schedulerRegistrationFormGroup.get('firstName').setValue(this.selectedValues.firstName);
            }
            if(this.selectedValues.lastName && this.schedulerRegistrationFormGroup.get('lastName')) {
              this.schedulerRegistrationFormGroup.get('lastName').setValue(this.selectedValues.lastName);
            }
            if(this.selectedValues.middleName && this.schedulerRegistrationFormGroup.get('middleName')) {
              this.schedulerRegistrationFormGroup.get('middleName').setValue(this.selectedValues.middleName);
            }
            //if (this.apptPk > -1) {
            this.clinicLocationsList = this.getLocations(this.selectedValues.clinicState, this.fieldsInfo.clinicLocationList);

            let clinic_location: any;
            if (this.schedulerUserInfo.clinicPK !== -1) {
              clinic_location = this.alaSQL("SELECT distinct * FROM ? WHERE clinicLocation = '" + this.selectedValues.clinicLocation.replace(/'/g, "\\'") + "' AND clinicPk = " + this.schedulerUserInfo.clinicPK + " GROUP BY  clinicState, clinicLocationId ORDER BY clinicLocationId ASC", [this.fieldsInfo.clinicLocationList]);
            } else {
              clinic_location = this.alaSQL("SELECT distinct * FROM ? WHERE clinicLocation = '" + this.selectedValues.clinicLocation.replace(/'/g, "\\'") + "' GROUP BY  clinicState, clinicLocationId ORDER BY clinicLocationId ASC", [this.clinicLocationsList]);
            }
            this.apptDatesList = this.getDates(clinic_location[0].clinicLocationId, this.fieldsInfo.clinicLocationList);
            this.apptTimesOfDayList = this.getTimesOfDay(clinic_location[0].clinicPk, clinic_location[0].clinicDateGroupId, this.fieldsInfo.scheduleApptTimeList);
            this.apptTimesList = [];

            this.apptTimesList = this.getTimes(clinic_location[0].clinicPk, clinic_location[0].clinicDateGroupId, this.selectedValues.apptTimeOfDay, this.fieldsInfo.scheduleApptTimeList);

            this.apptRoomsList = this.getRooms(clinic_location[0].clinicPk, this.selectedValues.apptDate, this.fieldsInfo.clinicLocationList);

            if (this.apptRoomsList.length === 0) {
              this.schedulerRegistrationFormGroup.get('apptRoom').clearValidators();
              this.schedulerRegistrationFormGroup.get('apptRoom').reset();
            } else {
              this.displayFormData.forEach(control => {
                if (control.fieldName === 'apptRoom') {
                  var is_required = ((control.isFieldRequired) ? Validators.required : null);
                  this.schedulerRegistrationFormGroup.get('apptRoom').clearValidators();
                  this.schedulerRegistrationFormGroup.get('apptRoom').setValidators([Validators.compose([is_required])]);
                  this.schedulerRegistrationFormGroup.get('apptRoom').reset();
                }
              });
            }
          } else {
            this.selectedValues = this.selectedValuesRaw.detailList[0];
          }
          this.selectedValues.clinicState = this.selectedValues.clinicState || '';
          this.selectedValues.clinicLocation = this.selectedValues.clinicLocation || '';
          this.selectedValues.apptDate = this.selectedValues.apptDate || '';
          this.selectedValues.apptTimeOfDay = this.selectedValues.apptTimeOfDay || '';
          this.selectedValues.apptTime = this.selectedValues.apptTime || '';
          this.selectedValues.apptRoom = this.selectedValues.apptRoom || '';
        });
      }
      else {
        this.showDialogError("Error", ErrorMessages.errorSchedulerMsg);
      }
    });
  }
 

  ngOnInit() {
    this.states = states;
    this.schedulerRegistrationFormGroup = this.group;
  }

  createSelectImmunization(control: any) {
    const immunizationArray = this.formBuilder.array([]);
    this.schedulerRegistrationFormGroup.addControl('selectedImmunization', immunizationArray);
  }
  
  // create FormArray once
  createFormElementsArray() {
    const elements: any[] = [];
    this.displayFormData.forEach(item => {
      switch (item.fieldName) {
        case "firstName":
        case "middleName":
        case "lastName":
          if (item.isFieldVisible) {
            this.row1Data.push(item);
          }
          break;
        case "email":
        case "employeeId":
          if (item.isFieldVisible) {
            this.row2Data.push(item);
          }
          break;
        case "clinicState":
        case "clinicLocation":
          if (item.isFieldVisible) {
            this.row3Data.push(item);
          }
          break;
        case "apptDate":
        case "apptTime":
        case "apptTimeOfDay":
        case "apptRoom":
          if (item.isFieldVisible) {
            this.row4Data.push(item);
          }
          break;
        case "selectedImmunization":
          if(item.isFieldVisible){
            this.row5Data.push(item);
          }
        default:
          break;
      }
    });
  }

  displayColMDCss(field: string, index: number) {
    let return_value: boolean = false;
    if (this.schedulerRegistrationFormGroup.get(field))
      return_value = this.schedulerRegistrationFormGroup.get(field) && !this.schedulerRegistrationFormGroup.get(field).valid && this.schedulerRegistrationFormGroup.get(field).touched;

    return {
      'has-error': return_value,
      'has-feedback': return_value
    };
    /*if (e == 'Gender') {
      return 'col-md-2 col-sm-2 col-12';
    }
    else {
      return 'col-md-4 col-sm-4 col-12';
    }*/
  }

  resetAppointmentInfo(field: string) {
    switch (field) {
      case "state":
        //this.selectedValues.clinicLocation = "";
        this.schedulerRegistrationFormGroup.get('clinicLocation').setValue("");
        //this.selectedValues.apptDate = "";
        this.schedulerRegistrationFormGroup.get('apptDate').setValue("");
        //this.selectedValues.apptTime = "";
        this.schedulerRegistrationFormGroup.get('apptTime').setValue("");        
        //this.selectedValues.apptRoom = "";
        this.schedulerRegistrationFormGroup.get('apptRoom').setValue("");
        //this.selectedValues.apptTimeOfDay = "";
        this.schedulerRegistrationFormGroup.get('apptTimeOfDay').setValue("");
        break;
      case "cliniclocation":
        //this.selectedValues.apptDate = "";
        this.schedulerRegistrationFormGroup.get('apptDate').setValue("");
        //this.selectedValues.apptTime = "";
        this.schedulerRegistrationFormGroup.get('apptTime').setValue(""); 
        //this.selectedValues.apptRoom = "";
        this.schedulerRegistrationFormGroup.get('apptRoom').setValue("");
        //this.selectedValues.apptTimeOfDay = "";
        this.schedulerRegistrationFormGroup.get('apptTimeOfDay').setValue("");
        break;
      case "apptdate":
     //this.selectedValues.apptTime = "";
     this.schedulerRegistrationFormGroup.get('apptTime').setValue(""); 
     //this.selectedValues.apptRoom = "";
     this.schedulerRegistrationFormGroup.get('apptRoom').setValue("");
     //this.selectedValues.apptTimeOfDay = "";
     this.schedulerRegistrationFormGroup.get('apptTimeOfDay').setValue("");
        break;
      case "appttimeofday":
      //this.selectedValues.apptRoom = "";
      this.schedulerRegistrationFormGroup.get('apptRoom').setValue("");
      //this.selectedValues.apptTimeOfDay = "";
      //this.schedulerRegistrationFormGroup.get('apptTimeOfDay').setValue("");
        break;
      case "appttime":
             //this.selectedValues.apptRoom = "";
      this.schedulerRegistrationFormGroup.get('apptRoom').setValue("");
        break;
      default:
        break;
    }
  }

  loadClinicLoctions(selected_value: any) {
    this.clinicLocationsList = [];
    this.apptDatesList = [];
    this.apptTimesOfDayList = [];
    this.apptTimesList = [];
    this.apptRoomsList = [];
    this.selectedImmunizations =[];
    this.resetAppointmentInfo('state');
    if (selected_value !== '' && selected_value.indexOf(' ') > -1) {
      selected_value = selected_value.split(' ')[1];
    };
    if (selected_value != "") {
    this.clinicLocationsList = this.getLocations(selected_value, this.fieldsInfo.clinicLocationList);
  }
    if (this.clinicLocationsList.length == 1) {
      let obs = interval(50).pipe(take(1));
      var t1 = this.selectedValuesRaw;
      this.selectedValues.apptRoom = this.clinicLocationsList[0].clinicRoom;//this.schedulerRegistrationFormGroup.get('apptRoom').value;
      this.schedulerRegistrationFormGroup.get('apptRoom').setValue(this.selectedValues.apptRoom );
      this.selectedValues.apptTime = this.schedulerRegistrationFormGroup.get('apptTime').value;
      this.selectedValues.apptDate = this.schedulerRegistrationFormGroup.get('apptDate').value;
      this.selectedValues.apptTimeOfDay = this.schedulerRegistrationFormGroup.get('apptTimeOfDay').value;
      this.selectedValues.clinicLocation = this.clinicLocationsList[0].clinicLocation;//this.schedulerRegistrationFormGroup.get('clinicLocation').value;
      this.schedulerRegistrationFormGroup.get('clinicLocation').setValue(this.selectedValues.clinicLocation);
      this.selectedValues.clinicState =this.clinicLocationsList[0].clinicState;// this.schedulerRegistrationFormGroup.get('clinicState').value;
      this.schedulerRegistrationFormGroup.get('clinicState').setValue(this.selectedValues.clinicState);
      this.selectedValues.email = this.schedulerRegistrationFormGroup.get('email').value;
      this.selectedValues.employeeId = this.schedulerRegistrationFormGroup.get('employeeId')?this.schedulerRegistrationFormGroup.get('employeeId').value:'';
      this.selectedValues.firstName = this.schedulerRegistrationFormGroup.get('firstName')?this.schedulerRegistrationFormGroup.get('firstName').value:'';
      this.selectedValues.lastName = this.schedulerRegistrationFormGroup.get('lastName')?this.schedulerRegistrationFormGroup.get('lastName').value:'';
      this.selectedValues.middleName = this.schedulerRegistrationFormGroup.get('middleName')?this.schedulerRegistrationFormGroup.get('middleName').value:'';
      this.selectedValues.clinicStoreId = this.clinicLocationsList[0].clinicStoreid;
      this.selectedValues.clinicPk= this.clinicLocationsList[0].clinicPk;
      this.selectedValues.outreachBusinessPk= this.clinicLocationsList[0].outreachBusinessPk;
      var t2 = this.selectedValues;
      var t3 = this.clinicLocationsList;
      var schRegFormGroup = this.schedulerRegistrationFormGroup;
      obs.subscribe(function (value) {
        t1.detailList[0].clinicLocation = t3[0].clinicLocation;
        t2.clinicLocation = t3[0].clinicLocation;
        schRegFormGroup.get('clinicLocation').setValue(t2.clinicLocation);
      });
      setTimeout(() => {
        var dt = document.getElementById('ddlLocation');
        this.loadAvblApptDates(dt);
      }, 1000);
    }
  }

  loadAvblApptDates(selected_clinic_ele: any) {
    this.apptDatesList = [];
    this.apptTimesOfDayList = [];
    this.apptTimesList = [];
    this.apptRoomsList = [];
    this.selectedImmunizations =[];
    this.resetAppointmentInfo('cliniclocation');
    //var ctrl_id = selected_clinic_ele.id;
    var temp_fieldsInfo = this.fieldsInfo;
    let clinic_location_id: number;
    let clinicPk: number;
    let clinicStoreId: string;
    let outreachBusinessPk: number;
    let selected_option: any;
    this.selectedlocationvalue = selected_clinic_ele.value
    if (selected_clinic_ele.options.length > 0) {
      selected_option = selected_clinic_ele.options[selected_clinic_ele.selectedIndex];
    }
    if (selected_option && selected_option.attributes["clinicLocationId"]) {
      clinic_location_id = selected_option.attributes["clinicLocationId"].value;
    }

    if (selected_option && selected_option.attributes["outreachBusinessPk"]) {
      outreachBusinessPk = selected_option.attributes["outreachBusinessPk"].value;
    }
    if (selected_option && selected_option.attributes["clinicStoreId"]) {
      clinicStoreId = selected_option.attributes["clinicStoreId"].value;
    }
    //if (this.selectedValues.apptPk == -1) {
    this.selectedValues.outreachBusinessPk = outreachBusinessPk;
    this.selectedValuesRaw.detailList[0].clinicPk = clinicPk;
    this.selectedValuesRaw.detailList[0].outreachBusinessPk = outreachBusinessPk;
    this.selectedValuesRaw.detailList[0].clinicStoreId = parseInt(clinicStoreId);
    this.selectedValues.clinicStoreId = parseInt(clinicStoreId);
    
    //}
    this.selectedValues.clinicLocationId = clinic_location_id;   
    this.apptDatesList = this.getDates(clinic_location_id, temp_fieldsInfo.clinicLocationList);
 
    if (this.apptDatesList.length == 1) {
      let obs = interval(50).pipe(take(1));
      var t1 = this.selectedValuesRaw;
      this.selectedValues.apptRoom = this.schedulerRegistrationFormGroup.get('apptRoom').value;
      this.selectedValues.apptTime = this.schedulerRegistrationFormGroup.get('apptTime').value;
      this.selectedValues.apptDate = this.schedulerRegistrationFormGroup.get('apptDate').value;
      this.selectedValues.apptTimeOfDay = this.schedulerRegistrationFormGroup.get('apptTimeOfDay').value;
      this.selectedValues.clinicLocation = this.schedulerRegistrationFormGroup.get('clinicLocation').value;
      this.selectedValues.clinicState = this.schedulerRegistrationFormGroup.get('clinicState').value;
      this.selectedValues.email = this.schedulerRegistrationFormGroup.get('email').value;
      this.selectedValues.employeeId = this.schedulerRegistrationFormGroup.get('employeeId')?this.schedulerRegistrationFormGroup.get('employeeId').value:'';
      this.selectedValues.firstName = this.schedulerRegistrationFormGroup.get('firstName')?this.schedulerRegistrationFormGroup.get('firstName').value:'';
      this.selectedValues.lastName = this.schedulerRegistrationFormGroup.get('lastName')?this.schedulerRegistrationFormGroup.get('lastName').value:'';
      this.selectedValues.middleName = this.schedulerRegistrationFormGroup.get('middleName')?this.schedulerRegistrationFormGroup.get('middleName').value:'';
      this.selectedValues.clinicPk = clinicPk;
      this.selectedValues.selectedImmunization = this.selectedImmuzvalues;
      var t2 = this.selectedValues;
      var t3 = this.apptDatesList;
      let schRegFormGroup:FormGroup = this.schedulerRegistrationFormGroup;
      obs.subscribe(function (value) {
        t1.detailList[0].apptDate = t3[0].clinicDate;
        t2.clinicDate = t3[0].clinicDate;
        schRegFormGroup.get('apptDate').setValue(t2.clinicDate);
      });
      setTimeout(() => {
        var tm = document.getElementById('ddlClinicDate');
        this.loadAvblTimesofDay(tm);
      }, 1000);
    }
  }

  loadAvblTimesofDay(selected_clinic_date_ele: any) {        
    this.apptTimesOfDayList = [];
    this.apptTimesList = [];
    this.apptRoomsList = [];
    this.selectedImmunizations =[];
    let clinic_date_group_id: number;
    let clinic_location_id: number;
    let clinicPk: number;
    let clinicStoreId :number;
    this.resetAppointmentInfo('apptdate');
    let selected_option: any;
    if (selected_clinic_date_ele.options.length > 0) {
      selected_option = selected_clinic_date_ele.options[selected_clinic_date_ele.selectedIndex];
    }
    if (selected_option && selected_option.attributes["clinicPk"]) {
      clinicPk = selected_option.attributes["clinicPk"].value;
    }
    if (selected_option && selected_option.attributes["clinicStoreId"]) {
      clinicStoreId = selected_option.attributes["clinicStoreId"].value;
      this.selectedValues.clinicStoreId = clinicStoreId;
    }
    // if (selected_option && selected_option.attributes["clinicLocationId"]) {
    //   clinic_location_id = selected_option.attributes["clinicLocationId"].value;
    // }
    if (selected_option && selected_option.attributes["clinicDateGroupId"]) {
      clinic_date_group_id = selected_option.attributes["clinicDateGroupId"].value;
    }
    this.selectedValues.clinicPk = clinicPk;
    this.apptTimesOfDayList = this.getTimesOfDay(clinicPk, clinic_date_group_id, this.fieldsInfo.scheduleApptTimeList);
    const selectedOption = selected_clinic_date_ele.options[selected_clinic_date_ele.selectedIndex]; 
    clinicPk = +selectedOption.getAttribute('clinicpk');
    this.apptImmunizationsList = this.fieldsInfo.clinicLocationList.find(clinic => clinic.clinicDate === selected_clinic_date_ele.value && clinic.clinicLocation === this.selectedlocationvalue );
    this.selectedImmuzvalues = this.fieldsInfo.sCLMScheduleImmunizationLists.filter(item => item.clinicPk === clinicPk  && item.estimatedQuantity > 0) ;     
  }

  loadAvblTimes(selected_clinic_date_ele: any,selectedIndex) {    
    this.apptTimesList = [];
    this.apptRoomsList = [];
    let clinic_date_group_id: number;
    let clinic_location_id: number;
    let clinicPk: number;
    this.resetAppointmentInfo('appttimeofday');
    let selected_option: any;
    if (selected_clinic_date_ele.options.length > 0) {
      selected_option = selected_clinic_date_ele.options[selected_clinic_date_ele.options.selectedIndex];
      this.schedulerRegistrationFormGroup.get('apptTimeOfDay').setValue(selected_clinic_date_ele.value);
    }
    if (selected_option && selected_option.attributes["clinicPk"]) {
      clinicPk = selected_option.attributes["clinicPk"].value;
    }
    // if (selected_option && selected_option.attributes["clinicLocationId"]) {
    //   clinic_location_id = selected_option.attributes["clinicLocationId"].value;
    // }
    if (selected_option && selected_option.attributes["clinicDateGroupId"]) {
      clinic_date_group_id = selected_option.attributes["clinicDateGroupId"].value;
    }
    //this.schedulerRegistrationFormGroup.get('apptTimeOfDay').setValue(selected_option.value);
    this.selectedValues.apptTimeOfDay =this.schedulerRegistrationFormGroup.get('apptTimeOfDay').value?this.schedulerRegistrationFormGroup.get('apptTimeOfDay').value.substring(this.schedulerRegistrationFormGroup.get('apptTimeOfDay').value.indexOf(":")+2).trim():"";     
    this.apptTimesList = this.getTimes(clinicPk, clinic_date_group_id, this.selectedValues.apptTimeOfDay, this.fieldsInfo.scheduleApptTimeList);
  }

  loadAvblRooms(selected_clinic_date_ele: any, index) {
    let clinic_location_id: number;
    this.resetAppointmentInfo('appttime');
    let selected_option: any;
    let clinicPk: number;
    if (selected_clinic_date_ele.options.length > 0) {
      selected_option = selected_clinic_date_ele.options[selected_clinic_date_ele.selectedIndex];
    }
    if (selected_option && selected_option.attributes["clinicPk"]) {
      clinicPk = selected_option.attributes["clinicPk"].value;
    }
    // if (selected_option && selected_option.attributes["clinicLocationId"]) {
    //   clinic_location_id = selected_option.attributes["clinicLocationId"].value;
    // }
    this.apptRoomsList = this.getRooms(clinicPk, this.selectedValues.apptDate, this.fieldsInfo.clinicLocationList);

    if (this.apptRoomsList.length == 0) {
      this.schedulerRegistrationFormGroup.get('apptRoom').clearValidators();
      this.schedulerRegistrationFormGroup.get('apptRoom').reset();
    } else {
      var field_info = this.displayFormData.find(item => item.fieldName === 'apptRoom');// [index];
      var is_required = Validators.compose([((field_info.isFieldRequired) ? Validators.required : null)]);
      this.schedulerRegistrationFormGroup.get('apptRoom').clearValidators();
      this.schedulerRegistrationFormGroup.get('apptRoom').setValidators(is_required);
      this.schedulerRegistrationFormGroup.get('apptRoom').reset();
      if (this.apptRoomsList.length == 1) {
        let obs = interval(50).pipe(take(1));
        var t1 = this.selectedValuesRaw;
      this.selectedValues.apptRoom = this.schedulerRegistrationFormGroup.get('apptRoom').value;
      this.selectedValues.apptTime = this.schedulerRegistrationFormGroup.get('apptTime').value;
      this.selectedValues.apptDate = this.schedulerRegistrationFormGroup.get('apptDate').value;
      this.selectedValues.apptTimeOfDay = this.schedulerRegistrationFormGroup.get('apptTimeOfDay').value;
      this.selectedValues.clinicLocation = this.schedulerRegistrationFormGroup.get('clinicLocation').value;
      this.selectedValues.clinicState = this.schedulerRegistrationFormGroup.get('clinicState').value;
      this.selectedValues.email = this.schedulerRegistrationFormGroup.get('email').value;
      this.selectedValues.employeeId = this.schedulerRegistrationFormGroup.get('employeeId')?this.schedulerRegistrationFormGroup.get('employeeId').value:'';
      this.selectedValues.firstName = this.schedulerRegistrationFormGroup.get('firstName')?this.schedulerRegistrationFormGroup.get('firstName').value:'';
      this.selectedValues.lastName = this.schedulerRegistrationFormGroup.get('lastName')?this.schedulerRegistrationFormGroup.get('lastName').value:'';
      this.selectedValues.middleName = this.schedulerRegistrationFormGroup.get('middleName')?this.schedulerRegistrationFormGroup.get('middleName').value:'';

        var t2 = this.selectedValues;
        var t3 = this.apptRoomsList;
        let schRegFormGroup:FormGroup = this.schedulerRegistrationFormGroup;
        obs.subscribe(function (value) {
          t1.detailList[0].apptRoom = t3[0].clinicRoom;
          t2.apptRoom = t3[0].clinicRoom;
          schRegFormGroup.get('apptRoom').setValue(t2.apptRoom);
        });
      }
    }
  }

handleImmunizations(event: any, immunization: any): void {
  const isSelect = event.target.checked;
  if (isSelect) {
      if (!this.selectedImmunizations.some(item => item.immunizationPk === immunization.immunizationPk)) {
          this.selectedImmunizations.push({
              clinicPk: immunization.clinicPk,
              immunizationPk: immunization.immunizationPk
          });
      }
  } else {
      this.selectedImmunizations = this.selectedImmunizations.filter(item => item.immunizationPk !== immunization.immunizationPk);
  }
}



  submitForm() {
    this.isDisable = true;
    this.utility.validateAllFormFields(this.schedulerRegistrationFormGroup);
    this.formErrors;
    if (this.schedulerRegistrationFormGroup.valid) {

     if(this.selectedValuesRaw.detailList[0].outreachBusinessPk == -1 || this.selectedValuesRaw.detailList[0].clinicPk == -1){
      this.showDialogError("Error",  ErrorMessages.errorSchedulerMsg);
      return false;
    }
      this.spinner.show();
      
      this.selectedValuesRaw.detailList[0].clientId = this.clientId;
      this.selectedValuesRaw.detailList[0].storeState=SessionDetails.GetState();
      this.selectedValues.clientId = this.clientId;
      this.selectedValuesRaw.detailList[0].apptRoom = this.schedulerRegistrationFormGroup.get('apptRoom').value;
      this.selectedValuesRaw.detailList[0].apptTime = this.schedulerRegistrationFormGroup.get('apptTime').value;
      this.selectedValuesRaw.detailList[0].apptDate = this.schedulerRegistrationFormGroup.get('apptDate').value;
      this.selectedValuesRaw.detailList[0].apptTimeOfDay = this.schedulerRegistrationFormGroup.get('apptTimeOfDay').value;
      this.selectedValuesRaw.detailList[0].apptTimeOfDay= this.schedulerRegistrationFormGroup.get('apptTimeOfDay').value?this.schedulerRegistrationFormGroup.get('apptTimeOfDay').value.substring(this.schedulerRegistrationFormGroup.get('apptTimeOfDay').value.indexOf(":")+2).trim():"";
      this.selectedValuesRaw.detailList[0].clinicLocation = this.schedulerRegistrationFormGroup.get('clinicLocation').value;
      this.selectedValuesRaw.detailList[0].clinicState = this.schedulerRegistrationFormGroup.get('clinicState').value;
      this.selectedValuesRaw.detailList[0].email = this.schedulerRegistrationFormGroup.get('email').value;
      this.selectedValuesRaw.detailList[0].employeeId = this.schedulerRegistrationFormGroup.get('employeeId')?this.schedulerRegistrationFormGroup.get('employeeId').value:'';
      this.selectedValuesRaw.detailList[0].firstName = this.schedulerRegistrationFormGroup.get('firstName')?this.schedulerRegistrationFormGroup.get('firstName').value:'';
      this.selectedValuesRaw.detailList[0].lastName = this.schedulerRegistrationFormGroup.get('lastName')?this.schedulerRegistrationFormGroup.get('lastName').value:'';
      this.selectedValuesRaw.detailList[0].middleName = this.schedulerRegistrationFormGroup.get('middleName')?this.schedulerRegistrationFormGroup.get('middleName').value:'';
      this.selectedValuesRaw.detailList[0].clinicStoreId = this.selectedValues.clinicStoreId ;
      this.selectedValuesRaw.detailList[0].clinicPk= this.selectedValues.clinicPk;
      //this.selectedValuesRaw.detailList[0].clinicLocationId= this.selectedValues.clinicLocationId;
      this.selectedValuesRaw.detailList[0].selectedImmunization = this.selectedImmunizations;
      this.scheduler_registration.postSchedulerApptDetails(this.selectedValuesRaw).subscribe((result:any) => {
        this.spinner.hide();
        this.isDisable = null;
      //  if (result.status === 200) {
          if (result.errorS !== null) {
            if (result.errorS.errorCode !== null && result.errorS.errorCode !== 0 && result.errorS.errorCode !== -3) {
              this.showDialogError(ErrorMessages['clinicRegistration'], result.errorS.errorMessage);
            } else if (result.errorS.errorCode === -3) {
              let temp_response = result.detailList[0];
              sessionStorage["registrationDetails"] = JSON.stringify(temp_response);
              this.router.navigate(['/schedulerConfirmation']);
            } else {
              this.router.navigate(['/schedulerConfirmation']);
            }
          }
          else {
            let temp_response = result.detailList[0];
            sessionStorage["registrationDetails"] = JSON.stringify(temp_response);
            this.router.navigate(['/schedulerConfirmation']);
          }
        //}
      },
      error => {
        let errorMessage = "";
        if (error.status == 417) {
          errorMessage = "HTTP/1.1 417 Expectation Failed.";
        } else {
          errorMessage = ErrorMessages.errorSchedulerMsg;
        }
        this.showDialogError("Error",  errorMessage);
      });
    } else {
      this.spinner.hide();
      this.isDisable = null;
    }
  }
  Error() {
    this.errorPopUp = false;
  }
  showDialogError(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.errorPopUp = true;
  }

  get formErrors() {
    this.errorMessages = [];
    return Object.keys(this.schedulerRegistrationFormGroup.controls).map(key => {
      var disp_name = "";
      this.displayFormData.forEach(control => {
        if (control.fieldName == key) {
          disp_name = control.displayName;
        }
      });
      const errors = this.schedulerRegistrationFormGroup.controls[key].errors;
      if (errors === null) {
        this.errorMessages[key] = disp_name;
        return null;
      }
      if (errors.required) {
        this.errorMessages[key] = disp_name + ` is required`;
      } else if (errors.pattern) {
        let temp: any = errors.pattern.requiredPattern.substring(errors.pattern.requiredPattern.indexOf('{') + 1, errors.pattern.requiredPattern.indexOf('}'));
        if (temp.indexOf(',') > -1) {
          temp = temp.split(',');
          if (errors.pattern.actualValue.length > parseInt(temp[1])) {
            this.errorMessages[key] = disp_name + ` cannot be greater than ` + temp[1] + ` characters length`;
          } else {
            this.errorMessages[key] = disp_name + ` is invalid`;
          }
        }
      } else {
        return disp_name + ` has an unknown error`;
      }
    });
  }

  cancelRegistration() {    
    this.isDisable = true;
    this.utility.validateAllFormFields(this.schedulerRegistrationFormGroup);
    this.formErrors;
    if (this.schedulerRegistrationFormGroup.valid) {
      this.spinner.show();
      let cancelled_post_json: any = {};
      cancelled_post_json = {
        "cancelledList": [
          {
            "confirmationId": this.selectedValues.confirmationId,
            "clinicPk": this.selectedValues.clinicPk,
            "schedulerApptPk": this.selectedValues.apptPk,
            "clientId": this.selectedValues.clientId
          }
        ]
      }
      this.scheduler_registration.cancelRegisteredAppt(cancelled_post_json, 1).subscribe((result:any) => {
        //if (result.status === 200) {
          if (result.errorS !== null) {
            if (result.errorS.errorCode !== null && result.errorS.errorCode !== 0) {
              this.showDialogError(ErrorMessages['clinicRegistration'], result.errorS.errorMessage);
            } else {
              sessionStorage["registrationDetails"] = JSON.stringify(this.selectedValuesRaw.detailList[0]);
              sessionStorage["isCancelled"] = true;
              this.router.navigate(['/schedulerConfirmation']);
            }
          }
          else {
            sessionStorage["isCancelled"] = true;
            sessionStorage["registrationDetails"] = JSON.stringify(this.selectedValuesRaw.detailList[0]);
            this.router.navigate(['/schedulerConfirmation']);
          }
        //}
      });
    }
    else {
      this.isDisable = null;
      this.spinner.hide();
    }
  }

  getLocations(selected_value: any, source: any) {
    return this.alaSQL("SELECT distinct * FROM ? " +
    "WHERE clinicState = '" + selected_value + "' GROUP BY  clinicState, clinicLocationId ORDER BY clinicLocationId ASC", [source]);
  }

  getDates(clinic_location_id: any, source: any) {    
    return this.alaSQL("SELECT clinicLocationId,clinicDate,clinicPk,clinicDateGroupId FROM ? " +
    "WHERE clinicLocationId = " + clinic_location_id + " ORDER BY clinicDate ASC", [source]);
  }

  getTimesOfDay(clinic_pk: any, clinic_date_group_id: any, soure) {
    return this.alaSQL("SELECT distinct clinicLocationId,timeOfDay,clinicPk,clinicDateGroupId FROM ? " +
    "WHERE clinicPk = " + clinic_pk + " AND clinicDateGroupId = " + clinic_date_group_id + " ORDER BY clinicLocationId ASC", [soure])
  }

  getTimes(clinic_pk: any, clinic_date_group_id: any, appt_times_of_day: any, source: any) {
    var temp = this.alaSQL("SELECT clinicLocationId,apptAvlTimes,clinicPk,clinicDateGroupId FROM ? " +
    "WHERE clinicPk = " + clinic_pk + " AND clinicDateGroupId = " + clinic_date_group_id + " AND timeOfDay ='" + appt_times_of_day + "'", [source]);
    let today  = new Date();
    if(temp&&temp.length>0 && temp.length>1){
    let interval:number = ( this._timeToDatePipe.transform(today,temp[1].apptAvlTimes.toLowerCase().replace("am"," AM").toLowerCase().replace("pm", " PM")) - this._timeToDatePipe.transform(today,temp[0].apptAvlTimes.toLowerCase().replace("am"," AM").toLowerCase().replace("pm", " PM")))/(60*1000);
    temp.forEach(element => {
      let startTime :Date = new Date();
      try{
      if(element.apptAvlTimes.toLowerCase().indexOf('pm')>-1){
      startTime = this._timeToDatePipe.transform(today,element.apptAvlTimes.toLowerCase().replace("pm", " PM"));
      startTime.setSeconds(0);
      }
      else 
      {
        startTime = this._timeToDatePipe.transform(today,element.apptAvlTimes.toLowerCase().replace("am", " AM"));
        startTime.setSeconds(0);
        if(startTime.getHours()==12){
          startTime.setHours(0);
        }
      }
      }
      catch(e){
       // console.log(e);
      }
      try{
      let startTimeToken = this._datePipe.transform(startTime, "shortTime");
      let endTimeToken= this._datePipe.transform(startTime.setMinutes(startTime.getMinutes()+(interval-1)), "shortTime");
      element.apptAvlTimesToShow = startTimeToken + " - " + endTimeToken;
      }
      catch(e){
       // console.log('670' + e);
        element.apptAvlTimesToShow = element.apptAvlTimes;
      }
    });
  }
  if(temp.length==1){
    temp[0].apptAvlTimesToShow = temp[0].apptAvlTimes.replace("AM"," AM").replace("PM", " PM") ;
  }
    return this.sortByKey(temp, 'apptAvlTimes');
  }

  getRooms(clinic_pk: any, selected_clinic_date: any, source: any) {
    return this.alaSQL("SELECT clinicLocationId,clinicRoom,clinicDateGroupId FROM ? " +
    "WHERE clinicPk = " + clinic_pk + " AND clinicDate = '" + selected_clinic_date + "' AND isRoomsAvailable = TRUE ORDER BY clinicLocationId ASC", [source]);
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      return Date.parse('1970/01/01 ' + a[key].slice(0, -2) + ' ' + a[key].slice(-2)) - Date.parse('1970/01/01 ' + b[key].slice(0, -2) + ' ' + b[key].slice(-2));
    });
  }
  onLastNameInput(e) {

    let lastInitial_pattern = /^[A-Za-z]+$/;
    if (e.target.name == "lastName" && (lastInitial_pattern.test(String.fromCharCode(e.keyCode || e.which)) && e.target.value.length > 0)) {
      e.preventDefault();
      return false;
    }
    else if (e.target.name == "lastName" && !lastInitial_pattern.test(e.key) && e.target.value.length >= 0) {
      e.preventDefault();
      return false;
    }
    else if (e.target.name == "firstName" && !this.name_pattern.test(String.fromCharCode(e.keyCode || e.which)) ||(e.target.name == "firstName" && e.target.value.length > 20)) {
      e.preventDefault();
      return false;
    }
    else if (e.target.name == "firstName" && !this.name_pattern.test(e.key) || (e.target.name == "firstName" &&e.target.value.length >= 20)) {
      e.preventDefault();
      return false;
    }
    else if ((e.target.name == "email" || e.target.name == "Email") && !this.email_pattern.test(String.fromCharCode(e.keyCode || e.which))&& e.target.value.length > 50 ) {
      e.preventDefault();
      return false;
    }
    else if ((e.target.name == "email" || e.target.name == "Email" ) && !this.email_pattern.test(e.key) && e.target.value.length >= 50) {
      e.preventDefault();
      return false;
    }
  }

}