import { Component, OnInit } from '@angular/core';
import { SessionDetails } from '../../../../utility/session';

@Component({
  selector: 'app-pcaresources',
  templateUrl: './pcaresources.component.html',
  styleUrls: ['./pcaresources.component.css']
})
export class PcaresourcesComponent implements OnInit { userProfile = SessionDetails.GetUserProfile();
  displayDownload:boolean=false;
  selectedIndex:number;
  selectedResourcesFiles:any=[];

  walgreensResources=
  {
    "resources": {
      "jobAids": [ {
        "resourceName": "Sample Call Script",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Sample_Call_Script-Patient_Care_and_Advocacy_Events.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      }, {
        "resourceName": "Scheduling & Completing a Patient Care & <br/>Advocacy Event",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Scheduling_and_Completing_an_HIV_and_or_HCV_Testing_Event.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },
      {
        "resourceName": "RVP LULA Approval",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/RVP_LULA_Approval.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },
      {
      "resourceName": "How to Prepare Your Store for a Patient <br/>Care & Advocacy Event",
      "resourceFiles": [{
          "resourceType": "PDF",
          "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/How_To_Prepare_Your_Store_For_A_Testing_Event.pdf"
        },
        {
          "resourceType": "PDF NOTES",
          "resourcePath": ""
        },
        {
          "resourceType": "PPTX",
          "resourcePath": ""
        },
        {
          "resourceType": "EXCEL",
          "resourcePath": ""
        }
      ]
    }],
      "MarketingFlyers": [
        {
          "resourceName": "Free HIV testing (English)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3794573_Free_HIV_Testing_Editable_Flyer-POD_ENG.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      }, {
        "resourceName": "Free HIV testing (Spanish)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3794573_Free_HIV_Testing_Editable_Flyer-POD_SPA.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      }, {
        "resourceName": "Free HIV testing (Puerto Rican)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3794573_Free_HIV_Testing_Editable_Flyer-POD_PR.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      }, {
          "resourceName": "Free HIV testing (English B&W)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3794573_FY24_Free_HIV_Testing_Editable_Flyer_ENG_PRINT.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      }, {
        "resourceName": "Free HIV testing (Spanish B&W)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3794573_FY24_Free_HIV_Testing_Editable_Flyer_SPA_PRINT.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      }, {
        "resourceName": "Free HIV testing (Puerto Rican B&W)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3794573_FY24_Free_HIV_Testing_Editable_Flyer_PR_PRINT.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & hepatitis C testing (English)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5656_V2_HIV_HepC_CMYK_Flyer_ENG.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & hepatitis C testing (Spanish)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5658_V2_HIV_HepC_CMYK_Flyer_SPA.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & hepatitis C testing (Puerto Rican)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5660_V2_HIV_HepC_CMYK_Flyer_PR.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & hepatitis C testing (English B&W)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5650_V2_HIV_HepC_B-W_Flyer_ENG.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & hepatitis C testing (Spanish B&W)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5652_V2_HIV_HepC_B-W_Flyer_SPA.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & hepatitis C testing (Puerto Rican B&W)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5654_V2_HIV_HepC_B-W_Flyer_PR.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & STI testing (English)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5656_V1_HIV_STI_CMYK_Flyer_ENG.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & STI testing (Spanish)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5658_V1_HIV_STI_CMYK_Flyer_SPA.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & STI testing (Puerto Rican)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5660_V1_HIV_STI_CMYK_Flyer_PR.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & STI testing (English B&W)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5650_V1_HIV_STI_B-W_Flyer_ENG.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & STI testing (Spanish B&W)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5652_V1_HIV_STI_B-W_Flyer_SPA.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      },{
          "resourceName": "Free HIV & STI testing (Puerto Rican B&W)",
        "resourceFiles": [{
            "resourceType": "PDF",
            "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/3995099-5654_V1_HIV_STI_B-W_Flyer_PR.pdf"
          },
          {
            "resourceType": "PDF NOTES",
            "resourcePath": ""
          },
          {
            "resourceType": "PPTX",
            "resourcePath": ""
          },
          {
            "resourceType": "EXCEL",
            "resourcePath": ""
          }
        ]
      } ],
     "OrganDonationRegistrationFlyers": [
        {
                "resourceName": "Organ donation registration (Bilingual)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Organ_Donation_Registration_Walgreens-Bilingual.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        },
        {
                "resourceName": "Organ donation registration (English)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Organ_Donation_Registration_Walgreens-English.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        },{
                "resourceName": "Organ donation registration (Community Bilingual)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Organ_Donation_Registration_Community-Bilingual.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        },
        {
                "resourceName": "Organ donation registration (Community English)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Organ_Donation_Registration_Community-English.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        },
        {
                "resourceName": "Organ donation registration (Duane Reade Bilingual)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Organ_Donation_Registration_Duande_Reade-Bilingual.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        },
        {
                "resourceName": "Organ donation registration (Duane Reade English)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Organ_Donation_Registration_Duane_Reade-English.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        }],
      /*   "BreastCancerScreeningFlyers": [
        {
                "resourceName": "Breast Cancer Screening Community - Bilingual",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Breast_Cancer_Screening_Community-Bilingual.pdf"
                  }
                ]
        },
        {
                "resourceName": "Breast Cancer Screening Community - English",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Breast_Cancer_Screening_Community-English.pdf"
                  }
                ]
        },
        {
                "resourceName": "Breast Cancer Screening Duane Reade - Bilingual",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Breast_Cancer_Screening_Duane_Reade-Bilingual.pdf"
                  }
                ]
        },
        {
                "resourceName": "Breast Cancer Screening Duane Reade - English",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Breast_Cancer_Screening_Duane_Reade-English.pdf"
                  }
                ]
        },
        {
                "resourceName": "Breast Cancer Screening Walgreens - Bilingual",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Breast_Cancer_Screening_Walgreens-Bilingual.pdf"
                  }
                ]
        },
        {
                "resourceName": "Breast Cancer Screening Walgreens - English",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Breast_Cancer_Screening_Walgreens-English.pdf"
                  }
                ]
        }], */
         "SkinCancerScreeningFlyers": [
        {
                "resourceName": "Skin Cancer screening (Bilingual)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Skin_Cancer_Screening_Walgreens-Bilingual.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        },
        {
                "resourceName": "Skin Cancer screening (English)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Skin_Cancer_Screening_Walgreens-English.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        },{
                "resourceName": "Skin Cancer screening (Community Bilingual)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Skin_Cancer_Screening_Community-Bilingual.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        },
        {
                "resourceName": "Skin Cancer screening (Community English)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Skin_Cancer_Screening_Community-English.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        },
        {
                "resourceName": "Skin Cancer screening (Duane Reade Bilingual)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Skin_Cancer_Screening_Duane_Reade-Bilingual.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        },
        {
                "resourceName": "Skin Cancer screening (Duane Reade English)",
                "resourceFiles": [{
                    "resourceType": "PDF",
                    "resourcePath": "https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Skin_Cancer_Screening_Duane_Reade-English.pdf"
                  },
                  {
                    "resourceType": "PDF NOTES",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "PPTX",
                    "resourcePath": ""
                  },
                  {
                    "resourceType": "EXCEL",
                    "resourcePath": ""
                  }
                ]
        }]
    }
  }

  constructor() { }

  ngOnInit() {
    this.displayDownload = false;
  }

  scrollDownSample(){
    window.document.getElementById('smapleCallScript').scrollIntoView();
  }

  showDownloadDialog(index,resource_category)
  {
    this.selectedResourcesFiles=this.walgreensResources.resources[resource_category][index].resourceFiles;
    this.displayDownload=true;
  }

  dispSelectedResource(selected_type){
    
  }

}
