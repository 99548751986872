import { Component, OnInit, SimpleChange, Input } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pcadetailsteps',
  templateUrl: './pcadetailsteps.component.html',
  styleUrls: ['./pcadetailsteps.component.css']
})
export class PcadetailstepsComponent implements OnInit {

  subscription: Subscription;
  step: number = 1;
  clinicstatus: string = "Completed Event";
  clickedPath = 'PCADetails';
  message: any;
  store_id: any;

  @Input("totalSteps") totalSteps: any;
  @Input("viewSteps") viewSteps: any;
  @Input("currentStep") currentStep: any;

  private onDestroy$ = new Subject<void>();

  constructor() {}
  ngOnInit() {
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes["viewSteps"]) {
      this.viewSteps = changes["viewSteps"].currentValue;
    }
    if (changes["totalSteps"]) {
      this.totalSteps = changes["totalSteps"].currentValue;
    }
    this.currentStep = ((changes["currentStep"].currentValue) ? changes["currentStep"].currentValue : this.step);
    
    this.GoToStep(this.currentStep);
  }

  GoToStep(step: number) {
    if (step === 2) {
      step = 2;
      this.clinicstatus = 'Completed Event';
    }
    if (step === -1) {
      if (this.totalSteps === 2) {
        step = 2;
        this.clinicstatus = 'Cancelled Event';
      } else if (this.totalSteps === 3) {
        step = 3;
        this.clinicstatus = 'Cancelled Event';
      }
    }

    this.step = step;
    return;
  }

}
