export class UserInfo
{
  email: string;
  userName: string;
  userPk: number;
  userRole: string;
  isAdmin:boolean;
  isPowerUser:boolean;
  firstName: string;
  lastName: string;
  locationType: string;
  assignedLocations: string;
}