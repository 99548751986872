import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Util } from './utility/util';
import { catchError } from 'rxjs/operators';
import { SelectType } from './models/OutreachOpportunity';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(private http: HttpClient) { }

  public getLookUpValues(columnName: string,  category: string): Observable<SelectType[]>{
    const url = `${environment.API_URL}/${environment.RESOURCES.GET_LOOKUP_VALUES}/${columnName}/${category}`;
    return this.http.get<SelectType[]>(url, Util.getRequestHeadersV1())
               .pipe(catchError(Util.handleErrorV2));
  }
}
