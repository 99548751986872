// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ul a + ul { display: none; }
ul a.open + ul { display: block; } */

.search span {
    cursor: pointer;
}
.oSearch {
    display: inline-block;
    width: 560px;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: absolute;
    top: 33px;
    right: 0px;
    padding: 10px;
}
.oSelect {
    width: 130px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/common/components/masterlayout/masterlayout.component.css"],"names":[],"mappings":"AAAA;oCACoC;;AAEpC;IACI,eAAe;AACnB;AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,4EAA4E;IAC5E,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,aAAa;AACjB;AACA;IACI,YAAY;AAChB","sourcesContent":["/* ul a + ul { display: none; }\r\nul a.open + ul { display: block; } */\r\n\r\n.search span {\r\n    cursor: pointer;\r\n}\r\n.oSearch {\r\n    display: inline-block;\r\n    width: 560px;\r\n    background: white;\r\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\r\n    position: absolute;\r\n    top: 33px;\r\n    right: 0px;\r\n    padding: 10px;\r\n}\r\n.oSelect {\r\n    width: 130px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
