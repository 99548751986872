import { Routes } from '@angular/router';
import { HomeRouteGuard } from '../guards/home.route.gaurd';
import { AdminRouteGuard } from '../guards/admin.route.guard';

import { UserProfileComponent } from '../modules/admin/components/user-profile/user-profile.component';
import { EditUserComponent } from '../modules/admin/components/user-profile/edit-user/edit-user.component';
import { AddUserComponent } from '../modules/admin/components/user-profile/add-user/add-user.component';
import { UserauthenticationComponent } from '../modules/auth/components/userauthentication/userauthentication.component';

import { LandingComponent } from '../modules/auth/components/landing/landing.component';
import { LocalClinicDetailsComponent } from '../modules/clinicdetails/components/local-clinic-details/local-clinic-details.component';

import { ImmunizationComponent } from '../modules/resources/components/immunization/immunization.component';
import { LoginComponent } from '../modules/auth/components/login/login.component';
import { DashboardComponent } from '../modules/home/dashboard/dashboard.component';
import { ScheduleEventComponent } from '../modules/store/components/schedule-event/schedule-event.component';
import { MasterlayoutComponent } from '../modules/common/components/masterlayout/masterlayout.component';
import { StoreOpportunityComponent } from '../modules/common/components/store-opportunity/store-opportunity.component';
import { SenioroutreachComponent } from '../modules/resources/components/senioroutreach/senioroutreach.component';
import { FollowupComponent } from '../modules/store/components/follow-up/followup.component';
import { OpportunityComponent } from '../modules/store/components/opportunity/opportunity.component';
import { ContactlogComponent } from '../modules/store/components/contactlog/contactlog.component';
import { ContractapproveComponent } from '../modules/contractaggreement/components/contractapprove/contractapprove.component';
import { ApproveagreementComponent } from '../modules/contractaggreement/components/approveagreement/approveagreement.component';
import { ThankyouComponent } from '../modules/contractaggreement/components/thankyou/thankyou.component';
import { ContractComponent } from '../modules/contractaggreement/components/local-contracts/contract.component';
import { CharitylocationsListComponent } from '../modules/contractaggreement/components/charity-locations-list/charity-locations-list.component';
import { CommunityoutreachComponent } from '../modules/contractaggreement/components/communityoutreach/communityoutreach.component';
import { ViewcontractAgreementComponent } from '../modules/contractaggreement/components/viewcontract-agreement/viewcontract-agreement.component';
import { TrainingvideosComponent } from '../modules/resources/components/trainingvideos/trainingvideos.component';
import { SignoutComponent } from '../modules/auth/components/signout/signout.component';
import { AgreementComponent } from '../modules/contractaggreement/components/contract-agreement/agreement.component';
import { ScheduledclinicsreportComponent } from '../modules/reports/components/scheduleclinicreport/scheduledclinicsreport.component';
import { GroupidassignmentreportComponent } from '../modules/reports/components/groupidassignmentreport/groupidassignmentreport.component';
import { WeeklygroupidassignmentsComponent } from '../modules/reports/components/weeklygroupidassignmentsreport/weeklygroupidassignments.component';
import { ServicefessreportComponent } from '../modules/reports/components/servicefeesreport/servicefeesreport.component';
import { WeeklyvaccinepurhaseComponent } from '../modules/reports/components/weeklyvaccinepuchasingreport/weeklyvaccinepurchase.component';
import { ImmunizationfinancereportComponent } from '../modules/reports/components/immunizationfinancereport/immunizationfinancereport.component';
import { RevisedclinicdatesComponent } from '../modules/reports/components/revisedclinicdates/revisedclinicdates.component';
import { ScheduledAppointmentsreportComponent } from '../modules/reports/components/scheduledappointmentsreport/scheduledappointmentsreport.component';
import { ActivityreportComponent } from '../modules/reports/components/activityreport/activityreport.component';
import { ScheduledeventsreportComponent } from '../modules/reports/components/scheduledeventsreport/scheduledeventsreport.component';
import { VaccinePurchasingReportComponent } from '../modules/reports/components/vaccinepurchasingreport/vaccinepurchasingreport.component';
import { WorkflowMonitorReportComponent } from '../modules/reports/components/workflowmonitorreport/workflowmonitorreport.component';
import { CanDeactivateGuard } from '../guards/can-deactivate/can-deactivate.guard';
import { VideoPlayerComponent } from '../modules/resources/components/video-player/video-player.component';
import { HighlevelstatusreportComponent } from '../modules/reports/components/highlevelstatusreport/highlevelstatusreport.component';
import { HcsActionsItemsIpComponent } from '../modules/reports/components/hcs-actions-items-ip/hcs-actions-items-ip.component';
import { StorecompliancereportComponent } from '../modules/reports/components/storecompliancereport/storecompliancereport.component';
import { UseraccesslogComponent } from '../modules/reports/components/useraccesslog/useraccesslog.component';
import { PotentiallocalbusinesslistComponent } from '../modules/reports/components/potentiallocalbusinesslist/potentiallocalbusinesslist.component';
import { PotentionallocalbusinessandcontactstatusComponent } from '../modules/reports/components/potentionallocalbusinessandcontactstatus/potentionallocalbusinessandcontactstatus.component';
import { MinimumShotExceptionsComponent } from '../modules/reports/components/minimumshotexceptions/minimumshotexceptions.component';
import { HighlevelcomplianceComponent } from '../modules/reports/components/highlevelcompliance/highlevelcompliance.component';
import { InitiatedcontractsreportComponent } from '../modules/reports/components/initiatedcontractsreport/initiatedcontractsreport.component';
import { ScheduledclinicstatusComponent } from '../modules/reports/components/scheduledclinicstatus/scheduledclinicstatus.component';
import { DistrictCompliancereportComponent } from '../modules/reports/components/districtcompliancereport/districtcompliancereport.component';
import { CorporateSchedulerComponent } from '../modules/admin/components/corporate-scheduler/corporate-scheduler.component';
import { CorporateSchedulerPreviewComponent } from '../modules/admin/components/corporate-scheduler-preview/corporate-scheduler-preview.component';
import { WalgreensSchedulerRegistrationComponent } from '../modules/admin/components/walgreens-scheduler-registration/walgreens-scheduler-registration.component';
import { CorporateuploaderComponent } from '../modules/admin/components/corporateuploader/corporateuploader.component';
import { WalgreensSchedulerConfirmationComponent } from '../modules/admin/components/walgreens-scheduler-confirmation/walgreens-scheduler-confirmation.component';

import { ClientSchedulerApptTrackerComponent } from '../modules/admin/components/client-scheduler-appt-tracker/client-scheduler-appt-tracker.component';
import { RestrictedstateclinicsComponent } from '../modules/reports/components/restrictedstateclinics/restrictedstateclinics.component';
import { SchedulerLandingComponent } from '../modules/admin/components/scheduler-landing/scheduler-landing.component';
import { SchedulerAppttrackingComponent } from '../modules/reports/components/scheduler-appttracking/scheduler-appttracking.component';
import { CorporateStoreApptTrackerComponent } from '../modules/admin/components/corporate-store-appt-tracker/corporate-store-appt-tracker.component';
import { NavigationCanDeactivateGuard } from '../guards/navigationDeactivate/navigation-can-deactivate';
import { AddpcaComponent } from '../modules/patientcareadvocacy/components/addpca/addpca.component';
import { AsolulaComponent } from '../modules/patientcareadvocacy/components/asolula/asolula.component';
import { LulaContractAgreementForUserComponent } from '../modules/patientcareadvocacy/components/lula-contract-agreement-for-user/lula-contract-agreement-for-user.component';
import { RhdContractAgreementComponent } from '../modules/patientcareadvocacy/components/rhd-contract-agreement/rhd-contract-agreement.component';
import { PcadetailsComponent } from '../modules/patientcareadvocacy/components/pcadetails/pcadetails.component';
import { GovlulaAgreementComponent } from '../modules/patientcareadvocacy/components/govlula-agreement/govlula-agreement.component';
import { PcaActionItemsComponent } from '../modules/reports/components/pcaactionitems/pcaactionitems.component';
import { PcaresourcesComponent } from '../modules/resources/components/pcaresources/pcaresources.component';
import { TestingeventsreportComponent } from '../modules/reports/components/testingeventsreport/testingeventsreport.component';
import { UnauthorizeComponent } from '../modules/auth/components/unauthorize/unauthorize.component';
import { OpolulaComponent } from '../modules/patientcareadvocacy/components/opolula/opolula.component';
import { CsslulaComponent } from '../modules/patientcareadvocacy/components/csslula/csslula.component';
import { StoreprofileuploaderComponent } from '../modules/admin/components/storeUploader/storeprofileuploader/storeprofileuploader.component';
import { ImmunizationProgramEmailTemplateComponent } from '../modules/resources/components/immunization-program-email-template/immunization-program-email-template.component';
import { SeniorOutreachEmailTemplateComponent } from '../modules/resources/components/senior-outreach-email-template/senior-outreach-email-template.component';
import { PatientCareAdvocacyEmailTemplateComponent } from '../modules/resources/components/patient-care-advocacy-email-template/patient-care-advocacy-email-template.component';
import { FavoritesComponent } from '../modules/resources/components/favorites/favorites.component';
import { ImmunizationCheckComponent } from '../modules/admin/components/immunization-check/immunization-check.component';
import { AddEditImmunizationCheckComponent } from '../modules/admin/components/immunization-check/add-edit-immunization-check/add-edit-immunization-check.component';
import { DroppedStoreReassignmentsComponent } from '../modules/admin/components/dropped-store-reassignments/dropped-store-reassignments.component';
import { GroupidsuploaderComponent } from '../modules/admin/components/groupidsuploader/groupidsuploader.component';
import { DataStatsReportingComponent } from '../modules/reports/components/data-stats-reporting/data-stats-reporting.component';
import { ScheduledApptsRepForReminderEmailComponent } from '../modules/reports/components/scheduledApptsRepForReminderEmail/scheduledApptsRepForReminderEmail.component';
import { ChartsComponent } from '../modules/home/charts/charts.component';
import { InvalidContractComponent } from '../modules/common/components/invalid-contract/invalid-contract.component';
import { ContactUsComponent } from '../modules/contractaggreement/components/contact-us/contact-us.component';
import { ContactUsReportComponent } from '../modules/reports/components/contact-us-report/contact-us-report.component';
import { MaintenanceComponent } from '../modules/contractaggreement/components/maintenance/maintenance.component';
import { VisComponent } from '../modules/resources/components/vis/vis.component';
import { VissComponent } from '../modules/resources/components/viss/viss.component';
import { CovidclinicsreportComponent } from '../modules/reports/components/covidclinicsreport/covidclinicsreport.component';
import { CovidOppStatusreportComponent } from '../modules/reports/components/covid-opp-statusreport/covid-opp-statusreport.component';
import { CovidContactUsComponent } from '../modules/common/components/covid-contact-us/covid-contact-us.component';
import { CovidContactUsReportComponent } from '../modules/reports/components/covid-contact-us-report/covid-contact-us-report.component';
import { ScheduledCovidClinicsReportComponent } from '../modules/reports/components/scheduled-covid-clinics-report/scheduled-covid-clinics-report.component';
import { CovidVaccinePurchasingReportComponent } from '../modules/reports/components/covid-vaccine-purchasing-report/covid-vaccine-purchasing-report.component';
import { CovidGroupidassignmentreportComponent } from '../modules/reports/components/covid-groupidassignmentreport/covid-groupidassignmentreport.component';
import { CovidHighlevelstatusreportrtComponent } from '../modules/reports/components/covid-highlevelstatusreportrt/covid-highlevelstatusreportrt.component';
import { CovidimmunizationfinanceReportComponent } from '../modules/reports/components/covidimmunizationfinancereport/covidimmunizationfinancereport.component';
import { CovidMinimumshotexceptionsReportComponent } from '../modules/reports/components/covid-minimumshotexceptions-report/covid-minimumshotexceptions-report.component';
import { CovidRevisedClinicdatesComponent } from '../modules/reports/components/covid-revised-clinicdates/covid-revised-clinicdates.component';
import { CovidWeeklyVaccinePurchaseReportComponent } from '../modules/reports/components/covid-weekly-vaccine-purchase-report/covid-weekly-vaccine-purchase-report.component';
import { LtcfContactUsComponent } from '../modules/common/components/ltcf-contact-us/ltcf-contact-us.component';
import { LtcfContactUsReportComponent } from '../modules/reports/components/ltcf-contact-us-report/ltcf-contact-us-report.component';
import { FluHcsActionsItemsIpComponent } from '../modules/reports/components/flu-hcs-actions-items-ip/flu-hcs-actions-items-ip.component';
import { UnsubscribeComponent } from '../modules/contractaggreement/components/unsubscribe/unsubscribe.component';
import { ViewcontractAgreementComponent2022 } from '../modules/contractaggreement/components/viewcontract-agreement-2022/viewcontract-agreement.component-2022';
import { ViewcontractAgreementComponent2023 } from '../modules/contractaggreement/components/viewcontract-agreement-2023/viewcontract-agreement.component-2023';
import { FluOpportunitiesContactStatusComponent } from '../modules/reports/components/flu-opportunities-contact-status/flu-opportunities-contact-status.component';
import { PatientReassignmentComponent } from '../modules/admin/components/patient-reassignment/patient-reassignment.component';
import { LulaContractAgreementForUserrelease2Component } from '../modules/patientcareadvocacy/components/lula-contract-agreement-for-user-release2/lula-contract-agreement-for-user-release2.component';
import { RhdContractAgreementrelease2Component } from '../modules/patientcareadvocacy/components/rhd-contract-agreement-release2/rhd-contract-agreement-release2.component';
import { ImmzContactUsComponent } from '../modules/common/components/immz-contact-us/immz-contact-us.component';
import { PreviousClientInterestComponent } from '../modules/common/components/previous-client-interest/previous-client-interest.component';
import { StoreRespondsYesOrNoComponent } from '../modules/common/components/store-responds-yes-or-no/store-responds-yes-or-no.component';
import { EquityeventsComponent } from '../modules/contractaggreement/components/equityevents/equityevents.component';
import { EquityEventDetailsComponent } from '../modules/clinicdetails/components/equity-event-details/equity-event-details.component';
import { ViewcontractAgreement2024Component } from '../modules/contractaggreement/components/viewcontract-agreement-2024/viewcontract-agreement-2024.component';
import { OppourtunitiesRequiringActionComponent } from '../modules/home/charts/oppourtunities-requiring-action/oppourtunities-requiring-action.component';
import { PriorityOppourtunitiesRequiringActionComponent } from '../modules/home/charts/priority-oppourtunities-requiring-action/priority-oppourtunities-requiring-action.component';
 


export const APP_ROUTES: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'unsubscribe', component: UnsubscribeComponent },
  { path: 'COVID-Contact-Us', component: ContactUsComponent, data: { title: 'COVID Schools Lead Generation' } },
  { path: 'Schools-Contact-Us', component: ImmzContactUsComponent, data: { title: 'Immunization Contact Us' } },
  { path: 'LTCF-Contact-Us', component: LtcfContactUsComponent, data: { title: 'LTCF Lead Generation' } },
  { path: 'Immunization-Contact-Us', component: ImmzContactUsComponent, data: { title: 'Immunization Contact Us' } },
  { path: 'contact-us', component: ContactUsComponent, data: { title: 'Lead Generation' } },
  { path: 'maintenance', component: MaintenanceComponent, data: { title: 'maintenance' } },
  { path: 'landing', component: LandingComponent, canActivate: [HomeRouteGuard] },
  { path: 'thankyou', component: ThankyouComponent, data: { title: 'Walgreens Community Outreach' } }, 
  { path: 'rejectCancellation', component: ThankyouComponent, data: { title: 'Walgreens Community Outreach' } }, 
  { path: 'approveCancellation', component: ThankyouComponent, data: { title: 'Walgreens Community Outreach' } }, 
  { path: 'rhdthankyou', component: ThankyouComponent, data: { title: 'rhdthankyou' } },
  { path: 'torgthankyou', component: ThankyouComponent, data: { title: 'Walgreens Community Outreach Thank You' } }, 
  { path: 'clientSchedulerApptTracker', component: ClientSchedulerApptTrackerComponent, canDeactivate: [NavigationCanDeactivateGuard]},
  { path: 'corporateSchedulerPreview', component: CorporateSchedulerPreviewComponent },
  { path: 'schedulerRegistration', component: WalgreensSchedulerRegistrationComponent },
  { path: 'schedulerConfirmation', component: WalgreensSchedulerConfirmationComponent},
  { path: 'schedulerLanding', component: SchedulerLandingComponent }, 
  { path: 'schedulerAppttracking', component: SchedulerAppttrackingComponent, data: { title: 'scheduledappointmentsreport' }, canDeactivate: [NavigationCanDeactivateGuard]}, 
  { path: 'corpScheduledAppts', component: SchedulerAppttrackingComponent,canActivate: [AdminRouteGuard], data: { title: 'schedulerappttrackingcomponent' }, canDeactivate: [NavigationCanDeactivateGuard]},
  { path: 'signout', component: SignoutComponent },
  { path: 'unauthorized', component: UnauthorizeComponent },
  { path: 'invalidCotract', component: InvalidContractComponent },
  { path: 'videoplayer', component: VideoPlayerComponent },  
  { path: 'contractAgreementForUser', component: ContractapproveComponent, data: { title: 'contractAgreementForUser' } },
  // { path: 'lulaContractAgreementForUser', component: LulaContractAgreementForUserComponent, data: { title: 'lulaContractAgreementForUser' } }, //removed to show only agrweement of 2023
  { path: 'lulaContractAgreementForUser-2023-1', component: LulaContractAgreementForUserrelease2Component, data: { title: 'lulaContractAgreementForUser' }, pathMatch:"full" },
  { path: 'rhdContractAgreement', component: RhdContractAgreementComponent, data: { title: 'rhdContractAgreement' } },
  { path: 'rhdContractAgreement-2023-1', component: RhdContractAgreementrelease2Component, data: { title: 'rhdContractAgreement' } },
//{ path: 'rhdContractAgreement1', component: RhdContractAgreementComponent,data: { title: 'rhdContractAgreement1' } },
  { path: 'viewcontract', component: ViewcontractAgreementComponent, data: { title: 'viewcontract' } },
  { path: 'viewcontract-2022-1', component: ViewcontractAgreementComponent2022, data: { title: 'viewcontract' } },
  { path: 'viewcontract-2023-1', component: ViewcontractAgreementComponent2023, data: { title: 'viewcontract' } },
  { path: 'viewcontract-2024-1', component: ViewcontractAgreement2024Component, data: { title: 'viewcontract' } },
  { path: 'previous-client-interest', component: PreviousClientInterestComponent, data: { title: 'Previous Client Interest' } },
  { path: 'store-response', component: StoreRespondsYesOrNoComponent, data: { title: 'Community Outreach' } },
  { path: 'visresources', component: VisComponent, data: { title: 'visresources' } },
  {
    path: 'communityoutreach', component: MasterlayoutComponent, children: 
    [
      { path: 'storehome', component: StoreOpportunityComponent, canActivate: [AdminRouteGuard], data: { title: 'Walgreens Community Outreach' }  },
      { path: 'charts', component: ChartsComponent,canActivate: [AdminRouteGuard], data: { title: 'Charts' } },
      { path: 'srhome', component: StoreOpportunityComponent, canActivate: [AdminRouteGuard], data: { title: 'srhome' }  },
      { path: 'home', component: DashboardComponent, canActivate: [AdminRouteGuard], data: { title: 'home' } }, 
      { path: 'pcadashboard', component: DashboardComponent, canActivate: [AdminRouteGuard], data: { title: 'pcadashboard' } },
      { path: 'soresources', component: SenioroutreachComponent, data: { title: 'soresources' } },
      { path: 'trainingvideos', component: TrainingvideosComponent, data: { title: 'trainingvideos' } },
      { path: 'imzresources', component: ImmunizationComponent, data: { title: 'imzresources' } },
      { path: 'pcaresources', component: PcaresourcesComponent, data: { title: 'pcaresources' } },
      { path: 'vissresouces', component: VissComponent, data: { title: 'vissresouces' } },
      { path: 'immunizationProgramEmailTemplate', component: ImmunizationProgramEmailTemplateComponent, data: { title: 'Immunization Program Email Template' } },
      { path: 'seniorOutreachEmailTemplate', component: SeniorOutreachEmailTemplateComponent, data: { title: 'Senior Outreach Email Template' } },
      { path: 'patientCareAdvocacyEmailTemplate', component: PatientCareAdvocacyEmailTemplateComponent, data: { title: 'Patient Care Advocacy Email Template' } },
      { path: 'favorites', component: FavoritesComponent, data: { title: 'Favorites' } },
      { path: 'scheduledcovidclinicsreport', component: ScheduledCovidClinicsReportComponent,canActivate: [AdminRouteGuard], data: { title: 'scheduledcovidclinicsreport' } },
      { path: 'scheduledclinicsreport', component: ScheduledclinicsreportComponent,canActivate: [AdminRouteGuard], data: { title: 'scheduledclinicsreport' } },
      { path: 'servicefeesreport', component: ServicefessreportComponent,canActivate: [AdminRouteGuard], data: { title: 'servicefeesreport' } },
      
      { path: 'covidgroupidassignmentreport', component: CovidGroupidassignmentreportComponent,canActivate: [AdminRouteGuard], data: { title: 'covidgroupidassignmentreport' } },
      { path: 'groupidassignmentreport', component: GroupidassignmentreportComponent,canActivate: [AdminRouteGuard], data: { title: 'groupidassignmentreport' } },
      { path: 'weeklygroupidassignmentsreport', component: WeeklygroupidassignmentsComponent,canActivate: [AdminRouteGuard], data: { title: 'weeklygroupidassignmentsreport' } },
      { path: 'vaccinepurchasingreport', component: VaccinePurchasingReportComponent,canActivate: [AdminRouteGuard], data: { title: 'vaccinepurchasingreport' } },
      { path: 'covidvaccinepurchasingreport', component: CovidVaccinePurchasingReportComponent,canActivate: [AdminRouteGuard], data: { title: 'covidvaccinepurchasingreport' } },
      { path: 'weeklyvaccinepurchasereport', component: WeeklyvaccinepurhaseComponent,canActivate: [AdminRouteGuard], data: { title: 'weeklyvaccinepurchasereport' } },
      { path: 'covidweeklyvaccinepurchasereport', component: CovidWeeklyVaccinePurchaseReportComponent,canActivate: [AdminRouteGuard], data: { title: 'covidweeklyvaccinepurchasereport' } },
      { path: 'covidimmunizationfinancereport', component: CovidimmunizationfinanceReportComponent,canActivate: [AdminRouteGuard], data: { title: 'covidimmunizationfinancereport' } },
      { path: 'immunizationfinancereport', component: ImmunizationfinancereportComponent,canActivate: [AdminRouteGuard], data: { title: 'immunizationfinancereport' } },
      { path: 'revisedclinicdatesreport', component: RevisedclinicdatesComponent,canActivate: [AdminRouteGuard], data: { title: 'revisedclinicdatesreport' } },
      { path: 'covidrevisedclinicdatesreport', component: CovidRevisedClinicdatesComponent,canActivate: [AdminRouteGuard], data: { title: 'covidrevisedclinicdatesreport' } },
      { path: 'scheduledappointmentsreport', component: ScheduledAppointmentsreportComponent,canActivate: [AdminRouteGuard], data: { title: 'scheduledappointmentsreport' } },
      { path: 'activityreport', component: ActivityreportComponent,canActivate: [AdminRouteGuard], data: { title: 'activityreport' } },
      { path: 'scheduledeventsreport', component: ScheduledeventsreportComponent,canActivate: [AdminRouteGuard], data: { title: 'scheduledeventsreport' } },
      { path: 'workflowmonitorreport', component: WorkflowMonitorReportComponent,canActivate: [AdminRouteGuard], data: { title: 'workflowmonitorreport' } },
      { path: 'restrictedstateclinicsreport', component: RestrictedstateclinicsComponent, data: { title: 'restrictedstateclinicsreport' } },
      { path: 'restrictedstateopportunitiesreport', component: RestrictedstateclinicsComponent,canActivate: [AdminRouteGuard], data: { title: 'restrictedstateopportunitiesreport' } },
      { path: 'userprofile', component: UserProfileComponent, data: { title: 'userprofile' }, canActivate: [AdminRouteGuard] },
      { path: 'corporateScheduler', component: CorporateSchedulerComponent, data: { title: 'corporateScheduler' }, canActivate: [AdminRouteGuard] },
      { path: 'contract', component: ContractComponent, data: { title: 'contract' }, canActivate: [AdminRouteGuard] },
      { path: 'edit_user', component: EditUserComponent, canActivate: [AdminRouteGuard],data: { title: 'edit_user' }},
      { path: 'add-user', component: AddUserComponent, canActivate: [AdminRouteGuard],data: { title: 'add-user' }},
      { path: 'rhdContractAgreement', component: RhdContractAgreementComponent, canActivate: [AdminRouteGuard] ,data: { title: 'rhdContractAgreement' } },
      { path: 'followUp', component: FollowupComponent, data: { title: 'followUp' }, canActivate: [AdminRouteGuard],  },
      //{ path: 'contractAgreementForUser', component: ContractapproveComponent, data: { title: 'contractAgreementForUser' }},
      { path: 'equityevents', component: EquityeventsComponent ,data: { title: 'equityevents' }, canActivate: [AdminRouteGuard]  },
      { path: 'approveagreement', component: ApproveagreementComponent, canActivate: [AdminRouteGuard] },
      { path: 'scheduleevent', component: ScheduleEventComponent, data: { title: 'scheduleevent' }, canActivate: [AdminRouteGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'viewcontactlog', component: ContactlogComponent, data: { title: 'contactlog' }, canActivate: [AdminRouteGuard] },
      { path: 'viewcontactlogHistory', component: ContactlogComponent, canActivate: [AdminRouteGuard] },
      { path: 'opportunity', component: OpportunityComponent, data: { title: 'addBusiness' }, canActivate: [AdminRouteGuard] },
      { path: 'organizationProfile', component: AddpcaComponent, data: { title: 'organizationProfile' }, canActivate: [AdminRouteGuard] },
      { path: 'uploadClinics', component: CorporateuploaderComponent, data: { title: 'uploadClinics' }, canActivate: [AdminRouteGuard] },
      { path: 'patient-reassignment', component: PatientReassignmentComponent, data: { title: 'Patient Reassignment' }, canActivate: [AdminRouteGuard] },
      { path: 'storeprofileuploader', component: StoreprofileuploaderComponent, data: { title: 'storeprofileuploader' }, canActivate: [AdminRouteGuard] },
      { path: 'immunizationcheck', component: ImmunizationCheckComponent, data: { title: 'immunizationCheck' }, canActivate: [AdminRouteGuard] },
      { path: 'droppedstorereassignments', component: DroppedStoreReassignmentsComponent, data: { title: 'Dropped Store Reassignments' }, canActivate: [AdminRouteGuard] },
      { path: 'groupidsuploader', component: GroupidsuploaderComponent, data: { title: 'Group Ids Uploader' }, canActivate: [AdminRouteGuard] },
      { path: 'add-immunization', component: AddEditImmunizationCheckComponent, data: { title: 'addimmunizationCheck' }, canActivate: [AdminRouteGuard] },
      { path: 'edit-immunization', component: AddEditImmunizationCheckComponent, data: { title: 'editimmunizationCheck' }, canActivate: [AdminRouteGuard] },
      { path: 'contact-us-report', component: ContactUsReportComponent,canActivate: [AdminRouteGuard], data: { title: 'Lead Generation Report' }, },
      { path: 'covidclinicsreport', component: CovidclinicsreportComponent,canActivate: [AdminRouteGuard], data: { title: 'covidclinicsreport' } },
      { path: 'covidoppOutreachstatusreport', component: CovidOppStatusreportComponent,canActivate: [AdminRouteGuard], data: { title: 'covidoppOutreachstatusreport' } },
      { path: 'covid-contact-us-report', component: CovidContactUsReportComponent,canActivate: [AdminRouteGuard], data: { title: 'COVID Schools Lead Generation Report' }, },
      { path: 'LTCF-contact-us-report', component: LtcfContactUsReportComponent,canActivate: [AdminRouteGuard], data: { title: 'LTCF Lead Generation Report' }, },

      { path: 'charityprogram', component: CharitylocationsListComponent, data: { title: 'charityProgram' }, canActivate: [AdminRouteGuard] },
      { path: 'communityoutreach', component: CommunityoutreachComponent, data: { title: 'Community Outreach Program' }, canActivate: [AdminRouteGuard] },
      { path: 'CommunityOutreachProgramDetails', component: LocalClinicDetailsComponent, data: { title: 'Community Outreach Clinic Details' }, canActivate: [AdminRouteGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'EquityEventDetails', component: EquityEventDetailsComponent, data: { title: 'equityeventdetails' }, canActivate: [AdminRouteGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'CharityProgramDetails', component: LocalClinicDetailsComponent, data: { title: 'Charity Program Clinic Details' }, canActivate: [AdminRouteGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'LocalClinicProgramDetails', component: LocalClinicDetailsComponent, data: { title: 'Local Clinic Details' }, canActivate: [AdminRouteGuard], canDeactivate: [CanDeactivateGuard] },
      { path: 'CorporateClinicProgramDetails', component: LocalClinicDetailsComponent, data: { title: 'Corporate Clinic Details' }, canActivate: [AdminRouteGuard], canDeactivate: [CanDeactivateGuard] },
	    { path: 'pcahome', component: StoreOpportunityComponent, data: { title: 'pcahome' }, canActivate: [AdminRouteGuard]  },
      { path: 'addpca', component: AddpcaComponent, data: { title: 'addpca' } },
      { path: 'asolula', component: AsolulaComponent, data: { title: 'ASO Lula' } },
      { path: 'govlula', component: GovlulaAgreementComponent, data: { title: 'Government LULA' } },
      { path: 'opolula', component: OpolulaComponent, data: { title: 'OPO Lula' } },
      { path: 'csslula', component: CsslulaComponent, data: { title: 'CSS Lula' } },
      { path: 'pcadetails', component: PcadetailsComponent, data: { title: 'pcadetails' },canActivate: [AdminRouteGuard], canDeactivate: [CanDeactivateGuard]  },
      { path: 'viewcontractForPrev', component: AgreementComponent, data: { title: 'viewcontract' } },
      {
        path: 'senioroutreach', children: [
          { path: 'highlevelstatusreport', component: HighlevelstatusreportComponent,canActivate: [AdminRouteGuard], data: { title: 'highlevelstatusreport' } },
          { path: 'storecompliancereport', component: StorecompliancereportComponent,canActivate: [AdminRouteGuard], data: { title: 'storecompliancereport' } },
          { path: 'localopportunitieslist', component: PotentiallocalbusinesslistComponent,canActivate: [AdminRouteGuard], data: { title: 'localopportunitieslist' } },
          { path: 'localopportunitiescontactstatusreport', component: PotentionallocalbusinessandcontactstatusComponent,canActivate: [AdminRouteGuard], data: { title: 'localopportunitiescontactstatusreport' } },
          //{ path: 'highlevelcompliancereport', component:HighlevelcomplianceComponent , data: { title: 'highlevelcompliancereport' }},
          { path: 'districtcompliancereport', component: DistrictCompliancereportComponent,canActivate: [AdminRouteGuard], data: { title: 'districtcompliancereport' } }

        ]
      },
      {
        path: 'immunizationprograms', children: [
          { path: 'highlevelstatusreport', component: HighlevelstatusreportComponent,canActivate: [AdminRouteGuard], data: { title: 'highlevelstatusreport' } },
          { path: 'covidhighlevelstatusreport', component: CovidHighlevelstatusreportrtComponent,canActivate: [AdminRouteGuard], data: { title: 'covidhighlevelstatusreport' } },
          { path: 'storecompliancereport', component: StorecompliancereportComponent,canActivate: [AdminRouteGuard], data: { title: 'storecompliancereport' } },
          { path: 'hcsactionitems', component: HcsActionsItemsIpComponent,canActivate: [AdminRouteGuard], data: { title: 'hcsactionitems' } },
          { path: 'fluhcsactionitems', component: FluHcsActionsItemsIpComponent,canActivate: [AdminRouteGuard], data: { title: 'fluhcsactionitems' } },
          { path: 'localopportunitiescontactstatusreport', component: PotentionallocalbusinessandcontactstatusComponent,canActivate: [AdminRouteGuard], data: { title: 'localopportunitiescontactstatusreport' } },
          { path: 'minimumshotexceptionsreport', component: MinimumShotExceptionsComponent,canActivate: [AdminRouteGuard], data: { title: 'minimumshotexceptionsreport' } },
          { path: 'covidminimumshotexceptionsreport', component: CovidMinimumshotexceptionsReportComponent,canActivate: [AdminRouteGuard], data: { title: 'covidminimumshotexceptionsreport' } },
          { path: 'oppourtinityrequiringaction', component: OppourtunitiesRequiringActionComponent,canActivate: [AdminRouteGuard], data: { title: 'oppourtinityrequiringaction' } },
          { path: 'priorityoppourtinityrequiringaction', component: PriorityOppourtunitiesRequiringActionComponent,canActivate: [AdminRouteGuard], data: { title: 'priorityoppourtinityrequiringaction' } }
        ]
      },
      {
        path: 'common', children: [
          { path: 'useraccesslogreport', component: UseraccesslogComponent,canActivate: [AdminRouteGuard],data: { title: 'useraccesslogreport' }, },
          { path: 'data-stats-reporting', component: DataStatsReportingComponent,canActivate: [AdminRouteGuard],data: { title: 'Data Stats Reporting' }, },
          { path: 'flu-opportunities-contact-status', component: FluOpportunitiesContactStatusComponent,canActivate: [AdminRouteGuard],data: { title: 'Opportunities Contact Status' }, }          
        ]
      },
      {
        path: 'patientcareadvocacy', children: [
          { path: 'pcaactionitems', component: PcaActionItemsComponent, data: { title: 'pcaactionitems' } },
          { path: 'testingeventsreport', component: TestingeventsreportComponent,canActivate: [AdminRouteGuard], data: { title: 'testingeventsreport' } },
          { path: 'highlevelstatusreport', component: HighlevelstatusreportComponent,canActivate: [AdminRouteGuard], data: { title: 'highlevelstatusreport' } }
        ]
      },
      { path: 'initiatedcontractsreport', component: InitiatedcontractsreportComponent,canActivate: [AdminRouteGuard], data: { title: 'initiatedcontractsreport' } },
      { path: 'scheduledclinicstatusreport', component: ScheduledclinicstatusComponent,canActivate: [AdminRouteGuard], data: { title: 'scheduledclinicstatusreport' }},
      { path: 'corpStoreApptTracker', component: CorporateStoreApptTrackerComponent,canActivate: [AdminRouteGuard], data: { title: 'CorporateStoreApptTrackerComponent' }, canDeactivate: [NavigationCanDeactivateGuard]},
      { path: 'schApptsRepForRemEmail', component: ScheduledApptsRepForReminderEmailComponent,canActivate: [AdminRouteGuard], data: { title: 'scheduledapptsrepforreminderemailcomponent' }, canDeactivate: [NavigationCanDeactivateGuard]}]
  },
  { path: 'unauthorize', component: UserauthenticationComponent, canActivate: [HomeRouteGuard] },
  { path: '**', component: UserauthenticationComponent, canActivate: [HomeRouteGuard] }
];
