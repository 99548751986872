import { Injectable } from "@angular/core";
@Injectable()
export class ClientInfo {
     headername : string = '';
     clientname : string = '';
     titlename : string = '';
     attentionto: string = '';
     address1: string= '';
     address2: string= '';
     cityname: string= ''; 
     statename: string= '';
     zipcode: string= '';
}
