import { Component, OnInit } from '@angular/core';
import { PcacontractAgreementService } from '../../Services/pcacontract-agreement.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PCAContractAgreementModel, ClientInfo } from '../../../../models/PCAContractAgreementModel';
import { Util } from '../../../../utility/util';
import { Router } from '@angular/router';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { String } from 'typescript-string-operations';
import { environment } from '../../../../../environments/environment';
import { LulaAso, LulaGov, OpoLula, CancerLula } from '../../../../models/pcaTandC';
import { ThankyouData } from '../../../../models/thankyouData';
import { SessionDetails } from '../../../../utility/session';
import { CancerLulaRelease2, LulaAsoRelease2, LulaGovRelease2, OpoLulaRelease2 } from '../../../../models/pcaTandC-release2';

@Component({
  selector: 'app-lula-contract-agreement-for-user-release2',
  templateUrl: './lula-contract-agreement-for-user-release2.component.html',
  styleUrls: ['./lula-contract-agreement-for-user-release2.component.css']
})
export class LulaContractAgreementForUserrelease2Component implements OnInit {
  image_source: any;
  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  firstName: string = '';
  lastName: string = '';
  titleName: string = '';
  Lulasteps: boolean = true;
  uploadedFileName: string = '';
  PCAContractForm: FormGroup;
  approvePCAContract: FormGroup;
  files: any;
  storeAddress: any;
  tandC: LulaAsoRelease2 = new LulaAsoRelease2();
  clinicType: number = 8;
  lulaHdr: string = "LIMITED USE LICENSE AGREEMENT<br/>TESTING EVENT";
  storeId: any;
  apprOrRejectTextBox: boolean = false;
  agreement_model: PCAContractAgreementModel = new PCAContractAgreementModel();
  private selectedLink: string;
  date: Date;
  dialogSummary: string;
  dialogMsg: string;
  display: boolean = false;
  proofInsurances = [];
  fileExt: string[] = ['JPG', 'JPEG', 'PDF'];
  uploadDoc: boolean = false;
  message_text: any;
  uploadTitle: string = 'Upload Proof of Insurance';
  disableBtn: boolean;
  alrdApprd: boolean = false;
  client_name: any;
  client_signature: any;
  client_title: any;
  approveRejectEmail: any;
  uploadBtn: boolean = true;
  disableBtnOnSave: boolean = null;
  isApproved: boolean = null;
  disableSignature: boolean = true;
  tempTncInsurance:string;
  outReachProgramType: any;
  oldPcaReleaseDate: string;
  PcaReleaseDate2022_1: string;
  isSTI: boolean = false;
  isHIVAndHepatitis: boolean = false;
  isHIV: boolean = false;
  isHepatitis: boolean = false;
  serviceLabel = "";
  headerLabel = "";

  constructor(
    private router: Router, 
    private utility: Util,
    private _contractService: PcacontractAgreementService, 
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
   this.outReachProgramType =  sessionStorage['clinic_type'];
    this.date = new Date();
    this.PCAContractForm = this.formBuilder.group({
      firstName: ['',
        Validators.required,
      ],
      lastName: ['',
        Validators.required,
      ],
      titleName: ['',
        Validators.required,
      ],
      inputFile: ['', Validators.required,
      ]
    });
    this.approvePCAContract = this.formBuilder.group({
      email_signature: this.formBuilder.control('', null),
      user_notes: this.formBuilder.control('', null),
      radioBtn: this.formBuilder.control('', Validators.required),
    });
    this.getProofOfInsurance(sessionStorage['clinic_agreement_pk']);
    this.getPCAContractAgreementData(sessionStorage['clinic_agreement_pk'], null);
    this.oldPcaReleaseDate = new LulaAso().tnCCutOffDate;
    this.PcaReleaseDate2022_1 = new LulaAsoRelease2().tnCCutOffDate;
  }

  goto(e: number) {

    if (e == 1) {
      this.step1 = true;
      this.step2 = false;
    }
    if (e == 2) {
      if (this.proofInsurances.length > 0) {
        this.removeValidator();
      }
      else {
        this.PCAContractForm.controls['inputFile'].setValidators([Validators.required]);
        this.PCAContractForm.controls['inputFile'].updateValueAndValidity();
      }

      if (this.PCAContractForm.valid) {
        this.client_name = this.PCAContractForm.controls['firstName'].value.trim() + ' ' + this.PCAContractForm.controls['lastName'].value.trim();
        this.client_title = this.PCAContractForm.controls['titleName'].value;
        this.step1 = false;
        this.step2 = true;
      }
      else {
        this.utility.validateAllFormFields(this.PCAContractForm);
        if (this.proofInsurances.length > 0) {
          this.removeValidator();
        }
      }

    }
  }
  onFileChange(event) {
    this.files = event.target.files;
    this.uploadedFileName = event.target.files[0].name;
    this.uploadBtn = null;
    //event= null

  }

  apprOrReject(e) {
    if (e.target.value == '1') {
      this.apprOrRejectTextBox = false;
    }
    if (e.target.value == '2') {
      this.apprOrRejectTextBox = true;
    }
  }
  getPCAContractAgreementData(agreement_pk, user_type) {
    
    this._contractService.getPCAContractAgreement(sessionStorage['clinic_agreement_pk'], user_type).subscribe((data :any)=> {
      this.agreement_model = data;
      if(this.agreement_model.clientInfo)
      {
        this.PCAContractForm.controls.firstName.setValue(this.agreement_model.clientInfo.name.split(' ')[0]);
        this.PCAContractForm.controls.lastName.setValue(this.agreement_model.clientInfo.name.split(' ')[1]);
        this.PCAContractForm.controls.firstName.setValue(this.agreement_model.clientInfo.name.split(' ')[0]);
        this.PCAContractForm.controls.lastName.setValue(this.agreement_model.clientInfo.name.split(' ')[1]);
      }
      this.isApproved = data.isApproved;
      if (this.agreement_model.isCurrent != null && this.agreement_model.isCurrent != undefined && !this.agreement_model.isCurrent) {
        this.router.navigate(['/invalidCotract']);
      }
      if(this.agreement_model.dateApproved != null && new Date(Util.getFormatedDate(this.agreement_model.dateApproved)) < new Date(this.PcaReleaseDate2022_1))
      {
        this.router.navigate(['/login']);
      }
      //var name = data.walgreenCoInfo.rhdName.split(' ');
      this.approveRejectEmail = data.walgreenCoInfo.rhdEmail;
      this.storeId = data.storeInfo.storeId;
      this.storeAddress = data.storeInfo.storeAddress;
      this.disableBtn = (this.agreement_model.isApproved == true || this.agreement_model.isApproved == false) ? true : null;
      this.alrdApprd = this.agreement_model.isApproved == null ? false : true;
      if (this.agreement_model.isApproved == true || this.agreement_model.isApproved == false) {
        this.Lulasteps = false;
        this.step1 = false;
        this.step2 = true;
        this.client_name = this.agreement_model.isApproved == true ? this.agreement_model.clientInfo.name : '';
        this.client_signature = this.agreement_model.signature;
        this.client_title = this.agreement_model.isApproved == true ? this.agreement_model.clientInfo.title : '';
        if (this.agreement_model.isApproved == false) {
          this.isSelected('Reject');
          this.selectedLink = 'Reject';
        }
        this.approvePCAContract.controls['email_signature'].setValue(this.agreement_model.isApproved == true ? this.agreement_model.clientInfo.name : '');
        this.approvePCAContract.controls['user_notes'].setValue(this.agreement_model.isApproved == false ? this.agreement_model.notes : '');
        this.approvePCAContract.controls['radioBtn'].setValue(this.agreement_model.isApproved == true ? '1' : this.agreement_model.isApproved == false ? '2' : '');
      }
      this.clinicType = sessionStorage['clinic_type'];
      switch (sessionStorage['clinic_type']) {
        case 11:
        case '11':
          this.lulaHdr = "LIMITED USE LICENSE AGREEMENT<br/>ORGAN DONOR REGISTRATION EVENT";
          break;
        case 12:
        case '12':
          this.lulaHdr = "LIMITED USE LICENSE AGREEMENT<br/>CANCER SCREENING EVENT";
          break;
        default:
          this.lulaHdr = "LIMITED USE LICENSE AGREEMENT<br/>TESTING EVENT";
          break;
      }
      this.uploadTitle = 'Upload Proof of Insurance or Letter of Self-Insurance';
      
      let tnCCutOffDate = null;
      let dateApproved  = null;
      if(data.dateApproved){
      let dateString = data.dateApproved.substring(0, data.dateApproved.indexOf('T')); // hack for TImezone for -9+
      let dateSplitTokens = dateString.split('-');
      dateApproved = new Date(dateSplitTokens[0], dateSplitTokens[1] - 1, dateSplitTokens[2], 18, 30, 30);
      }
      if(this.outReachProgramType != 11 && this.outReachProgramType != 12) {
        if(data.clinicImmunizationList.length){
          let serviceLabelList = data.clinicImmunizationList.map((immunization) => {
            let idx = immunization.immunizationName.indexOf("testing");
            return immunization.immunizationName.substring(0,idx);
          });
          serviceLabelList = serviceLabelList.sort();
          if(serviceLabelList.length > 1){
            let lastEle = serviceLabelList.pop();
            let label =  `${serviceLabelList.join(', ')} and ${lastEle}`;
            this.headerLabel = `${serviceLabelList.join(',')} and ${lastEle}`.replace(" ,", ", ");
            this.serviceLabel = `${label.replace(" ,", ", ")} testing and screening services and ${label.replace(" ,",", ").replace("HIV", "HIV/AIDS")} counseling to individuals`;
          } else {
            let label =  `${serviceLabelList.join(',')}`;
            this.headerLabel = `${serviceLabelList.join(',')}`.replace(" ,", ", ");
            this.serviceLabel = `${label.replace(" ,", ", ")} testing and screening services and ${label.replace(" ,",", ").replace("HIV", "HIV/AIDS")} counseling to individuals`;
          }
        }
      }

      switch (sessionStorage['clinic_type']) {
        case 10:
        case '10':
          this.tandC = new LulaGovRelease2(data.storeInfo.state);
          tnCCutOffDate = new Date(this.tandC.tnCCutOffDate);
          this.tandC.insurance = this.tandC.conditionalTnCInsurance.replace('{0}', `${this.headerLabel} Testing and counseling services`);
          break;
        case 11:
        case '11':
          this.tandC = new OpoLulaRelease2(data.storeInfo.state);
          break;
        case 12:
        case '12':
          this.tandC = new CancerLulaRelease2(data.storeInfo.state);
          tnCCutOffDate = new Date(this.tandC.tnCCutOffDate);
          if( dateApproved>=tnCCutOffDate || dateApproved === null|| data.isApproved===null|| data.isApproved===0){
          this.tandC.insurance = this.tandC.conditionalTnCInsurance.replace('{0}', "cancer screening services");
          }
          break;
        default:
          this.tandC = new LulaAsoRelease2();
          tnCCutOffDate = new Date(this.tandC.tnCCutOffDate);
          if (data.clinicList[0].clinicImzQtyList[0].immunizationName === "HIV testing and screening services and HIV/AIDS counseling to individuals"
          && (dateApproved === null || dateApproved>=tnCCutOffDate|| data.isApproved===null|| data.isApproved===0) ){
            this.tandC.insurance = this.tandC.conditionalTnCInsurance.replace('{0}', "HIV Testing and counseling services");
          }
          else if (data.clinicList[0].clinicImzQtyList[0].immunizationName === "Hepatitis C testing and screening services and Hepatitis C counseling to individuals"
          && ( dateApproved === null || dateApproved>=tnCCutOffDate|| data.isApproved===null|| data.isApproved===0) ){
            this.tandC.insurance = this.tandC.conditionalTnCInsurance.replace('{0}', "HCV Testing and counseling services");
          }
          else if (data.clinicList[0].clinicImzQtyList[0].immunizationName === "HIV and Hepatitis C testing and screening services and HIV/AIDS and Hepatitis C counseling to individuals"
          &&( dateApproved === null || dateApproved>=tnCCutOffDate || data.isApproved===null|| data.isApproved===0) ){
            this.tandC.insurance = this.tandC.conditionalTnCInsurance.replace('{0}', "HIV and HCV Testing and counseling services");
          }
          break;
      }

      this.tandC.conditionalTnCInsurance = this.tandC.conditionalTnCInsurance.replace( new RegExp("@serviceValue", 'g'),this.headerLabel); 
      this.tandC.use = this.tandC.use.map(paragraph => { return paragraph.replace(new RegExp('@serviceValue', 'g'),this.headerLabel);}); 
      this.tandC.term = this.tandC.term.replace( new RegExp("@serviceValue", 'g'),this.headerLabel);
      this.tandC.tradenames_publications =this.tandC.tradenames_publications.replace( new RegExp("@serviceValue", 'g'),this.headerLabel);
      this.tandC.indemnity = this.tandC.indemnity.map(paragraph => { return paragraph.replace(new RegExp('@serviceValue', 'g'),this.headerLabel);});
      this.tandC.surrender =this.tandC.surrender.replace( new RegExp("@serviceValue", 'g'),this.headerLabel);
      this.tandC.insurance= this.tandC.insurance.replace( new RegExp("@serviceValue", 'g'),this.headerLabel);
      this.tandC.miscellaneous=  this.tandC.miscellaneous.replace( new RegExp("@serviceValue", 'g'),this.headerLabel);
      this.tandC.conditionalTnCInsurance= this.tandC.conditionalTnCInsurance.replace( new RegExp("@serviceValue", 'g'),this.headerLabel);


      // this.tandC.conditionalTnCInsurance = this.tandC.conditionalTnCInsurance.replace( new RegExp("HIV and/or HEPATITIS", 'g'),this.headerLabel); 
      // this.tandC.use = this.tandC.use.map(paragraph => { return paragraph.replace(new RegExp('HIV and/or HEPATITIS', 'g'),this.headerLabel);}); 
      // this.tandC.term = this.tandC.term.replace( new RegExp("HIV and/or HEPATITIS", 'g'),this.headerLabel);
      // this.tandC.tradenames_publications =this.tandC.tradenames_publications.replace( new RegExp("HIV and/or HEPATITIS", 'g'),this.headerLabel);
      // this.tandC.indemnity = this.tandC.indemnity.map(paragraph => { return paragraph.replace(new RegExp('HIV and/or HEPATITIS', 'g'),this.headerLabel);});
      // this.tandC.surrender =this.tandC.surrender.replace( new RegExp("HIV and/or HEPATITIS", 'g'),this.headerLabel);
      // this.tandC.insurance= this.tandC.insurance.replace( new RegExp("HIV and/or HEPATITIS", 'g'),this.headerLabel);
      // this.tandC.miscellaneous=  this.tandC.miscellaneous.replace( new RegExp("HIV and/or HEPATITIS", 'g'),this.headerLabel);
      // this.tandC.conditionalTnCInsurance= this.tandC.conditionalTnCInsurance.replace( new RegExp("HIV and/or HEPATITIS", 'g'),this.headerLabel);
  

    });
  }

  Save() {
    var client_info = new ClientInfo();
    if (this.approvePCAContract.value.email_signature.trim() === '' && this.approvePCAContract.value.user_notes.trim() === '') {
      if (!this.approvePCAContract.controls['radioBtn'].value) {
        this.showDialog('Approve Agreement Error', 'Please select either \'Approve\' or \'Request Changes\' option from Approve Agreement section.');
        return false
      }
    }

    if (this.approvePCAContract.valid) {
      if (Number(this.approvePCAContract.controls['radioBtn'].value) === 1 &&
        this.approvePCAContract.value.email_signature.toLowerCase().trim() !== (this.PCAContractForm.controls['firstName'].value.trim() + ' ' + this.PCAContractForm.controls['lastName'].value.trim()).toLowerCase()) {
        if (this.approvePCAContract.controls["email_signature"].value.trim() !== '') {
          this.showDialog(ErrorMessages['ElectronicSignatureErrorHdng'], ErrorMessages['ElectronicSignatureErrorMsg']);
        }
        else {
          this.approvePCAContract.controls['email_signature'].setValue('');
          this.approvePCAContract.controls['email_signature'].updateValueAndValidity();
          this.utility.validateAllFormFields(this.approvePCAContract);
        }
        return false;
      }
      this.disableBtnOnSave = true;
      switch (this.selectedLink) {
        case 'Approve':
          this.agreement_model.isApproved = 1;
          this.agreement_model.isEmailSent = Number(this.agreement_model.isEmailSent);
          this.client_signature = this.agreement_model.signature = this.approvePCAContract.value.email_signature.trim();
          this.agreement_model.contractPostedByUser = 'Business User';
          this.agreement_model.approvedOrRejectedBy = sessionStorage['approveRejectEmail'];// this.approveRejectEmail;
          this.agreement_model.dateApproved = new Date();
          client_info.client = this.agreement_model.testingPartnerInfo.businessName;
          client_info.name = this.PCAContractForm.controls['firstName'].value.trim() + ' ' + this.PCAContractForm.controls['lastName'].value.trim();
          client_info.title = this.PCAContractForm.controls['titleName'].value;
          client_info.reviewedDate = new Date();
          this.agreement_model.clientInfo = client_info;
          this._contractService.updateContractForTestingPartner(this.agreement_model.storeInfo.storeId, sessionStorage['clinic_type'], this.agreement_model).subscribe((data: any) => {
            this.disableBtnOnSave = null;
            switch (data.errorS.errorCode) {
              case 'success':
              case null:
              case 'null':
              case 0:
              case '0':
                sessionStorage['Reject'] = null;
                var thankuData = new ThankyouData();
                thankuData.printContents = document.getElementById('asolulaContract').innerHTML;

                SessionDetails.setThankUDetails(thankuData);
                this.router.navigate(['/torgthankyou']);
                break;
              case -2:
              case '-2':
                this.showDialog(ErrorMessages['contract'], data.errorS.errorMessage);
                break;
              case -5:
                this.router.navigate(['/invalidCotract']);
                break;
              default:
                this.showDialog(ErrorMessages['contract'], String.Format('Unhandled ErrorCode received from Service errorCode:{0}, errorMessage:{1}', data.errorS.errorCode, data.errorS.errorMessage));
                break;
            }
          },
            err => {
              this.disableBtnOnSave = null;
              // this.spinner.hide();
              switch (err) {

                case 500:
                  this.showDialog(ErrorMessages['contract'], String.Format('ErrorCode 500 received from Service errorMessage:{0}', err.json().Message));
                  break;
                case 400:
                  this.showDialog(ErrorMessages['clientError'], err.json().Message);
                  break;
                default:
                  this.showDialog(ErrorMessages['contract'], String.Format('Unhandled exception ErrorCode received from Service errorMessage:{0}', err.json().Message));
                  break;
              }
            });
          break;
        case 'Reject':
          if (this.approvePCAContract.value.user_notes.trim() === '') {
            this.approvePCAContract.controls['user_notes'].setValue('');
            this.approvePCAContract.controls['user_notes'].updateValueAndValidity();
            this.utility.validateAllFormFields(this.approvePCAContract);
            this.disableBtnOnSave = null;
            return false;
          }
          this.agreement_model.isApproved = 0;
          this.agreement_model.isEmailSent = Number(this.agreement_model.isEmailSent);
          this.agreement_model.signature = this.approvePCAContract.value.email_signature;
          this.agreement_model.contractPostedByUser = 'Business User';
          this.agreement_model.dateApproved = new Date();
          this.agreement_model.notes = this.approvePCAContract.value.user_notes.trim();
          this.agreement_model.approvedOrRejectedBy = sessionStorage['approveRejectEmail'];// this.approveRejectEmail;
          client_info.client = this.agreement_model.testingPartnerInfo.businessName;
          client_info.name = this.PCAContractForm.controls['firstName'].value.trim() + ' ' + this.PCAContractForm.controls['lastName'].value.trim();
          client_info.title = this.PCAContractForm.controls['titleName'].value;
          client_info.reviewedDate = new Date();
          this.agreement_model.clientInfo = client_info;
          this._contractService.updateContractForTestingPartner(this.agreement_model.storeInfo.storeId, sessionStorage['clinic_type'], this.agreement_model).subscribe((data: any) => {
            this.disableBtnOnSave = null;
            switch (data.errorS.errorCode) {
              case 'success':
              case null:
              case 'null':
              case 0:
              case '0':
                sessionStorage['Reject'] = 'Reject';
                // var thankuData = new ThankyouData();
                // thankuData.printContents = document.getElementById('asolulaContract').innerHTML;

                // SessionDetails.setThankUDetails(thankuData);
                this.router.navigate(['/torgthankyou']);
                break;
              case -2:
              case '-2':
                this.showDialog(ErrorMessages['contract'], data.errorS.errorMessage);
                break;
              case -5:
                this.router.navigate(['/invalidCotract']);
                break;
              default:
                this.showDialog(ErrorMessages['contract'], String.Format('Unhandled ErrorCode received from Service errorCode:{0}, errorMessage:{1}', data.errorS.errorCode, data.errorS.errorMessage));
                break;
            }
          },
            err => {
              this.disableBtnOnSave = null;
              // this.spinner.hide();
              switch (err) {
                case 500:
                  this.showDialog(ErrorMessages['contract'], String.Format('ErrorCode 500 received from Service errorMessage:{0}', err.json().Message));
                  break;
                case 400:
                  this.showDialog(ErrorMessages['clientError'], err.json().Message);
                  break;
                default:
                  this.showDialog(ErrorMessages['contract'], String.Format('Unhandled exception ErrorCode received from Service errorMessage:{0}', err.json().Message));
                  break;
              }
            });
      }
    }
    else {
      this.utility.validateAllFormFields(this.approvePCAContract);
    }
  }
  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
  }
  isSelected(name: string): boolean {
    if (!this.selectedLink) {
      return false;
    }
    return (this.selectedLink === name);
  }
  isFieldValid(field: string) {
    return !this.approvePCAContract.get(field).valid && this.approvePCAContract.get(field).touched;
  }
  isFieldValidStep(field: string) {
    return !this.PCAContractForm.get(field).valid && this.PCAContractForm.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  displayFieldCssStep(field: string) {
    return {
      'has-error': this.isFieldValidStep(field),
      'has-feedback': this.isFieldValidStep(field)
    };
  }
  setradio(e: string): void {
    if (e == 'Approve') {
      this.disableSignature = true;
      this.approvePCAContract.controls['email_signature'].setValidators([Validators.required]);
      this.approvePCAContract.controls['email_signature'].updateValueAndValidity();
      this.approvePCAContract.controls['user_notes'].clearValidators();
      this.approvePCAContract.controls['user_notes'].updateValueAndValidity();
      this.approvePCAContract.controls['user_notes'].setValue('');
    }
    if (e == 'Reject') {
      this.disableSignature = null;
      this.approvePCAContract.controls['email_signature'].clearValidators();;
      this.approvePCAContract.controls['email_signature'].updateValueAndValidity();
      this.approvePCAContract.controls['user_notes'].setValidators([Validators.required]);
      this.approvePCAContract.controls['user_notes'].updateValueAndValidity();
      this.approvePCAContract.controls['email_signature'].setValue('');
    }
    this.selectedLink = e;
  }

  okClicked() {
    this.display = false;
  }
  Ok() {
    this.uploadDoc = false;
  }
  onSubmit() {
    this.saveFiles(this.files);
  }
  checker(value){
    var special_characters = ['<','>',':','"','/','\\','|','?','*','%','!','@','#','$','&'];
    for (var i = 0; i < special_characters.length; i++) {
      if (value.indexOf(special_characters[i]) > -1) {
        return false;
      }
    }
    return true;
  }
  saveFiles(files) {
    if (this.uploadedFileName === '') {
      this.uploadDoc = true;
      this.message_text = 'Please upload the file.';
      return;
    }
    
    if (!this.checker(this.uploadedFileName) ) {
      this.uploadDoc = true;
      this.message_text = '< > : " / \ | ? * % ! @ # $ & characters are not allowed in the filename. Please rename the file and try again.';
      return;
    }
    if (this.proofInsurances.length < 3) {
      if (files.length > 0) {
        if (this.isValidFiles(files)) {
          let formData: FormData = new FormData();
          for (var j = 0; j < files.length; j++) {
            formData.append('file', files[j]);
          }
          this.uploadBtn = true;
          var index = this.proofInsurances.findIndex(item => item.fileName === files[0].name);
          if (index > -1) {
            this.uploadedFileName = '';
            this.uploadDoc = true;
            this.message_text = 'There is already a file with the same name in this location, please try a different file name.';
            return;
          }
          var data = new Date().getTime();
          var file_display_name = sessionStorage['clinic_agreement_pk'] + '_' + this.uploadedFileName;
          this._contractService.UploadProofOfInsurance(sessionStorage['clinic_agreement_pk'], formData).subscribe(data => {
            this.uploadedFileName = '';
            this.getProofOfInsurance(sessionStorage['clinic_agreement_pk']);
          },
            err => {
              this.uploadedFileName = '';
              switch (err) {
                case 500:
                  alert('ErrorCode 500 received from Service');
                  break;
                case 400:
                  alert('Unhandled exception error code received from Service');
                  break;
                default:
                  alert('Unhandled exception error code received from Service');
                  break;
              }
            });
        }
        else {
          this.message_text = 'Please upload JPG or JPEG formats.';
          this.uploadDoc = true;
        }
      }
    }
    else {
      this.uploadedFileName = '';
      this.uploadDoc = true;
      this.message_text = 'Only 3 documents can be uploaded.';
    }
  }
  getProofOfInsurance(clinic_agreement_pk) {
    this._contractService.GetProofOfInsurance(clinic_agreement_pk).subscribe((data:any) => {
      this.proofInsurances = data;
      if (this.proofInsurances.length > 0) {
        this.removeValidator();
      }
    });
  }
  Delete(pk) {
    this._contractService.DeleteProofOfInsurance(pk).subscribe(data => {
      this.getProofOfInsurance(sessionStorage['clinic_agreement_pk']);
      var index = this.proofInsurances.findIndex(item => item.pk === pk);
      if (index > -1) {
        this.proofInsurances.splice(index, 1);
      }
    });
  }
  private isValidFiles(files) {
    return this.isValidFileExtension(files);
  }
  private isValidFileExtension(files) {

    for (var i = 0; i < files.length; i++) {
      // Get file extension
      var ext = files[i].name.toLocaleUpperCase().split('.').pop() || files[i].name;

      // Check the extension exists
      var exists = this.fileExt.filter(element => element == ext);

      if (exists.length <= 0) {

        return false;
      }
    }
    return true;
  }
  Download(file_name) {
    window.open(environment.RESOURCES.PROOF_OF_DOCUMENTS + '/' + file_name);
    // this.image_source = environment.API_URL + '/ProofOfDocs/' + this.uploadedFileName;
  }

  removeValidator() {
    this.PCAContractForm.get('inputFile').clearValidators();
    this.PCAContractForm.get('inputFile').updateValueAndValidity();
  }
  print() {
    this.utility.print("asolulaContract", "Limited Use License Agreement");
  }

  loadtAndCInsurance(){
    switch (this.clinicType) {
        case 10:
          // if(this.agreement_model.clinicList[0].clinicImzQtyList[0].immunizationName==="HIV testing and screening services and HIV/AIDS counseling to individuals"){
            this.tandC.insurance = `At all times, during the Term, Host Licensee shall maintain, or shall cause to be maintained at Host Licensee's sole cost and expense, (a) Workers Compensation and Employers Liability Insurance; (b) Professional Liability Insurance for all professionals conducting any ${this.headerLabel.toUpperCase()} Testing and counseling services at the Event with minimum limits of $1,000,000 each claim; $3,000,000 aggregate; (c) Commercial General Liability Insurance with minimum limits of $1,000,000 per occurrence, $1,000,000 for Personal Injury subject to $2,000,000 aggregate and coverage for product liability with minimum limits of $2,000,000 per occurrence; $2,000,000 aggregate naming Walgreen Co. as Additional Insured.  Host Licensee shall provide Walgreen Co. with a certificate of insurance prior to execution of this Agreement.`;
          // }
          // else if(this.agreement_model.clinicList[0].clinicImzQtyList[0].immunizationName==="Hepatitis C testing and screening services and Hepatitis C counseling to individuals"){
          //   this.tandC.insurance = "At all times, during the Term, Host Licensee shall maintain, or shall cause to be maintained at Host Licensee's sole cost and expense, (a) Workers Compensation and Employers Liability Insurance; (b) Professional Liability Insurance for all professionals conducting any HCV Testing and counseling services at the Event with minimum limits of $1,000,000 each claim; $3,000,000 aggregate; (c) Commercial General Liability Insurance with minimum limits of $1,000,000 per occurrence, $1,000,000 for Personal Injury subject to $2,000,000 aggregate and coverage for product liability with minimum limits of $2,000,000 per occurrence; $2,000,000 aggregate naming Walgreen Co. as Additional Insured.  Host Licensee shall provide Walgreen Co. with a certificate of insurance prior to execution of this Agreement.";
          // }
          // else if(this.agreement_model.clinicList[0].clinicImzQtyList[0].immunizationName==="HIV and Hepatitis C testing and screening services and HIV/AIDS and Hepatitis C counseling to individuals"){
          //   this.tandC.insurance = "At all times, during the Term, Host Licensee shall maintain, or shall cause to be maintained at Host Licensee's sole cost and expense, (a) Workers Compensation and Employers Liability Insurance; (b) Professional Liability Insurance for all professionals conducting any HIV and HCV Testing and counseling services at the Event with minimum limits of $1,000,000 each claim; $3,000,000 aggregate; (c) Commercial General Liability Insurance with minimum limits of $1,000,000 per occurrence, $1,000,000 for Personal Injury subject to $2,000,000 aggregate and coverage for product liability with minimum limits of $2,000,000 per occurrence; $2,000,000 aggregate naming Walgreen Co. as Additional Insured.  Host Licensee shall provide Walgreen Co. with a certificate of insurance prior to execution of this Agreement.";
          // }
          break;
      case 12:
        this.tandC.insurance="At all times, during the Term, Host Licensee shall maintain, or shall cause to be maintained at Host Licensee's sole cost and expense, (a) Workers Compensation and Employers Liability Insurance; (b) Professional Liability Insurance for all professionals conducting any cancer screening services at the Event with minimum limits of $1,000,000 each claim; $3,000,000 aggregate; (c) Commercial General Liability Insurance with minimum limits of $1,000,000 per occurrence, $1,000,000 for Personal Injury subject to $2,000,000 aggregate and coverage for product liability with minimum limits of $2,000,000 per occurrence; $2,000,000 aggregate naming Walgreen Co. as Additional Insured.  Host Licensee shall provide Walgreen Co. with a certificate of insurance prior to execution of this Agreement.";
        break;
      default:
          this.tandC.insurance= "At all times, during the term of this Agreement, Host Licensee shall maintain at its sole expense the following policies of insurance: (a) Workers Compensation and Employers Liability Insurance in amounts required by applicable law; and (b) Professional Liability Insurance for all professionals providing any service at the HIV Testing Event with individual or aggregate limits of liability acceptable to Walgreens. A certificate of insurance showing evidence of the required coverage must be submitted to Walgreens prior to or simultaneously with the execution of this Agreement by Host Licensee.";
        break;
    }
  }
}
