export class PotentialLocalBusiness{
    storeId: number;
    outreachEffort: string;
    contacted: number;
    fromDate: string;
    toDate: string;
    status: number;
    reportName: string;
    outputType: string;
    constructor(store_id: number, outreach_effort: string, contacted: number, from_date: string, to_date: string, status:number, report_name?: string,output_type?: string) {
        this.storeId = store_id;
        this.outreachEffort = outreach_effort;
        this.contacted = contacted;
        this.fromDate = from_date;
        this.toDate = to_date;
        this.status=status;
        this.outputType = output_type;
        this.reportName = report_name;
    }
}
export class PotentialCOVIDBusiness{
    storeId: number;
    regionNumber: number;
    areaNumber: number;
    districtNumber: number;
    outreachEffort: string;
    contacted: number;
    fromDate: string;
    toDate: string;
    status: number;
    reportName: string;
    outputType: string;
    state: string
    constructor(store_id: number, regionNumber: number, areaNumber:number, districtNumber:number, outreach_effort: string, contacted: number, from_date: string, to_date: string, status:number, report_name?: string,output_type?: string, state?: string) {
        this.storeId = store_id;
        this.outreachEffort = outreach_effort;
        this.contacted = contacted;
        this.fromDate = from_date;
        this.toDate = to_date;
        this.status=status;
        this.outputType = output_type;
        this.reportName = report_name;
        this.regionNumber = regionNumber;
        this.areaNumber = areaNumber;
        this.districtNumber = districtNumber;
        this.state = state;
    }
}