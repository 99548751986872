// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-content {
    padding: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/contractaggreement/components/local-contracts/contract.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB","sourcesContent":[".tab-content {\r\n    padding: 5px 0;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
