import { Component, OnInit } from '@angular/core';
import { PotentiallocalbusinesslistService } from '../../services/potentiallocalbusinesslist.service';
import { SessionDetails } from '../../../../utility/session';
import {  FormGroup,  FormBuilder, Validators } from '@angular/forms';
import * as appReportSettings from '../../globals';
import { saveAs as importedSaveAs } from "file-saver";
import { MessageServiceService } from '../../../store/services/message-service.service';
import { ErrorMessages } from "../../../../config-files/error-messages";

@Component({
  selector: 'app-potentiallocalbusinesslist',
  templateUrl: './potentiallocalbusinesslist.component.html',
  styleUrls: ['./potentiallocalbusinesslist.component.css']
})
export class PotentiallocalbusinesslistComponent implements OnInit {
  potentialLocalBusinessArray: any;
  potentialLocalBusinessForm: FormGroup;
  totalRecords: number;
  loading: boolean = true;
  private outreachEffort: any;
  ddlFormat: any;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  private storeId:string;
  constructor( private fb: FormBuilder,private potential_local_business_list: PotentiallocalbusinesslistService,private message_service:MessageServiceService) {
    this.message_service.getStoreID().subscribe(x=>{
      this.storeId = x.text;
      this.loading=true;
      this.generateReport();
    });
   }

  ngOnInit() {
    this.storeId =SessionDetails.GetStoreId(); 
    this.validateExportType();
    this.outreachEffort = appReportSettings.outreachEffortSO;
    this.ddlFormat = "xlsxv";
  this.setFormControls();
    this.generateReport();
  }

  setFormControls() {
    this. potentialLocalBusinessForm = this.fb.group({
       
       'ddlFormat': [this.ddlFormat]
     });
     //this.generateReport();
   }

  generateReport(){
    let data = this. potentialLocalBusinessForm.value;
     this.potential_local_business_list.getBusinessAssignedToStore(this.storeId, this.outreachEffort).subscribe((result_array :any)=> {
      this.potentialLocalBusinessArray = result_array;
      this.totalRecords=result_array.length;
      //setTimeout(function () {
        this.loading = false;
      //}, 1000);
    }  ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.potentialLocalBusinessArray = [];
      this.totalRecords = 0;
    });
    this.ddlFormat="xlsxv";
  }

  generateReportFormat(){
    
    let data = this. potentialLocalBusinessForm.value;
    this.loading=true;
    this.potential_local_business_list.exportReportData(SessionDetails.GetStoreId(), this.outreachEffort,"LocalOpportunityList","xlsxv").subscribe((res:any) => {
      importedSaveAs(res.body, "LocalOpportunityList.xlsx");
      this.loading = false;
    } ,error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
    this.ddlFormat="xlsxv";
  }
  validateExportType(){
    if(this.ddlFormat=="0")
    return true;
    else
     return false;
  }
    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}
