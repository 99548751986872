import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysLogPipe implements PipeTransform {
  transform(value, args: string[] = null): any {
    let keys = [];
    var values = [];
    /*for (let key in value) {
      
      for (let val in value[key]) {
        if ()
          values.push(val);
      }
      keys.push({ key: key, value: values });
    }*/
    let filtered_keys = [];
    if(value != undefined && value != null ) {
      
    for (let i = 0; i < value.length; i++) {
      if (filtered_keys.indexOf(value[i].updateSubCategory) == -1) {
        for (let j = 0; j < value.length; j++) {
          if (filtered_keys.indexOf(value[j].updateSubCategory) == -1) {
            if (value[j]["updatedField"] && value[j].updateSubCategory == value[i].updateSubCategory) {
                values.push(value[j].updatedField.replace("null<br />",""));
            }
            if (value[j]["updatedValue"] && value[j].updateSubCategory == value[i].updateSubCategory) {
              values.push(value[j].updatedValue);
            }
            if (j == (value.length - 1)) {
              keys.push({ key: value[i].updateSubCategory, value: values });
            }
          }else{
            if (j == (value.length - 1) && values.length>0) {
              keys.push({ key: value[i].updateSubCategory, value: values });
            }
          }
        }            
      filtered_keys.push(value[i].updateSubCategory);
      values = []
      }  
    }
  }
    return keys;
  }
}