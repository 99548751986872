import { Component, OnInit } from '@angular/core';
import { LeadOpportunitiesService } from '../../../contractaggreement/services/lead-opportunities.service';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { NgxSpinnerService } from "ngx-spinner";
import { saveAs as importedSaveAs } from "file-saver";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationSettings } from '../../../common/application-settings';
import * as appSettings from '../../globals';

@Component({
  selector: 'app-contact-us-report',
  templateUrl: './contact-us-report.component.html',
  styleUrls: ['./contact-us-report.component.css']
})
export class ContactUsReportComponent implements OnInit {
  LeadGenerationForm: FormGroup;
  LeadGenerationReportArray: any;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  loading: boolean;
  lgRData: any = [];
  ddlFormat: string = 'xlsxv';
  leadLaunchDate = "05/01/2020";
  seasons: any = [];
  ddlSeason: number;
  outreachStartDate = ApplicationSettings.outreachStartDateIP;
  private isCurrentSeason = 1;
  filterByDate: boolean = false;
  fromDate: Date;
  toDate: Date;
  minDate: Date;
  maxDate: Date;
  canExport: boolean = true;
  private currentSeason = appSettings.ipSeason;
  from_date: string;
  to_date: string;

  constructor(private fb: FormBuilder, private leadService: LeadOpportunitiesService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    for (let report_year: number = (new Date()).getFullYear(); report_year >= (new Date(this.leadLaunchDate)).getFullYear(); report_year--) {
      this.seasons.push({
        "yearName": report_year.toString() + "-" + (report_year + 1).toString(),
        "yearCode": report_year.toString()
      });
    }
    this.ddlSeason = (new Date(this.outreachStartDate)).getFullYear();
    this.setDates();
    this.setFormControls();
    this.loadReport();
    //this.generateReport();
  }
  setFormControls() {
    this.LeadGenerationForm = this.fb.group({
      'filterByDate': [this.filterByDate],
      'fromDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'toDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
      'ddlSeason': [this.ddlSeason],
      'ddlFormat': ["xlsxv"],
      'covidOnly': [false],
      'isCurrentSeason': [this.isCurrentSeason],
      'reportname': ["contactUs"],
    });
  }
   
  loadReport()
  {
    this.loading = true;
    let data = this.LeadGenerationForm.value;
    let leadModel = { isCovid: false, ddlFormat: "xlsxv", ddlSeason: data.ddlSeason, filterByDate: data.filterByDate, fromDate: data.fromDate, isCurrentSeason: this.isCurrentSeason, reportname: "Contact-Us", toDate: data.toDate };
    this.leadService.getContactUsReport(leadModel).subscribe((resultArray: any) => {
      resultArray.filter(p=>p.industry == 'Other').forEach(element => {
        element.industry = "Other ("+element.otherIndustry+")";
      });
      this.lgRData = resultArray;
      this.loading = false;
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
      this.lgRData = [];
    });

  }

  generateReport(): void {
    // 
    this.loading = true;
    let data = this.LeadGenerationForm.value;
    let leadModel = { isCovid: false, ddlFormat: "xlsxv", ddlSeason: data.ddlSeason, filterByDate: data.filterByDate, fromDate: data.fromDate, isCurrentSeason: this.isCurrentSeason, reportname: "Contact-Us", toDate: data.toDate };
    this.leadService.getContactUsReport(leadModel).subscribe((resultArray: any) => {
      this.lgRData = resultArray;
      this.loading = false;
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
      this.lgRData = [];
    });
  }
  generateReportFormat(): void {
    // 
    //if(this.ddlFormat == '0'){
    // return;
    //}
    this.loading = true;
    let data = this.LeadGenerationForm.value;
    this.LeadGenerationForm.value.isCurrentSeason = this.isCurrentSeason;
    this.leadService.exportReportData(this.LeadGenerationForm.value).subscribe((res: any) => {
      importedSaveAs(res.body, "contactUs.xlsx");
      this.loading = false;
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
  setDates() {
    if (this.LeadGenerationForm != undefined) {
      this.ddlSeason = this.LeadGenerationForm.controls['ddlSeason'].value;
    }
    let tempMinDate: Date;
    let tempMaxDate: Date;
    tempMinDate = new Date();
    tempMaxDate = new Date();
    this.minDate = new Date(tempMinDate);
    this.maxDate = new Date(tempMaxDate)
    tempMinDate.setDate(tempMinDate.getDate() - 90);
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    this.canExport = false;
    if (this.LeadGenerationForm != undefined) {
      this.LeadGenerationForm.controls['fromDate'].setValue(this.fromDate);
      this.LeadGenerationForm.controls['toDate'].setValue(this.toDate);
    }

    this.isCurrentSeason = (this.ddlSeason != this.currentSeason) ? 0 : 1;
  }
  setCalendarDates() {
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    this.canExport = false;
  }
  reset(): void {
    this.setFormControls();
    this.ddlSeason = this.currentSeason;
    this.setDates();
  }
}