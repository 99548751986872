
        export class StoreInfo {
            storeId: number;
            storePk: number;
            address: string;
            address2: string;
            city: string;
            state: string;
            zipCode: string;
            constructor(){
               this.state = ""; 
            }
        }
    
        export class TestingPartnerInfo {
            businessName: string;
            firstName: string;
            lastName: string;
            jobTitle: string;
            address1: string;
            address2?: any;
            phone: string;
            city: string;
            state: string;
            zip: string;
            email?: any;
        }
    
        export class ClinicImzQtyList {
            clinicPk: number;
            immunizationPk: number;
            paymentTypeId: number;
            estimatedQuantity: number;
            immunizationName: string;
            paymentTypeName: string;
        }
    
        export class ClinicList {
            pk: number;
            location: string;
            contactFirstName: string;
            contactLastName: string;
            contactEmail: string;
            contactPhone: string;
            clinicDate: Date;
            startTime: string;
            endTime: string;
            address1: string;
            address2: string;
            city: string;
            state: string;
            zipCode: string;
            estShots: string;
            isReassign: boolean;
            isNoClinic: boolean;
            isCurrent: boolean;
            fluExpiryDate?: any;
            routineExpiryDate?: any;
            latitude: number;
            longitude: number;
            clinicImzQtyList: ClinicImzQtyList[];
        }
    
        export class ClinicImmunizationList {
            immunizationPk: number;
            immunizationName: string;
            immunizationSpanishName?: any;
            price?: any;
            paymentTypeId?: any;
            paymentTypeName?: any;
            paymentTypeSpanishName?: any;
            sendInvoiceTo: number;
            name?: any;
            address1?: any;
            address2?: any;
            city?: any;
            state?: any;
            zip?: any;
            phone?: any;
            email?: any;
            isTaxExempt?: any;
            isCopay?: any;
            copayValue?: any;
            isVoucherNeeded?: any;
            voucherExpirationDate?: any;
            showprice?: any;
        }
    
        export class ClientInfo {
            client: string;
            name: string;
            title: string;
            reviewedDate: Date;
        }
    
        export class WalgreenCoInfo {
            name: string;
            title: string;
            preparedDate: Date;
            districtNumber: number;
            rhdEmail: string;
            rhdName?: any;
        }
    
        export class ErrorS {
            errorCode: number;
            errorMessage?: any;
        }
    
        export class ContractThankYou {
            storeState?: any;
            hasPrefilledForms: boolean;
            pdfVarFormEn?: any;
            pdfVarFormEsp?: any;
        }
    
        export class PCAContractAgreementModel {
            totalS: number;
            clinicAgreementPk: number;
            contactLogPk: number;
            isApproved: any;
            approvedOrRejectedBy: string;
            rvpApprovedOrRejectedBy:string;
            signature: string;
            rhdSignature: string;
            isCurrent?:boolean;
            isLocationReqAvailable:number;
            isHealthRoomAvailable:number;
            isEmailSent: number;
            notes?: any;
            dateCreated: Date;
            businessUserEmails: string;
            contractPostedByUser?: any;
            contactWagUser?: any;
            lastUpdatedDate: Date;
            licenseAgreementNum?: any;
            storeInfo: StoreInfo;
            testingPartnerInfo: TestingPartnerInfo;
            isRHDApproved?: any;
            dateApproved:Date;
            dateRHDApproved:Date;
            clinicList: ClinicList[];
            clinicImmunizationList: ClinicImmunizationList[];
            clientInfo: ClientInfo;
            walgreenCoInfo: WalgreenCoInfo;
            legalNoticeAddressInfo?: any;
            errorS: ErrorS;
            contractThankYou: ContractThankYou;
        }
    
    
    

    


