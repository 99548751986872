
export const commonMenuItems: any[] = [
{
  "title": "Dashboards",
  "forRoles": ["Admin", "District Manager", "Healthcare Supervisor", "Director - Rx and Retail Ops", "Regional Vice President", "Regional Healthcare Director", "Pod Healthcare Supervisor"],
  "navigationItems": [{
    "title": "Immunization Program",
    "url": "/communityoutreach/home",
    "forRoles": ["Admin", "District Manager", "Healthcare Supervisor", "Director - Rx and Retail Ops", "Regional Vice President", "Regional Healthcare Director", "Pod Healthcare Supervisor"]
  },
  {
    "title": "Patient Care & Advocacy",
    "url": "/communityoutreach/pcadashboard",
    "forRoles": ["Admin", "District Manager", "Healthcare Supervisor", "Director - Rx and Retail Ops", "Regional Vice President", "Regional Healthcare Director", "Pod Healthcare Supervisor"]
  },
  {
    "title": "Charts",
    "url": "/communityoutreach/charts",
    "forRoles": ["Admin"]
  }
  ]
},
{
  "title": "Store",
  "forRoles": ["all"],
  "url": "/communityoutreach/storehome"
},
{
  "title": "Resources",
  "forRoles": ["all"],
  "navigationItems": [{
    "title": "Training Videos",
    "url": "/communityoutreach/trainingvideos",
    "forRoles": ["all"]
  },
  {
    "title": "Outreach Materials",
    "forRoles": ["all"],
    "navigationItems": [{
      "title": "Immunization Program",
      "url": "/communityoutreach/imzresources",
      "forRoles": ["all"]
    },
    {
      "title": "Senior Outreach",
      "url": "/communityoutreach/soresources",
      "forRoles": ["all"]
    },
    {
      "title": "Patient Care & Advocacy",
      "url": "/communityoutreach/pcaresources",
      "forRoles": ["all"]
    }
    ]
  },
  {
    "title": "Program Details",
    "forRoles": ["all"],
    "navigationItems": [{
      "title": "Immunization Program",
      "url": "/communityoutreach/immunizationProgramEmailTemplate",
      "forRoles": ["all"]
    },
    {
      "title": "Patient Care & Advocacy",
      "url": "/communityoutreach/patientCareAdvocacyEmailTemplate",
      "forRoles": ["all"]
    }
    ]
  }
  ]

},
{
  "title": "Reports",
  "active": true,
  "forRoles": ["all"],
  "navigationItems": [
    // {
    //   "title": "COVID Program",
    //   "forRoles": ["all"],
    //   "navigationItems": [{
    //     "title": "COVID Clinics",
    //     "url": "/communityoutreach/covidclinicsreport",
    //     "forRoles": ["Admin", "Regional Healthcare Director", "Regional Vice President", "Healthcare Supervisor", "Director - Rx and Retail Ops", "District Manager"]
    //   },
    //   {
    //     "title": "COVID Lead Generation",
    //     "url": "/communityoutreach/covid-contact-us-report",
    //     "forRoles": ["Admin", "Regional Healthcare Director", "Regional Vice President", "Healthcare Supervisor", "Director - Rx and Retail Ops", "District Manager"]
    //   },
    //   // {
    //   //   "title": "COVID Opportunities Contact Status Report",
    //   //   "url": "/communityoutreach/covidoppOutreachstatusreport",
    //   //   "forRoles": ["Admin", "Regional Healthcare Director", "Regional Vice President", "Healthcare Supervisor", "Director - Rx and Retail Ops", "District Manager"]
    //   // },
    //   {
    //     "title": "Group ID Assignments",
    //     "url": "/communityoutreach/covidgroupidassignmentreport",
    //     "forRoles": ["Admin"]
    //   },
    //   {
    //     "title": "{role_title}High Level Status",
    //     "url": "/communityoutreach/immunizationprograms/covidhighlevelstatusreport",
    //     "forRoles": ["Admin", "Regional Healthcare Director", "Regional Vice President", "Healthcare Supervisor", "Director - Rx and Retail Ops", "District Manager"]
    //   },
    //   {
    //     "title": "Immunization Finance",
    //     "url": "/communityoutreach/covidimmunizationfinancereport",
    //     "forRoles": ["Admin"]
    //   },
    //   // {
    //   //   "title": "Initiated Contracts",
    //   //   "url": "/communityoutreach/initiatedcontractsreport",
    //   //   "forRoles": ["Admin"]
    //   // },
    //   // {
    //   //   "title": "Lead Generation",
    //   //   "url": "/communityoutreach/contact-us-report",
    //   //   "forRoles": ["Admin", "Regional Healthcare Director", "Regional Vice President", "Healthcare Supervisor", "Director - Rx and Retail Ops", "District Manager"]
    //   // },
    //   // {
    //   //   "title": "Local Opportunities List & Contact Status",
    //   //   "url": "/communityoutreach/immunizationprograms/localopportunitiescontactstatusreport",
    //   //   "forRoles": ["Admin", "Regional Healthcare Director", "Regional Vice President", "Healthcare Supervisor", "Director - Rx and Retail Ops", "District Manager", "Pharmacy Manager", "Store Manager"]
    //   // },
    //   // {
    //   //   "title": "LTCF Lead Generation",
    //   //   "url": "/communityoutreach/LTCF-contact-us-report",
    //   //   "forRoles": ["Admin", "Regional Healthcare Director", "Regional Vice President", "Healthcare Supervisor", "Director - Rx and Retail Ops", "District Manager"]
    //   // },
    //   {
    //     "title": "Minimum Shot Exceptions",
    //     "url": "/communityoutreach/immunizationprograms/covidminimumshotexceptionsreport",
    //     "forRoles": ["Admin"]
    //   },
    //   // {
    //   //   "title": "Restricted State Clinics",
    //   //   "url": "/communityoutreach/restrictedstateclinicsreport",
    //   //   "forRoles": ["Admin"]
    //   // },
    //   // {
    //   //   "title": "Restricted State Opportunities",
    //   //   "url": "/communityoutreach/restrictedstateopportunitiesreport",
    //   //   "forRoles": ["Admin"]
    //   // },
    //   {
    //     "title": "Revised Clinic Dates & Volumes",
    //     "url": "/communityoutreach/covidrevisedclinicdatesreport",
    //     "forRoles": ["Admin"]
    //   },
    //   {
    //     "title": "Scheduled Appointments",
    //     "url": "/communityoutreach/scheduledappointmentsreport",
    //     "forRoles": ["Admin"]
    //   },
    //   // {
    //   //   "title": "Scheduled Clinic Status",
    //   //   "url": "/communityoutreach/scheduledclinicstatusreport",
    //   //   "forRoles": ["Admin", "Regional Healthcare Director", "Regional Vice President", "Healthcare Supervisor", "Director - Rx and Retail Ops", "District Manager", "Pharmacy Manager", "Store Manager"]
    //   // },
    //   {
    //     "title": "Scheduled Clinics",
    //     "url": "/communityoutreach/scheduledcovidclinicsreport",
    //     "forRoles": ["all"]
    //   },
    //   // {
    //   //   "title": "Store Compliance",
    //   //   "url": "/communityoutreach/immunizationprograms/storecompliancereport",
    //   //   "forRoles": ["Admin", "Regional Healthcare Director", "Regional Vice President", "Healthcare Supervisor", "Director - Rx and Retail Ops", "Pharmacy Manager", , "District Manager", "Store Manager"]
    //   // },
    //   {
    //     "title": "Vaccine Purchasing",
    //     "url": "/communityoutreach/covidvaccinepurchasingreport",
    //     "forRoles": ["Admin"]
    //   },
    //   {
    //     "title": "Weekly Vaccine Purchasing",
    //     "url": "/communityoutreach/covidweeklyvaccinepurchasereport",
    //     "forRoles": ["Admin"]
    //   }
    // ]
    // },
    {
      "title": "Immunization Program",
      "forRoles": ["all"],
      "navigationItems": [
      // {
      //   "title": "COVID Clinics",
      //   "url": "/communityoutreach/covidclinicsreport",
      //   "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager"]
      // },
      // {
      //   "title": "COVID Schools Lead Generation",
      //   "url": "/communityoutreach/covid-contact-us-report",
      //   "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager"]
      // },
      // {  
      //     "title": "COVID Opportunities Contact Status Report",
      //     "url": "/communityoutreach/covidoppOutreachstatusreport",
      //     "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager"]
      //},
        {
        "title": "Group ID Assignments",
        "url": "/communityoutreach/groupidassignmentreport",
        "forRoles": ["Admin"]
      },
      {
        "title": "{role_title}High Level Status",
        "url": "/communityoutreach/immunizationprograms/highlevelstatusreport",
        "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager", "Pod Healthcare Supervisor"]
      },
      {
        "title": "Immunization Finance",
        "url": "/communityoutreach/immunizationfinancereport",
        "forRoles": ["Admin"]
      },
      {
        "title": "Initiated Contracts",
        "url": "/communityoutreach/initiatedcontractsreport",
        "forRoles": ["Admin"]
      },      
      {
        "title": "Lead Generation",
        "url": "/communityoutreach/contact-us-report",
        "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager", "Pod Healthcare Supervisor"]
      },
      {
        "title": "LTCF Lead Generation",
        "url": "/communityoutreach/LTCF-contact-us-report",
        "forRoles": ["Admin", "Regional Healthcare Director", "Regional Vice President", "Healthcare Supervisor", "Director - Rx and Retail Ops", "District Manager", "Pod Healthcare Supervisor"]
      },
      // {
      //   "title": "COVID Opportunities Contact Status Report",
      //   "url": "/communityoutreach/covidoppOutreachstatusreport",
      //   "forRoles": ["Admin", "Regional Healthcare Director", "Regional Vice President", "Healthcare Supervisor", "Director - Rx and Retail Ops", "District Manager"]
      // },
      {
        "title": "Local Opportunities List & Contact Status",
        "url": "/communityoutreach/immunizationprograms/localopportunitiescontactstatusreport",
        "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager","Pharmacy Manager","Store Manager", "Pod Healthcare Supervisor"]
      },
      {
        "title": "Minimum Shot Exceptions",
        "url": "/communityoutreach/immunizationprograms/minimumshotexceptionsreport",
        "forRoles": ["Admin"]
      },
      //{
      //  "title": "Restricted State Clinics",
      //  "url": "/communityoutreach/restrictedstateclinicsreport",
      //  "forRoles": ["Admin"]
      //},
      //{
      //  "title": "Restricted State Opportunities",
      //  "url": "/communityoutreach/restrictedstateopportunitiesreport",
      //  "forRoles": ["Admin"]
      //},
      {
        "title": "Revised Clinic Dates & Volumes",
        "url": "/communityoutreach/revisedclinicdatesreport",
        "forRoles": ["Admin"]
      },
      {
        "title": "Scheduled Appointments",
        "url": "/communityoutreach/scheduledappointmentsreport",
        "forRoles": ["Admin"]
      },
      {
        "title": "Scheduled Clinic Status",
        "url": "/communityoutreach/scheduledclinicstatusreport",
        "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager","Pharmacy Manager","Store Manager", "Pod Healthcare Supervisor"]
      },
      {
        "title": "Scheduled Clinics",
        "url": "/communityoutreach/scheduledclinicsreport",
        "forRoles": ["all"]
      },
      {
        "title": "Service Fee",
        "url": "/communityoutreach/servicefeesreport",
        "forRoles": ["Admin"]
      },
      {
        "title": "Store Compliance",
        "url": "/communityoutreach/immunizationprograms/storecompliancereport",
        "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","Pharmacy Manager",,"District Manager","Store Manager", "Pod Healthcare Supervisor"]
      },
      {
        "title": "Vaccine Purchasing",
        "url": "/communityoutreach/vaccinepurchasingreport",
        "forRoles": ["Admin"]
      },
      {
        "title": "Weekly Vaccine Purchasing",
        "url": "/communityoutreach/weeklyvaccinepurchasereport",
        "forRoles": ["Admin"]
      },
      {
        "title": "Opportunities Contact Status",
        "url": "/communityoutreach/common/flu-opportunities-contact-status",
        "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager", "Pod Healthcare Supervisor"]
      }
    ]
    },
    {
      "title": "Senior Outreach",
      "forRoles": ["all"],
      "navigationItems": [{
        "title": "Activity",
        "url": "/communityoutreach/activityreport",
        "forRoles": ["all"]
      },
      {
        "title": "District Compliance",
        "url": "/communityoutreach/senioroutreach/districtcompliancereport",
        "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager", "Pod Healthcare Supervisor"]
      }
      ,
      /*{
        "title": "High Level Compliance",
        "url": "/communityoutreach/senioroutreach/highlevelcompliancereport",
        "forRoles": ["all"]
      },*/
      {
        "title": "{role_title}High Level Status",
        "url": "/communityoutreach/senioroutreach/highlevelstatusreport",
        "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager", "Pod Healthcare Supervisor"]
      },
      {
        "title": "Local Opportunities Contact Status",
        "url": "/communityoutreach/senioroutreach/localopportunitiescontactstatusreport",
        "forRoles": ["Admin","Pharmacy Manager","Store Manager","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager", "Pod Healthcare Supervisor"]
      },
      {
        "title": "Local Opportunities List",
        "url": "/communityoutreach/senioroutreach/localopportunitieslist",
        "forRoles": ["Admin","Pharmacy Manager","Store Manager","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager", "Pod Healthcare Supervisor"]
      },
      {
        "title": "Scheduled Events",
        "url": "/communityoutreach/scheduledeventsreport",
        "forRoles": ["all"]
      },
      {
        "title": "Store Compliance",
        "url": "/communityoutreach/senioroutreach/storecompliancereport",
        "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","Pharmacy Manager","Store Manager","District Manager", "Pod Healthcare Supervisor"]
      }
      ]
    },
    {
      "title": "Patient Care & Advocacy",
      "forRoles": ["all"],
      "navigationItems": [{
        "title": "PCA Events",
        "url": "/communityoutreach/patientcareadvocacy/testingeventsreport",
        "forRoles": ["all"]
      },
      {
        "title": "{role_title}High Level Status",
        "url": "/communityoutreach/patientcareadvocacy/highlevelstatusreport",
        "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager", "Pod Healthcare Supervisor"]
      }]
    },
    {
      "title": "Common",
      "forRoles": ["all"],
      "navigationItems": [{
        "title": "User Access Log",
        "url": "/communityoutreach/common/useraccesslogreport",
        "forRoles": ["Admin","Regional Healthcare Director", "Regional Vice President","Healthcare Supervisor","Director - Rx and Retail Ops","District Manager", "Pod Healthcare Supervisor"]
      },
      {
        "title": "Workflow Monitor",
        "url": "/communityoutreach/workflowmonitorreport",
        "forRoles": ["all"]
      },
      {
        "title": "Data Stats Reporting",
        "url": "/communityoutreach/common/data-stats-reporting",
        "forRoles": ["admin"]
      }]
    }
  ]
},
{
  "title": "Admin",
  "forRoles": ["Admin"],
  "navigationItems": [
  //   {
    // "title": "Scheduler Site Setup",
    // "url": "/communityoutreach/corporateScheduler",
  //   "forRoles": ["Admin"]
  // },
  {
    "title": "Upload Scheduled Clinics",
    "url": "/communityoutreach/uploadClinics",
    "forRoles": ["Admin"]
  },
  {
    "title": "Patient Reassignment",
    "url": "/communityoutreach/patient-reassignment",
    "forRoles": ["Admin"]
  },
  {
    "title": "User Management",
    "url": "/communityoutreach/userprofile",
    "forRoles": ["Admin"]
  },
  {
    "title": "Dropped Store Reassignments",
    "forRoles": ["Admin"],
    "url": "/communityoutreach/droppedstorereassignments"
  },
  {
    "title": "Immunizations & Rates",
    "forRoles": ["Admin"],
    "url": "/communityoutreach/immunizationcheck"
  },
  {
    "title": "Store Profile Uploader",
    "url": "/communityoutreach/storeprofileuploader",
    "forRoles": ["Admin"]
  },
  {
    "title": "Group Id Uploader",
    "forRoles": ["Admin"],
    "url": "/communityoutreach/groupidsuploader"
  }
  ]
},
{
  "title": "Sign Out",
  "forRoles": ["all"],
  "url": "/login"
}
]


export const adminmenuItems: any[] = [{
  title: "Home",
  active: true,
  url: "/communityoutreach/home",
  navigationItems: [
    {
      title: "Charity Program",
      url: "/communityoutreach/charityprogram"
    }
    ,
    {
      title: "Community Outreach Clinic Details",
      url: "/communityoutreach/CommunityOutreachProgramDetails"
    }
    ,
    {
      title: "Charity Program Clinic Details",
      url: "/communityoutreach/CharityProgramDetails"
    }
    ,
    {
      title: "Corporate Clinic Details",
      url: "/communityoutreach/CorporateClinicProgramDetails"
    }
  ]

}, {
  title: "Store",
  url: "/communityoutreach/storehome",

},
// {
//   title: "Map",
//   url: "/communityoutreach/storehome",

// },
{
  title: "Resources",
  url: "/communityoutreach/resources",
  navigationItems: [
    {
      title: "Training Videos",
      url: "/communityoutreach/trainingvideos"
    },
    {
      title: "Outreach Materials",
      url: "/",
      navigationItems: [
        {
          title: "Immunization Program",
          url: "/communityoutreach/imzresources"
        },
        {
          title: "Senior Outreach",
          url: "/communityoutreach/soresources"
        },
        {
          title: "Patient Care & Advocacy",
          url: "/communityoutreach/pcaresources"
        }

      ]
    }
  ]

},
{
  title: "Reports",
  active: true,
  url: "/communityoutreach/scheduleclinicreport",
  navigationItems: [

    {
      title: "Immunization Program",
      url: "/",
      navigationItems: [
        {
          title: "Scheduled Clinics",
          url: "/communityoutreach/scheduledclinicsreport"
        },
        {
          title: "COVID Clinics",
          url: "/communityoutreach/covidclinicsreport"
        },
        {
          title: "COVID Opportunities Contact Status Report",
          url: "/communityoutreach/covidoppOutreachstatusreport"
        },
        {
          title: "Group ID Assignments",
          url: "/communityoutreach/groupidassignmentreport"
        },
        {
          title: "Weekly Group ID Assignments",
          url: "/communityoutreach/weeklygroupidassignmentsreport"
        },
        {
          title: "Vaccine Purchasing",
          url: "/communityoutreach/vaccinepurchasingreport"
        },
        {
          title: "Weekly Vaccine Purchasing",
          url: "/communityoutreach/weeklyvaccinepurchasereport"
        },
        {
          title: "Immunization Finance",
          url: "/communityoutreach/immunizationfinancereport"
        },
        {
          title: "Revised Clinic Dates & Volumes",
          url: "/communityoutreach/revisedclinicdatesreport"
        },
        {
          title: "Scheduled Appointments",
          url: "/communityoutreach/scheduledappointmentsreport"
        },
        {
          title: "Workflow Monitor",
          url: "/communityoutreach/workflowmonitorreport"
        }
      ]
    },
    {
      title: "Senior Outreach",
      url: "/",
      navigationItems: [
        {
          title: "Activity",
          url: "/communityoutreach/activityreport"
        },
        {
          title: "Scheduled Events",
          url: "/communityoutreach/scheduledeventsreport"
        }
      ]
    }
  ]
},
// {
//   title: "Admin",
//   active: true,
//   url: "/communityoutreach/storehome",
//   navigationItems: [
//     {
//       title: "User Profile",
//       url: "/communityoutreach/userprofile"
//     },
//     {
//       title: "Scheduler Site Setup",
//       url: "/"
//     },
//     {
//       title: "Upload Scheduled Clinics",
//       url: "/"
//     },
//     {
//       title: "Store Profile Uploader",
//       url: "/"
//     },
//     {
//       title: "Post Custom Message",
//       url: "/"
//     }
//   ]
// },
{
  title: "Sign Out",
  url: "/login",

}
]

export const storeManagermenuItems: any[] = [
  {
    title: "Store",
    url: "/communityoutreach/storehome",

  },
  // {
  //   title: "Map",
  //   url: "/communityoutreach/storehome",

  // },
  {
    title: "Resources",
    url: "/communityoutreach/resources",
    navigationItems: [
      {
        title: "Training Videos",
        url: "/communityoutreach/trainingvideos"
      },
      {
        title: "Outreach Materials",
        url: "/",
        navigationItems: [
          {
            title: "Immunization Program",
            url: "/communityoutreach/imzresources"
          },
          {
            title: "Senior Outreach",
            url: "/communityoutreach/soresources"
          }

        ]
      }
    ]

  },
  {
    title: "Reports",
    active: true,
    navigationItems: [
      /*{
        title: "Potential Local Business List and Contact Status",
        url: "/"
      },
      {
        title: "Scheduled Clinic Status",
        url: "/"
      }
      ,
      {
        title: "Store Compliance Report",
        url: "/"
      }
      ,*/
      {
        title: "Scheduled Clinic Report",
        url: "/communityoutreach/scheduledclinicsreport"
      }
    ]
  },


  {
    title: "Sign Out",
    url: "/login",

  }
]

export const districtManagermenuItems: any[] = [{
  title: "Home",
  url: "/communityoutreach/storehome",

},
{
  title: "Store",
  url: "/communityoutreach/storehome",

},
// {
//   title: "Map",
//   url: "/communityoutreach/storehome",

// },
{
  title: "Resources",
  url: "/communityoutreach/resources",
  navigationItems: [
    {
      title: "Training Videos",
      url: "/communityoutreach/trainingvideos"
    },
    {
      title: "Outreach Materials",
      url: "/",
      navigationItems: [
        {
          title: "Immunization Program",
          url: "/communityoutreach/imzresources"
        },
        {
          title: "Senior Outreach",
          url: "/communityoutreach/soresources"
        }

      ]
    }
  ]

},
{
  title: "Reports",
  active: true,
  navigationItems: [
    {
      title: "Scheduled Clinic Report",
      url: "/communityoutreach/scheduledclinicsreport"
    }
  ]
},


{
  title: "Sign Out",
  url: "/login",

}
]

export const healthcareSupervisormenuItems: any[] = [
  {
    title: "Store",
    url: "/communityoutreach/storehome",

  },
  // {
  //   title: "Map",
  //   url: "/communityoutreach/storehome",

  // },
  {
    title: "Resources",
    url: "/communityoutreach/resources",
    navigationItems: [
      {
        title: "Training Videos",
        url: "/communityoutreach/trainingvideos"
      },
      {
        title: "Outreach Materials",
        url: "/",
        navigationItems: [
          {
            title: "Immunization Program",
            url: "/communityoutreach/imzresources"
          },
          {
            title: "Senior Outreach",
            url: "/communityoutreach/soresources"
          }

        ]
      }
    ]

  },
  {
    title: "Reports",
    active: true,
    navigationItems: [
      {
        title: "Scheduled Clinic Report",
        url: "/communityoutreach/scheduledclinicsreport"
      }
    ]
  },


  {
    title: "Sign Out",
    url: "/login",

  }
]

export const regionalVicePresidentmenuItems: any[] = [
  {
    title: "Store",
    url: "/communityoutreach/storehome",

  },
  // {
  //   title: "Map",
  //   url: "/communityoutreach/storehome",

  // },
  {
    title: "Resources",
    url: "/communityoutreach/resources",
    navigationItems: [
      {
        title: "Training Videos",
        url: "/communityoutreach/trainingvideos"
      },
      {
        title: "Outreach Materials",
        url: "/",
        navigationItems: [
          {
            title: "Immunization Program",
            url: "/communityoutreach/imzresources"
          },
          {
            title: "Senior Outreach",
            url: "/communityoutreach/soresources"
          }

        ]
      }
    ]

  },
  {
    title: "Reports",
    active: true,
    navigationItems: [
      {
        title: "Scheduled Clinic Report",
        url: "/communityoutreach/scheduledclinicsreport"
      }
    ]
  },


  {
    title: "Sign Out",
    url: "/login",

  }
]

export const directorRxRetailmenuItems: any[] = [
  {
    title: "Store",
    url: "/communityoutreach/storehome",

  },
  // {
  //   title: "Map",
  //   url: "/communityoutreach/storehome",

  // },
  {
    title: "Resources",
    url: "/communityoutreach/resources",
    navigationItems: [
      {
        title: "Training Videos",
        url: "/communityoutreach/trainingvideos"
      },
      {
        title: "Outreach Materials",
        url: "/",
        navigationItems: [
          {
            title: "Immunization Program",
            url: "/communityoutreach/imzresources"
          },
          {
            title: "Senior Outreach",
            url: "/communityoutreach/soresources"
          }

        ]
      }
    ]

  },
  {
    title: "Reports",
    active: true,
    navigationItems: [
      {
        title: "Scheduled Clinic Report",
        url: "/communityoutreach/scheduledclinicsreport"
      }
    ]
  },
  {
    title: "Sign Out",
    url: "/login",
  },
  {
    title: "Reports",
    active: true,
    navigationItems: []
  },
  {
    title: "Sign Out",
    url: "/login",
  }
]
