import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionDetails } from '../../../../utility/session';
import { LocationStrategy } from '@angular/common';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../alert.service';

@Component({
  selector: 'app-corporate-store-appt-tracker',
  templateUrl: './corporate-store-appt-tracker.component.html',
  styleUrls: ['./corporate-store-appt-tracker.component.css']
})
export class CorporateStoreApptTrackerComponent implements OnInit,OnDestroy {
  clinicDetails: any;
  ctrlKeyDown = false;
  constructor(private location: LocationStrategy, private messageService: MessageServiceService,private route: ActivatedRoute,private alertService:AlertService) {
    this.location.onPopState(() => {
      // set isBackButtonClicked to true.
      this.messageService.setNavigationClicked(true);
      return false;
    });
    this.clinicDetails = SessionDetails.GetEventDetails();
    if (this.clinicDetails && Object.keys(this.clinicDetails).length > 0) {
      sessionStorage.removeItem('event_details');
    }

    document.addEventListener('keyup', () => {
      this.ctrlKeyDown = false;
    });
    document.addEventListener('keydown', (e) => {
      if ((e.which || e.keyCode) == 116 || ((e.which || e.keyCode) == 82 && this.ctrlKeyDown)) {
        // Pressing F5 or Ctrl+R
        if (this.clinicDetails) {
          sessionStorage["event_details"] = JSON.stringify(this.clinicDetails);
        }
      } else if ((e.which || e.keyCode) == 17) {
        // Pressing  only Ctrl
        this.ctrlKeyDown = true;
      }
    });
    window.onbeforeunload = (ev) => {
      if (this.clinicDetails) {
        sessionStorage["event_details"] = JSON.stringify(this.clinicDetails);
      }
    };
  }

  ngOnInit() {
    this.alertService.ChangecorpStoreApptTrackerBannerColor$.next(true);
  }

  ngOnDestroy() {
    this.alertService.ChangecorpStoreApptTrackerBannerColor$.next(false);
  }
}
