import { tryParse } from "selenium-webdriver/http";

export class ScheduledEventsReport {
    userId: number;
    userRole: string;
    yearStartDate: string;
    quarter: string;
    outreachEffort: number;
    isCurrentSeason: number;
    reportName: string = "";
    outputType: string = "";
    constructor(user_id: number, user_role: string, outreach_start_date: string, quarter: string, outreach_effort: number, is_current_season: number, report_name: string, output_type: string) {
        this.userId = user_id;
        this.userRole = user_role;
        this.yearStartDate = outreach_start_date;
        this.quarter = quarter
        this.outreachEffort = outreach_effort;
        this.reportName = report_name;
        this.outputType = output_type;
        this.isCurrentSeason = is_current_season;
    }
}