import { Component, OnInit } from '@angular/core';
import {  FormGroup, FormBuilder } from '@angular/forms';
import * as appSettings from '../../globals';

@Component({
  selector: 'app-weeklygroupid',
  templateUrl: './weeklygroupidassignments.component.html',
  styleUrls: ['./weeklygroupidassignments.component.css']
})
export class WeeklygroupidassignmentsComponent implements OnInit {
  WeeklyGroupIdReportForm: FormGroup;
  WeeklyGroupIdReportArray: any;

  ddlYear: any = [];
  season: string;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  private groupIdLaunchDate:string="05/01/2016";
  constructor(
    private fb: FormBuilder) {
  }
  ngOnInit() {
    this.WeeklyGroupIdReportForm = this.fb.group({
      'ddlYear': [null]
    });

    for (let year: number = new Date().getFullYear(); year >= new Date(this.groupIdLaunchDate).getFullYear(); year--) {
      this.ddlYear.push({
        "yearName": year.toString() + " - " + (year + 1).toString(),
        "yearCode": year.toString()
      });
    }

  }

  onSeasonChange(){
    
    if(this.season){
      this.bindReportSeasonWeeks();
    }
  }
  bindReportSeasonWeeks() {
    /*this.WeeklyGroupId.getGroupId(this.season,appSettings.outreachStartDateIP.replace(/\//g,'-')).subscribe((resultArray: any) => {
            this.WeeklyGroupIdReportArray=resultArray;
            
      });*/
    }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
