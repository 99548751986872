import { Component, OnInit } from '@angular/core';
import { Router } from '../../../../../../node_modules/@angular/router';
import { SchedulerregistrationService } from '../../services/schedulerregistration.service';
import { saveAs as importedSaveAs } from "file-saver";
import { SessionDetails } from '../../../../utility/session';
import { environment } from '../../../../../environments/environment';
import { ErrorMessages } from "../../../../config-files/error-messages";
import {String} from 'typescript-string-operations';
import { SchedulerUserInfo } from '../../../../models/schedulerUserInfo';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-walgreens-scheduler-confirmation',
  templateUrl: './walgreens-scheduler-confirmation.component.html',
  styleUrls: ['./walgreens-scheduler-confirmation.component.css']
})
export class WalgreensSchedulerConfirmationComponent implements OnInit {
  errorPopUp: boolean = false;
  dialogSummary: string;
  dialogMsg: string;
  registrationDetails: any = {};
  siteDesignInfo: any = {};
  scheduledAppointmentInfo: any = {};
  isCancelled: boolean = false;
  clientLogo:string="";
  scheduleregistrationInfo;
  constructor(private router: Router, private scheduler_registration: SchedulerregistrationService, private location: LocationStrategy) {
         history.pushState(null, null, window.location.href);  //disabel back button
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });

    this.scheduledAppointmentInfo = SessionDetails.getSchedulerUserInfo();
    //START --
    if (!this.scheduledAppointmentInfo) {
      this.scheduledAppointmentInfo = {
        "designPk": -1,
        "clientName": "",
        "redirectTo": "schedulerRegistration",
        "apptPk": -1,
        "clinicPk": -1
      };
      
    }
    if (sessionStorage["registrationDetails"]) {
      this.scheduledAppointmentInfo.apptPk = JSON.parse(sessionStorage["registrationDetails"]).apptPk
      this.scheduleregistrationInfo = JSON.parse(sessionStorage["registrationDetails"]);
    }
    
    if (sessionStorage["isCancelled"]) {
      this.isCancelled = JSON.parse(sessionStorage["isCancelled"]);
      sessionStorage.removeItem("isCancelled");
    }
    //END --
     
    this.scheduler_registration.getSchedulerSiteDesign(this.scheduledAppointmentInfo.designPk).subscribe(result => {
      if (Object.keys(result).length > 0) {
        this.siteDesignInfo = result;       
        this.clientLogo = ((this.siteDesignInfo.siteLogoAndStyles && this.siteDesignInfo.siteLogoAndStyles.logoFile && 
            this.siteDesignInfo.siteLogoAndStyles.logoFile !== "string") ? environment.RESOURCES.CORPORATE_SCHEDULER_UPLOADED_LOGOS_URL + this.siteDesignInfo.siteLogoAndStyles.logoFile : '');
      }
    });
    if (this.scheduledAppointmentInfo.apptPk) {
      this.scheduler_registration.getSchedulerApptDetails(this.scheduledAppointmentInfo.apptPk).subscribe((appointment:any) => {
        if (Object.keys(appointment).length > 0) {
          this.registrationDetails = appointment.detailList[0];
        }
      });
    }
    else {
      this.showDialog("Error", ErrorMessages.errorSchedulerMsg);
    }
  }

  ngOnInit() {
  }
  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.errorPopUp = true;
  }
  get name() {
    var name = (this.registrationDetails.firstName) ? this.registrationDetails.firstName : '';
    if (this.registrationDetails.middleName) {
      name += ((this.registrationDetails.middleName) ? ' ' + this.registrationDetails.middleName : '');
    }

    name += ((this.registrationDetails.lastName) ? ' ' + this.registrationDetails.lastName : '');
    return (name) ? name : '';
  }

  get appointmentTime() {
    // var appt_date_time = (this.registrationDetails.apptDate) ? this.registrationDetails.apptDate : '';
    // appt_date_time += ' ' + ((this.registrationDetails.apptTime) ? this.registrationDetails.apptTime : '');
    // return (appt_date_time) ? appt_date_time : '';
    return this.registrationDetails.apptTimeRangeString ? this.registrationDetails.apptTimeRangeString:"";
  }
  download(value: any) {
    if (this.registrationDetails.apptPk) {
      this.scheduler_registration.getVarFormPDF(this.registrationDetails.apptPk).subscribe((data:any) => {
        
        var res = data;

        if (res.pdfPath != "") {
          let url = /*environment.WalgreenDomain*/  res.pdfPath;
          localStorage.setItem("isApp", "yes");
          window.open(url);
     
        //importedSaveAs(res, 'VAR Form.pdf');        
        }
      });
    }
    else {
      this.showDialog("Error", ErrorMessages.errorVARForm);
    }
  }
  downloadVISForm(path: string) {
    var new_url = window.location.protocol + '//' + window.location.host + '/';
    let url = new_url + path;
    localStorage.setItem("isApp", "yes");
    window.open(url);
  }

  printConfirmation() {
    window.print();
  }
  goToResources(event){
    if(this.registrationDetails.isCovidClinic){
      window.open("https://tdwagsoutreachcfiles.blob.core.windows.net/pdf/Vaccinination_Authorization_Record_Instructions.pdf");
    }
    else {
    window.open('visresources');
  }
    
  }
  openSite() {    
    sessionStorage.removeItem('registrationDetails');
    console.log('TODO');
    //window.open(this.schedulerLink, '_blank');
 }
 editAppointment()
 {
   if(sessionStorage['registrationDetails']== null || sessionStorage['registrationDetails']== undefined)
   {
   sessionStorage['registrationDetails']= JSON.stringify(this.scheduleregistrationInfo);   
   }
   this.router.navigate(['/schedulerRegistration']);   
 }
}
