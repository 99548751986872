// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logTitle1 {
    font-family: Verdana;
    font-size: 13px;
    color: #3096D8;
}


::ng-deep a.p-accordion-header-link
{
  color: #333319 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/store/components/contactlog/contactlog.component.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;IACf,cAAc;AAClB;;;AAGA;;EAEE,yBAAyB;AAC3B","sourcesContent":[".logTitle1 {\r\n    font-family: Verdana;\r\n    font-size: 13px;\r\n    color: #3096D8;\r\n}\r\n\r\n\r\n::ng-deep a.p-accordion-header-link\r\n{\r\n  color: #333319 !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
