import { Injectable } from "@angular/core";
@Injectable()
export class Globalvariables {
    spanishLanguage : string;
    headerText : string;
    attachment: string;
    message : string;
  
    imz: string;
    payment: string;
    rates : string;
  
    englishLanguage : string;
    enheader : string;
    enattachment: string;
    language: string;
    showBtnEn : boolean = false;
    showBtnSp : boolean = true;
    enlanguageSelection: boolean;
    splanguageSelection: boolean;
    json : string;
}
