import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorMessages } from '../../../../config-files/error-messages';
import { LeadOpportunitiesService } from '../../../contractaggreement/services/lead-opportunities.service';
import { saveAs as importedSaveAs } from "file-saver";
import { environment } from '../../../../../environments/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-covid-contact-us-report',
  templateUrl: './covid-contact-us-report.component.html',
  styleUrls: ['./covid-contact-us-report.component.css']
})

export class CovidContactUsReportComponent implements OnInit {
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  loading: boolean;
  lgRData: any = [];
  ddlFormat: string = '0';
  canExport: boolean = true;
  constructor(private leadService: LeadOpportunitiesService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.generateReport();
  }

  generateReport(): void {
    this.loading = true;
    this.spinner.show();
    let leadModel = { isCurrentSeason: 1, isCovid: true, fromDate: new Date(), toDate: new Date(),covidOpportunityTYpe:0 };
    this.leadService.getContactUsReport(leadModel).subscribe((resultArray: any) => {
      this.lgRData = resultArray;
      this.loading = false;
      this.canExport = true;
      this.spinner.hide();
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
      this.lgRData = [];
    });
  }
  generateReportFormat(): void {
    if (this.ddlFormat == '0') {
      return;
    }
    this.loading = true;
    this.spinner.show();
    let leadModel = { isCurrentSeason: 1, isCovid: true, fromDate: new Date(), toDate: new Date(), ddlFormat: "xlsxv",covidOpportunityTYpe:0 };
    this.leadService.exportReportData(leadModel).subscribe((res: any) => {
      importedSaveAs(res.body, 'COVID ContactUs.xlsx');
      this.loading = false;
      this.spinner.hide();
    }, error => {
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.spinner.hide();
    });
  }

  // generateReportFormat2(): void {
  //   this.loading = true;
  //   if (this.ddlFormat == '0') {
  //     return;
  //   }
  //   if (this.ddlFormat == "xlsxv1") {
  //     this.leadService.exportReportDataV2().subscribe(res => {
  //       //importedSaveAs(res, this.scheduleclinicreport.filename);
  //       var dat = res.json();
  //       if (dat) {
  //         let url = environment.API_URL + "/" + dat;
  //         window.open(url);
  //       }
  //       this.loading = false;

  //     }, error => {
  //       this.loading = false;
  //       this.showDialog("Error", ErrorMessages["error"], true);
  //     });
  //   }
  // }
  validateExportType() {
    if (this.ddlFormat == "0" || !this.canExport)
      return true;
    else
      return false;
  }
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
