export const thankyouMessageData: any =
{
    "NorestrictedState": {
      "IMZWithCashPaid": {
        "message1": "A completed VAR form will be required for each participant before receiving an Immunization. Making these available prior to the clinic will allow participants to be immunized more efficiently.",
        "message2": ""
      },
      "IMZWithCorporateInvoice": {
        "message1": "Walgreens corporate office will directly bill the invoice contact entered on the contract one month after the clinic has been conducted. This will be delivered electronically in a secure email format.",
        "message2": ""
      },
      "IMZWithSubmitClaims": {
        "message1": "A completed VAR form will be required for each participant before receiving an Immunization. Making these available prior to the clinic will allow participants to be immunized more efficiently.",
        "message2": "Please ask all participants to provide a photocopy of their insurance card for processing purposes."
      },
      "IMZWithCorporateInvoiceAndCashPaid": {
        "message1": "Walgreens corporate office will directly bill the invoice contact entered on the contract one month after the clinic has been conducted. This will be delivered electronically in a secure email format.",
        "message2": ""
      },
      "IMZWithSubmitClaimsAndCashPaid": {
        "message1": "A completed VAR form will be required for each participant before receiving an Immunization. Making these available prior to the clinic will allow participants to be immunized more efficiently.",
        "message2": "Please ask all participants to provide a photocopy of their insurance card for processing purposes."
      },
      "IMZWithSubmitClaimsAndCorporateInvoice": {
        "message1": "Walgreens corporate office will directly bill the invoice contact entered on the contract one month after the clinic has been conducted. This will be delivered electronically in a secure email format.",
        "message2": "Please ask all participants to provide a photocopy of their insurance card for processing purposes."
      },
      "IMZWithSubmitPharmacyInsuranceAndCorporateInvoice": {
        "message1": "A completed VAR form will be required for each participant before receiving an Immunization. Making these available prior to the clinic will allow participants to be immunized more efficiently.",
        "message2": "Walgreens corporate office will directly bill the invoice contact entered on the contract one month after the clinic has been conducted. This will be delivered electronically in a secure email format. Please ask all participants to provide a photocopy of their insurance card for processing purposes."
      },
      "IMZWithAllCombination": {
        "message1": "Walgreens corporate office will directly bill the invoice contact entered on the contract one month after the clinic has been conducted. This will be delivered electronically in a secure email format.",
        "message2": "Please ask all participants to provide a photocopy of their insurance card for processing purposes."
      },
      "IMZWithCorporateVoucher": {
        "message1": "Walgreens corporate office will directly bill the invoice contact entered on the contract one month after the clinic has been conducted. This will be delivered electronically in a secure email format.",
        "message2": "Please ask all participants to provide a photocopy of their insurance card for processing purposes."
      },
      "IMZWithCorporateVoucherNo": {
        "message1": "A completed VAR form will be required for each participant before receiving an Immunization. Making these available prior to the clinic will allow participants to be immunized more efficiently.",
        "message2": "Walgreens corporate office will directly bill the invoice contact entered on the contract one month after the clinic has been conducted. This will be delivered electronically in a secure email format."
      },
      "IMZWithoutCorporate": {
        "message1": "A completed VAR form will be required for each participant before receiving an Immunization. Making these available prior to the clinic will allow participants to be immunized more efficiently.",
        "message2": "Please ask all participants to provide a photocopy of their insurance card for processing purposes."
      },
      
    },
    "RestrictedState1": {
      "IMZWithCorporateVoucher": {
        "message1": "Message 1",
        "message2": "Message 2"
      },
      "IMZWithCorporateVoucherNo": {
        "message1": "Message 1",
        "message2": "Message 2"
      },
      "IMZWithoutCorporate": {
        "message1": "Message 1",
        "message2": "Message 2"
      },
      
    },
    "RestrictedState2": {
      "IMZWithCorporateVoucher": {
        "message1": "Message 1",
        "message2": "Message 2"
      },
      "IMZWithCorporateVoucherNo": {
        "message1": "Message 1",
        "message2": "Message 2"
      },
      "IMZWithoutCorporate": {
        "message1": "Message 1",
        "message2": "Message 2"
      },
      
    },
    
  }