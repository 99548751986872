import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, Inject, ComponentRef, ComponentFactory } from '@angular/core';
import { UploadstoreprofilesComponent } from '../uploadstoreprofiles/uploadstoreprofiles.component';
import { StoreprofilereportandupdateComponent } from '../storeprofilereportandupdate/storeprofilereportandupdate.component';
import { StoreProfileUploadCompleteComponent } from '../store-profile-upload-complete/store-profile-upload-complete.component';
import { UpdatestoreprofileComponent } from '../updatestoreprofile/updatestoreprofile.component';
@Component({
  selector: 'app-storeprofileuploader',
  templateUrl: './storeprofileuploader.component.html',
  styleUrls: ['./storeprofileuploader.component.css']
})
export class StoreprofileuploaderComponent implements OnInit {

  viewSteps: boolean = true;
  uploadedFileName: string = '';
  totalSteps: number = 3;
  currentStep: number = 1;
  component: any;
  uploadedFileKey: string;
  uploadMode: number;
  partialFeedRecords: any = [];
  completedStatusMsg: string;
  //selectedStore: any = {};
  @ViewChild('childcontainer', { static: true, read: ViewContainerRef }) childCmpHost;//: ViewContainerRef;
  @ViewChild('dynamiccontainer', {static: true, read: ViewContainerRef }) dynamiCmpHost;//: ViewContainerRef;
  dynamicCompRef: any
  compRef: any;
  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.loadComponent();
  }

  ngOnDestroy() {
    if (this.compRef) {
      this.compRef.destroy();
    }
    this.childCmpHost.clear();
  }

  loadComponent() {
    try {
      switch (this.currentStep) {
        case 1:
          this.component = UploadstoreprofilesComponent;
          break;
        case 2:
          this.component = StoreprofilereportandupdateComponent;
          break;
        case 3:
          this.component = StoreProfileUploadCompleteComponent;
          break;
        default:
          break;
      }

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
      this.childCmpHost.clear();
      this.compRef = this.childCmpHost.createComponent(componentFactory);

      if (this.compRef.instance.uploadedFileKey !== undefined && this.compRef.instance.uploadedFileKey !== null) {
        this.compRef.instance.uploadedFileKey = this.uploadedFileKey;
      }
      if (this.compRef.instance.uploadMode) {
        this.compRef.instance.uploadMode = this.uploadMode;
      }


      //if (this.partialFeedRecords.length > 0 && this.compRef.instance.generatedStoreProfileData != undefined && this.compRef.instance.generatedStoreProfileData != null) {
      //  this.compRef.instance.generatedStoreProfileData = this.partialFeedRecords;
      //}



      if (this.compRef.instance.invalidFile !== undefined && this.compRef.instance.invalidFile === null) {
        this.compRef.instance.invalidFile = false;
      }
      // if (this.compRef.instance.selectedStore != undefined && this.compRef.instance.selectedStore == null) {
      //   this.selectedStore = this.compRef.instance.selectedStore;
      // }
      //if (this.compRef.instance.storeUpdateFormGroup != undefined && this.compRef.instance.storeUpdateFormGroup == null) {
      //   this.storeUpdateFormGroup = this.compRef.instance.storeUpdateFormGroup;
      // }

      if (this.compRef.instance.completedStatusMsg !== undefined  && this.compRef.instance.completedStatusMsg !== null) {
         this.compRef.instance.completedStatusMsg = this.completedStatusMsg;
       }

      if (this.compRef.instance.loadEditForm) {
        this.compRef.instance.loadEditForm.subscribe($event => {
          this.loadEditForm($event)
        });
      }

      if (this.compRef.instance.loadPage) {
        this.compRef.instance.loadPage.subscribe($event => {
          this.goToStep($event)
        });
      }
      this.compRef.changeDetectorRef.detectChanges();

      //this.childCmpHost = ref;
      //let invalidFile = (ref.instance).invalidFile;
    }
    catch (e) {
      console.log(e);
    }
  }

  goToStep(event_data: any) {
    this.currentStep = event_data.stepNumber;
    this.uploadedFileKey = event_data.uploadedOn;
    this.uploadMode = event_data.uploadMode;
    //this.completedStatusMsg= event_data.completedStatusMsg;
    if (event_data.completedStatusMsg) {
      this.completedStatusMsg = event_data.completedStatusMsg;
    }
    //if (event_data.partialFeedRecords) {
    //  this.partialFeedRecords = event_data.partialFeedRecords;
    //}
    this.loadComponent();
  }
  loadEditForm(event_data: any) {
    const dynCompFactory = this.componentFactoryResolver.resolveComponentFactory(UpdatestoreprofileComponent);
    this.dynamiCmpHost.clear();
    this.dynamicCompRef = this.dynamiCmpHost.createComponent(dynCompFactory);
    if (this.dynamicCompRef.instance.uploadedFileKey !== undefined && this.dynamicCompRef.instance.uploadedFileKey !== null) {
      this.dynamicCompRef.instance.uploadedFileKey = event_data.uploadedOn;
    }
    if (this.dynamicCompRef.instance.uploadMode) {
      this.dynamicCompRef.instance.uploadMode = event_data.uploadMode;
    }
    if (this.dynamicCompRef.instance.selectedStore) {
      this.dynamicCompRef.instance.selectedStore = event_data.selectedStore;
    }

    if (this.dynamicCompRef.instance.cancelUpdateProfile) {
        this.dynamicCompRef.instance.cancelUpdateProfile.subscribe($event => {
          this.cancelUpdateStoreProfile($event);
        });
      }
      if (this.dynamicCompRef.instance.updateProfile) {
        this.dynamicCompRef.instance.updateProfile.subscribe($event => {
          this.updateStoreProfile($event);
        });
      }

    this.dynamicCompRef.changeDetectorRef.detectChanges();
    this.compRef.instance.canShowEditStorePop = true;
  }
  updateStoreProfile(event_data) {
    this.compRef.instance.selectedStore = event_data;
     if (this.dynamicCompRef) {
      this.dynamicCompRef.destroy();
    }
    this.dynamiCmpHost.clear();
    this.compRef.instance.updateStore();
  }

  cancelUpdateStoreProfile(event) {
    if (this.dynamicCompRef) {
      this.dynamicCompRef.destroy();
    }
    this.dynamiCmpHost.clear();
    this.compRef.instance.cancelUpdate();
  }
}
