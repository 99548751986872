import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { Subject, Subscription } from '../../../../../../node_modules/rxjs';

@Component({
  selector: 'app-corporateuploaderstatus',
  templateUrl: './corporateuploaderstatus.component.html',
  styleUrls: ['./corporateuploaderstatus.component.css']
})
export class CorporateuploaderstatusComponent implements OnInit {
  subscription: Subscription;
  step: number = 1;
  clinicstatus: string = "Completed Clinic";
  clickedPath = 'LocalClinicProgramDetails';
  message: any;
  store_id: any;

  @Input("totalSteps") totalSteps: any;
  @Input("viewSteps") viewSteps: any;
  @Input("currentStep") currentStep: any;

  private onDestroy$ = new Subject<void>();
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes["viewSteps"]) {
      this.viewSteps = changes["viewSteps"].currentValue;
    }
    if (changes["totalSteps"]) {
      this.totalSteps = changes["totalSteps"].currentValue;
    }
    this.currentStep = ((changes["currentStep"].currentValue) ? changes["currentStep"].currentValue : this.step);
    
    this.GoToStep(this.currentStep);
  }

  GoToStep(step: number) {
    if (step == 2) {
      step = 2;
     // this.clinicstatus = 'Completed Clinic';
     
    }
    if (step == -1) {
      if (this.totalSteps == 2) {
        step = 2;
       // this.clinicstatus = 'Cancelled Clinic';
      } else if (this.totalSteps == 3) {
        step = 3;
       // this.clinicstatus = 'Cancelled Clinic';
      }
    }

    this.step = step;
    return;
  }

}
