export class UserAccessLinkUserList {
    userPk: number;
    email: number;
    userRole: string;
}

export class ErrorS {
    errorCode: number;
    errorMessage: string;
}

export class SendUserAccessLink {
    userAccessLinkUserList: UserAccessLinkUserList[];
    errorS: ErrorS;
}