import { Component, OnInit } from '@angular/core';
import { defaultSiteDesignInfo } from '../../../../JSON/schedulerDefaultValues';
import { environment } from '../../../../../environments/environment';
import { LocationStrategy } from '@angular/common';
import { MessageServiceService } from '../../../store/services/message-service.service';

@Component({
  selector: 'app-scheduler-appttracking',
  templateUrl: './scheduler-appttracking.component.html',
  styleUrls: ['./scheduler-appttracking.component.css']
})
export class SchedulerAppttrackingComponent implements OnInit {
  showMe: boolean = true;
  corpDesignInfo: any;
  clientLogo: any;
  BannerColor: string = '';
  BannerTextColor: string = '';
  ButtonBgColor: string = '';
  ButtonTextcolor: string = '';
  clientInfo: any;
  clinicEventDetails:any;
  ctrlKeyDown = false;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  constructor(private messageService: MessageServiceService, private location: LocationStrategy) {
    this.location.onPopState(() => {
      this.messageService.setNavigationClicked(true);
      return false;
    });
    if (sessionStorage["corporateTrackDesignInfo"]) {
      this.corpDesignInfo = JSON.parse(sessionStorage["corporateTrackDesignInfo"]);
      sessionStorage.removeItem("corporateTrackDesignInfo");
    }
    if (!this.corpDesignInfo) {
      this.corpDesignInfo["siteLogoAndStyles"] = defaultSiteDesignInfo.siteLogoAndStyles;
    }
    this.clientLogo = ((this.corpDesignInfo && this.corpDesignInfo.siteLogoAndStyles && this.corpDesignInfo.siteLogoAndStyles.logoFile &&
      this.corpDesignInfo.siteLogoAndStyles.logoFile != "string") ? environment.RESOURCES.CORPORATE_SCHEDULER_UPLOADED_LOGOS_URL + this.corpDesignInfo.siteLogoAndStyles.logoFile : '');

    // if (this.corpDesignInfo && this.corpDesignInfo.clientInfo.clientId == 0) {
    //   this.showMe = false;
    //   this.clientLogo = "assets/images/walgreens_1901_logo.png";
    // }


    if (sessionStorage["corporateTrackInfo"]) {
      this.clientInfo = JSON.parse(sessionStorage["corporateTrackInfo"]);
      sessionStorage.removeItem("corporateTrackInfo");
    }
    if (sessionStorage["event_details"]){
      this.clinicEventDetails = JSON.parse(sessionStorage["event_details"]);
      sessionStorage.removeItem("event_details");
    }
    
    this.BannerColor = '#323264';
    this.BannerTextColor = this.corpDesignInfo.siteLogoAndStyles.bannerTextColor;
    this.ButtonBgColor = this.corpDesignInfo.siteLogoAndStyles.buttonColor;
    this.ButtonTextcolor = this.corpDesignInfo.siteLogoAndStyles.buttonTextColor;

    document.addEventListener('keyup', () => {
      this.ctrlKeyDown = false;
    });
    document.addEventListener('keydown', (e) => {
      if ((e.which || e.keyCode) == 116 || ((e.which || e.keyCode) == 82 && this.ctrlKeyDown)) {
        // Pressing F5 or Ctrl+R
        //e.preventDefault();
        if (this.clientInfo) {
          sessionStorage["corporateTrackInfo"] = JSON.stringify(this.clientInfo);
        }
        if(this.corpDesignInfo){
          sessionStorage["corporateTrackDesignInfo"]=JSON.stringify(this.corpDesignInfo);
        }
        if (this.clinicEventDetails){
          sessionStorage["event_details"] = JSON.stringify(this.clinicEventDetails);
        }
      } else if ((e.which || e.keyCode) == 17) {
        // Pressing  only Ctrl
        this.ctrlKeyDown = true;
      }
    });
    window.onbeforeunload = (ev) => {
      if (this.clientInfo) {
        sessionStorage["corporateTrackInfo"] = JSON.stringify(this.clientInfo);
      }
      if(this.corpDesignInfo){
        sessionStorage["corporateTrackDesignInfo"]=JSON.stringify(this.corpDesignInfo);
      }
      if (this.clinicEventDetails){
        sessionStorage["event_details"] = JSON.stringify(this.clinicEventDetails);
      }
    };
  }

  ngOnInit() {
  }
  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.showDlg = show_dialog;
  }
}
