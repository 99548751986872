import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import * as _ from "lodash";
import { Util } from '../../../../utility/util';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { LeadOpportunitiesService } from '../../services/lead-opportunities.service';
import { NgxSpinnerService } from "ngx-spinner";
import { recaptchaRequest } from '../../../../models/localLeadsSave';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SelectType } from '../../../../models/OutreachOpportunity';
import { LookupService } from '../../../../lookup.service';
import { ContactUs } from '../../../../models/contactUsLocation';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  routerSubscription: Subscription;
  leadGenerationForm: FormGroup;
  dialogMsg: string = '';
  dialogSummary: string = '';
  locListModalDlg: boolean = false;
  successDlg: boolean = false;
  vacn: any = [];
  paymentType: any = [];

  selectedvcInfo: [string];
  showOtherVaccinationInputField:boolean = false;
  vaccinationsCheckBoxes: any[] = [
    { "vaccineVal": 1, "vaccineName": "Flu Trivalent" },
    { "vaccineVal": 2, "vaccineName": "Flu HD (65+)" },
    { "vaccineVal": 7, "vaccineName": "COVID19" },
    { "vaccineVal": 3, "vaccineName": "Pneumonia" },
    { "vaccineVal": 4, "vaccineName": "Tdap (Whooping Cough)" },
    { "vaccineVal": 5, "vaccineName": "Shingles" },
    { "vaccineVal": 8, "vaccineName": "RSV" },
    { "vaccineVal": 6, "vaccineName": "Other" }
  ]

  paymentTypeCheckBoxes: any[] = [
    { "paymentTypeValue": 2, "paymentTypeName": "Insurance", "paymentMessage": "Walgreens can bill patient’s insurance directly and the patient would be responsible for the copay. Walgreens can bill many pharmacy and medical plans." },
    { "paymentTypeValue": 1, "paymentTypeName": "Cash", "paymentMessage": "Payment for the vaccine can be collected from the patient receiving the immunization at the time of vaccination by cash or via check from the employer/location." },
    { "paymentTypeValue": 3, "paymentTypeName": "Direct Bill", "paymentMessage": "Employer/Location will pay for employee immunizations. They will receive an invoice directly from Walgreens for the number of immunizations provided. No payment will be collected at the time of the immunization." },
  ]
  redirect: boolean = false;
  minDateValue: Date;
  fNlNameRegEx: RegExp = /^(?!\d+$)(?:[a-zA-Z \.\,\'\-]*)?$/;
  cityRegEx: RegExp = /^(?!\d+$)(?:[a-zA-Z \-]*)?$/;
  address1N2: RegExp = /^[ A-Za-z0-9#'.,-/]*$/;
  maxDateValue: Date;
  isRecaptchaVerified: boolean = true;
  selectedIndustryText: any;
  industries: SelectType[] = [];
  otherIndustryIndex: number;

  constructor(
    private formBuilder: FormBuilder,
    private utility: Util, 
    private leadService: LeadOpportunitiesService, 
    private spinner: NgxSpinnerService, 
    public router: Router,
    private _lookupService: LookupService
  ) {
  }

  ngOnInit() {
    this.leadGenerationForm = this.formBuilder.group({
      lgCntrlynapmoc784: ['',Validators.required],
      lgCntrlIndustry: ['', Validators.required],
      lgCntrlAlternateFN: ['', [Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]],
      lgCntrlAlternateLN: ['', [ Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]],      
      lgCntrlAlternateJobTitle: [''],
      lgCntrlAltPhoneNumber: [''],
      lgCntrlAltPhoneNumberExt: [''],
      lgCntrlAlternateEmail: ['', [Validators.email, Validators.pattern(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/)]],      
      
      lgCntrlofnIsnoitaniccav193: this.createvaccinationsInfo(this.vaccinationsCheckBoxes),
      lgCntrletaDcinilc137: [''],
      lgCntrletaDcinilc11437:[''],
      lgCntrlstnapicitraPfOrebmuNdetamitse432: ['', [Validators.required, Validators.maxLength(5)]],
      lgCntrlstnapicitraPfOrebmuNdetamitse435: ['', [Validators.required, Validators.maxLength(5)]],
      lgCntrlsetatSluMcoLpme035: ['', Validators.required],
      hadClinicInPast: ['', Validators.required],
      lgCntrlepyTtnemyap294: this.createPaymentType(this.paymentTypeCheckBoxes),
      lgCntrlmgrPhcruoVsi530: ['', Validators.required],
      recaptchaReactive: ['default', Validators.required],
      vaccinationArray: [null],
      paymentTypeArray: [null],
      standardFormVaccines:[null],
      isCovid:[false],
      covidOpportunityType:[0],
      lgCntrlynapmo2284:['',[Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]],
      lgCntrlynapmo2184:['',[Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]],
      lgCntrlynapmo1184:['',[Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]],
      lgCntrlynapmoc3284:[''],
      lgCntrlOtherIndustry: [''],
      locations: this.formBuilder.array([]),
    });
    setTimeout(() => {
      this.resetONBackBtn();
    }, 100);
    {
      this.minDateValue = new Date();
      this.maxDateValue = new Date(this.minDateValue.getFullYear() + 1, this.minDateValue.getMonth(), this.minDateValue.getDate() - 1);
    }
    this.addLocation(undefined);
    this._lookupService.getLookUpValues('Industry', 'StandardOpportunity')
        .subscribe((response: SelectType[]) => {
          this.industries = response;
          this.otherIndustryIndex = this.industries?.find(industry => industry?.value?.toLocaleLowerCase() === 'other')?.id;
        });
    
  }

  createvaccinationsInfo(vaccinationsInfoInputs) {
    const arr = vaccinationsInfoInputs.map(vcVals => {
      return new FormControl(null);
    });
    return new FormArray(arr);
  }

  createPaymentType(paymentInp) {
    const arr = paymentInp.map(vcVals => {
      return new FormControl(null);
    });
    return new FormArray(arr);
  }

  getSelectedPaymentTypeInfo(ev, val) {
    this.paymentField('');
    if (ev.target.checked) {
      this.paymentType.push(val);
    } else {
      let i = this.vacn.indexOf(val);
      this.paymentType.splice(i, 1);
    }
  }

  getSelectedvaccinationsInfo(ev, val) {

    this.seperateFiedls('');
    if (ev.target.checked) {
      this.vacn.push(val);
      if(val===6){
        this.leadGenerationForm.controls.lgCntrlynapmoc3284.setValidators(Validators.required);
        this.leadGenerationForm.controls.lgCntrlynapmoc3284.updateValueAndValidity();
        this.showOtherVaccinationInputField = true;
        document.getElementById('lgCntrlynapmoc3284').classList.remove('has-error');
        document.getElementById('lgCntrlynapmoc3284').classList.remove('has-feedback');
          
      }
      else if(val == 6 && !ev.target.checked)
      {
        this.leadGenerationForm.controls.lgCntrlynapmoc3284.setValidators(Validators.required);
        this.leadGenerationForm.controls.lgCntrlynapmoc3284.updateValueAndValidity();
        this.showOtherVaccinationInputField = true;        
      }
    } else {
      let i = this.vacn.indexOf(val);
      this.vacn.splice(i, 1);
      if (val == 6)
      {
      this.showOtherVaccinationInputField = false;
      }
      this.leadGenerationForm.controls.lgCntrlynapmoc3284.clearValidators();
        this.leadGenerationForm.controls.lgCntrlynapmoc3284.updateValueAndValidity();
        document.getElementById('lgCntrlynapmoc3284').classList.remove('has-error');
        document.getElementById('lgCntrlynapmoc3284').classList.remove('has-feedback');
    }
  }

  getSelectedIndustry(ev) {
    this.selectedIndustryText =  ev.target.options[ev.target.options.selectedIndex].text;
    const otherIndustryIndex = this.industries?.find(industry => industry?.value?.toLocaleLowerCase() === 'other')?.id;
    const industry = this.leadGenerationForm.controls['lgCntrlIndustry']?.value;
    
    if (industry == otherIndustryIndex) {
      this.leadGenerationForm.controls.lgCntrlOtherIndustry.setValidators(Validators.required);
      this.leadGenerationForm.controls.lgCntrlOtherIndustry.updateValueAndValidity();
      this.showOtherVaccinationInputField = true;
      document.getElementById('lgCntrlOtherIndustry').classList.remove('has-error');
      document.getElementById('lgCntrlOtherIndustry').classList.remove('has-feedback');
    }
    else {
      this.leadGenerationForm.controls.lgCntrlOtherIndustry.clearValidators();
      this.leadGenerationForm.controls.lgCntrlOtherIndustry.updateValueAndValidity();
      document.getElementById('lgCntrlOtherIndustry').classList.remove('has-error');
      document.getElementById('lgCntrlOtherIndustry').classList.remove('has-feedback');
    }

  }

  getSelectedvaccinationsInfoName() {
    this.selectedvcInfo = _.filter(
      this.selectedvcInfo,
      function (vcVals) {
        if (vcVals !== false) {
          return vcVals;
        }
      }
    );
  }

  resolved(captchaResponse: string) {
   this.isRecaptchaVerified = true;
    if (captchaResponse) {
      let verify = new recaptchaRequest();
      verify.response = captchaResponse;
      this.leadService.siteVerify(verify).subscribe((data:any) => {
        let rsp = data;
        if (rsp.success == true) {
          this.isRecaptchaVerified = true;
          this.leadGenerationForm.controls.recaptchaReactive.setValue(rsp.success);
          this.leadGenerationForm.controls.recaptchaReactive.updateValueAndValidity();
        }
        else {
          this.showDialogV3("Error", "Recaptcha Verification Failed. Please retry.");
        }
      },
        err => {
          this.showDialogV3("Error", "Expectaion Failed");
        })
    }
  }

  onSubmit(formValues) {
    this.trimFormValues(formValues);
    if (this.leadGenerationForm.valid && !(this.leadGenerationForm.controls['lgCntrlIndustry'].value == this.otherIndustryIndex && this.leadGenerationForm.controls['lgCntrlOtherIndustry'].value == '' ))
    {      
      let vaccinationDescArray:any = [];
      this.vacn.forEach(element => {
       let vaccine =  this.vaccinationsCheckBoxes.filter(p=>p.vaccineVal == element);
       if(vaccine)
       {
         if (vaccine[0].vaccineName == "Other")
         {
          vaccine[0].vaccineName = vaccine[0].vaccineName + " (" + this.leadGenerationForm.controls['lgCntrlynapmoc3284'].value + ")";
        }      
        vaccinationDescArray.push(vaccine[0].vaccineName);
       }        
      });
      if(this.leadGenerationForm.controls.lgCntrlmgrPhcruoVsi530.value == 3) {
        this.leadGenerationForm.controls.lgCntrletaDcinilc137.setValue(null);
        this.leadGenerationForm.controls.lgCntrletaDcinilc11437.setValue(null);
        this.leadGenerationForm.controls.lgCntrlstnapicitraPfOrebmuNdetamitse432.setValue(0);
        this.leadGenerationForm.controls.lgCntrlstnapicitraPfOrebmuNdetamitse435.setValue(0);
        this.leadGenerationForm.updateValueAndValidity();
      }
      let standardFormVaccines = vaccinationDescArray.join(', ');
      this.leadGenerationForm.controls['vaccinationArray'].patchValue(this.vacn);
      this.leadGenerationForm.controls['paymentTypeArray'].patchValue(this.paymentType);
      this.leadGenerationForm.controls['standardFormVaccines'].patchValue(standardFormVaccines);      
      this.redirect = false;
      //if(this.leadGenerationForm.controls['recaptchaReactive'].value=='' || !this.isRecaptchaVerified || this.leadGenerationForm.controls['recaptchaReactive'].value==null){
      //  this.showDialogV3("Error", "Please verify that you are not a robot.");
      //  return false;
     // }
      if(this.leadGenerationForm.controls['lgCntrlstnapicitraPfOrebmuNdetamitse432'].value<50 && 
      this.leadGenerationForm.controls['lgCntrlmgrPhcruoVsi530'].value != '3'){
        this.showDialogV3("Minimum Requirement", 'There is a minimum of 50 participants required to request an on-site clinic. Please contact your local Walgreens for alternative options or select "Voucher Program Only" when filling out the form.');
        window.scroll(40,40);
        return false;
      }
      this.spinner.show();   
      
      this.leadGenerationForm.value.lgCntrlIndustry = this.selectedIndustryText;      
      this.leadService.saveLeadOpportunitesEnquiries(this.leadGenerationForm.value).subscribe((data:any) => {
        if (data == 1) {
          this.redirect = true;
          this.spinner.hide();
          this.showDialogV3("Thank you!", "Your contact information has been submitted and a local representative will be in touch shortly.");
        }
        else if (data == -1) {
          this.spinner.hide();
          this.showDialogV3("Error", "Bad Geocode -- Invalid Request.");
        }
        else if (data == -2) {
          this.spinner.hide();
          this.showDialogV3("Error", "Bad Geocode -- Over Query Limit.");
        }
        else if (data == -3) {
          this.spinner.hide();
          this.showDialogV3("Error", "Bad Geocode -- Request Denied.");
        }
        else if (data == -4) {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages['geo_zero_results']);
        }
        else if (data == -5) {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages['geo_unknown_error']);
        }
        else if (data == -6) {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages['geo_timed_out']);
        }
        else {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages["error"]);
        }
      }, err => {
        this.spinner.hide();
        this.showDialogV3("Error", "Expectation failed.");
      });
    
  }
    else {
      this.spinner.hide();
      this.validateFormNSendAlert();
      this.validateNResetCaptcha();
    }
  }

  isFieldValid(field: string) {
    return !this.leadGenerationForm.get(field).valid && this.leadGenerationForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  seperateFiedls(e) {
    if (e == '') {
      if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193) {
        if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193.includes(true)) {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-feedback');
        }
      }
    } else {
      if (e.toElement.checked && e.toElement.checked == true && this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193) {
        if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193.includes(true) || e.toElement.checked == true) {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-feedback');
        }
      }
    }
  }

  paymentField(e) {
    if (e == '') {
      if (this.leadGenerationForm.value.lgCntrlepyTtnemyap294) {
        if (this.leadGenerationForm.value.lgCntrlepyTtnemyap294.includes(true)) {
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-feedback');
        }
      }
    } else {
      if (e.toElement.checked && e.toElement.checked == true && this.leadGenerationForm.value.lgCntrlepyTtnemyap294) {
        if (this.leadGenerationForm.value.lgCntrlepyTtnemyap294.includes(true) || e.toElement.checked == true) {
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-feedback');
        }
      }
    }
  }

  showDialogV2(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.locListModalDlg = true;
  }
  showDialogV3(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.successDlg = true;
  }
  okClickedV2() {
    this.locListModalDlg = false;
  }
  goToWagsOnClose() {
    this.successDlg = false;
    if (this.redirect) {
      window.location.href = 'http://walgreens.com';
    }
  }
  public trimFormValues(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      if (key != "lgCntrlofnIsnoitaniccav193" && key != "lgCntrlepyTtnemyap294" 
      && key != "lgCntrletaDcinilc137" && key != "recaptchaReactive" 
      && key != "vaccinationArray" && key != "paymentTypeArray"
      && key!= "locations" && key != "isCovid" && key !="covidOpportunityType"
      && key != "lgCntrletaDcinilc11437" && key != "standardFormVaccines") {
        formGroup.get(key)?.setValue(formGroup?.get(key).value?.toString()?.trim())
      }
    });
  }
  validateFormNSendAlert() {
    this.utility.validateAllFormFields(this.leadGenerationForm);
    this.seperateFiedls('');
    this.paymentField('');
    this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['requiredFields']);
    return false;
  }
  onlyAlphabets(e, ctrl) {
    if (ctrl == "fNlName") {
      return this.fNlNameRegEx.test(e.key);
    } else if (ctrl == "city") {
      return this.cityRegEx.test(e.key);
    }
    else if (ctrl == "add1N2") {
      return this.address1N2.test(e.key);
    }
  }
  validateNResetCaptcha() {
    this.leadGenerationForm.controls.recaptchaReactive.setValue('default');
    this.leadGenerationForm.controls.recaptchaReactive.updateValueAndValidity();
  }
  resetONBackBtn() {
    this.leadGenerationForm.controls.lgCntrlrebmuNhp802.setValue('');
    this.leadGenerationForm.controls.lgCntrlrebmuNhp802.updateValueAndValidity();
    this.leadGenerationForm.controls.lgCntrletats936.setValue('');
    this.leadGenerationForm.controls.lgCntrletats936.updateValueAndValidity();
  }
  NavigateToImmContactUs()
  {
    this.router.navigateByUrl("/Immunization-Contact-Us");
  }

  handleClinicTypeSelection(){
    if(this.leadGenerationForm.controls.lgCntrlmgrPhcruoVsi530.value == 3){
      this.leadGenerationForm.controls.lgCntrletaDcinilc137.setValue('');
      this.leadGenerationForm.controls.lgCntrletaDcinilc11437.setValue('');
      this.leadGenerationForm.controls.lgCntrlstnapicitraPfOrebmuNdetamitse435.clearValidators();
      this.leadGenerationForm.controls.lgCntrlsetatSluMcoLpme035.clearValidators();
      this.leadGenerationForm.controls.hadClinicInPast.clearValidators();
      document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-error');
      document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-feedback');
      this.leadGenerationForm.updateValueAndValidity();
    } else {
      this.leadGenerationForm.controls.lgCntrlstnapicitraPfOrebmuNdetamitse435.addValidators([Validators.required, Validators.maxLength(5)]);
      this.leadGenerationForm.controls.lgCntrlsetatSluMcoLpme035.addValidators([Validators.required]);
      this.leadGenerationForm.controls.hadClinicInPast.addValidators([Validators.required]);
      this.leadGenerationForm.updateValueAndValidity();
    }
  }

  displayVaccineClass(vaccineName, index) {
    if(vaccineName == 'Other' && (index%2) > 0) {
      return "col-md-2 chkBox off offset-sm-1"
    } else if ((index%2) > 0) {
      return "col-md-4 chkBox off offset-sm-1"
    } else {
      return "col-md-4 chkBox"
    }
  }

  canDeleteTheClinic(clinicIndex: number) {
    if (this.leadGenerationForm.controls.locations.value.length > 1 && clinicIndex != 0) {
        return true;
    }
  }

  removeLocation(i: number) {
    let control = <FormArray>this.leadGenerationForm.controls['locations'];
    control.removeAt(i);
}

  addLocation(location: ContactUs) {
    if(location) {
      const control = <FormArray>this.leadGenerationForm.controls['locations'];
      let addLocationCtrl = this.initLocation(location);
      control.push(addLocationCtrl);
    } else {
      const control = <FormArray>this.leadGenerationForm.controls['locations'];
      let addLocationCtrl = this.initLocation(undefined);
      control.push(addLocationCtrl);
    }
  }

  initLocation(location: ContactUs): FormGroup {
    if(location)
      return this.formBuilder.group({
          firstName: new FormControl(location.firstName, [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]),
          mainContactEmail: new FormControl(location.mainContactEmail, [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/)]),
          lastName: new FormControl(location.lastName, [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]), 
          mainContactJobTitle: new FormControl(location.mainContactJobTitle),
          address1: new FormControl(location.address1, [Validators.required, Validators.maxLength(40), Validators.pattern(/^[ A-Za-z0-9#'.,-/]*$/)]), 
          address2: new FormControl(location.address2, [ Validators.maxLength(60), Validators.pattern(/^[ A-Za-z0-9#'.,-/]*$/)]), 
          city: new FormControl(location.city, [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \-$]*)?$/)]),
          state: new FormControl(location.state, Validators.required), 
          zipCode: new FormControl(location.zipCode, Validators.required), 
          phoneNumber: new FormControl(location.phoneNumber, Validators.required), 
          extension: new FormControl(location.extension), 
      });
    else 
      return this.formBuilder.group({
        firstName: new FormControl('', [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]),
        mainContactEmail: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/)]),
        lastName: new FormControl('', [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]), 
        mainContactJobTitle: new FormControl(''),
        address1: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.pattern(/^[ A-Za-z0-9#'.,-/]*$/)]), 
        address2: new FormControl('', [ Validators.maxLength(60), Validators.pattern(/^[ A-Za-z0-9#'.,-/]*$/)]), 
        city: new FormControl('', [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \-$]*)?$/)]),
        state: new FormControl('', Validators.required), 
        zipCode: new FormControl('', Validators.required), 
        phoneNumber: new FormControl('', Validators.required), 
        extension: new FormControl(''), 
    });
  }

}
