// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#form {
  position: relative; /* labels need this on the their parent element */
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  padding-top: 30px; /* leave a space to position for the labels */
}

fieldset {display: inline-block; vertical-align: middle;}`, "",{"version":3,"sources":["webpack://./src/app/modules/contractaggreement/components/contract-pharmacy-info/witness.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAE,iDAAiD;AACvE;;AAEA;EACE,SAAS;EACT,UAAU;EACV,SAAS;EACT,iBAAiB,EAAE,6CAA6C;AAClE;;AAEA,UAAU,qBAAqB,EAAE,sBAAsB,CAAC","sourcesContent":["#form {\r\n  position: relative; /* labels need this on the their parent element */\r\n}\r\n\r\nfieldset {\r\n  margin: 0;\r\n  padding: 0;\r\n  border: 0;\r\n  padding-top: 30px; /* leave a space to position for the labels */\r\n}\r\n\r\nfieldset {display: inline-block; vertical-align: middle;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
