import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import * as _ from "lodash";
import { Util } from '../../../../utility/util';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { LeadOpportunitiesService } from '../../../contractaggreement/services/lead-opportunities.service';
import { NgxSpinnerService } from "ngx-spinner";
import { recaptchaRequest } from '../../../../models/localLeadsSave';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { stateCodenName } from '../../../contractaggreement/components/contact-us/stateCodenName';

@Component({
  selector: 'app-covid-contact-us',
  templateUrl: './covid-contact-us.component.html',
  styleUrls: ['./covid-contact-us.component.css']
})

export class CovidContactUsComponent implements OnInit {
  routerSubscription: Subscription;
  leadGenerationForm: FormGroup;
  statecodenname: any;
  dialogMsg: string = '';
  dialogSummary: string = '';
  locListModalDlg: boolean = false;
  successDlg: boolean = false;
  vacn: any = [];
  paymentType: any = [];

  selectedvcInfo: [string];

  // vaccinationsCheckBoxes: any[] = [
  //   { "vaccineVal": 1, "vaccineName": "Flu Quadrivalent" },
  //   { "vaccineVal": 2, "vaccineName": "Flu HD (65+)" },
  //   { "vaccineVal": 3, "vaccineName": "Pneumonia" },
  //   { "vaccineVal": 4, "vaccineName": "Tdap (Whooping Cough)" },
  //   { "vaccineVal": 5, "vaccineName": "Shingles" }
  //   { "vaccineVal": 6, "vaccineName": "Other" },
  // ]

  paymentTypeCheckBoxes: any[] = [
    { "paymentTypeValue": 2, "paymentTypeName": "Insurance", "paymentMessage": "Walgreens can bill patient’s insurance directly." },
    // { "paymentTypeValue": 1, "paymentTypeName": "Cash", "paymentMessage": "Payment for the vaccine can be collected from the patient receiving the immunization at the time of vaccination by cash or via check from the employer/location." },
    { "paymentTypeValue": 3, "paymentTypeName": "Direct Bill", "paymentMessage": "Employer/Location will pay for employee immunizations. They will receive an invoice directly from Walgreens for the number of immunizations provided. No payment will be collected at the time of the immunization." },
  ]
  schoolPaymentTypeCheckBoxes: any[] = [
    { "paymentTypeValue": 2, "paymentTypeName": "Insurance", "paymentMessage": "Walgreens can bill patient’s insurance directly." },
    // { "paymentTypeValue": 1, "paymentTypeName": "Cash", "paymentMessage": "Payment for the vaccine can be collected from the patient receiving the immunization at the time of vaccination by cash or via check from the employer/location." },
    { "paymentTypeValue": 3, "paymentTypeName": "Direct Bill", "paymentMessage": "School will pay for immunizations. They will receive an invoice directly from Walgreens for the number of immunizations provided. No payment will be collected at the time of the immunization." },
  ]
  redirect: boolean = false;
  minDateValue: Date;
  fNlNameRegEx: RegExp = /^(?!\d+$)(?:[a-zA-Z \.\,\'\-]*)?$/;
  cityRegEx: RegExp = /^(?!\d+$)(?:[a-zA-Z \-]*)?$/;
  address1N2: RegExp = /^[ A-Za-z0-9#'.,-/]*$/
  urlPath='COVID-Contact-Us';
  maxDateValue: Date;
  isRecaptchaVerified: boolean = false;
  schoolContactusForm:boolean = false;
  businessNameTitle:string = "Company";
  headerTitle = "Payers & Health Systems";
  constructor(private formBuilder: FormBuilder, private utility: Util, private leadService: LeadOpportunitiesService, private spinner: NgxSpinnerService, public router: Router) {
  }

  ngOnInit() {
    if(this.router.url.search("Schools-Contact-Us")!==-1){
      this.schoolContactusForm = true;
      this.businessNameTitle = "School Name";
      this.headerTitle = "Healthcare Solutions";
    }
    else {
      this.schoolContactusForm = false;
      this.businessNameTitle = "Company";
      this.headerTitle = "Payers & Health Systems";
    }
    
    this.leadGenerationForm = this.formBuilder.group({
      lgCntrlemaNf255: ['', [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]],
      lgCntrlemaNl400: ['', [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \.\,\'\-$]*)?$/)]],
      lgCntrlynapmoc784: ['', Validators.required],
      lgCntrlliame450: ['', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/)]],
      lgCntrl1sserddA594: ['', [Validators.required, Validators.maxLength(35), Validators.pattern(/^[ A-Za-z0-9#'.,-/]*$/)]],
      lgCntrl2sserddA029: ['', [ Validators.maxLength(20), Validators.pattern(/^[ A-Za-z0-9#'.,-/]*$/)]],
      lgCntrlytic105: ['', [Validators.required, Validators.pattern(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z \-$]*)?$/)]],
      lgCntrletats936: ['', Validators.required],
      lgCntrlpiz466: ['', Validators.required],
      lgCntrlrebmuNhp802: ['', Validators.required],
      lgCntrlnoisnetxe491: [''],
      lgCntrlspmEfOoNlatot935: [0, [Validators.required, Validators.maxLength(5)]],
      // lgCntrlofnIsnoitaniccav193: this.createvaccinationsInfo(this.vaccinationsCheckBoxes),
      lgCntrletaDcinilc137: [''],
      lgCntrlstnapicitraPfOrebmuNdetamitse432: ['', [Validators.required, Validators.maxLength(5)]],
      lgCntrlsetatSluMcoLpme035: ['', this.schoolContactusForm?null:Validators.required],
      lgCntrlepyTtnemyap294: this.schoolContactusForm?[null]: this.createPaymentType(this.paymentTypeCheckBoxes),
      lgCntrlmgrPhcruoVsi530: ['', Validators.required],
      recaptchaReactive: ['default', Validators.required],
      vaccinationArray: [null],
      paymentTypeArray: [null],
      lgCntrlsetatSluMcoFluInt186: ['', this.schoolContactusForm?Validators.required:null],
      isCovid: [true],
      covidOpportunityType:['0'],
      fromSchoolsContactUsPage:[this.schoolContactusForm?true:false]
    });

    setTimeout(() => {
      this.resetONBackBtn();
    }, 100);




    this.statecodenname = stateCodenName;
    {
      this.minDateValue = new Date();
      this.maxDateValue = new Date(this.minDateValue.getFullYear() + 1, this.minDateValue.getMonth(), this.minDateValue.getDate() - 1);
    }
  }

  createvaccinationsInfo(vaccinationsInfoInputs) {
    const arr = vaccinationsInfoInputs.map(vcVals => {
      return new FormControl(null);
    });
    return new FormArray(arr);
  }

  createPaymentType(paymentInp) {
    const arr = paymentInp.map(vcVals => {
      return new FormControl(null);
    });
    return new FormArray(arr);
  }

  getSelectedPaymentTypeInfo(ev, val) {
    this.paymentField('');
    if (ev.target.checked) {
      this.paymentType.push(val);
    } else {
      let i = this.vacn.indexOf(val);
      this.paymentType.splice(i, 1);
    }
  }

  getSelectedvaccinationsInfo(ev, val) {
    this.seperateFiedls('');
    if (ev.target.checked) {
      this.vacn.push(val);
    } else {
      let i = this.vacn.indexOf(val);
      this.vacn.splice(i, 1);
    }
  }

  getSelectedvaccinationsInfoName() {
    this.selectedvcInfo = _.filter(
      this.selectedvcInfo,
      function (vcVals) {
        if (vcVals !== false) {
          return vcVals;
        }
      }
    );
  }

  resolved(captchaResponse: string) {
   this.isRecaptchaVerified = false;
    if (captchaResponse) {
      let verify = new recaptchaRequest();
      verify.response = captchaResponse;
      this.leadService.siteVerify(verify).subscribe((data:any) => {
        let rsp = data;
        if (rsp.success == true) {
          this.isRecaptchaVerified = true;
          this.leadGenerationForm.controls.recaptchaReactive.setValue(rsp.success);
          this.leadGenerationForm.controls.recaptchaReactive.updateValueAndValidity();
        }
        else {
          this.showDialogV3("Error", "Recaptcha Verification Failed. Please retry.");
        }
      },
        err => {
          this.showDialogV3("Error", "Expectaion Failed");
        })
    }
  }

  onSubmit(formValues) {
    this.trimFormValues(formValues);
    if (this.leadGenerationForm.valid) {
      this.leadGenerationForm.controls['vaccinationArray'].patchValue(this.vacn);
      this.leadGenerationForm.controls['paymentTypeArray'].patchValue(this.paymentType);
      this.redirect = false;
      if(this.leadGenerationForm.controls['recaptchaReactive'].value=='' || !this.isRecaptchaVerified || this.leadGenerationForm.controls['recaptchaReactive'].value==null){
        this.showDialogV3("Error", "Please verify that you are not a robot.");
        return false;
      }
      if(this.leadGenerationForm.controls['lgCntrlstnapicitraPfOrebmuNdetamitse432'].value<25 ){
        this.showDialogV3("Minimum Requirement", 'There is a minimum of 25 participates required to use this form. Please <a class="blueAnchor1" href="https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.walgreens.com%2Fstorelocator%2Ffind.jsp%3Ftab%3Dstore%2Blocator%26requestType%3Dlocator&data=02%7C01%7Ccsmith%40conversionalliance.com%7C0d84887a116b49829f7b08d84aa0ff14%7Cf7da0482aed242fa80233b240fb6598d%7C0%7C0%7C637341402632750223&sdata=STcZEKlnk7OfRG1mL7EN2PaexM1KlxsHumvIL%2Fow8Ag%3D&reserved=0" target="_blank">contact</a> your local Walgreens for alternative options.');
        window.scroll(40,40);
        return false;
      }
      this.spinner.show();
      this.leadService.saveLeadOpportunitesEnquiries(this.leadGenerationForm.value).subscribe((data:any) => {
        if (data == 1) {
          this.redirect = true;
          this.spinner.hide();
          this.showDialogV3("Thank you!", "Your contact information has been submitted and a local representative will be in touch shortly.");
        }
        else if (data == -1) {
          this.spinner.hide();
          this.showDialogV3("Error", "Bad Geocode -- Invalid Request.");
        }
        else if (data == -2) {
          this.spinner.hide();
          this.showDialogV3("Error", "Bad Geocode -- Over Query Limit.");
        }
        else if (data == -3) {
          this.spinner.hide();
          this.showDialogV3("Error", "Bad Geocode -- Request Denied.");
        }
        else if (data == -4) {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages['geo_zero_results']);
        }
        else if (data == -5) {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages['geo_unknown_error']);
        }
        else if (data == -6) {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages['geo_timed_out']);
        }
        else {
          this.spinner.hide();
          this.showDialogV3("Error", ErrorMessages["error"]);
        }
      }, err => {
        this.spinner.hide();
        this.showDialogV3("Error", "Expectation failed.");
      });
    }
    else {
      this.spinner.hide();
      this.validateFormNSendAlert();
      this.validateNResetCaptcha();
    }
  }

  isFieldValid(field: string) {
    return !this.leadGenerationForm.get(field).valid && this.leadGenerationForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  seperateFiedls(e) {
    if (e == '') {
      if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193) {
        if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193.includes(true)) {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-feedback');
        }
      }
    } else {
      if (e.toElement.checked && e.toElement.checked == true && this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193) {
        if (this.leadGenerationForm.value.lgCntrlofnIsnoitaniccav193.includes(true) || e.toElement.checked == true) {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-error');
          document.getElementById('lgCntrlofnIsnoitaniccav193').classList.add('has-feedback');
        }
      }
    }
  }

  paymentField(e) {
    if (e == '') {
      if (this.leadGenerationForm.value.lgCntrlepyTtnemyap294) {
        if (this.leadGenerationForm.value.lgCntrlepyTtnemyap294.includes(true)) {
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-feedback');
        }
      }
    } else {
      if (e.toElement.checked && e.toElement.checked == true && this.leadGenerationForm.value.lgCntrlepyTtnemyap294) {
        if (this.leadGenerationForm.value.lgCntrlepyTtnemyap294.includes(true) || e.toElement.checked == true) {
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.remove('has-feedback');
        }
        else {
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-error');
          document.getElementById('lgCntrlepyTtnemyap294').classList.add('has-feedback');
        }
      }
    }
  }

  showDialogV2(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.locListModalDlg = true;
  }
  showDialogV3(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.successDlg = true;
  }
  okClickedV2() {
    this.locListModalDlg = false;
  }
  goToWagsOnClose() {
    this.successDlg = false;
    if (this.redirect) {
      window.location.href = 'http://walgreens.com';
    }
  }
  public trimFormValues(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      if (key != "lgCntrlofnIsnoitaniccav193" && key != "lgCntrlepyTtnemyap294" 
      && key != "lgCntrletaDcinilc137" && key != "recaptchaReactive" 
      && key != "vaccinationArray" && key != "paymentTypeArray"
      && key != "lgCntrlspmEfOoNlatot935" && key != "isCovid" &&   key != "fromSchoolsContactUsPage") {
        formGroup.get(key).setValue(formGroup.get(key).value.trim())
      }
    });
  }
  validateFormNSendAlert() {
    this.utility.validateAllFormFields(this.leadGenerationForm);
    this.seperateFiedls('');
    this.paymentField('');
    this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['requiredFields']);
    return false;
  }
  onlyAlphabets(e, ctrl) {
    if (ctrl == "fNlName") {
      return this.fNlNameRegEx.test(e.key);
    } else if (ctrl == "city") {
      return this.cityRegEx.test(e.key);
    }
    else if (ctrl == "add1N2") {
      return this.address1N2.test(e.key);
    }
  }
  validateNResetCaptcha() {
    this.leadGenerationForm.controls.recaptchaReactive.setValue('default');
    this.leadGenerationForm.controls.recaptchaReactive.updateValueAndValidity();
  }
  resetONBackBtn() {
    this.leadGenerationForm.controls.lgCntrlrebmuNhp802.setValue('');
    this.leadGenerationForm.controls.lgCntrlrebmuNhp802.updateValueAndValidity();
    this.leadGenerationForm.controls.lgCntrletats936.setValue('');
    this.leadGenerationForm.controls.lgCntrletats936.updateValueAndValidity();
  }
}
