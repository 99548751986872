import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, } from '@angular/forms';
import { OutreachProgramService } from '../../services/outreach-program.service';
import { LocationNumberPipe } from '../../pipes/location-number.pipe';
import { Clinic, Immunization } from '../../../../models/contract';
import { TimeToDatePipe } from '../../pipes/time-to-date.pipe';
import { DatePipe } from '@angular/common';
import {Message} from 'primeng//api';
import { ConfirmationService} from 'primeng/api';
import { SessionDetails } from '../../../../utility/session';
import { Router } from '@angular/router';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { Util } from '../../../../utility/util';
import { CharityProgram, ClinicList, ClinicImzQtyList, LogOutreachStatus, ClinicImmunizationList } from '../../../../models/charityProgram';
import { String } from 'typescript-string-operations';
import { environment } from '../../../../../environments/environment';
@Component({
    selector: 'app-charity-locations-list',
    templateUrl: './charity-locations-list.component.html',
    styleUrls: ['./charity-locations-list.component.css'],
    providers: [LocationNumberPipe, TimeToDatePipe, DatePipe]
})

export class CharitylocationsListComponent implements OnInit {
    showHints: boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : true;
    pageName: string = "charityprogram";

    public contractForm: FormGroup;
    public addedImmunizations: Immunization[];
    location_number: any = 0;
    //contractData: ContractData = new ContractData();
    clinicData: Clinic = new Clinic();
    contactLogPk: Number;
    dialogSummary: string;
    dialogMsg: string;
    display: boolean = false;
    errordisplay: boolean = false;
    msgs: Message[] = [];
    defaultDate: Date = new Date();
    maxDateValue: Date = new Date();
    minDateValue: Date = new Date();
    cancel_save: string;
    event_details: any;
    savePopUp: boolean = false;
    immunization_list: any[];
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: number;
    userApprovedGt250: boolean = false;
    locConfirm25ModalDlg: boolean = false;
    coLocConfirm250ModalDlg: boolean = false;
    locConfirm250ModalDlg: boolean = false;
    userApprovedLt25: boolean = false;
    locListModalDlg: boolean = false;
    user_14days_check: boolean = false;
    user_250_check: boolean = false;
    previous_location_data: any[];
    previous_locations: any[];
    is_previous_locations: boolean = false;
    display_message: boolean = false;
    clinic_immunization_list: any;
    disable_button: boolean = false;
    overrideTimeSlotPopup: boolean = false;
    allowTimeOverRide: boolean = false;
    immunizerWarningAlert:boolean = false;
    immunizerWarningOkClicked:boolean = false;  
    showCancelBtn:boolean = true;
    OverrideOrOkTitle:string = "Override";
    constructor(private _fb: FormBuilder,
        private _charityProgramService: OutreachProgramService,
        private _localContractService: OutreachProgramService,
        private _locationNumberPipe: LocationNumberPipe,
        private _timeToDatePipe: TimeToDatePipe,
        private _datePipe: DatePipe, private utility: Util,
        private confirmationService: ConfirmationService, private router: Router) {
    }

    ngOnInit() {
        this.immunization_list = [];
        this.allowTimeOverRide = false;
        this.contractForm = this._fb.group({
            clinicList: this._fb.array([])
        });
        let oppurtunitiesData: any = SessionDetails.GetopportunitiesData();
        this.event_details = SessionDetails.GetEventDetails();
        if (this.isStoreIsfromRestrictedState()) {            
            this._localContractService.getpreviousseasoncliniclocations(0, oppurtunitiesData.businessPk).subscribe((res:any) => {
                this.previous_location_data = res.previousLocationsList;
                if (this.previous_location_data.length > 1) {
                    this.is_previous_locations = true;
                    this.display_message = true;
                }
                else {
                    this.display_message = false;
                }
            });
        }
        if (this.isStoreIsfromRestrictedState()) {
            let today = new Date();
            this.defaultDate = new Date(today.getFullYear(), 8, 1, 8, 55, 55);
            this.minDateValue = new Date(today.getFullYear(), 8, 1, 8, 55, 55);
            this.maxDateValue = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate(), 8, 55, 55);
        }
        else {
            let today = new Date();
            this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 55, 55);
            this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 55, 55);
            this.maxDateValue = new Date(today.getFullYear() + 5, today.getMonth(), today.getDate(), 8, 55, 55);
        }
        let loc = new ClinicList();
        if (oppurtunitiesData.businessPk !== undefined && oppurtunitiesData.businessPk !== null && oppurtunitiesData.businessPk !== "") {
            this._localContractService.getStoreBusinessDetails(oppurtunitiesData.businessPk).subscribe((res:any) => {
                loc.address1 = this.address1 = res.address1;
                loc.address2 = this.address2 = res.address2;
                loc.city = this.city = res.city;
                loc.state = this.state = res.state;
                loc.zipCode = this.zipCode = res.zipCode;
                let imz: any = {};
                let clinic_immunizations: any[];
                clinic_immunizations = [];
                loc.clinicImzQtyList = new Array<ClinicImzQtyList>();
                this._localContractService.getImmunizationsList(-1, 2).subscribe((rec:any) => {
                    this.clinic_immunization_list = rec.immunizationList;
                    imz.immunizationPk = rec.immunizationList[0].immunizationId;
                    imz.immunizationName = rec.immunizationList[0].immunizationName;
                    imz.paymentTypeName = rec.immunizationList[0].paymentTypeName;
                    imz.paymentTypeId = rec.immunizationList[0].paymentTypeId;
                    imz.estimatedQuantity = "";
                    imz.vouchersDistributed = "";
                    //imz.estimatedQuantity = loc.cli nicImzQtyList[0].estimatedQuantity;
                    //imz.vouchersDistributed = loc.clinicImzQtyList[0].vouchersDistributed;
                    clinic_immunizations.push(imz);

                    loc.clinicImzQtyList = clinic_immunizations;
                    this.immunization_list = loc.clinicImzQtyList;
                    loc.location = "CLINIC " + this._locationNumberPipe.transform(this.location_number + 1);
                    this.addLocation(loc);
                    // /this.location_number = this.location_number + 1;
                });
            });
        } else {
            this.showDialog(ErrorMessages['serverError'], "An error has occurred. Pleaase contact administrartor.");
            return false;
        }
    }
    PreviousLocationChange(event) {
        if (event != "") {
            this.display_message = false;
            this.previous_locations = this.previous_location_data.filter(item => item.rowId == event);
        }
        else {
            this.display_message = true;
        }
    }
    initLocation(location: ClinicList) {
        this.location_number = this.location_number + 1;
        let previous_display: boolean = true;
        if (this.previous_locations != undefined) {
            if (this.previous_locations.length > 0) {
                this.address1 = this.previous_locations[0].address;
                this.address2 = this.previous_locations[0].address2;
                this.city = this.previous_locations[0].city;
                this.state = this.previous_locations[0].state;
                this.zipCode = this.previous_locations[0].zip;
                previous_display = false;
            }
        }
        if (location === undefined) {

            location = new ClinicList();

            location.location = "CLINIC " + this._locationNumberPipe.transform(this.location_number);
            return this._fb.group({
                clinicImzQtyList: this.initImmunizations(this.immunization_list),
                location: this._fb.control(location.location),
                contactFirstName: this._fb.control(location.contactFirstName, Validators.required),
                contactLastName: this._fb.control(location.contactLastName, Validators.required),
                contactEmail: this._fb.control(location.contactEmail, Validators.required),
                contactPhone: this._fb.control(location.contactPhone, Validators.required),
                clinicDate: this._fb.control(location.clinicDate, Validators.required),
                startTime: this._fb.control(location.startTime, Validators.required),
                endTime: this._fb.control(location.endTime, Validators.required),
                latitude: this._fb.control(location.latitude),
                longitude: this._fb.control(location.longitude),
                //  disabling fields if the store state belongs to restricted state
                address1: this._fb.control(this.isStoreIsfromRestrictedState() ? this.address1 : location.address1, Validators.required),
                address2: this._fb.control(this.isStoreIsfromRestrictedState() ? this.address2 : location.address2),
                city: this._fb.control(this.isStoreIsfromRestrictedState() ? this.city : location.city, Validators.required),
                state: this._fb.control(this.isStoreIsfromRestrictedState() ? this.state : location.state, Validators.required),
                zipCode: this._fb.control(this.isStoreIsfromRestrictedState() ? this.zipCode : location.zipCode, Validators.required),
                isNoClinic: this._fb.control(+location.isNoClinic, null),
                isCurrent: this._fb.control(1),
                previousContact: this._fb.control(+location.previousContact, null),
                previousLocation: this._fb.control(+location.previousLocation, null),

                localEstmdShots: this._fb.control(this.immunization_list[0].estimatedQuantity, Validators.required),
                localvouchersDist: this._fb.control(this.immunization_list[0].vouchersDistributed, Validators.required)
            });

        }
        else {

            return this._fb.group({
                clinicImzQtyList: this.initImmunizations(location.clinicImzQtyList),
                location: this._fb.control(location.location),
                contactFirstName: this._fb.control(location.contactFirstName, Validators.required),
                contactLastName: this._fb.control(location.contactLastName, Validators.required),
                contactEmail: this._fb.control(location.contactEmail, Validators.required),
                contactPhone: this._fb.control(location.contactPhone, Validators.required),
                clinicDate: this._fb.control(location.clinicDate, Validators.required),
                startTime: this._fb.control(location.startTime, Validators.required),
                endTime: this._fb.control(location.endTime, Validators.required),
                latitude: this._fb.control(location.latitude),
                longitude: this._fb.control(location.longitude),
                //  disabling fields if the store state belongs to restricted state
                address1: this._fb.control(this.isStoreIsfromRestrictedState() ? this.address1 : location.address1, Validators.required),
                address2: this._fb.control(this.isStoreIsfromRestrictedState() ? this.address2 : location.address2),
                city: this._fb.control(this.isStoreIsfromRestrictedState() ? this.city : location.city, Validators.required),
                state: this._fb.control(this.isStoreIsfromRestrictedState() ? this.state : location.state, Validators.required),
                zipCode: this._fb.control(this.isStoreIsfromRestrictedState() ? this.zipCode : location.zipCode, Validators.required),

                isNoClinic: this._fb.control(+location.isNoClinic, null),
                isCurrent: this._fb.control(1),
                previousContact: this._fb.control(+location.previousContact, null),
                previousLocation: this._fb.control(+location.previousLocation, null),
                localEstmdShots: this._fb.control(location.clinicImzQtyList[0].estimatedQuantity, Validators.required),
                localvouchersDist: this._fb.control(location.clinicImzQtyList[0].estimatedQuantity, Validators.required)
            });
        }

    }
    initImmunizations(clinic_immunizations) {
        let imz_array: any[] = [];
        clinic_immunizations.forEach(imz => {
            imz.immunizationName = imz.immunizationName;
            imz.paymentTypeName = imz.paymentTypeName;
            imz_array.push(this._fb.group({
                clinicPk: this._fb.control(imz.clinicPk),
                immunizationPk: this._fb.control(imz.immunizationPk),
                estimatedQuantity: this._fb.control(imz.estimatedQuantity, null),
                immunizationName: this._fb.control(imz.immunizationName),
                paymentTypeName: this._fb.control(imz.paymentTypeName),
                paymentTypeId: this._fb.control(imz.paymentTypeId)
            }))
        })
        return this._fb.array(imz_array);
    }
    disableButton() {
        if (this.display_message) {
            return 'false';
        }
        else {
            return '';
        }
    }
    isStoreIsfromRestrictedState() {
        var resrict_states = ["MO"]; // "DC" -- Removing DC state as per task# 1363
        var current_date = new Date();
        if (resrict_states.indexOf(SessionDetails.GetState()) > -1) {
            if (current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO)) {
                return true;
            }
            if (current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    addLocation(location) {
        this.user_250_check = false;
        this.user_14days_check = false;
        const control = <FormArray>this.contractForm.controls['clinicList'];
        const addrCtrl = this.initLocation(location);
        control.push(addrCtrl);
    }
    removeLocation(i: number) {
        const control = <FormArray>this.contractForm.controls['clinicList'];
        control.removeAt(i);
        this.location_number--;

        for (let index = 0; index < this.location_number; index++) {
            let fGrp: FormGroup = control.controls[index] as FormGroup;
            fGrp.controls['location'].setValue("CLINIC " + this._locationNumberPipe.transform(index + 1));
        }
    }
    coShowConfirm250ImmDialog(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.coLocConfirm250ModalDlg = true;
    }
    showConfirm25ImmDialog(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.locConfirm25ModalDlg = true;
    }
    showConfirm250ImmDialog(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.locConfirm250ModalDlg = true;
    }
    coRejectGt250Imm() {
        this.coLocConfirm250ModalDlg = false;
        return;
    }
    acceptGt250Imm() {

        this.userApprovedGt250 = true;

        this.locConfirm250ModalDlg = false;
        if (this.userApprovedGt250) {
            this.user_250_check = true;
            this.save(false);
        }
        return;
    }
    rejectGt250Imm() {
        this.locConfirm250ModalDlg = false;
        return;
    }

    rejectLt25Imm() {
        this.locConfirm25ModalDlg = false;
        return;
    }
    getEstimatedImmunizers(errorList:string[]){
        let shots_per_hour = (60 / 10) * 4;  
        this.contractForm.value.clinicList.forEach(loc => {
          try {
              if (loc.startTime != "")
                  try{
                  loc.startTime = this._datePipe.transform(loc.startTime, 'shortTime');
                  }catch(e){
                      console.log(e);        
                  }
              if (loc.endTime != "") {
                  try{
                  loc.endTime = this._datePipe.transform(loc.endTime, 'shortTime');
              }catch(e){
                  console.log(e);        
              }
              }
          } catch (e) {
              console.log(e); // here the exception is delibarately caught because when you click back, startTime & end
              // Time is not correct timeformat. Hence we are ignoring this by catching here.
          }
      });
      for (let count = 0; count < this.contractForm.value.clinicList.length; count++) {        
        let locClinicDate:Date = new Date(this.contractForm.value.clinicList[count].clinicDate);
                let locStartTime = this.contractForm.value.clinicList[count].startTime;
                let locEndTime = this.contractForm.value.clinicList[count].endTime;
                if (this.contractForm.value.clinicList[count].isNoClinic >= 1 || 
                    this.contractForm.value.clinicList[count].isNoClinic === '1' || 
                    this.contractForm.value.clinicList[count].isReassign >= 1 || 
                    this.contractForm.value.clinicList[count].isReassign === '1') {
                    continue;
                }
                let endDate: Date;
                if (locEndTime.toString().toLowerCase().search('am') !== -1 || locEndTime.toString().toLowerCase().search('pm') !== -1) {
                    endDate = this._timeToDatePipe.transform(locClinicDate, locEndTime);
                }
                else {
                    endDate = locEndTime;
                }
                let startDatetmToDt: Date;
                if (locStartTime.toString().toLowerCase().search('am') !== -1 || locStartTime.toString().toLowerCase().search('pm') !== -1) {
                    startDatetmToDt = this._timeToDatePipe.transform(locClinicDate, locStartTime);
                }
                else {
                    startDatetmToDt = locStartTime;
                }
                let locStart: Date = new Date(startDatetmToDt);
                let locEnd: Date = new Date(endDate);
                let clinicLength = (locEnd.valueOf()-locStart.valueOf())/(3600*1000);
                if(clinicLength> 0 ){   
                 let clinicLoc= this.contractForm.value.clinicList[count];  
                 let totalImmunizations = Number(clinicLoc.localEstmdShots);
                 let estimatedImmunizers = Math.ceil(totalImmunizations/(shots_per_hour*clinicLength))
                 if(estimatedImmunizers>1){
                 errorList.push(String.Format(ErrorMessages['immunizerWarning'],clinicLoc.location,estimatedImmunizers))
                 }
                 totalImmunizations =  0;
                 estimatedImmunizers = 0;
                }
                
      }
      }
    save(is_override?: boolean) {
        var charity_program = new CharityProgram();
        var location = new ClinicList();
        location.clinicImzQtyList = new Array<ClinicImzQtyList>();

        let clinicArray = this.contractForm.controls.clinicList;
        for (let index = 0; index < this.contractForm.value.clinicList.length; index++) {

            let frmGrp: FormGroup = clinicArray.get(index.toString()) as FormGroup;
            if (frmGrp.controls['isNoClinic'].value == true || frmGrp.controls['isNoClinic'].value == 1) {
                let ctrl = frmGrp.get('address1');
                ctrl.setValidators(null);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('address2');
                ctrl.setValidators(null);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('city');
                ctrl.setValidators(null);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('state');
                ctrl.setValidators(null);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('zipCode');
                ctrl.setValidators(null);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('clinicDate');
                ctrl.setValidators(null);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('startTime');
                ctrl.setValidators(null);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('endTime');
                ctrl.setValidators(null);
                ctrl.updateValueAndValidity();
            } else {
                let ctrl = frmGrp.get('address1');
                ctrl.setValidators([Validators.required]);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('city');
                ctrl.setValidators([Validators.required]);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('state');
                ctrl.setValidators([Validators.required]);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('zipCode');
                ctrl.setValidators([Validators.required]);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('clinicDate');
                ctrl.setValidators([Validators.required]);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('startTime');
                ctrl.setValidators([Validators.required]);
                ctrl.updateValueAndValidity();

                ctrl = frmGrp.get('endTime');
                ctrl.setValidators([Validators.required]);
                ctrl.updateValueAndValidity();
            }
        }

        if (this.contractForm.valid && this.contractForm.value.clinicList.length > 0) {
            if(!this.immunizerWarningOkClicked){
                let estimatedImmunizers = 2;
                let errorList:string [] = [];
                
                this.getEstimatedImmunizers(errorList);
                if(errorList.length>0){
                this.showdImmunizerWarningDialog("Warning", ErrorMessages['immunizerWarningMainPara']+errorList.join().replace(",",""));
                return false;
                }
              }
            for (let i = 0; i < this.contractForm.value.clinicList.length; i++) {

                if (this.contractForm.value.clinicList[0].contactPhone.indexOf("X") > -1) {
                    let xcontrol = <FormArray>this.contractForm.controls['clinicList'];
                    xcontrol.controls[i].get('contactPhone').setValue(null);
                    xcontrol.controls[i].get('contactPhone').setValidators([Validators.required]);
                    xcontrol.controls[i].get('contactPhone').updateValueAndValidity();
                    this.utility.validateAllFormFields(<FormGroup>xcontrol.controls[i]);
                    this.showDialogV2(ErrorMessages['serverError'], ErrorMessages['MandatoryFields']);
                    return false;
                }

                if (!this.contractForm.value.clinicList[i].localvouchersDist || this.contractForm.value.clinicList[i].localvouchersDist == 0) {
                    this.showErrorDialog(ErrorMessages['clientError'], ErrorMessages['VouchersDistributedMsg']);
                    return false;
                }
            }
            let locString: String[] = [];
            this.contractForm.value.clinicList.forEach(loc => {
                loc.isNoClinic = loc.isNoClinic ? 1 : 0;
                for (let i = 0; i < this.contractForm.value.clinicList.length; i++) {

                    for (let j = 0; j < this.contractForm.value.clinicList[i].clinicImzQtyList.length; j++) {
                        this.contractForm.value.clinicList[i].clinicImzQtyList[j].clinicPk = this.contractForm.value.clinicList[i].clinicImzQtyList[j].clinicPk;
                        this.contractForm.value.clinicList[i].clinicImzQtyList[j].immunizationName = this.contractForm.value.clinicList[i].clinicImzQtyList[j].immunizationName;
                        this.contractForm.value.clinicList[i].clinicImzQtyList[j].paymentTypeName = this.contractForm.value.clinicList[i].clinicImzQtyList[j].paymentTypeName;
                        this.contractForm.value.clinicList[i].clinicImzQtyList[j].paymentTypeId = this.contractForm.value.clinicList[i].clinicImzQtyList[j].paymentTypeId;
                        this.contractForm.value.clinicList[i].clinicImzQtyList[j].estimatedQuantity = Number(this.contractForm.value.clinicList[i].localEstmdShots);
                        this.contractForm.value.clinicList[i].clinicImzQtyList[j].vouchersDistributed = Number(this.contractForm.value.clinicList[i].localvouchersDist);
                    }
                }
                loc.pk = 0;
                try {
                if (loc.startTime != "")
                    loc.startTime = this._datePipe.transform(loc.startTime, 'shortTime');
                if (loc.endTime != "")
                    loc.endTime = this._datePipe.transform(loc.endTime, 'shortTime');
                }catch(e){
                 // deliberately kept to avoid crash as it is expected for crash.   
                }

                let today = new Date();
                let diffDays: number;
                if (loc.isNoClinic == true || loc.isNoClinic == 1) {
                    diffDays = 15;
                }
                if (loc.isNoClinic == false || loc.isNoClinic == 0) {
                    var loc_date = (typeof loc.clinicDate === 'string') ? new Date(loc.clinicDate) : loc.clinicDate;
                    //diffDays = loc.clinicDate.getMonth() == today.getMonth() ? Math.round(loc.clinicDate.getDate() - today.getDate()) :
                    //    this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0), new Date(loc_date.getFullYear(), loc_date.getMonth(), loc_date.getDate(), 0, 0, 0));
                    diffDays = this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0), new Date(loc_date.getFullYear(), loc_date.getMonth(), loc_date.getDate(), 0, 0, 0));
                }
                if (!this.user_14days_check && (loc.isNoClinic == false || loc.isNoClinic == 0)) {

                    if (loc.isNoClinic != null && loc.isNoClinic == false && diffDays < 14) {
                        var userProfile = SessionDetails.GetUserProfile();
                        var lowerCaseName = userProfile.userRole.toLowerCase();
                        locString.push(loc.location_number)
                        // if (lowerCaseName == "director" ||
                        //     lowerCaseName == "supervisor" || lowerCaseName == "regionalvp" ||
                        //     lowerCaseName == "regionaldirector" || lowerCaseName == "admin" ||
                        //     lowerCaseName == "regional vice president" ||
                        //     lowerCaseName == "regional healthcare director" ||
                        //     lowerCaseName == "healthcare supervisor"
                        //     || lowerCaseName == "director - rx and retail ops" || lowerCaseName == "district manager") {
                            this.showCancelBtn = false;
                            this.OverrideOrOkTitle = "OK";
                            this.confirm(ErrorMessages['impRmndrFor2Wks'], ErrorMessages['new_clinicDateReminderBefore2WeeksEN']);
                            throw true;
              //          }
                        // else {
                        //     this.showErrorDialog(ErrorMessages['impRmndrFor2Wks'], ErrorMessages['clinicDateReminderBefore2WeeksEN']);
                        //     throw false;
                        // }
                    }
                }
            });
            if (this.dateTimeConflict()) {
                if (this.minimumShotValidations()) {
                    charity_program.logOutreachStatus = new LogOutreachStatus();
                    charity_program.clinicImmunizationList = new ClinicImmunizationList();
                    //  charity_program.logOutreachStatus = this.event_details;
                    charity_program.logOutreachStatus.businessPk = this.event_details.logOutreachStatus.businessPk;;
                    charity_program.logOutreachStatus.firstName = this.event_details.logOutreachStatus.firstName;
                    charity_program.logOutreachStatus.lastName = this.event_details.logOutreachStatus.lastName;
                    charity_program.logOutreachStatus.jobTitle = this.event_details.logOutreachStatus.jobTitle;
                    charity_program.logOutreachStatus.outreachProgram = this.event_details.logOutreachStatus.outreachProgram;
                    charity_program.logOutreachStatus.outreachBusinessPk = this.event_details.logOutreachStatus.outreachBusinessPk;
                    charity_program.logOutreachStatus.contactDate = this.event_details.logOutreachStatus.contactDate;

                    // charity_program.logOutreachStatus.contactDate =   new Date(this.event_details.logOutreachStatus.contactDate.getFullYear(),
                    // this.event_details.logOutreachStatus.contactDate.getMonth()
                    // ,this.event_details.logOutreachStatus.contactDate.getDate(),contact_date.getHours(),
                    // contact_date.getMinutes(),contact_date.getSeconds(),
                    // contact_date.getMilliseconds());
                    charity_program.logOutreachStatus.outreachStatusId = this.event_details.logOutreachStatus.outreachStatusId;
                    charity_program.logOutreachStatus.outreachStatusTitle = this.event_details.logOutreachStatus.outreachStatusTitle;
                    charity_program.logOutreachStatus.feedback = this.event_details.logOutreachStatus.feedback;
                    charity_program.logOutreachStatus.createdBy = this.event_details.logOutreachStatus.createdBy;
                    charity_program.logOutreachStatus.clinicAgreementPk = 0;
                    charity_program.logOutreachStatus.contactLogPk = 0;
                    charity_program.clinicImmunizationList.pk = 0;
                    charity_program.clinicImmunizationList.clinicPk = 0;
                    charity_program.clinicImmunizationList.immunizationPk = this.clinic_immunization_list[0].immunizationId;
                    charity_program.clinicImmunizationList.immunizationName = this.clinic_immunization_list[0].immunizationName;
                    charity_program.clinicImmunizationList.price = this.clinic_immunization_list[0].price;
                    charity_program.clinicImmunizationList.paymentTypeId = this.clinic_immunization_list[0].paymentTypeId;
                    charity_program.clinicImmunizationList.paymentTypeName = this.clinic_immunization_list[0].paymentTypeName;
                    charity_program.clinicImmunizationList.sendInvoiceTo = this.clinic_immunization_list[0].sendInvoiceTo;
                    charity_program.clinicImmunizationList.name = null; //this.clinic_immunization_list[0].name;
                    charity_program.clinicImmunizationList.address1 = null;
                    charity_program.clinicImmunizationList.address2 = null;
                    charity_program.clinicImmunizationList.city = null;
                    charity_program.clinicImmunizationList.state = null;
                    charity_program.clinicImmunizationList.zip = null;
                    charity_program.clinicImmunizationList.phone = null;
                    charity_program.clinicImmunizationList.email = null;
                    charity_program.clinicImmunizationList.isTaxExempt = null;
                    charity_program.clinicImmunizationList.isCopay = false;
                    charity_program.clinicImmunizationList.copayValue = null;
                    charity_program.clinicImmunizationList.isVoucherNeeded = null;
                    charity_program.clinicImmunizationList.voucherExpirationDate = null;


                    charity_program.charityVoucherProgram = this.contractForm.value;
                    charity_program.charityVoucherProgram.clinicAgreementPk = 0;
                    charity_program.charityVoucherProgram.contactLogPk = 0;
                    charity_program.charityVoucherProgram.isApproved = true;
                    charity_program.charityVoucherProgram.approvedOrRejectedBy = "";
                    charity_program.charityVoucherProgram.isEmailSent = true;
                    charity_program.charityVoucherProgram.dateCreated = new Date();
                    charity_program.charityVoucherProgram.lastUpdatedDate = new Date();
                    charity_program.charityVoucherProgram.contactWagUser = "";
                    charity_program.charityVoucherProgram.clinicList.forEach(cl => {
                        try {
                            cl.contactPhone = cl.contactPhone;
                            if (cl.clinicDate === null || cl.clinicDate === undefined || cl.clinicDate === '') {
                                cl.clinicDate = null;
                            } else {
                                cl.clinicDate = this._datePipe.transform(cl.clinicDate, "MM/dd/yyyy");
                            }
                        } catch (e) {
                            console.log('error in charity save');
                        }
                    });
                    // if (this._charityProgramService.saveClinicLocations(charity_program)) {
                    this.disable_button = true;
                    this._charityProgramService.insertCharityProgram(SessionDetails.GetStoreId(), 2,
                        charity_program, is_override).subscribe((data: any) => {
                            this.disable_button = false;
                            switch (data.errorS.errorCode) {
                                case 0:
                                case '0':
                                case 200:
                                case "null":
                                    this.showDialog(ErrorMessages['chSummary'], ErrorMessages['chSucccess']);
                                    break;
                                case -4:
                                case '-4':
                                    this.showCancelBtn = true;
                                    this.OverrideOrOkTitle = "Override";
                                    this.confirmOverride(ErrorMessages['eventDateTimeConflict'], String.Format("{0}<br/><br/>{1}", data.errorS.errorMessage, ErrorMessages['eventDateTimeConflictErrText']));
                                    break;
                                    case -9:
                                    case '-9':
                                        this.showDialog(ErrorMessages['chSummary'],  data.errorS.errorMessage);
                                        break;
                                default:
                                    this.showErrorDialog(ErrorMessages['chSummary'], data.errorS.errorMessage);
                                    break;
                            }
                        },
                            err => {
                                this.showErrorDialog(ErrorMessages['unKnownError'], err.json().Message);
                                this.disable_button = false;
                            });
                }
            }
        }
        else {
            this.utility.validateAllFormFields(this.contractForm);
        }
    }
    showdImmunizerWarningDialog(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.immunizerWarningAlert = true;
      }
      showdImmunizerWarningDialogOk(){
        this.immunizerWarningAlert = false;
        this.immunizerWarningOkClicked = true;;
        this.save(false);
      }
      editdImmunizerWarning(){
        this.immunizerWarningAlert = false;
      }
    dateTimeConflict(): boolean {
        if (this.allowTimeOverRide) return true;
        var locConflictList = [];
        var errMsgV1: string[] = [];
        try {
            for (let count = 0; count < this.contractForm.value.clinicList.length; count++) {
                try {
                    let locClinicDate: Date = new Date(this.contractForm.value.clinicList[count].clinicDate);
                    let locStartTime = this.contractForm.value.clinicList[count].startTime;
                    let locEndTime = this.contractForm.value.clinicList[count].endTime;

                    let locationsCpy = JSON.parse(JSON.stringify(this.contractForm.value.clinicList));
                    var index = locationsCpy.findIndex(it => it.location === this.contractForm.value.clinicList[count].location);
                    if (index > -1) {
                        locationsCpy.splice(index, 1);
                    }
                    locationsCpy.forEach(element => {
                        element.clinicDate = new Date(element.clinicDate);
                    });
                    let conflictLocs = locationsCpy.filter(locItem => locClinicDate.getFullYear() === locItem.clinicDate.getFullYear() && locClinicDate.getMonth() === locItem.clinicDate.getMonth() && locClinicDate.getDate() === locItem.clinicDate.getDate());
                    let endDateAdd39Mts: Date;
                    if (locEndTime.toString().toLowerCase().search('am') !== -1 || locEndTime.toString().toLowerCase().search('pm') !== -1) {
                        endDateAdd39Mts = this._timeToDatePipe.transform(locClinicDate, locEndTime);
                    }
                    else {
                        endDateAdd39Mts = locEndTime;
                    }
                    let startDatetmToDt: Date;
                    if (locStartTime.toString().toLowerCase().search('am') !== -1 || locStartTime.toString().toLowerCase().search('pm') !== -1) {
                        startDatetmToDt = this._timeToDatePipe.transform(locClinicDate, locStartTime);
                    }
                    else {
                        startDatetmToDt = locStartTime;
                    }
                    let add30MinTS: Date = new Date(locClinicDate.getFullYear(), locClinicDate.getMonth(), locClinicDate.getDate(), endDateAdd39Mts.getHours(), endDateAdd39Mts.getMinutes() + 30, endDateAdd39Mts.getSeconds());
                    let locStart: Date = new Date(startDatetmToDt);

                    if (conflictLocs.length > 0) {
                        var conflictLocsCpy = JSON.parse(JSON.stringify(conflictLocs));
                        conflictLocsCpy.forEach(element => {
                            element.clinicDate = new Date(element.clinicDate);
                            element.startTime = this._timeToDatePipe.transform(element.clinicDate, element.startTime);
                            element.endTime = this._timeToDatePipe.transform(element.clinicDate, element.endTime);
                        });

                        conflictLocs = conflictLocsCpy.filter(locItem => locClinicDate.getFullYear() === locItem.clinicDate.getFullYear() && locClinicDate.getMonth() === locItem.clinicDate.getMonth() && locClinicDate.getDate() === locItem.clinicDate.getDate() && ((locItem.startTime.valueOf() >= locStart.valueOf() && locItem.startTime.valueOf() < add30MinTS.valueOf()) || (locItem.endTime.valueOf() > locStart.valueOf() && locItem.endTime.valueOf() < add30MinTS.valueOf())));
                        if (conflictLocs.length > 0) {
                            locConflictList.push({
                                srcLocName: this.contractForm.value.clinicList[count],
                                dstLocName: conflictLocs
                            });
                        }
                    }
                }
                catch (e) {
                    console.log(e);
                }
            }
            let conflictClinicList: any[] = [];
            locConflictList.forEach(item => {
                item.dstLocName.forEach(element => {
                    let duplicateEntry = conflictClinicList.filter(l => l.location == element.location);
                    if (duplicateEntry.length <= 0) {
                        conflictClinicList.push(element);
                    }
                });

            });

            if (conflictClinicList.length > 0) {
                conflictClinicList.sort((leftItem, rightItem): number => {
                    if (leftItem.location < rightItem.location) return -1;
                    if (leftItem.location > rightItem.location) return 1;
                    return 0;
                });

                if (conflictClinicList.length <= 2) {
                    if (conflictClinicList.length == 2) {
                        conflictClinicList.forEach(loc => {
                            errMsgV1.push(String.Format(ErrorMessages['clincLocDateTimeMultipleConflict'], loc.location, this._datePipe.transform(loc.clinicDate, "shortDate"), this._datePipe.transform(loc.startTime, 'shortTime'), this._datePipe.transform(loc.endTime, 'shortTime'), SessionDetails.GetopportunitiesData().businessName));
                        });
                        //errMsgV1.push(String.Format(ErrorMessages['clincLocDateTimeSingleConflict'], conflictClinicList[1].location, this._datePipe.transform(conflictClinicList[0].clinicDate, "shortDate"),  this._datePipe.transform(conflictClinicList[0].startTime, 'shortTime'),  this._datePipe.transform(conflictClinicList[0].endTime,'shortTime'), SessionDetails.GetopportunitiesData().businessName));
                        this.showOverRideTimeSlot(ErrorMessages['resolve'], String.Format("{0}{1}", ErrorMessages['multipleClinicDatesConflictLineItem'], errMsgV1.join('<br/><br/>')));
                        return false;
                    } else {
                        errMsgV1.push(String.Format(ErrorMessages['clincLocDateTimeSingleConflict'], conflictClinicList[0].location, this._datePipe.transform(conflictClinicList[0].clinicDate, "shortDate"), this._datePipe.transform(conflictClinicList[0].startTime, 'shortTime'), this._datePipe.transform(conflictClinicList[0].endTime, 'shortTime'), SessionDetails.GetopportunitiesData().businessName));
                    }

                    this.showOverRideTimeSlot(ErrorMessages['resolve'], errMsgV1.join('<br/><br/>'));
                    return false;
                }
                if (conflictClinicList.length >= 2) {
                    conflictClinicList.forEach(loc => {
                        errMsgV1.push(String.Format(ErrorMessages['clincLocDateTimeMultipleConflict'], loc.location, this._datePipe.transform(loc.clinicDate, "shortDate"), this._datePipe.transform(loc.startTime, 'shortTime'), this._datePipe.transform(loc.endTime, 'shortTime'), SessionDetails.GetopportunitiesData().businessName));
                    });
                    this.showOverRideTimeSlot(ErrorMessages['resolve'], String.Format("{0}{1}", ErrorMessages['multipleClinicDatesConflictLineItem'], errMsgV1.join('<br/><br/>')));
                    return false;
                }
            }
            return true;
        } catch (e) {

            console.log(e);
        }

        return true;
    }

    minimumShotValidations(): boolean {
        var estimatedQty = 0;
        var location250Names = [];
        var errMsg: string[] = [];
        var moreThan255ImmunizationError = false;

        for (let i = 0; i < this.contractForm.value.clinicList.length; i++) {

            for (let j = 0; j < this.contractForm.value.clinicList[i].clinicImzQtyList.length; j++) {
                let qty: number = +this.contractForm.value.clinicList[i].clinicImzQtyList[j].estimatedQuantity;
                if (qty >= 250) {
                    moreThan255ImmunizationError = true;
                    location250Names.push({
                        estimatedQuantity: qty, immunizationName: this.contractForm.value.clinicList[i].clinicImzQtyList[j].immunizationName,
                        location: this.contractForm.value.clinicList[i].location
                    });
                    this.user_250_check = false;
                }
                else {
                    estimatedQty += qty;
                }
            }
            moreThan255ImmunizationError = false;
        }
        if (!this.userApprovedGt250 && location250Names.length > 0) {
            this.userApprovedGt250 = false;
            this.userApprovedLt25 = false;
        }
        else {
            this.userApprovedGt250 = true;
        }
        if (!this.user_250_check) {
            if (!this.userApprovedGt250) {
                location250Names.forEach(item => {
                    errMsg.push(String.Format(ErrorMessages['moreThan250Immunizations'], item.estimatedQuantity, item.immunizationName, item.location));
                });
                var userProfile = SessionDetails.GetUserProfile();
                //if (userProfile.userRole.toLowerCase() == "admin" || userProfile.userRole.toLowerCase() == "store manager" || userProfile.userRole.toLowerCase() == "pharmacy manager") {
                this.showConfirm250ImmDialog(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
                // }
                // else
                // {
                //  this.showDialogV2(ErrorMessages['warning'], errMsg.join('<br/><br/>'));
                //}
                return false;
            }
        }
        return true;
    }
    showDialogV2(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.locListModalDlg = true;
    }
    okClickedV2() {
        this.locListModalDlg = false;
    }
    handleValidationsOption() {
        try {
            this.contractForm.value.locations.forEach(loc => {
                if (loc.isNoClinic == true) {
                    if (loc.localvouchersDist.length <= 0 || loc.contactFirstName.length <= 0 || loc.contactLastName.length <= 0 || loc.contactPhone.length <= 0 ||
                        loc.contactPhone === null || loc.localEstmdShots.length <= 0) {
                        throw false;
                    }
                }
                else {
                    if (loc.address1.length <= 0 || loc.address2.length <= 0 || loc.city.length <= 0 || loc.clinicDate === null || loc.clinicDate === ""
                        || loc.endTime === null || loc.startTime === null || loc.endTime === "" || loc.startTime === "" ||
                        loc.state.length <= 0 || loc.localEstmdShots.length <= 0 || loc.contactEmail.length <= 0 ||
                        loc.contactFirstName.length <= 0 || loc.contactLastName.length <= 0 || loc.contactPhone.length <= 0 ||
                        loc.contactPhone === null || loc.localEstmdShots.length <= 0
                        || loc.localvouchersDist.length <= 0 || loc.zipCode === null || loc.zipCode.length <= 0) {
                        throw false;
                    }
                }
            }
            );
        }
        catch (e) {
            return e;
        }
        return true;
    }

    CancelCharity() {
        //if (this.contractForm.value.length > 0) {
        this.showDialogCancel(ErrorMessages['unSavedData'], ErrorMessages['charity_alert'])
        this.savePopUp = true;
        // }
        // else {
        //   if (this.router) this.router.navigate(['./communityoutreach/storehome']);
        // }
    }
    doNotSave() {
        this.savePopUp = false;
        if (this.router) this.router.navigate(['./communityoutreach/storehome']);
    }
    Continue() {
        this.savePopUp = false;
    }

    showDialogCancel(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
    }

    showDialog(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.display = true;
    }
    showErrorDialog(msgSummary: string, msgDetail: string) {
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.errordisplay = true;
    }
    confirm(hdr: string, msg: string) {
        this.confirmationService.confirm({
            message: msg,
            header: hdr,
            accept: () => {
                this.user_14days_check = true;
                this.save(false);
                //todo to display successful message after subscribe          
                //this.showDialog(ErrorMessages['chSummary'], ErrorMessages['chSucccess']);
                return;
            },
            reject: () => {
                return;
            }
        });
    }
    confirmOverride(hdr: string, msg: string) {
        this.confirmationService.confirm({
            message: msg,
            header: hdr,
            accept: () => {
                this.save(true);
                return;
            },
            reject: () => {
                return;
            }
        });
    }

    displayNgMsg(msgSeverity: string, msgSummary: string, msgDetail: string) {
        this.msgs = [];
        this.msgs.push({ severity: msgSeverity, summary: msgSummary, detail: msgDetail });
    }

    okClicked() {
        this.display = false;
        if (this.router) this.router.navigate(['/communityoutreach/storehome']);

    }

    okErrorClicked() {
        this.errordisplay = false;
    }
    disabledButton() {
        if (this.disable_button) {
            return 'false';
        }
        else {
            return '';
        }

    }
    overrideTimeSlot() {
        this.overrideTimeSlotPopup = false;
        this.dialogMsg = '';
        this.dialogSummary = '';
        this.allowTimeOverRide = true;
        this.save(false);
    }
    cancelTimeSlot() {
        this.overrideTimeSlotPopup = false;
        this.dialogMsg = '';
        this.dialogSummary = '';
        this.allowTimeOverRide = false;
    }
    showOverRideTimeSlot(msgSummary: string, msgDetail: string) {
        this.allowTimeOverRide = false;
        this.dialogMsg = msgDetail;
        this.dialogSummary = msgSummary;
        this.overrideTimeSlotPopup = true;
    }

}
