// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-resource{
    background: #2a7db5 !important;
}
.text-blue{
    color: #2a7db5 !important;;
}
.list-group-item {
    padding: 5px 10px !important;
}

.btn-lst {
    padding: 4px 4px !important;
    line-height: 0 !important;
}

.btn-resource{
    background: #2a7db5 !important;
    color: white;
    margin-top: 10px;
    padding:5px 10px !important;
    border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/resources/components/immunization/immunization.component.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,8BAA8B;IAC9B,YAAY;IACZ,gBAAgB;IAChB,2BAA2B;IAC3B,kBAAkB;AACtB","sourcesContent":[".bg-resource{\r\n    background: #2a7db5 !important;\r\n}\r\n.text-blue{\r\n    color: #2a7db5 !important;;\r\n}\r\n.list-group-item {\r\n    padding: 5px 10px !important;\r\n}\r\n\r\n.btn-lst {\r\n    padding: 4px 4px !important;\r\n    line-height: 0 !important;\r\n}\r\n\r\n.btn-resource{\r\n    background: #2a7db5 !important;\r\n    color: white;\r\n    margin-top: 10px;\r\n    padding:5px 10px !important;\r\n    border-radius: 3px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
