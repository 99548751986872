import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { OutreachProgramService } from '../../services/outreach-program.service';
import { Immunization2 } from '../../../../models/contract';
import { states } from '../../../../JSON/States';
import { OutReachProgramType } from '../../../../models/OutReachPrograms';
import { SessionDetails } from '../../../../utility/session';
import { ConfirmationService } from 'primeng/api';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { Util } from '../../../../utility/util';
import { environment } from '../../../../../environments/environment';
import { COVIDVisits } from '../../../../JSON/COVIDVisits';
import { adminUserNames } from '../../../../JSON/adminsInformation';
import * as _ from 'lodash';
import { ClinicDetailsUtilities } from '../../../../utility/clinicDetailsUtilities';
import { UniquePipeClinicDetails } from '../../../../modules/clinicdetails/pipes/clinicUnique.pipe';
import * as moment from 'moment';
import { LocationNumberPipe } from '../../pipes/location-number.pipe';
import { UserRole } from '../../../../config-files/UserRole';
declare var google: any;

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css'],
  providers: [DatePipe, UniquePipeClinicDetails]
})
export class LocationsComponent implements OnInit {
  @Input('group')
  public locationForm: FormGroup;
  @Input('locFormArray')
  public locationFormArray: FormArray;
  @Input('locationNumber')
  locationNumber: number = 0;
  @Input('outReachProgramType')
  outReachProgramType: string = OutReachProgramType.contracts;
  locationData: any = {};
  immunizations: any[];
  states: any[];
  COVIDVisits: any[];
  defaultStartTime: Date;
  isCovidVaccineAvailable: boolean = false;
  defaultDate: Date = new Date();
  maxDateValue: Date = new Date();
  minDateValue: Date = new Date();
  invalidDates: Array<Date> = [];
  dialogSummary: string;
  dialogMsg: string;
  display: boolean = false;
  locDlgDisplay: boolean = false;
  dlgDisplay: boolean = false;
  outReachTypeOtherVisible = false;
  isStoreIs_From_RestrictedState: boolean = false;
  from_local_clinic: boolean = false;
  is_reAgreement: boolean = false;
  isCovid: boolean = false;
  isCovidVoucher: boolean = false;
  is_reAgreementCommOutreach: boolean = false;
  canDisplayNoClinicFeeOption: boolean = true;
  isAdmin: boolean;
  canDisplayVouchersDistributedField = false;
  shouldDisplayNoClinicVoucherDistribution = false;
  @Input('totalClinicsCount') totalClinicsCount: number;
  shouldDisableVouchersDistributed = false;
  onlyVoucherPaymentMethodSelected = false;
  shouldDisableNoClinicVoucherDistributionText = false;
  IsAmendisNoClinicValue: boolean = false;
  isOnlyVoucherValue =  false;
  ProfileDetails : any;
  estimatedQuantity: Number;
  hasVoucherPaymentMethodOnly = false;
  isRhdUser = false;
  manualNoClinicFeeChecked = null;
  opportunitie;

  constructor(
    private _localContractService: OutreachProgramService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private _utility: Util,
    private _ref: ChangeDetectorRef,
    private _uniquePipeClinicDetails: UniquePipeClinicDetails,
    public locationNumberPipe: LocationNumberPipe
  ) {
  }

  ngOnInit() { 

    this.shouldDisplayNoClinicVoucherDistribution = true;
    this.from_local_clinic = SessionDetails.GetfromUserPage();

    if (sessionStorage["AmendAgreement"] != null) {
      this.is_reAgreement = JSON.parse(sessionStorage["AmendAgreement"]);
      //this.locationNumber=0;
    }
    let today = new Date();
    if (sessionStorage["AmendAgreementForCommOutreach"] != null) {
      this.is_reAgreementCommOutreach = JSON.parse(sessionStorage["AmendAgreementForCommOutreach"]);

      this.locationFormArray.controls.forEach((element, index) => {
 
        let OutreachTypeIdzVal = element.get('coOutreachTypeId').value;
        this.locationFormArray.controls[index].get('coOutreachTypeId').setValue(String(OutreachTypeIdzVal));
        
        if(String(OutreachTypeIdzVal)==='4')
        {
          this.outReachTypeOtherVisible = true;
          this.locationForm.controls['coOutreachTypeDesc'].enable();
          this.locationForm.controls['coOutreachTypeDesc'].setValidators([Validators.required]);
          this.locationForm.controls['coOutreachTypeDesc'].updateValueAndValidity();
        }
      });

      this.locationFormArray.controls.forEach((element, index) => {
        var commORdate = new Date(this.locationFormArray.controls[index].get('clinicDate').value);
        this.locationFormArray.controls[index].get('clinicDate').setValue(new Date(commORdate.getFullYear(), commORdate.getMonth(), commORdate.getDate(), 8, 0, 0));
      });

      // addding zero value to the immunizations with index greater than zero
      for (let i = 0; i < this.locationFormArray.controls.length; i++) {
        var immDistinct = Array.from(new Set(this.locationFormArray.controls[i].value.clinicImzQtyList.map(x => x.immunizationPk)));

        immDistinct.forEach(element => {
          let frmGrps = this.locationForm.controls.clinicImzQtyList['controls'].filter(p => p.controls.immunizationPk.value == element);
          frmGrps.forEach((element, index) => {
            if (index > 0)
              element.patchValue({
                estimatedQuantity: 0
              });
          });
        });
      }
    }

    this.states = states.sort((a, b) => a.id.localeCompare(b.id));
    this.COVIDVisits = COVIDVisits;//.sort((a,b)=>a.id.localeCompare(b.id));

    var userProfile = SessionDetails.GetUserProfile();
    this.opportunitie = SessionDetails.GetopportunitiesData();
    this.ProfileDetails = userProfile;
    if(this.opportunitie.covidOpportunityType === 1){
      if(this.locationForm.contains('estimatedParticipants'))
        if(!this.locationForm.controls['isNoClinic'].value && this.locationForm?.get('estimatedParticipants')?.value > 0 && this.manualNoClinicFeeChecked === null)
          this.handleRsvVaccinceSelection();
    };
    this.isCovid = this.locationForm.value.clinicImzQtyList.findIndex(x => x.immunizationName.includes('COVID')) > -1 ? true : false;
    if (this._utility.isStoreIsfromRestrictedState()) {
      if (userProfile.userRole.toLowerCase() == "store manager" || userProfile.userRole.toLowerCase() == "pharmacy manager") {
        this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);//+14
        this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);//+14
      }
      else {
        this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
        this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
      }
      if (this.checkIfAnyFluImmIsSelected()) {
        this.maxDateValue = new Date(today.getFullYear() + 1, 3, 1, 12, 55, 55);
      }
      else {
        this.maxDateValue = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate() - 1, 12, 55, 55);
      }
    }
    else {
      if (userProfile.userRole.toLowerCase() == "store manager" || userProfile.userRole.toLowerCase() == "pharmacy manager"
      ) {
        this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);//+14
        this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);//+14

      } else {
        this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
        this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
      }
      this.maxDateValue = new Date(today.getFullYear() + 5, today.getMonth(), today.getDate() - 1, 8, 0, 0);
    }
    this.defaultStartTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
    if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
      this.isCovidVaccineAvailable = this._localContractService.getCOSelectedImmunizations().filter(p => p.immunizationName.search(/COVID19/gi) >= 0).length > 0;
    }
    else {
      this.isCovidVaccineAvailable = this._localContractService.getSelectedImmunizations().filter(p => p.immunizationName.search(/COVID19/gi) >= 0).length > 0;
    }
    let covidVaccine = this._localContractService.getSelectedImmunizations().filter(p => p.immunizationName.includes('COVID19 Vaccine (')).length > 0;

    // 775 fix
    this.isCovidVoucher = this._localContractService.getSelectedImmunizations().filter(item => item.immunizationName.includes('Vaccine Voucher')).length > 0;

    // if(!covidVaccine && this.isCovidVoucher){
    //   this.locationFormArray.controls[0].get('isNoClinic').setValue(true);
    //   //this.isCovidVoucher = !this.isCovidVoucher;
    // }    
    if (['CommunityUpdate@WagOutreach.com', 'jerry.v.thomas@walgreens.com', 'samuel.nass@walgreens.com', 'carlos.flores@walgreens.com', 'enterpriseimmunizationservice@walgreens.com', 'tyrone.clark@walgreens.com'].indexOf(userProfile.email) > -1) {
      let today = new Date();
      this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);

      this.minDateValue.setMonth(this.minDateValue.getMonth() - 6);
      this.minDateValue.setDate(this.minDateValue.getDate() + 4);
    }
    //work around to fill the input mask fields
    setTimeout(() => {
      const maskedInputControl = this.locationForm.get('contactPhone') as FormControl;
      maskedInputControl.setValue(maskedInputControl.value);
    });

    this.isAdmin = adminUserNames.filter(item => item.toLowerCase() == userProfile.userName.toLowerCase()).length > 0 ;
    this.isRhdUser =  userProfile.userRole.toLocaleLowerCase() === UserRole.REGIONALHEALTHCAREDIRECTOR;
    if (this.isAdmin || this.isRhdUser) {
      this.canDisplayNoClinicFeeOption = true;
    }
    else {
      this.canDisplayNoClinicFeeOption = false;
    }
    this.handleConditionForVouchersDistributed();

    this.onlyVoucherPaymentMethodSelected = ClinicDetailsUtilities.CheckIfOnlyVoucherPaymentMethodIsSelected(this.locationForm.get('clinicImzQtyList').value);
    this.hasVoucherPaymentMethodOnly = ClinicDetailsUtilities.CheckOnlyVoucherPaymentIsSelected(this.locationForm.get('clinicImzQtyList').value);
    
    if (this.locationForm.controls['isNoClinic'].value && this.onlyVoucherPaymentMethodSelected) {
      this.shouldDisableVouchersDistributed = true;
    }
   
    if(this.is_reAgreement){
      const clinicImzList = ClinicDetailsUtilities.GetImmunizationGroupedByImmzPk(this.locationForm.get('clinicImzQtyList').value);
      let totalShotsCount =0;
      for (const clinicImz of clinicImzList) {
        const estimatedShots = clinicImz.Immzs.reduce((accumulator, object) => {
          return accumulator + (object?.estimatedQuantity ? parseInt(object.estimatedQuantity) : 0);
        }, 0);
        totalShotsCount = totalShotsCount + estimatedShots;
      }
      if(this.locationForm.controls['isNoClinic'].value && totalShotsCount === parseInt(this.locationForm.controls['vouchersDistributed'].value ?? 0)){
        this.shouldDisableVouchersDistributed = true;
      }
    }

   setTimeout(()=>{
      this.locationFormArray.controls.forEach((element, index) => {
        let OutreachTypeIdzVal = element.get('coOutreachTypeId').value;
        this.locationFormArray.controls[index].get('coOutreachTypeId').setValue(String(OutreachTypeIdzVal));
        
        if(String(OutreachTypeIdzVal)==='4')
        {
          this.outReachTypeOtherVisible = true;
          this.locationForm.controls['coOutreachTypeDesc'].enable();
          this.locationForm.controls['coOutreachTypeDesc'].setValidators([Validators.required]);
          this.locationForm.controls['coOutreachTypeDesc'].updateValueAndValidity();
        }
        else
        {
          this.outReachTypeOtherVisible = false;
          this.locationForm.controls['coOutreachTypeDesc'].setValidators(null);
          this.locationForm.controls['coOutreachTypeDesc'].setErrors(null);
          this.locationForm.controls['coOutreachTypeDesc'].updateValueAndValidity();
          this.locationForm.controls['coOutreachTypeDesc'].disable();
        }
      });
    },2000)

    this._ref.detectChanges();

    // For equity events if "Wags Free Flu Voucher" payment method
    // is selected then display vouchers distributed
    if(this.outReachProgramType === OutReachProgramType.equityevents) { 
      const newClinicImzQtyList = ClinicDetailsUtilities.GetImmunizationGroupedByImmzPk(this.locationForm.get('clinicImzQtyList').value);

      if(newClinicImzQtyList.some(list => list.immPk == '31')){
        const immunizations = newClinicImzQtyList[newClinicImzQtyList.findIndex(list => list.immPk == '31')];
        if(immunizations.Immzs.some(immunization => immunization.paymentTypeId == 14)){
          this.canDisplayVouchersDistributedField = true;
        }
      }

      let isAmmendValue =    sessionStorage["IsEquityAmendAgreement"]; 
      if(isAmmendValue ==="true")
      {
        this.IsAmendisNoClinicValue =true;
      }
      else
      {
        this.IsAmendisNoClinicValue =false;
      }
    }
  }

  logImm(immunization, i) {
    //console.log('index = '+ i ,immunization.value.immunizationName);
  }

  public handleNoClinicVoucherDistribution() {
    if (this.shouldDisplayNoClinicVoucherDistribution) {
      const newClinicImzQtyList = ClinicDetailsUtilities.GetImmunizationGroupedByImmzPk(this.locationForm.get('clinicImzQtyList').value);
      
      for (const imzqyt of newClinicImzQtyList) {
        if (imzqyt.immPk == '31' && imzqyt.Immzs.some(immunization => immunization.paymentTypeId == 14) && imzqyt.Immzs.some(immunization => parseInt(immunization.estimatedQuantity) > 0)) {
          // this.shouldDisableNoClinicVoucherDistribution = false;
          this.locationForm.controls['vouchersDistributed'].setValue(0);
        }
        else if (imzqyt.immPk == '31' && imzqyt.Immzs.some(immunization => immunization.paymentTypeId == 14) && imzqyt.Immzs.some(immunization => parseInt(immunization.estimatedQuantity) == 0)) {
          // this.shouldDisableNoClinicVoucherDistribution = true;
          this.locationForm.controls['isNoClinic'].setValue(0);
        }
      }
    }
  }

  focusOutFunction(immunization) {
    let estimatedQty = Number(immunization.controls.estimatedQuantity.value);
    this.estimatedQuantity = estimatedQty;
    let estimatedParticipants = Number(this.locationForm.get('estimatedParticipants').value);
    let frmGrps = this.locationForm.controls.clinicImzQtyList['controls'].filter(p => p.controls.immunizationPk.value == immunization.controls.immunizationPk.value);
    if(this.opportunitie.covidOpportunityType === 1){
      if(!this.locationForm.controls['isNoClinic'].value && this.locationForm.get('estimatedParticipants').value > 0 && this.manualNoClinicFeeChecked === null)
      this.handleRsvVaccinceSelection();
    };
    frmGrps.forEach((element, index) => {
      if (index > 0)
        element.patchValue({
          estimatedQuantity: 0
        });
    });
    if(estimatedQty != null && estimatedQty > 0 && estimatedQty != undefined && estimatedQty > estimatedParticipants &&  this.locationForm.controls['isNoClinic'].value == 0){
      this.showDialogV3("Please resolve the below issues to continue further", ErrorMessages["estimatedShotsWarning"]);
    }
  }
  onlyNumberKey(event) {
    Util.onlyNumbers(event);
  }

  isFieldValid(field: string) {
    if (this.locationForm.get(field) != null) {
      return !this.locationForm.get(field).valid && this.locationForm.get(field).touched;
    }
  }

  isValidArrayField(fields: string, index: number) {
    let return_value = false;
    Object.keys(this.locationForm.controls).forEach(field => {
      const control = this.locationForm.get(field);
      if (control instanceof FormArray) {
        return_value = !control.controls[index].get(fields).valid && control.controls[index].get(fields).touched;
      }
    });
    return return_value;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  displayFieldCssexpiry(field: string) {
    return {
      'has-error': false,
      'has-feedback': false
    };
  }

  displayFieldCssForArray(field: string, index: number) {
    const clinicImmzList = this.locationForm.controls['clinicImzQtyList'] as FormArray;
    const transformedList = this._uniquePipeClinicDetails.transform(clinicImmzList.controls);
    const fieldValue = transformedList[index].controls[field].value;
    const fieldTouched = transformedList[index].controls[field].touched;
    const fieldInvalid = transformedList[index].controls[field].status == 'INVALID';
    const fieldPristine = transformedList[index].controls[field].pristine;
    const return_value = (fieldInvalid && fieldTouched) || (!fieldPristine &&  fieldValue == "") ;
    return {
      'has-error': return_value,
      'has-feedback': return_value
    };
  }

  checkIfFluImmForCorpInvoiceSelected() {
    let added_immunizations = this._localContractService.getSelectedImmunizations();
    try {
      added_immunizations.forEach(rec => {
        if (rec.paymentTypeId == 13 && (rec.sendInvoiceTo === '1' || rec.sendInvoiceTo === 1) &&
          (rec.isVoucherNeeded === '1' || rec.isVoucherNeeded === 1) && rec.immunizationName.toLowerCase().search('flu') !== -1) {
          throw true;
        }
      });
    } catch (e) {
      if (e === true)
        return true;
    }
    return false;
  }
  checkIfVoucherYesSelectedForFluCorpInvoiceSelected() {
    let added_immunizations = this._localContractService.getSelectedImmunizations();
    let isFluYes: boolean = false;
    let count: number = 0;
    try {
      added_immunizations.forEach(rec => {
        if ((rec.paymentTypeId == 13 && (rec.sendInvoiceTo == '1' || rec.sendInvoiceTo == 1))) { //&& rec.immunizationName.toLowerCase().search('flu')!==-1
          //throw true;
          if (rec.isVoucherNeeded == 1 || rec.isVoucherNeeded == '1') {
            isFluYes = true;
          }
          count++;
        }
      });
    } catch (e) {
      if (e === true)
        return true;
    }
    if (count <= 0) {
      return true;
    }
    else if (count > 0 && !isFluYes) {
      return false;
    }

    return true;
  }

  checkIfVoucherYesSelectedForNonFluCorpInvoiceSelected() {
    let added_immunizations = this._localContractService.getSelectedImmunizations();
    let isNonFluYes: boolean = false;
    let count: number = 0;
    try {
      added_immunizations.forEach(rec => {
        if (rec.paymentTypeId == 13 && (rec.sendInvoiceTo === '1' || rec.sendInvoiceTo === 1) && rec.immunizationName.toLowerCase().search('flu') == -1) {
          if (rec.isVoucher == true) {
            isNonFluYes = true;
          }
          count++;
          //throw true;
        }
      });
    } catch (e) {
      if (e === true)
        return true;
    }
    if (count <= 0) {
      return true;
    }
    else if (count > 0 && !isNonFluYes) {
      return false;
    }

    return true;
  }

  checkIfNonFluImmForCorpInvoiceSelected() {
    let added_immunizations = this._localContractService.getSelectedImmunizations();
    try {
      added_immunizations.forEach(rec => {
        if (rec.paymentTypeId == 13 && (rec.sendInvoiceTo === '1' || rec.sendInvoiceTo === 1)
          && (rec.isVoucherNeeded === '1' || rec.isVoucherNeeded === 1 && rec.immunizationName.toLowerCase().search('flu') == -1)) {
          throw true;
        }
        // else if(rec.paymentTypeId==6 && rec.sendInvoiceTo===true && rec.immunizationName.toLowerCase().search('flu') == -1){
        //     this.hasNonFluImmSelected = true;
        // }
      });
    } catch (e) {
      if (e === true)
        return true;
    }
    return false;
  }
  checkIfCovidImmForCorpInvoiceSelected() {
    let added_immunizations = this._localContractService.getSelectedImmunizations();
    try {
      added_immunizations.forEach(rec => {
        if (rec.paymentTypeId == 13 && (rec.sendInvoiceTo === '1' || rec.sendInvoiceTo === 1)
          && (rec.isVoucherNeeded === '1' || rec.isVoucherNeeded === 1) && rec.immunizationName.toLowerCase().search('covid') !== -1) {
          throw true;
        }
        // else if(rec.paymentTypeId==6 && rec.sendInvoiceTo===true && rec.immunizationName.toLowerCase().search('flu') == -1){
        //     this.hasNonFluImmSelected = true;
        // }
      });
    } catch (e) {
      if (e === true)
        return true;
    }
    return false;
  }
 
  calcNoClinicStatus(): boolean {
    const newClinicFeeValue = this.getServiceFee();
    this.locationForm.controls['clinicFee'].setValue(newClinicFeeValue);
    let hasCorpInvoice = this.checkIfAnyImmunizationsHasCorpInvoiceV2();
    if (!hasCorpInvoice || this.from_local_clinic || this.isCovidVoucher) {
      return true;
    } else {
      return null; 
    }
  }

  checkIfAnyImmunizationsHasCorpInvoiceV2(): boolean {
    let added_immunizations = this._localContractService.getSelectedImmunizations();
    try {
      let hasCorpInvoice: Boolean = false;
      var fluType = 'flu';
      let fluDone: Boolean = false;
      let routineDone: Boolean = false;
      added_immunizations.forEach(rec => {
        if (rec.paymentTypeId == 13 && (rec.sendInvoiceTo == '1' || rec.sendInvoiceTo == 1)) {
          hasCorpInvoice = true;
          if (rec.immunizationName.toLowerCase().search(fluType) == -1 && !routineDone) {
            routineDone = true;
          }
          else {
            if (!fluDone) {
              fluDone = true;
            }
          }
        }
      });
      if (hasCorpInvoice == true)
        return true;
    } catch (e) {
      if (e === true)
        return true;
    }
    return false;
  }

  onClinicDateSelected(selectedDate: Date, i) {
    this._localContractService.setValidationsRequiredStatus(true);
    this._localContractService.SetIscanceled(true);
    this.locationForm.controls["clinicDate"].setValue(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 18, 0, 0));
    let address1: string = this.locationFormArray.controls[i].get('address1').value;
    let address2: string = this.locationFormArray.controls[i].get('address2').value;
    let city: string = this.locationFormArray.controls[i].get('city').value;
    let state: string = this.locationFormArray.controls[i].get('state').value;
    let zipCode: string = this.locationFormArray.controls[i].get('zipCode').value;
    let latitude: string = this.locationFormArray.controls[i].get('latitude').value;
    let longitude: string = this.locationFormArray.controls[i].get('longitude').value;
    let address = address1 + ", " + address2 + ", " + city + ", " + state + " " + zipCode;
    for(let immunization of this.locationForm.controls.clinicImzQtyList['controls']) {
       if(immunization.get('immunizationName').value.toLocaleLowerCase()?.includes('covid')){
        let startDate  = moment(environment.covidVaccineNotAviableDates.startDate, "DD/MM/YYYY");
        let endDate = moment(environment.covidVaccineNotAviableDates.endDate, "DD/MM/YYYY");
        let comparisonDate = moment(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 18, 0, 0), "DD/MM/YYYY");

        if(comparisonDate.isBetween(startDate, endDate) || comparisonDate.isSame(startDate) || comparisonDate.isSame(endDate)){
          this.showDialogV2("Warning", ErrorMessages["sesionalCovid19Info"]);
          // immunization.estimatedQuantity.setErrors({ 'incorrect': true });
        }
       }
    }
    if (address1.length > 0 && city.length > 0 && state.length > 0 && zipCode.length > 0 && (!latitude ||
      !longitude)) {
      this.getLatitudeLongitude(this.locationFormArray, address, i);
    }
  }
  onSelectedTime(selectedTime, i) {

    this._localContractService.setValidationsRequiredStatus(true);
    this._localContractService.SetIscanceled(true);
    let address1: string = this.locationFormArray.controls[i].get('address1').value;
    let address2: string = this.locationFormArray.controls[i].get('address2').value;
    let city: string = this.locationFormArray.controls[i].get('city').value;
    let state: string = this.locationFormArray.controls[i].get('state').value;
    let zipCode: string = this.locationFormArray.controls[i].get('zipCode').value;
    let address = address1 + ", " + address2 + ", " + city + ", " + state + " " + zipCode;
    let latitude: string = this.locationFormArray.controls[i].get('latitude').value;
    let longitude: string = this.locationFormArray.controls[i].get('longitude').value;

    if (address1.length > 0 && city.length > 0 && state.length > 0 && zipCode.length > 0 && (!latitude ||
      !longitude)) {
      this.getLatitudeLongitude(this.locationFormArray, address, i);
    }
  }
  onvisitTypeClicked(visitRadio: any) {
  }
  onOtherRadioClicked(OtherRadio: any) {
    if (this.outReachProgramType == OutReachProgramType.communityoutreach && OtherRadio === '4') {
      this.outReachTypeOtherVisible = true;
      this.locationForm.controls['coOutreachTypeDesc'].enable();
      this.locationForm.controls['coOutreachTypeDesc'].setValidators([Validators.required]);
      this.locationForm.controls['coOutreachTypeDesc'].updateValueAndValidity();
    }
    else if (this.outReachProgramType == OutReachProgramType.equityevents && OtherRadio === '4') {
      this.outReachTypeOtherVisible = true;
      this.locationForm.controls['coOutreachTypeDesc'].enable();
      this.locationForm.controls['coOutreachTypeDesc'].setValidators([Validators.required]);
      this.locationForm.controls['coOutreachTypeDesc'].updateValueAndValidity();
    }
    else {
      this.outReachTypeOtherVisible = false;
      this.locationForm.controls['coOutreachTypeDesc'].setValidators(null);
      this.locationForm.controls['coOutreachTypeDesc'].setErrors(null);
      this.locationForm.controls['coOutreachTypeDesc'].updateValueAndValidity();
      this.locationForm.controls['coOutreachTypeDesc'].disable();

    }
  }

  copyPreviousLocation(formIndex: number, checked: boolean) {
    if (!checked) {
      this.locationFormArray.controls[formIndex].get('address1').setValue('');
      this.locationFormArray.controls[formIndex].get('address2').setValue('');
      this.locationFormArray.controls[formIndex].get('city').setValue('');
      this.locationFormArray.controls[formIndex].get('state').setValue('');
      this.locationFormArray.controls[formIndex].get('zipCode').setValue('');
    }
    else {
      this._localContractService.SetIscanceled(true);
      this.locationFormArray.controls[formIndex].get('address1').setValue(this.locationFormArray.controls[formIndex - 1].get('address1').value);
      this.locationFormArray.controls[formIndex].get('address2').setValue(this.locationFormArray.controls[formIndex - 1].get('address2').value);
      this.locationFormArray.controls[formIndex].get('city').setValue(this.locationFormArray.controls[formIndex - 1].get('city').value);
      this.locationFormArray.controls[formIndex].get('state').setValue(this.locationFormArray.controls[formIndex - 1].get('state').value);
      this.locationFormArray.controls[formIndex].get('zipCode').setValue(this.locationFormArray.controls[formIndex - 1].get('zipCode').value);
    }
  }

  copyPreviousContactInfo(formIndex: number, checked: boolean) {
    if (!checked) {
      this.locationFormArray.controls[formIndex].get('contactFirstName').setValue('');
      this.locationFormArray.controls[formIndex].get('contactLastName').setValue('');
      this.locationFormArray.controls[formIndex].get('contactEmail').setValue('');
      this.locationFormArray.controls[formIndex].get('contactPhone').setValue('');
    }
    else {
      this._localContractService.SetIscanceled(true);
      this.locationFormArray.controls[formIndex].get('contactFirstName').setValue(this.locationFormArray.controls[formIndex - 1].get('contactFirstName').value);
      this.locationFormArray.controls[formIndex].get('contactLastName').setValue(this.locationFormArray.controls[formIndex - 1].get('contactLastName').value);
      this.locationFormArray.controls[formIndex].get('contactEmail').setValue(this.locationFormArray.controls[formIndex - 1].get('contactEmail').value);
      this.locationFormArray.controls[formIndex].get('contactPhone').setValue(this.locationFormArray.controls[formIndex - 1].get('contactPhone').value);
    }
  }

  getGoogleLatLngCoordinates(i: number) {
    let address1: string = this.locationFormArray.controls[i].get('address1').value;
    let address2: string = this.locationFormArray.controls[i].get('address2').value;
    let city: string = this.locationFormArray.controls[i].get('city').value;
    let state: string = this.locationFormArray.controls[i].get('state').value;
    let zipCode: string = this.locationFormArray.controls[i].get('zipCode').value;
    let address = address1 + ", " + address2 + ", " + city + ", " + state + " " + zipCode;
    let latitude: string = this.locationFormArray.controls[i].get('latitude').value;
    let longitude: string = this.locationFormArray.controls[i].get('longitude').value;
    this._localContractService.SetIscanceled(true);
    if (address1 && address1.length > 0 && city && city.length > 0 && state && state.length > 0 && zipCode && zipCode.length > 0 && (!latitude ||
      !longitude)) {
      this.getLatitudeLongitude(this.locationFormArray, address, i)
    }
  }

  //geocoder to get the latitude and longitude 
  getLatitudeLongitude(locationFormArray, fulladdress, index) {

    //let controlclinicList = <FormArray>this.clinicDetailsForm.controls['clinicList'];
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': fulladdress }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        locationFormArray.controls[index].get('latitude').setValue(results[0].geometry.location.lat());
        locationFormArray.controls[index].get('longitude').setValue(results[0].geometry.location.lng());
      }
    });

  }

  

  noClinicOptionChanged(formIndex: number, checked: boolean) {
    // if the selected payment method is Voucher: Corporate to Invoice Employer Directly 
    // and no other payment methods are selected 
    // then don't allow to uncheck No Clinic - Voucher Distribution Only checkbox
    this.onlyVoucherPaymentMethodSelected = ClinicDetailsUtilities.CheckIfOnlyVoucherPaymentMethodIsSelected(this.locationForm.get('clinicImzQtyList').value);
    const newClinicImzQtyList = ClinicDetailsUtilities.GetImmunizationGroupedByImmzPk(this.locationForm.get('clinicImzQtyList').value);

    if(this.outReachProgramType === 'equityevents')
    {
      if (newClinicImzQtyList.some(imzqty => imzqty.immPk == '31' && imzqty.Immzs.some(immunization => immunization.paymentTypeId == 14)) && checked) {
        this.locationForm.controls['isNoClinic'].setValue(1);
      }
      else
      {
        this.locationForm.controls['isNoClinic'].setValue(0);
        return false;
      }
    }
  
    let clinicDateCtrl = this.locationFormArray.controls[formIndex].get('clinicDate');
    let startTimeCtrl = this.locationFormArray.controls[formIndex].get('startTime');
    let endTimeCtrl = this.locationFormArray.controls[formIndex].get('endTime');

    if (newClinicImzQtyList.some(imzqty => imzqty.immPk == '31' && imzqty.Immzs.some(immunization => immunization.paymentTypeId == 14))
         && checked) {
      if (!this.checkIfVoucherYesSelectedForFluCorpInvoiceSelected()) {
        let sumMsg = ErrorMessages['resolve'];
        let errMsg = ErrorMessages['voucherFluYes'];
        this.locationFormArray.controls[formIndex].get('isNoClinic').setValue(false);
        this.showDialogV2(sumMsg, errMsg);
        return;
      }
      
      clinicDateCtrl.setValidators(null);
      clinicDateCtrl.setErrors(null);
      clinicDateCtrl.updateValueAndValidity();
      clinicDateCtrl.setValue(null);

      startTimeCtrl.setValidators(null);
      startTimeCtrl.setErrors(null);
      startTimeCtrl.updateValueAndValidity();
      startTimeCtrl.setValue(null);

      endTimeCtrl.setValidators(null);
      endTimeCtrl.setErrors(null);
      endTimeCtrl.updateValueAndValidity();
      endTimeCtrl.setValue(null);
      this.canDisplayNoClinicFeeOption = false;
      this.locationForm.controls['clinicFee'].setValue(0);

      if(this.onlyVoucherPaymentMethodSelected){
        const vouchersDistributedCount = ClinicDetailsUtilities.calculateVouchersDistributedCount(this.locationForm);      
        this.locationForm.controls['vouchersDistributed'].setValue(vouchersDistributedCount);
        // this.shouldDisableVouchersDistributed = true;
        // this.locationForm.controls['vouchersDistributed'].markAsUntouched();
        // this.locationForm.controls['vouchersDistributed'].markAsPristine();
        this.locationForm.controls['vouchersDistributed'].setErrors(null);
        this.locationForm.controls['vouchersDistributed'].setValidators(null);
  
        this.locationForm.controls['vouchersDistributed'].updateValueAndValidity();  
      }
       
      this.canDisplayVouchersDistributedField = true;
      this.shouldDisableNoClinicVoucherDistributionText =true;

      if(this.outReachProgramType === 'equityevents' && checked){
       const imzQtyArray = this.locationForm.controls['clinicImzQtyList'] as FormArray;
       for (const imzQty of imzQtyArray.controls) {
         const imzCtrl = imzQty as FormGroup;
         if(imzCtrl.value.immunizationPk != 31){
           imzCtrl.controls['estimatedQuantity'].setValue(0);
           // imzCtrl.controls['estimatedQuantity'].disable();
         }
       }
      }
    }
    else if(checked && this.outReachProgramType === OutReachProgramType.contracts){
      if (!this.checkIfVoucherYesSelectedForFluCorpInvoiceSelected()) {
        let sumMsg = ErrorMessages['resolve'];
        let errMsg = ErrorMessages['voucherFluYes'];
        this.locationFormArray.controls[formIndex].get('isNoClinic').setValue(false);
        this.showDialogV2(sumMsg, errMsg);
        return;
      }
      clinicDateCtrl.setValidators(null);
      clinicDateCtrl.setErrors(null);
      clinicDateCtrl.updateValueAndValidity();
      clinicDateCtrl.setValue(null);

      startTimeCtrl.setValidators(null);
      startTimeCtrl.setErrors(null);
      startTimeCtrl.updateValueAndValidity();
      startTimeCtrl.setValue(null);

      endTimeCtrl.setValidators(null);
      endTimeCtrl.setErrors(null);
      endTimeCtrl.updateValueAndValidity();
      endTimeCtrl.setValue(null);
      this.canDisplayNoClinicFeeOption = false;
      this.locationForm.controls['clinicFee'].setValue(0);
      this.locationForm.controls['estimatedParticipants'].setValue(0);
      const immunizationControls = this.locationForm.controls['clinicImzQtyList']['controls'];
      for (let i = 0; i < immunizationControls.length; i++) {
        immunizationControls[i].get('estimatedQuantity').setValue(0);
      }

      const vouchersDistributedValue: number = ClinicDetailsUtilities.GetAllEstimatedShotsValue(this.locationForm);
      this.locationForm.controls['vouchersDistributed'].setValue(vouchersDistributedValue);
      this.locationForm.controls['vouchersDistributed'].setErrors(null);
      this.locationForm.controls['vouchersDistributed'].setValidators(null);
      this.locationForm.controls['vouchersDistributed'].updateValueAndValidity();  
       
      this.canDisplayVouchersDistributedField = true;
      this.shouldDisableNoClinicVoucherDistributionText =true;

    }
    else {
      clinicDateCtrl.setValidators([Validators.required]);
      clinicDateCtrl.updateValueAndValidity();

      startTimeCtrl.setValidators([Validators.required]);
      startTimeCtrl.updateValueAndValidity();

      endTimeCtrl.setValidators([Validators.required]);
      endTimeCtrl.updateValueAndValidity();

      this.handleConditionForVouchersDistributed();
      this.locationForm.controls['vouchersDistributed'].setValue(0);
      this.locationForm.controls['vouchersDistributed'].enable();
      if (this.isAdmin || this.isRhdUser) {
        this.canDisplayNoClinicFeeOption = true;
      }
      else {
        this.canDisplayNoClinicFeeOption = false;
      }
      this.shouldDisableVouchersDistributed = false;
      this.hasVoucherPaymentMethodOnly = false;
      this.shouldDisableNoClinicVoucherDistributionText =false;
      if (this.outReachProgramType === OutReachProgramType.equityevents && checked) {
      const imzQtyArray = this.locationForm.controls['clinicImzQtyList'] as FormArray;
      for (const imzQty of imzQtyArray.controls) {
        const imzCtrl = imzQty as FormGroup;
        if (imzCtrl.value.immunizationPk != 31) {
          imzCtrl.controls['estimatedQuantity'].setValue(null);
          // imzCtrl.controls['estimatedQuantity'].enable();
        }
      }}

    }
  
  }
  showHideNoClinicOption(formIndex: number) {
    let returnValue = true;
    if (this.is_reAgreement && this.isCovidVaccineAvailable && this.isCovidVoucher)
      returnValue = true;
    else if (this.is_reAgreement && !this.locationFormArray.controls[formIndex].get('isNoClinic').value) {
      returnValue = false;
    }
    return returnValue;
  }
  showDialog(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.display = true;
  }
  showDialogV2(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.locDlgDisplay = true;
  }
  showDialogV3(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.dlgDisplay = true;
  }
  confirm() {
    let dtlMsg = ErrorMessages['clinicDateReminderBefore2WeeksEN'];
    let summary = ErrorMessages['impRmndr'];
    this.confirmationService.confirm({
      message: dtlMsg,
      header: summary,
      icon: 'fa fa-question-circle',
      accept: () => { },
      reject: () => {
        return;
      }
    });
  }
  AllowAdminsToEditDataForRestrictedState() {
    return this._utility.AllowAdminsToEditDataForRestrictedState();
  }
  checkIfAnyFluImmIsSelected() {
    let added_immunizations: Immunization2[] = [];
    if (this.outReachProgramType === OutReachProgramType.communityoutreach) {
      added_immunizations = this._localContractService.getCOSelectedImmunizations();
    }
    else {
      added_immunizations = this._localContractService.getSelectedImmunizations();
    }
    try {
      added_immunizations.forEach(rec => {
        if (rec.immunizationName.toLowerCase().search('flu') != -1) {
          throw true;
        }
      });
    } catch (e) {
      if (e === true)
        return true;
    }
    return false;
  }
  isStoreIsfromRestrictedState() {
    var resrict_states = ["MO"]; //"DC" -- Removing DC state as per task# 1363
    var current_date = new Date();
    var storeState = SessionDetails.GetState();
    if (resrict_states.indexOf(storeState) > -1) {
      if (storeState == "MO" && current_date > new Date(environment.MO_State_From) && current_date < new Date(environment.MO_State_TO)) {
        return true;
      }
      else if (storeState == "DC" && current_date > new Date(environment.DC_State_From) && current_date < new Date(environment.DC_State_TO)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  public calculateClinicFee(shouldValidateVouchersCount: boolean, event?: any) {  
    if(event?.target?.value)  
      this.manualNoClinicFeeChecked = event.target.checked;
    if (this.outReachProgramType === OutReachProgramType.equityevents) {
      this.handleNoClinicVoucherDistribution();
      return;
    }

    else if(this.outReachProgramType === OutReachProgramType.equityevents) { 
      let newClinicImzQtyList = ClinicDetailsUtilities.GetImmunizationGroupedByImmzPk(this.locationForm.get('clinicImzQtyList').value);
      
      for (const imzqyt of newClinicImzQtyList) {
        this.locationForm.controls['vouchersDistributed'].setValue(imzqyt.Immzs[0].estimatedQuantity);
      } 
    
      this.locationForm.controls['clinicDate'].setValidators(null);

      // let clinicDateCtrl = this.locationFormArray.controls[formIndex].get('clinicDate');
      // let startTimeCtrl = this.locationFormArray.controls[formIndex].get('startTime');
      // let endTimeCtrl = this.locationFormArray.controls[formIndex].get('endTime');
       
      this.locationForm.controls['clinicDate'].setValidators(null);
      this.locationForm.controls['clinicDate'].setErrors(null);
      this.locationForm.controls['clinicDate'].updateValueAndValidity();
      this.locationForm.controls['clinicDate'].setValue(null);
  
      this.locationForm.controls['startTime'].setValidators(null);
      this.locationForm.controls['startTime'].setErrors(null);
      this.locationForm.controls['startTime'].updateValueAndValidity();
      this.locationForm.controls['startTime'].setValue(null);
  
      this.locationForm.controls['endTime'].setValidators(null);
      this.locationForm.controls['endTime'].setErrors(null);
      this.locationForm.controls['endTime'].updateValueAndValidity();
      this.locationForm.controls['endTime'].setValue(null);
     
      return;
    }

    // Only when No Clinic - Voucher Distribution Only is checked
    this.onlyVoucherPaymentMethodSelected = ClinicDetailsUtilities.CheckIfOnlyVoucherPaymentMethodIsSelected(this.locationForm.get('clinicImzQtyList').value);
    if (!this.locationForm.value.isNoClinic && this.onlyVoucherPaymentMethodSelected) { 
      const vouchersDistributedCount = ClinicDetailsUtilities.calculateVouchersDistributedCount(this.locationForm);
      this.locationForm.controls['vouchersDistributed'].setValue(0);
    }
    else if(this.locationForm.value.isNoClinic){
      const vouchersDistributedValue: number = ClinicDetailsUtilities.GetAllEstimatedShotsValue(this.locationForm);
      this.locationForm.controls['vouchersDistributed'].setValue(vouchersDistributedValue);
    }

    let aggregatedEstimatedQuantity = 0;
    //key is the immunization name and value is estimated quantity count (number)
    let uniqueImmunizationShotsCount = new Map();
  
    this.locationForm.value?.clinicImzQtyList?.forEach(immunization => { 
      if(!isNaN(parseInt(immunization?.estimatedQuantity)) && (parseInt(immunization?.estimatedQuantity) > 0)){
        uniqueImmunizationShotsCount[immunization.immunizationName] = parseInt(immunization?.estimatedQuantity);
      }
    });
    
    for(const key in uniqueImmunizationShotsCount){
      if (uniqueImmunizationShotsCount.hasOwnProperty(key)) {
        aggregatedEstimatedQuantity += uniqueImmunizationShotsCount[key];
      }
    }

    const vouchersDistributed = parseInt(this.locationForm.controls.vouchersDistributed?.value ?? 0);
    if (shouldValidateVouchersCount && !isNaN(vouchersDistributed)) {
      if ((parseInt(this.locationForm.controls.vouchersDistributed?.value ?? 0)) > aggregatedEstimatedQuantity) {
        let sumMsg = ErrorMessages['resolve'];
        let errMsg = ErrorMessages['vouchersDistributed'];
        this.showDialogV2(sumMsg, errMsg);
        this.locationForm.controls['vouchersDistributed'].setErrors({ 'incorrect': true });
        this.locationForm.controls['vouchersDistributed'].markAsDirty();
        this.locationForm.controls['vouchersDistributed'].markAsTouched();

        return;
      }
      else {
        this.locationForm.controls['vouchersDistributed'].setErrors(null);
        this.locationForm.controls['vouchersDistributed'].updateValueAndValidity();
      }
    }

    let paymentIdsForClinic = new Set<number>();
    this.locationForm.value?.clinicImzQtyList?.forEach(immunization => {
      paymentIdsForClinic.add(immunization.paymentTypeId);
    });


    // if "Voucher: Corporate to Invoice Employer Directly" (id = 13) payment method is NOT SELECTED
    // then consider the calculatedClinicFee as is
    let calculatedClinicFee: number = ClinicDetailsUtilities.calculateClinicFeeForQty(aggregatedEstimatedQuantity);

    // if "Voucher: Corporate to Invoice Employer Directly" (id = 13) payment method is SELECTED along with other methods
    // then subtract the aggregatedEstimatedQuantity from "Vouchers Distributed" value and calculate the fee
    if (paymentIdsForClinic?.size > 1 && paymentIdsForClinic.has(13)) {
      var updatedQuantity = aggregatedEstimatedQuantity - (this.locationForm.controls.vouchersDistributed.value ?? 0);
      calculatedClinicFee = ClinicDetailsUtilities.calculateClinicFeeForQty(updatedQuantity);
    }

    // if only the "Voucher: Corporate to Invoice Employer Directly" (id = 13) payment method is selected
    // then Clinic Fee is 0
    if (paymentIdsForClinic?.size == 1 && paymentIdsForClinic.has(13)) {
      calculatedClinicFee = 0;
    }

    // if "No Clinic - Voucher Distribution Only" checkbox is checked
    // then clinic fee is 0
    if (this.locationForm.controls['isNoClinic'].value ?? false) {
      calculatedClinicFee = 0;
    }

    // if "No Clinic Fee" checkbox is checked
    // then clinic fee should be zero
    if (this.locationForm.controls['isNoClinicFee'].value ?? false) {
      calculatedClinicFee = 0;
    }

    this.locationForm.controls['clinicFee'].setValue(calculatedClinicFee);
    //reset these values
    uniqueImmunizationShotsCount = null;
    calculatedClinicFee = null;
    aggregatedEstimatedQuantity = null;
  }

  private handleConditionForVouchersDistributed() {
    let paymentIds = new Set<number>();
    this.locationForm.value?.clinicImzQtyList?.forEach(immunization => {
      paymentIds.add(immunization.paymentTypeId);
    });

    // if Voucher: Corporate to Invoice Employer Directly payment method
    // is selected then display vouchers distributed
    if (paymentIds.has(13)) {
      this.canDisplayVouchersDistributedField = true;
      this.locationForm.controls['vouchersDistributed'].setValidators([Validators.required]);
    }
  }

  public shouldDisplayFieldAsReadonly(fieldName: string) {
    // Based on the existing condition for each control (previously all the conditions are in the html)
    // this method returns whether to display the field as readonly or not.
    // if location number is less than total clinic count then we will display in readonly mode

    if (
      fieldName.toLocaleLowerCase() === 'contactfirstname'
      || fieldName.toLocaleLowerCase() === 'contactlastname'
      || fieldName.toLocaleLowerCase() === 'contactphone'
      || fieldName.toLocaleLowerCase() === 'contactemail'
    ) {
      if (this.from_local_clinic == true || this.is_reAgreement == true || this.is_reAgreementCommOutreach == true) {
        if (this.locationNumber < this.totalClinicsCount) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    else if (
      fieldName.toLocaleLowerCase() === 'address1'
      || fieldName.toLocaleLowerCase() === 'address2'
      || fieldName.toLocaleLowerCase() === 'city'
      || fieldName.toLocaleLowerCase() === 'zipcode'
    ) {
      if (this.AllowAdminsToEditDataForRestrictedState() == true || this.from_local_clinic == true || this.is_reAgreement == true || this.is_reAgreementCommOutreach == true) {
        if (this.locationNumber < this.totalClinicsCount) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    else if (
      fieldName.toLocaleLowerCase() === 'state'
    ) {
      if (this.AllowAdminsToEditDataForRestrictedState() == true || this.from_local_clinic == true || this.is_reAgreement == true || this.is_reAgreementCommOutreach == true) {
        if (this.locationNumber < this.totalClinicsCount) {
          return true;
        }
        else {
          return null;
        }
      }
      else {
        return null;
      }
    }
    else if (
      fieldName.toLocaleLowerCase() === 'fluexpirydate'
      || fieldName.toLocaleLowerCase() === 'routineexpirydate'
      || fieldName.toLocaleLowerCase() === 'clinicdate'
      || fieldName.toLocaleLowerCase() === 'starttime'
      || fieldName.toLocaleLowerCase() === 'endtime'
    ) {
      if (this.from_local_clinic == true || this.is_reAgreement == true) {
        if(fieldName.toLocaleLowerCase() === 'clinicdate' || fieldName.toLocaleLowerCase() === 'starttime'|| fieldName.toLocaleLowerCase() === 'endtime'){
          if(this.locationForm.controls['isNoClinic'].value == 0)
            return false;
        }
        if (this.locationNumber < this.totalClinicsCount) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
  }

  public shouldDisplayCheckbox() {
    if (this.is_reAgreement && (this.locationNumber >= this.totalClinicsCount)) {
      return true;
    }
    else if (!this.is_reAgreement) {
      return true;
    }
    else {
      return false;
    }
  }

  handleParticipantsChange() {
    let estimatedParticipants = this.locationForm.get('estimatedParticipants').value;
    var isAdmin = this.ProfileDetails.isAdmin; 
    const clinicName = this.locationNumberPipe.transform(this.locationNumber+1);
    const errorMessage = ErrorMessages["estimatedParticipantsWarning"].replace('LOCATION', `LOCATION ${clinicName}`);
    if (estimatedParticipants < 50) {
      if (isAdmin) {
        this.showDialogV3("Please resolve the below issues to continue further", errorMessage);
      } else{
        this.showDialogV3("Please resolve the below issues to continue further", errorMessage);
      }
    };
    if(this.opportunitie.covidOpportunityType === 1){
      if(!this.locationForm.controls['isNoClinic'].value && this.locationForm.get('estimatedParticipants').value > 0 && this.manualNoClinicFeeChecked === null)
        this.handleRsvVaccinceSelection();
    };
    this.isAdmin = isAdmin; 
  }
  
  getServiceFee() {
    if (this.locationForm.controls['isNoClinicFee'].value ||
    this.locationForm.controls['isNoClinic'].value == 1
    ) {
        return environment.NO_SERVICE_FEE; 
    }
   
    const estimatedParticipants = this.locationForm.get('estimatedParticipants').value;
    if (estimatedParticipants >= 1 && estimatedParticipants <= 100) {
        return environment.SERVICE_FEE_1;; 
    } else if (estimatedParticipants >= 101 && estimatedParticipants <= 200) {
        return environment.SERVICE_FEE_2;
    } else if (estimatedParticipants > 200) {
        return environment.SERVICE_FEE_3; 
    } else {
        return environment.NO_SERVICE_FEE; 
    }
  }

  getAllEstimatedShots(){
    const initialValue = 0;
    const totalEstimatedShots = this.locationForm.controls.clinicImzQtyList['controls'].reduce((accumulator, currentValue) => Number(accumulator?.controls?.estimatedQuantity?.value) + Number(currentValue?.controls?.estimatedQuantity?.value));
    return totalEstimatedShots;
  }

  handleRsvVaccinceSelection(){
    const clinicImzList = ClinicDetailsUtilities.GetImmunizationGroupedByImmzPk(this.locationForm.get('clinicImzQtyList').value);
    if(this.checkVaccineNames(clinicImzList)){
      this.locationForm.patchValue({isNoClinicFee: 1});
      this.calculateClinicFee(false);
    } else {
      this.locationForm.patchValue({isNoClinicFee: 0});
      this.calculateClinicFee(false);
    }
  }


  checkVaccineNames(clinicList){
    let isRsv = clinicList.some(imz => Number(imz.immPk) === 249 && imz?.Immzs?.[0]?.immunizationName === "RSV (ABRYSVO, AREXVY)" && imz?.Immzs?.[0]?.estimatedQuantity > 0);
    let isOther = clinicList.some(imz => imz?.Immzs?.[0]?.immunizationName !== "RSV (ABRYSVO, AREXVY)" && imz?.Immzs?.[0]?.estimatedQuantity > 0);
    return !clinicList.every(imz => Number(imz.immPk) === 249 && imz?.Immzs?.[0]?.immunizationName === "RSV (ABRYSVO, AREXVY)") && clinicList.some(imz => Number(imz.immPk) === 249 && imz?.Immzs?.[0]?.immunizationName === "RSV (ABRYSVO, AREXVY)") && isRsv && isOther;
  }
}
