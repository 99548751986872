import { Component, OnInit } from '@angular/core';
import { PreviewService } from '../../services/preview.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-corporate-scheduler-preview',
  templateUrl: './corporate-scheduler-preview.component.html',
  styleUrls: ['./corporate-scheduler-preview.component.css']
})
export class CorporateSchedulerPreviewComponent implements OnInit {
  data: any = [];
  BannerColor: string = '';
  BannerTextColor: string = '';
  ButtonBgColor: string = '';
  ButtonTextColor: string = '';
  LogoFile: string = '';
  MastheadLogo: string = '';
  BodyText: string = '';
  FooterText: string = '';
  HeaderText: string = '';
  image_source: any = "";
  clientLogo:any;
  constructor(private previewService: PreviewService) { }

  ngOnInit() { 
    this.data = (sessionStorage["previewInfo"])?JSON.parse(sessionStorage["previewInfo"]):{};
    this.BannerColor = this.data.BannerColor;
    this.BannerTextColor = this.data.BannerTextColor;
    this.ButtonBgColor = this.data.ButtonBgColor;
    this.ButtonTextColor = this.data.ButtonTextColor;
    this.clientLogo = environment.RESOURCES.CORPORATE_SCHEDULER_UPLOADED_LOGOS_URL + ((!this.data.LogoFileName)?'walgreens_email_logo.png':this.data.LogoFileName);
    this.BodyText = this.decodeHTML(this.data.BodyText);
    this.FooterText = this.decodeHTML(this.data.FooterText).replace("VIS Form.pdf", "visresources");
    this.HeaderText = this.decodeHTML(this.data.HeaderText);
    sessionStorage.removeItem("previewInfo");
  }


  closeWindow() {
    window.close()
  }

  decodeHTML(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };




}
