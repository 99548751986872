import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { EncryptDecrypt } from '../../../models/encryptDecrypt';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


@Injectable()
export class AuthenticationService {

  constructor(private _http: HttpClient, private router: Router) { }

  getLoginUser(encoded_data: string): Observable<any> {
    var url = environment.API_URL + environment.RESOURCES.VERIFY_USER_GET + "/" + encoded_data;
    
    return this._http.get(url).pipe(catchError(Util.handleErrorV2));
}

postEncryptionDecryption(encrypt_decrypt: EncryptDecrypt): Observable<any> {
  var url = environment.API_URL + environment.RESOURCES.POST_ENCRYPTION_DECRYPTION;  
  return this._http.post(url, encrypt_decrypt,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}

getUserProfile(): Observable<any> {  
  var url = environment.API_URL + environment.RESOURCES.GET_USER_PROFILE;  
  return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
}

getSchedulerSiteLink(client_key:string): Observable<any> {
  var url = environment.API_URL + environment.RESOURCES.GET_SCHEDULER_SITE_LINK +"/"+ client_key;  
  return this._http.get(url).pipe(catchError(Util.handleErrorV2));
}

}
