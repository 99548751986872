export class ScheduledClinicStatus{
    storeId:number;
    outreachEffort: number;
    contacted:number;
    fromDate: string;
    toDate: string;
    status:number;
    outputType: string;
    reportName: string;
    constructor(store_id: number, outreach_effort: number, contacted: number, from_date: string, to_date: string, status:number, report_name?: string,output_type?: string) {
        this.storeId = store_id;
        this.outreachEffort = outreach_effort;
        this.contacted = contacted;
        this.fromDate = from_date;
        this.toDate = to_date;
        this.status=status;
        this.outputType = output_type;
        this.reportName = report_name;
    }
}