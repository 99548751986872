import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ContactlogService {

  constructor(private http: HttpClient, private router: Router) { }
  // public GetContactLogs(businessPk,businessType):Observable<any> {
  //   return this.http.get(this.baseUrl + "GetContactLogs?business_pk=" + businessPk + " &business_type="+businessType+"").map((response: Response) => response.json());
  // }

  public GetContactLogs(business_pk,store_id,outreach_effort_code): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.GET_OUTREACH_CONTACT_LOGS + "/" + business_pk + "/"+ store_id+ "/"+outreach_effort_code;
    return this.http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
  public DeleteContactLog(contact_log_pk): Observable<any> {
    let url = environment.API_URL + environment.RESOURCES.DELETE_CONTACT_LOG + "/" + contact_log_pk;
    return this.http.delete(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }
}
