import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PcaContractsService } from '../../services/pca-contracts.service';
import { Util } from '../../../../utility/util';
import { WalgreenCo } from '../../../../models/contract';
import { SessionDetails } from '../../../../utility/session';
import { DatePipe } from '@angular/common';
import { PcaContract } from '../../../../models/pcaclinic';

@Component({
  selector: 'app-pca-pharmacy-info',
  templateUrl: './pca-pharmacy-info.component.html',
  styleUrls: ['./pca-pharmacy-info.component.css'],
  providers: [DatePipe]
})
export class PcaPharmacyInfoComponent implements OnInit {
  witnessForm:FormGroup;
  minDate = new Date();
  witnessInfo1: any;
  preparedDate:any;
  witnessInfo: WalgreenCo;
  profile_data: any;
  @Input('outReachProgramType')
  outReachProgramType: string;
  constructor(private _fb: FormBuilder, private pcaContractService:PcaContractsService, private utility: Util,
    private _datePipe: DatePipe) { 

  }

  ngOnInit() {
    this.witnessInfo1 = this.pcaContractService.fetchPcaContractAgreementData();

    this.witnessInfo = this.witnessInfo1.walgreenCoInfo;
    
    this.profile_data = SessionDetails.getStoreProfileData();
    this.preparedDate = this.witnessInfo !== null && this.witnessInfo.preparedDate !== '' ? new Date(this.witnessInfo.preparedDate) : new Date();
    if(this.witnessInfo == null || this.witnessInfo == undefined){
    this.pcaContractService.getStoreRhdDetails(this.profile_data[0].storeId).subscribe((data:any)=>{      
      this.witnessInfo = new WalgreenCo();     
      this.witnessInfo.rhdName = data.name;
      this.witnessInfo.rhdEmail = data.email;
     this.bindControls();
    },
    err =>{
      console.log(err);
    });
  }
    this.bindControls();
  }
  getRHDEmail()
  {
   return this.witnessForm.controls["rhdEmail"].value;
  }
  bindControls(){
    this.witnessForm = this._fb.group({
      title: this._fb.control(this.witnessInfo != null && this.witnessInfo != undefined &&  this.witnessInfo.title != ""  ? this.witnessInfo.title:"Regional Vice President", [Validators.required]),
      preparedDate: this._fb.control(this.preparedDate, Validators.required),
      rhdName: this._fb.control(this.witnessInfo != null && this.witnessInfo != undefined && this.witnessInfo.rhdName != null && this.witnessInfo.rhdName != "" ?this.witnessInfo.rhdName:"", null),
      rhdEmail: this._fb.control(this.witnessInfo != null && this.witnessInfo != undefined && this.witnessInfo.rhdEmail != null && this.witnessInfo.rhdEmail != "" ? this.witnessInfo.rhdEmail:"", [Validators.required]),
    });
  }
  simpleSave(): boolean {
    this.witnessInfo.preparedDate = this._datePipe.transform(this.preparedDate, "shortDate");
    this.pcaContractService.setWalgreensWitnessData(this.witnessForm.value);
    return true;
  }
  save(): boolean {
    this.witnessInfo.preparedDate = this._datePipe.transform(this.preparedDate, "shortDate");
    if (!this.isFormValid()) {
      this.utility.validateAllFormFields(this.witnessForm);
      return false;
    }

    this.pcaContractService.setWalgreensWitnessData(this.witnessForm.value);
    return true;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  isFormValid() {    
    let validForm: boolean = true;
    if (this.witnessForm.controls['title'].value && this.witnessForm.controls['rhdEmail'].value ) {
      return validForm;
    }
    else {
      return false;
    }
    
  }
  
  isFieldValid(field: string) {
    if (this.witnessForm.get(field) != null) {
      let ret: boolean = !this.witnessForm.get(field).valid && this.witnessForm.get(field).touched;
      if (ret) {
        return ret;
      } else {
        if (field.search('name') !== -1 || field.search('title') !== -1) {
          let validField: boolean = this.witnessForm.controls[field].value!=null? this.witnessForm.controls[field].value.trim().length > 0:false;
          return !validField;
        }
        else if (field.search('districtNumber') !== -1) {
          let validNo: number = +this.witnessForm.controls[field].value;
          if (validNo > 0) {
            return false;
          }
          else {
            return true;
          }
        }
      }
      return false;
    }
}
getFormData(pcaObject:PcaContract){
  pcaObject.walgreenCoInfo = new WalgreenCo();
  let pharmacyInfo = this.witnessForm.value;
  pcaObject.walgreenCoInfo.title = pharmacyInfo.title
  pcaObject.walgreenCoInfo.rhdName = pharmacyInfo.rhdName
  pcaObject.walgreenCoInfo.rhdEmail = pharmacyInfo.rhdEmail
}
}
