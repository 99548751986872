export const ClinicalRequirementsImmunizations: any[] = [
     "HPV (Gardasil 9)",
     "Prevnar 13"
]
export const ClinicalRequirementInstruction:string='If anyone other than the immunizing pharmacist is executing this agreement, please ensure that the immunizing pharmacist is aware of the policy.'

export const ClinicalRequirementsImmunizationsContent :any ={
     "HPV (Gardasil 9)":'Clinical guidelines require patients ages 27-45 to have a <a  class="noHover"  href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/shared_clinical_decision_making.pdf" target="_blank" title="Click to review  the shared clinical decision making">"shared clinical discussion"</a> between the patient and healthcare provider in order to provide the HPV (Gardasil 9) immunization. <br /> <br /> <a  class="noHover" href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/hpv_vaccine_clinical_guide.pdf" target="_blank" title="Click to review the HPV Vaccine Clinical Guide">HPV Vaccine Clinical Information</a>',
     "Prevnar 13":'Clinical guidelines require patients ages 65 and up to have a <a  class="noHover"  href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/shared_clinical_decision_making.pdf" target="_blank" title="Click to review the shared clinical decision making">"shared clinical discussion"</a> between the patient and healthcare provider in order to provide the Prevnar 13 immunization. <br /> <br /><a  class="noHover" href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/pneumococcal_vaccine_clinical_info.pdf" target="_blank" title="Click to review the Prevnar 13 Vaccine Clinical Guide">Prevnar 13 Vaccine Clinical Information</a>'
}
export const ClinicalRequirementsImmunizationsChkBoxContent :any ={
     "HPV (Gardasil 9)":'The immunizing pharmacist agrees to have a "shared clinical discussion" prior to administering HPV (Gardasil 9) with patients within the 27-45 age group.',
     "Prevnar 13":'The immunizing pharmacist agrees to have a "shared clinical discussion" prior to administering Prevnar 13 with patients within the 65 and up age group.'
}
export const ImmunizationsClinicalRequirements: any = {
    "userNote":"If anyone other than the immunizing pharmacist is executing this agreement, please ensure that the immunizing pharmacist is aware of the policy.",
    "imzClinicalrequirementsText":{
         "imzText":'Clinical guidelines require patients ages {0} to have a {2} between the patient and healthcare provider in order to provide the {1} immunization. {3}',
         "chkText":'The immunizing pharmacist agrees to have a "shared clinical discussion" prior to administering {0} with patients within the {1} age group.'
    },
    "links":{
         "HPV (Gardasil 9)":'<a title="Click to review the shared clinical decision making" href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/shared_clinical_decision_making.pdf" target="_blank" class="clLinks">"shared clinical discussion"</a>',
         "HPV":'<a title="Click to review the shared clinical decision making" href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/shared_clinical_decision_making.pdf" target="_blank" class="clLinks">"shared clinical discussion"</a>'
        ,"Prevnar 13":'<a title="Click to review the shared clinical decision making" href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/shared_clinical_decision_making.pdf" target="_blank" class="clLinks">"shared clinical discussion"</a>',
        "Pneumonia":'<a title="Click to review the shared clinical decision making" href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/shared_clinical_decision_making.pdf" target="_blank" class="clLinks">"shared clinical discussion"</a>'
    },
    "additionalLinks":{
        "HPV (Gardasil 9)":'<br /><br /><a title="Click to review the HPV Vaccine Clinical Guide" href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/hpv_vaccine_clinical_guide.pdf" target="_blank" class="clLinks">HPV Vaccine Clinical Information</a>',
        "HPV":'<br /><br /><a title="Click to review the HPV Vaccine Clinical Guide" href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/hpv_vaccine_clinical_guide.pdf" target="_blank" class="clLinks">HPV Vaccine Clinical Information</a>'
        ,"Prevnar 13":'<br /><br /><a title="Click to review the Prevnar 13 Vaccine Clinical Guide" href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/pneumococcal_vaccine_clinical_info.pdf" target="_blank" class="clLinks">Prevnar 13 Vaccine Clinical Information</a>',
        "Pneumonia":'<br /><br /><a title="Click to review the Pneumonia Vaccine Clinical Guide" href="http://snetapp.walgreens.com/prodpublisher/enterprise_immunization/clinical_information/pneumococcal_vaccine_clinical_info.pdf" target="_blank" class="clLinks">Pneumonia Vaccine Clinical Information</a>',
   },
    "immunizations":[
            "HPV (Gardasil 9)","HPV"
            ,"Prevnar 13","Pneumonia"
            ],
    "imzClinicalReqAgeGroups":{
         "HPV (Gardasil 9)":"27-45",
         "HPV":"27-45"
        ,"Prevnar 13":"65 and up" ,
        "Pneumonia":"65 and up"

    }
}