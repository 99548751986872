import { Injectable } from "@angular/core";
import { environment } from '../../../../environments/environment';
import { ContractData } from '../../../models/contract';

@Injectable()
export class ValidationHandler {
    validateLocalAgreement(agreement_json: ContractData) {
        let is_blocked_imz = false, has_minimum_shots = false;
        let shots_count = 0, total_shots_count = 0;
        let failed_clinic_locations = '';
        let validation_msg_list: any[] = [];
        let has_clinic_before_2weeks = false, has_clinic_after_2weeks = false;
        let agreement_messages = new AgreementMessages();

        agreement_json.clinicList.forEach(loc => {
            loc.clinicImzQtyList.forEach(imz => {                
                if (environment.BlockedImmunizations[0].BlockedImmunizations.filter(item => item.toLowerCase() == imz.immunizationName.toLowerCase()).length > 0 && +imz.estimatedQuantity > 0)
                    is_blocked_imz = true;
                shots_count = +imz.estimatedQuantity;
                total_shots_count = total_shots_count + shots_count;
            });
            failed_clinic_locations = failed_clinic_locations + "<br />" + loc.location;
            if (total_shots_count < 25)
                has_minimum_shots = true;
            let current_date = new Date().toString();
           let diffInMs: number = (Date.parse(loc.clinicDate) - Date.parse(current_date));
            let diffInDates : number = Math.ceil(diffInMs/1000/60/60/24);
            if (diffInDates < 14)
                has_clinic_before_2weeks = true;
            else
                has_clinic_after_2weeks = true;
        });

        if (failed_clinic_locations != '' && is_blocked_imz) {
            agreement_messages.errorMessages.push(environment.ErrorMessages.blockoutImmunizationValidationMessage + failed_clinic_locations);
        }
        // if (has_minimum_shots) {
        //     agreement_messages.errorMessages.push(environment.ErrorMessages.minimumShotsRequiredAlert);
        // }
        // if(has_clinic_before_2weeks)
        // {
        //     agreement_messages.errorMessages.push(environment.ErrorMessages.clinicDateReminderBefore2WeeksEN);
        // }
        if(has_clinic_after_2weeks)
        {
            agreement_messages.infoMessages.push(environment.ErrorMessages.clinicDateReminderAfter2WeeksEN);
        }
        return agreement_messages;
    }
}
export class AgreementMessages {
    errorMessages: any[] = [];
    infoMessages: any[] = [];
    warningMessages: any[] = [];
}