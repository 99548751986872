// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-datatable .p-datatable-tbody > tr.p-highlight
{
  color: #333333 !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight
{
    background-color: #fafafb !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/admin/components/patient-reassignment/patient-reassignment.component.css"],"names":[],"mappings":"AAAA;;EAEE,yBAAyB;AAC3B;AACA;;IAEI,oCAAoC;AACxC","sourcesContent":[".p-datatable .p-datatable-tbody > tr.p-highlight\r\n{\r\n  color: #333333 !important;\r\n}\r\n.p-datatable .p-datatable-tbody > tr.p-highlight\r\n{\r\n    background-color: #fafafb !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
