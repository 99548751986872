import { Component, OnInit } from '@angular/core';
import {  FormGroup,   FormBuilder, Validators} from '@angular/forms';
import * as appSettings from '../../globals';
import { RevisedClinicDatesAndVolumesService } from '../../services/revisedclinicdatesandvolumes.service';
import { saveAs as importedSaveAs } from "file-saver";
import { ApplicationSettings } from '../../../common/application-settings';
import { ErrorMessages } from "../../../../config-files/error-messages";
import { NgxSpinnerService } from "ngx-spinner";
//import { NGXLogger } from 'ngx-logger';
import { VaccinePurchase } from '../../../../models/VaccinePurchase';

@Component({
  selector: 'app-revisedclinicdates',
  templateUrl: './revisedclinicdates.component.html',
  styleUrls: ['./revisedclinicdates.component.css']
})
export class RevisedclinicdatesComponent implements OnInit {
  RevisedClinicDatesReportForm: FormGroup;
  RevisedClinicDatesreportArray: any;
  //private datasource: any;
  ddlSeason: number;
  groupIdLaunchDate="05/01/2018";
  private currentSeason = appSettings.ipSeason;
  outreachStartDate=ApplicationSettings.outreachStartDateIP;
  outreachStartYear=ApplicationSettings.getOutreachReportStartYear();
  ddlFormat:any;
  loading:boolean;
  private isCurrentSeason:any=1;
  seasons: any=[];
  totalRecords:any=[];
  canExport:boolean=true;
  showDlg: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";
  minDate:Date;
  maxDate:Date;
  fromDate: Date;
  toDate: Date;
  private from_date: string;
  private to_date: string;
  showGrid:boolean= false;
  covidOnly:boolean = false;
  constructor(
    private fb: FormBuilder,
   
 private revisedClinicDatesAndVolumes:RevisedClinicDatesAndVolumesService,
private spinner: NgxSpinnerService) {
    
  };

  ngOnInit() {
	// for (let report_year:number = (new Date(this.outreachStartDate)).getFullYear(); report_year >=(new Date(this.groupIdLaunchDate)).getFullYear(); report_year--)
  //   {
  //        this.seasons.push({
  //         "yearName": report_year.toString()+"-"+(report_year+1).toString(),
  //         "yearCode": report_year.toString()
  //       });
  //   }
  //   this.ddlSeason = this.currentSeason;
  //   this.ddlFormat="0";
  //   this.RevisedClinicDatesReportForm = this.fb.group({
  //     'ddlSeason': [null],
  //     'ddlFormat':[null]
  //   });
  for (let report_year:number = (new Date(this.outreachStartDate)).getFullYear(); report_year >=(new Date(this.outreachStartDate)).getFullYear(); report_year--)
  {
      if(report_year<=2015){
        continue;
      }
       this.seasons.push({
        "yearName": report_year.toString()+"-"+(report_year+1).toString(),
        "yearCode": report_year.toString()
      });
  }
  this.ddlSeason = (new Date(this.outreachStartDate)).getFullYear();
  this.fromDate =this.minDate;
  this.toDate = this.maxDate;
  this.ddlFormat="xlsxv";
  this.setDates();
  this.setFormControls();
  }
  setFormControls() {
    this.RevisedClinicDatesReportForm = this.fb.group({
      'fDate': [this.fromDate, [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      'tDate': [this.toDate, [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
      'ddlSeason': [this.ddlSeason,null],
      'ddlFormat':[this.ddlFormat,null]
    });
    
     //this.generateReport();
   }
  
  setDates(){
    let tempMinDate:Date;
    let tempMaxDate:Date;
    //this.ddlSeason = this.RevisedClinicDatesReportForm.controls['ddlSeason'].value;    
    tempMinDate=new Date(ApplicationSettings.getIMmunizationStartDate(this.ddlSeason));
    tempMaxDate=new Date(ApplicationSettings.getImmunizationEndDate(this.ddlSeason));
    this.minDate=new Date(tempMinDate);
    this.maxDate=new Date(tempMaxDate)
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    
    this.canExport=false;
  }
  setDates1(){
    let tempMinDate:Date;
    let tempMaxDate:Date;
    this.ddlSeason = this.RevisedClinicDatesReportForm.controls['ddlSeason'].value;    
    tempMinDate=new Date(ApplicationSettings.getIMmunizationStartDate(this.ddlSeason));
    tempMaxDate=new Date(ApplicationSettings.getImmunizationEndDate(this.ddlSeason));
    this.minDate=new Date(tempMinDate);
    this.maxDate=new Date(tempMaxDate)
    this.fromDate = tempMinDate;
    this.toDate = tempMaxDate;
    
    this.canExport=false;
  }
  setCalendarDates(){
    this.fromDate = this.RevisedClinicDatesReportForm.controls['fDate'].value;
    this.toDate = this.RevisedClinicDatesReportForm.controls['tDate'].value;
    let d1 = this.fromDate;
    let d2 = this.toDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    this.canExport=false;
  }
  reset(): void {
    this.showGrid = false;
    this.loading = false;
    this.ddlSeason = this.currentSeason;
    this.setDates();
    this.isCurrentSeason=1;
    this.ddlFormat=0;
    this.setFormControls();
    //this.generateReport();
  }
  generateReport(): void {  
      
    if(!this.showGrid){
      this.spinner.show();
    }else{
      this.loading = true;
    }
    let data=this.RevisedClinicDatesReportForm.value;
    let d1 = data.fDate;
    let d2 = data.tDate;
    this.from_date = `${d1.getMonth() + 1}/${d1.getDate()}/${d1.getFullYear()}`;
    this.to_date = `${d2.getMonth() + 1}/${d2.getDate()}/${d2.getFullYear()}`;
    //this.logger.log(this.from_date);
    //this.logger.log(this.to_date);
     
   
    //this.isCurrentSeason=((this.ddlSeason) != (new Date(this.outreachStartDate).getFullYear()) ? 0 : 1);
    this.ddlSeason = this.RevisedClinicDatesReportForm.controls['ddlSeason'].value;
    if (this.ddlSeason != this.currentSeason) {
      this.isCurrentSeason = 0;
    } 
    
    let vaccine_purchase = new VaccinePurchase(this.from_date,this.to_date,this.isCurrentSeason,"", this.covidOnly,"");
    this.RevisedClinicDatesreportArray = [];
    this.totalRecords = 0;
    this.revisedClinicDatesAndVolumes.getRevisedClinicDatesAndVolumeReportData(vaccine_purchase).subscribe((resultArray: any) => {
      this.totalRecords = resultArray.length;
      this.RevisedClinicDatesreportArray = resultArray;
      //this.logger.log("totalRecords:" + this.totalRecords);    
      setTimeout(() => {
        this.loading = false;
        this.canExport=true;
        this.showGrid = true;
        this.spinner.hide();
      }, 1000)
    },error =>{
      this.spinner.hide();
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
      this.RevisedClinicDatesreportArray = [];
      this.totalRecords = 0;
    });
  }
  // reset(): void {
  //   this.loading = true;
  //   this.ddlSeason = this.currentSeason;
  //   this.isCurrentSeason=1;
  //   this.ddlFormat=0;
  //   this.generateReport();
  // }
  canExportOnchange(){
    this.canExport=false;
  }
  validateExportType(){
    this.ddlFormat = this.RevisedClinicDatesReportForm.controls['ddlFormat'].value;
    if(this.ddlFormat=="0" || !this.canExport)
    return true;
    else
     return false;
  }
  getOutreachStartDate() {
    //let outreach_start_date = new Date();
    // Need to select the outreachstartdate based on the program selected
    return /*outreach_start_date =*/ new Date(appSettings["outreachStartDate" + appSettings.outreachProgramSelected].Length == 0 ? new Date() : appSettings["outreachStartDate" + appSettings.outreachProgramSelected]);
  }

  generateReportFormat(): void {
    
    this.loading=true;
    this.ddlFormat = this.RevisedClinicDatesReportForm.controls['ddlFormat'].value;
    let data=this.RevisedClinicDatesReportForm.value;
    let vaccine_purchase = new VaccinePurchase(this.from_date,this.to_date,this.isCurrentSeason,"",false,"RevisedClinicsAndVolumes",this.ddlFormat);
    this.revisedClinicDatesAndVolumes.exportReportData(vaccine_purchase).subscribe((res:any) => {
      importedSaveAs(res.body, res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1]);
      this.loading=false;
    },error =>{
      this.showDialog("Error", ErrorMessages["error"], true);
      this.loading = false;
    });
  }
    //Shows the message dialog.
    showDialog(msg_header: string, msg_body: string, show_dialog: boolean) {
      this.dialogHeader = msg_header;
      this.dialogBody = msg_body;
      this.showDlg = show_dialog;
    }
}
