import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-trainingvideos',
  templateUrl: './trainingvideos.component.html',
  styleUrls: ['./trainingvideos.component.css']
})
export class TrainingvideosComponent implements OnInit {
  domain: any;
  protocol: any;
  port: any; 
  constructor(private router: Router, @Inject(DOCUMENT) private document: any) {
    
  }
 
  ngOnInit() {
    this.domain = this.document.location.hostname;
    this.protocol = this.document.location.protocol;
    this.port = this.document.location.port;
  }

  passVideoPath(event: string) {
    sessionStorage["videoSrcPath"] = event;
    let path = this.protocol + "//" + this.domain + ((this.port) ? ":" + this.port : "");
    console.log(path);
    window.open(path + '/videoplayer', '_blank');
  }
}
