export class VaccinePurchase {
    fromDate:string;
    toDate:string;
    isCurrentSeason:number;
    reportName:string = null;
    outputType:string = null;
    vaccineType:string = null;
    covidOnly: boolean;
    constructor(from_date:string,to_date:string,is_current_season:number,vaccine_type:string,covid_only:boolean,report_name?: string,output_type?: string) {
        this.fromDate = from_date;
        this.toDate = to_date;
        this.isCurrentSeason = is_current_season;
        this.outputType = output_type;
        this.reportName = report_name;
        this.vaccineType = vaccine_type;
        this.covidOnly = covid_only;
    }
}