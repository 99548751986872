export class ThankyouData
{
    //hasPrefilledForms : number;
    hasPrefilledForms : boolean;
    isCovidClinic:boolean = false;
    storeState:string;
    pdfVarFormEn: string;
    pdfVarFormEsp:string;
    voucherNeeded:boolean;
    isRestrictedState:boolean;
    corporateInvoice:boolean;
    cashPaid:boolean;
    submitInsurance:boolean;
    medicalInsurance:boolean;
    pharmacyInsurance:boolean;
    printContents:string;
    isCovidOpportunity: boolean;
    covidOpportunityType: number;
}