import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'locationNumber'
})
export class LocationNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
        let clinic_location = value-1;
        if (clinic_location >= 26)
        return (String.fromCharCode((65 + (clinic_location % 26 == 0 ? Math.ceil(clinic_location / 26) - 1 : Math.ceil(clinic_location / 26) - 2))) + "" + String.fromCharCode(65 + (clinic_location % 26)));
        else
        return (String.fromCharCode(65 + clinic_location % 26));
  }

}
