import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ImmunizationCheckService {
	constructor(private _http: HttpClient) {

	}

	getAllImmunizationChecks() {
		let url = environment.API_URL + environment.RESOURCES.GET_IMMUNIZATION_CHECKS;
		return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}

	getImmunizationCheckByPk(pk) {
		let url = environment.API_URL + environment.RESOURCES.GET_IMMUNIZATION_CHECKBYPK + "/" + pk;
		return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}

	updateImmunizationCheck(immunizationCheck) {
		let url = environment.API_URL + environment.RESOURCES.UPDATE_IMMUNIZATION_CHECK;
		return this._http.post(url, immunizationCheck, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}

	createImmunizationCheck(immunizationCheck) {
		let url = environment.API_URL + environment.RESOURCES.CREATE_IMMUNIZATION_CHECK;
		return this._http.post(url, immunizationCheck, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}

	getAllClinicTypes(){
		let url=environment.API_URL+environment.RESOURCES.GET_ALL_CLINIC_TYPES;
		return this._http.get(url, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
	}

}
