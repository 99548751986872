import { Component, OnInit, ViewChild } from '@angular/core';
import { CorporateuploaderService } from '../../services/corporateuploader.service';
import {  
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { Util } from '../../../../utility/util';
import { SessionDetails } from '../../../../utility/session';
import { NgxSpinnerService } from "ngx-spinner";
import { Table } from 'primeng/table';
@Component({
  selector: 'app-corporateuploader',
  templateUrl: './corporateuploader.component.html',
  styleUrls: ['./corporateuploader.component.css']
})
export class CorporateuploaderComponent implements OnInit {

  fileExt: string[] = ["XLSX", "XLS", "XLSM"];

  corporateUploaderForm: FormGroup;
  viewSteps: boolean = true;
  uploadedFileName:string='';
  totalSteps: number = 3;
  currentStep: number;
  corporateUploadData: any;
  uploadData: boolean = false;
  invalidFile: boolean = false;
  userProfile: any;
  isError: boolean = false;
  dialogMessage: string = '';
  files:any;
  fileName:string='';
  totalInsert:any;
  totalFailed:any;  
  private _dataTable: any;
  private rowCount: Number;
  private pageNoSize: any;
  exportfilename:string="clinicUploadReport";
  cols: any[];
  @ViewChild('dataTable', { static: false }) dataTable: Table;
  constructor(private corporateuploaderService: CorporateuploaderService, private formBuilder: FormBuilder, private utility: Util,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.userProfile = SessionDetails.GetUserProfile();
    this.cols = [
      {field:"issueType",header:"Remarks"},
      {field:"clientName",header:"Client Name"},
      {field:"localClientContactFirstName",header:"Local Client Contact First Name"},
      {field:"localClientContactLastName",header:"Local Client Contact Last Name"},
      {field:"localClientContactPhone",header:"Local Client Contact Phone #"},
      {field:"localClientContactEmail",header:"Local Client Contact Email"},
      {field:"industry",header:"Industry"},
      {field:"clinicLocationName",header:"Clinic  Name"},
      {field:"clinicStreetAddress1",header:"Clinic Street Address 1"},
      {field:"clinicStreetAddress2",header:"Clinic Street Address 2"},
      {field:"room",header:"Room"},
      {field:"city",header:"City"},
      {field:"state",header:"State"},
      {field:"zip",header:"Zip"},
      {field:"clinicDate",header:"Clinic Date"},
      {field:"clinicStartTime",header:"Clinic Start Time"},
      {field:"clinicEndTime",header:"Clinic End Time"},
      {field:"totalEmployeeCount",header:"Total Employee Count"},
      {field:"coverageType",header:"Coverage Type (Employee Only/ Employee+Spouse/ Employee+Spouse+Family)"},
      {field:"vaccinesCovered",header:"Vaccines Covered"},
      {field:"intakeID",header:"Intake ID"},
      {field:"estimatedVolumePerProduct",header:"Estimated Volume Per Product"},      
      {field:"additionalCommentsInstructions",header:"Additional Comments/ Instructions"},
      {field:"specialBillingInstructions",header:"Special Billing Instructions"},
      {field:"worksiteSchedulerNeeded",header:"CURA Patient Appointment Scheduler"},
      {field:"paymentMethod",header:"Payment Method"},
      {field:"planId",header:"Plan ID"},
      {field:"groupId",header:"Group ID"},
      {field:"idRecipient",header:"ID Recipient"},
      {field:"byPasstoHostingStoreId",header:"By Pass to Hosting Store #"},
       {field:"clientReportingEmail",header:"Client Reporting Email"},
      {field:"b2BTeamEmail",header:"B2B Team Email"},
      // {field:"targetPopulation",header:"Target Population"},
      // {field:"communityPartner",header:"Community Partner"},
      // {field:"communityPartnerName",header:"Partner Name"},
      // {field:"healthDeptInvolved",header:"State or Local Health Dept Involved"},
      // {field:"opportunityHostLocation",header:"In-Store or Off-Site?"},
      // {field:"vaccineInventorySource",header:"Vaccine Inventory Source"},
      {field:"patientGroupId",header:"Patient Group"},
      // {field:"serviceFeeDollarAmount",header:"Service Fee Dollar Amount"},
      {field:"salesforceAccountID",header:"Salesforce Opportunity ID"},
      {field:"salesforceAccountName",header:"Salesforce Account Name"}
  ];
    this.corporateUploaderForm = this.formBuilder.group({
      clinicType: ['', Validators.required],
      inputFile: [null, Validators.required],

    });

  }

  onFileChange(event) {   
      this.files= event.target.files;
     // this.uploadData = false;
     this.uploadedFileName=event.target.files[0].name;
   
  }
  onSubmit()
  {
    this.corporateUploaderForm.controls['clinicType'].setValidators([Validators.required]);
    this.corporateUploaderForm.controls['clinicType'].updateValueAndValidity();
    this.corporateUploaderForm.controls['inputFile'].setValidators([Validators.required]);
    this.corporateUploaderForm.controls['inputFile'].updateValueAndValidity();
    if (this.corporateUploaderForm.valid) {     
      this.uploadData = false;
      this.saveFiles(this.files);
    }
    else {
      this.utility.validateAllFormFields(this.corporateUploaderForm);
      return;
    }
  }
  saveFiles(files) {
    this.corporateUploadData = [];   
    if (!this.isValidFiles(files)) {
      this.invalidFile = true;   
      return;
    }

    if (files.length > 0) {
      let formData: FormData = new FormData();
      for (var j = 0; j < files.length; j++) {
        formData.append("file", files[j]);
      }
      this.spinner.show();
      this.corporateuploaderService.upload(formData, this.corporateUploaderForm.value.clinicType, this.userProfile.userPk, this.userProfile.email.replace(/[.]/g, '^')).subscribe((data:any) => {
        this.spinner.hide();
        this.uploadedFileName='';
        this.corporateUploaderForm.controls['clinicType'].setValue('');
        this.corporateUploaderForm.controls['inputFile'].setValue(null);
        this.corporateUploaderForm.controls['clinicType'].setValidators(null);
        this.corporateUploaderForm.controls['clinicType'].updateValueAndValidity();
        this.corporateUploaderForm.controls['inputFile'].setValidators(null);
        this.corporateUploaderForm.controls['inputFile'].updateValueAndValidity();
        // this.uploadStatus.emit(true);
        if (data.corporateClinicList.length === 0 && data.errorS !== null) {
          this.isError = true;
          this.dialogMessage = data.errorS.errorMessage;
        }
        else if (data.corporateClinicList.length > 0) {
          this.corporateUploadData = data.corporateClinicList;
           this.corporateUploadData.forEach(element => {
            let paymentTypeIds = [];
            if (element.paymentMethod.indexOf('7') >= 0) {
              paymentTypeIds.push("Cash");
            }
            if (element.paymentMethod.indexOf('11') >= 0) {
              paymentTypeIds.push("Insurance");
            }
            if (element.paymentMethod.indexOf('6') >= 0) {
              paymentTypeIds.push("Off-Site: Corporate to Invoice Employer Directly");
            }
            element.paymentMethod = paymentTypeIds.join(",");            
           });
          
          
          let total_insert=this.corporateUploadData.filter(item => item.isValidRecord)
          let total_failed=this.corporateUploadData.filter(item => !item.isValidRecord)
          this.totalInsert=total_insert.length;
          this.totalFailed=total_failed.length
          if (this.corporateUploadData.length > 0) {
            this.currentStep = 3;
            this.uploadData = true;
            this.fileName= this.files[0].name;
          }
          this.corporateUploaderForm = this.formBuilder.group({
            clinicType: ['', Validators.required],
            inputFile: [null, Validators.required],

          });
        }
      },
      error => {
        if (error.indexOf("417") > -1) {
          this.spinner.hide();
          this.isError = true;
          this.uploadedFileName='';
          this.corporateUploaderForm.controls['inputFile'].setValue(null);
          this.corporateUploaderForm.controls['clinicType'].setValue('');
          this.corporateUploaderForm.controls['clinicType'].setValidators(null);
          this.corporateUploaderForm.controls['clinicType'].updateValueAndValidity();
          this.corporateUploaderForm.controls['inputFile'].setValidators(null);
          this.corporateUploaderForm.controls['inputFile'].updateValueAndValidity();
          this.dialogMessage = "HTTP/1.1 417 Expectation Failed";
        }
      }
      )
    }
  }
  private isValidFiles(files) {
    return this.isValidFileExtension(files);
  }

  private isValidFileExtension(files) {
    for (var i = 0; i < files.length; i++) {
      // Get file extension
      var ext = files[i].name.toLocaleUpperCase().split('.').pop() || files[i].name;

      // Check the extension exists
      var exists = this.fileExt.filter(element => element == ext);

      if (exists.length <= 0) {
        return false;
      }
      // return false;
    }
    return true;
  }

  isFieldValid(field: string) {
    return !this.corporateUploaderForm.get(field).valid && this.corporateUploaderForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  Continue() {

  }

  exportCSV(dataTable) {
    this.rowCount = 50;
    this.pageNoSize = 'page=0&size=' + this.rowCount;
    this.dataTable = dataTable;
    dataTable.exportCSV();
}
      
  Ok() {
    this.invalidFile = false;
  }
  OkError() {
    this.isError = false;
  }
}
