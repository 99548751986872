import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {ComponentCanDeactivate} from './component-can-deactivate';
import { MessageServiceService } from '../../modules/store/services/message-service.service';

export interface CanDeactivate<T> {
    canDeactivate(component: T, route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean>|Promise<boolean>|boolean;
  }

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private message_service: MessageServiceService){}
  //canDeactivate(component: ComponentCanDeactivate): boolean {
    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if(!component.canDeactivate()){
        this.message_service.setCanShowRouteChangeConfirmation(true);
        return this.message_service.getCanRouteChange();
    }
    return true;
  }
}
