export const UniversalTandCData2023: any =
{
   "english":"<p class=black-font>This<b> <i>IMMUNIZATION SERVICE AGREEMENT</i></b>  &nbsp;(&quot;<b>Agreement</b>&quot;) by and between the party indicated below (&quot;<b>Client</b>&quot;), and Walgreen Co., on behalf of itself and its subsidiaries and affiliates (&quot;<b>Walgreens</b>&quot;)</b> is made and entered into on the date last signed by an authorized representative of both the Client and Walgreens (the &quot;<b>Effective Date</b>&quot;). Walgreens and Client may be individually referred to as a &quot;<b>Party</b>&quot;</b> or collectively as the &quot;<b>Parties.</b>&quot;<br/></p>",
    "englishheader":"<p class=black-font>For good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, Client and Walgreens, hereby agree that Walgreens will dispense and administer the immunizations as indicated in Attachment A (&quot;<b>Immunizations</b>&quot;) according to the terms and conditions contained in Attachment B, to a participant population agreed to by the Parties (&quot;<b>Participants</b>&quot;) at mutually agreed upon location(s). Attachments A and B, attached hereto and incorporated herein by reference, are made part of and subject to the Agreement and in no event shall any other attachments, alterations or revisions be applicable unless without the expressed written consent of Walgreens.</p>",
   "englishattachment":"<h3 class='text-center mb-5 ' style='font-size:18px !important;color:#212529 !important;'>ATTACHMENT A</h3><h3 class='text-center mb-5 ' style='font-size:18px !important;color:#212529 !important;'>ARTICLE I</h3><p class=\"black-font\">1.1 &nbsp;In accordance with the Agreement, for each Immunization administered by Walgreens under this Agreement, Walgreens will be entitled to the applicable reimbursement rates set forth in Table 1, plus any government imposed taxes, on such products or services, however styled, using the payment billing method agreed to by the Parties indicated below.  In the event that the U.S. Food and Drug Administration (&quot;FDA&quot;) approves an Immunization or issues an Emergency Use Authorization for an Immunization after the Effective Date and such Immunization is (i) available for purchase by Walgreens, or (ii) is made available to Walgreens to administer but not purchase, Walgreens has the right to notify Client of a new reimbursement rate or fee, or both, for such Immunization, which will be effective ten (10) days following Client’s receipt of the notice.  In the event Client notifies Walgreens in writing that it objects to the new reimbursement rate or fee, or both, within the ten (10) day period, Walgreens may elect, in its sole discretion, to not administer the Immunization to Participants.  Client acknowledges that the fees and reimbursement rates set forth in the Agreement are Walgreens’ Confidential Information and Client agrees not to disclose this information to any third-party other than as minimally necessary under the terms of this Agreement.  Unless otherwise indicated below, the rates listed in Table 1 are inclusive of the cost of vaccine, dispensing fee, and administration fee.</p>",
   "imzen":"Immunization",
   "ImzVcnNameEn": "Immunization (Vaccine) Name",
   "PaymtBillMethodEn":"Payment Billing Method",
   "VoucherRatesEn":"Voucher Rates",
   "OffSiteClinicRatesEn" : "Off-Site Clinic Rates",
   "articleIIEN":"<h3 class='text-center mb-5 ' style='font-size:18px !important;color:#212529 !important;'>ARTICLE II<br/>CLINIC<br/>FEES</h3><p class=\"black-font\">2.1 &nbsp;<u>Service Fee.</u> In order to support a Clinic location, including allocating appropriate supplies and staffing arrangements at each Clinic, Client agrees to pay Walgreens the applicable service fee(s) set forth in Table 2 (&quot;<b>Service Fee</b>&quot;) based on the estimated number of Immunizations for each separate Clinic location and event date. Client agrees to provide Walgreens’ designated contact with a written estimate of the number of Immunizations per Clinic location and date at least two weeks prior to the scheduled date of the first Clinic.  Based on the written estimated provided by Client, Walgreens will invoice Client the applicable Service Fee for each completed Clinic, and Client shall pay Walgreens in full within thirty (30) days of receipt of the invoice. The Parties agree that Service Fee(s) will be billed directly to Client and neither Party will seek to recover Service Fee(s) from any Third Party Payors. For illustration purposes under this Section only, one (1) Participant receiving two (2) different Immunizations at the same Clinic location on the same day would count as two (2) Immunizations towards the total number of estimated number of Immunizations for that Clinic location and event date.</p>",
   "articleNoFeeIIEN":"<h3 class='text-center mb-5 ' style='font-size:18px !important;color:#212529 !important;'>ARTICLE II<br/>CLINIC<br/>FEES</h3><p class=\"black-font\">2.1 &nbsp;Service Fees are not applicable for this Agreement.<br/><br/><br/>",
   "payen":"Payment",
   "ratesen":"Rate",
   "clienten":"Client:",
   "nameen":"Name:",
   "eSignatureEN": "Signature:",
   "titleen":"Title:",
   "attentionen": "Attention",
   "address1en":"Address1",
   "address2en":"Address2",
   "cityen":"City",
   "stateen":"State",
   "zipcodeen":"Zip",
   "legalen":"Legal Notice Address",
   "clientinfoen":"Client Information:",
   "inWitnessWhereOffEN":"IN WITNESS WHEREOF,",
   "inWitnessWhereOff2ndLineEN":"Client and Walgreens have executed this Agreement.",
   "sendLegalNoticeToClientEN":"Legal Notice Address:",
   "sendLegalNoticeToWalgreensEN":"Legal Notice Address:",
   "wallGreensCommOffsiteTitle1EN":"ATTACHMENT B",
   "wallGreensCommOffsiteTitle2EN":"TERMS AND CONDITIONS",
   "wallGreensResponsibilitiesEN":" WALGREENS' RESPONSIBILITIES",// deliberately extra space
   "wallGreensResponsibilitiesPara1HeadingEN":"Immunizations",
   "wallGreensResponsibilitiesCvrdServicesEN":" Subject to limitations or restrictions imposed by federal and state contracts, laws, and regulations, and the availability of appropriate Immunizations, Walgreens will administer Immunizations to Participants either directly or through an authorized provider.  The Parties agree to comply with all procedures set forth in this Agreement.  When required by state law, Walgreens will require Participants to provide a valid prescription from their physician or allow the health care professional to contact their physician to obtain a valid prescription; however, for certain specific Immunizations, Walgreens may be responsible for obtaining standing orders from physicians.  Participants will be required to complete a Walgreens vaccine administration record and consent form before receiving an Immunization.",
    "profJudgementHeadingEN":"Professional Judgment",
    "profJudgementTextEN":" Walgreens may withhold administration of Immunizations to a Participant for good cause, including but not limited to, Client’s or Participant’s (where applicable) failure to pay for Immunization, requests by Participant for services inconsistent with applicable legal requirements; or where, in the professional judgment of the health care professional, the services should not be rendered.",
    //"provHealthCrProfHdngEN":"Provision of Healthcare Professional",
    //"provHealthCrProfTextEN":" If the Parties agree in writing that Walgreens will administer Immunizations at locations outside of Walgreens' store locations (&quot;<b>Clinics</b>&quot;), Walgreens will provide Client with the appropriate number of qualified health care professionals and technicians to provide such immunizations at the Off-Site Clinic locations based upon and in reliance on Client's good-faith estimates of Participant volume. Any requests for additional personnel will be subject to mutual agreement by the Parties and may require additional agreed-upon fees to be paid by Client to Walgreens in accordance with this Agreement.",
    "clientsResponsibilitesEN":"CLIENT'S RESPONSIBILITIES",
    "vouchersHeadingEN":"Vouchers",
    "vouchersTextEN":" If the Parties agree in writing that Walgreens will administer Immunizations upon receipt of a Walgreens approved voucher issued to Participants by Client (&quot;<b>Vouchers</b>&quot;), Client will provide each Participant with a Voucher, and the Participant may redeem the Voucher at a participating Walgreens retail store location.  Once the Voucher is approved by both Parties it may not be modified. Client may not rescind, retract, reduce or deny payment owed to Walgreens for claims where Immunizations were provided to its Participants, even if Client no longer considers the individual presenting the Voucher to be a Participant.",
    "offSiteLocationEN":"Clinic Locations.",
    "importantEN":"IMPORTANT",
    "offSiteLocationText1EN":" If the Parties agree in writing that Walgreens will administer Immunizations at agreed upon locations outside of Walgreens’ retail store locations (&quot;<b>Clinics</b>&quot;), Walgreens will provide Client with the appropriate number of qualified health care professionals and/or technicians to provide Immunizations at the Clinics based upon and in reliance on Client’s good-faith estimates of Immunization volume subject to the Service Fee set forth in Attachment A. Furthermore, Client will communicate to Participants the dates, times and locations for such Clinics.  At all Clinics, Client will provide a private, clean room location, tables and chairs for Walgreens’ personnel and Participants. Where applicable, Client agrees to assist Walgreens in the collection of Participants’ Third Party Payors (defined below) eligibility information and any additional reasonably requested information, in order to help expedite the delivery of Immunizations.",
    //"eligibleParticipantsEN":"Eligible Participants.",
    //"eligibleParticipantsTextEN":" The Parties acknowledge specific populations may have been designated by the applicable Federal, State, or local authorities (&quot;<b>Jurisdiction</b>&quot;) to be eligible for certain Immunizations.  Client represents Participants receiving Immunizations under this Agreement are eligible to receive Immunizations based on the applicable Jurisdiction's requirements and at no time will Client allow a Participant to receive Immunizations under this Agreement until eligible under the applicable Jurisdiction's criteria. The Parties agree that when providing Immunizations, Walgreens will act in good faith reliance as it relates to Client's determination of Participants' eligibility in each Jurisdiction.",
    "pmtAndBIllingEN":"PAYMENT AND BILLING",
    "paymentEN":"Payment.",
    "paymentTextEN":"3.1 <u>Payment</u>. Walgreens will invoice Client for Immunizations administered to Participants (including any applicable fees as listed in Attachment A) and Client will pay all invoices in full within thirty (30) days from receipt. Payments will be made to the remittance address set forth on the invoice.  However, in the event the Parties agree in writing, solely for those amounts listed in Article I of Attachment A,  and  (i) Participant provides evidence of coverage under third-party insurance or a government funded program (e.g., Medicare) (&quot;<b>Third Party Payor</b>&quot;) prior to the provision of Immunizations; and (ii) Walgreens is contracted with that Third Party Payor, Walgreens, will, except as otherwise stated herein, submit the claim for Immunizations to the Participant’s Third Party Payor, and any copayment, coinsurance, deductible owed by the Participant will be collected at the time of service or billed at a later date. If evidence of coverage by a Third Party Payor is not provided by a Participant at the time of service or a claim for reimbursement is denied by a Third Party Payor, Client will reimburse Walgreens for all Immunizations administered to the Participant.",
    "latePaymentEN":"Late Payment.",
    "latePaymentTextEN":" All sums owed by Client to Walgreens will bear interest of 1.5% per month from the date payment is due until paid; however, in no event will such interest rate be greater than the rate permitted by law. Client shall be solely responsible for any and all costs incurred by Walgreens in seeking collection of any delinquent amounts owed by Client. Walgreens may invoice Client for interest and costs due under this Section on a monthly basis and payment will be due within thirty (30) days from receipt.",
    "costIncreaseEN":"Cost Increase.",
    "costIncreasetextEN":" If the Wholesale Acquisition Cost (&quot;<b>WAC</b>&quot;) or Average Wholesale Price (&quot;<b>AWP</b>&quot;) for any Immunization increased by 2.0% or greater during the term of this Agreement, Walgreens has the right to increase the contracted reimbursement rate of the Immunization by the same proportion as the WAC or AWP increase.  The new rate will be effective ten (10) days following Client’s receipt of the rates unless Client notifies Walgreens that it does not agree to the adjusted rates within the ten (10) day period, in which case Walgreens may discontinue providing services for those Immunizations.",
    "termAndTermCapsEN":"TERM AND TERMINATION",
    "termAndTermEN":"Term and Termination",
    "termAndTermTextEN":" This Agreement will become effective on the Effective Date and shall continue in full force and effect for an initial term of one (1) year. Upon expiration of the initial term, this Agreement will automatically renew for successive one (1) year terms at the then current Walgreens reimbursement rates in effect for Immunizations, which will be made available upon request.  Either Party may terminate this Agreement at any time without cause by giving at least thirty (30) days’ prior written notice to the other Party.",
    "effectAndTerminiationEN":"Effect of Termination",
    "effectAndTerminiationTextEN":" Termination will have no effect upon the rights or obligations of the Parties arising out of any transactions occurring prior to the effective date of such termination.",
    "waiverEN":"Waiver",
    "waiverTextEN":" The waiver by either Party of a breach or violation of any provision of this Agreement shall not operate as or be construed to be a waiver of any subsequent breach or violation thereof.",
    "insurAndIndemnifyEN":"INSURANCE AND INDEMNIFICATION ",
    "insuranceEN":"Insurance",
    "insuranceTextEN":" Each Party will self-insure or maintain at its sole expense, and in amounts consistent with industry standards, insurance for general and professional liability and such other insurance as may be necessary to insure the Party, its employees, and agents against any claim or claims for damages arising out of or in connection with its duties and obligations under this Agreement. Upon request from Walgreens, Client will provide a memorandum of insurance or certificate of insurance. Walgreens' insurance information is available at www.walgreens.com/insurance.",
    "indemnificationEN":"Indemnification",
    "indemnificationTextEN":" To the extent permitted by law, each Party will indemnify, defend, and hold harmless the other Party, including its employees and agents, from and against any and all third-party claims or liabilities arising from the negligence or willful misconduct of the indemnifying Party, its employees, or agents in carrying out its duties, obligations, representations, warranties and covenants under the terms of this Agreement. EXCEPT FOR ANY EXPRESS WARRANTIES SET FORTH IN THIS AGREEMENT, THE PARTIES HEREBY DISCLAIM ANY IMPLIED WARRANTIES OF ANY KIND, INCLUDING WARRANTY OF MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE. In no event shall either Party be liable to the other Party for any indirect, special, or consequential damages or lost profits, arising out of or related to performance of this Agreement or a breach of this Agreement, even if advised of the possibility of such damages or lost profits. This Section will survive the termination of this Agreement. Notwithstanding the foregoing, the Parties agree that Walgreens is not liable for activities covered by the Public Readiness and Emergency Preparedness (PREP) Act, and the foregoing obligations of indemnity shall not apply to Walgreens for any claims or liabilities arising out of activities covered by the PREP Act or any other applicable laws related to vaccines and/or health care providers.  This Section will survive the termination of this Agreement.",
    "generalTermsEN":" GENERAL TERMS ",// deliberately extra space
    "businessConfidentialityEN":"Business Confidentiality.",
    "businessConfidentialityTextEN":" The Parties acknowledge that certain proprietary or confidential business information may be disclosed between the Parties (&quot;<b>Confidential Information</b>&quot;).  Each Party will maintain the confidentiality of all Confidential Information, including, without limitation, implementing those precautions the Party employs with respect to its own Confidential Information and disclosing Confidential Information only to those employees who have a need to know in order to effectuate the purpose(s) of this Agreement or to maintain compliance with applicable laws.  In no event will either Party use the other Party’s Confidential Information to benefit itself or others, except as otherwise not prohibited under this Agreement. Confidential Information shall not include information: (i) generally known to the public or the industry without breach of this Agreement; (ii) independently developed by the receiving Party; (iii) known to or in the possession of the receiving Party prior to the disclosure pursuant to this Agreement; (iv) disclosed to the receiving Party by a third party without the confidentiality obligations set forth herein; or (v) required to be disclosed by any court or government agency; provided however, to the extent allowed by law, the receiving Party shall provide written notice of such planned disclosure to the disclosing Party allowing reasonable time for the disclosing Party to raise any objections to such disclosure.  Each Party acknowledges that disclosure of the other Party’s Confidential Information would cause the other Party irreparable harm and may, without limiting the remedies available for such breach, be enjoined at the instance of the harmed Party. This Section will survive the termination of this Agreement.",
    "confidentialityEN":"Confidentiality of Protected Health Information",
    "confidentialityTextEN":" The Parties agree to protect and respect each Participant’s right to privacy and confidentiality concerning their medical and pharmaceutical records, and to protect all individually identifiable health information as protected health information from misuse or disclosure, in compliance with all applicable state and federal laws, including the Health Insurance Portability and Accountability Act of 1996. Without limiting the generality of the foregoing, the Parties agree to use Participant-specific information: (i) only for permitted treatment, billing and related record-keeping purposes; or (ii) as otherwise permitted by law. Failure by either Party to abide by these requirements shall be a basis for immediate termination of this Agreement. Nothing herein will limit either Party’s use of any de-identified Participant information. This Section will survive the termination of this Agreement.",
    "AdvertisingEN":"Advertising",
    "AdvertisingTextEN":" Neither Party may advertise or use any trademarks, service marks, or symbols of the other Party without first receiving the written consent of the Party owning the mark and/or symbol with the following exceptions:  Client may use the name and the addresses of Walgreens locations in materials to inform Participants that Walgreens provides Immunizations.  Any other reference to Walgreens in any Client materials must be pre-approved, in writing, by Walgreens.",
    "frcMajEN":"Force Majeure",
    "frcMajTextEN":" The performance by either Party hereunder will be excused to the extent of circumstances beyond such Party's reasonable control, such as flood, tornado, earthquake, or other natural disaster, epidemic, pandemic, war, material destruction of facilities, fire, acts of terrorism, acts of God, etc.  In such event, the Parties will use their best efforts to resume performance as soon as reasonably possible under the circumstances.",
    "complianceEN":"Compliance",
    "complianceTextEN":" Each Party agrees to comply with all applicable federal, state and local laws, rules, and regulations for each territory in which Immunizations are provided under this Agreement.  Each Party will cooperate with reasonable requests by the other Party for information that is needed for its compliance with applicable laws, rules, and/or regulations. ",
    "assignmentEN":"Assignment",
    "assignmentTextEN":" Neither Party may assign this Agreement to a third-party without the prior written consent of the other Party, except that either Party will have the right to assign this Agreement to any direct or indirect parent, subsidiary or affiliated company or to a successor company without such consent.  Any permitted assignee will assume all obligations of its assignor under this Agreement.  No assignment will relieve any Party of responsibility for the performance of any obligations which have already occurred.  This Agreement will inure to the benefit of and be binding upon each Party, its respective successors and permitted assignees.",
    "thirdPartyRightsEN":"Third Party Rights",
    "thirdPartyRightsTextEN":" This Agreement is solely between Walgreens and Client and may not be construed to create any rights or remedies in favor of any third party, including, but not limited to, any Participant.",
    "noticesEN":"Notices",
    "noticesTextEN":" All notices provided for herein must be in writing, sent by U.S. certified mail, return receipt requested, postage prepaid, or by overnight delivery service providing proof of receipt to the address set forth following the signature blocks.  Notices will be deemed delivered upon receipt or upon refusal to accept delivery.",
    "entireAgrEN":"Entire Agreement",
    "entireAgrTextEN":" This Agreement, which includes any and all attachments, exhibits, riders, and other documents referenced herein, constitutes the entire and full agreement between the Parties relating to the subject matter herein and supersedes any previous contract, and except as otherwise permitted hereunder, no changes, amendments, or alterations will be effective unless reduced to a writing signed by a representative of each Party. Any prior agreements, documents, understandings, or representations relating to the subject matter of this Agreement not expressly set forth herein or referred to or incorporated herein by reference are of no force or effect.",
    "counterPartsHeadingEN":"Counterparts",
    "counterPartsTextEN":" This Agreement may be executed in two or more counterparts, each of which together shall be deemed an original, and all of which together shall constitute one and the same instrument. Execution and delivery of this Agreement may be completed electronically through facsimile, digitally or by .pdf file and any such electronic signature contained therein, shall create a valid and binding obligation of the Party executing it, having the same effect as if the original handwritten signature had been signed and delivered to the other Party.",
    //"rightsEN":"All rights reserved.",
    "approveAgrmntEN":"Approve Agreement",
    "approveTextEN":"Carefully review the Community Off-Site Agreement. If you agree to the conditions of the contract, please check &#8216;Approve' below and type your name into the Electronic Signature field. If there are any discrepancies in the Agreement, reject the Agreement and provide corrections in the notes field.",
    "electrSignEN":"Electronic Signature",
    "notesEN":"Notes:",
    "pageTitleEN":"",
    "dateEN":"Date:",
    "districtEN":"District#:",
    "approveEN":"Approve",
    "rejectEN":"Request Changes",
    "submitEN":"Submit",
    "cancelEN":"Cancel",
    "printAgreementEN":"Print Agreement",
    "clinicLocationEN":"Clinic Location:",
    "clinicDateEN":"Clinic Date:",
    "clinicTimeEN":"Clinic Time:",
    "contactEN":"Contact:",
    "phoneEN":"Phone:",
    "estShotsEN":"Est. Shots",
    "locationEN":"Location:",
    "timeEN":"Time:",
    "emailEN":"Email:",
    "emailAgreementToEN":"Email Agreement to:",
    "multipleEmailAddressEN":'Multiple email addresses may be entered by placing a comma between addresses.',
    "sendAgreementEN":"SEND AGREEMENT",
    "editAgreementEN":'EDIT AGREEMENT',
    "saveAgreementEN":"SAVE AGREEMENT",
    "noteTextEN":"* Please note, once a contract has been signed by the client, it cannot be edited.",
    "printAgreementTxtCapsEN":"PRINT AGREEMENT",
    "table1EN":'Table I',
    "attnEN":"Attn",
    "fluExpiryDateEN":"Flu Exp. Date:",
    "routineExpiryDateEN":"Routine Exp. Date:",
    "sendInvoiceToEN":'Send Invoice to:',
    'isTaxExemptEN':'Is Employer Tax Exempt?',
    'isCopayEN':'Will patient pay the portion of the cost. Is there a copay?',
    'voucherNeededEN':'Voucher Needed:',
    'voucherExpiryEN':'Expiration Date:',
    'copayValueEN':'Copay Value:',
    'influenzaSeasonText1EN':'per influenza season (August – April)',
    'contractYearText1EN':'per contract year',
    'influenzaSeasonText2EN':'influenza season',
    'contractYearText2EN':'contract year',
    'contactName':'Contact Name:',
    'contactPhone':'Contact Phone:',
    'contactEmail':'Contact Email:',
    'doseEN':'COVID Clinic:',
    'confidentialityP1EN':'',
    'confidentialityP2EN':'',
    'singleStarTAndC':'* For purposes of this Attachment A, this Immunization is a &quot;Government Supplied COVID19 Immunization&quot; which means that such COVID19 vaccines are (i) purchased by the United States government and (ii) made available to select vaccine providers where such supply is still available. The reimbursement rate for Government Supplied COVID19 Immunizations does not include the cost of the Immunization, but does include an administration fee and related taxes.',
    //'article2Heading':"",
    //'article2Text':"<h3 class='text-center font-weight-bold mb-5 black-font' style='font-size:18px !important'>ARTICLE II<br/>OFF-SITE CLINIC MINIMUMS</h3><p class=\"black-font\">2.1 &nbsp;Client guarantees that the average minimum of Immunizations set forth in Table 2 will be administered to Participants at each of Client's Off-Site Clinic locations per contract year (&quot;<b>Site Minimum</b>&quot;).  If the Site Minimum is not achieved for the contract year (determined by taking the total number of Immunizations administered at all Off-Site Clinics divided by the number of Off-Site Clinics locations in such contract year (&quot;<b>Site Average</b>&quot;)), at Walgreens' discretion, Walgreens will invoice Client a fee for the difference between the Site Minimum and Site Average multiplied by the number of Off-Site Clinics. The sum of which will be multiplied by the lowest reimbursement rate set forth in Table 1 in Attachment A and Client shall pay such amount within 30 days of being invoiced by Walgreens. Fees related to this paragraph will be billed directly to Client and will not be used by either Party for billing to Third Party Payors.</p>",
    //"siteMinTextfluEN":"(&quot;<b>Site Minimum</b>&quot;). If Walgreens determines that the Site Minimum is not achieved for the {0} (determined by taking the total number of Immunizations administered at all off-site locations divided by the number of off-site locations in such {1} ",
    //"siteMinTextEN":"(&quot;<b>Site Minimum</b>&quot;). If Walgreens determines that the Site Minimum is not achieved for the {0} (determined by taking the total number of Immunizations administered at all off-site locations divided by the number of off-site locations in such {1} ",
    //"siteAvgTextEN":"(&quot;<b>Site Average</b>&quot;)), at Walgreens' discretion, it will invoice Client for the difference between the Site Minimum and Site Average multiplied by the number of off-site events. The sum of which will be multiplied by the lowest reimbursement rate set forth in table in Attachment A and Client shall pay such amount within 30 days of being invoiced by Walgreens.",
    'indemnificationTAndCChangedDate':'12/22/2020',
    'universalContractReleaseDate':'06/06/2022',
    'clinicFee': 'Clinic Fee:',
    'participants': 'Participants:'
  }
  export const universalContractReleaseDate:string='05/02/2023';