// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `element.style {
  height: 135px;
}

.table td,
.table th {
  border-top: 0px solid #e9ecef;
}

.radio,
.checkbox {
  margin: 0 2px 5px 0 !important;
}

.error-msg {
  color: red;
}

.has-error label {
  color: red !important;
}

.has-error input {
  border: 1px solid red !important;
}

.fontSize14 {
  font-size: 14px !important;
}

.mb-30{
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/patientcareadvocacy/components/lula-contract-agreement-for-user-release2/lula-contract-agreement-for-user-release2.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;;EAEE,6BAA6B;AAC/B;;AAEA;;EAEE,8BAA8B;AAChC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["element.style {\r\n  height: 135px;\r\n}\r\n\r\n.table td,\r\n.table th {\r\n  border-top: 0px solid #e9ecef;\r\n}\r\n\r\n.radio,\r\n.checkbox {\r\n  margin: 0 2px 5px 0 !important;\r\n}\r\n\r\n.error-msg {\r\n  color: red;\r\n}\r\n\r\n.has-error label {\r\n  color: red !important;\r\n}\r\n\r\n.has-error input {\r\n  border: 1px solid red !important;\r\n}\r\n\r\n.fontSize14 {\r\n  font-size: 14px !important;\r\n}\r\n\r\n.mb-30{\r\n  margin-bottom: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
